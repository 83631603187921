import {
    Box,
    CircularProgress,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    //TextField,
    Typography,
  } from "@mui/material";
  import React, { useContext } from "react";
  import VisibilityIcon from '@mui/icons-material/Visibility';
  import { useDispatch, useSelector } from "react-redux";
  import {
      getEmployeeDashboardActivities,
    GetWeekTaskDetails,
    //GetSingleEmployeeAttendance,
  } from "../../Redux/Slice/Dashboard/EmployeeDashboardSlice";
  import { useEffect } from "react";
  import { useState } from "react";
  import moment from "moment/moment";
  import Cookies from "universal-cookie";
import EmpWeeklyTaskModal from "./EmpWeeklyTaskModal";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";

  function EmployeeWeeklyTaskDetail() {
    const navigate=useNavigate();
    const { setsnackBarData } = useContext(SnackBarContext);
    const { weektaskdata,status,IsLogin,message } = useSelector(
        getEmployeeDashboardActivities
    );
    const [state, setstate] = useState(null);
    const [ShowModal, setShowModal] = useState(false);
    const [Tasksdata, setTasksdata] = useState(null);
   // const [date, setdate] = useState("");
    const dispatch = useDispatch();
    const cookies = new Cookies();
    const user = cookies.get("user");
  console.log(Tasksdata);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);

    useEffect(() => {
      dispatch(
        GetWeekTaskDetails( user?.Emp_id ))
    }, [dispatch]);
  
    useEffect(() => {
      setstate(weektaskdata);
    }, [weektaskdata]);
  
    // const handleChange = (e) => {
    //   setdate(e.target.value);
    //   let date = moment(e.target.value).format("YYYY-MM-DD");
    //   dispatch(GetWeekTaskDetails({ userid: user?.Emp_id, date: date }));
    // };
  
    return (
      <>
        <Grid container sx={{ overflow: "scroll", width: "100%" }}>
          <div
            style={{
              height: "430px",
              width: "100%",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              overflow: "scroll",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: (theme) => theme.palette.grey["500"],
                  textAlign: "left",
                }}
              >
                Weekly Tasks
              </Typography>
              {/* <TextField
                variant="standard"
                type="month"
                autoComplete="off"
                value={date}
                placeholder="Employee"
                className="input_border"
                onChange={handleChange}
                id="ProjectNameFilter"
              /> */}
            </Box>
  
            <TableContainer
              component={Paper}
              sx={{ height: "400px", overflowY: "scroll" }}
            >
              {status !== "succeeded" ? (
                <Box
                  sx={{
                    width: "100%",
                    height: "400px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Table
                  sx={{
                    minWidth: { md: "auto", xs: "auto" },
                    overflow: "scroll",
                  }}
                  aria-label="simple table"
                  size="small"
                >
                  <TableHead>
                    <TableRow size="small">
                      <TableCell
                        size="small"
                        align="left"
                        sx={{
                          fontWeight: "bold",
                          color: (theme) => theme.palette.grey["500"],
                        }}
                      >
                        Date
                      </TableCell>
                      <TableCell
                        size="small"
                        align="left"
                        sx={{
                          fontWeight: "bold",
                          color: (theme) => theme.palette.grey["500"],
                        }}
                      >
                        Estimated Hours
                      </TableCell>
                      <TableCell
                        size="small"
                        align="left"
                        sx={{
                          fontWeight: "bold",
                          color: (theme) => theme.palette.grey["500"],
                        }}
                      >
                        Tasks
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state &&
                      state?.map((row, i) => (
                        <TableRow
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell size="small" align="left">
                            {row?.Date
                              ? moment(row?.Date).format("DD-MMM-YYYY")
                              : ""}
                          </TableCell>
                          
                          <TableCell size="small" align="left">
                            {row?.EstimatedHours
                            //   ? `${row?.EstimatedHours.split(":")[0]} hrs ${
                            //       row?.EstimatedHours.split(":")[1]
                            //     } min `
                            //   : null
                              }
                          </TableCell>
                          <TableCell size="small" align="left">
                           <VisibilityIcon onClick={()=>{
                            setTasksdata(row?.Tasks);
                            setShowModal(true);
                            }}/>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </div>
        </Grid>
        <EmpWeeklyTaskModal Tasksdata={Tasksdata} ShowModal={ShowModal} setShowModal={setShowModal}/>
      </>
    );
  }
  
  export default EmployeeWeeklyTaskDetail;
  