import PropTypes from "prop-types";
import {
  Box,
  // CircularProgress,
  Grid,
  gridClasses,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";

import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import { GetMissingPunchesForAllEmployees } from "../../Api/MissingPunches/MissingPunchesApi";
import Loader from "../Loader/Loader";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});

function MissingPunchesOfAllEmployees({
  setShowForm,
  ShowForm,
  setrowvalue,
  setselectedDate,
  selectedDate,
}) {
  // const dispatch = useDispatch();
  const { header } = useStyles();
  const { tabelStyle } = useContext(TabelStyleContext);
  const [filterRows, setfilterRows] = useState([]);
  const [pageSize, setPageSize] = useState(50);
  const [loading, setloading] = useState(false);
  const [MissingPunches, setMissingPunches] = useState([]);
  console.log(MissingPunches);

  const { setsnackBarData } = useContext(SnackBarContext);

  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [addPermit, setaddpermit] = useState(false);

  const GetMissingPunches = async (date) => {
    setloading(true);
    if (!date) {
      setloading(false);
      return setsnackBarData({
        openToast: true,
        message: "please select month",
        type: "error",
      });
    }
    const response = await GetMissingPunchesForAllEmployees({
      month: date,
    });
    setloading(false);
    if (response?.snackBarData?.message !== undefined) {
      setsnackBarData(response?.snackBarData);
    }

    if (response.data?.IsSuccess) {
      setMissingPunches(response?.data?.punches);
    }
  };

  useEffect(() => {
    let string = "MonthlyReport";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Add") {
        setaddpermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      setaddpermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);

  useEffect(() => {
    console.log(selectedDate);
    if (selectedDate) {
      GetMissingPunches(selectedDate); //Change This For No Api Call || moment().format("YYYY-MM")
    }
  }, [selectedDate, ShowForm]);
  useEffect(() => {
    let arr = [];
    if (MissingPunches) {
      MissingPunches?.forEach((item, idx) => {
        return arr.push({
          ...item,
          id: idx + 1,
          Date: moment(item?.Date).format("DD MMM YYYY"),
        });
      });
    }

    setfilterRows(arr);
  }, [MissingPunches]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport
          csvOptions={{ fileName: "MonthlyMissingPunchesAllEmpReport" }}
        />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "id", headerName: "Sr #", width: 100, headerClassName: header },
    {
      field: "Emp_name",
      headerName: "Employee Name",
      width: 160,
      // type: "date",
      headerClassName: header,
    },
    {
      field: "Date",
      headerName: "Date",
      width: 160,
      // type: "date",
      headerClassName: header,
    },
    {
      field: "LogCount",
      headerName: "Punch Count",
      width: 160,
      headerClassName: header,
    },
    {
      field: "action",
      headerName: "Action",
      width: 160,
      headerClassName: header,
      renderCell: (cellVal) => {
        return (
          <>
            <Box>
              <IconButton
                onClick={() => {
                  setrowvalue({ ...cellVal?.row, month: selectedDate });
                  setShowForm(true);
                }}
              >
                <AppRegistrationIcon />
              </IconButton>
            </Box>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          padding: "20px 10px 10px 10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
            width: "100%",
          }}
        >
          {addPermit ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                width: "100%",
              }}
            >
              <TextField
                size="small"
                type="month"
                variant="outlined"
                color="primary"
                fullWidth
                sx={{
                  input: { color: "black" },
                  maxWidth: "250px",
                  textAlign: "left",
                }}
                value={selectedDate}
                inputProps={{
                  max: moment().format("YYYY-MM"),
                }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setselectedDate(e.target.value);
                }}
              />
            </Box>
          ) : null}
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "end",
            width: "100%",
          }}
        ></Box>
      </Box>

      {/* <span className="ml-2 float-right">
        <div className="loaderclass"> </div>
      </span> */}

      {loading ? (
        <>
          <Loader />
        </>
      ) : null}
      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        overflow
        sx={{ height: tabelStyle.height, padding: "10px" }}
      >
        <DataGrid
          rows={filterRows}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          pagination
          components={{
            Toolbar: CustomToolbar,
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />
      </Grid>
    </>
  );
}

export default MissingPunchesOfAllEmployees;
MissingPunchesOfAllEmployees.propTypes = {
  setShowForm: PropTypes.any,
  setrowvalue: PropTypes.any,
  selectedDate: PropTypes.any,
  setselectedDate: PropTypes.any,
  ShowForm: PropTypes.any,
};
