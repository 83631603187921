import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import Cookies from "universal-cookie";
import { BreakAdjustmentApi } from "../../Api/BreakAdjustment/BreakAdjustment";
import {
  BreakAdjustmentAdded,
  updateBreakAdjustments,
} from "../../Redux/Slice/BreakAdjustment/BreakaAjustmentSlice";

const useStyles = makeStyles((theme) => ({
  inputBox: {
    width: "100%",
  },
  btns: {
    width: "80px",
    color: "#ffffff",
  },
  inputTypography: {
    textAlign: "left",
    fontSize: "15px",
    marginTop: "10px",
  },
  formInput: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
  },
  fieldInRow: {
    display: "flex",
    justifyContent: "space-between",
    gap: "25px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: "10px",
    },
  },
}));

const BreakAdjustmentForm = ({
  setShowForm,
  isEdited,
  adjData,
  setisEdited,
}) => {
  const { setsnackBarData } = useContext(SnackBarContext);
  const classes = useStyles();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const formikRef = useRef(null);

  useEffect(() => {
    if (isEdited && formikRef.current) {
      formikRef.current.setFieldValue("requestedTime", adjData?.requestedTime);
      formikRef.current.setFieldValue("reason", adjData?.reason);
      formikRef.current.setFieldValue("requestedDate", adjData?.requestedDate);
    }
  }, [adjData]);

  const handleBreakAdjAdd = async (values, formikHelpers) => {
    try {
      if (isEdited) {
        const newData = {
          ...values,
          reasonId: adjData?.reasonId,
          emp_id: adjData?.emp_id,
        };
        console.log("updatedValues", newData);
        dispatch(updateBreakAdjustments(newData));
        setShowForm(false);
        formikHelpers.resetForm();
      } else if (!isEdited) {
        const user = cookies.get("user");
        const emp_id = user.Emp_id;
        const updatedValues = {
          ...values,
          requestedTime: values.requestedTime / 60,
          emp_id: emp_id,
        };
        console.log("updatedValues", updatedValues);
        const response = await BreakAdjustmentApi(updatedValues);
        setsnackBarData(response.snackBarData);
        if (response.data.IsSuccess) {
          console.log("response", response.data.BreakAdjustment);
          dispatch(BreakAdjustmentAdded(response.data.BreakAdjustment));
          setShowForm(false);
          formikHelpers.resetForm();
        }
      }
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  const validationSchema = Yup.object({
    requestedTime: Yup.number()

      .required("Required")
      .min(1, "Time must be at least 1 minute")
      .max(60, "Time must be at most 60 minutes"),
    reason: Yup.string().required("Required").nullable(),
    requestedDate: Yup.string().required("Required").nullable(),
  });

  return (
    <Box
      sx={{
        minHeight: "calc(100vh - 173px)",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "20px",
      }}
    >
      <Formik
        initialValues={{
          requestedTime: null,
          reason: "",
          requestedDate: null,
        }}
        onSubmit={(values, formikHelpers) => {
          handleBreakAdjAdd(values, formikHelpers);
        }}
        validationSchema={validationSchema}
        innerRef={formikRef}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => {
          formikRef.current = { setFieldValue };
          return (
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  maxWidth: "1000px",
                  minWidth: "600px",
                  width: "100%",
                  display: "flex",
                  gap: "20px",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <Box className={classes.fieldInRow}>
                  <Box className={classes.inputBox}>
                    <Typography className={classes.inputTypography}>
                      Date
                    </Typography>
                    <TextField
                      className={classes.formInput}
                      fullWidth
                      size="small"
                      name="requestedDate"
                      type="date"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.requestedDate}
                      variant="outlined"
                      error={
                        Boolean(touched.requestedDate) &&
                        Boolean(errors.requestedDate)
                      }
                      helperText={
                        Boolean(touched.requestedDate) && errors.requestedDate
                      }
                    />
                  </Box>

                  <Box className={classes.inputBox}>
                    <Typography className={classes.inputTypography}>
                      Time in minutes
                    </Typography>
                    <TextField
                      className={classes.formInput}
                      fullWidth
                      size="small"
                      name="requestedTime"
                      type="number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.requestedTime}
                      variant="outlined"
                      error={
                        Boolean(touched.requestedTime) &&
                        Boolean(errors.requestedTime)
                      }
                      helperText={
                        Boolean(touched.requestedTime) && errors.requestedTime
                      }
                    />
                  </Box>
                </Box>

                <Box className={classes.fieldInRow}>
                  <Box className={classes.inputBox}>
                    <Typography className={classes.inputTypography}>
                      Reason
                    </Typography>
                    <TextField
                      className={classes.formInput}
                      fullWidth
                      size="small"
                      multiline
                      rows={4}
                      name="reason"
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.reason}
                      variant="outlined"
                      error={Boolean(touched.reason) && Boolean(errors.reason)}
                      helperText={Boolean(touched.reason) && errors.reason}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "10px",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    className={classes.btns}
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setShowForm(false);
                      setisEdited(false);
                    }}
                  >
                    Cancel
                  </Button>
                  {isSubmitting ? (
                    <CircularProgress size={24} />
                  ) : (
                    <Button
                      className={classes.btns}
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  )}
                </Box>
              </Box>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default BreakAdjustmentForm;

BreakAdjustmentForm.propTypes = {
  setShowForm: PropTypes.any,
  adjData: PropTypes.any,
  isEdited: PropTypes.any,
  setAdjData: PropTypes.any,
  setisEdited: PropTypes.any,
};
