import {
  // Avatar,
  Box,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  SwipeableDrawer,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
// import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import moment from "moment";
const useStyle = makeStyles((theme) => {
  return {
    container: {
      width: "100vw",
      maxWidth: "500px",
      //   minWidth:'300px',

      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px 20px",
      height: "100%",
      minHeight: "100vh",
      overflowY: "scroll",
      color: theme.palette.grey[700],
    },
    TabsContainer: {
      width: "100%",
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    tabs: {
      fontWeight: "bold",
    },
    ActiveTabs: {
      fontWeight: "bold",
      color: "red",
    },
    searchFieldContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      gap: "20px",
      justifyContent: "space-between",
      //   flexWrap: "wrap",
    },
    card: {
      padding: " 10px 20px",
      width: "100%",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      gap: "20px",
      display: "flex",
      justifyContent: "space-between",
    },
    contentContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "5px",
    },
    imageContainer: {
      height: "50px",
      width: "50px",
      borderRadius: "50%",
    },
    cardContainers: {
      // height: "100%",
      overflowY: "scroll",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "10px",
    },
    picBox: {
      height: "80px",
      width: "100px",
    },
  };
});

const AttendanceDrawer = ({ open, setOpen, data }) => {
  const {
    container,
    TabsContainer,
    tabs,
    ActiveTabs,
    searchFieldContainer,
    card,
    contentContainer,
    // imageContainer,
    cardContainers,
    picBox,
  } = useStyle();
  const navigate = useNavigate();
  const [value, setvalue] = useState(0);
  const [dataToFilter, setdataToFilter] = useState();
  const [searchvalue, setsearchValue] = useState("");
  const [emp, setemp] = useState(null);

  const handleChange = (__, val) => {
    // console.log(val);
    setvalue(val);
  };
  const handleCloseDrawer = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  useEffect(() => {
    if (data) {
      if (value === 0) {
        setemp(data?.Ontime_arr);
        setdataToFilter(data?.Ontime_arr);
      } else if (value === 1) {
        setemp(data?.late_arr);
        setdataToFilter(data?.late_arr);
      } else if (value === 2) {
        setemp(data?.absent_arr);
        setdataToFilter(data?.absent_arr);
      }
      setsearchValue("");
    }
  }, [data, value]);

  // console.log(data);
  // console.log(emp);

  const onTextChange = (e) => {
    // console.log(e.target.value);

    setsearchValue(e.target.value);
    console.log(dataToFilter);
    if (dataToFilter?.length > 0) {
      let value = dataToFilter?.filter((f) =>
        f?.emp_id?.name?.toLowerCase()?.includes(e.target.value?.toLowerCase())
      );
      setemp(value);
    }
  };

  return (
    <SwipeableDrawer anchor="right" open={open} onClose={handleCloseDrawer}>
      <Box className={container}>
        <Box className={TabsContainer}>
          <Tabs variant="fullWidth" value={value} onChange={handleChange}>
            <Tab
              label="On Time"
              className={`${tabs} ${value === 0 ? ActiveTabs : ""}`}
            />
            <Tab
              label="Late"
              className={`${tabs} ${value === 1 ? ActiveTabs : ""}`}
            />
            <Tab
              label="Absent/Leave"
              className={`${tabs} ${value === 2 ? ActiveTabs : ""}`}
            />
          </Tabs>
        </Box>
        <Box className={searchFieldContainer}>
          <OutlinedInput
            id="outlined-adornment-password"
            size="small"
            value={searchvalue}
            fullWidth
            onChange={onTextChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" edge="end">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
            placeholder="Search Employee"
          />
          <Button
            sx={{
              whiteSpace: "nowrap",
              textDecoration: "underline",
              color: (theme) => `${theme.palette.grey[800]} !important`,
            }}
            onClick={() => navigate("/TodayAttendance")}
          >
            All Employees
          </Button>
        </Box>
        <Box className={cardContainers}>
          {emp?.map((val, i) => {
            console.log("Data", val);
            return (
              <Box className={card} key={i}>
                {/* <Box>
                  {val?.profile_pic ? (
                    <img
                      src={val?.profile_pic || "./profile.png"}
                      alt={"employee"}
                      className={imageContainer}
                    />
                  ) : (
                    <Avatar src={val?.profile_pic} className={imageContainer} />
                  )}
                </Box> */}
                <Box className={contentContainer}>
                  <Box>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      {val?.emp_id?.name}
                    </Typography>
                    <Typography>{val?.department?.department_name}</Typography>
                    <Typography> {val?.name}</Typography>
                  </Box>
                  <Box>
                    <Typography>
                      Login :{" "}
                      {val.in_time
                        ? moment(val?.in_time, "HH:mm:ss").format("hh:mm A")
                        : ""}
                    </Typography>
                    <Typography>
                      Logout :{" "}
                      {val.out_time
                        ? moment(val?.out_time, "HH:mm:ss").format("hh:mm A")
                        : ""}
                    </Typography>
                  </Box>
                </Box>
                <Box className={picBox}>
                  <img src={val?.emp_image || "./profile.png"} style={{ height: '100%', width: "100%", borderRadius: '50%' }} />
                </Box>

                {/* <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <FiberManualRecordIcon
                      color={
                        val?.tag === "onTime"
                          ? "success"
                          : val?.tag === "late"
                            ? "error"
                            : ""
                      }
                    />
                    <Typography sx={{ textTransform: "capitalize" }}>
                      {val?.tag?.toLowerCase()}
                    </Typography>
                  </Box>
                </Box> */}
              </Box>
            );
          })}
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

export default AttendanceDrawer;
AttendanceDrawer.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
  data: PropTypes.any,
};
