import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  SalaryDetails: [],
  EmployeeSalaryDetails: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,

};

export const fetchSalaryDetails = createAsyncThunk(
  "getallpayrolldetail",
  async () => {
    const response = await axiosInstance.get(`getallpayrolldetail`);

    return response.data;
  }
);
export const fetchsingleSlaryDetails = createAsyncThunk(
    "getsinglepayrolldetail/",
    async (id) => {
      const response = await axiosInstance.get(`getsinglepayrolldetail/${id}`);

      return response.data;
    }
  );
  export const FetchSalaryReportsByMonths = createAsyncThunk(
    "getpayrollhistorymonth",
    async () => {
      const response = await axiosInstance.get(`getpayrollhistorymonth`);
      return response.data;
    }
  );

const SalaryDetailsSlice = createSlice({
  name: "SalaryDetails",
  initialState,
  reducers: {
    SalaryDetailsAdded: {
      reducer(state, action) {
        state.SalaryDetails?.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSalaryDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSalaryDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload?.IsSuccess;
        state.SalaryDetails = action?.payload?.Data;
      })
      .addCase(fetchSalaryDetails.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {

          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(fetchsingleSlaryDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.EmployeeSalaryDetails = action.payload.Data;
      })
      .addCase(fetchsingleSlaryDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchsingleSlaryDetails.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
 
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })  
       .addCase(FetchSalaryReportsByMonths.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.ReportByMonths = action.payload.Data;
      })
      .addCase(FetchSalaryReportsByMonths.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchSalaryReportsByMonths.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
  },
});

export const getSalaryDetailsDetails = (state) => state.SalaryDetails;

export const { SalaryDetailsAdded } = SalaryDetailsSlice.actions;

export default SalaryDetailsSlice.reducer;