import PropTypes from "prop-types";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  gridClasses,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  // Tooltip,
  //   InputAdornment,
  //   TextField,
  // Typography,
} from "@mui/material";
// import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import {
  fetchAdvanced,
  fetchAllAdvanced,
  getAdvancedDetails,
} from "../../Redux/Slice/Advanced/AdvancedSlice";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import { PatchaAdvanceadminApi } from "../../Api/Advanced/PatchAdvanceAdminApi";
import moment from "moment/moment";
import AdvanceApprovedModal from "./AdvanceApprovedModal";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import AdvancedEditModal from "./AdvancedEditModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AdvancedDetailsModal from "./AdvancedDetailsModal";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../Button/AddNewButton";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
// import AddTaskIcon from "@mui/icons-material/AddTask";
const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});
function AdvancedTable({ setShowForm }) {
  const { tabelStyle } = useContext(TabelStyleContext);
  const navigate = useNavigate();
  const { header } = useStyles();
  const [pageSize, setPageSize] = useState(10);
  const [rows, setrows] = useState([]);
  const [advances, setadvances] = useState([]);
  const [open, setOpen] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [advanceRow, setadvanceRow] = useState();
  const [advancedData, setadvancedData] = useState();
  const [editRow, seteditRow] = useState();
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const [showModal, setshowModal] = useState(false);
  const [showModal2, setshowModal2] = useState(false);
  const [showModal3, setshowModal3] = useState(false);
  const [editRow2, seteditRow2] = useState();
  const { Advanced, status, IsLogin, message } =
    useSelector(getAdvancedDetails);
  const Name = useRef();
  const Date = useRef();

  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [editPermit, seteditpermit] = useState(false);
  // console.log(crop);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  console.log(roles);
  useEffect(() => {
    let string = "Advances";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Edit") {
        seteditpermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      seteditpermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  console.log(editPermit);

  const user = cookies.get("user");
  useEffect(() => {
    if (cookies.get("user")?.type === "Employee") {
      dispatch(fetchAdvanced(cookies.get("user")?.Emp_id));
    } else if (cookies.get("user")?.type === "Admin") {
      dispatch(fetchAllAdvanced());
    }
  }, [dispatch]);
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "AdvanceApplication" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    let rowData = [];
    Advanced?.Employee_advance?.map((adv, idx) => {
      rowData.push({
        ...adv,
        id: idx + 1,
        amount: adv?.amount?.toLocaleString(),
        month: moment(adv?.month).format("MMM-YYYY"),
        note: adv?.note,
        requestedamount: adv?.requested_amount?.toLocaleString(),
        status: adv?.status,
        advance_id: adv?.advance_id,
        name: adv?.emp_id?.name,
      });
    });
    setrows(rowData);
    setadvances(rowData);
  }, [Advanced]);
  const handleApprove = (val) => {
    setadvancedData(val);
  };
  const RejectedAdvanced = async () => {
    setloading(true);
    const response = await PatchaAdvanceadminApi({
      advance_id: advanceRow.advance_id,
      status: "Rejected",
    });
    setsnackBarData(response.snackBarData);
    setloading(false);

    if (response.data.IsSuccess) {
      setadvanceRow(null);
      dispatch(fetchAllAdvanced());
      handleClose();
      setloading(false);
    }
  };
  const columns = [
    { field: "id", headerName: "Sr #", headerClassName: header },
    {
      field: "name",
      headerName: "Employee",
      width: 180,
      headerClassName: header,
    },
    {
      field: "month",
      headerName: "Month",
      width: 160,
      headerClassName: header,
    },
    {
      field: "requestedamount",
      headerName: "Requested ",
      width: 160,
      headerClassName: header,
    },
    {
      field: "amount",
      headerName: "Approved ",
      width: 180,
      headerClassName: header,
    },

    {
      field: "note",
      headerName: "Note",
      width: 240,
      headerClassName: header,
    },
    {
      field: "status",
      headerName: "Status",
      width: 180,
      headerClassName: header,
      renderCell: (celVal) => {
        return (
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Typography sx={{ width: "5rem" }}>{celVal.row?.status}</Typography>
            <Box
              sx={{
                width: "20px",
                height: "20px",
                backgroundColor:
                  celVal?.row?.status === "Rejected"
                    ? "red"
                    : celVal?.row?.status === "Accepted"
                    ? "green"
                    : celVal?.row?.status === "Pending"
                    ? "yellow"
                    : null,
                borderRadius: "100%",
              }}
            ></Box>
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "Actions",
      width: 240,
      headerClassName: header,
      renderCell: (cellVal) => {
        return cellVal.row.status === "Accepted" ||
          cellVal.row.status === "Rejected" ? (
          <Box sx={{ display: "flex", gap: "10px" }}>
            {editPermit ? (
              <Tooltip title="Edit">
                <AppRegistrationIcon
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    seteditRow(cellVal.row);
                    setshowModal2(true);
                    // setShowFormAdmin(true);
                  }}
                />
              </Tooltip>
            ) : null}
            <Tooltip title="Details">
              <VisibilityIcon
                fontSize="small"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  seteditRow2(cellVal.row);
                  setshowModal3(true);
                  // setShowFormAdmin(true);
                }}
              />
            </Tooltip>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {editPermit ? (
              <Tooltip title="Approve">
                <CheckCircleIcon
                  fontSize="small"
                  sx={{ cursor: "pointer", color: "green" }}
                  onClick={() => {
                    handleApprove(cellVal.row);
                    setshowModal(true);
                    // setShowFormAdmin(true);
                  }}
                />
              </Tooltip>
            ) : null}
            {editPermit ? (
              <Tooltip title="Reject">
                <CancelPresentationIcon
                  fontSize="small"
                  sx={{ cursor: "pointer", color: "red" }}
                  onClick={() => {
                    handleClickOpen();
                    setadvanceRow(cellVal.row);
                  }}
                />
              </Tooltip>
            ) : null}
          </Box>
        );
      },
    },
  ];
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onTextChange = () => {
    if (Name.current?.value === "" && Date.current.value === "") {
      setadvances(rows);
    } else {
      let suggestions = [];
      let regex1 = new RegExp(Name.current?.value, "i");
      let regex2 = new RegExp(
        moment(Date.current.value).format("MMM-YYYY"),
        "i"
      );

      suggestions = rows?.filter(
        (val) =>
          (Name.current?.value === "" || regex1.test(val?.name)) &&
          (Date.current?.value === "" || regex2.test(val?.month))
      );

      setadvances(suggestions);
    }
  };
  useEffect(() => {
    if (status === "failed") {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
    }
  }, [status]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          padding: "10px 10px 0px 10px",
        }}
      >
        {user?.type === "Employee" ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {/* <Button
              sx={{ padding: "5px 10px" }}
              onClick={() => {
                setShowForm(true);
              }}
              variant="contained"
              className="tabletopbutton1 text-right"
            >
              New
            </Button> */}
            <CustomButton
              Color={"primary"}
              Icon={<AddIcon />}
              onClick={() => {
                setShowForm(true);
              }}
              Label={"Add"}
            />
          </Box>
        ) : null}
        <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
          <Box
            sx={{ padding: "10px 10px 0px 10px", display: "flex", gap: "10px" }}
          >
            {user?.type === "Employee" ? null : (
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        sx={{ color: (theme) => theme.palette.grey.G2 }}
                      />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                type="text"
                autoComplete="off"
                placeholder="Employee"
                // className="input_border"
                id="empName"
                inputRef={Name}
                onChange={onTextChange}
              />
            )}
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{ color: (theme) => theme.palette.grey.G2 }}
                    />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              type="month"
              autoComplete="off"
              placeholder="Search"
              // className="input_border"
              inputRef={Date}
              id="date"
              onChange={onTextChange}
            />
          </Box>
        </Box>
      </Box>

      <Box
        sx={{ height: tabelStyle.height, padding: "10px" }}
        // sx={{
        //   height:
        //     user?.type === "Employee"
        //       ? "calc(100vh - 222px)"
        //       : "calc(100vh - 220px)",
        //   width: "100%",
        //   padding: "10px",
        // }}
      >
        {Advanced?.length<=0 && status === "loading" ? <Loader /> : null}
        <DataGrid
          rows={advances}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          columnVisibilityModel={
            user?.type === "Employee"
              ? {
                  action: false,
                  name: false,
                }
              : { status: true }
          }
          // pagination
          components={{
            Toolbar: CustomToolbar,
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to Rejected
            <b> {advanceRow?.requestedamount}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              sx={{
                background: "red",
                color: "white !important",
                fontSize: "13px",
                textTransform: "none",
              }}
              onClick={handleClose}
            >
              No
            </Button>

            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                sx={{
                  background: "#3480FA",
                  color: "white !important",
                  fontSize: "13px",
                  textTransform: "none",
                }}
                onClick={() => {
                  RejectedAdvanced();
                }}
                autoFocus
              >
                Yes
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
      <AdvanceApprovedModal
        advancedData={advancedData}
        setshowModal={setshowModal}
        showModal={showModal}
      />
      <AdvancedEditModal
        editRow={editRow}
        showModal2={showModal2}
        setshowModal2={setshowModal2}
      />
      <AdvancedDetailsModal
        editRow2={editRow2}
        showModal3={showModal3}
        setshowModal3={setshowModal3}
      />
    </>
  );
}

export default AdvancedTable;
AdvancedTable.propTypes = {
  setShowForm: PropTypes.any,
  setShowFormAdmin: PropTypes.any,
};
