import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  //   MenuItem,
  Typography,
  FormControl,
  CircularProgress,
  MenuItem,
  Select,
  OutlinedInput,
  Chip,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  Tooltip,
} from "@mui/material";
// import moment from "moment";
import PropTypes from "prop-types";
import theme from "../../theme";
import { makeStyles } from "@mui/styles";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
// import { GazettedHolidayAdded } from "../../Redux/Slice/GazettedHolidays/GazettedHolidaysSlice"
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
// import moment from "moment";
import { AddShiftRulesApi } from "../../Api/ShiftRule/ShiftRuleApis";
import { useTheme } from "styled-components";
import // fetchShiftRulesPopulate,
// getshiftrulesDetails,
"../../Redux/Slice/ShiftRules/ShiftRuleSlice";
import InfoIcon from "@mui/icons-material/Info";
import { useContext } from "react";
import TimezoneSelect from "react-timezone-select";
import { PatchShiftRuleApi } from "../../Api/ShiftRule/UpdateShiftRuleApi";
import moment from "moment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const useStyles = makeStyles(() => {
  return {
    Form_input: {
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,
    },
    input_typography: {
      textAlign: "left",
      fontSize: `15px !important`,
      marginTop: "10px !important",
      paddingRight: "20px !important",
    },

    form_main: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      minHeight: "calc(100vh - 173px)",
      padding: "30px 5%",
    },
    responsive: {
      maxWidth: "900px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },

    btns: {
      width: "80px",
    },
    Form_inputx: {
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,
      width: "20pc",
      display: "flex",
      alignItems: "center",
    },
    FieldBox: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },

    FieldInRow: {
      display: "flex",
      justifyContent: "space-between",
      gap: "25px",
      alignItems: "center",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },

    icon: {
      cursor: "pointer",
    },
    inputBox: {
      width: "100%",
    },
  };
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ShiftRulesForm = ({
  setShowForm,
  isEdited,
  setisEdited,
  shiftRowData,
}) => {
  const theme = useTheme();
  const {
    form_main,
    responsive,
    Form_input,
    btns,
    input_typography,
    inputBox,
    icon,
    FieldInRow,
    Form_inputx,
  } = useStyles();
console.log(shiftRowData);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [selectedTimezone, setSelectedTimezone] = useState({});
  const [OffDay, setOffDay] = useState([]);
  const [tableRow, setTableRow] = useState([]);

  const [weeknames] = useState([
    {
      offday: "Monday",
      offday_no: 2,
    },
    {
      offday: "Tuesday",
      offday_no: 3,
    },
    {
      offday: "Wednesday",
      offday_no: 4,
    },
    {
      offday: "Thursday",
      offday_no: 5,
    },
    {
      offday: "Friday",
      offday_no: 6,
    },
    {
      offday: "Saturday",
      offday_no: "7",
    },
    {
      offday: "Sunday",
      offday_no: "8",
    },
  ]);

  let minDate = moment()
    .subtract(1, "months")
    .endOf("month")
    .format("YYYY-MM-DD");
  let maxDate = moment()
    .subtract(11, "months")
    .startOf("month")
    .format("YYYY-MM-DD");

  const handleAddRow = () => {
    if (
      tableRow.length === 0 ||
      (tableRow.length > 0 &&
        tableRow[tableRow.length - 1].alternate_offday !== "" &&
        tableRow[tableRow.length - 1].alternate_offdate !== "")
    ) {
      setTableRow((prevRow) => [
        ...prevRow,
        {
          alternate_offday_no: "",
          alternate_offday: "",
          alternate_offdate: "",
        },
      ]);
    } else {
      setsnackBarData({
        openToast: "true",
        message: "Please Fill Both Fields",
        type: "error",
      });
    }
  };

  const handleDelete = (index) => {
    setTableRow((prevRow) => prevRow.filter((_, idx) => idx !== index));
  };

  const handleRowChange = (e, index, row) => {
    console.log(row);
    let name = e.target.name;
    let value = e.target.value;

    if (name === "alternate_offdate") {
      if (row.alternate_offday == "") {
        setsnackBarData({
          type: "error",
          openToast: "true",
          message: `Select Day First`,
        });
        return;
      }
      const selectedDate = moment(value, "YYYY-MM-DD");
      const dayFromSelectedDate = selectedDate.format("dddd");
      console.log(dayFromSelectedDate);

      if (row?.alternate_offday != `${dayFromSelectedDate}`) {
        setsnackBarData({
          type: "error",
          openToast: "true",
          message: `Select This Day: ${row?.alternate_offday}`,
        });
        return;
      }

      setTableRow((prevRow) => {
        const newRow = { ...prevRow[index], [name]: value };
        console.log("newRoiw", newRow);

        return prevRow.map((row, idx) => (idx === index ? newRow : row));
      });
    } else {
      console.log("row", value);
      const obj = weeknames.find((item) => item.offday_no == value);
      // console.log("sss", obj)

      setTableRow((prevRow) => {
        const newRow = {
          ...prevRow[index],
          ["alternate_offday"]: obj.offday,
          [`alternate_offday_no`]: obj.offday_no,
        };
        return prevRow.map((row, idx) => (idx === index ? newRow : row));
      });
    }
  };

  useEffect(() => {
    if (isEdited) {
      setTableRow(shiftRowData?.alternate_offdays);
      let arr = shiftRowData?.offDay?.map((m) => m.offday);
      setOffDay(arr);
      setSelectedTimezone(
        shiftRowData?.TimeZone ? JSON.parse(shiftRowData?.TimeZone) : {}
      );
    }
  }, [shiftRowData]);
  const handelAdd = async (values, formikHelpers) => {
    let alloffdays = [];

    OffDay.map((off) => {
      weeknames.map((all) => {
        if (off === all.offday) {
          alloffdays.push(all);
        }
      });
    });

    if (Object.keys(selectedTimezone).length === 0) {
      formikHelpers.setSubmitting(false);
      return setsnackBarData({
        message: "please select time-zone",
        type: "error",
        openToast: true,
      });
    } else if (alloffdays.length < 1) {
      return setsnackBarData({
        message: "Please select atleast one offday",
        type: "error",
        openToast: true,
      });
    }

    let valuesData = {
      ...values,
      offdays: alloffdays,
      time_zone: JSON.stringify(selectedTimezone),
    };

    if (tableRow.length === 0) {
      valuesData.alternate_offdays = [];
    } else {
      valuesData.alternate_offdays = tableRow;
    }
    console.log("My Data Api", valuesData);

    if (!isEdited) {
      const response = await AddShiftRulesApi(valuesData);
      console.log("Apiiii", response);
      formikHelpers.setSubmitting(false);
      setsnackBarData(response.snackBarData);
      if (response.data.IsSuccess) {
        formikHelpers.setSubmitting(false);
        setShowForm(false);
      }
      formikHelpers.setSubmitting(false);
    } else if (isEdited) {
      const response = await PatchShiftRuleApi({
        ...valuesData,
        rule_id: shiftRowData?.rule_id,
      });
      formikHelpers.setSubmitting(false);
      setsnackBarData(response.snackBarData);
      if (response.data.IsSuccess) {
        formikHelpers.setSubmitting(false);
        setShowForm(false);
        setisEdited(false);
      }
      formikHelpers.setSubmitting(false);
    }
  };
  console.log("Shift Row", shiftRowData);
  const ShiftRule = Yup.object({
    checkin_time: Yup.string().required("Required"),
    checkout_time: Yup.string().required("Required"),
    breakin_time: Yup.string().required("Required"),
    breakout_time: Yup.string().required("Required"),
    working_hrs: Yup.string().required("Required"),
    margin_min: Yup.string().required("Required"),
    duty_hrs: Yup.string().required("Required"),
    shift_name: Yup.string().required("Required"),
  });

  const handleChangeValue = (event) => {
    const {
      target: { value },
    } = event;
    setOffDay(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  function getStyles(tech, filterTechnology) {
    return {
      backgroundColor: filterTechnology?.indexOf(tech) === -1 ? "" : "#BCDAF4",
    };
  }
  console.log("sss", tableRow, weeknames);
  return (
    <Box className={form_main}>
      <Box className={responsive}>
        <Formik
          initialValues={{
            checkin_time: isEdited ? shiftRowData?.checkInTime : "",
            checkout_time: isEdited ? shiftRowData?.checkOutTime : "",
            breakin_time: isEdited ? shiftRowData?.brakeInTime : "",
            breakout_time: isEdited ? shiftRowData?.brakeOutTime : "",
            working_hrs: isEdited ? shiftRowData?.workinghrs : "",
            duty_hrs: isEdited ? shiftRowData?.dutyhrs : "",
            shift_name: isEdited ? shiftRowData?.shiftname : "",
            offdays: [],
            margin_min: isEdited ? shiftRowData?.margin_min : "",
            tableRow: isEdited ? shiftRowData?.alternate_offdate : "",
            shortbreak_mins: isEdited ? shiftRowData?.shortbreak_mins : "",
          }}
          validationSchema={ShiftRule}
          onSubmit={(values, formikHelpers) => {
            // console.log("check", values);
            handelAdd(values, formikHelpers);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            isSubmitting,
            handleBlur,
            // handleSubmit,
          }) => (
            <Form>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
              >
                <Box className={FieldInRow}>
                  <Box className={inputBox}>
                    <Typography className={input_typography}>
                      Checkin Time
                    </Typography>
                    <FormControl fullWidth sx={{ textAlign: "left" }}>
                      <Field
                        className={Form_input}
                        fullWidth
                        as={TextField}
                        size="small"
                        name="checkin_time"
                        type="time"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.checkin_time}
                        variant="outlined"
                        error={
                          Boolean(touched.checkin_time) &&
                          Boolean(errors.checkin_time)
                        }
                        helperText={
                          Boolean(touched.checkin_time) && errors.checkin_time
                        }
                      ></Field>
                    </FormControl>
                  </Box>
                  <Box className={inputBox}>
                    <Typography className={input_typography}>
                      Checkout Time
                    </Typography>
                    <FormControl fullWidth sx={{ textAlign: "left" }}>
                      <Field
                        className={Form_input}
                        fullWidth
                        as={TextField}
                        size="small"
                        name="checkout_time"
                        type="time"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.checkout_time}
                        variant="outlined"
                        error={
                          Boolean(touched.checkout_time) &&
                          Boolean(errors.checkout_time)
                        }
                        helperText={
                          Boolean(touched.checkout_time) && errors.checkout_time
                        }
                      />
                    </FormControl>
                  </Box>
                </Box>

                <Box className={FieldInRow}>
                  <Box className={inputBox}>
                    <Typography className={input_typography}>
                      Break Time
                    </Typography>
                    <FormControl fullWidth sx={{ textAlign: "left" }}>
                      <Field
                        className={Form_input}
                        fullWidth
                        as={TextField}
                        size="small"
                        name="breakin_time"
                        type="time"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.breakin_time}
                        variant="outlined"
                        error={
                          Boolean(touched.breakin_time) &&
                          Boolean(errors.breakin_time)
                        }
                        helperText={
                          Boolean(touched.breakin_time) && errors.breakin_time
                        }
                      />
                    </FormControl>
                  </Box>
                  <Box className={inputBox}>
                    <Typography className={input_typography}>
                      BreakOff Time
                    </Typography>
                    <FormControl fullWidth sx={{ textAlign: "left" }}>
                      <Field
                        className={Form_input}
                        fullWidth
                        as={TextField}
                        size="small"
                        name="breakout_time"
                        type="time"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.breakout_time}
                        variant="outlined"
                        error={
                          Boolean(touched.breakout_time) &&
                          Boolean(errors.breakout_time)
                        }
                        helperText={
                          Boolean(touched.breakout_time) && errors.breakout_time
                        }
                      />
                    </FormControl>
                  </Box>
                </Box>

                <Box className={FieldInRow}>
                  <Box className={inputBox}>
                    <Typography className={input_typography}>
                      Duty Hours
                    </Typography>
                    <FormControl fullWidth sx={{ textAlign: "left" }}>
                      <Field
                        className={Form_input}
                        fullWidth
                        as={TextField}
                        size="small"
                        name="duty_hrs"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.duty_hrs}
                        variant="outlined"
                        error={
                          Boolean(touched.duty_hrs) && Boolean(errors.duty_hrs)
                        }
                        helperText={
                          Boolean(touched.duty_hrs) && errors.duty_hrs
                        }
                      ></Field>
                    </FormControl>
                  </Box>
                  <Box className={inputBox}>
                    <Typography className={input_typography}>
                      Working hours
                    </Typography>
                    <FormControl fullWidth sx={{ textAlign: "left" }}>
                      <Field
                        className={Form_input}
                        fullWidth
                        as={TextField}
                        size="small"
                        name="working_hrs"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.working_hrs}
                        variant="outlined"
                        error={
                          Boolean(touched.working_hrs) &&
                          Boolean(errors.working_hrs)
                        }
                        helperText={
                          Boolean(touched.working_hrs) && errors.working_hrs
                        }
                      />
                    </FormControl>
                  </Box>
                </Box>

                <Box className={FieldInRow}>
                  <Box className={inputBox}>
                    <Typography className={input_typography}>
                      Margin Min
                    </Typography>
                    <FormControl fullWidth sx={{ textAlign: "left" }}>
                      <Field
                        className={Form_input}
                        fullWidth
                        as={TextField}
                        size="small"
                        name="margin_min"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.margin_min}
                        variant="outlined"
                        error={
                          Boolean(touched.margin_min) &&
                          Boolean(errors.margin_min)
                        }
                        helperText={
                          Boolean(touched.margin_min) && errors.margin_min
                        }
                      ></Field>
                    </FormControl>
                  </Box>

                  <Box className={inputBox}>
                    <Typography className={input_typography}>
                      Shift Name
                    </Typography>
                    <FormControl fullWidth sx={{ textAlign: "left" }}>
                      <Field
                        className={Form_input}
                        fullWidth
                        as={TextField}
                        size="small"
                        name="shift_name"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.shift_name}
                        variant="outlined"
                        // select
                        error={
                          Boolean(touched.shift_name) &&
                          Boolean(errors.shift_name)
                        }
                        helperText={
                          Boolean(touched.shift_name) && errors.shift_name
                        }
                      ></Field>
                    </FormControl>
                  </Box>
                </Box>

                <Box className={FieldInRow}>
                  <Box className={inputBox}>
                    <Typography className={input_typography}>
                      Off Days
                    </Typography>
                    <FormControl fullWidth sx={{ textAlign: "left" }}>
                      <Field
                        className={Form_input}
                        fullWidth
                        MenuProps={MenuProps}
                        value={OffDay}
                        // as={TextField}
                        size="small"
                        name="offdays"
                        type="Number"
                        as={Select}
                        onBlur={handleBlur}
                        onChange={handleChangeValue}
                        // value={values.offdays}
                        variant="outlined"
                        error={
                          Boolean(touched.offdays) && Boolean(errors.offdays)
                        }
                        helperText={Boolean(touched.offdays) && errors.offdays}
                        multiple
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Chip"
                          />
                        }
                        renderValue={(selected) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                            }}
                          >
                            {selected?.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                      >
                        {weeknames &&
                          weeknames?.map((name) => (
                            <MenuItem
                              key={name.offday_no}
                              value={name?.offday}
                              style={getStyles(name?.offday, OffDay, theme)}
                            >
                              {name?.offday}
                            </MenuItem>
                          ))}
                      </Field>
                    </FormControl>
                  </Box>
                  <Box className={inputBox}>
                    <Typography className={input_typography}>
                      Time Zone
                    </Typography>
                    <TimezoneSelect
                      value={selectedTimezone}
                      onChange={setSelectedTimezone}
                    />
                  </Box>
                </Box>
                <Box className={FieldInRow}>
                  <Box className={inputBox}>
                    <Typography className={input_typography}>
                    Short Break Minute
                    </Typography>
                    <FormControl fullWidth sx={{ textAlign: "left" }}>
                      <Field
                        className={Form_input}
                        fullWidth
                        as={TextField}
                        size="small"
                        name="shortbreak_mins"
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.shortbreak_mins}
                        variant="outlined"
                      
                      ></Field>
                    </FormControl>
                  </Box>

                  <Box className={inputBox}>
              
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      marginTop: "10px",
                      textAlign: "justify",
                    }}
                  >
                    Alternate Off Days
                  </Typography>
                </Box>

                <Box>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>#Sr</TableCell>
                        <TableCell>Select Day</TableCell>
                        {/* {console.log("Day", tableRow[0]?.alternate_offday)}
                        {console.log("Dayxxx", tableRow?.length)} */}

                        {tableRow && (
                          <TableCell>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "flex-end",
                                gap: "10px",
                              }}
                            >
                              {`Select any previous off "${
                                tableRow[tableRow.length - 1]?.alternate_offday
                              }" of this Shift`}
                              <Tooltip
                                title={
                                  "Select the last alternate offday date of previous month"
                                }
                              >
                                <InfoIcon />
                              </Tooltip>
                            </Box>
                          </TableCell>
                        )}

                        <TableCell>
                          <AddCircleIcon
                            className={icon}
                            color="primary"
                            onClick={handleAddRow}
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableRow?.length > 0 &&
                        tableRow?.map((row, idx) => {
                          return (
                            <TableRow key={idx}>
                              <TableCell>{1 + idx}</TableCell>
                              <TableCell>
                                <FormControl
                                  fullWidth
                                  sx={{ textAlign: "left" }}
                                >
                                  <Field
                                    className={Form_inputx}
                                    fullWidth
                                    as={TextField}
                                    size="small"
                                    name="alternate_offday"
                                    type="text"
                                    value={row.alternate_offday_no}
                                    onChange={(e) =>
                                      handleRowChange(e, idx, row)
                                    }
                                    variant="outlined"
                                    select
                                    // disabled={row.alternateoffday_id ? true : false}
                                  >
                                    {weeknames &&
                                      weeknames?.map((name) => (
                                        <MenuItem
                                          key={name.offday_no}
                                          value={name.offday_no}
                                        >
                                          {name?.offday}
                                        </MenuItem>
                                      ))}
                                  </Field>
                                </FormControl>
                              </TableCell>
                              <TableCell>
                                <FormControl
                                  fullWidth
                                  sx={{ textAlign: "left" }}
                                >
                                  <TextField
                                    className={Form_inputx}
                                    size="small"
                                    type="date"
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    name="alternate_offdate"
                                    value={row.alternate_offdate}
                                    onChange={(e) =>
                                      handleRowChange(e, idx, row)
                                    }
                                    inputProps={{
                                      min: maxDate,
                                      max: minDate,
                                    }}
                                    // disabled={row.alternateoffday_id ? true : false}
                                  />
                                </FormControl>
                              </TableCell>

                              <TableCell>
                                <DeleteForeverIcon
                                  className={icon}
                                  color="error"
                                  variant="contained"
                                  onClick={() => {
                                    // if (!row.alternateoffday_id) {
                                    handleDelete(idx);
                                    //   }
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </Box>

                <Box
                  sx={{
                    justifyContent: "flex-end",
                    display: "flex",
                    gap: "10px",
                    //   marginTop: "20px",
                  }}
                  spacing={2}
                >
                  <>
                    <Button
                      className={btns}
                      variant="contained"
                      color="error"
                      onClick={() => {
                        setShowForm(false);
                        setisEdited(false);
                      }}
                    >
                      Cancel
                    </Button>

                    {isSubmitting ? (
                      <CircularProgress />
                    ) : isEdited ? (
                      <Button
                        type="submit"
                        className={btns}
                        variant="contained"
                        color="primary"
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        className={btns}
                        variant="contained"
                        color="primary"
                      >
                        Save
                      </Button>
                    )}
                  </>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default ShiftRulesForm;
ShiftRulesForm.propTypes = {
  setShowForm: PropTypes.any,
  isEdited: PropTypes.any,
  setisEdited: PropTypes.any,
  shiftRowData: PropTypes.any,
};
