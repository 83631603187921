import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  jobTitle: [],
  department: [],
  technologies :[],
  experties:[],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,

};

export const fetchJobTitle = createAsyncThunk(
  "getjobtitles",
  async () => {
    const response = await axiosInstance.get(`getjobtitles`);
    return response.data;
  }
);

export const jobTitlePopulate = createAsyncThunk(
  "jobtitlepopulate",
  async () => {
    const response = await axiosInstance.get(`jobtitlepopulate`);
    return response.data;
  }
);

const JobTitleSlice = createSlice({
  name: "jobTitle",
  initialState,
  reducers: {
    JobTitleAdded: {
      reducer(state, action) {
        state.jobTitle.push(action.payload);
      },
    },
    deleteJobtitle: {
      reducer(state, action) {
        const userIndex = state?.jobTitle.findIndex(
          (jobTitle) => jobTitle.jobTitle_id === action.payload
        );
        if (userIndex !== -1) {
          state.jobTitle.splice(userIndex, 1);
        }
      },
    },
    JobTitleResetData: (state) => {
      state.jobTitle= [];
      state.department= [];
      state.technologies =[];
      state.experties=[];
      state.isSuccess= false;
      state.message= null;
      state.status= "idle"; //'idle' | 'loading' | 'succeeded' | 'failed'
      state.error= null;
      state.IsLogin= true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchJobTitle.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchJobTitle.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.jobTitle = action.payload.Jobtitles;
      })
      .addCase(fetchJobTitle.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {

          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(jobTitlePopulate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(jobTitlePopulate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.department = action.payload.Department;
        state.technologies = action.payload.Technolgies;
        state.experties = action.payload.Expertise;
      })
      .addCase(jobTitlePopulate.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {

          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })

  },
});

export const getJobTitleDetails = (state) => state.jobTitle;

export const { JobTitleAdded,JobTitleResetData,deleteJobtitle } = JobTitleSlice.actions;

export default JobTitleSlice.reducer;