import Cookies from "universal-cookie";
import axiosInstance from "../../Utils/AxiosInstance";
const cookies = new Cookies();

export const LoginAPI = async (data) => {
  let snackBarData = {};

  try {
    let response = await axiosInstance.post("loginuser", data);

    console.log("trye");
    if (
      response.data.IsSuccess &&
      response?.data?.Data?.isverifiedemail === false
    ) {
      console.log("hello");

      cookies.set(
        "user",
        { ...response.data.Data, roletype: [] },
        {
          path: "/",
        }
      );
      snackBarData = {
        type: "success",
        message: response?.data?.Message,
        openToast: true,
      };
      return {
        ...response,
        snackBarData,
        navigate: "/login/emailvarification",
      };
    } else if (
      response.data.IsSuccess &&
      response?.data?.Data?.isverifiedemail &&
      response?.data?.Data?.ispasswordchange === false
    ) {
      console.log("hello");
      cookies.set(
        "user",
        { ...response.data.Data, roletype: [] },
        {
          path: "/",
        }
      );
      snackBarData = {
        type: "success",
        message: "please change your password.!",
        openToast: true,
      };
      return { ...response, snackBarData, navigate: "/changepassword" };
    } else if (
      response.data.IsSuccess &&
      response?.data?.Data?.ispasswordchange &&
      response?.data?.Data?.isverifiedemail
    ) {
      console.log("hello");
      console.log(response?.data?.Data);
      cookies.set("access_token", response.data.acces_token, {
        path: "/",
      });
      localStorage.setItem(
        "roles",
        response.data.Data?.roletype
          ? JSON.stringify(response.data.Data?.roletype)
          : JSON.stringify([])
      );
      cookies.set(
        "user",
        {
          ...response.data.Data,
          roletype: [],
        },
        {
          path: "/",
        }
      );
      snackBarData = {
        type: "success",
        message: response.data.Message,
        openToast: true,
      };
      return { ...response, snackBarData, navigate: "/dashboard" };
    } else {
      snackBarData = {
        type: "error",
        message: response?.data?.Message,
        openToast: true,
      };
      return { ...response, snackBarData };
    }
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response.data.Message,
      openToast: true,
    };
    return { ...error, snackBarData };
  }
};
