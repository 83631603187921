import { Box, CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext, useEffect } from "react";

import "react-circular-progressbar/dist/styles.css";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAllLeaveApplications,
  getDashboardActivities,
} from "../../../Redux/Slice/Dashboard/DashboardSlice";
import { PiGraph } from "../Graph/PiGraph";
import { DonatGraph } from "../Graph/DonatGraph";
import { logout } from "../../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";

const useStyle = makeStyles((theme) => {
  return {
    container: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      gap: "20px",
      borderRadius: "20px",
      color: theme.palette.white.main,
      transition: "0.5s",
    },
    contentContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      overflow: "hidden",
      gap: "20px",
      flexWrap: "wrap",
      color: theme.palette.grey[700],
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    ChartContainer: {
      flex: 1,
      display: "flex",
      minWidth: "225px",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "10px",
      // gap: "20px",
      position: "relative",
      // padding: "20px",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
      transition: "0.5s",
      "&:hover": {
        transform: "scale(1.02)",
      },
    },
  };
});

const ActiveInactiveCard = ({ background }) => {
  const navigate = useNavigate();
  const { container, contentContainer, ChartContainer } = useStyle();
  const { setsnackBarData } = useContext(SnackBarContext);
  const {
    allleaves,
    dashboarddata,
    IsLogin,
    message,
    status,
    allleavesstatus,
    dashboarddatastatus,
  } = useSelector(getDashboardActivities);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetAllLeaveApplications());
  }, [dispatch]);

  console.log(status);
  console.log("allleaves", allleaves);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);

  return (
    <Box className={container} sx={{ background: background }}>
      <Box className={contentContainer}>
        <Box className={ChartContainer}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Leave Applications
          </Typography>
          {allleavesstatus !== "succeeded" ? (
            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {Object.keys(allleaves).length > 0 ? (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PiGraph allleaves={allleaves} />
                </Box>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    height: "60%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src="./NoData.png"
                    alt="No Data Found "
                    style={{ height: "100%", width: "95%" }}
                  />
                </Box>
              )}
            </>
          )}
        </Box>
        <Box className={ChartContainer}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Attendance Details
          </Typography>
          {dashboarddatastatus !== "succeeded" ? (
            <Box
              sx={{
                height: "300px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {dashboarddata ? (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <DonatGraph dashboarddata={dashboarddata} />
                </Box>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src="./NoData.png"
                    alt="No Data Found "
                    style={{ height: "100%", width: "95%" }}
                  />
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ActiveInactiveCard;
ActiveInactiveCard.propTypes = {
  title: PropTypes.any,
  background: PropTypes.any,
  icon: PropTypes.any,
  count: PropTypes.any,
};
