import {
  Box,
  Button,
  CircularProgress,
  gridClasses,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import {
  fetchMonthalyDetailed,
  getEarlyDepartureReportDetails,
} from "../../Redux/Slice/EarlyDepartureReport/EarlyDepartureReportSlice";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ReasonEarlyArrivalReportApi } from "../../Api/EarlyDepartureReport/ReasonEarlyDepartureReportApi";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});
function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport csvOptions={{ fileName: "EarlyDepartureReport" }} />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  );
}
function EmployeeEaryDepartureReport() {
  const navigate = useNavigate();
  const { header } = useStyles();
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();
  const [rows, setrows] = useState([]);
  const [months, setmonths] = useState("");
  const cookies = new Cookies();
  const { MonthlyDetailed, status, IsLogin, message } = useSelector(
    getEarlyDepartureReportDetails
  );
  const [isEdit, setisEdit] = useState(true);
  const [loading, setloading] = useState(false);
  const [monthlyDetailed, setmonthlyDetailed] = useState([]);
  const { setsnackBarData } = useContext(SnackBarContext);
  const Date = useRef();
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  console.log(rows);
  useEffect(() => {
    let arr = [];

    MonthlyDetailed &&
      MonthlyDetailed?.forEach((item, idx) => {
        return arr.push({
          ...item,
          id: idx + 1,
          sr: idx + 1,
          date: item?.date ? moment(item?.date).format("DD-MMM-YYYY") : "",
          time: item?.time ? moment(item?.time, "HH:mm").format("LT") : "",
          early_time: item?.early_time
            ? parseInt(item?.early_time.split(":")[0]) === 0 &&
              parseInt(item?.early_time.split(":")[1]) === 0
              ? 0
              : `${item?.early_time.split(":")[0]} hrs ${
                  item?.early_time.split(":")[1]
                } min`
            : "",
          attendance_id: item?.attendance_id,
        });
      });

    setrows(arr);
    setmonthlyDetailed(arr);
  }, [MonthlyDetailed]);
  const handleChange = (e, cellval) => {
    console.log(e);
    console.log(cellval);
    monthlyDetailed[cellval.row.id - 1].reason = e.target.value;
    monthlyDetailed[cellval.row.id - 1].edit = true;
    setisEdit(false);
  };
  const columns = [
    { field: "sr", headerName: "Sr #", width: 100, headerClassName: header },
    {
      field: "date",
      headerName: "Date",
      width: 200,
      headerClassName: header,
    },
    {
      field: "time",
      headerName: "Check Out",
      width: 200,
      headerClassName: header,
    },
    {
      field: "early_time",
      headerName: "Early Time",
      width: 150,
      headerClassName: header,
    },
    {
      field: "comment",
      headerName: "Comment",
      width: 250,
      headerClassName: header,
      renderCell: (cellval) => {
        return cellval?.row?.reason && !cellval?.row?.edit ? (
          <Typography>{cellval?.row?.reason?.reason}</Typography>
        ) : (
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              width: "100%",
              alignItems: "center",
            }}
          >
            <TextField
              name="coment"
              value={cellval?.row?.comments}
              fullWidth
              variant="outlined"
              size="small"
              onChange={(e) => {
                handleChange(e, cellval);
              }}
            />
          </Box>
        );
      },
    },
  ];
  const handleSubmit = async () => {
    if (months === "") {
      return setsnackBarData({
        openToast: true,
        message: "please select date",
        type: "error",
      });
    }
    // const obj = { ...values, country: value?.label };
    dispatch(
      fetchMonthalyDetailed({
        emp_id: cookies.get("user")?.Emp_id,
        months: months,
      })
    );
  };
  const handleComment = async () => {
    setloading(true);
    let arr = [];
    monthlyDetailed?.map((item) => {
      if (item?.edit === true) {
        arr.push({ reason: item?.reason, attendance_id: item?.attendance_id });
      }
    });
    const response = await ReasonEarlyArrivalReportApi({ reasons: arr });
    setsnackBarData(response.snackBarData);
    console.log(response);
    if (response.data.IsSuccess) {
      setloading(false);
      dispatch(
        fetchMonthalyDetailed({
          emp_id: cookies.get("user")?.Emp_id,
          months: months,
        })
      );
    }
    setloading(false);
  };
  const onTextChange = () => {
    if (Date.current.value === "") {
      setmonthlyDetailed(rows);
    } else {
      let suggestions = [];
      let regex2 = new RegExp(
        moment(Date.current.value).format("DD-MMM-YYYY"),
        "i"
      );

      suggestions = rows?.filter(
        (val) => Date.current?.value === "" || regex2.test(val?.date)
      );
      setmonthlyDetailed(suggestions);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            paddingBottom: "10px",
            width: "100%",
            padding: "10px 10px 0px 10px",
            alignItems: "center",
          }}
        >
          <TextField
            type={"month"}
            name="months"
            // variant="outlined"
            size="small"
            value={months}
            onChange={(e) => {
              setmonths(e.target.value);
            }}
          />

          <Button
            onClick={handleSubmit}
            sx={{ padding: "5px 10px" }}
            variant="contained"
          >
            Get
          </Button>
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              disabled={isEdit}
              onClick={handleComment}
              variant="contained"
              sx={{ padding: "5px 10px", textAlign: "center" }}
            >
              Update
            </Button>
          )}
        </Box>
        <Box sx={{ padding: "10px 10px 0px 0px" }}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: (theme) => theme.palette.grey.G2 }}
                  />
                </InputAdornment>
              ),
            }}
            variant="standard"
            type="date"
            autoComplete="off"
            placeholder="Search"
            // className="input_border"
            inputRef={Date}
            id="date"
            onChange={onTextChange}
          />
        </Box>
      </Box>
      {status === "loading" ? (
        <Box
          sx={{
            height: "calc(100vh - 224px)",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div
          style={{
            height: "calc(100vh - 220px)",
            width: "100%",
            padding: "10px",
          }}
        >
          <DataGrid
            rows={monthlyDetailed}
            columns={columns}
            onRowClick={() => {}}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            pagination
            components={{
              Toolbar: CustomToolbar,
            }}
            localeText={{
              toolbarDensity: "Size",
              toolbarDensityLabel: "Size",
              toolbarDensityCompact: "Small",
              toolbarDensityStandard: "Medium",
              toolbarDensityComfortable: "Large",
            }}
          />
        </div>
      )}
    </>
  );
}

export default EmployeeEaryDepartureReport;
