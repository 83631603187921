import {
  Box,
  // Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  //  Typography
} from "@mui/material";
import React, { Component } from "react";
import PrintHeader from "../Print/PrintHeader/PrintHeader";
import PropTypes from "prop-types";
// import Cookies from "universal-cookie";
import PrintReportSetion from "../Print/PrintReportSection/PrintReportSetion";
class MonthlyReportPrint extends Component {
  render() {
    // const cookies = new Cookies();
    return (
      <Box sx={{ width: "100%", padding: "10px" }}>
        <PrintHeader />
        <PrintReportSetion
          Reportname={"Monthly Report"}
          Months={this.props.month}
          userName={this.props.name}
        />
        <Box>
          <TableContainer component={Paper} sx={{ overflowY: "scroll" }}>
            <Table
              sx={{
                minWidth: { md: "auto", xs: "auto" },
                overflow: "scroll",
              }}
              aria-label="simple table"
              size="small"
            >
              <TableHead>
                <TableRow size="small">
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      // color: (theme) => theme.palette.grey["500"],
                    }}
                  >
                    Sr #
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      // color: (theme) => theme.palette.grey["500"],
                    }}
                  >
                    Month
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      // color: (theme) => theme.palette.grey["500"],
                    }}
                  >
                    Employee
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      // color: (theme) => theme.palette.grey["500"],
                    }}
                  >
                    Expected Hours
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Estimated Work
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Logged Work
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      // color: (theme) => theme.palette.grey["500"],
                    }}
                  >
                    OT Hours
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      // color: (theme) => theme.palette.grey["500"],
                    }}
                  >
                    Working Hours
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      // color: (theme) => theme.palette.grey["500"],
                    }}
                  >
                    Absents
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Casual leaves
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Annual leave
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.filterRows?.map((item, i) => {
                  return (
                    <TableRow
                      key={i}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell size="small" align="left">
                        {item?.id}
                      </TableCell>
                      <TableCell size="small" align="left">
                        {item?.date}
                      </TableCell>
                      <TableCell size="small" align="left">
                        {item?.Employee}
                      </TableCell>
                      <TableCell size="small" align="left">
                        {item?.Expected_hours}
                      </TableCell>
                      <TableCell size="small" align="left">
                        {item?.Estimated_work}
                      </TableCell>
                      <TableCell size="small" align="left">
                        {item?.Logged_work}
                      </TableCell>
                      <TableCell size="small" align="left">
                        {item?.OT_Hours}
                      </TableCell>
                      <TableCell size="small" align="left">
                        {item?.workinghrs}
                      </TableCell>
                      <TableCell size="small" align="left">
                        {item?.absent}
                      </TableCell>
                      <TableCell size="small" align="left">
                        {item?.clleave}
                      </TableCell>
                      <TableCell size="small" align="left">
                        {item?.alleave}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {/* <Box sx={{ width: "100%", display: "flex", justifyContent: "end", paddingTop: "20px" }}>
                    <Box sx={{
                        display: "flex", flexDirection: "column", width: "25%", gap: "10px"
                    }}>
                        <Box sx={{
                            display: "flex", justifyContent: "space-between", gap: "20px"
                        }}>
                            <Typography sx={{ fontWeight: "bold" }}> Sub Total</Typography>
                            <Typography sx={{ fontWeight: "bold" }}> 60986</Typography>
                        </Box>
                        <Divider sx={{ width: "100%" }} />
                        <Box sx={{
                            display: "flex", justifyContent: "space-between", gap: "20px"
                        }} >
                            <Typography sx={{ fontWeight: "bold" }}> Taxes</Typography>
                            <Typography sx={{ fontWeight: "bold" }}> 60986</Typography>
                        </Box>
                        <Divider sx={{ width: "100%" }} />
                        <Box sx={{
                            display: "flex", justifyContent: "space-between", gap: "20px"
                        }}>
                            <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}> Total</Typography>
                            <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}> 60986</Typography>
                        </Box>
                    </Box>
                </Box> */}
        {/* <Divider sx={{ width: "100%", paddingTop: "10px", marginBottom: "10px" }} /> */}
      </Box>
    );
  }
}

export default MonthlyReportPrint;
MonthlyReportPrint.propTypes = {
  filterRows: PropTypes.any,
  month: PropTypes.any,
  name: PropTypes.any,
};
