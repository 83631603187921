import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  // CircularProgress,
  Grid,
  gridClasses,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useRef, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  fetchCpl,
  fetchsingleEmpCpl,
  getcplDetails,
} from "../../Redux/Slice/CPL/CPLSlice";

import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import Cookies from "universal-cookie";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { Navigate, useNavigate } from "react-router-dom";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import PrintCPL from "./PrintCPL";
import { useReactToPrint } from "react-to-print";
import Loader from "../Loader/Loader";
import { useFormik } from "formik";
import * as Yup from "yup";
import theme from "../../theme";
import { UpdateCplApi } from "../../Api/CPL/UpdateCplApi";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
    },
    Container: {
      width: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    container: {
      // height: "100%",
      width: "100%",
      padding: "0px 20px 20px 20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      // padding: "20px",
      width: "100%",
      // maxWidth: "700px",
      // minHeight: "calc(100vh - 215px)",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      textAlign: "left",
    },
  };
});
const validationSchema = Yup.object().shape({
  casual_leaves: Yup.string().required("Please Enter Casual Leaves"),
  annual_leaves: Yup.string().required("Please Enter Annual Leaves"),
});
const initialValues = {
  casual_leaves: "",
  annual_leaves: "",
};
function CPLTable() {
  const { tabelStyle } = useContext(TabelStyleContext);
  const {
    header,
    ModalContainer,
    Container,
    TableHeader,
    subContainer,
    container,
    formContainer,
  } = useStyles();
  const dispatch = useDispatch();
  const [rows, setrows] = useState([]);
  const [filterRows, setfilterRows] = useState([]);
  const [pageSize, setPageSize] = useState(50);
  const [selectedYear, setselectedYear] = useState("");
  const { setsnackBarData } = useContext(SnackBarContext);
  const navigate = useNavigate();
  const [Cpl, setCpl] = useState(null);
  const [Years, setYears] = useState([]);
  const [editPermit, seteditpermit] = useState(false);
  const { cpl, status, IsLogin, message } = useSelector(getcplDetails);
  const cookies = new Cookies();
  const user = cookies.get("user");
  const [canView, setcanView] = useState(null);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [showModal, setshowModal] = useState(false);
  const [EditedData, setEditedData] = useState(null);
  console.log(EditedData);
  useEffect(() => {
    if (EditedData) {
      formik.setFieldValue("casual_leaves", EditedData?.cl_leaves);
      formik.setFieldValue("annual_leaves", EditedData?.annual_leaves);
    }
  }, [EditedData]);
  const handleSave = async (values, setSubmitting) => {
    let obj = {
      casual_leaves: Number(values.casual_leaves),
      annual_leaves: Number(values.annual_leaves),
      cpl_id: EditedData?.id,
    };
    const response = await UpdateCplApi(obj);

    setsnackBarData(response.snackBarData);
    if (response.data.IsSuccess) {
      dispatch(fetchCpl(selectedYear));
      setSubmitting(false);
      setshowModal(false);
    } else {
      setSubmitting(false);
    }
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSave(values, resetForm, setSubmitting);
    },
  });
  console.log(formik.values);
  const componentRef = useRef();
  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });
  console.log(selectedYear);
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const startYear = 1960;
    const yearRange = Array.from(
      { length: currentYear - startYear + 1 },
      (_, index) => startYear + index
    );

    setYears(yearRange.reverse());
  }, []);
  const handleClick = () => {
    if (cookies.get("user")?.type === "Employee") {
      dispatch(
        fetchCpl({
          year: selectedYear ? selectedYear : "",
          emp_id: cookies.get("user")?.Emp_id,
        })
      );
    } else if (cookies.get("user")?.type === "Admin") {
      if (selectedYear) {
        dispatch(fetchCpl({ year: selectedYear ? selectedYear : "" }));
      }
    }
  };
  console.log(roles);
  useEffect(() => {
    let string = "CPL";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    let match = value?.some((val) => {
      if (val?.module?.split("_")[1] === "View") {
        return true;
      }
    });
    let match2 = value?.some((val) => {
      if (val?.module?.split("_")[1] === "Edit") {
        return true;
      }
    });
    seteditpermit(match2);
    setcanView(match);
    console.log(match);
    if (!roles || roles.length < 1) {
      setcanView(false);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  useEffect(() => {
    console.log(user);
    if (cookies.get("user")?.type === "Employee") {
      dispatch(fetchsingleEmpCpl(cookies.get("user").Emp_id));
    } else if (cookies.get("user")?.type === "Admin") {
      if (selectedYear) {
        dispatch(fetchCpl({ year: selectedYear ? selectedYear : "" }));
      }
    }
  }, [dispatch]);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
    if (cpl?.length >= 0) {
      setCpl(cpl);
    } else if (cpl) {
      setCpl([cpl]);
    } else {
      setCpl(null);
    }
  }, [cpl, IsLogin]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "CPL" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "sr", headerName: "Sr #", width: 100, headerClassName: header },
    {
      field: "emp",
      headerName: "Employee",
      width: 200,
      headerClassName: header,
    },
    {
      field: "cl_leaves",
      headerName: "Casual Leaves",
      width: 150,
      headerClassName: header,
    },
    {
      field: "annual_leaves",
      headerName: "Annual Leaves",
      width: 150,
      headerClassName: header,
    },
    { field: "year", headerName: "Year", width: 150, headerClassName: header },
    {
      field: "Actions",
      headerName: "Actions",
      width: 200,
      headerClassName: header,
      renderCell: (celVal) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              gap: "10px",
            }}
          >
            {editPermit ? (
              <IconButton
                size={"small"}
                onClick={() => {
                  setEditedData(celVal.row);
                  setshowModal(true);
                }}
              >
                <AppRegistrationIcon
                  sx={{ cursor: "pointer", color: "black !important" }}
                />
              </IconButton>
            ) : null}
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    let arr = [];

    Cpl &&
      Cpl.forEach((item, idx) => {
        return arr.push({
          sr: idx + 1,
          id: item?.cpl_id,
          emp: item?.emp_id?.name,
          cl_leaves: item?.available_CL,
          year: item?.year,
          annual_leaves: item?.available_AL,
        });
      });

    setrows(arr);
    setfilterRows(arr);
  }, [Cpl]);

  const onTextChange = () => {
    if (document.getElementById("ProjectNameFilter").value === "") {
      setfilterRows(rows);
    } else {
      let suggestions = [];

      var regex1 = new RegExp(
        document.getElementById("ProjectNameFilter").value,
        "i"
      );

      suggestions = rows?.filter(
        (val) =>
          document.getElementById("ProjectNameFilter").value === "" ||
          regex1.test(val.emp)
      );

      setfilterRows(suggestions);
    }
  };
  useEffect(() => {
    if (status === "failed") {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
    }
  }, [status]);

  return (
    <>
      {canView === true ? (
        <>
          <Box sx={{ display: "none" }}>
            <PrintCPL ref={componentRef} Cpl={Cpl} />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              padding: "20px 10px 10px 10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <Autocomplete
                disablePortal
                options={Years}
                getOptionLabel={(option) => option}
                sx={{
                  input: { color: "black" },
                  width: "200px",
                  maxWidth: "250px",
                  textAlign: "left",
                }}
                // className={Form_input}
                fullWidth
                value={selectedYear}
                size="small"
                name="months"
                onChange={(e, val) => {
                  console.log(e, val);
                  setselectedYear(val);
                  // formik.handleChange({
                  //   target: {
                  //     name: "months",
                  //     value: val,
                  //   },
                  // });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Year"
                    // error={
                    //   Boolean(formik.errors.months) &&
                    //   Boolean(formik.touched.months)
                    // }
                    // helperText={
                    //   Boolean(formik.touched.months) &&
                    //   Boolean(formik.errors.months) &&
                    //   formik.errors.months
                    // }
                  />
                )}
              />
              {/* <TextField
                size="small"
                type="month"
                variant="outlined"
                color="primary"
                fullWidth
                sx={{
                  input: { color: "black" },
                  maxWidth: "250px",
                  textAlign: "left",
                }}
                value={selectedYear}
                inputProps={{
                  max: moment().format("YYYY-MM"),
                }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setselectedYear(e.target.value);
                }}
              /> */}
              {status == "loading" ? (
                <CircularProgress />
              ) : (
                <Button
                  onClick={handleClick}
                  type="submit"
                  sx={{ padding: "5px 10px" }}
                  variant="contained"
                >
                  Get
                </Button>
              )}
              <Box>
                <Button
                  onClick={() => {
                    printPaymentRec();
                  }}
                  sx={{ padding: "5px 10px" }}
                  variant="contained"
                >
                  Print
                </Button>
              </Box>
            </Box>

            <Box>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        sx={{ color: (theme) => theme.palette.grey.G2 }}
                      />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width: "100%",
                  maxWidth: "180px",
                }}
                variant="standard"
                type="text"
                autoComplete="off"
                placeholder="Employee"
                className="input_border"
                id="ProjectNameFilter"
                onChange={onTextChange}
              />
            </Box>
          </Box>

          <span className="ml-2 float-right">
            <div className="loaderclass"> </div>
          </span>

          {cpl?.length <= 0 && status === "loading" ? <Loader /> : null}
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            overflow
            sx={{ height: tabelStyle.height, padding: "10px" }}
          >
            <DataGrid
              rows={filterRows}
              columns={columns}
              onRowClick={() => {}}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
              pagination
              components={{
                Toolbar: CustomToolbar,
              }}
              localeText={{
                toolbarDensity: "Size",
                toolbarDensityLabel: "Size",
                toolbarDensityCompact: "Small",
                toolbarDensityStandard: "Medium",
                toolbarDensityComfortable: "Large",
              }}
            />
          </Grid>
        </>
      ) : canView === false ? (
        <Navigate to={"/dashboard"} />
      ) : (
        <Box>Loading ...</Box>
      )}
      {showModal && (
        <>
          <Modal
            open={showModal}
            onClose={() => {
              setEditedData(null);
              setshowModal(false);
            }}
          >
            <Box className={ModalContainer}>
              <Box className={Container}>
                <Box className={TableHeader}>
                  <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                    Update CPL
                  </Typography>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditedData(null);
                      setshowModal(false);
                    }}
                  >
                    <HighlightOffIcon
                      color="primary"
                      sx={{
                        fontSize: "28px",
                      }}
                    />
                  </IconButton>
                </Box>
                <Box className={container}>
                  <Box className={subContainer}>
                    <form onSubmit={formik.handleSubmit}>
                      <Box className={formContainer}>
                        <FormControl fullWidth>
                          <Typography>Employee</Typography>
                          <TextField
                            sx={{
                              textAlign: "left",
                              input: { color: "black" },
                            }}
                            fullWidth
                            size="small"
                            type="text"
                            value={EditedData?.emp}
                            variant="outlined"
                            disabled
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <Typography>Casual Leaves</Typography>
                          <TextField
                            sx={{
                              textAlign: "left",
                              input: { color: "black" },
                            }}
                            fullWidth
                            size="small"
                            name="casual_leaves"
                            type="number"
                            onBlur={formik.handleBlur}
                            onChange={(e) => {
                              if (e.target.value < 0) {
                                return;
                              } else {
                                formik.setFieldValue(
                                  "casual_leaves",
                                  e.target.value
                                );
                              }
                            }}
                            value={formik.values.casual_leaves}
                            variant="outlined"
                            error={
                              Boolean(formik.touched.casual_leaves) &&
                              Boolean(formik.errors.casual_leaves)
                            }
                            helperText={
                              Boolean(formik.touched.casual_leaves) &&
                              formik.errors.casual_leaves
                            }
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <Typography>Annual Leaves</Typography>
                          <TextField
                            sx={{
                              textAlign: "left",
                              input: { color: "black" },
                            }}
                            fullWidth
                            size="small"
                            name="annual_leaves"
                            type="number"
                            onBlur={formik.handleBlur}
                            onChange={(e) => {
                              if (e.target.value < 0) {
                                return;
                              } else {
                                formik.setFieldValue(
                                  "annual_leaves",
                                  e.target.value
                                );
                              }
                            }}
                            value={formik.values.annual_leaves}
                            variant="outlined"
                            error={
                              Boolean(formik.touched.annual_leaves) &&
                              Boolean(formik.errors.annual_leaves)
                            }
                            helperText={
                              Boolean(formik.touched.annual_leaves) &&
                              formik.errors.annual_leaves
                            }
                          />
                        </FormControl>
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "end",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "10px",
                            }}
                          >
                            <Box>
                              {formik.isSubmitting ? (
                                <CircularProgress />
                              ) : (
                                <Button type="submit" variant="contained">
                                  <Typography>Update</Typography>
                                </Button>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </form>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}

export default CPLTable;
