import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
  // Button,
  // CircularProgress,
  gridClasses,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchProjectSucbscriptions,
  getSubScriptionDetails,
} from "../../Redux/Slice/ProjectSubscriptionSlice/ProjectSubScriptionSlice";
import { useState } from "react";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import SearchIcon from "@mui/icons-material/Search";
import { useRef } from "react";
import { makeStyles } from "@mui/styles";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../Button/AddNewButton";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import Loader from "../Loader/Loader";
const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});
const ProjectSubscriptionTable = ({ setShowForm, setEditRow, setisEdited }) => {
  const { tabelStyle } = useContext(TabelStyleContext);
  const { header } = useStyles();
  const dispatch = useDispatch();
  const { ProjectSubscription, status, IsLogin, message } = useSelector(
    getSubScriptionDetails
  );
  const [allProjectSubscription, setProjectSubscription] = useState([]);
  const [rows, setrows] = useState([]);
  const ProjectName = useRef();
  const navigate = useNavigate();
  const { setsnackBarData } = useContext(SnackBarContext);
  const SubscriptionType = useRef();

  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [editPermit, seteditpermit] = useState(false);
  const [addPermit, setaddpermit] = useState(false);
  // console.log(crop);

  console.log(roles);
  useEffect(() => {
    let string = "ProjectSubscription";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Edit") {
        seteditpermit(true);
      } else if (val?.module?.split("_")[1] === "Add") {
        setaddpermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      setaddpermit(true);
      seteditpermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  console.log(editPermit);
  console.log(addPermit);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport
          csvOptions={{ fileName: "Projects Subscription " }}
        />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
        {/* <GridCsvExportOptions fileName="jhdjwdhwjh" /> */}
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "id", headerName: "Sr #", width: 100, headerClassName: header },
    {
      field: "ProjectName",
      headerName: "Project Name",
      width: 200,
      headerClassName: header,
    },
    {
      field: "subscription_type",
      headerName: "Subscription Type",
      width: 200,
      headerClassName: header,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      headerClassName: header,
    },
    {
      field: "margin_days",
      headerName: "Margin Day",
      width: 150,
      headerClassName: header,
    },

    {
      field: "Update",
      headerName: "Actions",
      width: 100,
      headerClassName: header,
      renderCell: (celVal) => {
        return (
          <>
            {editPermit ? (
              <Tooltip title="Update Project Subscription">
                <AppRegistrationIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => editData(celVal)}
                />
              </Tooltip>
            ) : null}
          </>
        );
      },
    },
  ];
  const editData = (celVal) => {
    setEditRow(celVal.row);
    setShowForm(true);
    setisEdited(true);
  };
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  useEffect(() => {
    let arr = [];
    ProjectSubscription?.forEach((sub, idx) => {
      return arr.push({
        id: idx + 1,
        ProjectName: sub?.project?.project_name,
        subscription_type: sub?.subscription_type,
        Project_id: sub?.project?.project_id,
        margin_days: sub?.margin_days,
        subscription_id: sub?.subscription_id,
        status: sub?.status,
        amount: sub?.amount,
      });
    });

    setrows(arr);
    setProjectSubscription(arr);
  }, [ProjectSubscription]);
  useEffect(() => {
    dispatch(fetchProjectSucbscriptions());
  }, [dispatch]);

  const abc = () => {
    setShowForm(true);
  };

  const onTextChange = () => {
    if (
      ProjectName.current.value === "" &&
      SubscriptionType.current.value === ""
    ) {
      setProjectSubscription(rows);
    } else {
      let suggestions = [];

      let regex1 = new RegExp(ProjectName.current.value, "i");
      let regex2 = new RegExp(SubscriptionType.current.value, "i");

      suggestions = rows?.filter(
        (val) =>
          (ProjectName.current.value === "" || regex1.test(val.ProjectName)) &&
          (SubscriptionType.current.value === "" ||
            regex2.test(val?.subscription_type))
      );

      setProjectSubscription(suggestions);
    }
  };
  useEffect(() => {
    if (status === "failed") {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
    }
  }, [status]);

  return (
    <Box spacing={2} sx={{ padding: "10px 0px" }}>
      <Box
        sx={{
          padding: "10px",
          display: "flex",
          justifyContent: "space-between",
          gap: "20px",
          width: "100%",
        }}
      >
        {addPermit ? (
          <>
            {/* <Button
              sx={{ padding: "5px 10px" }}
              variant="contained"
              onClick={abc}
            >
              New
            </Button> */}
            <CustomButton
              Color={"primary"}
              Icon={<AddIcon />}
              onClick={abc}
              Label={"Add"}
            />
          </>
        ) : null}
        <Box sx={{ display: "flex", gap: "20px" }}>
          <Box>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{ color: (theme) => theme.palette.grey.G2 }}
                    />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              type="text"
              autoComplete="off"
              placeholder="Name"
              className="input_border"
              inputRef={ProjectName}
              onChange={onTextChange}
            />
          </Box>
          <Box>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{ color: (theme) => theme.palette.grey.G2 }}
                    />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              type="text"
              autoComplete="off"
              placeholder="Subscription Type"
              className="input_border"
              inputRef={SubscriptionType}
              onChange={onTextChange}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ height: tabelStyle.height, padding: "10px" }}>
        {ProjectSubscription?.length<=0 && status === "loading" ? <Loader /> : null}
        <DataGrid
          rows={allProjectSubscription}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          components={{
            Toolbar: CustomToolbar,
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />
      </Box>
    </Box>
  );
};

export default ProjectSubscriptionTable;
ProjectSubscriptionTable.propTypes = {
  setShowForm: PropTypes.any,
  setEditRow: PropTypes.any,
  setisEdited: PropTypes.any,
};
