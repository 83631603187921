import {
  Box,
  Button,
  Dialog,
  // DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { object, string } from "yup";
import { posthiringStage } from "../../Api/Applicants/ApplicantAPI";
import CancelIcon from "@mui/icons-material/Cancel";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { getstagesData } from "../../Redux/Slice/Applicants/ApplicantSlice";
import { useDispatch } from "react-redux";

const initialValues = {
  title: "",
  comment: "",
  discription: "",
};

const validationSchema = object({
  title: string().required("This field is required"),
  discription: string().required("This field is required"),
  comment: string().required("This field is required"),
});
const Modal = ({ onClose }) => {
  const { setsnackBarData } = useContext(SnackBarContext);
  const [loading,setloading] = useState(false);
const dispatch=useDispatch();
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values, resetForm);
      console.log(values);
    },
  });
  const handleSubmit = async (values, resetForm) => {
    setloading(true)
    const res = await posthiringStage(values);
    if (res?.data?.IsSuccess) {
      setsnackBarData(res.snackBarData);
    setloading(false)
    onClose()
    dispatch(getstagesData())
    resetForm();
    }else{
      setsnackBarData(res.snackBarData);
      setloading(false)
    }
  };
  return (
    <>
      <Dialog open={true} onClose={onClose} maxWidth="xs" fullWidth>
        <DialogTitle sx={{ color: "#000000",}}>
          <strong>Add Stages</strong>
          <IconButton
            sx={{ position: "absolute", top: "10px", right: "10px" }}
            onClick={onClose}
          >
            <CancelIcon color="error" sx={{ fontSize: "25px" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={formik?.handleSubmit}>
            <Box
              sx={{ display: "flex", flexDirection:"column",gap:"10px"}}
            >
              <Box>
                <Typography>Title</Typography>
                <FormControl fullWidth>
                  <TextField
                    variant="outlined"
                    sx={{ width: "400px" }}
                    size="small"
                    name="title"
                    onChange={(e) => {
                      formik?.handleChange(e);
                    }}
                    value={formik?.values.title}
                    onBlur={formik.handleBlur}
                    error={
                      Boolean(formik?.errors.title) &&
                      Boolean(formik?.touched.title)
                    }
                    helperText={
                      Boolean(formik?.touched.title) && formik?.errors.title
                    }
                  />
                </FormControl>
              </Box>
              <Box>
                <Typography>Comment</Typography>
                <FormControl>
                  <TextField
                    variant="outlined"
                    sx={{ width: "400px" }}
                    name="comment"
                    size="small"

                    onChange={(e) => {
                      formik?.handleChange(e);
                    }}
                    value={formik?.values.comment}
                    onBlur={formik.handleBlur}
                    error={
                      Boolean(formik?.errors.comment) &&
                      Boolean(formik?.touched.comment)
                    }
                    helperText={
                      Boolean(formik?.touched.comment) && formik?.errors.comment
                    }
                  />
                </FormControl>
              </Box>
         
            <Box sx={{ }}>
              <Typography>Discription</Typography>
              <FormControl>
                <TextField
                    size="small"

                  variant="outlined"
                  sx={{ width: "400px" }}
                  name="discription"
                  onChange={(e) => {
                    formik?.handleChange(e);
                  }}
                  value={formik?.values.discription}
                  onBlur={formik.handleBlur}
                  error={
                    Boolean(formik?.errors.discription) &&
                    Boolean(formik?.touched.discription)
                  }
                  helperText={
                    Boolean(formik?.touched.discription) &&
                    formik?.errors.discription
                  }
                />
              </FormControl>
            </Box>
            </Box>
            <Box sx={{ textAlign: "right", paddingTop:"20px"}}>
            {loading === false ? (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                // style={{ marginTop: "37px", width: "142px" }}

              >
                Add Stages
              </Button>
            ):(
              <CircularProgress />
            )
}
            </Box>
          </form>
        </DialogContent>
        {/* <DialogActions>
    
        </DialogActions> */}
      </Dialog>
    </>
  );
};
Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
export default Modal;
