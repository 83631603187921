import {
  Box,
  // Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
// import DashboardTable from "../../DashboardTable/DashboardTable";
import WeeklyChangeRequestGraph from "../WeeklyChangeRequestGraph/WeeklyChangeRequestGraph";
import { useDispatch } from "react-redux";
import { FetchPresentEmployeeData } from "../../../Redux/Slice/Dashboard/DashboardSlice";
import ActiveInactiveCard from "../Cards/ActiveInactiveCard";

const useStyle = makeStyles((theme) => {
  return {
    container: {
      width: "100%",
      display: "flex",
      
      gap: "20px",
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
        // flexDirection: "column",
      },
    },
    tableContainer: {
      // flex: 1,
      width: "100%",

      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
    detailContainer: {
      // flex: 1,
      width: "100%",
      padding: "20px",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
  };
});

const PresentEmployeeTableContainer = () => {
  const { container, detailContainer, tableContainer } = useStyle();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(FetchPresentEmployeeData());
  }, [dispatch]);
  return (
    <Box className={container}>
      <Box className={tableContainer}>
      <ActiveInactiveCard />
      </Box>
      <Box className={detailContainer}>
        <WeeklyChangeRequestGraph />
      </Box>
    </Box>
  );
};

export default PresentEmployeeTableContainer;
