import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  FormControl,
} from "@mui/material";

import PropTypes from "prop-types";
import theme from "../../theme";
import { makeStyles } from "@mui/styles";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";

import {
  AddAllowanceApi,
  UpdateAllowanceApi,
} from "../../Api/Allowance/AllowanceApis";
const useStyles = makeStyles(() => {
  return {
    Form_input: {
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,
    },

    input_typography: {
      textAlign: "left",
      fontSize: `15px !important`,
      marginTop: "10px !important",
    },

    form_main: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      padding: "60px 8%",
      minHeight: "calc(100vh - 173px)",
    },
    responsive: {
      maxWidth: "600px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },

    btns: {
      width: "80px",
    },
  };
});

const AllowancesForm = ({
  setShowForm,
  setIsEdit,
  setEditedData,
  IsEdit,
  EditedData,
}) => {
  const { form_main, responsive, Form_input, btns, input_typography } =
    useStyles();
  const [initialValues] = useState({
    allowance_type: "",
    allowance_id: "",
  });
  const { setsnackBarData } = useContext(SnackBarContext);
  const handelAdd = async (values, formikHelpers) => {
    let response;
    if (IsEdit) {
      response = await UpdateAllowanceApi(values);
    } else {
      response = await AddAllowanceApi(values);
    }
    setsnackBarData(response.snackBarData);

    if (response.data.IsSuccess) {
      formikHelpers.resetForm();
      setShowForm(false);
      setIsEdit(false);
      setEditedData(null);
    }
    formikHelpers.setSubmitting(false);
  };

  const Allowances = Yup.object({
    allowance_type: Yup.string().required("Required"),
  });

  return (
    <Box className={form_main}>
      <Box className={responsive}>
        <Formik
          initialValues={EditedData || initialValues}
          validationSchema={Allowances}
          onSubmit={(values, formikHelpers) => {
            handelAdd(values, formikHelpers);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            isSubmitting,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                <Typography className={input_typography}>
                  Allowance Type
                </Typography>

                <FormControl fullWidth sx={{ textAlign: "left" }}>
                  <Field
                    className={Form_input}
                    fullWidth
                    as={TextField}
                    size="small"
                    name="allowance_type"
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.allowance_type}
                    variant="outlined"
                    error={
                      Boolean(touched.allowance_type) &&
                      Boolean(errors.allowance_type)
                    }
                    helperText={
                      Boolean(touched.allowance_type) && errors.allowance_type
                    }
                  ></Field>
                </FormControl>

                <Box
                  sx={{
                    justifyContent: "flex-end",
                    display: "flex",
                    gap: "10px",
                    paddingTop: "20px",
                  }}
                >
                  <Button
                    className={btns}
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setEditedData(null);
                      setShowForm(false);
                      setIsEdit(false);
                    }}
                  >
                    Cancel
                  </Button>
                  {isSubmitting ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      type="submit"
                      className={btns}
                      variant="contained"
                      color="primary"
                      onClick={() => {}}
                    >
                      {IsEdit ? "Update" : "Save"}
                    </Button>
                  )}
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default AllowancesForm;
AllowancesForm.propTypes = {
  setShowForm: PropTypes.any,
  setIsEdit: PropTypes.func,
  setEditedData: PropTypes.func,
  IsEdit: PropTypes.any,
  EditedData: PropTypes.any,
};
