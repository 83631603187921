import { createContext, useState } from "react";
import PropTypes from "prop-types";

export const ProfileContext = createContext();

const ProfileContextProvider = (props) => {
  const [profileData, setprofileData] = useState({
    user_id: "",
    profile_image: "",
    email: "",
    username: "",
    // Emp_id: "",
    type: "",
  });

  return (
    <ProfileContext.Provider value={{ profileData, setprofileData }}>
      {props.children}
    </ProfileContext.Provider>
  );
};

export default ProfileContextProvider;

ProfileContextProvider.propTypes = {
  children: PropTypes.any,
};
