import { Box } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import TagForm from "../../Components/Tags/TagForm";
import TagsTable from "../../Components/Tags/TagsTable";

const Tags = () => {
  const [ShowForm, setShowForm] = useState(false);
  const [isEdited, setisEdited] = useState(false);
  const [selectedRow, setselectedRow] = useState(null);
  const [canView, setcanView] = useState(null);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  useEffect(() => {
    let string = "Tags";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    let match = value?.some((val) => {
      if (val?.module?.split("_")[1] === "View") {
        return true;
      }
    });
    setcanView(match);
    console.log(match);
    if (!roles || roles.length < 1) {
      setcanView(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);

  return (
    <Box>
      {canView === true ? (
        ShowForm ? (
          <TagForm
            ShowForm={ShowForm}
            setShowForm={setShowForm}
            setisEdited={setisEdited}
            isEdited={isEdited}
            selectedRow={selectedRow}
            setselectedRow={setselectedRow}
          />
        ) : (
          <TagsTable
            setisEdited={setisEdited}
            isEdited={isEdited}
            setShowForm={setShowForm}
            setselectedRow={setselectedRow}
          />
        )
      ) : canView === false ? (
        <Navigate to={"/dashboard"} />
      ) : (
        <Box>Loading ...</Box>
      )}
    </Box>
  );
};

export default Tags;
