import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  // CircularProgress,
  Grid,
  gridClasses,
  //   MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  fetchMonthlyReportPopulate,
  getmonthlyreportDetails,
} from "../../../Redux/Slice/MonthlyReport/MonthlyReportSlice";
import theme from "../../../theme";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment/moment";
import LateYearlySummaryPrint from "./YearlySummaryPrint";
import { useReactToPrint } from "react-to-print";
import { logout } from "../../../Redux/Slice/LogoutSlice/LogoutSlice";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../Utils/AxiosInstance";
// import Loader from "../../Loader/Loader";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
    Form_input: {
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,
    },
  };
});
function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport csvOptions={{ fileName: "YearlySummary" }} />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  );
}
function YearlySummary() {
  const navigate = useNavigate();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { header, Form_input } = useStyles();
  const dispatch = useDispatch();
  const [rows, setrows] = useState([]);
  const [pageSize, setPageSize] = useState(50);
  const [IsLogin, setIsLogin] = useState(true);
  const [message, setmessage] = useState(null);
  const { Report_Populate } = useSelector(getmonthlyreportDetails);
  const [yearlySummary, setyearlySummary] = useState([]);
  const [YearlySummary, setYearlySummary] = useState([]);
  const [employees, setemployees] = useState([]);
  const [EmpName, setEmpName] = useState("");
  const [loader, setloader] = useState(false);
  const [Years, setYears] = useState([]);
  const [totalTime, settotalTime] = useState("");

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const startYear = 1960;
    const yearRange = Array.from(
      { length: currentYear - startYear + 1 },
      (_, index) => startYear + index
    );

    setYears(yearRange.reverse());
  }, []);
  // const [selectedEmployee, setselectedEmployee] = useState(null);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  console.log(rows, Report_Populate);

  const componentRef = useRef();
  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    dispatch(fetchMonthlyReportPopulate());

    setemployees(Report_Populate);
  }, [dispatch]);
  useEffect(() => {
    if (Report_Populate) {
      setemployees(Report_Populate);
    }
  }, [Report_Populate]);

  const columns = [
    { field: "sr", headerName: "Sr #", width: 100, headerClassName: header },
    {
      field: "Employee_Name",
      headerName: "Employee",
      width: 250,
      headerClassName: header,
    },
    {
      field: "month",
      headerName: "Month",
      width: 200,
      headerClassName: header,
    },
    {
      field: "total_latetime",
      headerName: "Late time",
      width: 200,
      headerClassName: header,
    },
    {
      field: "count",
      headerName: "Days Count",
      width: 200,
      headerClassName: header,
    },
  ];
  useEffect(() => {
    let arr = [];

    YearlySummary &&
      YearlySummary?.forEach((item, idx) => {
        return arr.push({
          ...item,
          id: idx + 1,
          sr: idx + 1,
          month: moment(item?.month).format("MMM-YYYY"),
          total_latetime:
            parseInt(item?.total_latetime.split(":")[0]) === 0 &&
            parseInt(item?.total_latetime.split(":")[1]) === 0
              ? 0
              : `${item?.total_latetime.split(":")[0]} hrs ${
                  item?.total_latetime.split(":")[1]
                } min `,
                totalLatetime:item?.total_latetime
        });
      });

    setrows(arr);
    setyearlySummary(arr);
    getTotalHourandMinutes(arr);
  }, [YearlySummary]);
  const initialValues = {
    employee: "",
    months: "",
  };
  const fetchYearlySummary = async ({ emp_id, year }) => {
    setloader(true);
    try {
      const response = await axiosInstance.get(
        `getoneyearlatearrivaldata?year=${year}&emp_id=${emp_id}`
      );
      if (response?.data?.IsSuccess === true) {
        setloader(false);
        setYearlySummary(response?.data?.Data);
      } else {
        setsnackBarData({
          type: "error",
          message: "Something went wrong",
          openToast: true,
        });
        setloader(false);
      }
    } catch (error) {
      if (error?.message.includes(401)) {
        setmessage(error?.message);
        setIsLogin(false);
      }
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
      setloader(false);
      console.error("Error fetching data:", error);
    }
  };
  const handleSubmit = async (values) => {
    // const obj = { ...values, country: value?.label };
    // let year = moment(values?.months).format("YYYY");
    // dispatch(fetchYearlySummary({ emp_id: values?.employee, year: year }));
    fetchYearlySummary({ emp_id: values?.employee, year: values?.months });
  };
  const ValidationSchema = Yup.object().shape({
    employee: Yup.string().required("Please Enter employee"),
    months: Yup.string().nullable().required("Please Enter Year"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values, resetForm);
    },
  });
  const getTotalHourandMinutes = (attendance) => {
    let TotalHours = 0;
    let TotalMinutes = 0;
    attendance?.forEach((time) => {
      TotalMinutes += parseInt(time?.totalLatetime.split(":")[1]);
      TotalHours += parseInt(time?.totalLatetime.split(":")[0]);
    });
console.log(TotalHours);
    TotalHours += Math.floor(TotalMinutes / 60);
    TotalMinutes %= 60;
    settotalTime(`${TotalHours} hrs ${TotalMinutes} min`);
  };
  console.log(employees);
  return (
    <>
      <Box sx={{ display: "none" }}>
        <LateYearlySummaryPrint
          ref={componentRef}
          name={EmpName}
          month={moment(formik.values.months).format("MMM-YYYY")}
          yearlySummary={yearlySummary}
        />
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            paddingBottom: "10px",
            width: "100%",
            padding: "10px 10px 0px 10px",
            alignItems: "center",
          }}
        >
          {/* <TextField
                size="small"
                variant="outlined"
                name="employee"
                label="Employee"

                select
                color="primary"
                sx={{ input: { color: "black" }, maxWidth: "250px", textAlign: "left" }}
                className={Form_input}
                fullWidth
                value={formik.values.employee}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.employee) && Boolean(formik.touched.employee)}
                helperText={Boolean(formik.errors.employee) && formik.errors.employee}
            >
                <MenuItem value="">Select employee</MenuItem>
                {employees.map((employee) => {
                    return (
                        <MenuItem key={employee?.id} value={employee?.id}>
                            {employee?.name}
                        </MenuItem>
                    );
                })}
            </TextField> */}
          <Autocomplete
            disablePortal
            options={employees}
            getOptionLabel={(option) => option.name}
            sx={{
              input: { color: "black" },
              maxWidth: "250px",
              textAlign: "left",
            }}
            className={Form_input}
            fullWidth
            // value={values.employee}
            size="small"
            onChange={(e, val) => {
              console.log(e, val);
              setEmpName(val?.name);
              formik.handleChange({
                target: {
                  name: "employee",
                  value: val?.id,
                },
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="employee"
                placeholder="Employee"
                error={
                  Boolean(formik.errors.employee) &&
                  Boolean(formik.touched.employee)
                }
                helperText={
                  Boolean(formik.touched.employee) &&
                  Boolean(formik.errors.employee) &&
                  formik.errors.employee
                }
              />
            )}
          />
          <Autocomplete
            disablePortal
            options={Years}
            getOptionLabel={(option) => option}
            sx={{
              input: { color: "black" },
              maxWidth: "250px",
              textAlign: "left",
            }}
            className={Form_input}
            fullWidth
            value={formik.values.months}
            size="small"
            name="months"
            onChange={(e, val) => {
              console.log(e, val);
              formik.setFieldValue("months", val);
              // formik.handleChange({
              //   target: {
              //     name: "months",
              //     value: val,
              //   },
              // });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Year"
                error={
                  Boolean(formik.errors.months) &&
                  Boolean(formik.touched.months)
                }
                helperText={
                  Boolean(formik.touched.months) &&
                  Boolean(formik.errors.months) &&
                  formik.errors.months
                }
              />
            )}
          />
          {/* <TextField
            type={"month"}
            name="months"
            // variant="outlined"
            size="small"
            value={formik.values.months}
            onChange={formik.handleChange}
            error={
              Boolean(formik.errors.months) && Boolean(formik.touched.months)
            }
            helperText={Boolean(formik.errors.months) && Boolean(formik.touched.months) && formik.errors.months}
          /> */}
          <Box sx={{ display: "flex", gap: "10px" }}>
            {loader ? (
              <CircularProgress />
            ) : (
              <Button
                //   onClick={() => {
                //     handleGet();
                //   }}
                type="submit"
                sx={{ padding: "5px 10px" }}
                variant="contained"
              >
                Get
              </Button>
            )}
            <Button
              onClick={() => {
                printPaymentRec();
              }}
              sx={{ padding: "5px 10px" }}
              variant="contained"
            >
              Print
            </Button>
          </Box>
          {/* )} */}
        </Box>
      </form>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          paddingX: "20px",
          gap: "50px",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
          }}
        >
          Total Late Time
        </Typography>
        <Typography
          sx={{
            textDecoration: "underline",
          }}
        >
          {" "}
          {totalTime}
        </Typography>
      </Box>
      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        overflow
        sx={{ height: "calc(100vh - 193px)", padding: "10px" }}
      >
        <DataGrid
          rows={yearlySummary}
          columns={columns}
          onRowClick={() => {}}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          pagination
          components={{
            Toolbar: CustomToolbar,
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />
      </Grid>
    </>
  );
}

export default YearlySummary;
