import { Box, Button, CircularProgress, FormControl, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
// import moment from "moment";
import { SketchPicker } from "react-color";
import PropTypes from "prop-types";
import { PostTagApi } from "../../Api/Tags/PostTagsApi";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useDispatch } from "react-redux";
import { fetchAllTags } from "../../Redux/Slice/Tags/TagSlice";
import { PatchTagAPI } from "../../Api/Tags/PatchTagApi";
const useStyles = makeStyles(() => {
  return {
    container: {
      display: "flex",
      gap: "50px",
      justifyContent: "center",
      minHeight: "calc(100vh - 175px)",
    },
    subContainer: {
      display: "flex",
      width: "100%",
      maxWidth: "600px",
      flexDirection: "column",
      gap: "20px",
      textAlign: "left",
      padding: "40px 20px",
    },
    FieldInRow: {
      width: "100%",
      display: "flex",
      gap: "20px",
      textAlign: "left",
      flexWrap: "wrap",
    },
    colorLabelContainer: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
      justifyContent: "space-between",
    },
  };
});

const validationSchema = Yup.object().shape({
  tag: Yup.string().required("Required"),
});

const initialValues = {
  tag: "",
};

const TagForm = ({
  setShowForm,
  setselectedRow,
  selectedRow,
  isEdited,
  setisEdited,
}) => {
  const { container, subContainer, FieldInRow, colorLabelContainer } =
    useStyles();
  const [selectedColor, setselectedColor] = useState("#fff");
  const { setsnackBarData } = useContext(SnackBarContext);
  const [loader,setloader]=useState(false)
  const dispatch = useDispatch();
  console.log(selectedRow);
  console.log(setselectedRow);
  console.log(isEdited);
  console.log(setisEdited);
  const handleSubmit = async (values, setSubmitting, resetForm) => {
    setloader(true)

    const obj = { ...values, color_code: selectedColor };
    let response = {};

    if (isEdited) {
      const updateObject = { ...obj, tag_id: selectedRow?.tag_id };
      response = await PatchTagAPI(updateObject);
    } else {
      response = await PostTagApi(obj);
    }

    console.log(response);
    setsnackBarData(response.snackBarData);
    if (response.data.IsSuccess) {
      setloader(false)
      setSubmitting(false);
      resetForm();
      setShowForm(false);
      dispatch(fetchAllTags());
    }else{
      setloader(false)

    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (isEdited) {
      formik.setFieldValue("tag", selectedRow?.tag);
      setselectedColor(selectedRow?.color);
    }
  }, [selectedRow]);

  const handleChangeComplete = (color) => {
    console.log(color);
    setselectedColor(color?.hex);
  };
  const handleCancel = () => {
    console.log("hello");
    setisEdited(false);
    setselectedRow(null);
    setShowForm(false);
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      handleSubmit(values, setSubmitting, resetForm);
    },
  });
  return (
    <Box className={container}>
      <form
        onSubmit={formik.handleSubmit}
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <Box className={subContainer}>
          <Box className={FieldInRow}>
            <FormControl fullWidth>
              <Typography>Tag</Typography>
              <TextField
                name="tag"
                fullWidth
                size="small"
                value={formik.values.tag}
                onChange={formik.handleChange}
                error={formik.touched.tag && Boolean(formik.errors.tag)}
                helperText={formik.touched.tag && formik.errors.tag}
              />
            </FormControl>
            <FormControl fullWidth>
              <Box className={colorLabelContainer}>
                <Typography>Color</Typography>
                <Box
                  sx={{
                    height: "20px",
                    width: "40px",
                    background: selectedColor,
                  }}
                ></Box>
              </Box>
              <SketchPicker
                width="auto"
                color={selectedColor}
                onChangeComplete={handleChangeComplete}
              />
            </FormControl>
          </Box>

          <Box className={FieldInRow} sx={{ justifyContent: "end" }}>
            <Button variant="contained" color="error" onClick={handleCancel}>
              Cancel
            </Button>
            {
              loader ? <CircularProgress/>:
            
            <Button variant="contained" type="submit">
              {isEdited ? "Update" : "Save"}
            </Button>
            }
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default TagForm;
TagForm.propTypes = {
  setShowForm: PropTypes.any,
  setselectedRow: PropTypes.any,
  selectedRow: PropTypes.any,
  isEdited: PropTypes.any,
  setisEdited: PropTypes.any,
};
