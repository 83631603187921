import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,  } from '@mui/material'
import React, { Component } from 'react'
import PrintHeader from '../../Print/PrintHeader/PrintHeader';
import PropTypes from "prop-types";
// import Cookies from 'universal-cookie';
import PrintReportSetion from '../../Print/PrintReportSection/PrintReportSetion';
class EarlyMonthlyDetailedPrint extends Component {
    render() {
        // const cookies = new Cookies
        return (

            <Box sx={{ width: "100%", padding: "10px" }}>
                <PrintHeader />
                <PrintReportSetion Months={this.props.month} Reportname={"Early Departure( Monthly Detailed) Report"} userName={this.props.name} />
                <Box>

                    <TableContainer
                        component={Paper}
                        sx={{ overflowY: "scroll" }}
                    >

                        <Table
                            sx={{
                                minWidth: { md: "auto", xs: "auto" },
                                overflow: "scroll",
                            }}
                            aria-label="simple table"
                            size="small"
                        >
                            <TableHead>
                                <TableRow size="small">
                                    <TableCell
                                        size="small"
                                        align="left"
                                        sx={{
                                            fontWeight: "bold",
                                            // color: (theme) => theme.palette.grey["500"],
                                        }}
                                    >
                                        Sr #
                                    </TableCell>
                                    <TableCell
                                        size="small"
                                        align="left"
                                        sx={{
                                            fontWeight: "bold",
                                            // color: (theme) => theme.palette.grey["500"],
                                        }}
                                    >
                                        Employee
                                    </TableCell>
                                    <TableCell
                                        size="small"
                                        align="left"
                                        sx={{
                                            fontWeight: "bold",
                                            // color: (theme) => theme.palette.grey["500"],
                                        }}
                                    >
                                        Date
                                    </TableCell>
                                    <TableCell
                                        size="small"
                                        align="left"
                                        sx={{
                                            fontWeight: "bold",

                                        }}
                                    >
                                        Time
                                    </TableCell>
                                    <TableCell
                                        size="small"
                                        align="left"
                                        sx={{
                                            fontWeight: "bold",

                                        }}
                                    >
                                        Early Time
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.props.monthlyDetailed?.map((item, i) => {
                                        return (
                                            <TableRow key={i}
                                                sx={{
                                                    "&:last-child td, &:last-child th": { border: 0 },
                                                }}
                                            >
                                                <TableCell size="small" align="left">
                                                    {item?.sr}
                                                </TableCell>
                                                <TableCell size="small" align="left">
                                                    {item?.name}
                                                </TableCell>
                                                <TableCell size="small" align="left">
                                                    {item?.date}
                                                </TableCell>
                                                <TableCell size="small" align="left">
                                                    {item?.time}
                                                </TableCell>
                                                <TableCell size="small" align="left">
                                                    {item?.early_time}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }


                            </TableBody>
                        </Table>

                    </TableContainer>
                </Box>
                {/* <Box sx={{ width: "100%", display: "flex", justifyContent: "end", paddingTop: "20px" }}>
                    <Box sx={{
                        display: "flex", flexDirection: "column", width: "25%", gap: "10px"
                    }}>
                        <Box sx={{
                            display: "flex", justifyContent: "space-between", gap: "20px"
                        }}>
                            <Typography sx={{ fontWeight: "bold" }}> Sub Total</Typography>
                            <Typography sx={{ fontWeight: "bold" }}> 60986</Typography>
                        </Box>
                        <Divider sx={{ width: "100%" }} />
                        <Box sx={{
                            display: "flex", justifyContent: "space-between", gap: "20px"
                        }} >
                            <Typography sx={{ fontWeight: "bold" }}> Taxes</Typography>
                            <Typography sx={{ fontWeight: "bold" }}> 60986</Typography>
                        </Box>
                        <Divider sx={{ width: "100%" }} />
                        <Box sx={{
                            display: "flex", justifyContent: "space-between", gap: "20px"
                        }}>
                            <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}> Total</Typography>
                            <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}> 60986</Typography>
                        </Box>
                    </Box>
                </Box>
                <Divider sx={{ width: "100%", paddingTop: "10px", marginBottom: "10px" }} /> */}

            </Box>
        )
    }
}

export default EarlyMonthlyDetailedPrint;

EarlyMonthlyDetailedPrint.propTypes = {
    monthlyDetailed: PropTypes.any,
    month: PropTypes.any,
    name: PropTypes.any,
};