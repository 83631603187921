import { Box, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
// import logo from "../../Assets/logo.png";
// import Image from "next/image";
const useStyle = makeStyles(() => {
  return {
    container: {
      width: "100%",
      position: "relative",
      top: 0,
      left: 0,
      //   background: "rgba(250,250,250,0.4)",
      background: "rgba(250,250,250,0.4)",
      //   overflow: "hidden",
      //   position: "fixed",
    },
  };
});

const Loader = () => {
  const { container } = useStyle();
  return (
    <Box className={container}>
      <Box
        sx={{
          width: "100%",
          height: "calc(100vh - 250px)",
          position: "absolute",
          zIndex: 1000,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress color="primary" />
      </Box>
    </Box>
  );
};

export default Loader;
