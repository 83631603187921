import PropTypes from "prop-types";
import {
  Box,
  // CircularProgress,
  gridClasses,
  InputAdornment,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
// import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
// import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useNavigate } from "react-router-dom";
// import theme from "../../theme";
import DeleteModal from "../DeleteModal/DeleteModal";
import { DeleteTechnology } from "../../Api/Technologies/TechnologiesAPis";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../Button/AddNewButton";
// import InfoIcon from "@mui/icons-material/Info";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import Loader from "../Loader/Loader";
import {
  deleteDeapplication,
  fetchApplications,
  getApplicationsDetails,
} from "../../Redux/Slice/JobApplication/JobApplicationSlice";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});
function JobApplicationTable({
  setShowForm,
  // setIsEdit,
  // setEditedData,
  EditedData,
}) {
  const { tabelStyle } = useContext(TabelStyleContext);
  const { header } = useStyles();
  const dispatch = useDispatch();
  const [rows, setrows] = useState([]);
  const [filterRows, setfilterRows] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [loading, setloading] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  const ApplicationDetails = useSelector(getApplicationsDetails);
  const { IsLogin, message } = useSelector(getApplicationsDetails);
  const navigate = useNavigate();
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [editPermit, seteditpermit] = useState(false);
  const [addPermit, setaddpermit] = useState(false);
  const [deletePermit, setdeletepermit] = useState(false);

  console.log(ApplicationDetails);
  useEffect(() => {
    let string = "Technologies";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Edit") {
        seteditpermit(true);
      } else if (val?.module?.split("_")[1] === "Add") {
        setaddpermit(true);
      } else if (val?.module?.split("_")[1] === "Delete") {
        setdeletepermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      setaddpermit(true);
      seteditpermit(true);
      setdeletepermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  console.log(editPermit);
  console.log(addPermit);
  console.log(deletePermit);

  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  useEffect(() => {
    dispatch(fetchApplications());
  }, [dispatch]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "JobApplicationReport" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "id", headerName: "Sr #", flex: 0, headerClassName: header },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      headerClassName: header,
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 200,
      headerClassName: header,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      headerClassName: header,
    },
    {
      field: "cnic",
      headerName: "CNIC",
      width: 200,
      headerClassName: header,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 200,
      headerClassName: header,
    },
    {
      field: "technologies",
      headerName: "Technologies",
      width: 200,
      headerClassName: header,
    },

    // {
    //   field: "Actions",
    //   headerName: "Actions",
    //   width: 200,
    //   headerClassName: header,
    //   renderCell: (celVal) => {
    //     return (
    //       <Box
    //         sx={{
    //           display: "flex",
    //           justifyContent: "start",
    //           gap: "10px",
    //         }}
    //       >
    //         {editPermit ? (
    //           <IconButton
    //             size={"small"}
    //             onClick={() => {
    //               setEditedData(celVal.row);
    //               setIsEdit(true);
    //               setShowForm(true);
    //             }}
    //           >
    //             <AppRegistrationIcon
    //               sx={{ cursor: "pointer", color: "black !important" }}
    //             />
    //           </IconButton>
    //         ) : null}
    //         {deletePermit ? (
    //           <IconButton
    //             onClick={() => {
    //               setEditedData(celVal.row);
    //               setshowDeleteModal(true);
    //             }}
    //             size={"small"}
    //           >
    //             <DeleteOutlineIcon sx={{ color: theme.palette.error.main }} />
    //           </IconButton>
    //         ) : null}
    //         {/* {
    //           <IconButton >
    //             <InfoIcon
    //               sx={{ cursor: "pointer", color: "black !important" }}
    //               onClick={() => {
    //                 setSelectedItem(celVal.row);
    //                 setOpenDialog(true)
    //               }}
    //               size={"small"}
    //             />
    //           </IconButton>
    //         } */}
    //       </Box>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    let arr = [];
    console.log(ApplicationDetails);
    if (ApplicationDetails) {
      ApplicationDetails?.applications?.forEach((item, idx) => {
        return arr.push({
          ...item,
          id: idx + 1,
        });
      });
    }
    setrows(arr);
    setfilterRows(arr);
  }, [ApplicationDetails]);

  const onTextChange = () => {
    if (document.getElementById("ProjectNameFilter").value === "") {
      setfilterRows(rows);
    } else {
      let suggestions = [];

      var regex1 = new RegExp(
        document.getElementById("ProjectNameFilter").value,
        "i"
      );
      suggestions = rows?.filter(
        (val) =>
          document.getElementById("ProjectNameFilter").value === "" ||
          regex1.test(val.name)
      );

      setfilterRows(suggestions);
    }
  };
  const handelDelete = async () => {
    setloading(true);
    const response = await DeleteTechnology(EditedData?.tech_id);
    setsnackBarData(response.snackBarData);
    setloading(false);
    if (response.data?.IsSuccess) {
      setshowDeleteModal(false);

      dispatch(deleteDeapplication(EditedData?.tech_id));
    }
  };
  useEffect(() => {
    if (ApplicationDetails?.status === "failed") {
      setsnackBarData({
        type: "error",
        message: ApplicationDetails?.error,
        openToast: true,
      });
    }
  }, [ApplicationDetails?.status]);
  return (
    <>
      <Box spacing={2} sx={{ padding: "10px 0px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              padding: "10px",
            }}
          >
            {addPermit ? (
              <>
                {/* <Button
                sx={{ padding: "5px 10px" }}
                onClick={() => {
                  setShowForm(true);
                }}
                variant="contained"
                className="tabletopbutton1 text-right"
              >
                New
              </Button> */}
                <CustomButton
                  Color={"primary"}
                  Icon={<AddIcon />}
                  onClick={() => {
                    setShowForm(true);
                  }}
                  Label={"Add"}
                />
              </>
            ) : null}
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{ color: (theme) => theme.palette.grey.G2 }}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{
                width: "100%",
                maxWidth: "220px",
              }}
              variant="standard"
              type="text"
              autoComplete="off"
              placeholder="Name"
              className="input_border"
              id="ProjectNameFilter"
              onChange={onTextChange}
            />
          </Box>
        </Box>

        <Box sx={{ height: tabelStyle.height, padding: "10px" }}>
          {ApplicationDetails?.applications?.length<=0 && ApplicationDetails?.status === "loading" ? <Loader /> : null}
          <DataGrid
            rows={filterRows}
            columns={columns}
            onRowClick={() => {}}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            pagination
            components={{
              Toolbar: CustomToolbar,
            }}
            localeText={{
              toolbarDensity: "Size",
              toolbarDensityLabel: "Size",
              toolbarDensityCompact: "Small",
              toolbarDensityStandard: "Medium",
              toolbarDensityComfortable: "Large",
            }}
          />
        </Box>
        <DeleteModal
          name={`"${EditedData?.technology_name}" Technology`}
          handelDelete={handelDelete}
          showModal={showDeleteModal}
          setshowModal={setshowDeleteModal}
          loading={loading}
        />
        {/* {console.log("Tech Name", selectedItem?.technology_name)} */}
      </Box>
    </>
  );
}

export default JobApplicationTable;
JobApplicationTable.propTypes = {
  setShowForm: PropTypes.any,
  setIsEdit: PropTypes.func,
  setEditedData: PropTypes.func,
  EditedData: PropTypes.any,
  loadings: PropTypes.bool,
};
