import {
  Box,
  //  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  //    Typography
} from "@mui/material";
import React, { Component } from "react";
import PrintHeader from "../../Components/Print/PrintHeader/PrintHeader";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import PrintReportSetion from "../../Components/Print/PrintReportSection/PrintReportSetion";
class AttendanceDetailPrint extends Component {
  render() {
    const cookies = new Cookies();
    console.log(cookies?.get("user")?.username);
    return (
      <Box sx={{ width: "100%", padding: "10px" }}>
        <PrintHeader />
        <PrintReportSetion
          Reportname={"Attendance Detail Report"}
          userName={
            this.props?.name ? this.props?.name : cookies?.get("user")?.username
          }
          Months={this.props.month}
        />
        <Box
          // sx={{padding:"0px 10px"}}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 10px 10px 10px",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ fontWeight: "bold" }}>
              Total Regular Hours:
            </Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              {
                this.props.Total_workedHrs
                // ? `${parseInt(
                //     Total_workedHrs.split(":")[0]
                //   )} hrs ${parseInt(Total_workedHrs.split(":")[1])} min`
                // : null
              }
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ fontWeight: "bold" }}>
              {" "}
              Total Overtime Hours:
            </Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              {this.props.Total_overtimeHrs}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography sx={{ fontWeight: "bold" }}>Total Hours:</Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              {this.props.totalHour ? this.props.totalHour : ""}
            </Typography>
          </Box>
        </Box>
        <Box>
          <TableContainer component={Paper} sx={{ overflowY: "scroll" }}>
            <Table
              sx={{
                minWidth: { md: "auto", xs: "auto" },
                overflow: "scroll",
              }}
              aria-label="simple table"
              size="small"
            >
              <TableHead>
                <TableRow size="small">
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      // color: (theme) => theme.palette.grey["500"],
                    }}
                  >
                    Sr #
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      // color: (theme) => theme.palette.grey["500"],
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      // color: (theme) => theme.palette.grey["500"],
                    }}
                  >
                    Check In
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Check Out
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Worked Hours
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Over Time
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.atandanceDetailsReport?.map((item, i) => {
                  return (
                    <TableRow
                      key={i}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell size="small" align="left">
                        {item?.sr}
                      </TableCell>
                      <TableCell size="small" align="left">
                        {item?.Date}
                      </TableCell>
                      <TableCell size="small" align="left">
                        {item?.CheckIn}
                      </TableCell>
                      <TableCell size="small" align="left">
                        {item?.CheckOut}
                      </TableCell>
                      <TableCell size="small" align="left">
                        {item?.Worked_hrs}
                      </TableCell>
                      <TableCell size="small" align="left">
                        {item?.OverTime_hrs}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {/* <Box sx={{ width: "100%", display: "flex", justifyContent: "end", paddingTop: "20px" }}>
                    <Box sx={{
                        display: "flex", flexDirection: "column", width: "25%", gap: "10px"
                    }}>
                        <Box sx={{
                            display: "flex", justifyContent: "space-between", gap: "20px"
                        }}>
                            <Typography sx={{ fontWeight: "bold" }}> Sub Total</Typography>
                            <Typography sx={{ fontWeight: "bold" }}> 60986</Typography>
                        </Box>
                        <Divider sx={{ width: "100%" }} />
                        <Box sx={{
                            display: "flex", justifyContent: "space-between", gap: "20px"
                        }} >
                            <Typography sx={{ fontWeight: "bold" }}> Taxes</Typography>
                            <Typography sx={{ fontWeight: "bold" }}> 60986</Typography>
                        </Box>
                        <Divider sx={{ width: "100%" }} />
                        <Box sx={{
                            display: "flex", justifyContent: "space-between", gap: "20px"
                        }}>
                            <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}> Total</Typography>
                            <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}> 60986</Typography>
                        </Box>
                    </Box>
                </Box>
                <Divider sx={{ width: "100%", paddingTop: "10px", marginBottom: "10px" }} /> */}
      </Box>
    );
  }
}

export default AttendanceDetailPrint;
AttendanceDetailPrint.propTypes = {
  atandanceDetailsReport: PropTypes.any,
  name: PropTypes.any,
  month: PropTypes.any,
  Total_overtimeHrs: PropTypes.any,
  Total_workedHrs: PropTypes.any,
  totalHour: PropTypes.any,
};
