import axiosInstance from "../../Utils/AxiosInstance";

export const GetMissingPunchesForEmployee = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.get(
      `reportofempmissingpunches?emp_id=${data?.emp_id}`
    );
    console.log("Missing Punch Employe", response);
    snackBarData = {
      type: response?.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return { ...response, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response.data.Message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const GetMonthlyPunchesForEmployee = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.get(
      `reportofmonthmissingpunches?month=${data?.month}`
    );
    console.log("Monthly Punch Employe", response);
    snackBarData = {
      type: response?.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return { ...response, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response?.data?.Message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};


