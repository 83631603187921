import { Box, Button, TextField } from "@mui/material";

import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import DailyAttTable from "../../Pages/DailyAttendance/AttTable";
import {
  FetchDailyAttendanceReport,
  getAtandanceDetailsReportDetails,
} from "../../Redux/Slice/AtandanceDetailsReport/AtandanceDetailsReportSlice";
import moment from "moment";
import Loader from "../Loader/Loader";

function DailyAttendanceTable() {
  const dispatch = useDispatch();
  const [employees, setemployees] = useState([]);
  const [currenttime, setcurrenttime] = useState(null);
  const navigate = useNavigate();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { dailyAttendance, message, IsLogin, status } = useSelector(
    getAtandanceDetailsReportDetails
  );
  console.log(employees);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
    if (dailyAttendance) {
      console.log(dailyAttendance);
      setemployees(dailyAttendance);
    }
  }, [dailyAttendance, IsLogin]);

  useEffect(() => {
    console.log({ date: moment().format("YYYY-MM-DD") });
    dispatch(
      FetchDailyAttendanceReport({ date: moment().format("YYYY-MM-DD") })
    );
  }, [dispatch]);

  const handleGet = async () => {
    if (!currenttime) {
      return setsnackBarData({
        openToast: true,
        message: "please select date.!",
        type: "error",
      });
    }
    dispatch(FetchDailyAttendanceReport({ date: currenttime }));
    console.log(currenttime);
  };
  useEffect(() => {
    if (status === "failed") {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
    }
  }, [status]);

  return (
    <Box
      sx={{
        paddingTop: "20px",
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          alignItems: "center",
          padding: "10px 10px 10px 10px",
        }}
      >
        <Box>
          <TextField
            type="date"
            size="small"
            fullWidth
            sx={{ width: "100%", maxWidth: "250px" }}
            value={currenttime}
            onChange={(e) => {
              console.log(e.target.value);
              setcurrenttime(e.target.value);
            }}
          />
        </Box>
        <Button
          onClick={handleGet}
          sx={{ padding: "5px 10px" }}
          variant="contained"
        >
          Get
        </Button>
      </Box>

      <Box>
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 167px)",
            padding: "10px",
          }}
        >
          {(dailyAttendance?.length<=0 || dailyAttendance===null) && status === "loading" ? <Loader /> : null}
          <DailyAttTable
            sx={{ height: "calc(100vh - 167px)", padding: "10px" }}
            attendanceDetails={employees}
          />
        </div>
      </Box>
      {/* )} */}
    </Box>
  );
}

export default DailyAttendanceTable;

DailyAttendanceTable.propTypes = {
  //   setShowForm: PropTypes.any,
  //   setlatest_Entry: PropTypes.any,
};
