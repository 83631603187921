import { Box } from "@mui/material";

import React, { useState } from "react";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import LogWorkTimeForm from "../../Components/LogWorkTime/LogWorkTimeForm";
import LogWorkTimeTable from "../../Components/LogWorkTime/LogWorkTimeTable";

const LogWorkTime = () => {
  const [ShowForm, setShowForm] = useState(false);
  const [latest_Entry, setlatest_Entry] = useState(null);
  const [canView, setcanView] = useState(null);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  useEffect(() => {
    let string = "LogTime";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    let match = value?.some((val) => {
      if (val?.module?.split("_")[1] === "View") {
        return true;
      }
    });
    setcanView(match);
    console.log(match);
    if (!roles || roles.length < 1) {
      setcanView(false);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);

  return (
    <Box>
      {canView === true ? (
        ShowForm ? (
          <LogWorkTimeForm
            setShowForm={setShowForm}
            latest_Entry={latest_Entry}
          />
        ) : (
          <LogWorkTimeTable
            setlatest_Entry={setlatest_Entry}
            setShowForm={setShowForm}
          />
        )
      ) : canView === false ? (
        <Navigate to={"/dashboard"} />
      ) : (
        <Box>Loading ...</Box>
      )}
    </Box>
  );
};

export default LogWorkTime;
