import React from "react";

import CPLTable from "../../Components/CPL/CPLTable";

const CPLMain = () => {
  return (
    <div>
      <CPLTable />
    </div>
  );
};

export default CPLMain;
