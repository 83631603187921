import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { makeStyles } from "@mui/styles";
import {
  Box,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import theme from "../../theme";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => {
  return {
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
    },
    Container: {
      width: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    container: {
      // height: "100%",
      width: "100%",
      padding: "0px 20px 20px 20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
  };
});
function DetailsModal({ showModal2, setshowModal2, detailsRow,setdetailsRow }) {
    const {
        Container,
        ModalContainer,
        TableHeader,
        container,
      } = useStyles();
      console.log(detailsRow);

  return (
    <>
      <Modal
        open={showModal2}
        onClose={() => {
          setshowModal2(false);
        }}
      >
        <Box className={ModalContainer}>
          <Box className={Container}>
            <Box className={TableHeader}>
              <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                Loan Details
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setdetailsRow(null);
                  setshowModal2(false);
                }}
              >
                <HighlightOffIcon
                  color="primary"
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Box>
            <Box className={container}>
            <TableContainer
            // component={Paper}
            sx={{ height: "200px", overflowY: "scroll" }}
          >

              <Table
                sx={{
                  minWidth: { md: "auto", xs: "auto" },
                  overflow: "scroll",
                }}
                aria-label="simple table"
                size="small"
              >
                <TableHead>
                  <TableRow size="small">
                    <TableCell
                      size="small"
                      align="left"
                      sx={{
                        fontWeight: "bold",
                        color: (theme) => theme.palette.grey["500"],
                      }}
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      size="small"
                      align="left"
                      sx={{
                        fontWeight: "bold",
                        color: (theme) => theme.palette.grey["500"],
                      }}
                    >
                      Note
                    </TableCell>
                    <TableCell
                      size="small"
                      align="left"
                      sx={{
                        fontWeight: "bold",
                        color: (theme) => theme.palette.grey["500"],
                      }}
                    >
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell size="small" align="left">
                          {
                            detailsRow?.Request_Nodeduction_loan?.req_amount
                          }
                        </TableCell>
                        <TableCell size="small" align="left">
                          {
                            detailsRow?.Request_Nodeduction_loan?.note
                          }
                        </TableCell>
                        <TableCell size="small" align="left">
                          {
                            detailsRow?.Request_Nodeduction_loan?.status
                          }
                        </TableCell>

                      </TableRow>

                </TableBody>
              </Table>
     
          </TableContainer>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default DetailsModal;
DetailsModal.propTypes = {
    showModal2: PropTypes.any,
    setshowModal2: PropTypes.any,
    detailsRow: PropTypes.any,
    setdetailsRow: PropTypes.any,
  };
  