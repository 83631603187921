import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  department: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};

export const fetchDepartment = createAsyncThunk("getdpartments", async () => {
  const response = await axiosInstance.get(`getdpartments`);
  return response.data;
});

const DepartmentSlice = createSlice({
  name: "department",
  initialState,
  reducers: {
    DepartmentAdded: {
      reducer(state, action) {
        state.department.push(action.payload);
    
      },
    },
    deleteDepartment: {
      reducer(state, action) {
        const userIndex = state?.department.findIndex(
          (department) => department.dept_id === action.payload
        );
   
        if (userIndex !== -1) {
          state.department.splice(userIndex, 1);
        }
      },
    },
    DepartmentResetData: (state) => {
      state.department = [];
      state.isSuccess = false;
      state.message = null;
      state.status = "idle"; //'idle' | 'loading' | 'succeeded' | 'failed'
      state.error = null;
      state.IsLogin = true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDepartment.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDepartment.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.department = action.payload.DeptData;
      })
      .addCase(fetchDepartment.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
  },
});

export const getDepartmentDetails = (state) => state.department;

export const { DepartmentAdded, DepartmentResetData, deleteDepartment } =
  DepartmentSlice.actions;

export default DepartmentSlice.reducer;
