import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  allowance: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,

};

export const fetchallowance = createAsyncThunk(
  "getallowances",
  async () => {
    const response = await axiosInstance.get(`getallowances
`);
    return response.data;
  }
);

const AddAllowancesSlice = createSlice({
  name: "allowance",
  initialState,
  reducers: {
    AllowanceAdded: {
      reducer(state, action) {
        state.allowance.push(action.payload);
        console.log(action.payload)
      },
    },
    deleteAllowance: {
      reducer(state, action) {
        const userIndex = state?.allowance.findIndex(
          (allowance) => allowance.allowance_id === action.payload
        );
        if (userIndex !== -1) {
          state.allowance.splice(userIndex, 1);
        }
      },
    },
    AllowanceResetData: (state) => {
      state.allowance= [];
      state.isSuccess= false;
      state.message= null;
      state.status= "idle"; //'idle' | 'loading' | 'succeeded' | 'failed'
      state.error= null;
      state.IsLogin= true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchallowance.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchallowance.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.allowance = action.payload.Allowance;
      })
      .addCase(fetchallowance.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })

  },
});

export const getallowanceDetails = (state) => state.allowance;

export const { AllowanceAdded,AllowanceResetData,deleteAllowance } = AddAllowancesSlice.actions;

export default AddAllowancesSlice.reducer;