import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import DoneIcon from "@mui/icons-material/Done";
import { useFormik } from "formik";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import theme from "../../theme";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { ForgotPasswordPatchAPI } from "../../Api/ForgotPassword/ResetPasswordPatchAPi";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";

const useStyle = makeStyles(() => {
  return {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "white !important",
      height: "100vh",
      width: "100%",
      padding: "25px",
      [theme.breakpoints.down("md")]: {
        minHeight: "100vh",
        height: "fit-content",
        padding: "30px 10px",
      },
    },
    BoxContainer: {
      alignItems: "center",
      display: "flex",
      background: "white !important",
      borderRadius: "10px",
      padding: "20px",
      boxShadow:
        "rgba(20, 20, 20, 0.16) 0px 5px 16px 0px, rgba(20, 20, 20, 0.06) 0px 0px 0px 1px",
      width: "100%",
      maxWidth: "850px",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        width: "100%",
      },
    },
    LeftSideTypoContainer: {
      textAlign: "left",
      width: "100%",
      padding: "40px 20px",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    Typo: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      color: theme.palette.grey[700],
    },
    RightSideFormContainer: {
      textAlign: "left",
      width: "100%",
      padding: "20px",
    },
  };
});
const initialValues = {
  password: "",
  cpassword: "",
};

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Please new enter password")
    .min(8, "password must be 8 character long"),
  cpassword: Yup.string().when("password", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "Both Password need to be the same"
    ),
  }),
});

const ChangeForgotPassword = () => {
  const [showPassword, setshowPassword] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  const { userid } = useParams();
  const navigate = useNavigate();
  console.log(userid);
  // const cookies = new Cookies();
  const {
    container,
    BoxContainer,
    LeftSideTypoContainer,
    Typo,
    RightSideFormContainer,
  } = useStyle();

  const HandleFormValues = async (values, setSubmitting, resetForm) => {
    if (values?.cpassword === "") {
      setSubmitting(false);
      return setsnackBarData({
        openToast: true,
        type: "error",
        message: "please enter confirm password",
      });
    }

    setSubmitting(true);

    const obj = {
      user_id: userid,
      newpassword: values?.password,
    };

    const response = await ForgotPasswordPatchAPI(obj);
    setsnackBarData(response.snackBarData);
    if (response.data?.IsSuccess) {
      setSubmitting(false);
      resetForm();
      navigate('/')
    }

    setSubmitting(false);
    console.log(obj);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      HandleFormValues(values, setSubmitting, resetForm);
    },
  });

  return (
    <Box className={container}>
      <Box className={BoxContainer}>
        <Box className={LeftSideTypoContainer}>
          <Typography
            variant="h3"
            sx={{
              marginBottom: "30px",
              color: (theme) => theme.palette.grey[700],
            }}
          >
            Change Password
          </Typography>
          <Typography
            variant="h6"
            sx={{ margin: "10px", color: (theme) => theme.palette.grey[600] }}
          >
            Password Must Contain
          </Typography>
          <Box className={Typo}>
            <DoneIcon />
            <Typography>At-least 6 character long</Typography>
          </Box>
          <Box className={Typo}>
            <DoneIcon />
            <Typography>At-least 1 upper-case character [A-Z]</Typography>
          </Box>
          <Box className={Typo}>
            <DoneIcon />
            <Typography>At-least 1 lower-case character [a-z]</Typography>
          </Box>
          <Box className={Typo}>
            <DoneIcon />
            <Typography>At-least 1 numaric character [0-9]</Typography>
          </Box>
        </Box>
        <Box className={RightSideFormContainer}>
          <form onSubmit={formik.handleSubmit}>
            <Box>
              <Box>
                <FormControl variant="standard" fullWidth>
                  <Typography className={Typo}>Password:</Typography>
                  <TextField
                    type={showPassword ? "text" : "password"}
                    value={formik.values.password}
                    placeholder="Password"
                    onChange={formik.handleChange}
                    name="password"
                    size="small"
                    error={
                      Boolean(formik.errors.password) &&
                      Boolean(formik.touched.password)
                    }
                    helperText={
                      Boolean(formik.touched.password) && formik.errors.password
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setshowPassword(!showPassword);
                          }}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl variant="standard" fullWidth>
                  <Typography className={Typo}>Confirm Password:</Typography>
                  <TextField
                    type={showPassword ? "text" : "password"}
                    value={formik.values.cpassword}
                    placeholder="Confirm Password"
                    onChange={formik.handleChange}
                    name="cpassword"
                    size="small"
                    error={
                      Boolean(formik.errors.cpassword) &&
                      Boolean(formik.touched.cpassword)
                    }
                    helperText={
                      Boolean(formik.touched.cpassword) &&
                      formik.errors.cpassword
                    }
                  />
                </FormControl>
              </Box>

              <Box
                item
                xs={12}
                md={12}
                sx={{
                  margin: "20px auto",
                  display: "flex",
                  gap: "10px",
                  justifyContent: "end",
                }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Cancel
                </Button>
                {formik.isSubmitting ? (
                  <CircularProgress />
                ) : (
                  <Button variant="contained" type="submit">
                    Submit
                  </Button>
                )}
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default ChangeForgotPassword;
