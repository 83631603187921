import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import ProjectSubscriptionForm from "../../Components/ProjectSubscription/ProjectSubscriptionForm";
import ProjectSubscriptionTable from "../../Components/ProjectSubscription/ProjectSubscriptionTable";
import { Navigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useEffect } from "react";

const ProjectSubscription = () => {
  const [ShowForm, setShowForm] = useState(false);
  const [editRow, setEditRow] = useState([]);
  const [isEdited, setisEdited] = useState(false);
  const [canView, setcanView] = useState(null);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  useEffect(() => {
    let string = "ProjectSubscription";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    let match = value?.some((val) => {
      if (val?.module?.split("_")[1] === "View") {
        return true;
      }
    });
    setcanView(match);
    console.log(match);
    if (!roles || roles.length < 1) {
      setcanView(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);

  useEffect(() => {
 
      setEditRow(null);
    
  }, []);

  return (
    <div>
      {canView === true ? (
        ShowForm ? (
          <ProjectSubscriptionForm
            editRow={editRow}
            setEditRow={setEditRow}
            setisEdited={setisEdited}
            isEdited={isEdited}
            setShowForm={setShowForm}
          />
        ) : (
          <ProjectSubscriptionTable
            setEditRow={setEditRow}
            setisEdited={setisEdited}
            setShowForm={setShowForm}
          />
        )
      ) : canView === false ? (
        <Navigate to={"/dashboard"} />
      ) : (
        <Box>Loading ...</Box>
      )}
    </div>
  );
};

export default ProjectSubscription;
ProjectSubscription.propTypes = {
  setShowForm: PropTypes.any,
  setEditRow: PropTypes.any,
  editRow: PropTypes.any,
};
