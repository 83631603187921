import React from "react";
import AttendanceMain from "../Pages/Attendance/AttendanceMain";
import AttendanceDetailMain from "../Pages/AttendanceDetail/AttendanceDetailMain";
import CPLMain from "../Pages/CPL/CPLMain";
import Dashboard from "../Pages/Dashboard/Dashboard";
import SettingMain from "../Pages/Settings/SettingMain";
import EmployeeMain from "../Pages/Employee/EmployeeMain";
import GazettedHolidaysMain from "../Pages/GazettedHolidays/GazettedHolidaysMain";
import MonthlyReportMain from "../Pages/MonthlyReport/MonthlyReportMain";
import ProjectMain from "../Pages/Project/ProjectMain";
import TechnologiesMain from "../Pages/Technologies/TechnologiesMain";
import UserDashboard from "../Pages/UserDashboard/UserDashBoard";
import ChangeinRequest from "../Pages/ChangeinRequest/ChangeinRequest";
import BreakAdjustment from "../Pages/BreakAdjustment/BreakAdjustment";
import SubscriptionHistory from "../Pages/ProjectSubscriptionHistory/SubscriptionHistory";
import ProjectSubscription from "../Pages/ProjectSubscription/ProjectSubscription";
import DepartmentMain from "../Pages/Department/DepartmentMain";
import ExpertiesMain from "../Pages/Experties/ExpertiesMain";
import JobTitleMain from "../Pages/JobTitle/JobTitleMain";
import DesignationMain from "../Pages/Designation/DesignationMain";
import TodayAttendanceMain from "../Pages/TodayAttendance/TodayAttendanceMain";
import LoginContent from "../Pages/Login/LoginContent";
import EmployeeAllowance from "../Pages/EmployeeAllowance/EmployeeAllowance";
import ShiftRulesMain from "../Pages/ShiftRule/ShiftRulesMain";
import EmployeeIncrementDecrement from "../Pages/EmployeeIncrementDecrement/EmployeeIncrementDecrement";
import EmployeePromotionDemotion from "../Pages/EmployeePromotionDemotion/EmployeePromotionDemotion";
import InActiveEmployees from "../Pages/InActiveEmployees/InActiveEmployee";
import LeaveApplication from "../Pages/LeaveApplication/LeaveApplication";
import InActiveProjects from "../Components/InActiveProjects/InActiveProjects";
import EmployeeDetails from "../Pages/EmployeeDetails/EmployeeDetails";
import ChangePassword from "../Pages/ChangePassword/ChangePassword";
import LogWorkTime from "../Pages/LogWorkTime/LogWorkTime";
import AllowancesMain from "../Pages/Allowances/AllowancesMain";
import EmployeeDashboard from "../Components/Dashboard/DashboardForEmployee";
import EmployeMonthlyReport from "../Pages/EmployeMonthlyReport/EmployeMonthlyReport";

import Profile from "../Components/ProfileDashboard/Profile";
import UserProfile from "../Pages/UserProfile/UserProfile";
// import MyAccount from "../Components/ProfileDashboard/MyAccount";
import ChangePassProfile from "../Components/ChangePasswordProfile/ChangePassProfile";
import Loans from "../Pages/Loans/Loans";
import Advanced from "../Pages/Advanced/Advanced";
import EmailVarification from "../Pages/EmailVarification/EmailVarification";
import SalaryDetailsMain from "../Pages/SalaryDetails/SalaryDetailsMain";
import LateArrivalReportMain from "../Pages/LateArrivalReport/LateArrivalReportMain";
import EarlyDepartureReportMain from "../Pages/EarlyDepartureReport/EarlyDepartureReportMain";
import OvertimeReportMain from "../Pages/OvertimeReport/OvertimeReportMain";
import EmployeeLateArrivalReport from "../Pages/EmployeLateArrivalReport/EmployeeLateArrivalReport";
import EmployeeEaryDepartureReport from "../Pages/EmployeeEarlyDepartureReport/EmployeeEaryDepartureReport";
import EmployeeOvertimeReport from "../Pages/EmployeeOvertimeReport/EmployeeOvertimeReport";
import AtandanceDetailsReport from "../Pages/AtandenceDetailsReport/AtandanceDetailsReport";
import EmployeSalaryDetails from "../Pages/EmployeeSalaryDetails/EmployeSalaryDetails";
import CompanyData from "../Components/ProfileDashboard/CompanyData";
import CompletedChangeReq from "../Components/CompletedChangeRequest/CompletedChangeReq";
import PendingChangeReq from "../Components/PendingChangeRequest/PendingChangeReq";
import UnallocatedChangeReq from "../Components/UnallocatedChangeRequest/UnallocatedChangeReq";
import MonthlyChangeReq from "../Components/EmpMonthlyChangeRequest/MonthlyChangeReq";
import LoandDeductionRequest from "../Components/LoanDeductionRequest/LoandDeductionRequest";
import RulesMain from "../Pages/Rules/Rules";
import RolesMain from "../Pages/Roles/RolesMain";
import ManualLoanMain from "../Pages/ManualLoan/ManualLoanMain";
import DailyAttendance from "../Pages/DailyAttendance/DailyAttendance";
import EmployeeEngagement from "../Pages/EmployeeEngagement/EmployeeEngagement";
import Tags from "../Pages/Tags/Tags";
import AdminEngagements from "../Pages/AdminEngagements/AdminEngagements";
import MissingPunchesReport from "../Pages/MissingPunches/MissingPunches";
import EmployeMissingPunches from "../Pages/EmployeMissingPunches/EmployeMissingPunches";
import MonthlyMissingPunches from "../Pages/MonthlyMissingPunches/MonthlyMissingPunches";
import JobApplication from "../Pages/JobApplication/JobApplication";
import ApplicantsMain from "../Pages/Applicants/ApplicantMain";
import HiringSessionMain from "../Pages/HiringSession/HiringSessionMain";
import ApplicantSessionMain from "../Pages/ApplicantSession/ApplicantSessionMain";
import EmailMain from "../Pages/Email/Email";
import AllowanceforEmploye from "../Components/AllowanceforEmploye/AllowanceforEmploye";
import EmpOffDays from "../Pages/EmpOffDays";
import Organizations from "../Pages/Organizations/Organizations";
import MonhtlyBreakAdjReport from "../Pages/MonhtlyBreakAdjReport/MonhtlyBreakAdjReport";

export const EmployeeRoutes = [
  {
    path: "*",
    component: (
      <UserDashboard
        activePage={<EmployeeDashboard />}
        headerTitle={"Dashboard"}
      />
    ),
  },
  {
    path: "/",
    component: <LoginContent />,
  },
  {
    path: "/dashboard",
    component: (
      <UserDashboard
        activePage={<EmployeeDashboard />}
        headerTitle={"Dashboard"}
      />
    ),
  },
  {
    path: "/employeesDetails",
    component: (
      <UserDashboard
        activePage={<EmployeeDetails />}
        headerTitle={"Employee Details"}
      />
    ),
  },
  {
    path: "/loans",
    component: <UserDashboard activePage={<Loans />} headerTitle={"Loans"} />,
  },
  {
    path: "/advanced",
    component: (
      <UserDashboard activePage={<Advanced />} headerTitle={"Advanced"} />
    ),
  },
  {
    path: "/leaveapplication",
    component: (
      <UserDashboard
        activePage={<LeaveApplication />}
        headerTitle={"Leave Applications"}
      />
    ),
  },
  {
    path: "/cpl",
    component: <UserDashboard activePage={<CPLMain />} headerTitle={"CPL"} />,
  },
  {
    path: "/employelatearrivalreport",
    component: (
      <UserDashboard
        activePage={<EmployeeLateArrivalReport />}
        headerTitle={"Late Arrival Report"}
      />
    ),
  },
  {
    path: "/employeeEarlydeparturereport",
    component: (
      <UserDashboard
        activePage={<EmployeeEaryDepartureReport />}
        headerTitle={"Early Departure Report"}
      />
    ),
  },
  {
    path: "/employeeOvertimereport",
    component: (
      <UserDashboard
        activePage={<EmployeeOvertimeReport />}
        headerTitle={"Overtime Report"}
      />
    ),
  },
  {
    path: "/allowancesofemployee",
    component: (
      <UserDashboard
        toolTipText={
          "Allowances for employees are additional forms of compensation provided by employers to cover specific expenses related to an employee's job or lifestyle. These may include a housing allowance, transportation allowance, or meal allowance, among others. Allowances are often provided as part of a larger employee benefits package and can help employees manage the costs of living and working. The specific types and amounts of allowances offered may vary depending on the organization and the employee's position and responsibilities."
        }
        activePage={<AllowanceforEmploye />}
        headerTitle={"Allowances"}
      />
    ),
  },
  {
    path: "/changepassword",
    component: <ChangePassword />,
  },
  {
    path: "/employee/logworktime",
    component: (
      <UserDashboard activePage={<LogWorkTime />} headerTitle={"Log Time"} />
    ),
  },
  {
    path: "/ChangeRequests",
    component: (
      <UserDashboard
        activePage={<ChangeinRequest />}
        headerTitle={"Change Request"}
      />
    ),
  },
  {
    path: "/breakAdjustments",
    component: (
      <UserDashboard
        activePage={<BreakAdjustment />}
        headerTitle={"Break Adjustments"}
      />
    ),
  },
  {
    path: "/employeMonthlyAttendance",
    component: (
      <UserDashboard
        activePage={<EmployeMonthlyReport />}
        headerTitle={" Monthly Attendance"}
      />
    ),
  },
  {
    path: "/employeepayrolldetails",
    component: (
      <UserDashboard
        activePage={<EmployeSalaryDetails />}
        headerTitle={"Payroll Details"}
      />
    ),
  },

  {
    path: "/setting/profile",
    component: <UserProfile activePage={<Profile />} headerTitle={"Profile"} />,
  },
  // {
  //   path: "/setting/accounts",
  //   component: (
  //     <UserProfile activePage={<MyAccount />} headerTitle={"My Account"} />
  //   ),
  // },
  {
    path: "/setting/changepassword",
    component: (
      <UserProfile
        activePage={<ChangePassProfile />}
        headerTitle={"Change Password"}
      />
    ),
  },
  {
    path: "/login/emailvarification",
    component: <EmailVarification />,
  },
  {
    path: "/monthlyEmployeeChangeRequest",
    component: (
      <UserDashboard
        activePage={<MonthlyChangeReq />}
        headerTitle={"Monthly Change Request"}
      />
    ),
  },
  {
    path: "/employee/engagements",
    component: (
      <UserDashboard
        activePage={<EmployeeEngagement />}
        headerTitle={"Engagement"}
        toolTipText={
          "The log time screen allows us to track the engagements of employees. It is submitted to their employer for payroll processing. It typically includes a user-friendly interface where employees can easily enter their clock-in and clock-out times, as well as any breaks taken during their shift. This feature can help streamline the payroll process and ensure accurate compensation for employees."
        }
      />
    ),
  },
];

export const AdminRoutes = [
  {
    path: "*",
    component: (
      <UserDashboard activePage={<Dashboard />} headerTitle={"Dashboard"} />
    ),
  },
  {
    path: "/",
    component: <LoginContent />,
  },
  {
    path: "/changepassword",
    component: <ChangePassword />,
  },
  {
    path: "/dashboard",
    component: (
      <UserDashboard activePage={<Dashboard />} headerTitle={"Dashboard"} />
    ),
  },
  {
    path: "/allsettings",
    component: (
      <UserDashboard activePage={<SettingMain />} headerTitle={"Settings"} />
    ),
  },
  {
    path: "/loans",
    component: (
      <UserDashboard
        activePage={<Loans />}
        headerTitle={"Loans"}
        toolTipText={
          "A loan to an employee is a type of financial assistance provided by an employer to an employee, that must be repaid over time. This type of loan may be offered as part of an employee benefits package or as a means of helping an employee deal with unexpected financial expenses. The terms of the loan, including the interest rate and repayment schedule, will depend on the specific agreement between the employer and employee."
        }
      />
    ),
  },
  {
    path: "/advanced",
    component: (
      <UserDashboard
        activePage={<Advanced />}
        headerTitle={"Advances"}
        toolTipText={
          "An advance is a type of financial assistance where an employer provides an employee with a portion of their expected earnings in advance of their regular payday. This type of arrangement may be used to help employees cover unexpected expenses or to bridge a short-term financial gap. The advance is typically repaid through deductions from the employee's future paychecks."
        }
      />
    ),
  },
  {
    path: "/activeemployees",
    component: (
      <UserDashboard
        activePage={<EmployeeMain />}
        headerTitle={"Active Employees"}
      />
    ),
  },
  {
    path: "/inactiveemployees",
    component: (
      <UserDashboard
        activePage={<InActiveEmployees />}
        headerTitle={"Inactive Employees"}
      />
    ),
  },
  {
    path: "/employeesDetails",
    component: (
      <UserDashboard
        toolTipText={
          "Employee details refer to the personal and professional information that an organization collects and maintains about its employees. This information may include the employee's name, contact information, employment history, job title and duties, salary and benefits information, and any relevant training or certifications. Accurate and up-to-date employee details are important for effective workforce management, payroll processing, and compliance with labor laws and regulations."
        }
        activePage={<EmployeeDetails />}
        headerTitle={"Employee Details"}
      />
    ),
  },
  {
    path: "/employeeallowances",
    component: (
      <UserDashboard
        toolTipText={
          "Allowances for employees are additional forms of compensation provided by employers to cover specific expenses related to an employee's job or lifestyle. These may include a housing allowance, transportation allowance, or meal allowance, among others. Allowances are often provided as part of a larger employee benefits package and can help employees manage the costs of living and working. The specific types and amounts of allowances offered may vary depending on the organization and the employee's position and responsibilities."
        }
        activePage={<EmployeeAllowance />}
        headerTitle={"Employee Allowances"}
      />
    ),
  },
  {
    path: "/employee/increment/decrement",
    component: (
      <UserDashboard
        toolTipText={
          "An increment or decrement in an employee's salary refers to an increase or decrease in their base pay or total compensation package. Increments may be granted as a reward for good performance or to adjust for inflation, while decrements may be imposed as a disciplinary measure or due to changes in the employee's job responsibilities or performance. The amount of the increment or decrement, as well as the criteria used to determine it, will depend on the specific policies and practices of the employer."
        }
        activePage={<EmployeeIncrementDecrement />}
        headerTitle={"Employee Increments/Decrements"}
      />
    ),
  },
  {
    path: "/employee/promotion/demotion",
    component: (
      <UserDashboard
        toolTipText={
          "A promotion or demotion in an employee's salary refers to an increase or decrease in their position as a result of a change in their job title, responsibilities, or level of authority within the organization. Promotions may be granted as a reward for good performance or to recognize an employee's potential for growth, while demotions may be imposed due to poor performance or changes in the organization's needs. The amount of the promotion or demotion, as well as the criteria used to determine it, will depend on the specific policies and practices of the employer."
        }
        activePage={<EmployeePromotionDemotion />}
        headerTitle={"Employee Promotions/Demotions"}
      />
    ),
  },
  {
    path: "/employee/logworktime",
    component: (
      <UserDashboard
        activePage={<LogWorkTime />}
        headerTitle={"Log Time"}
        toolTipText={
          "The log time screen allows us to track the  working hours  of employees. It is submitted to their employer for payroll processing. It typically includes a user-friendly interface where employees can easily enter their clock-in and clock-out times, as well as any breaks taken during their shift. This feature can help streamline the payroll process and ensure accurate compensation for employees."
        }
      />
    ),
  },
  {
    path: "/attendance",
    component: (
      <UserDashboard
        toolTipText={
          "Attendance refers to the recording and tracking of employees' presence or absence in the workplace on a daily basis. This information is used by employers to monitor employee attendance and productivity, ensure compliance with attendance policies, and calculate employee wages and benefits."
        }
        activePage={<AttendanceMain />}
        headerTitle={"Attendance"}
      />
    ),
  },
  {
    path: "/dailyattendance",
    component: (
      <UserDashboard
        toolTipText={
          "Daily attendance of employees is used to get the record of employees' presence or absence in the workplace on a particular day. The clock’in and clock’out of employee can be viewed by clicking on the attendance . This information is used by employers to monitor employee attendance and productivity, ensure compliance with attendance policies, and calculate employee wages and benefits."
        }
        activePage={<DailyAttendance />}
        headerTitle={"Daily Attendance"}
      />
    ),
  },
  {
    path: "/attendance_detail",
    component: (
      <UserDashboard
        toolTipText={""}
        activePage={<AttendanceDetailMain />}
        headerTitle={"Attendance Detail"}
      />
    ),
  },
  {
    path: "/montly_report",
    component: (
      <UserDashboard
        toolTipText={
          "Shift rules are guidelines or policies that dictate how employees are assigned to specific work shifts. These rules can include factors such as seniority, skill level, availability, and scheduling preferences. The purpose of shift rules is to ensure fair and efficient distribution of work shifts among employees, while also meeting the needs of the organization."
        }
        activePage={<MonthlyReportMain />}
        headerTitle={"Monthly Report"}
      />
    ),
  },
  {
    path: "/emp_offdays",
    component: (
      <UserDashboard
        toolTipText={""}
        activePage={<EmpOffDays />}
        headerTitle={"Employee Alternate Off Days"}
      />
    ),
  },
  {
    path: "/missing_punches",
    component: (
      <UserDashboard
        toolTipText={
          "Shift rules are guidelines or policies that dictate how employees are assigned to specific work shifts. These rules can include factors such as seniority, skill level, availability, and scheduling preferences. The purpose of shift rules is to ensure fair and efficient distribution of work shifts among employees, while also meeting the needs of the organization."
        }
        activePage={<MissingPunchesReport />}
        headerTitle={"Missing Punches Report"}
      />
    ),
  },
  {
    path: "/payrolldetails",
    component: (
      <UserDashboard
        toolTipText={
          "Payroll details of an employee refer to the information related to their compensation, including their base pay, overtime pay, bonuses, and deductions such as taxes and benefits. These details are used to calculate the employee's total pay for a given pay period and to ensure that they receive the correct amount on their paycheck. Accurate and timely processing of payroll details is essential for maintaining employee satisfaction and compliance with legal and regulatory requirements."
        }
        activePage={<SalaryDetailsMain />}
        headerTitle={"Payroll Details"}
      />
    ),
  },
  {
    path: "/latearrivalreport",
    component: (
      <UserDashboard
        toolTipText={
          "A late arrival report of employee is a summary of the number of times an employee arrives late to work over a specified period of time. It typically includes information such as the date and time of the employee's late arrival and the reason for the tardiness, if provided. Late arrival reports of employee can be used by managers to monitor employee attendance, identify patterns of lateness, and address any concerns with individual employees."
        }
        activePage={<LateArrivalReportMain />}
        headerTitle={"Late Arrival Report"}
      />
    ),
  },
  {
    path: "/earlydeparturereport",
    component: (
      <UserDashboard
        toolTipText={
          "An early departure report of employee is a summary of the number of times an employee leaves work before their scheduled shift end time over a specified period of time. It typically includes information such as the date and time of the employee's early departure and the reason for leaving early, if provided. Early departure reports of employee can be used by managers to monitor employee attendance, identify patterns of early departure, and address any concerns with individual employees."
        }
        activePage={<EarlyDepartureReportMain />}
        headerTitle={"Early Departure Report"}
      />
    ),
  },
  {
    path: "/attendancedetailsreport",
    component: (
      <UserDashboard
        toolTipText={
          "An attendance detail report of employee is a summary of an individual employee's attendance records over a specified period of time. It typically includes information such as the employee's clock-in and clock-out times, any absences or tardiness, and any notes or comments related to the employee's attendance. Attendance detail reports of employee can be used by managers to monitor employee attendance, assess productivity, and identify any issues or concerns that need to be addressed."
        }
        activePage={<AtandanceDetailsReport />}
        headerTitle={"Attendance Details Report"}
      />
    ),
  },
  {
    path: "/overtimereport",
    component: (
      <UserDashboard
        toolTipText={
          "An overtime report of employee is a summary of the number of hours an employee works beyond their scheduled work hours over a specified period of time. It typically includes information such as the date and time of the overtime work and the reason for the additional hours, if provided. Overtime reports of employee can be used by managers to monitor employee productivity, track labor costs, and ensure that overtime policies are being followed appropriately."
        }
        activePage={<OvertimeReportMain />}
        headerTitle={"Overtime Report"}
      />
    ),
  },
  {
    path: "/leaveapplication",
    component: (
      <UserDashboard
        toolTipText={
          "A leave application is a formal request made by an employee to take time off from work for a specific period. This may include vacation time, sick leave, maternity or paternity leave, or other types of authorized time off. Leave applications typically include details such as the reason for the requested time off, the dates requested, and any required supporting documentation. The approval or denial of leave applications will depend on the specific policies and practices of the employer."
        }
        activePage={<LeaveApplication />}
        headerTitle={"Leave Application"}
      />
    ),
  },
  {
    path: "/cpl",
    component: (
      <UserDashboard
        activePage={<CPLMain />}
        toolTipText={""}
        headerTitle={"CPL"}
      />
    ),
  },
  {
    path: "/GazettedHolidays",
    component: (
      <UserDashboard
        toolTipText={
          "Gazetted holidays  screen is used to view and add public holidays. It typically includes important events or religious celebrations. The dates of gazetted holidays are often fixed and employers are required to provide their employees with paid leave on these days."
        }
        activePage={<GazettedHolidaysMain />}
        headerTitle={"Gazetted Holidays"}
      />
    ),
  },
  {
    path: "/Technologies",
    component: (
      <UserDashboard
        toolTipText={
          "The technologies or languages of a company refer to the software, programming languages, and other tools used to support the organization's operations and objectives. These may include enterprise resource planning (ERP) software, customer relationship management (CRM) systems, data analytics tools, and programming languages such as Java, Python, or C++. The specific technologies or languages used by a company will depend on its industry, size, and specific business needs."
        }
        activePage={<TechnologiesMain />}
        headerTitle={"Technologies"}
      />
    ),
  },
  {
    path: "/ChangeRequests",
    component: (
      <UserDashboard
        toolTipText={
          "Ticket management is the process of managing and resolving customer service or support tickets through a centralized system. It typically involves creating, assigning, and tracking tickets, as well as communicating with customers to resolve their issues or concerns. Ticket management can help organizations improve customer service quality, streamline support processes, and enhance customer satisfaction."
        }
        activePage={<ChangeinRequest />}
        headerTitle={"Change Request"}
      />
    ),
  },
  {
    path: "/Project",
    component: (
      <UserDashboard
        activePage={<ProjectMain />}
        toolTipText={
          "The software projects of a company may include a variety of applications and systems, such as customer relationship management (CRM) software, enterprise resource planning (ERP) software, mobile and web applications, and data analytics tools. These projects may involve the development of new software from scratch, the customization of existing software, or the integration of multiple software systems to create a unified solution. The specific software projects pursued by a company will depend on its industry, size, and business needs."
        }
        headerTitle={"Projects"}
      />
    ),
  },
  {
    path: "/SubscriptionHistory",
    component: (
      <UserDashboard
        activePage={<SubscriptionHistory />}
        toolTipText={
          "A project subscription history of a company refers to a record of the projects that the company has subscribed to over time, including the duration of each subscription and the associated costs. This information may be used to track the company's spending on software and technology projects, as well as to identify patterns in project usage and adoption. By analyzing project subscription history, companies can make more informed decisions about future investments in software and technology."
        }
        headerTitle={"Subscription History"}
      />
    ),
  },
  {
    path: "/ProjectSubscription",
    component: (
      <UserDashboard
        activePage={<ProjectSubscription />}
        toolTipText={
          "A project subscription of a company is a service model where customers pay a recurring fee to access ongoing support, maintenance, and updates for a specific software project or product. This model is often used for software-as-a-service (SaaS) offerings, where customers subscribe to use a cloud-based software application rather than purchasing a perpetual license. Project subscriptions may also include access to technical support, training resources, and other benefits that help customers maximize the value of the software product."
        }
        headerTitle={"Project Subscription"}
      />
    ),
  },
  {
    path: "/InactiveProjects",
    component: (
      <UserDashboard
        activePage={<InActiveProjects />}
        toolTipText={
          "Inactive projects of company refer to those initiatives that have been put on hold or stopped due to various reasons such as lack of resources, changes in business priorities, or external factors. These projects may have been in different stages of development, ranging from initial planning and design to implementation and testing.While inactive projects do not contribute to the company's current operations, they may still hold some value and could potentially be revived or repurposed in the future."
        }
        headerTitle={"Inactive Projects"}
      />
    ),
  },
  {
    path: "/Department",
    component: (
      <UserDashboard
        activePage={<DepartmentMain />}
        toolTipText={
          "Departments of a company refer to the various functional units within an organization that are responsible for carrying out specific tasks or functions to support the overall goals and objectives of the company. Common departments found in many companies include finance, human resources, marketing, operations, sales, and information technology, among others. Each department is typically headed by a manager or director who is responsible for overseeing the department's activities and ensuring that they align with the company's overall strategy and goals"
        }
        headerTitle={"Departments"}
      />
    ),
  },
  {
    path: "/Designation",
    component: (
      <UserDashboard
        activePage={<DesignationMain />}
        toolTipText={
          "Designation in a company refers to the job title or position held by an employee. Some common designations in a company include CEO, CFO, CTO, HR Manager, Marketing Manager, Sales Manager, Software Engineer, and Customer Service Representative, among others. Designations are typically used to establish a hierarchy within an organization and help define roles, responsibilities, and authority levels. They also play a crucial role in determining an employee's salary, benefits, and career growth opportunities."
        }
        headerTitle={"Designations"}
      />
    ),
  },
  {
    path: "/JobTitle",
    component: (
      <UserDashboard
        activePage={<JobTitleMain />}
        headerTitle={"Job Titles"}
        toolTipText={
          "Job titles of a company refer to the official designations given to employees based on their roles and responsibilities. These titles may vary depending on the size and structure of the company, but typically include positions such as CEO, CFO, COO, manager, supervisor, analyst, specialist, and associate. Each job title carries specific duties and expectations, and employees are often evaluated and compensated based on their performance in these roles."
        }
      />
    ),
  },
  {
    path: "/JobApplications",
    component: (
      <UserDashboard
        activePage={<JobApplication />}
        headerTitle={"Job Applications"}
        toolTipText={
          "Job titles of a company refer to the official designations given to employees based on their roles and responsibilities. These titles may vary depending on the size and structure of the company, but typically include positions such as CEO, CFO, COO, manager, supervisor, analyst, specialist, and associate. Each job title carries specific duties and expectations, and employees are often evaluated and compensated based on their performance in these roles."
        }
      />
    ),
  },
  {
    path: "/MonhtlyBreakAdjReport",
    component: (
      <UserDashboard
        activePage={<MonhtlyBreakAdjReport />}
        headerTitle={"Monhtly Break Adjusment Report "}
        toolTipText={
          "Job titles of a company refer to the official designations given to employees based on their roles and responsibilities. These titles may vary depending on the size and structure of the company, but typically include positions such as CEO, CFO, COO, manager, supervisor, analyst, specialist, and associate. Each job title carries specific duties and expectations, and employees are often evaluated and compensated based on their performance in these roles."
        }
      />
    ),
  },
  {
    path: "/SoftSkills",
    component: (
      <UserDashboard
        activePage={<ExpertiesMain />}
        headerTitle={"Soft Skills"}
        toolTipText={
          "The expertise of a company refers to its specialized knowledge, skills, and experience in a particular field or industry. This could include areas such as product development, marketing, finance, technology, or operations. A company's expertise is often a key factor in its ability to deliver high-quality products or services, innovate, and stay competitive in the marketplace."
        }
      />
    ),
  },
  {
    path: "/TodayAttendance",
    component: (
      <UserDashboard
        toolTipText={
          "Today's attendance screen is used to add and view the attendance of employees' of the current day. The attendance intime and outtime can also be updated."
        }
        activePage={<TodayAttendanceMain />}
        headerTitle={"Today Attendance"}
      />
    ),
  },
  {
    path: "/EmployeeMissingPunches",
    component: (
      <UserDashboard
        activePage={<EmployeMissingPunches />}
        toolTipText={"Missing Punches"}
        headerTitle={"Employe Missing Punches"}
      />
    ),
  },
  {
    path: "/MonthlyMissingPunches",
    component: (
      <UserDashboard
        activePage={<MonthlyMissingPunches />}
        toolTipText={"Missing Punches"}
        headerTitle={"Monthly Missing Punches"}
      />
    ),
  },
  {
    path: "/ShiftRules",
    component: (
      <UserDashboard
        activePage={<ShiftRulesMain />}
        toolTipText={
          "Shift rules of company refer to the guidelines and policies that determine the scheduling and management of employee shifts. These rules typically include information on the number of hours per shift, rest and meal break policies, overtime and pay rates, and shift rotations. Adhering to shift rules is crucial for ensuring a fair and efficient workplace, minimizing the risk of employee burnout and ensuring compliance with labor laws and regulations."
        }
        headerTitle={"Shift Rules"}
      />
    ),
  },
  {
    path: "/allowances",
    component: (
      <UserDashboard
        activePage={<AllowancesMain />}
        toolTipText={
          "Allowances refer to the financial benefits or perks that a company provides to its employees in addition to their regular salary. These benefits can include things like health insurance, retirement plans, paid time off, bonuses, and other incentives designed to attract and retain top talent. By offering competitive allowances, companies can improve employee morale and job satisfaction while also enhancing their reputation as a desirable employer in the marketplace."
        }
        headerTitle={"Allowances"}
      />
    ),
  },
  {
    path: "/loanDeductionRequest",
    component: (
      <UserDashboard
        activePage={<LoandDeductionRequest />}
        toolTipText={
          "Allowances refer to the financial benefits or perks that a company provides to its employees in addition to their regular salary. These benefits can include things like health insurance, retirement plans, paid time off, bonuses, and other incentives designed to attract and retain top talent. By offering competitive allowances, companies can improve employee morale and job satisfaction while also enhancing their reputation as a desirable employer in the marketplace."
        }
        headerTitle={"Loan Deduction Request"}
      />
    ),
  },
  {
    path: "/employeMonthlyAttendance",
    component: (
      <UserDashboard
        activePage={<EmployeMonthlyReport />}
        toolTipText={
          "Employee monthly attendance screen shows the record of each employee's presence or absence from work during a given month. Employee’s total working hours, working days and other attendance relevant information will be viewed on the screen."
        }
        headerTitle={"Employee Monthly Attendance"}
      />
    ),
  },
  {
    path: "/setting/profile",
    component: (
      <UserProfile
        activePage={<Profile />}
        toolTipText={
          "The profile settings typically provides users with options to set their user profile accordingly . Users can use this screen to update their profile details, change their password and much more depending on the software's features."
        }
        headerTitle={"Profile"}
      />
    ),
  },
  {
    path: "/setting/company",
    component: (
      <UserProfile
        activePage={<CompanyData />}
        toolTipText={
          "Company information settings refer to the configuration and management of key information related to a business, such as its name, address, logo, and contact details. Ensuring that company information settings are accurate and up-to-date is important for maintaining a professional image, building customer trust, and complying with applicable laws and regulations."
        }
        headerTitle={"My Account"}
      />
    ),
  },
  {
    path: "/setting/changepassword",
    component: (
      <UserProfile
        activePage={<ChangePassProfile />}
        toolTipText={
          "A change password screen is typically a user interface element that allows users to update their account password. This screen usually includes fields for the current password, new password, and confirmation of the new password to ensure accuracy."
        }
        headerTitle={"Change Password"}
      />
    ),
  },
  // {
  //   path: "/setting/changepassword",
  //   component: <ChangePassProfile />,
  // },
  {
    path: "/login/emailvarification",
    component: <EmailVarification />,
  },
  {
    path: "/completedChangeRequest",
    component: (
      <UserDashboard
        activePage={<CompletedChangeReq />}
        headerTitle={"Completed Change Request"}
      />
    ),
  },
  {
    path: "/pendingChangeRequest",
    component: (
      <UserDashboard
        activePage={<PendingChangeReq />}
        headerTitle={"Pending Change Request"}
      />
    ),
  },
  {
    path: "/unallocatedChangeRequest",
    component: (
      <UserDashboard
        activePage={<UnallocatedChangeReq />}
        headerTitle={"Unallocated Change Request"}
      />
    ),
  },
  {
    path: "/monthlyEmployeeChangeRequest",
    component: (
      <UserDashboard
        activePage={<MonthlyChangeReq />}
        headerTitle={"Monthly Change Request"}
      />
    ),
  },
  {
    path: "/rules",
    component: (
      <UserDashboard
        activePage={<RulesMain />}
        toolTipText={
          "Rules refers to the process of assigning access and managing screens. User can Edit, Delete, Add or View the screens. All Functions are added here."
        }
        headerTitle={"Rules"}
      />
    ),
  },
  {
    path: "/roles",
    component: (
      <UserDashboard
        activePage={<RolesMain />}
        toolTipText={
          "Role management refers to the process of assigning and managing different levels of access or permissions to users within a system or application. These roles are typically defined based on the user's responsibilities and the level of access they require to perform their tasks. It is an essential security measure to ensure that users have access to only the features and data that they need to perform their duties. It helps to prevent unauthorized access, data breaches, and other security incidents."
        }
        headerTitle={"Roles"}
      />
    ),
  },
  {
    path: "/manualloan",
    component: (
      <UserDashboard
        activePage={<ManualLoanMain />}
        toolTipText={
          "Adding a manual loan involves the process of manually entering the amount of a loan received by employee into the loan paid table."
        }
        headerTitle={"Manual Loan"}
      />
    ),
  },
  {
    path: "/tags",
    component: (
      <UserDashboard
        activePage={<Tags />}
        toolTipText={
          "Welcome to the Tag Screen! Here you can define tags. Simply create a new tag and choose a color that represents it best. You can then apply the tag to relevant items, such as emails, documents, and tasks, and easily filter and search for them later.This feature can help you stay organized and efficient, as well as improve your productivity and time management skills. So go ahead and start tagging! "
        }
        headerTitle={"Tags"}
      />
    ),
  },
  {
    path: "/engagements",
    component: (
      <UserDashboard
        activePage={<AdminEngagements />}
        toolTipText={""}
        headerTitle={"Engagements"}
      />
    ),
  },
  {
    path: "/applicants",
    component: (
      <UserDashboard
        activePage={<ApplicantsMain />}
        toolTipText={
          "Recruitment management involves the strategic planning, execution, and oversight of the entire hiring process within an organization. It encompasses activities such as job posting, candidate sourcing, resume screening, interviews, and ultimately, the selection of the right candidates to fulfill specific roles within the company. Effective recruitment management not only ensures the acquisition of top talent but also contributes to building a skilled and diverse workforce. It plays a crucial role in aligning the organization's staffing needs with its overall goals and objectives, fostering a dynamic and thriving work environment."
        }
        headerTitle={"Applicant"}
      />
    ),
  },
  {
    path: "/hiringSession",
    component: (
      <UserDashboard
        activePage={<HiringSessionMain />}
        toolTipText={
          "Recruitment management involves the strategic planning, execution, and oversight of the entire hiring process within an organization. It encompasses activities such as job posting, candidate sourcing, resume screening, interviews, and ultimately, the selection of the right candidates to fulfill specific roles within the company. Effective recruitment management not only ensures the acquisition of top talent but also contributes to building a skilled and diverse workforce. It plays a crucial role in aligning the organization's staffing needs with its overall goals and objectives, fostering a dynamic and thriving work environment."
        }
        headerTitle={"HiringSession"}
      />
    ),
  },
  {
    path: "/applicantSession",
    component: (
      <UserDashboard
        activePage={<ApplicantSessionMain />}
        toolTipText={
          "Recruitment management involves the strategic planning, execution, and oversight of the entire hiring process within an organization. It encompasses activities such as job posting, candidate sourcing, resume screening, interviews, and ultimately, the selection of the right candidates to fulfill specific roles within the company. Effective recruitment management not only ensures the acquisition of top talent but also contributes to building a skilled and diverse workforce. It plays a crucial role in aligning the organization's staffing needs with its overall goals and objectives, fostering a dynamic and thriving work environment."
        }
        headerTitle={"ApplicantSession"}
      />
    ),
  },
  {
    path: "/email",
    component: (
      <UserDashboard
        activePage={<EmailMain />}
        toolTipText={
          "Recruitment management involves the strategic planning, execution, and oversight of the entire hiring process within an organization. It encompasses activities such as job posting, candidate sourcing, resume screening, interviews, and ultimately, the selection of the right candidates to fulfill specific roles within the company. Effective recruitment management not only ensures the acquisition of top talent but also contributes to building a skilled and diverse workforce. It plays a crucial role in aligning the organization's staffing needs with its overall goals and objectives, fostering a dynamic and thriving work environment."
        }
        headerTitle={"Email Template"}
      />
    ),
  },
];

export const SuperAdminRoutes = [
  {
    path: "*",
    component: (
      <UserDashboard activePage={<Dashboard />} headerTitle={"Dashboard"} />
    ),
  },
  {
    path: "/",
    component: <LoginContent />,
  },
  {
    path: "/changepassword",
    component: <ChangePassword />,
  },
  {
    path: "/dashboard",
    component: (
      <UserDashboard activePage={<Dashboard />} headerTitle={"Dashboard"} />
    ),
  },
  {
    path: "/organizations",
    component: (
      <UserDashboard
        activePage={<Organizations />}
        headerTitle={"Organizations"}
      />
    ),
  },
];
