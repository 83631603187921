import { Box, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext, useEffect } from "react";
import BlobCard from "../Cards/BlobCards";
// import ActiveInactiveCard from "../Cards/ActiveInactiveCard";
import Graph from "../Graph/Graph";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchPresentEmployeeData,
  getDashboardActivities,
} from "../../../Redux/Slice/Dashboard/DashboardSlice";
import { logout } from "../../../Redux/Slice/LogoutSlice/LogoutSlice";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { useNavigate } from "react-router-dom";
const useStyle = makeStyles((theme) => {
  return {
    cardContainer: {
      display: "flex",
      gap: "20px",
      padding: "10px 0px",
      justifyContent: "space-evenly",
      // padding: "20px",
      [theme.breakpoints.down("lg")]: {
        flexWrap: "wrap",
        padding: "0px",
      },
    },
    GraphContainer: {
      width: "100%",
      padding: "20px",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
      [theme.breakpoints.down("md")]: {
        width: "auto",
        flex: 1,
      },
    },
    CardBox: {
      display: "flex",
      alignItems: "center",
      height: "100%",
      width: '100%',
      justifyContent: "space-between",
      gap: "20px",
      flexWrap: "wrap",

      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        padding: "0px",
      },
    },
  };
});

const DetailsCharts = () => {
  const navigate = useNavigate()
  const { setsnackBarData } = useContext(SnackBarContext);
  const { cardContainer, GraphContainer, CardBox } = useStyle();
  const { dashboarddata, IsLogin, message,dashboarddatastatus } = useSelector(getDashboardActivities);
  const dispatch = useDispatch();
  console.log(dashboarddatastatus);
  useEffect(() => {
    dispatch(FetchPresentEmployeeData());
  }, [dispatch]);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  return (
    <Box className={cardContainer}>
      <Box className={GraphContainer}>
        <Graph />
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          padding: "20px",
        }}
      >
        <Box className={CardBox}>
          {dashboarddatastatus !== "succeeded" ? (
            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (

            <>
              {dashboarddata && dashboarddata?.Active_Projects || dashboarddata?.Inactive_Projects || dashboarddatastatus!=="succeeded" || dashboarddatastatus==="succeeded" && dashboarddata !==null ? (
                <>
                  <BlobCard
                    title={"Active Projects"}
                    count={dashboarddata?.Active_Projects}
                  // description={"Total active projects"}
                  />
                  <BlobCard
                    title={"InActive Projects"}
                    count={dashboarddata?.Inactive_Projects}
                  // description={"Total inactive projects"}
                  />
                </>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src="./NoData.png"
                    alt="Waiting For Data" style={{ height: '84%', width: '95%' }} />
                </Box>

              )}

            </>
          )}
        </Box>
        {/* <Box className={CardBox}>
          {status === "loading" ? (
            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {dashboarddata?.Inactive_Projects ? (
                <>

                  <BlobCard
                    title={"InActive Projects"}
                    count={dashboarddata?.Inactive_Projects}
                  // description={"Total inactive projects"}
                  />
                </>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h1>InActive Projects</h1>
                  <img src="./NoData.png" alt="No Data Found " style={{ height: '84%', width: '95%' }} />
                </Box>

              )}

            </>
          )}
        </Box> */}

      </Box>
      {/* )} */}
    </Box>
  );
};

export default DetailsCharts;
