import {
  Box,
  Button,
  CircularProgress,
  // CircularProgress,
  Grid,
  gridClasses,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import EarlyMonthlyDetailedPrint from "./EarlyMonthlyDetailedPrint";
import SearchIcon from "@mui/icons-material/Search";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../Redux/Slice/LogoutSlice/LogoutSlice";
import axiosInstance from "../../../Utils/AxiosInstance";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});
function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport csvOptions={{ fileName: "EarlyMonthlyDetailed" }} />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  );
}
function EarlyMonthlyDetailed() {
  const navigate = useNavigate();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { header } = useStyles();
  const componentRef = useRef();
  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });
  const dispatch = useDispatch();
  const [rows, setrows] = useState([]);
  const [pageSize, setPageSize] = useState(40);
  const [IsLogin, setIsLogin] = useState(true);
  const [message, setmessage] = useState(null);
  const [monthlyDetailed, setmonthlyDetailed] = useState([]);
  const [AllMonthlyDetailed, setAllMonthlyDetailed] = useState([]);
  const [loader, setloader] = useState(false);
  const [totalTime, settotalTime] = useState("");
  const Name = useRef();
  const Date = useRef();
  // const [selectedEmployee, setselectedEmployee] = useState(null);

  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  const columns = [
    { field: "sr", headerName: "Sr #", width: 100, headerClassName: header },
    {
      field: "name",
      headerName: "Employee",
      width: 250,
      headerClassName: header,
    },
    {
      field: "date",
      headerName: "Date",
      width: 200,
      headerClassName: header,
    },
    {
      field: "time",
      headerName: "Check Out",
      width: 200,
      headerClassName: header,
    },
    {
      field: "early_time",
      headerName: "Early Time",
      width: 150,
      headerClassName: header,
    },
  ];
  useEffect(() => {
    let arr = [];

    AllMonthlyDetailed &&
      AllMonthlyDetailed?.forEach((item, idx) => {
        return arr.push({
          id: idx + 1,
          sr: idx + 1,
          date: item?.date?moment(item?.date).format("DD-MMM-YYYY"):"",
          time: item?.time?moment(item?.time, "HH:mm").format("LT"):"",
          TotalMinutes: item?.TotalMinutes,
          TotalHours: item?.TotalHours,
          early_time:
          item?.early_time? parseInt(item?.early_time.split(":")[0]) === 0 &&
            parseInt(item?.early_time.split(":")[1]) === 0
              ? 0
              : `${item?.early_time.split(":")[0]} hrs ${
                  item?.early_time.split(":")[1]
                } min `:"",
          name: item?.Name,
        });
      });

    setrows(arr);
    setmonthlyDetailed(arr);
    getTotalHourandMinutes(arr);

  }, [AllMonthlyDetailed]);
  const initialValues = {
    months: "",
  };
  const fetchAllMonthalyDetailed = async (months) => {
    setloader(true);
    try {
      const response = await axiosInstance.get(
        `allempearlydeptpm?month=${months}`
      );
      if (response?.data?.IsSuccess === true) {
        setloader(false);
        setAllMonthlyDetailed(response?.data?.Data);
      } else {
        setsnackBarData({
          type: "error",
          message: "Something went wrong",
          openToast: true,
        });
        setloader(false);
      }
    } catch (error) {
      if (error?.message.includes(401)) {
        setmessage(error?.message);
        setIsLogin(false);
      }
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
      setloader(false);
      console.error("Error fetching data:", error);
    }
  };
  const handleSubmit = async (values) => {
    fetchAllMonthalyDetailed(values?.months);
  };
  const ValidationSchema = Yup.object().shape({
    months: Yup.string().required("Please Enter months"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });
  const onTextChange = () => {
    if (Name.current.value === "" && Date.current.value === "") {
      setmonthlyDetailed(rows);
      getTotalHourandMinutes(rows);

    } else {
      let suggestions = [];
      let regex1 = new RegExp(Name.current.value, "i");
      let regex2 = new RegExp(
        moment(Date.current.value).format("DD-MMM-YYYY"),
        "i"
      );

      suggestions = rows?.filter(
        (val) =>
          (Name.current?.value === "" || regex1.test(val?.name)) &&
          (Date.current?.value === "" || regex2.test(val?.date))
      );
      getTotalHourandMinutes(suggestions);

      setmonthlyDetailed(suggestions);
    }
  };
  const getTotalHourandMinutes = (attendance) => {
    let TotalHours = 0;
    let TotalMinutes = 0;
    attendance.forEach((time) => {
      TotalMinutes += time?.TotalMinutes;
      TotalHours += time?.TotalHours;
    });

    TotalHours += Math.floor(TotalMinutes / 60);
    TotalMinutes %= 60;
    settotalTime(`${TotalHours} hrs ${TotalMinutes} min`);
  };
  return (
    <>
      <Box sx={{ display: "none" }}>
        <EarlyMonthlyDetailedPrint
          name={""}
          month={moment(formik.values.months).format("MMM-YYYY")}
          ref={componentRef}
          monthlyDetailed={monthlyDetailed}
        />
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              paddingBottom: "10px",
              width: "100%",
              padding: "10px 10px 0px 10px",
              alignItems: "center",
            }}
          >
            <TextField
              type={"month"}
              name="months"
              // variant="outlined"
              size="small"
              value={formik.values.months}
              onChange={formik.handleChange}
              error={
                Boolean(formik.errors.months) && Boolean(formik.touched.months)
              }
              helperText={Boolean(formik.errors.months) && formik.errors.months}
            />
            {loader ? (
              <CircularProgress />
            ) : (
              <Button
                type="submit"
                sx={{ padding: "5px 10px" }}
                variant="contained"
              >
                Get
              </Button>
            )}
            <Button
              onClick={() => {
                printPaymentRec();
              }}
              sx={{ padding: "5px 10px" }}
              variant="contained"
            >
              Print
            </Button>
          </Box>
          <Box
            sx={{ padding: "10px 10px 0px 10px", display: "flex", gap: "10px",width:"100%",maxWidth:"400px" }}
          >
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{ color: (theme) => theme.palette.grey.G2 }}
                    />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              type="text"
              autoComplete="off"
              placeholder="Employee"
              // className="input_border"
              id="empName"
              inputRef={Name}
              onChange={onTextChange}
            />
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{ color: (theme) => theme.palette.grey.G2 }}
                    />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              type="date"
              autoComplete="off"
              placeholder="Search"
              // className="input_border"
              inputRef={Date}
              id="date"
              onChange={onTextChange}
            />
          </Box>
        </Box>
      </form>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          paddingX: "20px",
          gap: "50px",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
          }}
        >
          Total Early Time
        </Typography>
        <Typography
          sx={{
            textDecoration: "underline",
          }}
        >
          {" "}
          {totalTime}
        </Typography>
      </Box>
      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        overflow
        sx={{ height: "calc(100vh - 193px)", padding: "10px" }}
      >
        <DataGrid
          rows={monthlyDetailed}
          columns={columns}
          onRowClick={() => {}}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          // pagination
          // hideFooter
          components={{
            Toolbar: CustomToolbar,
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />
      </Grid>
    </>
  );
}

export default EarlyMonthlyDetailed;
