import { configureStore } from "@reduxjs/toolkit";
import AttendanceSlice from "./Slice/Attendance/AttendanceSlice";
// import NewAttendanceSlice from "./Slice/Attendance/NewAttendanceSlice";
import AttendanceDetailSlice from "./Slice/AttendanceDetail/AttendanceDetailSlice";
import CPLSlice from "./Slice/CPL/CPLSlice";
import EmplyeeSlice from "./Slice/Employee/EmplyeeSlice";
import GazettedHolidaysSlice from "./Slice/GazettedHolidays/GazettedHolidaysSlice";
import MonthlyReportSlice from "./Slice/MonthlyReport/MonthlyReportSlice";
import TechnologiesSlice from "./Slice/Technologies/TechnologiesSlice";
import ChangeRequestSlice from "./Slice/ChangeRequest/ChangeRequestSlice";
import ProjectSlice from "./Slice/Project/ProjectSlice";
import ProjectSubscriptionHisSlice from "./Slice/ProjectSubscriptionHistory/ProjectSubscriptionHisSlice";
import ProjectSubScriptionSlice from "./Slice/ProjectSubscriptionSlice/ProjectSubScriptionSlice";
import DepartmentSlice from "./Slice/Department/DepartmentSlice";
import JobTitleSlice from "./Slice/JobTitle/JobTitleSlice";
import ExpertiesSlice from "./Slice/Experties/ExpertiesSlice";
import DesignationSlice from "./Slice/Designation/DesignationSlice";
import TodayAttendanceSlice from "./Slice/TodayAttendance/TodayAttendanceSlice";
import ShiftRuleSlice from "./Slice/ShiftRules/ShiftRuleSlice";
import AllowanceSlice from "./Slice/AllAllowances/AllAllowance";
import LeaveApplicationSlice from "./Slice/LeaveApplication/LeaveApplicationSlice";
import EmployeDetailSlice from "./Slice/EmployeDetail/EmployeDetailSlice";
import AddAllowancesSlice from "./Slice/Allowance/AllowanceSlice";
import IncrementDecrementSlice from "./Slice/PerformanceManagement/IncrementDecrementSlice";
import PromotionDemotionSlice from "./Slice/PerformanceManagement/PromotionDemotionSlice";
import DashboardSlice from "./Slice/Dashboard/DashboardSlice";
import EmployeMonthlyReportSlice from "./Slice/EmployeMonthlyReport/EmployeMonthlyReportSlice";
import LoansSlice from "./Slice/Loans/LoansSlice";
import AdvancedSlice from "./Slice/Advanced/AdvancedSlice";
import SalaryDetailsSlice from "./Slice/SalaryDetails/SalaryDetailsSlice";
import LateArrivalReportSlice from "./Slice/LateArrivalReport/LateArrivalReportSlice";
import EarlyDepartureReportSlice from "./Slice/EarlyDepartureReport/EarlyDepartureReportSlice";
import OvertimeReportSlice from "./Slice/OvertimeReport/OvertimeReportSlice";
import CompanyProfileSlice from "./Slice/CompanyProfile/CompanyProfileSlice";
import AtandanceDetailsReportSlice from "./Slice/AtandanceDetailsReport/AtandanceDetailsReportSlice";
import LoanDeductionRequestSlice from "./Slice/LoanDeductionRequest/LoanDeductionRequestSlice";
import RulesSlice from "./Slice/Rules/RulesSlice";
import RolesSlice from "./Slice/Roles/RolesSlice";
import ManualLoanSlice from "./Slice/ManualLoan/ManualLoanSlice";
import EmployeeDashboardSlice from "./Slice/Dashboard/EmployeeDashboardSlice";
import TagSlice from "./Slice/Tags/TagSlice";
import EngagementSlice from "./Slice/Engagements/EngagementSlice";
import EngagementEvents from "./Slice/Engagements/EngagementEvents";
import PapulateChangeRequestSlice from "./Slice/Dashboard/PapulateChangeRequestSlice";
import JobApplicationSlice from "./Slice/JobApplication/JobApplicationSlice";
import ApplicantSlice from "./Slice/Applicants/ApplicantSlice";
import OrganizationsSlice from "./Slice/Organizations/OrganizationsSlice";
import BreakaAjustmentSlice from "./Slice/BreakAdjustment/BreakaAjustmentSlice";

export const store = configureStore({
  reducer: {
    employee: EmplyeeSlice,
    cpl: CPLSlice,
    attendance: AttendanceSlice,
    attendancedetail: AttendanceDetailSlice,
    monthlyreport: MonthlyReportSlice,
    gazettedholidays: GazettedHolidaysSlice,
    technologies: TechnologiesSlice,
    ChangeRequests: ChangeRequestSlice,
    BreakAdjustments: BreakaAjustmentSlice,
    project: ProjectSlice,
    ProjectSubscription: ProjectSubScriptionSlice,
    ProjectSubscriptionHistory: ProjectSubscriptionHisSlice,
    department: DepartmentSlice,
    jobTitle: JobTitleSlice,
    experties: ExpertiesSlice,
    designation: DesignationSlice,
    todayattendance: TodayAttendanceSlice,
    shiftrules: ShiftRuleSlice,
    Allowances: AllowanceSlice,
    leaveapplication: LeaveApplicationSlice,
    employeDetail: EmployeDetailSlice,
    allowance: AddAllowancesSlice,
    IncrementDecrement: IncrementDecrementSlice,
    PromotionDemotion: PromotionDemotionSlice,
    dashboardactivity: DashboardSlice,
    Empmonthlyreport: EmployeMonthlyReportSlice,
    Loans: LoansSlice,
    Advanced: AdvancedSlice,
    CompanyProfile: CompanyProfileSlice,
    SalaryDetails: SalaryDetailsSlice,
    LateArrivalReport: LateArrivalReportSlice,
    EarlyDepartureReport: EarlyDepartureReportSlice,
    OvertimeReport: OvertimeReportSlice,
    AtandanceDetailsReport: AtandanceDetailsReportSlice,
    LoanDeduction: LoanDeductionRequestSlice,
    module: RulesSlice,
    Roles: RolesSlice,
    ManualLoan: ManualLoanSlice,
    employeedashboardactivity: EmployeeDashboardSlice,
    Tagslice: TagSlice,
    engagementSlice: EngagementSlice,
    engagementeventSlice: EngagementEvents,
    changeRequests: PapulateChangeRequestSlice,
    applications: JobApplicationSlice,
    applicants: ApplicantSlice,
    organizations: OrganizationsSlice,
  },
});
