import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  BreakAdjustments: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
  SingleAdjDetail: [],
};

export const fetchempBreakAdjustment = createAsyncThunk(
  "empgetbreakadjustment",
  async (id) => {
    try {
      const response = await axiosInstance.get(`/getbreakadjustment/${id}`);
      console.log(response.data);
      return response?.data?.Break_Adjustments;
    } catch (error) {
      console.error("Error fetching single emp record:", error);
      throw error;
    }
  }
);
export const deleteBreakAdjustment = createAsyncThunk(
  "deletebreakAdjustment",
  async (id) => {
    try {
      const response = await axiosInstance.delete(
        `/deletebreakAdjustment/${id}`
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error Deleting Adj record:", error);
      throw error;
    }
  }
);

export const fetchBreakAdjustments = createAsyncThunk(
  "getbreakadjustment",
  async () => {
    const response = await axiosInstance.get(`getbreakadjustment`);
    console.log(response);
    return response?.data?.Break_Adjustments;
  }
);
// export const fetchBreakAdjustments = createAsyncThunk(
//   "getbreakadjustment?",
//   async ({ page, pageSize }) => {
//     const response = await axiosInstance.get(
//       `getbreakadjustment?PageNum=${Number(page) + 1}&PageSize=${pageSize}`
//     );
//     console.log(response.data);
//     return response.data;
//   }
// );

export const updateBreakAdjustments = createAsyncThunk(
  "updatebreakadjustment",
  async (newData) => {
    try {
      const response = await axiosInstance.put(
        `updatebreakadjustment`,
        newData
      );
      console.log(response);
      return response.data;
    } catch (error) {
      console.error("Error updating adjustment:", error);
      throw error;
    }
  }
);

const BreakaAjustmentSlice = createSlice({
  name: "BreakAdjustments",
  initialState,
  reducers: {
    BreakAdjustmentAdded: {
      reducer(state, action) {
        console.log("payload", action.payload);
        console.log("BreakAdjustment", state);
        state.BreakAdjustments.push(action.payload);
      },
    },

    BreakAdjResetData: (state) => {
      state.BreakAdjustments = [];
      state.status = "idle";
      state.error = null;
      state.IsLogin = true;
      state.message = null;
      state.SingleAdjDetail = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchempBreakAdjustment.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchempBreakAdjustment.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        console.log("fetch", action.payload);
        state.BreakAdjustments = action?.payload;
      })
      .addCase(fetchempBreakAdjustment.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(fetchBreakAdjustments.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBreakAdjustments.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.BreakAdjustments = action?.payload;
      })
      .addCase(fetchBreakAdjustments.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(updateBreakAdjustments.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateBreakAdjustments.fulfilled, (state, action) => {
        console.log("actionpayload", action.payload);
        state.status = "succeeded";
        const reasonId = action.payload?.updatedBreakAdjustment?.reasonId;
        console.log("reasonId", reasonId);
        const index = state.BreakAdjustments.findIndex(
          (item) => item.reasonId === reasonId
        );
        if (index !== -1) {
          console.log("Updating adjustment", index);
          state.BreakAdjustments[index] =
            action.payload?.updatedBreakAdjustment;
        }
      })
      .addCase(updateBreakAdjustments.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(deleteBreakAdjustment.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteBreakAdjustment.fulfilled, (state, action) => {
        state.status = "succeeded";
        const reasonId = action.payload?.DeletedAdjustment?.reasonId;
        console.log("Deleting adjustment with reasonId:", reasonId);
        state.BreakAdjustments = state.BreakAdjustments.filter(
          (item) => item.reasonId !== reasonId
        );
      })
      .addCase(deleteBreakAdjustment.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
  },
});
export const getBreakAdjustmentDetails = (state) => state.BreakAdjustments;
export const { BreakAdjustmentAdded, BreakAdjResetData } =
  BreakaAjustmentSlice.actions;

export default BreakaAjustmentSlice.reducer;
