import { Box, CircularProgress, Grid, gridClasses, IconButton } from "@mui/material";
import React, { useContext, useEffect } from "react";
import Cookies from "universal-cookie";

import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  fetchsingleSlaryDetails,
  getSalaryDetailsDetails,
} from "../../Redux/Slice/SalaryDetails/SalaryDetailsSlice";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from "@mui/icons-material/Edit";
import EditModal from "./EditModal";
import DetailsModal from "./DetailsModal";
import moment from "moment";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});
function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport csvOptions={{ fileName: "Payroll Detail" }} />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  );
}
function EmployeSalaryDetails() {
  const navigate=useNavigate();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { header } = useStyles();
  const dispatch = useDispatch();
  const [rows, setrows] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const { EmployeeSalaryDetails, status,IsLogin,message } = useSelector(
    getSalaryDetailsDetails
  );
  const [salaryDetails, setsalaryDetails] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [EditedDataRow, setEditedDataRow] = useState(null);
  const [detailsRow, setdetailsRow] = useState(null);
  const [showModal2, setshowModal2] = useState(false);
  const cookies = new Cookies();

  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);

  console.log(rows);
  console.log(EditedDataRow);
  console.log(EmployeeSalaryDetails);
  useEffect(() => {
    dispatch(fetchsingleSlaryDetails(cookies.get("user").Emp_id));
  }, [dispatch]);
  const columns = [
    { field: "sr", headerName: "Sr #", width: 100, headerClassName: header },
    {
      field: "expected_hours",
      headerName: "Expected Hours",
      width: 150,
      headerClassName: header,
    },
    {
      field: "worked_hours",
      headerName: "Worked Hours",
      width: 150,
      headerClassName: header,
    },
    {
      field: "basic_salary",
      headerName: "Basic Salary",
      width: 200,
      headerClassName: header,
    },
    {
      field: "actual_salary",
      headerName: "Actual Salary",
      width: 150,
      headerClassName: header,
    },

    {
      field: "net_salary",
      headerName: "Net Salary",
      width: 150,
      headerClassName: header,
    },
    {
      field: "loanDeducted",
      headerName: "Loan",
      width: 180,
      headerClassName: header,
    },
    {
      field: "advance_deducted",
      headerName: "Advance",
      width: 180,
      headerClassName: header,
    },
    {
      field: "monthly",
      headerName: "Month",
      width: 180,
      headerClassName: header,
    },
    {
      field: "action",
      headerName: "Action",
      width: 180,
      headerClassName: header,
      renderCell: (celVal) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              gap: "10px",
            }}
          >
            {
              celVal?.row?.loan_deducted===0 || celVal?.row?.loan_deducted===null?null:
           <>
            <IconButton

              size={"small"}
              onClick={() => {
                setEditedDataRow(celVal.row);
                setshowModal(true);
                // setIsEdit(true);
              }}
            >
              <EditIcon fontSize="small"  />
            </IconButton>
            </>
             }
            <IconButton
            //   onClick={() => {
            //     setEditedData(celVal.row);
            //     setshowDeleteModal(true);
            //   }}
              size={"small"}
            >
              <VisibilityIcon  fontSize="small"               onClick={() => {
                setdetailsRow(celVal.row);
                setshowModal2(true);
                // setIsEdit(true);
              }} />
            </IconButton>
          </Box>
        );
      },
    },
    
  ];
  useEffect(() => {
    let arr = [];
    EmployeeSalaryDetails &&
      EmployeeSalaryDetails?.forEach((item, idx) => {
        console.log("njsd",item?.worked_hours.toString().split('.'));
        return arr.push({
          ...item,
          id: idx + 1,
          sr: idx + 1,
          worked_hours: 
          item?.worked_hours
            ? `${item?.worked_hours.toString()?.split(".")[0]} hrs ${
                (item?.worked_hours.toString()?.split(".")[1] /100 *60).toFixed(0)
              } min `
            : 0,
          basic_salary: item?.basic_salary?.toLocaleString(),
          actual_salary: item?.actual_salary?.toLocaleString(),
          net_salary: item?.net_salary?.toLocaleString(),
          loanDeducted: item?.loan_deducted?.toLocaleString(),
          monthly: moment(item?.monthly).format("MMM-YYYY"),
        });
      });

    setrows(arr);
    setsalaryDetails(arr);
  }, [EmployeeSalaryDetails]);
  return (
    <>
      <span className="ml-2 float-right">
        <div className="loaderclass"> </div>
      </span>

      {status !== "succeeded" ? (
        <Box
          sx={{
            height: "calc(100vh - 234px)",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid item md={12} sm={12} xs={12} overflow>
          <div
            style={{
              height: "calc(100vh - 170px)",
              width: "100%",
              padding: "10px",
            }}
          >
            <DataGrid
              rows={salaryDetails}
              columns={columns}
              onRowClick={() => {}}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
              pagination
              components={{
                Toolbar: CustomToolbar,
              }}
              localeText={{
                toolbarDensity: "Size",
                toolbarDensityLabel: "Size",
                toolbarDensityCompact: "Small",
                toolbarDensityStandard: "Medium",
                toolbarDensityComfortable: "Large",
              }}
            />
          </div>
        </Grid>
      )}
      <EditModal setEditedDataRow={setEditedDataRow} EditedDataRow={EditedDataRow} showModal={showModal} setshowModal={setshowModal} />
      <DetailsModal detailsRow={detailsRow} setdetailsRow={setdetailsRow} showModal2={showModal2} setshowModal2={setshowModal2} />

    </>
  );
}

export default EmployeSalaryDetails;
