import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import theme from "../../theme";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { fetchAllLoans } from "../../Redux/Slice/Loans/LoansSlice";
import { PatchaLoansadminApi } from "../../Api/Loans/PatchLoansAdminApi";
const useStyles = makeStyles(() => {
  return {
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
    },
    Container: {
      width: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    container: {
      // height: "100%",
      width: "100%",
      padding: "0px 20px 20px 20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      // padding: "20px",
      width: "100%",
      // maxWidth: "700px",
      // minHeight: "calc(100vh - 215px)",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      textAlign: "left",
    },
  };
});
function LoansApproveModal({ LoansData, showModal, setshowModal,setLoansData }) {
  const {
    Container,
    ModalContainer,
    TableHeader,
    container,
    subContainer,
    formContainer,
  } = useStyles();
  const { setsnackBarData } = useContext(SnackBarContext);
  console.log(LoansData);
  const initialValues = {
    amount: 0,
    total_month: "",
    permonth_deduction: "",
  };
 const [loading,setloading]=useState(false)
  const dispatch = useDispatch();
  useEffect(() => {
    formik.setFieldValue(
      "amount",
      LoansData?.requested_amount ? Number(LoansData?.requested_amount) : 0
    );
    formik.setFieldValue(
      "total_month",
      LoansData?.total_month ? LoansData?.total_month : null
    );
  }, [LoansData]);
  const handleSave = async (values, resetForm) => {
    setloading(true);

    const obj = { ...values, loan_id: LoansData?.loan_id, status: "Accepted" };
    const response = await PatchaLoansadminApi(obj);
    setsnackBarData(response.snackBarData);
    if (response.data.IsSuccess) {
      resetForm();
      setshowModal(false);
      setLoansData(null)
      dispatch(fetchAllLoans());
      
      setloading(false);
    }else{
      setloading(false);
    }
  };
  const validationSchema = Yup.object().shape({
    amount: Yup.string().required("Please Enter Amount"),
    total_month: Yup.string().required("Please Enter total_month"),
    permonth_deduction: Yup.string().required(
      "Please Enter Per Month Deduction"
    ),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSave(values, resetForm, setSubmitting);
    },
  });
  return (
    <>
      <Modal
        open={showModal}
        onClose={() => {
          setshowModal(false);
          setLoansData(null)
        }}
      >
        <Box className={ModalContainer}>
          <Box className={Container}>
            <Box className={TableHeader}>
              <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                Approve Loan
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setLoansData(null)
                  setshowModal(false);

                }}
              >
                <HighlightOffIcon
                  color="primary"
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Box>
            <Box className={container}>
              <Box className={subContainer}>
                <form onSubmit={formik.handleSubmit}>
                  <Box className={formContainer}>
                    <FormControl>
                      <Typography>Amount</Typography>
                      <TextField
                        sx={{ textAlign: "left", input: { color: "black" } }}
                        fullWidth
                        size="small"
                        name="amount"
                        type="number"
                        onBlur={formik.handleBlur}
                        onChange={
                          (e) => {
                            if (e.target.value > LoansData?.requested_amount) {
                              return;
                            } else {
                              formik.setFieldValue("amount", e.target.value);
                            }
                          }
                          // formik.handleChange
                        }
                        value={formik.values.amount}
                        variant="outlined"
                        error={
                          Boolean(formik.touched.amount) &&
                          Boolean(formik.errors.amount)
                        }
                        helperText={
                          Boolean(formik.touched.amount) && formik.errors.amount
                        }
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Typography>Total Months</Typography>
                      <TextField
                        sx={{ textAlign: "left", input: { color: "black" } }}
                        fullWidth
                        size="small"
                        name="total_month"
                        type="number"
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                            formik.handleChange(e)
                        //   formik.setFieldValue("total_month", e.target.value);
                          formik.setFieldValue("permonth_deduction", Number((formik.values.amount/e.target.value)?.toFixed(2)));

                        }}
                        value={formik.values.total_month}
                        variant="outlined"
                        error={
                          Boolean(formik.touched.total_month) &&
                          Boolean(formik.errors.total_month)
                        }
                        helperText={
                          Boolean(formik.touched.total_month) && Boolean(formik.errors.total_month) &&
                          formik.errors.total_month
                        }
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Typography>Per Month Deduction</Typography>
                      <TextField
                        sx={{ textAlign: "left", input: { color: "black" } }}
                        fullWidth
                        size="small"
                        name="permonth_deduction"
                        type="number"
                        onBlur={formik.handleBlur}
                        // onChange={formik.handleChange}
                        value={formik.values.permonth_deduction}
                        variant="outlined"
                        error={
                          Boolean(formik.touched.permonth_deduction) &&
                          Boolean(formik.errors.permonth_deduction)
                        }
                        helperText={
                          Boolean(formik.touched.permonth_deduction) &&
                          formik.errors.permonth_deduction
                        }
                      />
                    </FormControl>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "end",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <Box>
                          {loading ? (
                            <CircularProgress />
                          ) : (
                            <Button type="submit" variant="contained">
                              <Typography>Submit</Typography>
                            </Button>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default LoansApproveModal;
LoansApproveModal.propTypes = {
  showModal: PropTypes.any,
  setshowModal: PropTypes.any,
  LoansData: PropTypes.any,
  setLoansData: PropTypes.any,
};
