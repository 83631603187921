import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";
const initialState = {
  Advanced: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,

};
export const fetchAdvanced = createAsyncThunk(
  "getallempadvancebyId",
  async (id) => {
    const response = await axiosInstance.get(`getallempadvance?id=${id}`);
    console.log(response)
    return response.data;
  }
);
export const fetchAllAdvanced = createAsyncThunk(
  "getallempadvance",
  async () => {
    const response = await axiosInstance.get(`getallempadvance`);
    console.log(response)
    return response.data;
  }
);
const AdvancedSlice = createSlice({
  name: "Advanced",
  initialState,
  reducers: {
    AdvancedAdded: {
      reducer(state, action) {
        state.Advanced?.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAdvanced.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAdvanced.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Advanced = action.payload;
      })
      .addCase(fetchAdvanced.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(fetchAllAdvanced.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllAdvanced.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Advanced = action.payload;
      })
      .addCase(fetchAllAdvanced.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
  },
});
export const getAdvancedDetails = (state) => state.Advanced;

export const { AdvancedAdded } = AdvancedSlice.actions;

export default AdvancedSlice.reducer;


