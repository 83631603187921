import { Box } from '@mui/material';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import ProjectForm from '../../Components/Project/ProjectForm';
import ProjectTable from '../../Components/Project/ProjectTable';

const ProjectMain = () => {
  const [ShowForm, setShowForm] = useState(false);
  const [projData,setprojData] = useState(null)
  const [isEdited,setisEdited]=useState(false)
  const [canView, setcanView] = useState(null);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  
  useEffect(() => {
    let string = "Projects";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    let match = value?.some((val) => {
      if (val?.module?.split("_")[1] === "View") {
        return true;
      }
    });
    setcanView(match);
    console.log(match);
    if (!roles || roles.length < 1) {
      setcanView(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  return (
    <div>

      {canView === true ? (

      ShowForm ? (
        <ProjectForm
          setShowForm={setShowForm}
          isEdited={isEdited}
          setisEdited={setisEdited}
          projData={projData}
        />
      ) : (
        <ProjectTable
        setShowForm={setShowForm}
        isEdited={isEdited}
        setisEdited={setisEdited}
        setprojData={setprojData}
        />
      )
      ) : canView === false ? (
        <Navigate to={"/dashboard"} />
      ) : (
        <Box>Loading ...</Box>
      )}
  
   </div>
  )

}


export default ProjectMain;


