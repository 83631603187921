import { Box,Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment/moment';
import React from 'react'
import PropTypes from "prop-types";

const useStyles = makeStyles(() => {
    return {
        reportName: {
            display: "flex",
            justifyContent: "center",
            paddingTop: "20px"
        },
        dateBox: {
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
            gap: "20px"
        },
        mobBox: {
            display: "flex",
            gap: "6px", justifyContent: "end"
        },

    };
});
function PrintReportSetion({ Reportname, userName,Months }) {
    const { reportName, dateBox, } = useStyles()
    console.log(userName);
    return (
        <Box>
            <Box className={reportName} >
                <Typography sx={{ fontWeight: "bold" }}>{Reportname} {Months? `(${Months})`:null}</Typography>
            </Box>
            <Box className={dateBox}>
                <Typography sx={{ fontWeight: "bold" }}>{moment(new Date()).format("DD-MMM-YYYY")}</Typography>
                <Typography sx={{ fontWeight: "bold" }}>{userName}</Typography>
            </Box>

        </Box>
    )
}

export default PrintReportSetion;
PrintReportSetion.propTypes = {
    Reportname: PropTypes.any,
    userName: PropTypes.any,
    Months: PropTypes.any,
};