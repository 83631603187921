import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  MonthlyDetailed: [],
  AllMonthlyDetailed: [],
  MonthlySummary: [],
  YearlySummary: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  status1: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  status2: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  status3: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,

};

export const fetchMonthalyDetailed = createAsyncThunk(
  "singempearlydeptpm?",
  async ({emp_id,months}) => {
    const response = await axiosInstance.get(`singempearlydeptpm?emp_id=${emp_id}&month=${months}`);

    return response.data;
  }
);
export const fetchAllMonthalyDetailed = createAsyncThunk(
  "allempearlydeptpm?",
  async (months) => {
    const response = await axiosInstance.get(`allempearlydeptpm?month=${months}`);

    return response.data;
  }
);
export const fetchEarlyMonthlySummary = createAsyncThunk(
    "getallearlydept?",
    async (months) => {
      const response = await axiosInstance.get(`getallearlydept?month=${months}`);
    
      return response.data;
    }
  );
  export const fetchYearlySummary = createAsyncThunk(
    "getoneyearearlydeptdatasingleemp?year=2023&emp_id=71db5c7d-bff4-4664-890e-d50cd5aa80b1?",
    async ({emp_id,year}) => {
      const response = await axiosInstance.get(`getoneyearearlydeptdatasingleemp?year=${year}&emp_id=${emp_id}`);

      return response.data;
    }
  );

const EarlyDepartureReportSlice = createSlice({
  name: "EarlyDepartureReport",
  initialState,
  reducers: {
    MonthlyDetailedAdded: {
      reducer(state, action) {
        state.MonthlyDetailed?.push(action.payload);
      },
    },
    MonthlySummaryAdded: {
      reducer(state, action) {
        state.MonthlySummary?.push(action.payload);
      },
    },
    YearlySummaryAdded: {
      reducer(state, action) {
        state.YearlySummary?.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMonthalyDetailed.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMonthalyDetailed.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.MonthlyDetailed = action.payload.Data;
      })
      .addCase(fetchMonthalyDetailed.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {         
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(fetchAllMonthalyDetailed.pending, (state) => {
        state.status1 = "loading";
      })
      .addCase(fetchAllMonthalyDetailed.fulfilled, (state, action) => {
        state.status1 = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.AllMonthlyDetailed = action.payload.Data;
      })
      .addCase(fetchAllMonthalyDetailed.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status1 = "failed";
        }
      })
      .addCase(fetchEarlyMonthlySummary.fulfilled, (state, action) => {
        state.status2 = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.MonthlySummary = action.payload.Data;
      })
      .addCase(fetchEarlyMonthlySummary.pending, (state) => {
        state.status2 = "loading";
      })
      .addCase(fetchEarlyMonthlySummary.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status2 = "failed";
        }
      })
      .addCase(fetchYearlySummary.pending, (state) => {
        state.status3 = "loading";
      })
      .addCase(fetchYearlySummary.fulfilled, (state, action) => {
        state.status3 = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.YearlySummary = action.payload.Data;
      })
      .addCase(fetchYearlySummary.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {         
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status3 = "failed";
        }
      })
  },
});

export const getEarlyDepartureReportDetails = (state) => state.EarlyDepartureReport;

export const { YearlySummaryAdded,MonthlySummaryAdded,MonthlyDetailedAdded } = EarlyDepartureReportSlice.actions;

export default EarlyDepartureReportSlice.reducer;