import React, { useContext } from "react";
import moment from "moment";

import {
  Box,
  Button,
  FormControl,
  Typography,
  TextField,
  CircularProgress,
  // MenuItem,
  Autocomplete,
} from "@mui/material";
import theme from "../../theme";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Field, Form, Formik } from "formik";
import { object, string } from "yup";
import { useEffect } from "react";
import { getattendanceDetails } from "../../Redux/Slice/Attendance/AttendanceSlice";
import { useDispatch, useSelector } from "react-redux";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useState } from "react";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles(() => {
  return {
    input_typography: {
      textAlign: "left",
      fontSize: `15px !important`,

      marginTop: "10px !important",
      paddingRight: "20px !important",
    },
    Form_input: {
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,
    },
    form_main: {
      padding: "5% 30%",
      [theme.breakpoints.down("lg")]: {
        padding: "5% 9%",
      },
    },

    btns: {
      width: "80px",
      color: "#ffff",
    },
    inputBox: {
      width: "100%",
    },
    FieldInRow: {
      display: "flex",
      justifyContent: "space-between",
      gap: "25px",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },
  };
});

export const FormIkForm = ({
  setShowForm,
  isEdit,
  setisEdit,
  setSelectedRow,
  attendancedata,
  setattendancedata,
  empName,
  handelSave,
  Allemployees,
}) => {
  const { Form_input, input_typography, btns, inputBox, FieldInRow } =
    useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { AttendanceDetails, IsLogin, message } = useSelector(getattendanceDetails);
  const { setsnackBarData } = useContext(SnackBarContext);

  const [text] = useState("");
  const [disabled, setdisabled] = useState(false);
  const [formData, setformData] = useState({
    id: "",
    in_time: "",
    out_time: "",
    date: "",
    emp_id: "",
  });
  useEffect(() => {
    if (isEdit) {
      setdisabled(true);
    }
  }, []);
  console.log(Allemployees, text);
  useEffect(() => {
    console.log(text);
  }, [text]);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  useEffect(() => {
    if (
      AttendanceDetails?.status === "succeeded" ||
      AttendanceDetails?.status === "failed"
    ) {
      if (AttendanceDetails?.isSuccess === true) {
        setSelectedRow(null);
        setformData({
          id: "",
          in_time: "",
          out_time: "",
          date: "",
          emp_id: "",
        });
      }
    }
  }, [AttendanceDetails]);
  console.log(attendancedata);
  const handelSubmitForm = (values, formikHelpers) => {
    values = { ...values, emp_id: values?.emp_id?.id };

    if (isEdit) {
      let Data = {
        attendance_id: values?.attendance_id,
        out_time: moment(
          `${values?.date}T${moment(
            moment(values?.out_time, "HH:mm").format("hh:mm A"),
            "HH:mm:ss a"
          ).format("HH:mm")}`
        )
          .format()
          .slice(0, 19),
        in_time: moment(
          `${values?.date}T${moment(values?.in_time, "HH:mm:ss a").format(
            "HH:mm"
          )}`
        )
          .format()
          .slice(0, 19),
      };
      console.log(Data);

      handelSave(Data, formikHelpers);
    } else {
      let Data = {};

      Data = {
        emp_id: values?.emp_id,
        out_time: moment(`${values?.date}T${values?.out_time}`)
          .format()
          .slice(0, 19),
        in_time: moment(`${values?.date}T${values?.in_time}`)
          .format()
          .slice(0, 19),
        date: values?.date,
      };
      console.log(Data);
      handelSave(Data, formikHelpers);
    }
  };

  const validateIsNotEdit = {
    date: string().required("This field is required"),
    in_time: string().required("This field is required"),
    emp_id: object().required("This field is required"),
    out_time: string().required("This field is required"),
  };
  const isEditValidate = {
    date: string().required("This field is required"),
    in_time: string().required("This field is required"),
    out_time: string().required("This field is required"),
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "20px",
      }}
    >
      <Formik
        initialValues={isEdit ? attendancedata : formData}
        validationSchema={object(isEdit ? isEditValidate : validateIsNotEdit)}
        onSubmit={(values, formikHelpers) => {
          handelSubmitForm(values, formikHelpers);
        }}
      >
        {({ errors, touched, isSubmitting, values, handleChange }) => {
          return (
            <Form style={{ width: "100%", maxWidth: "900px" }}>
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  width: "100%",
                }}
              >
                <Box className={FieldInRow}>
                  {isEdit === false ? (
                    <>
                      <Box className={inputBox}>
                        <Typography className={input_typography}>
                          Employee
                        </Typography>
                        {/* <FormControl fullWidth sx={{ textAlign: "left" }}>
                          <Field
                            size="small"
                            name="emp_id"
                            as={TextField}
                            value={values.emp_id}
                            select
                            onChange={(e) => {
                              settext(
                                e.target.value.time_zone
                                  ? JSON.parse(e.target.value.time_zone).label
                                  : ""
                              );
                              handleChange(e);
                            }}
                            variant="outlined"
                            type="number"
                            color="primary"
                            sx={{ input: { color: "black" } }}
                            className={Form_input}
                            fullWidth
                            error={
                              Boolean(errors.emp_id) && Boolean(touched.emp_id)
                            }
                            helperText={
                              Boolean(touched.emp_id) && errors.emp_id
                                ? Boolean(touched.emp_id) && errors.emp_id
                                : text
                            }
                          >
                            <MenuItem value="">Select Employee</MenuItem>
                            {Allemployees?.map((employee) => {
                              return (
                                <MenuItem value={employee} key={employee?.id}>
                                  {employee?.name}
                                </MenuItem>
                              );
                            })}
                          </Field>
                        </FormControl> */}
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          name="emp_id"
                          options={Allemployees}
                          getOptionLabel={(option) => option.name}
                          className={Form_input}
                          fullWidth
                          // value={values.emp_id}
                          size="small"
                          onChange={(e, val) => {
                            console.log(e, val);
                            handleChange({
                              target: {
                                name: "emp_id",
                                value: val
                              }
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="emp_id"
                              placeholder="Employee"
                              error={
                                Boolean(errors.emp_id) &&
                                Boolean(touched.emp_id)
                              }
                              helperText={
                                Boolean(touched.emp_id) && errors.emp_id
                                  ? Boolean(touched.emp_id) && errors.emp_id
                                  : text
                              }
                            />
                          )}
                        />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box className={inputBox}>
                        <Typography className={input_typography}>
                          Employe Name
                        </Typography>
                        <FormControl fullWidth sx={{ textAlign: "left" }}>
                          <Field
                            size="small"
                            name="employeename"
                            as={TextField}
                            value={empName}
                            disabled
                            variant="outlined"
                            type="text"
                            color="primary"
                            sx={{ input: { color: "black" } }}
                            className={Form_input}
                            fullWidth
                          />
                        </FormControl>
                      </Box>
                    </>
                  )}

                  <Box className={inputBox}>
                    <Typography className={input_typography}>Date</Typography>
                    <FormControl fullWidth sx={{ textAlign: "left" }}>
                      <Field
                        value={values.date}
                        disabled={disabled}
                        size="small"
                        type="date"
                        name="date"
                        as={TextField}
                        onChange={handleChange}
                        variant="outlined"
                        color="primary"
                        className={Form_input}
                        inputProps={{
                          max: `${moment()
                            .subtract(1, "day")
                            .format("YYYY-MM-DD")}`,
                        }}
                        fullWidth
                        error={Boolean(errors.date) && Boolean(touched.date)}
                        helperText={Boolean(touched.date) && errors.date}
                      />
                    </FormControl>
                  </Box>
                </Box>

                <Box className={FieldInRow}>
                  <Box className={inputBox}>
                    <Typography className={input_typography}>
                      In Time
                    </Typography>
                    <FormControl fullWidth sx={{ textAlign: "left" }}>
                      <Field
                        value={
                          values?.in_time && isEdit && attendancedata?.in_time
                            ? moment(values?.in_time, "hh:mm a").format("hh:mm")
                            : values?.in_time
                        }
                        type="time"
                        disabled={attendancedata?.in_time ? true : false}
                        size="small"
                        name="in_time"
                        onChange={handleChange}
                        as={TextField}
                        variant="outlined"
                        color="primary"
                        className={Form_input}
                        fullWidth
                        error={
                          Boolean(errors.in_time) && Boolean(touched.in_time)
                        }
                        helperText={Boolean(touched.in_time) && errors.in_time}
                      />
                    </FormControl>
                  </Box>

                  <Box className={inputBox}>
                    <Typography className={input_typography}>
                      Out Time
                    </Typography>
                    <FormControl
                      className={Form_input}
                      fullWidth
                      sx={{ textAlign: "left" }}
                    >
                      <Field
                        value={
                          values?.out_time && isEdit && attendancedata?.out_time
                            ? moment(values?.out_time, "hh:mm a").format(
                              "hh:mm"
                            )
                            : values?.out_time
                        }
                        onChange={handleChange}
                        size="small"
                        type="time"
                        name="out_time"
                        disabled={attendancedata?.out_time ? true : false}
                        as={TextField}
                        variant="outlined"
                        color="primary"
                        sx={{ input: { color: "black" } }}
                        className={Form_input}
                        fullWidt
                        error={
                          Boolean(errors.out_time) && Boolean(touched.out_time)
                        }
                        helperText={
                          Boolean(touched.out_time) && errors.out_time
                        }
                      />
                    </FormControl>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    float: "right",
                    display: "flex",
                    gap: "10px",
                    marginTop: "20px",
                  }}
                  spacing={2}
                >
                  <>
                    <Button
                      className={btns}
                      variant="contained"
                      color="error"
                      onClick={() => {
                        setShowForm(false);
                        setisEdit(false);
                        setattendancedata(null);
                        setSelectedRow(null);
                      }}
                    >
                      Cancel
                    </Button>
                    {isSubmitting ? (
                      <CircularProgress />
                    ) : (
                      <>
                        {isEdit === true ? (
                          <Button
                            type="submit"
                            className={btns}
                            variant="contained"
                          >
                            Update
                          </Button>
                        ) : (
                          <Button
                            type="submit"
                            className={btns}
                            variant="contained"
                            color="primary"
                          >
                            Add
                          </Button>
                        )}
                      </>
                    )}
                  </>
                </Box>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
export default FormIkForm;
FormIkForm.propTypes = {
  setShowForm: PropTypes.any,
  SelectedRow: PropTypes.any,
  setSelectedRow: PropTypes.any,
  isEdit: PropTypes.any,
  setisEdit: PropTypes.any,
  attendancedata: PropTypes.any,
  setattendancedata: PropTypes.func,

  handelSave: PropTypes.func,
  Allemployees: PropTypes.any,
  setAllemployees: PropTypes.any,
  empName: PropTypes.any,
};
