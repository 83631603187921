import {
  Box,
  // Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  // Typography
} from "@mui/material";
import React, { Component } from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import PrintHeader from "../Print/PrintHeader/PrintHeader";
import PrintReportSetion from "../Print/PrintReportSection/PrintReportSetion";

class SalaryDetailsPrint extends Component {
  render() {
    const cookies = new Cookies();
    return (
      <Box sx={{ width: "100%", padding: "10px" }}>
        <PrintHeader />
        <PrintReportSetion
          Reportname={"Payroll detail"}
          userName={cookies?.get("user")?.username}
        />
        <Box>
          <TableContainer component={Paper} sx={{ overflowY: "scroll" }}>
            <Table
              sx={{
                minWidth: { md: "auto", xs: "auto" },
                overflow: "scroll",
              }}
              aria-label="simple table"
              size="small"
            >
              <TableHead>
                <TableRow size="small">
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      // color: (theme) => theme.palette.grey["500"],
                    }}
                  >
                    Sr #
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      // color: (theme) => theme.palette.grey["500"],
                    }}
                  >
                    Department
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                      // color: (theme) => theme.palette.grey["500"],
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Expected Hours
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Worked Hours
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Basic Salary
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Actual Salary
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Net Salary
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.salaryDetails?.map((item, i) => {
                  return (
                    <TableRow
                      key={i}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell size="small" align="left">
                        {item?.sr}
                      </TableCell>
                      <TableCell size="small" align="left">
                        {item?.Department_Name}
                      </TableCell>
                      <TableCell size="small" align="left">
                        {item?.emp_name}
                      </TableCell>
                      <TableCell size="small" align="left">
                        {item?.expected_hours}
                      </TableCell>
                      <TableCell size="small" align="left">
                        {item?.worked_hours}
                      </TableCell>
                      <TableCell size="small" align="left">
                        {item?.basic_salary}
                      </TableCell>
                      <TableCell size="small" align="left">
                        {item?.actual_salary}
                      </TableCell>
                      <TableCell size="small" align="left">
                        {item?.net_salary}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {/* <Box sx={{ width: "100%", display: "flex", justifyContent: "end", paddingTop: "20px" }}>
          <Box sx={{
            display: "flex", flexDirection: "column", width: "25%", gap: "10px"
          }}>
            <Box sx={{
              display: "flex", justifyContent: "space-between", gap: "20px"
            }}>
              <Typography sx={{ fontWeight: "bold" }}> Sub Total</Typography>
              <Typography sx={{ fontWeight: "bold" }}> 60986</Typography>
            </Box>
            <Divider sx={{ width: "100%" }} />
            <Box sx={{
              display: "flex", justifyContent: "space-between", gap: "20px"
            }} >
              <Typography sx={{ fontWeight: "bold" }}> Taxes</Typography>
              <Typography sx={{ fontWeight: "bold" }}> 60986</Typography>
            </Box>
            <Divider sx={{ width: "100%" }} />
            <Box sx={{
              display: "flex", justifyContent: "space-between", gap: "20px"
            }}>
              <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}> Total</Typography>
              <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}> 60986</Typography>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ width: "100%", paddingTop: "10px", marginBottom: "10px" }} /> */}
      </Box>
    );
  }
}

export default SalaryDetailsPrint;
SalaryDetailsPrint.propTypes = {
  salaryDetails: PropTypes.any,
};
