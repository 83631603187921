import {
  Button,
  CircularProgress,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useState } from "react";
import theme from "../../theme";
import { PatchChangeInReqClientApi } from "../../Api/ChangeinReqApi/PatchChangeInClientApi";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";

import {
  ChangeRequestClientStatusUpdated,
  fetchChangeRequest,
  fetchsingleEmpChangeReq,

} from "../../Redux/Slice/ChangeRequest/ChangeRequestSlice";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";

const useStyles = makeStyles(() => {
  return {
    inputBox: {
      width: "100%",
      minWidth: "350px",
      [theme.breakpoints.down("md")]: {
        minWidth: "200px",
      },
    },
    closeIson: {
      float: "right",
    },
    input_typography: {
      textAlign: "left",
      fontSize: `17px !important`,
      marginTop: "10px",
    },
  };
});

const ClientStatusForm = ({ filterClientStatus, setShowUpdateForm }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const cookies = new Cookies();

  const [loader, setloader] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [disabledRejection, setDisabledRejection] = useState(false);


  const { inputBox, input_typography } = useStyles();
  useEffect(() => {
    if (filterClientStatus?.AmendStatus === "pending") {
      setDisabledRejection(true);
    }
  }, []);
  useEffect(() => {
    if (filterClientStatus?.client_status === "approved") {
      setIsDisabled(true);
    }
  }, []);

  const dispatch = useDispatch();
  const [statusUpdate] = useState([
    {
      statusValue: "Fresh",
      statusName: "Fresh",
    },
    {
      statusValue: "approved",
      statusName: "approved",
    },

    {
      statusValue: "rejected",
      statusName: "rejected",
    },
  ]);
  const [amendStatus] = useState([
    {
      statusValue: "pending",
      statusName: "pending",
    },
  ]);
  let ret = false;

  const UpdateClientStatus = async (values, formikHelpers) => {

    if (values.client_status === "approved") {

      if(values?.approval_date==='')
      {
        formikHelpers.setSubmitting(false);
        return setsnackBarData({openToast:true,message:"please select approval date",type:'error'})
      }
      const response = await PatchChangeInReqClientApi({
        request_id: values.request_id,
        client_status: values.client_status,
        approval_date: values.approval_date,
        timeline_id: values.timeline_id?.timeline_id,
      });

      setsnackBarData(response.snackBarData);
      if (response.data.IsSuccess) {
        if (cookies.get("user")?.type === "Employee") {
          dispatch(fetchsingleEmpChangeReq(cookies.get("user").Emp_id));
        } else if (cookies.get("user")?.type === "Admin") {
            dispatch(fetchChangeRequest({ page: 0, pageSize: 10 }));
        }
        formikHelpers.resetForm();
        setShowUpdateForm("Table");
      }
    }
    formikHelpers.setSubmitting(false);
  };
  const handleRejectedStatus = async (values) => {
    setloader(true);
    if (
      values.amend_status === "" ||
      values.rejection_date === "" ||
      values.description === ""
    ) {
      setsnackBarData({
        type: "error",
        message: "Please Add Required Data",
        openToast: true,
      });
      setloader(false);

      return;
    }
    if (values.client_status === "rejected") {
      const response2 = await PatchChangeInReqClientApi({
        request_id: values.request_id,
        client_status: values.client_status,
        rejection_date: values.rejection_date,
        amend_status: values.amend_status,
        description: values.description,
      });
      setsnackBarData(response2.snackBarData);

      if (response2.data.IsSuccess) {
        setloader(false);
        handleClose();
        setShowUpdateForm("Table");
        dispatch(
          ChangeRequestClientStatusUpdated(response2.data.New_amendement)
        );
        if (cookies.get("user")?.type === "Employee") {
          dispatch(fetchsingleEmpChangeReq(cookies.get("user").Emp_id));
        } else if (cookies.get("user")?.type === "Admin") {
            dispatch(fetchChangeRequest({ page: 0, pageSize: 10 }));
        }
      }
      setloader(false);
    }
  };
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        minHeight: "calc(100vh - 175px)",
      }}
    >
      <Formik
        initialValues={{
          request_id: filterClientStatus?.request_id,
          client_status: filterClientStatus
            ? filterClientStatus.client_status
            : "",
          approval_date: "",
          rejection_date: "",
          timeline_id: filterClientStatus?.req_timeline,
          amend_status: "",
          description: "",
        }}
        onSubmit={(values, formikHelpers) => {
          UpdateClientStatus(values, formikHelpers);
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                maxWidth: "960px",
                display: "flex",
                gap: "20px",
                flexWrap: "wrap",
              }}
            >
              <Box className={inputBox}>
                <Typography className={input_typography}>
                  Client Status
                </Typography>
                <TextField
                  sx={{ textAlign: "left" }}
                  fullWidth
                  size="small"
                  name="client_status"
                  type="text"
                  value={values.client_status}
                  select
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("client_status", e.target.value);
                    e.target.value === "rejected"
                      ? handleOpen()
                      : setOpen(false);
                  }}
                  variant="outlined"
                >
                  {statusUpdate?.map((status, ind) => {
                    if (status.statusValue === values.client_status) {
                      ret = true;
                    }
                    if (ret === true) {
                      return (
                        <MenuItem key={ind} value={status.statusValue}>
                          {status.statusName}
                        </MenuItem>
                      );
                    }
                  })}
                </TextField>
              </Box>
              {values.client_status === "approved" ? (
                <Box className={inputBox}>
                  <Typography className={input_typography}>
                    Approval date
                  </Typography>
                  <TextField
                    sx={{ textAlign: "left" }}
                    fullWidth
                    size="small"
                    name="approval_date"
                    type="date"
                    value={values.approval_date}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    disabled={isDisabled}
                  />
                </Box>
              ) : (
                ""
              )}
              {values.client_status === "rejected" ? (
                <Box>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        maxWidth: "500px",
                        padding: "10px",
                        backgroundColor: "white !important",
                      }}
                    >
                      {/* <CloseIcon onClick={()=>handleClose()} className={closeIson} /> */}

                      <Box>
                        <Typography className={input_typography}>
                          Rejection Date
                        </Typography>
                        <TextField
                          sx={{ textAlign: "left" }}
                          fullWidth
                          size="small"
                          name="rejection_date"
                          type="date"
                          value={values.rejection_date}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          disabled={disabledRejection}
                        />
                      </Box>
                      <Box>
                        <Typography className={input_typography}>
                          Amendment Status
                        </Typography>
                        <TextField
                          sx={{ textAlign: "left" }}
                          fullWidth
                          size="small"
                          name="amend_status"
                          type="text"
                          value={values.amend_status}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          select
                          disabled={disabledRejection}
                        >
                          {amendStatus?.map((amend, ind) => {
                            return (
                              <MenuItem value={amend.statusValue} key={ind}>
                                {amend.statusName}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Box>
                      <Box>
                        <Typography className={input_typography}>
                          Description
                        </Typography>
                        <TextField
                          sx={{ textAlign: "left" }}
                          fullWidth
                          size="small"
                          name="description"
                          multiline
                          minRows={3}
                          type="text"
                          value={values.description}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          disabled={disabledRejection}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          marginTop: "10px",
                          justifyContent: "end",
                        }}
                      >
                        <Button
                          sx={{ backgroundColor: "red !important" }}
                          variant="contained"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        {loader ? (
                          <CircularProgress />
                        ) : (
                          <Button
                            sx={{}}
                            variant="contained"
                            onClick={() => {
                              handleRejectedStatus(values);
                            }}
                          >
                            Save
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </Modal>
                </Box>
              ) : (
                ""
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "20px",
                paddingTop: "20px",
              }}
            >
              <Button
                sx={{ width: "120px", backgroundColor: "red !important" }}
                onClick={() => setShowUpdateForm("Table")}
                variant="contained"
              >
                Cancel
              </Button>
              {isSubmitting ? (
                <CircularProgress />
              ) : (
                <Button
                  sx={{ width: "120px" }}
                  type="submit"
                  variant="contained"
                >
                  Submit
                </Button>
              )}
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default ClientStatusForm;
ClientStatusForm.propTypes = {
  filterClientStatus: PropTypes.any,
  setShowUpdateForm: PropTypes.any,
};
