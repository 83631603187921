import PropTypes from "prop-types";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  gridClasses,
  InputAdornment,
  Modal,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import {
  fetchEmployee,
  removeSingleEmployeeData,
  UpdateEmployeeData,
} from "../../Redux/Slice/Employee/EmplyeeSlice";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";

import { UpdateEmpStatApi } from "../../Api/Employee/UpdateEmployeStatus";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import InfoIcon from "@mui/icons-material/Info";
import { HeaderContext } from "../../Context/SnackBarContext/HeaderContext/HeaderContext";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { makeStyles } from "@mui/styles";
import { useRef } from "react";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { TooltipContext } from "../../Context/TooltipContext/TooltipContent";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../Button/AddNewButton";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import Loader from "../Loader/Loader";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});

function EmployeeTable({
  setShowForm,
  setempData,
  setisEdited,
  employeeDetails,
  IsLogin,
  pageSize,
  setPageSize,
  page,
  setPage,
  setShowDetails,
}) {
  const { tabelStyle } = useContext(TabelStyleContext);

  const { header } = useStyles();
  const dispatch = useDispatch();
  const [AllEmployees, setAllEmployees] = useState([]);
  const { setheader } = useContext(HeaderContext);
  const [selectedEmployee, setselectedEmployee] = useState(null);
  const [rows, setrows] = useState([]);
  const [filterRows, setfilterRows] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [loader, setloader] = useState(false);
  const date = new Date();
  const descRef = useRef();
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [editPermit, seteditpermit] = useState(false);
  const [addPermit, setaddpermit] = useState(false);
  const { settooltipData } = useContext(TooltipContext);

  console.log(employeeDetails);

  console.log(AllEmployees);
  useEffect(() => {
    let string = "ActiveEmployees";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Edit") {
        seteditpermit(true);
      } else if (val?.module?.split("_")[1] === "Add") {
        setaddpermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      setaddpermit(true);
      seteditpermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  // console.log(editPermit);
  // console.log(addPermit);

  useEffect(() => {
    // dispatch(fetchEmployee({ page: page, pageSize: pageSize }));
    dispatch(removeSingleEmployeeData());
  }, [dispatch]);
  useEffect(() => {
if(employeeDetails){
    setAllEmployees(employeeDetails?.employee);
    setPage(employeeDetails?.PageNum);
    setPageSize(employeeDetails?.PageSize);
    settotalCount(employeeDetails?.TotalCount);
}
  }, [employeeDetails]);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: employeeDetails?.message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }

  }, [IsLogin]);
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "Active Employees" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }

  const columns = [
    { field: "id", headerName: "Sr #", headerClassName: header },
    {
      field: "name",
      headerName: "Employee",
      width: 180,
      headerClassName: header,
    },

    // {
    //   field: "cnic",
    //   headerName: "CNIC #",
    //   width: 200,
    //   headerClassName: header,
    // },

    {
      field: "phone",
      headerName: "Phone",
      width: 200,
      headerClassName: header,
    },

    // { field: "dob", headerName: "DOB", width: 180, headerClassName: header },

    {
      field: "department",
      headerName: "Department",
      width: 200,
      headerClassName: header,
    },
    {
      field: "designation",
      headerName: "Designation",
      width: 200,
      headerClassName: header,
    },
    // {
    //   field: "technology",
    //   headerName: "Technology",
    //   width: 200,
    //   headerClassName: header,
    // },
    // {
    //   field: "expertie",
    //   headerName: "Experties",
    //   width: 200,
    //   headerClassName: header,
    // },
    {
      field: "shiftrules",
      headerName: "Shift",
      width: 180,
      headerClassName: header,
    },
    // {
    //   field: "time_zone",
    //   headerName: "Time Zone",
    //   width: 250,
    //   headerClassName: header,
    // },
    {
      field: "portal_status",
      headerName: "Portal Status",
      width: 120,
      headerClassName: header,
      renderCell: (cell) => {
        // console.log(cell);
        return (
          <>
            {" "}
            <Box sx={{ display: "flex", gap: "25px", alignItems: "center" }}>
              {cell.row.portal_status ? (
                <CheckCircleIcon sx={{ cursor: "pointer", color: "green" }} />
              ) : (
                <CancelIcon sx={{ cursor: "pointer", color: "red" }} />
              )}
            </Box>
          </>
        );
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 160,
      headerClassName: header,
      renderCell: (celval) => {
        return (
          <>
            {" "}
            <Box sx={{ display: "flex", gap: "25px", alignItems: "center" }}>
              {addPermit ? (
                <Tooltip title="Update Employe">
                  <AppRegistrationIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setempData(celval.row);
                      setisEdited(true);
                      setShowForm(true);
                    }}
                  />
                </Tooltip>
              ) : null}
              <Tooltip title="Details">
                <InfoIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    settooltipData(`Welcome to the Employee Detail View Screen!
This screen is designed to provide a comprehensive view of an individual employee's information. Here, you will find details such as their name, job title, department, contact information, and more.
If you have any questions or concerns about the platform, please don't hesitate to reach out to your HR representative`);
                    // navigate("/employeesDetails", { state: celval.row });
                    setShowDetails(true);
                    setempData(celval.row);
                  }}
                />
              </Tooltip>

              {editPermit ? (
                <Tooltip title="Deactivate">
                  <AutorenewIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setselectedEmployee(celval.row);
                      handleOpen();
                    }}
                  />
                </Tooltip>
              ) : null}
            </Box>
          </>
        );
      },
    },
  ];
  const hanldeInactiveEmp = async (empRow) => {
    if (!descRef.current.value) {
      setsnackBarData({
        type: "error",
        message: "please add Reason",
        openToast: "true",
      });
      return;
    }
    setloader(true);
    const obj = {
      type: "Inactive",
      emp_id: empRow?.empId,
      date: moment(date).format("YYYY-MM-DD"),
      description: descRef.current.value,
    };
    const response = await UpdateEmpStatApi(obj);
    setsnackBarData(response.snackBarData);
    setloader(false);
    if (response.data.IsSuccess) {
      dispatch(fetchEmployee({ page: page - 1, pageSize: pageSize }));
      dispatch(UpdateEmployeeData(response.data?.History?.emp_id?.Emp_id));

      handleClose();
    }
  };
  useEffect(() => {
    let arr = [];
    if (AllEmployees) {
      AllEmployees?.forEach((item, idx) => {
        let Technology = item?.emptech?.map((tech) => {
          return tech?.technology_name;
        });
        let Expertie = item?.emp_expertise?.map((exp) => {
          return exp?.expertise_name;
        });

        let timezone = item?.shift_rules?.time_zone
          ? JSON.parse(item?.shift_rules?.time_zone)
          : {};

        return arr.push({
          id: idx + 1,
          empId: item?.Emp_id,
          name: item?.name,
          fathername: item?.father_name,
          cnic: item?.cnic,
          dob: item?.dob ? moment(item?.dob).format("DD-MMM-YYYY") : "",
          phone: item?.phone,
          shiftrules: item?.shift_rules?.shift_name,
          details: item?.details,
          status: item?.status,
          department: item?.department?.department_name,
          technology: Technology?.toString(),
          expertie: Expertie?.toString(),
          designation: item?.designation?.designation_name,
          time_zone: timezone?.label,
          portal_status: item?.portal_status,
        });
      });
    }

    setrows(arr);
    setfilterRows(arr);
  }, [AllEmployees]);

  const onTextChange = () => {
    let name = document.getElementById("employeeName").value;
    let dept = document.getElementById("employeeDept").value;
    let desig = document.getElementById("employeeDesig").value;
    if (name == "" && dept == "" && desig == "") {
      setfilterRows(rows);
    } else {
      let suggestions = [];
      const nameRegix = new RegExp(name, "i");
      const deptRegix = new RegExp(dept, "i");
      const desigRegix = new RegExp(desig, "i");
      suggestions = rows.filter(
        (val) =>
          (name === "" || nameRegix.test(val?.name)) &&
          (dept === "" || deptRegix.test(val?.department)) &&
          (desig === "" || desigRegix.test(val?.designation))
      );

      setfilterRows(suggestions);
    }
  };
  useEffect(() => {
    if (employeeDetails?.status === "failed") {
      setsnackBarData({
        type: "error",
        message: employeeDetails?.error,
        openToast: true,
      });
    }
  }, [employeeDetails]);
  return (
    <Grid
      container
      spacing={2}
      sx={{ padding: "15px 10px 6px 10px", backgroundColor: "white" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          flexWrap: "wrap",
          alignItems: "center",
          padding: "8px 7px 7px 17px",
        }}
      >
        {addPermit ? (
          <>
            {/* <Button
              sx={{
                display: "flex",
                borderRadius: 0,
                padding: "0px",
              }}
              onClick={() => {
                setheader("Employee And Education Information");
                settooltipData("fdsfsdfsdfsdf");
                setShowForm(true);
              }}
              variant="contained"
              className="tabletopbutton1 text-right"
            >
              <Typography
                sx={{
                  padding: "0px 20px",
                  textAlign: "center",
                }}
              >
                Add
              </Typography>
              <Box
                sx={{
                  padding: "8px 10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: (theme) => theme.palette.background.text,
                }}
              >
                <AddIcon />
              </Box>
            </Button> */}
            <CustomButton
              Color={"primary"}
              Icon={<AddIcon />}
              onClick={() => {
                setheader("Employee And Education Information");
                settooltipData("fdsfsdfsdfsdf");
                setShowForm(true);
              }}
              Label={"Add"}
            />
          </>
        ) : null}

        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "flex-end",
          }}
        >
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: (theme) => theme.palette.grey.G2 }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              width: "100%",
              maxWidth: "180px",
            }}
            variant="standard"
            type="text"
            autoComplete="off"
            placeholder="Employee"
            id="employeeName"
            onChange={onTextChange}
          />
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: (theme) => theme.palette.grey.G2 }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              width: "100%",
              maxWidth: "200px",
            }}
            variant="standard"
            type="text"
            autoComplete="off"
            placeholder="Department"
            id="employeeDept"
            onChange={onTextChange}
          />
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: (theme) => theme.palette.grey.G2 }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              width: "100%",
              maxWidth: "200px",
            }}
            variant="standard"
            type="text"
            autoComplete="off"
            placeholder="Designation"
            id="employeeDesig"
            onChange={onTextChange}
          />
        </Box>
      </Box>
      {(employeeDetails?.employee?.length<=0 && employeeDetails?.employeeStatus !== "succeeded" &&
      employeeDetails?.status !== "failed") || employeeDetails?.employeeStatus==="loading" ? (
        <>
          <Loader />
        </>
      ) : null}
      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        overflow
        sx={{ height: tabelStyle.height, overflowX: "visible" }}
      >
        <DataGrid
          style={{ overflow: "visible" }}
          disableColumnMenu
          scrollbarSize={50}
          // hideFooterSelectedRowCount={true}
          rows={filterRows}
          columns={columns}
          rowCount={totalCount}
          pageSize={pageSize}
          page={Number(page) - 1}
          onPageChange={(newPage) => {

            setPage(newPage + 1);
            // if(employeeDetails?.status==="loading"){

            // }
            if (
              Number(employeeDetails?.PageNum) < newPage + 1 &&
              AllEmployees?.length < pageSize * Number(newPage + 1)
            ) {
              dispatch(fetchEmployee({ page: newPage, pageSize: pageSize }));
            }
            // else if(allChangeData?.length < pageSize*(Number(newPage+1))){
            //   dispatch(fetchChangeRequest({page:newPage,pageSize:pageSize}))

            // }
          }}
          onPageSizeChange={(newPageSize) => {
            if (AllEmployees?.length >= newPageSize) {
              setPage(1);

              setPageSize(newPageSize);
            } else if (newPageSize >= AllEmployees?.length) {
              // dispatch(RemoveChangeRequest())
              setPage(1);

              dispatch(
                fetchEmployee({ page: Number(0), pageSize: newPageSize })
              );
            }
          }}
          rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          pagination
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{
            toolbar: { csvOptions: { fileName: "test" } },
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />
      </Grid>

      <Modal
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
        }}
        open={open}
        onClose={() => {
          setselectedEmployee(null);
          handleClose();
        }}
      >
        <Paper sx={{ width: "100%", maxWidth: "500px", padding: "10px" }}>
          <Typography sx={{ textAlign: "left" }}>Reason</Typography>
          <TextField
            placeholder={`Please Enter Reason to InActive ${selectedEmployee?.name}`}
            sx={{ width: "100%", maxWidth: "500px" }}
            variant="outlined"
            multiline
            minRows={4}
            inputRef={descRef}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              paddingTop: "10px",
              gap: "10px",
            }}
          >
            <Button
              sx={{
                background: "red",
                color: "white !important",
                fontSize: "13px",
                textTransform: "none",
              }}
              onClick={() => {
                setselectedEmployee(null);
                handleClose();
              }}
            >
              Cancel
            </Button>
            {loader ? (
              <CircularProgress />
            ) : (
              <Button
                sx={{
                  background: "#3480FA",
                  color: "white !important",
                  fontSize: "13px",

                  textTransform: "none",
                }}
                onClick={() => {
                  hanldeInactiveEmp(selectedEmployee);
                }}
              >
                Save
              </Button>
            )}
          </Box>
        </Paper>
      </Modal>
    </Grid>
  );
}

export default EmployeeTable;

EmployeeTable.propTypes = {
  setShowForm: PropTypes.any,
  setempData: PropTypes.any,
  setisEdited: PropTypes.any,
  employeeDetails: PropTypes.any,
  IsLogin: PropTypes.any,
  pageSize: PropTypes.any,
  setPageSize: PropTypes.any,
  page: PropTypes.any,
  setPage: PropTypes.any,
  setShowDetails: PropTypes.any,
};
