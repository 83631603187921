import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";
const initialState = {
  Loans: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};
export const fetchLoans = createAsyncThunk("getallemploanbyId", async (id) => {
  const response = await axiosInstance.get(`getallemploan?id=${id}`);

  return response.data;
});
export const fetchAllLoans = createAsyncThunk("getallemploan", async () => {
  const response = await axiosInstance.get(`getallemploan`);

  return response.data;
});
const LoansSlice = createSlice({
  name: "Loans",
  initialState,
  reducers: {
    LoansAdded: {
      reducer(state, action) {
        state.Loans?.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLoans.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLoans.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Loans = action.payload;
      })
      .addCase(fetchLoans.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {

          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(fetchAllLoans.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllLoans.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Loans = action.payload;
      })
      .addCase(fetchAllLoans.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
  },
});
export const getLoansDetails = (state) => state.Loans;

export const { LoansAdded } = LoansSlice.actions;

export default LoansSlice.reducer;
