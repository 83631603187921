import {
    IconButton,
    Modal,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    Typography,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
const useStyles = makeStyles(() => {
    return {
        ModalContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            padding: "10px",
            width: "100%",
        },
        Container: {
            width: "630px",
            height: '450px',
            padding: "20px",
            border: "none",
            position: "relative",
            background: "white",
            borderRadius: "10px",
            display: "flex",
            gap: "20px",
            flexDirection: "column",
        },
        TableContain: { height: "450px", overflowY: "scroll" },
        TableHeader: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        Form_input: {
            borderRadius: '3px',
            backgroundColor: '#ADD8E6 !important',
            padding: '4px'
        },
    };
});

const SkillSetModel = ({ open, setOpen, selected }) => {
    const { TableContain, Container, ModalContainer, TableHeader, Form_input } = useStyles();
console.log(selected);
    return (
        <>
            <Modal open={open}>
                <Box className={ModalContainer}>
                    <Box className={Container}>
                        <Box className={TableHeader}>
                            {selected && (
                                <Typography sx={{fontWeight:"bold"}}>{selected?.technology_name}&nbsp;Skills</Typography>
                            )}
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setOpen(!open);
                                }}
                            >
                                <HighlightOffIcon
                                    color="primary"
                                    sx={{
                                        fontSize: "28px",
                                    }}
                                />
                            </IconButton>
                        </Box>
                        <TableContainer component={Paper} className={TableContain}>
                            <Table
                                stickyHeader
                                sx={{
                                    minWidth: { md: "auto", xs: "auto" },
                                    overflow: "scroll",
                                }}
                                aria-label="simple table"
                                size="small"
                            >
                                <TableBody sx={{ display: 'flex', gap: '15px', flexWrap: 'wrap' }}>
                                    {selected &&
                                        selected?.expertise_name
                                            .split(",")
                                            .map((experties, index) => (

                                                <TableRow key={index} >
                                                    <Typography className={Form_input}>{experties.trim()}</Typography>
                                                </TableRow>
                                            ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Modal>


        </>
    );
};


export default SkillSetModel;

SkillSetModel.propTypes = {
    open: PropTypes.any,
    setOpen: PropTypes.any,
    selected: PropTypes.any,
};