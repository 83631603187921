import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import wave from "../../../Assets/wave.svg";
import PropTypes from "prop-types";
const useStyle = makeStyles((theme) => {
  return {
    container: {
      width: "100%",
      minWidth: "225px",
      padding: "10px 20px",
      gap: "20px",
      flex: 1,
      height: "100%",
      borderRadius: "10px",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
      position: "relative",
      transition: "0.5s",
      background: `url(${wave})`,
      backgroundRepeat: "no-repeat",

      minHeight: "250px",
      backgroundPosition: "center bottom",
      "&:hover": {
        transform: "scale(1.05)",
      },
    },
    contentContainer: {
      display: "flex",
      gap: "10px",
      flexDirection: "column",
      padding: "20px",
      textAlign: "left",
      color: theme.palette.grey[700],
    },
  };
});

const BlobCard = ({ title, count, description }) => {
  const { container, contentContainer } = useStyle();
  return (
    <Box className={container}>
      <Box className={contentContainer}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {title}
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {count?.toLocaleString()}
        </Typography>
        <Typography sx={{ fontWeight: "bold" }}>{description}</Typography>
      </Box>
    </Box>
  );
};

export default BlobCard;
BlobCard.propTypes = {
  title: PropTypes.any,

  count: PropTypes.any,
  description: PropTypes.any,
};
