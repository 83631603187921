import PropTypes from "prop-types";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  gridClasses,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchShiftRules,
  getshiftrulesDetails,
} from "../../Redux/Slice/ShiftRules/ShiftRuleSlice";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";

import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../Button/AddNewButton";
import InfoIcon from "@mui/icons-material/Info";
import CancelIcon from "@mui/icons-material/Cancel";
import Loader from "../Loader/Loader";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
    model_shift: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
  };
});

function ShiftRulesTable({ setShowForm, setisEdited, setshiftRowData }) {
  const { header, model_shift } = useStyles();
  const [rows, setrows] = useState([]);
  const [filterRows, setfilterRows] = useState([]);
  const [pageSize, setPageSize] = useState(10);

  const [setmessage] = useState("");
  const [setopenToast] = useState(false);
  const [setAlertType] = useState("success");
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const RulesDetails = useSelector(getshiftrulesDetails);
  const { IsLogin } = useSelector(getshiftrulesDetails);
  const { setsnackBarData } = useContext(SnackBarContext);
  const { tabelStyle } = useContext(TabelStyleContext);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [editPermit, seteditpermit] = useState(false);
  const [addPermit, setaddpermit] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);

  useEffect(() => {
    let string = "ShiftRules";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Edit") {
        seteditpermit(true);
      } else if (val?.module?.split("_")[1] === "Add") {
        setaddpermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      setaddpermit(true);
      seteditpermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  //  console.log("ssssedit",roles);
  // console.log("addPermit",addPermit);

  useEffect(() => {
    dispatch(fetchShiftRules());
  }, [dispatch]);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: RulesDetails?.message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "Shift Rules" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "id", headerName: "Sr #", width: 100, headerClassName: header },
    {
      field: "shiftname",
      headerName: "Shift",
      width: 180,
      headerClassName: header,
    },
    {
      field: "checkintime",
      headerName: "Check In",
      width: 170,
      headerClassName: header,
    },
    {
      field: "checkouttime",
      headerName: "Check Out",
      width: 170,
      headerClassName: header,
    },
    {
      field: "breakintime",
      headerName: "Break Time",
      width: 200,
      headerClassName: header,
    },
    // { field: "breakouttime", headerName: "Break Time",  },
    {
      field: "workinghrs",
      headerName: "Working Hours",
      width: 150,
      headerClassName: header,
    },
    {
      field: "dutyhrs",
      headerName: "Duty Hours",
      width: 150,
      headerClassName: header,
    },
    {
      field: "margin_min",
      headerName: "Margin min",
      width: 150,
      headerClassName: header,
    },
    {
      field: "shortbreak_mins",
      headerName: "Short Break min",
      width: 150,
      headerClassName: header,
    },
    {
      field: "time_zone",
      headerName: "Time Zone",
      width: 250,
      headerClassName: header,
    },
    {
      field: "off_days",
      headerName: "Off Days",
      width: 250,
      headerClassName: header,
    },
    {
      field: "alternate_offday",
      headerName: "Alternate Days off",
      width: 250,
      headerClassName: header,
    },
    {
      field: "action",
      headerName: "Actions",
      width: 250,
      headerClassName: header,
      renderCell: (celval) => {
        return (
          <>
            {editPermit ? (
              <Tooltip title="Update ShiftRule">
                <AppRegistrationIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setshiftRowData(celval.row);
                    setisEdited(true);
                    setShowForm(true);
                  }}
                />
              </Tooltip>
            ) : null}
            {
              <IconButton>
                <InfoIcon
                  sx={{ cursor: "pointer", color: "black !important" }}
                  onClick={() => {
                    setSelectedItem(celval.row);
                    setOpenDialog(true);
                  }}
                  size={"small"}
                />
              </IconButton>
            }
          </>
        );
      },
    },
  ];

  useEffect(() => {
    let arr = [];
    console.log(arr);
    console.log(selectedItem);
    console.log(RulesDetails?.shiftrules);

    if (RulesDetails) {
      RulesDetails?.shiftrules?.forEach((item, idx) => {
        let offDays = item?.offdays?.map((day) => {
          return day?.offday;
        });
        let offDay = item?.alternateoffday?.map((day) => {
          return day?.alternate_offday;
        });
        let timezone = JSON.parse(item?.time_zone);
        return arr.push({
          id: idx + 1,
          yearlyleaves: item?.yearly_leaves,
          checkintime: item.checkin_time
            ? moment(item?.checkin_time, "HH:mm:ss").format("hh:mm A")
            : "",
          checkouttime: item?.checkout_time
            ? moment(item?.checkout_time, "HH:mm:ss").format("hh:mm A")
            : "",
          breakintime: `${
            item?.breakin_time
              ? moment(item?.breakin_time, "HH:mm:ss").format("hh:mm A")
              : ""
          } - ${
            item?.breakout_time
              ? moment(item?.breakout_time, "HH:mm:ss").format("hh:mm A")
              : ""
          }`,
          workinghrs: item?.working_hrs,
          dutyhrs: item?.duty_hrs,
          shiftname: item?.shift_name,
          off_days: offDays.toString(),
          alternate_offdays: item.alternateoffday,
          margin_min: item?.margin_min,
          shortbreak_mins: item?.shortbreak_mins,
          time_zone: timezone?.label,
          brakeInTime: item?.breakin_time,
          brakeOutTime: item?.breakout_time,
          offDay: item?.offdays,
          TimeZone: item?.time_zone,
          checkInTime: item?.checkin_time,
          checkOutTime: item?.checkout_time,
          rule_id: item?.rule_id,
          alternate_offday: offDay.toString(),
        });
      });
    }

    setrows(arr);
    setfilterRows(arr);
  }, [RulesDetails]);

  useEffect(() => {
    //   console.log(loading);

    if (
      getshiftrulesDetails?.status === "succeeded" ||
      getshiftrulesDetails?.status === "failed"
    ) {
      // setloading(false);
      if (getshiftrulesDetails?.isSuccess === true) {
        setAlertType("success");
        setmessage(getshiftrulesDetails.message);
        setopenToast(true);
      }
      if (getshiftrulesDetails?.isSuccess === false) {
        setAlertType("error");
        setmessage(getshiftrulesDetails.message || getshiftrulesDetails.error);
        setopenToast(true);
      }
    }
  }, [getshiftrulesDetails]);

  const onTextChange = () => {
    if (document.getElementById("OrderNoFilter").value === "") {
      setfilterRows(rows);
    } else {
      let suggestions = [];

      var regex1 = new RegExp(
        document.getElementById("OrderNoFilter").value,
        "i"
      );

      suggestions = rows?.filter(
        (val) =>
          document.getElementById("OrderNoFilter").value === "" ||
          regex1.test(val.shiftname)
      );
      setfilterRows(suggestions);
    }
  };
  useEffect(() => {
    if (RulesDetails.status === "failed") {
      setsnackBarData({
        type: "error",
        message: RulesDetails?.error,
        openToast: true,
      });
    }
  }, [RulesDetails.status]);
  return (
    <>
      <Grid container spacing={2} sx={{ padding: "10px" }}>
        <Grid item md={2} xs={12} xl={2} sx={{ display: "flex", gap: "5px" }}>
          {addPermit ? (
            <>
              <CustomButton
                Color={"primary"}
                Icon={<AddIcon />}
                onClick={() => {
                  setShowForm(true);
                }}
                Label={"Add"}
              />
            </>
          ) : null}
        </Grid>
        <Grid item md={8} xs={12} xl={8}></Grid>
        <Grid item md={2} xs={12} xl={2} sx={{ textAlign: "right" }}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: (theme) => theme.palette.grey.G2 }}
                  />
                </InputAdornment>
              ),
            }}
            variant="standard"
            type="text"
            autoComplete="off"
            placeholder="Shift"
            className="input_border"
            id="OrderNoFilter"
            onChange={onTextChange}
          />
        </Grid>

        <span className="ml-2 float-right">
          <div className="loaderclass"> </div>
        </span>

        {RulesDetails?.shiftrules?.length <= 0 &&
        RulesDetails.status === "loading" ? (
          <>
            <Loader />
          </>
        ) : null}
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          overflow
          sx={{ height: tabelStyle.height }}
        >
          {/* <div style={{ height: tabelStyle.height}}> */}
          <DataGrid
            rows={filterRows}
            columns={columns}
            onRowClick={() => {}}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            pagination
            components={{
              Toolbar: CustomToolbar,
            }}
            localeText={{
              toolbarDensity: "Size",
              toolbarDensityLabel: "Size",
              toolbarDensityCompact: "Small",
              toolbarDensityStandard: "Medium",
              toolbarDensityComfortable: "Large",
            }}
          />
          {/* </div> */}
        </Grid>
      </Grid>

      {/* View Shift */}

      <Dialog open={openDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Shift Details</DialogTitle>
        <DialogContent>
          <Box className={model_shift}>
            <Box sx={{ width: "37%" }}>
              <Typography>
                {" "}
                <strong>Shift Name:</strong> &nbsp; {selectedItem.shiftname}
              </Typography>
              <Typography>
                <strong>Check-in Time:</strong> &nbsp;{selectedItem.checkintime}
              </Typography>
              <Typography>
                <strong>Check-out Time:</strong> &nbsp;
                {selectedItem.checkouttime}
              </Typography>
              <Typography>
                <strong>Break Time:</strong> &nbsp;{selectedItem.breakintime}
              </Typography>
            </Box>
            <Box sx={{ width: "37%" }}>
              <Typography>
                <strong>Working Hours:</strong> &nbsp;{selectedItem.workinghrs}
              </Typography>
              <Typography>
                <strong>Duty Hours:</strong>&nbsp; {selectedItem.dutyhrs}
              </Typography>
              <Typography>
                <strong>Minimum Margin:</strong>&nbsp; {selectedItem.margin_min}{" "}
                minutes
              </Typography>
              <Typography>
                <strong>Time Zone:</strong> &nbsp;{selectedItem.time_zone}
              </Typography>
              {selectedItem &&
                selectedItem.offDay &&
                selectedItem.offDay.map((item, index) => (
                  <Typography key={index}>
                    <strong>Off Day:</strong> &nbsp; {item.offday}
                  </Typography>
                ))}
            </Box>
          </Box>
          <Box className={model_shift}>
            <Typography
              sx={{
                fontWeight: "700",
                marginTop: "10px",
              }}
            >
              Alternate Off Days
            </Typography>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell> Day</TableCell>
                <TableCell> Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedItem &&
                selectedItem.alternate_offdays &&
                selectedItem.alternate_offdays.map((alternate, index) => (
                  <TableRow key={index}>
                    <TableCell>{alternate.alternate_offday_no}</TableCell>
                    <TableCell>{alternate.alternate_offday}</TableCell>
                    <TableCell>{alternate.alternate_offdate}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <IconButton
            sx={{ position: "absolute", top: "0px", right: "0px" }}
            onClick={() => setOpenDialog(false)}
          >
            <CancelIcon color="error" sx={{ fontSize: "25px" }} />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ShiftRulesTable;
ShiftRulesTable.propTypes = {
  setShowForm: PropTypes.any,
  setisEdited: PropTypes.any,
  setshiftRowData: PropTypes.any,
};
