import React, { useState, useEffect,useContext } from "react";
import {
  Box,
  Button,
  FormControl,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import theme from "../../theme";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { AddExpertiesApi, UpdateExpertiesApi } from "../../Api/Experties/ExpertiesAPis";
import { ExpertiesAdded } from "../../Redux/Slice/Experties/ExpertiesSlice";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
const useStyles = makeStyles(() => {
  return {
    input_typography: {
      textAlign: "left",
      
      fontSize: `15px !important`,

      marginTop: "10px !important",
      paddingRight: "20px !important",
    },
    Form_input: {
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,
    },

    form_main: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      padding: "60px 8%",
      minHeight: "calc(100vh - 175px)",
    },
    responsive: {
      maxWidth: "600px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },

    btns: {
      width: "80px",
    
    },
  };
});

const ExpertiesForm = ({ setShowForm,setIsEdit,
  setEditedData,
  IsEdit,
  EditedData}) => {
  const { setsnackBarData } = useContext(SnackBarContext);
  const [loading, setloading] = useState(false);
  const { Form_input, input_typography, btns, form_main, responsive } =
    useStyles();

  const [formData, setformData] = useState({
    skill_name: "",

  });
  const dispatch = useDispatch();

  const handelChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setformData({ ...formData, [name]: value });
  };
  const HandleAddExperties = async () => {
    if (formData === "") {
      setsnackBarData({
        type: "error",
        message: "Please Enter Experty",
        openToast: true,
      });
    } else {
      setloading(true);
      let response
      if(IsEdit){
        response = await UpdateExpertiesApi(formData);

      }
      else{
        response = await AddExpertiesApi(formData);

      }
      setsnackBarData(response.snackBarData);
      if (response.data.IsSuccess) {
        setformData({
          skill_name: "",
         
        })
        setShowForm(false);
        setIsEdit(false);
        setEditedData(null);
        dispatch(ExpertiesAdded(response.data.Expertise));
      }
      setloading(false);
    }
  };
  useEffect(() => {
    if(EditedData && IsEdit){
      setformData(EditedData);
    }
    }, [EditedData,IsEdit])
  return (
    <Box className={form_main}>
      <Box className={responsive}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
            }}
          >
            <Typography className={input_typography}>Soft Skill</Typography>
            <FormControl fullWidth sx={{ textAlign: "left" }}>
              <TextField
                size="small"
                value={formData?.skill_name}
                onChange={handelChange}
                className={Form_input}
                name="skill_name"
              />
            </FormControl>
          </Box>
        </Box>

          <Box
            sx={{
              justifyContent: "flex-end",
              display: "flex",
              gap: "10px",
            
            }}
            spacing={2}
          >
            <>
              <Button
                className={btns}
                variant="contained"
                color="error"
                onClick={() => {setEditedData(null); setShowForm(false)}}
              >
                Cancel
              </Button>
              {loading ? (
          
            <CircularProgress />
        
        ) : (
              <Button
                className={btns}
                variant="contained"
                color="primary"
                onClick={() => {
                  HandleAddExperties();
                }}
              >
               {IsEdit? "Update":"Add"}
              </Button>
               )}
            </>
          </Box>
       
      </Box>
    </Box>
  );
};

export default ExpertiesForm;
ExpertiesForm.propTypes = {
  setShowForm: PropTypes.any,
  setIsEdit: PropTypes.func,
  setEditedData: PropTypes.func,
  IsEdit: PropTypes.any,
  EditedData: PropTypes.any
};
