import { Box, Button, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      overflow: "hidden",
      gap: "0px",
      padding: "0px",
    },
    TypoContainer: {
      padding: "0px 20px",
    },
    iconContainer: {
      padding: "8px 10px",
      background: theme.palette.background.text,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };
});

const CustomButton = ({ Label, Icon, onClick, Color }) => {
  const { container, TypoContainer, iconContainer } = useStyles();
  return (
    <Button
      color={Color}
      variant="contained"
      onClick={onClick}
      className={container}
    >
      <Box className={TypoContainer}>
        <Typography>{Label}</Typography>
      </Box>
      <Box className={iconContainer}>{Icon}</Box>
    </Button>
  );
};

export default CustomButton;

CustomButton.propTypes = {
  Label: PropTypes.any,
  Icon: PropTypes.any,
  onClick: PropTypes.any,
  Color: PropTypes.any,
};
