import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  promotionDemotionHistory:[],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,

};



export const FetchPromotionDemotionHistory = createAsyncThunk(
  "get/incrementdecrementHistory",
  async () => {
    const response = await axiosInstance.get(`getpositionchng`);
    return response.data;
  }
);





const PromotionDemotionSlice = createSlice({
  name: "PromotionDemotion",
  initialState,
  reducers: {
    EmpPromDemResetData: (state) => {
      state.promotionDemotionHistory= [];
      state.isSuccess= false;
      state.message= null;
      state.status= "idle"; //'idle' | 'loading' | 'succeeded' | 'failed'
      state.error= null;
      state.IsLogin= true;
    },
  },
  extraReducers(builder) {
    builder
     .addCase(FetchPromotionDemotionHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchPromotionDemotionHistory.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.promotionDemotionHistory = action.payload.Position;
      })
      .addCase(FetchPromotionDemotionHistory.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
  },
});

export const getPromotionDemotion = (state) => state.PromotionDemotion;
export const { EmpPromDemResetData } = PromotionDemotionSlice.actions;

export default PromotionDemotionSlice.reducer;
