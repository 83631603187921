import {
  Box,
  Button,
  Typography,
  TextField,
  MenuItem,
  FormControl,
  CircularProgress,
} from "@mui/material";
window.Buffer = window.Buffer || require("buffer").Buffer;
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import Cookies from "universal-cookie";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { LeaveApplicationAPI } from "../../Api/LeaveApplication/LeaveApplicationAPI";
import { makeStyles } from "@mui/styles";
import theme from "../../theme";
import { useDispatch } from "react-redux";
import { fetchsingleEmpLeave } from "../../Redux/Slice/LeaveApplication/LeaveApplicationSlice";
import moment from "moment";
const useStyles = makeStyles(() => {
  return {
    inputBox: {
      width: "100%",
    },

    FieldInRow: {
      display: "flex",
      justifyContent: "space-between",
      gap: "20px",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },
  };
});
const LeaveApplicationForm = ({ setShowForm }) => {
  const { inputBox, FieldInRow } = useStyles();

  const { setsnackBarData } = useContext(SnackBarContext);
  const cookies = new Cookies();
  const [fieldvalues, setfieldvalues] = useState({
    start_date: '',
    end_date: '',
    leave_type: "",
    reason: "",
    type: "",
  });
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [attachment, setattachment] = useState(null);

  function handleChange(e) {
    if (e.target.name == "attachment") {
      setattachment(e.target.files[0]);
    } else {
      setfieldvalues({ ...fieldvalues, [e.target.name]: e.target.value });
    }
  }
  const handleSave = async () => {
    let user = cookies.get("user");

    setloading(true);
    if (
   
      fieldvalues?.type=="Full" &&
      fieldvalues?.end_date == ""
    ) {
      setloading(false);
      return setsnackBarData({
        type: "error",
        message: "please add end date.!!",
        openToast: true,
      });
    }
    if (
      fieldvalues?.start_date == "" ||
      fieldvalues?.reason == "" ||
      fieldvalues?.leave_type == "" ||
      fieldvalues?.type==""
    ) {
      setloading(false);
      return setsnackBarData({
        type: "error",
        message: "please fill all fields properly.!!",
        openToast: true,
      });
    }

    let formData = new FormData();
    formData.append("start_date", fieldvalues?.start_date);
    formData.append("end_date", fieldvalues?.type=="Half"?fieldvalues?.start_date:fieldvalues?.end_date);
    formData.append("reason", fieldvalues?.reason);
    formData.append("leave_type", fieldvalues?.leave_type);
    formData.append("type", fieldvalues?.type);
    formData.append("attachment", attachment);
    formData.append("emp_id", user.Emp_id);

    const response = await LeaveApplicationAPI(formData);
    setsnackBarData(response.snackBarData);

    if (!response) {
      setloading(false);
    }
    setsnackBarData(response.snackBarData);
    setloading(false);
    if (response.data.IsSuccess) {
      dispatch(fetchsingleEmpLeave(cookies.get("user").Emp_id));
      setShowForm(false);
    } else {
      setloading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          minHeight: "calc(100vh - 175px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: "20px",
        }}
      >
        <Box
          sx={{
            maxWidth: "900px",
            width: "100%",
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            flexDirection: "column",
            height: "fit-content",
          }}
        >
 
          <Box className={FieldInRow}>
            <Box className={inputBox}>
              <Typography sx={{ textAlign: "left" }}>Leave Type</Typography>
              <FormControl fullWidth sx={{ textAlign: "left" }}>
                <TextField
                  onChange={handleChange}
                  size="small"
                  name="leave_type"
                  variant="outlined"
                  value={fieldvalues?.leave_type}
                  type="text"
                  color="primary"
                  sx={{ input: { color: "black" } }}
                  fullWidth
                  required
                  select
                >
                  <MenuItem value="Casual Leave">Casual Leave</MenuItem>
                  <MenuItem value="Annual Leave">Annual Leave</MenuItem>
                </TextField>
              </FormControl>
            </Box>

            <Box className={inputBox}>
              <Typography sx={{ textAlign: "left" }}>Attach File</Typography>
              <FormControl fullWidth sx={{ textAlign: "left" }}>
                <TextField
                  onChange={handleChange}
                  name="attachment"
                  size="small"
                  variant="outlined"
                  type="file"
                  required
                  inputProps={{ accept: ".pdf,.png,.jpg,.jpeg,.docx" }}
                  color="primary"
                  sx={{ input: { color: "black" } }}
                  fullWidth
                />
              </FormControl>
            </Box>
          </Box>
          <Box className={FieldInRow}>
            <Box className={inputBox}>
              <Typography sx={{ textAlign: "left" }}>Type</Typography>
              <FormControl fullWidth sx={{ textAlign: "left" }}>
                <TextField
                  onChange={handleChange}
                  size="small"
                  name="type"
                  variant="outlined"
                  value={fieldvalues?.type}
                  type="text"
                  color="primary"
                  sx={{ input: { color: "black" } }}
                  fullWidth
                  required
                  select
                >
                  <MenuItem value="Half">Half</MenuItem>
                  <MenuItem value="Full">Full</MenuItem>
                </TextField>
              </FormControl>
            </Box>
            <Box className={inputBox}>
              <Typography sx={{ textAlign: "left" }}>Start Date</Typography>
              <FormControl fullWidth sx={{ textAlign: "left" }}>
                <TextField
                  onChange={handleChange}
                  name="start_date"
                  size="small"
                  variant="outlined"
                  value={fieldvalues?.start_date}
                  type="date"
                  color="primary"
                  inputProps={{
                    max: moment(fieldvalues?.end_date).format("YYYY-MM-DD") ,
                    input: { color: "black" },
                  }}
                  fullWidth
                />
              </FormControl>
            </Box>
          </Box>
          {
            fieldvalues?.type=="Full" ||  fieldvalues?.type==""?
          
          <Box className={FieldInRow}>
      
            <Box className={inputBox}>
              <Typography sx={{ textAlign: "left" }}>End Date</Typography>
              <FormControl fullWidth sx={{ textAlign: "left" }}>
                <TextField
                  onChange={handleChange}
                  name="end_date"
                  value={fieldvalues?.end_date}
                  size="small"
                  variant="outlined"
                  type="date"
                  color="primary"
                  inputProps={{
                    min:  moment(fieldvalues?.start_date).format("YYYY-MM-DD"),
                    input: { color: "black" },
                  }}
                  fullWidth
                />
              </FormControl>
            </Box>
            <Box className={inputBox}>
</Box>
          </Box>:null}
          <Box className={FieldInRow}>
            <Box className={inputBox}>
              <Typography sx={{ textAlign: "left" }}>Reason</Typography>
              <FormControl fullWidth sx={{ textAlign: "left" }}>
                <TextField
                  onChange={handleChange}
                  size="small"
                  name="reason"
                  variant="outlined"
                  type="text"
                  color="primary"
                  multiline
                  value={fieldvalues?.reason}
                  minRows={5}
                  sx={{ input: { color: "black" } }}
                  fullWidth
                />
              </FormControl>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              gap: "20px",
            }}
          >
            <Button
              variant="contained"
              color="error"
              sx={{ width: "80px" }}
              onClick={() => {
                setShowForm(false);
              }}
            >
              Cancel
            </Button>

            {loading == false ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                sx={{ width: "80px" }}
              >
                Save
              </Button>
            ) : (
              <CircularProgress />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LeaveApplicationForm;

LeaveApplicationForm.propTypes = {
  setShowForm: PropTypes.any,
};
