import {
  Box,
  Button,
  CircularProgress,
  // CircularProgress,
  Grid,
  gridClasses,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment/moment";
import SearchIcon from "@mui/icons-material/Search";
import EarlyMonthlySummaryPrint from "./EarlyMonthlySummaryPrint";
import { useReactToPrint } from "react-to-print";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../Redux/Slice/LogoutSlice/LogoutSlice";
import axiosInstance from "../../../Utils/AxiosInstance";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});
function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport csvOptions={{ fileName: "EarlyMonthlySummary" }} />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  );
}
function EarlyMonthlySummary() {
  const navigate = useNavigate();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { header } = useStyles();
  const dispatch = useDispatch();
  const [rows, setrows] = useState([]);
  const [pageSize, setPageSize] = useState(50);
  const [monthlySummary, setmonthlySummary] = useState([]);
  const [MonthlySummary, setMonthlySummary] = useState([]);
  const Name = useRef();
  const [loader, setloader] = useState(false);
  const [IsLogin, setIsLogin] = useState(true);
  const [message, setmessage] = useState(null);
  const [totalTime, settotalTime] = useState("");
  const componentRef = useRef();
  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  const columns = [
    { field: "sr", headerName: "Sr #", width: 100, headerClassName: header },
    {
      field: "name",
      headerName: "Employee",
      width: 250,
      headerClassName: header,
    },
    //   {
    //       field: "month",
    //       headerName: "Month",
    //       width: 200,
    //       headerClassName: header,
    //   },
    {
      field: "total_earlytime",
      headerName: "Early time",
      width: 200,
      headerClassName: header,
    },
    {
      field: "count",
      headerName: "Days Count",
      width: 200,
      headerClassName: header,
    },
  ];
  useEffect(() => {
    let arr = [];

    MonthlySummary &&
      MonthlySummary?.forEach((item, idx) => {
        return arr.push({
          ...item,
          id: idx + 1,
          sr: idx + 1,
          month: item?.month?moment(item?.month).format("MMM-YYYY"):"",
          TotalMinutes: item?.TotalMinutes,
          TotalHours: item?.TotalHours,
          total_earlytime:
          item?.total_earlytime?parseInt(item?.total_earlytime.split(":")[0]) === 0 &&
            parseInt(item?.total_earlytime.split(":")[1]) === 0
              ? 0
              : `${item?.total_earlytime.split(":")[0]} hrs ${
                  item?.total_earlytime.split(":")[1]
                } min `:"",
        });
      });

    setrows(arr);
    setmonthlySummary(arr);
    getTotalHourandMinutes(arr);

  }, [MonthlySummary]);
  const initialValues = {
    months: "",
  };
  const fetchEarlyMonthlySummary = async (months) => {
    setloader(true);
    try {
      const response = await axiosInstance.get(
        `getallearlydept?month=${months}`
      );
      if (response?.data?.IsSuccess === true) {
        setloader(false);
        setMonthlySummary(response?.data?.Data);
      } else {
        setsnackBarData({
          type: "error",
          message: "Something went wrong",
          openToast: true,
        });
        setloader(false);
      }
    } catch (error) {
      if (error?.message.includes(401)) {
        setmessage(error?.message);
        setIsLogin(false);
      }
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
      setloader(false);
      console.error("Error fetching data:", error);
    }
  };
  const handleSubmit = async (values) => {
    fetchEarlyMonthlySummary(values?.months);
  };
  const ValidationSchema = Yup.object().shape({
    months: Yup.string().required("Please Enter months"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values, resetForm);
    },
  });
  const onTextChange = () => {
    if (Name.current.value === "") {
      setmonthlySummary(rows);
      getTotalHourandMinutes(rows);

    } else {
      let suggestions = [];
      let regex1 = new RegExp(Name.current.value, "i");

      suggestions = rows?.filter(
        (val) => Name.current?.value === "" || regex1.test(val?.name)
      );

      setmonthlySummary(suggestions);
      getTotalHourandMinutes(suggestions);

    }
  };
  const getTotalHourandMinutes = (attendance) => {
    let TotalHours = 0;
    let TotalMinutes = 0;
    attendance.forEach((time) => {
      TotalMinutes += time?.TotalMinutes;
      TotalHours += time?.TotalHours;
    });

    TotalHours += Math.floor(TotalMinutes / 60);
    TotalMinutes %= 60;
    settotalTime(`${TotalHours} hrs ${TotalMinutes} min`);
  };
  return (
    <>
      <Box sx={{ display: "none" }}>
        <EarlyMonthlySummaryPrint
          month={moment(formik.values.months).format("MMM-YYYY")}
          name={""}
          ref={componentRef}
          monthlySummary={monthlySummary}
        />
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              paddingBottom: "10px",
              width: "100%",
              padding: "10px 10px 0px 10px",
              alignItems: "center",
            }}
          >
            <TextField
              type={"month"}
              name="months"
              // variant="outlined"
              size="small"
              value={formik.values.months}
              onChange={formik.handleChange}
              error={
                Boolean(formik.errors.months) && Boolean(formik.touched.months)
              }
              helperText={Boolean(formik.errors.months) && formik.errors.months}
            />
            {loader ? (
              <CircularProgress />
            ) : (
              <Button
                //   onClick={() => {
                //     handleGet();
                //   }}
                type="submit"
                sx={{ padding: "5px 10px" }}
                variant="contained"
              >
                Get
              </Button>
            )}
            <Button
              onClick={() => {
                printPaymentRec();
              }}
              sx={{ padding: "5px 10px" }}
              variant="contained"
            >
              Print
            </Button>
          </Box>
          <Box sx={{ padding: "10px 10px 0px 10px" }}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{ color: (theme) => theme.palette.grey.G2 }}
                    />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              type="text"
              autoComplete="off"
              placeholder="Employee"
              // className="input_border"
              id="empName"
              inputRef={Name}
              onChange={onTextChange}
            />
          </Box>
        </Box>
      </form>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          paddingX: "20px",
          gap: "50px",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
          }}
        >
          Total Early Time
        </Typography>
        <Typography
          sx={{
            textDecoration: "underline",
          }}
        >
          {" "}
          {totalTime}
        </Typography>
      </Box>
      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        overflow
        sx={{ height: "calc(100vh - 193px)", padding: "10px" }}
      >
        <DataGrid
          rows={monthlySummary}
          columns={columns}
          onRowClick={() => {}}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          pagination
          components={{
            Toolbar: CustomToolbar,
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />
      </Grid>
    </>
  );
}

export default EarlyMonthlySummary;
