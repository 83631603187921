import {
  Button,
  CircularProgress,
  FormControl,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import forgot_password from "../../Assets/forgot password.png";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import EmailSentModal from "../../Components/SendMailModal/SendMailModal";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { ForgotPasswordPostAPI } from "../../Api/ForgotPassword/ForgotPasswordPostApi";
const useStyle = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "20px",

      height: "100vh",
      backgroundColor: "white !important",
    },
    subContainer: {
      width: "100%",
      maxWidth: "1080px",
      display: "flex",
      gap: "20px",
      alignItems: "center",
      justifyContent: "center",
      padding: "20px",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    imageContainer: {
      width: "100%",
      padding: "20px",
    },
    FormContainer: {
      width: "100%",
      padding: "20px",
      textAlign: "left",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      textAlign: "left",
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "left",
      gap: "10px",
      width: "100%",
    },
  };
});

const initialValues = {
  email: "",
};
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please Enter A Valid Email Address.!")
    .required("Please Enter Email Address.!"),
});

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [email, setemail] = useState(null);
  const { setsnackBarData } = useContext(SnackBarContext);
  console.log(window.location);
  const {
    container,
    subContainer,
    imageContainer,
    FormContainer,
    formContainer,
    buttonContainer,
  } = useStyle();
  const handleSubmit = async (values, setSubmitting, resetForm) => {
    setemail(values);

    const response = await ForgotPasswordPostAPI({
      email: values?.email,
      link: `${window.location.origin}/changeforgotpassword/`,
    });
    console.log(response);
    setsnackBarData(response.snackBarData);
    if (response.data?.IsSuccess) {
      setOpen(true);
      setSubmitting(false);
      resetForm();
      setTimeout(() => {
        navigate("/");
      }, 5000);
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });
  return (
    <Box className={container}>
      <Paper elevation={3} className={subContainer}>
        <Box className={imageContainer}>
          <img
            src={forgot_password}
            alt="forgotpassword"
            style={{ width: "90%" }}
          />
        </Box>
        <Box className={FormContainer}>
          <Box>
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              Forgot
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              Your Password?.
            </Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Box className={formContainer}>
              <FormControl fullWidth>
                <Typography>Email</Typography>
                <TextField
                  name="email"
                  size="small"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  variant="outlined"
                  error={
                    Boolean(formik.touched.email) &&
                    Boolean(formik.errors.email)
                  }
                  helperText={
                    Boolean(formik.errors.email) && formik.errors.email
                  }
                />
              </FormControl>

              <Box className={buttonContainer}>
                <Box sx={{ width: "100%", textAlign: "center" }}>
                  {formik.isSubmitting ? (
                    <CircularProgress />
                  ) : (
                    <Button type="submit" variant="contained" fullWidth>
                      <Typography>Submit</Typography>
                    </Button>
                  )}
                </Box>
                <Box sx={{ textAlign: "left", width: "100%" }}>
                  <Typography>
                    Don&apos;t have an account?{" "}
                    <Typography
                      component={"span"}
                      color={"primary"}
                      sx={{ cursor: "pointer" }}
                    >
                      Sign up for free
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </form>
        </Box>
      </Paper>
      <EmailSentModal open={open} setOpen={setOpen} Email={email} />
    </Box>
  );
};

export default ForgotPassword;
