import { Box } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Navigate } from "react-router-dom";

import RulesForm from "../../Components/Rules/RulesForm";
import RulesTable from "../../Components/Rules/RulesTable";

const RulesMain = () => {
  const [ShowForm, setShowForm] = useState(false);
  const [IsEdit, setIsEdit] = useState(false);
  const [EditedData, setEditedData] = useState(null);
  const [canView, setcanView] = useState(null);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  useEffect(() => {
    let string = "Rules";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    let match = value?.some((val) => {
      if (val?.module?.split("_")[1] === "View") {
        return true;
      }
    });
    setcanView(match);
    console.log(match);
    if (!roles || roles.length < 1) {
      setcanView(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  return (
    <div>
      {canView === true ? (
        ShowForm ? (
          <RulesForm
            setShowForm={setShowForm}
            setEditedData={setEditedData}
            EditedData={EditedData}
            setIsEdit={setIsEdit}
            IsEdit={IsEdit}
          />
        ) : (
          <RulesTable
            setShowForm={setShowForm}
            setEditedData={setEditedData}
            EditedData={EditedData}
            setIsEdit={setIsEdit}
          />
        )
      ) : canView === false ? (
        <Navigate to={"/dashboard"} />
      ) : (
        <Box>Loading ...</Box>
      )}
    </div>
  );
};

export default RulesMain;
