import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  ChangeInRequests: [],
  CompletedChangeRequest: [],
  PendingChangeRequest: [],
  UnallocatedChangeRequest: [],
  EmpMonthlyChangeRequest: [],
  Employee: [],
  isSuccess: false,
  PageNum: 0,
  PageSize: 10,
  TotalCount: 0,
  IsLogin: true,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const fetchChangeRequest = createAsyncThunk(
  "getchangereqs",
  async ({ page, pageSize }) => {
    console.log(page, pageSize);
    const response = await axiosInstance.get(
      `getchangereqs?PageNum=${Number(page) + 1}&PageSize=${pageSize}`
    );
    console.log(response.data)
    return response.data;
  }
);
export const ChangeinRequestPopulate = createAsyncThunk(
  "requestpopulate",
  async () => {
    const response = await axiosInstance.get(`requestpopulate`);
    // console.log(response.data)
    return response.data;
  }
);
export const ChangeReqEmpPopulate = createAsyncThunk(
  "emppopulatechgreq",
  async () => {
    const response = await axiosInstance.get(`emppopulatechgreq`);
    // console.log(response.data)
    return response.data;
  }
);
export const fetchsingleEmpChangeReq = createAsyncThunk(
  "singleempchngreq",
  async (id) => {
    const response = await axiosInstance.get(`singleempchngreq/${id}`);
    console.log(response);
    return response.data;
  }
);
export const FetchCompletedChangeRequest = createAsyncThunk(
  "changereqscompleted",
  async () => {
    const response = await axiosInstance.get(`changereqscompleted`);
    console.log(response.data)
    return response.data;
  }
);
export const FetchPendingChangeRequest = createAsyncThunk(
  "changereqsallocated",
  async () => {
    const response = await axiosInstance.get(`changereqsallocated`);
    console.log(response.data)
    return response.data;
  }
);
export const FetchUnallocatedChangeRequest = createAsyncThunk(
  "changereqsunallocated",
  async () => {
    const response = await axiosInstance.get(`changereqsunallocated`);
    console.log(response.data)
    return response.data;
  }
);
export const MonthEmpChangeRequest = createAsyncThunk(
  "onemonthoneempchangereqs",
  async ({month,id}) => {
    const response = await axiosInstance.get(`onemonthoneempchangereqs?month=${month}&emp_id=${id}`);
    console.log(response.data)
    return response.data;
  }
);
const ChangeRequestSlice = createSlice({
  name: "ChangeRequests",
  initialState,
  reducers: {
    ChangeRequestAdded: {
      reducer(state, action) {
        state.ChangeInRequests?.push(action.payload);
      },
    },
    RemoveChangeRequest: {
      reducer(state) {
        state.ChangeInRequests = [];
      },
    },
    ChangeRequestStatusUpdated: {
      reducer(state, action) {
        console.log(state.ChangeInRequests);
        console.log(action.payload);
        state.ChangeInRequests?.map((item) => {
          if (item.request_id === action.payload.request_id) {
            item = action.payload;
          }
        });
      },
    },
    ChangeRequestClientStatusUpdated: {
      reducer(state, action) {
        console.log(state.ChangeInRequests);
        console.log(action.payload);
        state.ChangeInRequest?.map((item) => {
          if (item.request_id === action.payload.request_id) {
            item = action.payload;
          }
        });
      },
    },
    ChangeReqResetData: (state) => {
      state.ChangeInRequests = [];
      state.Projects = [];
      state.Employee = [];
      state.isSuccess = false;
      state.PageNum = 0;
      state.PageSize = 10;
      state.TotalCount = 0;
      state.IsLogin = true;
      state.message = null;
      state.status = "idle"; //'idle' | 'loading' | 'succeeded' | 'failed'
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchChangeRequest.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchChangeRequest.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        let arr = [...state.ChangeInRequests];
        Array.prototype.splice.apply(
          arr,
          [
            action.payload?.PageSize * action.payload?.PageNum -
              action.payload?.PageSize,
            action?.payload?.Data.length,
          ].concat(action?.payload?.Data)
        );

        state.ChangeInRequests = [...arr];
        console.log(state.PageNum);
        // state.ChangeInRequests = state.ChangeInRequests?.concat(action?.payload?.Data)  ;
        state.PageNum = Number(action.payload?.PageNum);
        state.PageSize = Number(action.payload?.PageSize);
        state.TotalCount = Number(action.payload?.TotalCount);
      })
      .addCase(fetchChangeRequest.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(ChangeinRequestPopulate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(ChangeinRequestPopulate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Projects = action.payload.Projects;
      })
      .addCase(ChangeinRequestPopulate.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(ChangeReqEmpPopulate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(ChangeReqEmpPopulate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Employee = action.payload.Employees;
      })
      .addCase(ChangeReqEmpPopulate.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(fetchsingleEmpChangeReq.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchsingleEmpChangeReq.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.ChangeInRequests = action.payload.Data;
      })
      .addCase(fetchsingleEmpChangeReq.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(FetchCompletedChangeRequest.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchCompletedChangeRequest.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.CompletedChangeRequest = action.payload.Completed;
      })
      .addCase(FetchCompletedChangeRequest.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(FetchPendingChangeRequest.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchPendingChangeRequest.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.PendingChangeRequest = action.payload.Allocated;
      })
      .addCase(FetchPendingChangeRequest.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(FetchUnallocatedChangeRequest.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchUnallocatedChangeRequest.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.UnallocatedChangeRequest = action.payload.Unallocated;
      })
      .addCase(FetchUnallocatedChangeRequest.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(MonthEmpChangeRequest.pending, (state) => {
        state.status = "loading";
      })
      .addCase(MonthEmpChangeRequest.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.EmpMonthlyChangeRequest = action.payload.Data;
      })
      .addCase(MonthEmpChangeRequest.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
  },
});

export const getChangerequestDetails = (state) => state.ChangeRequests;

export const {
  ChangeRequestAdded,
  ChangeRequestStatusUpdated,
  ChangeRequestClientStatusUpdated,
  RemoveChangeRequest,
  ChangeReqResetData,
} = ChangeRequestSlice.actions;

export default ChangeRequestSlice.reducer;
