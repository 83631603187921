import PropTypes from "prop-types";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  gridClasses,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import AddIcon from "@mui/icons-material/Add";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchTodayAttendance,
  gettodayattendanceDetails,
} from "../../Redux/Slice/TodayAttendance/TodayAttendanceSlice";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import moment from "moment/moment";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useNavigate } from "react-router-dom";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import CustomButton from "../Button/AddNewButton";
import theme from "../../theme";
import { useFormik } from "formik";
import { BrakeAdjustmentApi } from "../../Api/TodayAttendance/BrakeAdjustmentApi";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import Loader from "../Loader/Loader";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
    },
    Container: {
      width: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    container: {
      width: "100%",
      padding: "0px 20px 20px 20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      textAlign: "left",
    },
  };
});

function TodayAttendanceTable({ setShowForm, seteditedRow, setisEdited }) {
  const { tabelStyle } = useContext(TabelStyleContext);

  const dispatch = useDispatch();
  const {
    header,
    ModalContainer,
    Container,
    TableHeader,
    container,
    subContainer,
    formContainer,
  } = useStyles();
  const [rows, setrows] = useState([]);
  const [filterRows, setfilterRows] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const { setsnackBarData } = useContext(SnackBarContext);
  const navigate = useNavigate();
  const [showModal, setshowModal] = useState(false);
  const todayAttendanceDetails = useSelector(gettodayattendanceDetails);
  const { IsLogin } = useSelector(gettodayattendanceDetails);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [editPermit, seteditpermit] = useState(false);
  const [addPermit, setaddpermit] = useState(false);
  const [rowData, setrowData] = useState(null);
  console.log(rowData);
  const initialValues = {
    break_time: "",
    breakadjustmentreason: "",
  };
  console.log(roles);
  useEffect(() => {
    let string = "TodayAttendance";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Edit") {
        seteditpermit(true);
      } else if (val?.module?.split("_")[1] === "Add") {
        setaddpermit(true);
      }
    });
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  console.log(editPermit);
  console.log(addPermit);

  useEffect(() => {
    dispatch(fetchTodayAttendance());
    setisEdited(false);
  }, [dispatch]);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: todayAttendanceDetails?.message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "Today Attendance" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }
  const editData = (celVal) => {
    setisEdited(true);
    seteditedRow(celVal);
    setShowForm(true);
  };
  const columns = [
    { field: "sr", headerName: "Sr #", width: 100, headerClassName: header },

    {
      field: "employee_name",
      headerName: "Employee",
      width: 150,
      headerClassName: header,
    },

    {
      field: "started_time",
      headerName: "Check In",
      width: 150,
      headerClassName: header,
    },
    {
      field: "ended_time",
      headerName: "Check Out",
      width: 150,
      headerClassName: header,
    },
    {
      field: "time_zone",
      headerName: "Time Zone",
      width: 250,
      headerClassName: header,
    },
    {
      field: "click",
      headerName: "Actions",
      width: 150,
      headerClassName: header,
      // type: <button />,

      renderCell: (celVal) => {
        // console.log(celVal?.row);
        return (
          <Box sx={{ display: "flex", gap: "10px" }}>
            {celVal.row.ended_time === "" && editPermit ? (
              <Tooltip title="Update Check Out">
                <AppRegistrationIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    editData(celVal.row);
                  }}
                />
              </Tooltip>
            ) : null}
            {celVal?.row?.break_time ? null : (
              <Tooltip title="Break Adjustment">
                <DisplaySettingsIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setrowData(celVal?.row);
                    setshowModal(true);
                  }}
                />
              </Tooltip>
            )}
          </Box>
        );
      },
    },
  ];
  useEffect(() => {
    let arr = [];
    if (todayAttendanceDetails) {
      todayAttendanceDetails?.todayattendance?.forEach((item, idx) => {
        let timezone = item?.emp_id?.shift_rules?.time_zone
          ? JSON.parse(item?.emp_id?.shift_rules?.time_zone)
          : {};

        return arr.push({
          sr: idx + 1,
          id: item?.attendance_id,

          employee_name: item?.emp_id.name,
          emp_id: item?.emp_id?.Emp_id,
          total_hrs: item?.total_hrs,
          over_time: item?.over_time,
          break_time: item?.break_time,
          started_time: item?.in_time
            ? moment(item?.in_time, "HH:mm").format("LT")
            : "",
          ended_time: item?.out_time
            ? moment(item?.out_time, "HH:mm").format("LT")
            : "",
          time_zone: timezone?.label,
        });
      });
    }
    setrows(arr);

    setfilterRows(arr);
  }, [todayAttendanceDetails]);

  const onTextChange = () => {
    if (document.getElementById("ProjectNameFilter").value === "") {
      setfilterRows(rows);
    } else {
      let suggestions = [];

      var regex1 = new RegExp(
        document.getElementById("ProjectNameFilter").value,
        "i"
      );
      suggestions = rows?.filter(
        (val) =>
          document.getElementById("ProjectNameFilter").value === "" ||
          regex1.test(val.employee_name)
      );

      setfilterRows(suggestions);
    }
  };
  const handleSave = async (values, setSubmitting, resetForm) => {
    console.log(values, resetForm);
    const obj = { ...values, attendance_id: rowData?.id };

    const response = await BrakeAdjustmentApi(obj);
    setsnackBarData(response.snackBarData);
    if (response.data.IsSuccess) {
      dispatch(fetchTodayAttendance());
      resetForm();
      setshowModal(false);
    }
    setSubmitting(false);
  };
  const validationSchema = Yup.object().shape({
    break_time: Yup.string().required("Please Enter Time"),
    breakadjustmentreason: Yup.string().required("Please Enter Reason"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSave(values, resetForm, setSubmitting);
    },
  });
  useEffect(() => {
    if (todayAttendanceDetails.status === "failed") {
      setsnackBarData({
        type: "error",
        message: todayAttendanceDetails?.error,
        openToast: true,
      });
    }
  }, [todayAttendanceDetails.status]);
  return (
    <>
      <Grid container spacing={2} sx={{ padding: "10px" }}>
        <Grid item md={2} xs={12} xl={2} sx={{ display: "flex", gap: "5px" }}>
          {addPermit ? (
            <>
              {/* <Button
              sx={{ padding: "5px 10px" }}
              onClick={() => {
                setShowForm(true);
              }}
              variant="contained"
              className="tabletopbutton1 text-right"
            >
              New
            </Button> */}
              <CustomButton
                Color={"primary"}
                Icon={<AddIcon />}
                onClick={() => {
                  setShowForm(true);
                }}
                Label={"Add"}
              />
            </>
          ) : null}
        </Grid>
        <Grid item md={8} xs={12} xl={8}></Grid>
        <Grid item md={2} xs={12} xl={2}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: (theme) => theme.palette.grey.G2 }}
                  />
                </InputAdornment>
              ),
            }}
            variant="standard"
            type="text"
            autoComplete="off"
            placeholder="Employee"
            className="input_border"
            id="ProjectNameFilter"
            onChange={onTextChange}
          />
        </Grid>

        {todayAttendanceDetails?.todayattendance?.length <= 0 &&
        todayAttendanceDetails.status === "loading" ? (
          <Loader />
        ) : null}
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          overflow
          sx={{ height: tabelStyle.height }}
        >
          <DataGrid
            rows={filterRows}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            pagination
            components={{
              Toolbar: CustomToolbar,
            }}
            localeText={{
              toolbarDensity: "Size",
              toolbarDensityLabel: "Size",
              toolbarDensityCompact: "Small",
              toolbarDensityStandard: "Medium",
              toolbarDensityComfortable: "Large",
            }}
          />
        </Grid>
      </Grid>
      {showModal && (
        <Modal
          open={showModal}
          onClose={() => {
            setrowData(null);
            setshowModal(false);
          }}
        >
          <Box className={ModalContainer}>
            <Box className={Container}>
              <Box className={TableHeader}>
                <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                  Break Adjustment
                </Typography>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setshowModal(false);
                  }}
                >
                  <HighlightOffIcon
                    color="error"
                    sx={{
                      fontSize: "28px",
                    }}
                  />
                </IconButton>
              </Box>
              <Box className={container}>
                <Box className={subContainer}>
                  <form onSubmit={formik.handleSubmit}>
                    <Box className={formContainer}>
                      <FormControl fullWidth>
                        <Typography>Time</Typography>
                        <TextField
                          sx={{ textAlign: "left", input: { color: "black" } }}
                          fullWidth
                          size="small"
                          name="break_time"
                          type="number"
                          placeholder="Enter time in hours e.g 1.5 or 0.5"
                          onBlur={formik.handleBlur}
                          onChange={
                            // (e) => {
                            //     if (e.target.value > advancedData?.requested_amount){
                            //         return
                            //     }else{
                            //         formik.setFieldValue("break_time",e.target.value)
                            //     }
                            // }
                            formik.handleChange
                          }
                          value={formik.values.break_time}
                          variant="outlined"
                          error={
                            Boolean(formik.touched.break_time) &&
                            Boolean(formik.errors.break_time)
                          }
                          helperText={
                            Boolean(formik.touched.break_time) &&
                            formik.errors.break_time
                          }
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <Typography>Reason</Typography>
                        <TextField
                          sx={{ textAlign: "left", input: { color: "black" } }}
                          fullWidth
                          size="small"
                          multiline
                          rows={3}
                          name="breakadjustmentreason"
                          type="text"
                          onBlur={formik.handleBlur}
                          onChange={
                            // (e) => {
                            //     if (e.target.value > advancedData?.requested_amount){
                            //         return
                            //     }else{
                            //         formik.setFieldValue("breakadjustmentreason",e.target.value)
                            //     }
                            // }
                            formik.handleChange
                          }
                          value={formik.values.breakadjustmentreason}
                          variant="outlined"
                          error={
                            Boolean(formik.touched.breakadjustmentreason) &&
                            Boolean(formik.errors.breakadjustmentreason)
                          }
                          helperText={
                            Boolean(formik.touched.breakadjustmentreason) &&
                            formik.errors.breakadjustmentreason
                          }
                        />
                      </FormControl>
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "end",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px",
                          }}
                        >
                          <Box>
                            {formik.isSubmitting ? (
                              <CircularProgress />
                            ) : (
                              <Button type="submit" variant="contained">
                                <Typography>Submit</Typography>
                              </Button>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </form>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
}

export default TodayAttendanceTable;
TodayAttendanceTable.propTypes = {
  setShowForm: PropTypes.any,
  seteditedRow: PropTypes.any,
  setisEdited: PropTypes.any,
};
