import {
  Box,
  Button,
  FormControl,
  Typography,
  TextField,
  // CircularProgress,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
window.Buffer = window.Buffer || require("buffer").Buffer;
import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import { object, string, array } from "yup";
import "react-image-crop/dist/ReactCrop.css";
import { useContext } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { HeaderContext } from "../../../Context/SnackBarContext/HeaderContext/HeaderContext";
// import { logout } from "../../../Redux/Slice/LogoutSlice/LogoutSlice";
// import { useNavigate } from "react-router-dom";

import "react-phone-number-input/style.css";

// import {
//   getemployeeDetails,
// } from "../../../Redux/Slice/Employee/EmplyeeSlice";
// import Loader from "../../Loader/Loader";
import theme from "../../../theme";
// import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
// import axiosInstance from "../../../Utils/AxiosInstance";
import { posthiringSession, putSession } from "../../../Api/Applicants/ApplicantAPI";
import Modal from "../../StageModal.jsx/Modal";
import { getsingledata, getstagesData } from "../../../Redux/Slice/Applicants/ApplicantSlice";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";

const useStyles = makeStyles(() => {
  return {
    inputBox: {
      width: "100%",
    },
    input_typography: {
      textAlign: "left",
      fontSize: `15px !important`,
      marginTop: "10px !important",
    },
    Form_input: {
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,
    },
    btns: {
      width: "80px",
    },
    uploadBoxContainer: {
      minWidth: "100%",
      position: "relative",
    },
    IconContainer: {
      paddingTop: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    reviewBox: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
      flexWrap: "wrap",
    },
    FieldInRow: {
      display: "flex",
      justifyContent: "space-between",
      gap: "20px",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },
    imgcontainer: {
      width: "120px",
      height: "120px",
      borderRadius: "50%",
      padding: "10px",
    },
  };
});

const validationSchema = object({
  jobTitle: string().required("This field is required"),
  title: string().required("This field is required"),
  stages: array().required('Required'),
});
const HiringSessionForm = ({ setShowForm, empData, setisEdited, isEdited }) => {
  console.log(empData)
  const { Form_input, input_typography, btns, inputBox, FieldInRow } =
    useStyles();
  const [loading,setloading] = useState(false);
  const [open, setOpen] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);

  // const navigate = useNavigate();
  // const { setsnackBarData } = useContext(SnackBarContext);
  // const { singleEmployee, IsLogin, message, status } =
  //   useSelector(getemployeeDetails);
  const { setheader } = useContext(HeaderContext);
  // const [value, setValue] = useState();
  const dispatch = useDispatch();

  const initialValues = {
    jobTitle: "",
    title: "",
    stages: [],
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      // handelAdd(values, resetForm);
      handleSubmit(values, resetForm);
    },
  });
  console.log(formik.values.stages);
  const handleSubmit = async (values, resetForm) => {
    let data = [];    //Send single id in Backend ***************************//////
    values.stages?.map((ele) => {
      data.push(ele.stage_id)
    })
console.log(data);
setloading(true)
    if (isEdited) {
      const res= await putSession({ ...values, stages: data ,id:empData?.session_id});
      if (res?.data?.IsSuccess) {
        setsnackBarData(res.snackBarData);
        setloading(false)
        setisEdited(false)
        setShowForm(false)

      }else{
        setsnackBarData(res.snackBarData);
        setloading(false)

      }

    } else {
      const res = await posthiringSession({ ...values, stages: data });
      if (res?.data?.IsSuccess) {
        setsnackBarData(res.snackBarData);
        setloading(false)
        setShowForm(false)
        resetForm();
      }else{
        setsnackBarData(res.snackBarData);
        setloading(false)

      }
    
    }

  };
  // const handlePut = async (values) => {
  // const res= await putSession(values);
  //  console.log(res)
  // };
  const [sessionData, setSessionData] = useState(null);
  console.log(sessionData)
  //Get data by edit through id ----------------------
  const { singlesession } = useSelector((state) => state.applicants)
  console.log(singlesession)
  useEffect(() => {
    // const sessionId = '20b62633-a417-4f65-9f00-2b870a502afd';
    if (empData) {
      dispatch(getsingledata(empData.session))

    }
  }, [empData])
  useEffect(() => {
    setSessionData(singlesession);
  }, [singlesession]);
  useEffect(() => {
    if (sessionData && isEdited) {
      formik.setFieldValue("jobTitle", sessionData?.jobTitle);
      formik.setFieldValue("title", sessionData?.title);
      formik.setFieldValue("stages", sessionData?.stage_id ? sessionData?.stage_id : []);
    }
  }, [sessionData])

  // useEffect(()=>{
  //  if(singlesession){
  //   formik.setFieldValue("jobTitle", singlesession?.jobTitle);
  //       formik.setFieldValue("title", singlesession?.title);
  //       formik.setFieldValue("stages", singlesession?.stage_id);
  //  }
  // },[])

  // console.log('sessioneditid...',singlesession)
  //     useEffect(() => {
  //   // Update the formik initial values based on the fetched data
  //   if (isEdited && applicants.length > 0) {
  //     const editedApplicant = applicants.find(
  //       (applicant) => applicant.id === empData.empId
  //     );

  //     if (editedApplicant)
  //       console.log(editedApplicant)
  //     {
  //       formik.setFieldValue("jobTitle", editedApplicant?.jobTitle);
  //     formik.setFieldValue("title", editedApplicant?.title);
  //     formik.setFieldValue("stages", editedApplicant?.stage_id);

  //     }
  //   }
  // }, [isEdited]);
  // useEffect(() => {
  //   if (!IsLogin) {
  //     setsnackBarData({
  //       type: "error",
  //       message: message,
  //       openToast: true,
  //     });
  //     dispatch(logout());
  //     return navigate("/");
  //   }
  // }, [IsLogin]);
  // Get Stages from popup data populate in form .........********************////////////////

  const { stages } = useSelector((state) => state.applicants)
  console.log('stages console...', stages)

  useEffect(() => {
    dispatch(getstagesData())
  }, [])



  return (
    <>
      {open && <Modal onClose={() => setOpen(false)} />}

      {/* {status !== "succeeded" ? <Loader /> : null} */}
      <Box
        sx={{
          minHeight: "calc(100vh - 165px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: "20px",
        }}
      >
        <form onSubmit={formik?.handleSubmit}>
          <Box>
            <Box
              sx={{
                maxWidth: "900px",
                width: "100%",
                display: "flex",
                gap: "20px",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Box className={FieldInRow}>
              <Box className={inputBox}>
                  <Typography className={input_typography}>Session Title</Typography>
                  <FormControl fullWidth sx={{ textAlign: "left" }}>
                    <TextField
                      onChange={formik?.handleChange}
                      onBlur={formik.handleBlur}
                      size="small"
                      name="title"
                      value={formik?.values.title}
                      variant="outlined"
                      type="text"
                      color="primary"
                      sx={{ input: { color: "black" } }}
                      className={Form_input}
                      autoComplete="off"
                      inputProps={{ autoComplete: "off" }}
                      // disabled={isEdited ? true : false}
                      fullWidth
                      error={
                        Boolean(formik?.errors.title) &&
                        Boolean(formik?.touched.title)
                      }
                      helperText={
                        Boolean(formik?.touched.title) && formik?.errors.title
                      }
                    />
                  </FormControl>
                </Box>
                <Box className={inputBox}>
                  <Typography className={input_typography}>
                    Job Title
                  </Typography>

                  <FormControl fullWidth sx={{ textAlign: "left" }}>
                    <TextField
                      onBlur={formik.handleBlur}
                      onChange={formik?.handleChange}
                      size="small"
                      name="jobTitle"
                      value={formik?.values.jobTitle}
                      variant="outlined"
                      type="text"
                      color="primary"
                      sx={{ input: { color: "black" } }}
                      className={Form_input}
                      fullWidth
                      error={
                        Boolean(formik?.errors.jobTitle) &&
                        Boolean(formik?.touched.jobTitle)
                      }
                      helperText={
                        Boolean(formik?.touched.jobTitle) &&
                        formik?.errors.jobTitle
                      }
                    />
                  </FormControl>
                </Box>


              </Box>
              <Box className={FieldInRow}>
                <Box className={inputBox}>
                  <Autocomplete
                    multiple
                    name="stages"
                    id="tags-standard"
                    options={stages || []}
                    getOptionLabel={(option) => option.title}
                    value={formik.values.stages}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Stages"
                        placeholder=""
                        error={
                          Boolean(formik?.errors.stages) &&
                          Boolean(formik?.touched.stages)
                        }
                        helperText={
                          Boolean(formik?.touched.stages) && formik?.errors.stages
                        }
                      />
                    )}
                    style={{ marginTop: "24px" }}
                    onChange={(e, val) => {
                      console.log(e.target.value);
                      formik.setFieldValue("stages", val);
                    }}
                  />
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "37px", width: "142px" }}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Add Stages
                </Button>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <Box
                sx={{
                  float: "right",
                  display: "flex",
                  gap: "10px",
                  marginTop: "20px",
                }}
                spacing={2}
              >
                <>
                  <Button
                    className={btns}
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setShowForm(false);
                      setisEdited(false);
                      setheader(null);
                    }}
                  >
                    Cancel
                  </Button>

                  {loading === false ? (
                    !isEdited ? (
                      <Button
                        className={btns}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Save
                      </Button>
                    ) : (
                      <Button
                        className={btns}
                        type="submit"
                        variant="contained"
                        color="primary"
                      // onClick={handlePut}
                      >
                        Update
                      </Button>
                    )
                  ) : (
                    <CircularProgress />
                  )}
                </>

              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default HiringSessionForm;

HiringSessionForm.propTypes = {
  setShowForm: PropTypes.any,
  empData: PropTypes.any,
  setisEdited: PropTypes.any,
  isEdited: PropTypes.any,
};
