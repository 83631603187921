import {
    IconButton,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
  } from "@mui/material";
  import React from "react";
  import PropTypes from "prop-types";
  import { Box } from "@mui/system";
  import { makeStyles } from "@mui/styles";
  import HighlightOffIcon from "@mui/icons-material/HighlightOff";
  import moment from 'moment/moment'
  const useStyles = makeStyles(() => {
    return {
      ModalContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        padding: "10px",
        width: "100%",
      },
      Container: {
        width: "800px",
        padding: "20px",
        border: "none",
        position: "relative",
        background: "white",
        borderRadius: "10px",
        display: "flex",
        gap: "20px",
        flexDirection: "column",
      },
      TableContain: { height: "250px", overflow: "scroll" },
      TableHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
    };
  });
function LoansDetailsModal({ showModal2, setshowModal2, editRow }) {
    const { TableContain, Container, ModalContainer, TableHeader } = useStyles();

  return (
    <Modal open={showModal2}>
      <Box className={ModalContainer}>
        <Box className={Container}>
          <Box className={TableHeader}>
            <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
              Loans Details
            </Typography>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setshowModal2(!showModal2);
              }}
            >
              <HighlightOffIcon
              color="primary"
                sx={{
                  fontSize: "28px",
                }}
              />
            </IconButton>
          </Box>
          <TableContainer component={Paper} className={TableContain}>
            <Table
              stickyHeader
              sx={{
                minWidth: { md: "auto", xs: "auto" },
                overflow: "scroll",
              }}
              aria-label="simple table"
              size="small"
            >
              <TableHead>
                <TableRow size="small">
                  <TableCell size="small" sx={{ fontWeight: "bold" }}>
                    Requested Date
                  </TableCell>
                  <TableCell size="small" sx={{ fontWeight: "bold" }}>
                    Recieved Date
                  </TableCell>
                  <TableCell size="small" sx={{ fontWeight: "bold" }}>
                    Recieved Amount
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {editRow?.manualloan &&
                  editRow?.manualloan.map((row,i) => (
                    <TableRow
                 key={i}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell size="small" component="th" scope="row">
                        {row?.created_at? moment(row?.created_at).format("DD-MMM-YYYY"):null}
                      </TableCell>
                      <TableCell size="small">{row?.received_date? moment(row?.received_date).format("DD-MMM-YYYY"):null}</TableCell>
                      <TableCell size="small">{row?.received_amount?.toLocaleString()}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Modal>
  )
}

export default LoansDetailsModal;
LoansDetailsModal.propTypes = {
    showModal2: PropTypes.any,
    setshowModal2: PropTypes.any,
    editRow: PropTypes.any,
  };
  