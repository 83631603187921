import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  LoanDeduction: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};

export const fetchLoanDeduction = createAsyncThunk(
  "getempnodeductionreq",
  async () => {
    const response = await axiosInstance.get(`getempnodeductionreq`);

    return response.data;
  }
);

const LoanDeductionSlice = createSlice({
  name: "LoanDeduction",
  initialState,
  reducers: {
    LoanDeductionAdded: {
      reducer(state, action) {
        state.LoanDeduction.push(action.payload);

      },
    },
    removeLeaveApplicationDetail: {
      reducer(state) {
        state.LoanDeduction = [];
      },
    },
    LeaveAppResetData: (state) => {
      state.LoanDeduction = [];
      state.isSuccess = false;
      state.message = null;
      state.status = "idle"; //'idle' | 'loading' | 'succeeded' | 'failed'
      state.error = null;
      state.IsLogin = true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLoanDeduction.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLoanDeduction.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.LoanDeduction = action.payload.Employee_Loan;
      })
      .addCase(fetchLoanDeduction.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
 
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
  },
});

export const getLoanDeductionDetails = (state) => state.LoanDeduction;

export const {
  LoanDeductionAdded,
  removeLeaveApplicationDetail,
  LeaveAppResetData,
} = LoanDeductionSlice.actions;

export default LoanDeductionSlice.reducer;
