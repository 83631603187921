import React, { useState } from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import theme from "../../theme";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import FormIkForm from "./FormIkForm";
import {
  AddAttendanceApi,
  UpdateAttendanceApi,
} from "../../Api/Attendance/AttendanceAPis";
import {
  AttenndanceAdded,
  fetchAttendancePopulate,
  getattendanceDetails,
} from "../../Redux/Slice/Attendance/AttendanceSlice";
import { useDispatch, useSelector } from "react-redux";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles(() => {
  return {
    input_typography: {
      textAlign: "left",
      fontWeight: "bold",
      fontSize: `15px !important`,
      marginTop: "10px !important",
      paddingRight: "20px !important",
    },
    Form_input: {
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,
    },
    form_main: {
      padding: "25px",
      width: "100%",
      minHeight: "calc(100vh - 173px)",
     
    },

    btns: {
      width: "80px",
      color: "#ffff",
    },
  };
});
const AttendanceForm = ({
  ShowForm,
  setShowForm,
  SelectedRow,
  setSelectedRow,
}) => {
  const { form_main } = useStyles();
  const [Allemployees, setAllemployees] = useState();
  const navigate = useNavigate();

  const [isEdit, setisEdit] = useState(false);
  const dispatch = useDispatch();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { Employees,IsLogin,message } = useSelector(getattendanceDetails);
  const [attendancedata, setattendancedata] = useState(null);
  const [empName, setempName] = useState('');
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
    setAllemployees(Employees);
  }, [Employees,IsLogin]);

  
  useEffect(() => {
    if (SelectedRow !== null) {
      setisEdit(true);
      setempName(SelectedRow?.employeename)
      setattendancedata({
        attendance_id: SelectedRow?.attendance_id,
        in_time: SelectedRow?.in_time,
        out_time: SelectedRow?.out_time,
        date: SelectedRow.date,
        emp_id: SelectedRow.emp_id?.Emp_id,
        isEdit: true,
      });
    } else {
      setattendancedata({
        id: null,
        in_time: null,
        out_time: null,
        date: null,
        emp_id: null,
      });
    }
  }, [ShowForm]);

  const handelAdd = async (values, formikHelpers) => {
    const response = await AddAttendanceApi(values);
    setsnackBarData(response.snackBarData);

    if (response.data.IsSuccess) {
      formikHelpers.resetForm();
      dispatch(AttenndanceAdded(response.data.attendrecrd));
      setShowForm(false);
    }
    formikHelpers.setSubmitting(false);
  };
  const handelUpdate = async (values, formikHelpers) => {
    const response = await UpdateAttendanceApi(values);
    setsnackBarData(response.snackBarData);
    formikHelpers.setSubmitting(false);

    if (response.data.IsSuccess) {
      formikHelpers.resetForm();
    formikHelpers.setSubmitting(false);

      setShowForm(false);
    }
    formikHelpers.setSubmitting(false);

  };

  useEffect(() => {
    dispatch(fetchAttendancePopulate());
  }, [dispatch]);

  return (
    <Box className={form_main}>
      {/* in case of Add New Attendance */}
      {isEdit === false ? (
        <FormIkForm
          setShowForm={setShowForm}
          setisEdit={setisEdit}
          isEdit={isEdit}
          setattendancedata={setattendancedata}
          attendancedata={attendancedata}
          setSelectedRow={setSelectedRow}
          handelSave={handelAdd}
          Employees={Employees}
          Allemployees={Allemployees}
          setAllemployees={Allemployees}
        />
      ) : (
        ""
      )}
      {/* in case of Update Attendance */}
      {isEdit === true ? (
        <FormIkForm
        empName={empName}
          setShowForm={setShowForm}
          setisEdit={setisEdit}
          isEdit={isEdit}
          setattendancedata={setattendancedata}
          attendancedata={attendancedata}
          SelectedRow={SelectedRow}
          setSelectedRow={setSelectedRow}
          Employees={Employees}
          handelSave={handelUpdate}
          Allemployees={Allemployees}
          setAllemployees={Allemployees}
        />
      ) : (
        ""
      )}
    </Box>
  );
};

export default AttendanceForm;
AttendanceForm.propTypes = {
  ShowForm: PropTypes.any,
  setShowForm: PropTypes.any,
  SelectedRow: PropTypes.any,
  setisEdit: PropTypes.any,
  setSelectedRow: PropTypes.any,
  empName: PropTypes.any,
};
