import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
// import { Pie } from "react-chartjs-2";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
// import LeaveApplicationModal from "../../Dashboard/LeaveApplicationModal/LeaveApplicationModal";

ChartJS.register(ArcElement, Tooltip, Legend);

export const PiGraph = ({ allleaves }) => {
  // const [applicationData, setapplicationData] = useState([]);
  // const [openleave, setopenleave] = useState(false);

  // const data = {
  //   labels: ["Pending", "Approved", "Rejected"],
  //   datasets: [
  //     {
  //       label: "Leaves",
  //       data: [
  //         allleaves?.Pending_count,
  //         allleaves?.Approve_count,
  //         allleaves?.Rejectcount,
  //       ],
  //       backgroundColor: [
  //         "#53B7C5",
  //         "#75D3DE",
  //         "#9FE9F0",
  //         // "rgba(255, 206, 86, 0.7)",
  //         // "rgba(75, 192, 192, 0.7)",
  //         // "rgba(255, 99, 132, 0.7)",
  //       ],
  //       borderColor: [
  //         "#53B7C5",
  //         "#75D3DE",
  //         "#9FE9F0",
  //         // "rgba(255, 206, 86, 1)",
  //         // "rgba(75, 192, 192, 1)",
  //         // "rgba(255, 99, 132, 1)",
  //       ],
  //       borderWidth: 1,
  //     },
  //   ],
  // };
  console.log(allleaves);
  const [series, setseries] = useState([0, 0, 0]);

  useEffect(() => {
    if (allleaves) {
      setseries([
        allleaves?.Pending_count,
        allleaves?.Approve_count,
        allleaves?.Rejectcount,
      ]);
    }
  }, [allleaves]);
  const [state] = useState({
    options: {
      chart: {
        width: "100%",
        type: "pie",
      },

      labels: ["Pending", "Approved", "Rejected"],
      theme: {
        monochrome: {
          enabled: true,
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -10,
          },
        },
      },

      dataLabels: {
        formatter(val, opts) {
          const name = opts.w.globals.labels[opts.seriesIndex];
          return [name, val.toFixed(1) + "%"];
        },
      },
      legend: {
        show: false,
      },
    },
  });
  // const handleDataClick = (event, segments) => {
  //   console.log(allleaves);
  //   console.log(segments);

  //   if (segments.length) {
  //     const segmentIndex = segments[0].index;
  //     console.log("Clicked segment value:", data.labels[segmentIndex]);
  //     if (data.labels[segmentIndex] === "Pending") {
  //       setapplicationData(allleaves?.Pending_leaves);
  //       setopenleave(true);
  //     } else if (data.labels[segmentIndex] === "Approved") {
  //       setapplicationData(allleaves?.Approved_leaves);
  //       setopenleave(true);
  //     } else if (data.labels[segmentIndex] === "Rejected") {
  //       setapplicationData(allleaves?.Rejected_leaves);
  //       setopenleave(true);
  //     }
  //   }
  // };

  return (
    <>
      {/* <Pie
        data={data}
      redraw
        options={{
          responsive: true,
          onClick: handleDataClick,
        }}
      />
      <LeaveApplicationModal
        open={openleave}
        setopen={setopenleave}
        applicationData={applicationData}
      /> */}

      <div id="chart" >
        <ReactApexChart options={state.options} series={series} type="pie" />
      </div>
    </>
  );
};

PiGraph.propTypes = {
  allleaves: PropTypes.any,
};
