import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
  Button,
  CircularProgress,
  gridClasses,
  InputAdornment,
  MenuItem,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProjectSubHistoryData,
  getSubScriptionHistoryDetails,
} from "../../Redux/Slice/ProjectSubscriptionHistory/ProjectSubscriptionHisSlice";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { PatchSubHistoryApi } from "../../Api/ProjectSubscriptionHistoryApi/PatchSubHistoryApi";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { makeStyles } from "@mui/styles";
import moment from "moment/moment";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import SearchIcon from "@mui/icons-material/Search";
import { useRef } from "react";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../Button/AddNewButton";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import Loader from "../Loader/Loader";
import DescriptionIcon from '@mui/icons-material/Description';
import { useReactToPrint } from "react-to-print";
import HistoryInvoice from "./HistoryInvoice";
const useStyles = makeStyles(() => {
  return {
    modalBox: {
      position: "absolute ",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      backgroundColor: "white",
      boxShadow: 24,
      padding: "10px",
    },
    edidButtonBox: {
      backgroundColor: "#3480FA !important",
      color: "white !important",
      textTransform: "none",
      // paddingTop: "20px",
      // paddingleft: "20px",
    },
    closeIson: {
      position: "absolute",
      right: "10px",
      top: "10px",
    },
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});

const SubscriptionHistoryTable = ({ setShowForm }) => {
  const { tabelStyle } = useContext(TabelStyleContext);
  const { modalBox, edidButtonBox, closeIson, header } = useStyles();
  const { setsnackBarData } = useContext(SnackBarContext);
  const [changeStatus, setStatusChange] = useState("");
  const [rowvalue, setrowvalue] = useState(null);
  const [rows, setrows] = useState([]);
  const [open, setOpen] = useState(false);
  const { ProjectSubHistory, status, IsLogin, message } = useSelector(
    getSubScriptionHistoryDetails
  );
  const dispatch = useDispatch();
  const [allProjectSubHistory, setAllProjectSubHistory] = useState([]);
  const [filterRows, setFilterRows] = useState([]);
  const [loader, setloader] = useState(false);
  const ProjectName = useRef();
  const navigate = useNavigate();
  const Month = useRef();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [editPermit, seteditpermit] = useState(false);
  const [addPermit, setaddpermit] = useState(false);
  const componentRef = useRef();
  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });

  console.log(roles);
  useEffect(() => {
    let string = "SubscriptionHistory";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Edit") {
        seteditpermit(true);
      } else if (val?.module?.split("_")[1] === "Add") {
        setaddpermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      setaddpermit(true);
      seteditpermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  console.log(editPermit);
  console.log(addPermit);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "Subscription History" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
        {/* <GridCsvExportOptions fileName="jhdjwdhwjh" /> */}
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "id", headerName: "Sr #", width: 100, headerClassName: header },
    {
      field: "ProjectName",
      headerName: "Project Name",
      width: 200,
      headerClassName: header,
    },
    {
      field: "Month",
      headerName: "Month",
      width: 150,
      headerClassName: header,
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      width: 150,
      headerClassName: header,
    },
    {
      field: "RecievedDate",
      headerName: "Received Date",
      width: 200,
      headerClassName: header,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      headerClassName: header,
    },
    {
      field: "difference",
      headerName: "Difference",
      width: 200,
      headerClassName: header,
    },
    {
      field: "Status",
      headerName: "Status",
      width: 150,
      headerClassName: header,
    },
    {
      field: "UpdateStatus",
      headerName: "Actions",
      width: 100,
      headerClassName: header,
      renderCell: (CellVal) => {
        return (
          <>
          <Box sx={{display:"flex",gap:"5px"}}>
            {CellVal.row.Status === "pending" ? (
              // <Button onClick={handleOpen} sx={{background:"#3480FA" ,color:"white!important",fontSize:"12px"}}>Update</Button>
              editPermit ? (
                <Tooltip title="Update Subscription History Status">
                  <AppRegistrationIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setrowvalue(CellVal);
                      handleOpen();
                    }}
                  />
                </Tooltip>
              ) : null
            ) : (
              ""
            )}
                <Tooltip title="Invoice">

            <DescriptionIcon 
                 onClick={() => {
                  setrowvalue(CellVal.row);
                  printPaymentRec();
                }}
                 sx={{cursor:"pointer"}}/>
            </Tooltip>
            </Box>
          </>
        );
      },
    },
  ];

  const HandleUpdate = async (CellVal) => {
    setloader(true);
    if (changeStatus === "") {
      setsnackBarData({
        type: "error",
        message: "Please Change Status",
        openToast: true,
      });
      setloader(false);

      return;
    }

    const response = await PatchSubHistoryApi({
      status: changeStatus,
      project_id: CellVal.row.project_id,
    });
    setsnackBarData(response.snackBarData);
    if (response.data.IsSuccess) {
      setloader(false);
      handleClose();
      dispatch(fetchProjectSubHistoryData());
    }
    setloader(false);
  };

  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
    setAllProjectSubHistory(ProjectSubHistory);
  }, [ProjectSubHistory, IsLogin]);

  useEffect(() => {
    dispatch(fetchProjectSubHistoryData());
  }, dispatch);
  useEffect(() => {
    let arr = [];
    allProjectSubHistory?.forEach((item, ind) => {
      var date2 = item?.received_date
        ? moment(item?.received_date)
        : moment(new Date());
      var date1 = item?.due_date?moment(item?.due_date):"";
      var months = date2.diff(date1, "months");
      date1.add(months, "months");
      var days = date2.diff(date1, "days");

      let diffMonths = months < 0 ? 0 : months;
      let diffDays = days < 0 ? 0 : days;
      let diff =
        diffDays && diffMonths
          ? (diffDays && diffMonths) === 0
            ? 0
            : diffMonths + " " + "Months" + " " + diffDays + " Days"
          : 0;
          // let abc=item?.project?.
      return arr.push({
        id: ind + 1,
        ProjectName: item?.project_name,
        ProjectSubscriptionType: item?.subscription_type,
        Status: item?.status,
        dueDate: item?.due_date
          ? moment(item?.due_date).format("DD-MMM-YYYY")
          : "",
        Month: item?.month?moment(item?.month).format("DD-MMMM"):"",
        RecievedDate: item?.received_date
          ? moment(item?.received_date).format("DD-MMM-YYYY")
          : "",
        difference: diff,
        project_id: item?.project_id,
        amount: item?.amount,
      });
    });
    setrows(arr);
    setFilterRows(arr);
  }, [allProjectSubHistory]);

  const abc = () => {
    setShowForm(true);
  };

  const onTextChange = () => {
    if (ProjectName.current.value === "" && Month.current.value === "") {
      setFilterRows(rows);
    } else {
      let suggestions = [];

      let regex1 = new RegExp(ProjectName.current.value, "i");
      let regex2 = new RegExp(
        moment(Month.current.value).format("DD-MMMM"),
        "i"
      );

      suggestions = rows?.filter(
        (val) =>
          (ProjectName.current.value === "" || regex1.test(val.ProjectName)) &&
          (Month.current.value === "" || regex2.test(val?.Month))
      );

      setFilterRows(suggestions);
    }
  };
  useEffect(() => {
    if (status === "failed") {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
    }
  }, [status]);
  return (
    <>
              <Box sx={{ display: "none" }}>
            <HistoryInvoice
              ref={componentRef}
              empmonthlyreport={rowvalue}
            />
          </Box>
    <Box spacing={2} sx={{ padding: "10px 0px" }}>
      <Box
        sx={{
          padding: "10px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {addPermit ? (
          <>
            {/* <Button
              sx={{ padding: "5px 10px" }}
              variant="contained"
              onClick={abc}
            >
              New
            </Button> */}
            <CustomButton
              Color={"primary"}
              Icon={<AddIcon />}
              onClick={abc}
              Label={"Add"}
            />
          </>
        ) : null}
        <Box sx={{ display: "flex", gap: "20px" }}>
          <Box>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{ color: (theme) => theme.palette.grey.G2 }}
                    />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              type="text"
              autoComplete="off"
              placeholder="Name"
              className="input_border"
              inputRef={ProjectName}
              onChange={onTextChange}
            />
          </Box>
          <Box>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{ color: (theme) => theme.palette.grey.G2 }}
                    />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              type="month"
              autoComplete="off"
              placeholder="Month"
              className="input_border"
              inputRef={Month}
              onChange={onTextChange}
            />
          </Box>
        </Box>
      </Box>

      <Box sx={{ height: tabelStyle.height, padding: "10px" }}>
        {ProjectSubHistory?.length<=0 && status === "loading" ? <Loader /> : null}
        <DataGrid
          rows={filterRows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5]}
          components={{
            Toolbar: CustomToolbar,
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box className={modalBox}>
          <CloseIcon onClick={handleClose} className={closeIson} />
          <Typography sx={{ paddingBottom: "10px" }}>Update Status</Typography>
          <TextField
            size="small"
            sx={{ width: "100%", height: "20px" }}
            value={changeStatus}
            select
            onChange={(e) => setStatusChange(e.target.value)}
          >
            {/* <MenuItem value="">Select</MenuItem> */}

            <MenuItem value="completed">completed</MenuItem>
          </TextField>
          <Box sx={{ paddingTop: "25px", textAlign: "right" }}>
            {loader ? (
              <CircularProgress />
            ) : (
              <Button
                className={edidButtonBox}
                onClick={() => {
                  HandleUpdate(rowvalue);
                }}
              >
                Update
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    </Box>
    </>
  );
};

export default SubscriptionHistoryTable;
SubscriptionHistoryTable.propTypes = {
  setShowForm: PropTypes.any,
};
