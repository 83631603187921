import {
  Divider,
  Typography,
  Button
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import PropTypes from "prop-types"
import DownloadIcon from '@mui/icons-material/Download';
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
function Details({ data, setShowDetails }) {

  const navigate = useNavigate();
  const handlebackButScreen = () => {
    navigate(-1);
  };



  console.log(data)
  const htmlContent = { __html: data.body };
  return (
    <>

      <Box sx={{ padding: "10px" }}>
        <Button
          sx={{ width: "30px", height: "30px",display:"block",marginBottom:"30px" }}
          variant="outlined"
          onClick={() => {
            if (data) {
              setShowDetails(false);
            } else handlebackButScreen();
          }}
        >
          <ArrowBackIcon sx={{ color: "grey !important" }} />

          
        </Button>

       
        <Typography
          sx={{ textAlign: "left", fontWeight: "bold", fontSize: "18px" }}
        >
          Email Template Details
        </Typography>
        <Divider />

        <Box sx={{ marginTop: "20px", display: "flex", gap: "10px" }}>
          <Typography
            sx={{ textAlign: "left", fontWeight: "bold", fontSize: "18px" }}
          >
            Title:
          </Typography>


          <Typography
            sx={{ textAlign: "left", fontSize: "18px" }}
          >
            {data.title}
          </Typography>
        </Box>


        <Box sx={{ marginTop: "20px", display: "flex", gap: "10px" }}>
          <Typography
            sx={{ textAlign: "left", fontWeight: "bold", fontSize: "18px" }}
          >
            Subject:
          </Typography>


          <Typography
            sx={{ textAlign: "left", fontSize: "18px" }}
          >
            {data.subject}
          </Typography>
        </Box>


        <Box sx={{ marginTop: "20px" }}>
          <Typography
            sx={{ textAlign: "left", fontWeight: "bold", fontSize: "18px" }}
          >
            Body:
          </Typography>
          <Box>
            <div dangerouslySetInnerHTML={htmlContent} style={{ textAlign: "left" }}></div>

          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {data.type == "Image" ? <Box sx={{width:"100%",maxWidth:"500px"}}><img style={{ width:"100%",height:"100%", }} src={data.image} alt="Attachment" /></Box> :
              data.type == "Document" ? <a href={data.image} target="_blank" rel="noopener noreferrer" style={{ display: "block", width: "fit-content" }}>
                <Box sx={{ width: "300px", height: "300px", backgroundColor: "gray", display: "flex", justifyContent: "space-between", alignItems: "flex-end", padding: "20px" }}>
                  <img style={{ height: "100px" }} src="https://accessibility.huit.harvard.edu/sites/hwpi.harvard.edu/files/styles/os_files_large/public/online-accessibility-huit/files/symbols-document-conversion_-_docs.png?m=1670263149&itok=OxIL9JXZ" alt="" />


                  <DownloadIcon sx={{ width: "60px", height: "60px" }} color="secondary" wid />
                </Box>


              </a> : null}

          </Box>
        </Box>

      </Box>


    </>
  );
}

export default Details;

Details.propTypes = {

  data: PropTypes.object,
  setShowDetails: PropTypes.any
};