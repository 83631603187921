import {
  Box,
  Typography,
  //  Divider,

  //    Typography
} from "@mui/material";
import React, { Component } from "react";
import PrintHeader from "../../Components/Print/PrintHeader/PrintHeader";
import PropTypes from "prop-types";
// import Cookies from "universal-cookie";
//   import PrintReportSetion from "../../Components/Print/PrintReportSection/PrintReportSetion";
class HistoryInvoice extends Component {
  render() {
    // console.log(this.props.empmonthlyreport);
    // const cookies = new Cookies();
    return (
      <Box sx={{ width: "100%", padding: "10px" }}>
        <PrintHeader />
        {/* <PrintReportSetion
            Months={this.props.month}
            Reportname={" Employee Monthly Attendance Report"}
            userName={this.props.name}
          /> */}
        <Box sx={{ paddingTop: "20px", width: "100%",display:"flex",flexDirection:"column",gap:"10px" }}>
            <Typography sx={{fontSize:"22px",fontWeight:"bold",paddingLeft:"20px"}}>Subscription History Invoice</Typography>
          <Box
            sx={{
              display: "flex",
              // flexWrap: "wrap",
              gap: "10px",
              width: "100%",
              paddingX: "20px",
              // maxWidth:"80%"
              // overflow: "scroll",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px", width: "50%" }}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  width: "100%",
                  maxWidth: "120px",
                }}
              >
                Project Name:
              </Typography>
              <Typography>
                {this.props.empmonthlyreport?.ProjectName}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "10px", width: "50%" }}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  width: "100%",
                  maxWidth: "120px",
                }}
              >
                Month
              </Typography>
              <Typography>{this.props.empmonthlyreport?.Month}</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              // flexWrap: "wrap",
              gap: "10px",
              width: "100%",
              paddingX: "20px",
              // maxWidth:"80%"
              // overflow: "scroll",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px", width: "50%" }}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  width: "100%",
                  maxWidth: "120px",
                }}
              >
                Due Date:
              </Typography>
              <Typography>
                {this.props.empmonthlyreport?.dueDate}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "10px", width: "50%" }}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  width: "100%",
                  maxWidth: "120px",
                }}
              >
                Recieved Date:
              </Typography>
              <Typography>{this.props.empmonthlyreport?.RecievedDate}</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              // flexWrap: "wrap",
              gap: "10px",
              width: "100%",
              paddingX: "20px",
              // maxWidth:"80%"
              // overflow: "scroll",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px", width: "50%" }}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  width: "100%",
                  maxWidth: "120px",
                }}
              >
                Amount:
              </Typography>
              <Typography>
                {this.props.empmonthlyreport?.amount}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "10px", width: "50%" }}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  width: "100%",
                  maxWidth: "120px",
                }}
              >
                Difference:
              </Typography>
              <Typography>{this.props.empmonthlyreport?.difference}</Typography>
            </Box>
          </Box>
          
        </Box>
      </Box>
    );
  }
}

export default HistoryInvoice;
HistoryInvoice.propTypes = {
  empmonthlyreport: PropTypes.any,
};
