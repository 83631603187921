import { Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchEvents,
  getEmployeeDashboardActivities,
  GetSingleEmployeeDetails,
  GetPrevDaysTaskDetails,
} from "../../Redux/Slice/Dashboard/EmployeeDashboardSlice";
import theme from "../../theme/index";
import CalendarChart from "../Charts/CalendarChart/CalendarChart";
import Card2 from "./DashboardCard/Card2";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import Card3 from "./DashboardCard/Card3";
import DifferenceIcon from "@mui/icons-material/Difference";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import GradingIcon from "@mui/icons-material/Grading";
import moment from "moment";
import EmployeeAttendanceDetails from "../DashboardTable/EmployeeAttendanceDetails";
import Cookies from "universal-cookie";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Card5 from "./DashboardCard/Card5";
import EmployeeWeeklyTaskDetail from "../DashboardTable/EmployeeWeekylytasks";
import EmpPrevTaskModal from "../DashboardTable/EmployeePrevTaskModal";
const useStyles = makeStyles(() => {
  return {
    container: {
      width: "100%",
      alignItems: "center",
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      gap: "40px",
    },
    sectionContainer: {
      display: "flex",
      justifyContent: "center",
      gap: "20px",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    leftSideSection: {
      width: "100%",
    },
    rightSideSection: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },

    PaperStyle2: {
      width: "100%",
      padding: "10px",
      display: "flex",
      gap: "20px",
    },
  };
});

const EmployeeDashboard = () => {
  const {
    container,
    sectionContainer,
    leftSideSection,
    rightSideSection,
    PaperStyle2,
  } = useStyles();
  const dispatch = useDispatch();
  const { singleEmployeeDetails, IsLogin, message, prevdaystaskdata, status } =
    useSelector(getEmployeeDashboardActivities);
  console.log("prevdaystaskdata", prevdaystaskdata);
  console.log("Status", status);
  const cookies = new Cookies();
  const user = cookies.get("user");
  const navigate = useNavigate();
  const { setsnackBarData } = useContext(SnackBarContext);
  const [ShowModal, setShowModal] = useState(false);
  const [Tasksdata, setTasksdata] = useState(null);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  useEffect(() => {
    dispatch(GetSingleEmployeeDetails(user?.Emp_id));
    dispatch(GetPrevDaysTaskDetails(user?.Emp_id));
    dispatch(FetchEvents());
  }, [dispatch]);
  return (
    <Box className={container}>
      <Box className={sectionContainer}>
        <Box className={leftSideSection}>
          <Grid
            container
            sx={{
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <Grid item xs={12} md={12} sx={{ paddingTop: "9px" }}>
              <Typography
                variant="h6"
                sx={{
                  color: (theme) => theme.palette.grey["500"],
                  textAlign: "left",
                }}
              >
                Attendance Details
              </Typography>
            </Grid>

            <>
              <Grid item xs={5.5} md={5.9} sx={{ minWidth: "250px" }}>
                <Paper className={PaperStyle2} elevation={3}>
                  <Card2
                    title={
                      <Typography
                        sx={{ color: (theme) => theme.palette.success.main }}
                      >
                        Presents ({moment().format("MMMM")})
                      </Typography>
                    }
                    data={singleEmployeeDetails?.Present_count}
                    icon={
                      <PersonAddAlt1Icon
                        sx={{
                          fontSize: "35px",
                          color: (theme) => theme.palette.success.main,
                        }}
                      />
                    }
                  />
                </Paper>
              </Grid>
              <Grid item xs={5.5} md={5.9} sx={{ minWidth: "250px" }}>
                <Paper className={PaperStyle2} elevation={3}>
                  <Card2
                    title={
                      <Typography
                        sx={{ color: (theme) => theme.palette.error.main }}
                      >
                        Absents ({moment().format("MMMM")})
                      </Typography>
                    }
                    data={singleEmployeeDetails?.Absent_count}
                    icon={
                      <PersonRemoveIcon
                        sx={{
                          fontSize: "35px",
                          color: (theme) => theme.palette.error.main,
                        }}
                      />
                    }
                  />
                </Paper>
              </Grid>
            </>
            <Grid item xs={12} md={12} sx={{ paddingTop: "20px " }}>
              <Typography
                variant="h6"
                sx={{
                  color: (theme) => theme.palette.grey["500"],
                  textAlign: "left",
                }}
              >
                Leave Applications
              </Typography>
            </Grid>

            <>
              <Grid item xs={5.5} md={5.9} sx={{ minWidth: "250px" }}>
                <Paper className={PaperStyle2} elevation={3}>
                  <Card3
                    title={"Pendings"}
                    data={singleEmployeeDetails?.Pending_count}
                    icon={
                      <DifferenceIcon
                        sx={{
                          fontSize: "35px",
                          color: (theme) => theme.palette.white.main,
                        }}
                      />
                    }
                  />
                </Paper>
              </Grid>
              <Grid item xs={5.5} md={5.9} sx={{ minWidth: "250px" }}>
                <Paper className={PaperStyle2} elevation={3}>
                  <Card3
                    title={"Approved"}
                    data={singleEmployeeDetails?.Approve_count}
                    icon={
                      <GradingIcon
                        sx={{
                          fontSize: "35px",
                          color: (theme) => theme.palette.white.main,
                        }}
                      />
                    }
                  />
                </Paper>
              </Grid>
            </>

            <>
              <Grid item xs={5.5} md={5.9} sx={{ minWidth: "250px" }}>
                <Paper className={PaperStyle2} elevation={3}>
                  <Card3
                    title={"Rejected"}
                    data={singleEmployeeDetails?.Rejectcount}
                    icon={
                      <ContentPasteGoIcon
                        sx={{
                          fontSize: "35px",
                          color: (theme) => theme.palette.white.main,
                        }}
                      />
                    }
                  />
                </Paper>
              </Grid>
            </>
            <Grid item xs={12} md={12} sx={{ paddingTop: "20px " }}>
              <Typography
                variant="h6"
                sx={{
                  color: (theme) => theme.palette.grey["500"],
                  textAlign: "left",
                }}
              >
                Task Assigned
              </Typography>
            </Grid>

            <>
              {prevdaystaskdata?.map((item, idx) => {
                return (
                  <Grid key={idx} item xs={8} md={8} sx={{ minWidth: "450px" }}>
                    <Paper
                      className={PaperStyle2}
                      elevation={3}
                      onClick={() => {
                        setTasksdata(item?.Tasks);
                        setShowModal(true);
                      }}
                    >
                      <Card5
                        title={"Date"}
                        data={moment(item?.Date).format("DD MMMM")}
                        title2={"Estimated Hours"}
                        data2={item?.EstimatedHours}
                        title3={"Worked Hours"}
                        data3={item?.WorkedHours}
                        icon={
                          <AddTaskIcon
                            sx={{
                              fontSize: "35px",
                              color: (theme) => theme.palette.white.main,
                            }}
                          />
                        }
                      />
                    </Paper>
                  </Grid>
                );
              })}
            </>
          </Grid>
        </Box>
        <Box className={rightSideSection}>
          <Box sx={{ width: "100%" }}>
            <EmployeeAttendanceDetails />
          </Box>
          <Box sx={{ width: "100%" }}>
            <EmployeeWeeklyTaskDetail />
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          background: (theme) => theme.palette.white.main,
          borderRadius: "5px",
          width: "100%",
        }}
      >
        <CalendarChart />
      </Box>

      <EmpPrevTaskModal
        Tasksdata={Tasksdata}
        ShowModal={ShowModal}
        setShowModal={setShowModal}
      />
    </Box>
  );
};

export default EmployeeDashboard;
