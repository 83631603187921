import React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import * as Yup from "yup";
import {
  Button,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import theme from "../../theme";
import { PatchChangeInReqCompanyApi } from "../../Api/ChangeinReqApi/PatchChangeInReqCompany";
import {
  ChangeReqEmpPopulate,
  ChangeRequestStatusUpdated,
  fetchChangeRequest,
  fetchsingleEmpChangeReq,
  getChangerequestDetails,
} from "../../Redux/Slice/ChangeRequest/ChangeRequestSlice";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment/moment";
import Cookies from "universal-cookie";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => {
  return {
    inputBox: {
      width: "100%",
      minWidth: "500px",
      [theme.breakpoints.down("lg")]: {
        minWidth: "500px",
      },
      [theme.breakpoints.down("md")]: {
        width: "300px",
      },
      [theme.breakpoints.down("sm")]: {
        minWidth: "200px",
      },
      [theme.breakpoints.up("xl")]: {
        minWidth: "500px",
      },
    },
    input_typography: {
      textAlign: "left",

      fontSize: `17px !important`,
      marginTop: "10px",
    },
  };
});
const cookies = new Cookies();

const CompanyStatusForm = ({ filtertoStatus, setShowUpdateForm }) => {
  const user = cookies.get("user");
  const { inputBox, input_typography } = useStyles();
  const { setsnackBarData } = useContext(SnackBarContext);
  const dispatch = useDispatch();
  const { Employee, IsLogin, message } = useSelector(getChangerequestDetails);
  const [EmployeePopulate, setEmployeePopulate] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();
  console.log(filtertoStatus);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
    setEmployeePopulate(Employee);
  }, [Employee, IsLogin]);
  useEffect(() => {
    dispatch(ChangeReqEmpPopulate());
  }, [dispatch]);
  const [statusUpdate, setstatusUpdate] = useState([
    {
      index: 4,
      statusValue: "Fresh",
      statusName: "Fresh",
    },
    {
      index: 0,
      statusValue: "inreview",
      statusName: "inreview",
    },
    {
      index: 1,
      statusValue: "allocated",
      statusName: "allocated",
    },

    {
      index: 2,
      statusValue: "completed",
      statusName: "completed",
    },
  ]);
  console.log(filtertoStatus);
  useEffect(() => {
    if (filtertoStatus?.company_status !== "allocated") {
      let value = statusUpdate;
      value = value.slice(0, length - 1);

      setstatusUpdate(value);
    }
  }, [filtertoStatus]);

  // let ret = false;
  useEffect(() => {
    if (filtertoStatus?.company_status === "allocated") {
      setIsDisabled(true);
    }
  }, [filtertoStatus]);

  const CompanyStatusSchema = Yup.object({
    company_status: Yup.string().required("Required"),
  });
  const UpdateCompanyStatus = async (values, formikHelpers) => {
    if (values.company_status === "Fresh") {
      setsnackBarData({
        type: "error",
        message: "Please Change Status",
        openToast: true,
      });
      formikHelpers.setSubmitting(false);
      return;
    } else if (values.company_status === "inreview") {
      const response = await PatchChangeInReqCompanyApi({
        company_status: values.company_status,
        request_id: values.request_id,
      });

      setsnackBarData(response.snackBarData);
      if (response.data.IsSuccess) {
        if (cookies.get("user")?.type === "Employee") {
          dispatch(fetchsingleEmpChangeReq(cookies.get("user").Emp_id));
        } else if (cookies.get("user")?.type === "Admin") {
          dispatch(fetchChangeRequest({ page: 0, pageSize: 10 }));
        }
        dispatch(ChangeRequestStatusUpdated(response.data.Data));

        setShowUpdateForm("Table");
        formikHelpers.resetForm();
      }

      formikHelpers.setSubmitting(false);
    } else if (values.company_status === "allocated") {
      if (
        !values.start_date ||
        !values.due_date ||
        !values.emp_id ||
        !values?.complexity ||
        !values?.estimated_hours
      ) {
        setsnackBarData({
          type: "error",
          message: "Please Add Required Data",
          openToast: true,
        });
        formikHelpers.setSubmitting(false);
        return;
      }

      const response = await PatchChangeInReqCompanyApi({
        request_id: values.request_id,
        company_status: values.company_status,
        start_date: values.start_date,
        due_date: values.due_date,
        emp_id: values.emp_id,
        complexity: values?.complexity,
        estimated_hours: values?.estimated_hours,
        workpkgid: filtertoStatus?.submodule?.workpkgid,
      });

      setsnackBarData(response.snackBarData);
      if (response.data.IsSuccess) {
        setShowUpdateForm("Table");

        if (cookies.get("user")?.type === "Employee") {
          dispatch(fetchsingleEmpChangeReq(cookies.get("user").Emp_id));
        } else if (cookies.get("user")?.type === "Admin") {
          dispatch(fetchChangeRequest({ page: 0, pageSize: 10 }));
        }
        dispatch(ChangeRequestStatusUpdated(response.data.Data));

        formikHelpers.resetForm();
      }
      formikHelpers.setSubmitting(false);
    } else if (values.company_status === "completed") {
      if (!values.completed_date || !values.workedhours) {
        setsnackBarData({
          type: "error",
          message: "Please Add Required Data",
          openToast: true,
        });
        formikHelpers.setSubmitting(false);
        return;
      }
      const response = await PatchChangeInReqCompanyApi({
        request_id: values.request_id,
        company_status: values.company_status,
        completed_date: values.completed_date,
        timeline_id: values.req_timeline,
        workedhours: values.workedhours,
      });
      setsnackBarData(response.snackBarData);
      if (response.data.IsSuccess) {
        if (user?.type === "Employee") {
          dispatch(fetchsingleEmpChangeReq(user?.Emp_id));
        } else {
          dispatch(fetchChangeRequest({ page: 0, pageSize: 10 }));
        }
        dispatch(ChangeRequestStatusUpdated(response.data.Data));
        setShowUpdateForm("Table");

        formikHelpers.resetForm();
      }
      formikHelpers.setSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        minHeight: "calc(100vh - 175px)",
      }}
    >
      <Formik
        initialValues={{
          company_status: filtertoStatus ? filtertoStatus.company_status : "",
          start_date: filtertoStatus
            ? moment(filtertoStatus?.req_timeline?.start_date).format(
                "YYYY-MM-DD"
              )
            : "",
          due_date: filtertoStatus
            ? moment(filtertoStatus?.req_timeline?.due_date).format(
                "YYYY-MM-DD"
              )
            : null,
          emp_id:
            user?.type === "Employee"
              ? user?.Emp_id
              : filtertoStatus.req_timeline?.emp_id?.Emp_id,
          completed_date: "",
          req_timeline: filtertoStatus?.req_timeline?.timeline_id,
          request_id: filtertoStatus?.request_id,
          complexity: filtertoStatus?.complexity
            ? filtertoStatus?.complexity
            : "",
          estimated_hours: filtertoStatus?.req_timeline
            ? filtertoStatus?.req_timeline?.estimated_hours
            : "",
          workedhours: "",
        }}
        onSubmit={(values, formikHelpers) => {
          UpdateCompanyStatus(values, formikHelpers);
        }}
        validationSchema={CompanyStatusSchema}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          touched,
          errors,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  maxWidth: "760px",
                  display: "flex",
                  gap: "20px",
                  flexWrap: "wrap",
                  [theme.breakpoints.down("md")]: {
                    maxWidth: "500px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    maxWidth: "300px",
                  },
                }}
              >
                <Box className={inputBox}>
                  <Typography className={input_typography}>
                    Company Status
                  </Typography>
                  <TextField
                    sx={{ textAlign: "left" }}
                    fullWidth
                    size="small"
                    name="company_status"
                    type="text"
                    value={values.company_status}
                    select
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    error={
                      Boolean(touched.company_status) &&
                      Boolean(errors.company_status)
                    }
                    helperText={
                      Boolean(touched.company_status) && errors.company_status
                    }
                  >
                    {statusUpdate?.map((status, ind) => {
                      // if (status.statusValue === values.company_status) {
                      //   ret = true;
                      // }

                      let index = statusUpdate.findIndex(
                        (x) => x.statusValue === filtertoStatus.company_status
                      );

                      // if (ret === true) {
                      return (
                        ind >= index && (
                          <MenuItem key={ind} value={status.statusValue}>
                            {status.statusName}
                          </MenuItem>
                        )
                      );

                      // user?.type === "Employee" &&
                      //   (status.statusName === "completed" ||
                      //     status.statusName === "allocated") ? (
                      //   <MenuItem key={ind} value={status.statusValue}>
                      //     {status.statusName}
                      //   </MenuItem>
                      // ) : user?.type === "Admin" ? (
                      //   stat ? (
                      //     <MenuItem key={ind} value={status.statusValue}>
                      //       {status.statusName}
                      //     </MenuItem>
                      //   ) : null
                      // ) : null;
                      // }
                    })}
                  </TextField>
                </Box>
                {values.company_status === "allocated" ? (
                  <>
                    <Box className={inputBox}>
                      <Typography className={input_typography}>
                        Start Date
                      </Typography>
                      <TextField
                        sx={{ textAlign: "left" }}
                        fullWidth
                        size="small"
                        name="start_date"
                        type="date"
                        value={values.start_date}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        disabled={isDisabled}
                        inputProps={{
                          min: moment().format("YYYY-MM-DD"),
                        }}
                      />
                    </Box>
                    <Box className={inputBox}>
                      <Typography className={input_typography}>
                        Due Date
                      </Typography>
                      <TextField
                        sx={{ textAlign: "left" }}
                        fullWidth
                        size="small"
                        name="due_date"
                        type="date"
                        value={values.due_date}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        disabled={isDisabled}
                        inputProps={{
                          min: moment().format("YYYY-MM-DD"),
                        }}
                      />
                    </Box>
                    <Box className={inputBox}>
                      <Typography className={input_typography}>Emp</Typography>
                      <TextField
                        sx={{ textAlign: "left" }}
                        fullWidth
                        size="small"
                        name="emp_id"
                        type="text"
                        select
                        value={values.emp_id}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        disabled={isDisabled}
                      >
                        {EmployeePopulate?.map((emp) => (
                          <MenuItem key={emp.id} value={emp.id}>
                            {emp.name}{" "}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                    <Box className={inputBox}>
                      <Typography className={input_typography}>
                        Complexity
                      </Typography>
                      <TextField
                        sx={{ textAlign: "left" }}
                        fullWidth
                        size="small"
                        name="complexity"
                        type="text"
                        select
                        value={values.complexity}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        disabled={isDisabled}
                      >
                        <MenuItem value="easy">Easy</MenuItem>
                        <MenuItem value="medium">Medium</MenuItem>
                        <MenuItem value="hard">Hard</MenuItem>
                        <MenuItem value="complex">Complex</MenuItem>
                      </TextField>
                    </Box>
                    <Box className={inputBox}>
                      <Typography className={input_typography}>
                        Estimated Hours
                      </Typography>
                      <TextField
                        sx={{ textAlign: "left" }}
                        fullWidth
                        size="small"
                        name="estimated_hours"
                        type="number"
                        value={values.estimated_hours}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        disabled={isDisabled}
                      />
                    </Box>
                  </>
                ) : (
                  ""
                )}
                {values.company_status === "completed" ? (
                  <>
                    <Box className={inputBox}>
                      <Typography className={input_typography}>
                        Completed Date
                      </Typography>
                      <TextField
                        sx={{ textAlign: "left" }}
                        fullWidth
                        size="small"
                        name="completed_date"
                        type="date"
                        value={values.completed_date}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        inputProps={{
                          min: moment().format("YYYY-MM-DD"),
                        }}
                      />
                    </Box>
                    <Box className={inputBox}>
                      <Typography className={input_typography}>
                        Worked Hours
                      </Typography>
                      <TextField
                        sx={{ textAlign: "left" }}
                        fullWidth
                        size="small"
                        name="workedhours"
                        type="number"
                        value={values.workedhours}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                      />
                    </Box>
                  </>
                ) : (
                  ""
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "flex-end",
                  paddingTop: "20px",
                }}
              >
                <Button
                  sx={{ width: "120px", backgroundColor: "red !important" }}
                  onClick={() => setShowUpdateForm("Table")}
                  variant="contained"
                >
                  Cancel
                </Button>
                {isSubmitting ? (
                  <CircularProgress />
                ) : (
                  <Button
                    sx={{ width: "120px" }}
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </Button>
                )}
              </Box>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default CompanyStatusForm;
CompanyStatusForm.propTypes = {
  filtertoStatus: PropTypes.any,
  setShowUpdateForm: PropTypes.any,
};
