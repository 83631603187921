import React from "react";
import { useState } from "react";
import JobTitleForm from "../../Components/JobTitle/JobTitleForm";
import JobTitleTable from "../../Components/JobTitle/JobTitleTable";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useEffect } from "react";

const JobTitleMain = () => {
  const [ShowForm, setShowForm] =useState(false);
  const [updateRow,setupdateRow] = useState([])
  const [isEdited,setIsEdited]=useState(false)
  const [IsEdit, setIsEdit] = useState(false);
  const [EditedData, setEditedData] = useState(null);
  const [canView, setcanView] = useState(null);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  useEffect(() => {
    let string = "JobTitles";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    let match = value?.some((val) => {
      if (val?.module?.split("_")[1] === "View") {
        return true;
      }
    });
    setcanView(match);
    console.log(match);
    if (!roles || roles.length < 1) {
      setcanView(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  return (
    <div>
            {canView === true ? (

      ShowForm ? (
        <JobTitleForm
          setShowForm={setShowForm}
          updateRow={updateRow}
          setIsEdited={setIsEdited}
          isEdited={isEdited}
          setIsEdit={setIsEdit}
          setEditedData={setEditedData}
          IsEdit={IsEdit}
          EditedData={EditedData}
        />
      ) : (
        <JobTitleTable
        setupdateRow={setupdateRow} setShowForm={setShowForm}
        setIsEdited={setIsEdited}
        isEdited={isEdited}
        setIsEdit={setIsEdit}
          setEditedData={setEditedData}
          EditedData={EditedData}
        />
      )
      ) : canView === false ? (
        <Navigate to={"/dashboard"} />
      ) : (
        <Box>Loading ...</Box>
      )}
    </div>
  );
};

export default JobTitleMain;
JobTitleMain.propTypes = {
  setShowForm: PropTypes.any,
  isEdited: PropTypes.any,
  setIsEdited: PropTypes.any,
};
