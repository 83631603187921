import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import StyleIcon from "@mui/icons-material/Style";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "start",
    padding: theme.spacing(2),
    gap: theme.spacing(2),
  },
  header: {
    fontWeight: "bold",
  },
  description: {
    fontSize: theme.typography.pxToRem(16),
    color: "#53b7c5",
  },
  card: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "65%",
    },
    alignItems: "start",
    padding: theme.spacing(5),
    gap: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
  },
  cardItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: theme.spacing(1),
    cursor: "pointer",
  },
  icon: {
    color: "#53b7c5",
  },
}));

const SettingMain = () => {
  const style = useStyles();
  const navigate = useNavigate();

  return (
    <div>
      <Box className={style.root}>
        <Typography variant="h6" className={style.header}>
          Employee
        </Typography>
        <Typography variant="body1" className={style.description}>
          Manage all your employee settings here.
        </Typography>
        <Box className={style.card}>
          <Box
            className={style.cardItem}
            onClick={() => {
              navigate("/tags");
            }}
          >
            <StyleIcon className={style.icon} />
            <Typography variant="h7">Tags</Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default SettingMain;
