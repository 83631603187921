import {
  Autocomplete,
  Box,
  Button,
  // CircularProgress,
  Grid,
  gridClasses,
  InputAdornment,
  //   MenuItem,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useRef } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import moment from "moment/moment";
import SearchIcon from "@mui/icons-material/Search";
import { Navigate, useNavigate } from "react-router-dom";
import theme from "../theme";
import {
  fetchMonthlyReportPopulate,
  getmonthlyreportDetails,
} from "../Redux/Slice/MonthlyReport/MonthlyReportSlice";
import { SnackBarContext } from "../Context/SnackBarContext/SnackBarContext";
import { getAtandanceDetailsReportDetails } from "../Redux/Slice/AtandanceDetailsReport/AtandanceDetailsReportSlice";
import { logout } from "../Redux/Slice/LogoutSlice/LogoutSlice";
import Loader from "../Components/Loader/Loader";
import { GetEmpOffDayApi } from "../Api/MonthlyReport/GetEmpOffDayApi";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
    Form_input: {
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,
    },
  };
});
function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport csvOptions={{ fileName: "EmpOffDays" }} />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  );
}
function EmpOffDays() {
  const { setsnackBarData } = useContext(SnackBarContext);
  const navigate = useNavigate();
  const { header, Form_input } = useStyles();
  const dispatch = useDispatch();
  const [rows, setrows] = useState([]);
  const [pageSize, setPageSize] = useState(40);
  const { AtandanceDetailsReport, status, IsLogin, message } = useSelector(
    getAtandanceDetailsReportDetails
  );
  const { Report_Populate } = useSelector(getmonthlyreportDetails);
  const [atandanceDetailsReport, setatandanceDetailsReport] = useState([]);
  const [employees, setemployees] = useState([]);
  const [EmpName, setEmpName] = useState(null);
  const Date = useRef();
  const [canView, setcanView] = useState(null);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  useEffect(() => {
    let string = "AttendanceDetailsReport";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    let match = value?.some((val) => {
      if (val?.module?.split("_")[1] === "View") {
        return true;
      }
    });
    setcanView(match);
    console.log(match);
    if (!roles || roles.length < 1) {
      setcanView(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  console.log(EmpName);

  useEffect(() => {
    dispatch(fetchMonthlyReportPopulate());

    setemployees(Report_Populate);
  }, [dispatch]);
  useEffect(() => {
    if (Report_Populate) {
      setemployees(Report_Populate);
    }
  }, [Report_Populate]);
  const columns = [
    { field: "sr", headerName: "Sr #", width: 100, headerClassName: header },
    {
      field: "Date",
      headerName: "Date",
      width: 200,
      headerClassName: header,
    },
    {
      field: "CheckIn",
      headerName: "Check In",
      width: 200,
      headerClassName: header,
    },
    {
      field: "CheckOut",
      headerName: "Check Out",
      width: 200,
      headerClassName: header,
    },
    {
      field: "Worked_hrs",
      headerName: "Worked Hours",
      width: 200,
      headerClassName: header,
    },
    {
      field: "OverTime_hrs",
      headerName: "Extra Hours",
      width: 200,
      headerClassName: header,
    },
    {
      field: "waste_time",
      headerName: "Short Hours",
      width: 200,
      headerClassName: header,
    },
  ];
  useEffect(() => {
    let arr = [];

    AtandanceDetailsReport &&
      AtandanceDetailsReport?.forEach((item, idx) => {
        return arr.push({
          ...item,
          id: idx + 1,
          sr: idx + 1,
          Date: moment(item?.Date).format("DD-MMM-YYYY"),
          CheckIn: item?.CheckIn
            ? moment(item?.CheckIn, "HH:mm").format("LT")
            : "",
          CheckOut: item?.CheckOut
            ? moment(item?.CheckOut, "HH:mm").format("LT")
            : "",
          OverTime_hrs: item?.OverTime_hrs,
          Worked_hrs: item?.Worked_hrs,
          waste_time: item?.waste_time,
        });
      });

    setrows(arr);
    setatandanceDetailsReport(arr);
  }, [AtandanceDetailsReport]);

  const handleSubmit = async () => {
    if (!EmpName) {
      return setsnackBarData({
        type: "error",
        message: "Please Enter Employee",
        openToast: true,
      });
    }
    dispatch(
      GetEmpOffDayApi({
        id: EmpName?.id,
      })
    );
  };

  const onTextChange = () => {
    if (Date.current.value === "") {
      setatandanceDetailsReport(rows);
    } else {
      let suggestions = [];
      let regex1 = new RegExp(
        moment(Date.current.value).format("DD-MMM-YYYY"),
        "i"
      );

      console.log(regex1);
      suggestions = rows?.filter(
        (val) => Date.current?.value === "" || regex1.test(val?.Date)
      );
      console.log(suggestions);
      setatandanceDetailsReport(suggestions);
    }
  };
  console.log(employees);
  return (
    <>
      {canView === true ? (
        <>
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                paddingBottom: "10px",
                width: "100%",
                padding: "10px 10px 0px 10px",
                alignItems: "center",
              }}
            >
              <Autocomplete
                disablePortal
                options={employees}
                getOptionLabel={(option) => option.name}
                sx={{
                  input: { color: "black" },
                  maxWidth: "250px",
                  textAlign: "left",
                }}
                className={Form_input}
                fullWidth
                size="small"
                onChange={(e, val) => {
                  console.log(e, val);
                  setEmpName(val);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="employee"
                    placeholder="Employee"
                  />
                )}
              />

              <Button
                onClick={() => {
                  //   handleGet();
                  handleSubmit();
                }}
                type="submit"
                sx={{ padding: "5px 10px" }}
                variant="contained"
              >
                Get
              </Button>
            </Box>
            <Box sx={{ padding: "10px 10px 0px 10px" }}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        sx={{ color: (theme) => theme.palette.grey.G2 }}
                      />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                type="date"
                autoComplete="off"
                placeholder="Search"
                // className="input_border"
                inputRef={Date}
                id="date"
                onChange={onTextChange}
              />
            </Box>
          </Box>

          {status === "loading" ? <Loader /> : null}
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            overflow
            sx={{ height: "calc(100vh - 182px)", padding: "10px" }}
          >
            <DataGrid
              rows={atandanceDetailsReport}
              columns={columns}
              onRowClick={() => {}}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
              pagination
              components={{
                Toolbar: CustomToolbar,
              }}
              localeText={{
                toolbarDensity: "Size",
                toolbarDensityLabel: "Size",
                toolbarDensityCompact: "Small",
                toolbarDensityStandard: "Medium",
                toolbarDensityComfortable: "Large",
              }}
            />
          </Grid>
        </>
      ) : canView === false ? (
        <Navigate to={"/dashboard"} />
      ) : (
        <Box>Loading ...</Box>
      )}
    </>
  );
}

export default EmpOffDays;
