import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  engagements: null,
  adminEngagements:null,
  AllUserEngagements:[],
  employees:[],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};

export const FetchAllEngUser = createAsyncThunk(
  "userpopulate",
  async () => {
    const response = await axiosInstance.get(`userpopulate`);
    return response.data;
  }
);
export const FetchAllEngagements = createAsyncThunk(
  "FetchAllEngagements",
  async (data) => {
    const response = await axiosInstance.post(`getuserstask`,data);
    return response.data;
  }
);

export const FetchAdminEngagements = createAsyncThunk(
  "FetchAdminEngagements",
  async (obj) => {
    console.log(obj)
    // const response = await axiosInstance.get(`getuserstask`);
    // return response.data;
  }
);

export const FetchEmployeesForEngagements = createAsyncThunk(
  "FetchEmployeesForEngagements",
  async () => {
    const response = await axiosInstance.get(`userpopulate`);
    return response.data;
  }
);


const EngagementSlice = createSlice({
  name: "engagementSlice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(FetchAllEngagements.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchAllEngagements.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.engagements = action.payload?.Tasks;
      })
      .addCase(FetchAllEngagements.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(FetchAdminEngagements.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchAdminEngagements.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.engagements = action.payload?.Tasks;
      })
      .addCase(FetchAdminEngagements.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(FetchEmployeesForEngagements.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchEmployeesForEngagements.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.employees = action.payload?.users;
      })
      .addCase(FetchEmployeesForEngagements.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(FetchAllEngUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchAllEngUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.AllUserEngagements = action.payload?.users;
      })
      .addCase(FetchAllEngUser.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
  },
});

export const GetAllEngagements = (state) => state.engagementSlice;

export default EngagementSlice.reducer;
