import React, { useEffect } from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
// import MenuItem from "@mui/material/MenuItem";
import { Autocomplete, CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getSubScriptionHistoryDetails,
  ProjectSubScriptionHistoryAdded,
} from "../../Redux/Slice/ProjectSubscriptionHistory/ProjectSubscriptionHisSlice";
import { useState } from "react";
import { ProjectSubscriptionHistoryApi } from "../../Api/ProjectSubscriptionHistoryApi/SubscriptionHistoryApi";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import {
  getSubScriptionDetails,
  ProjectSubscriptionPopulate,
} from "../../Redux/Slice/ProjectSubscriptionSlice/ProjectSubScriptionSlice";

const useStyles = makeStyles(() => {
  return {
    inputBox: {
      width: "100%",
    },
    input_typography: {
      textAlign: "left",
      fontSize: `17px !important`,
      // paddingBottom: "10px",
    },
  };
});

const SubscriptionHistoryForm = ({ setShowForm }) => {
  const { setsnackBarData } = useContext(SnackBarContext);

  const { IsLogin, message } = useSelector(getSubScriptionHistoryDetails);
  const { Projects } = useSelector(getSubScriptionDetails);

  const disptach = useDispatch();
  const [allProjects, setAllProjects] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      disptach(logout());
      return navigate("/");
    }
    setAllProjects(Projects);
  }, [Projects, IsLogin]);

  useEffect(() => {
    disptach(ProjectSubscriptionPopulate());
  }, [disptach]);

  const { inputBox, input_typography } = useStyles();
  const SubscriptionSchema = Yup.object({
    month: Yup.string().required("Required"),
    project_id: Yup.string().required("Required"),
  });

  const handleSubscriptionHistoryAdd = async (values, formikHelpers) => {
    const response = await ProjectSubscriptionHistoryApi(values);
    setsnackBarData(response.snackBarData);
    if (response.data.IsSuccess) {
      setShowForm(false);
      formikHelpers.resetForm();
      disptach(ProjectSubScriptionHistoryAdded(response.data));
    }
    formikHelpers.setSubmitting(false);
  };
console.log(allProjects);
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        height: "calc(100vh - 175px)",
        // paddingTop: "50px",
        padding: "40px 20px 20px 20px",
      }}
    >
      <Formik
        initialValues={{
          month: "",
          project_id: "",
        }}
        validationSchema={SubscriptionSchema}
        onSubmit={(values, formikHelpers) => {
          handleSubscriptionHistoryAdd(values, formikHelpers);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form
            onSubmit={handleSubmit}
            style={{ width: "100%", maxWidth: "800px" }}
          >
            <Box
              sx={{
                maxWidth: "800px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Box className={inputBox}>
                <Typography className={input_typography}>Month</Typography>
                <TextField
                  fullWidth
                  size="small"
                  name="month"
                  type="month"
                  value={values.month}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  error={Boolean(touched.month) && Boolean(errors.month)}
                  helperText={Boolean(touched.month) && errors.month}
                />
              </Box>

              <Box className={inputBox}>
                <Typography className={input_typography}>Project</Typography>

                {/* <TextField
                  sx={{ textAlign: "left" }}
                  fullWidth
                  size="small"
                  name="project_id"
                  type="text"
                  value={values.project_id}
                  onBlur={handleBlur}
                  select
                  onChange={handleChange}
                  variant="outlined"
                  error={
                    Boolean(touched.project_id) && Boolean(errors.project_id)
                  }
                  helperText={Boolean(touched.project_id) && errors.project_id}
                >
                  <MenuItem value="">Select</MenuItem>
                  {allProjects.map((project) => (
                    <MenuItem value={project.id} key={project.id}>
                      {project?.name}
                    </MenuItem>
                  ))}
                </TextField> */}
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  name="project_id"
                  options={allProjects}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  // value={values.project_id}
                  size="small"
                  onChange={(e, val) => {
                    console.log(e, val);
                    handleChange({
                      target: {
                        name: "project_id",
                        value: val?.id,
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="project_id"
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                paddingTop: "20px",
                gap: "20px",
              }}
            >
              <Button
                color="error"
                variant="contained"
                onClick={() => setShowForm(false)}
              >
                Cancel
              </Button>
              {isSubmitting ? (
                <Box>
                  <CircularProgress />
                </Box>
              ) : (
                <Box>
                  <Button type="submit" variant="contained">
                    Save
                  </Button>
                </Box>
              )}
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default SubscriptionHistoryForm;
SubscriptionHistoryForm.propTypes = {
  setShowForm: PropTypes.any,
};
