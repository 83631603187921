import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  designation: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,

};

export const fetchDesignation = createAsyncThunk(
  "getdesignation",
  async () => {
    const response = await axiosInstance.get(`getdesignation`);
    return response.data;
  }
);

const DesignationSlice = createSlice({
  name: "designation",
  initialState,
  reducers: {
    DesignationAdded: {
      reducer(state, action) {
        state.designation.push(action.payload);
   
      },
    },
    deleteDesignation: {
      reducer(state, action) {
        const userIndex = state?.designation.findIndex(
          (designation) => designation.designation_id === action.payload
        );
        if (userIndex !== -1) {
          state.designation.splice(userIndex, 1);
        }
      },
    },
    DesignationResetData: (state) => {
      state.designation= [];
      state.isSuccess= false;
      state.message= null;
      state.status= "idle"; //'idle' | 'loading' | 'succeeded' | 'failed'
      state.error= null;
      state.IsLogin= true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDesignation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDesignation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.designation = action.payload.Designations;
      })
      .addCase(fetchDesignation.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

  },
});

export const getDesignationDetails = (state) => state.designation;

export const { DesignationAdded,DesignationResetData , deleteDesignation} = DesignationSlice.actions;

export default DesignationSlice.reducer;