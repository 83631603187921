import React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import theme from "../../theme";
import { makeStyles } from "@mui/styles";
// import { Select } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getSubScriptionDetails,
  ProjectSubscriptionAdded,
  // ProjectAdded,
  ProjectSubscriptionPopulate,
  // ProjectSubscriptionUpdated,
} from "../../Redux/Slice/ProjectSubscriptionSlice/ProjectSubScriptionSlice";
import { useState } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { ProjectSubscriptionApi } from "../../Api/Project Subscription/ProjectSubscriptionApi";
import { Autocomplete, CircularProgress, Typography } from "@mui/material";
import { PatchProjectSubscriptionApi } from "../../Api/Project Subscription/PatchSubscriptionApi";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
// import { useState } from "react";

const useStyles = makeStyles(() => {
  return {
    inputBox: {
      width: "100%",
      minWidth: "300px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "200px",
      },
      [theme.breakpoints.down("md")]: {
        minWidth: "100px",
      },
    },
    input_typography: {
      textAlign: "left",
      fontSize: `17px !important`,
      // paddingBottom: "10px",
    },
  };
});

const ProjectSubscriptionForm = ({
  setShowForm,
  editRow,
  isEdited,
  setEditRow,
  setisEdited,
}) => {
  const { setsnackBarData } = useContext(SnackBarContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Projects, IsLogin, message } = useSelector(getSubScriptionDetails);

  const [allProjects, setAllProjects] = useState([]);

  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
    setAllProjects(Projects);
  }, [Projects, IsLogin]);

  useEffect(() => {
    dispatch(ProjectSubscriptionPopulate());
  }, [dispatch]);

  const { inputBox, input_typography } = useStyles();

  const handleADD = async (values, formikHelpers) => {
    console.log(values);
    if (isEdited) {
      const response2 = await PatchProjectSubscriptionApi({
        subscription_type: values.subscription_type,
        subscription_id: values.subscription_id,
        margin_days: values.margin_days,
        amount: values.amount,
      });

      setsnackBarData(response2.snackBarData);

      if (response2?.data?.IsSuccess) {
        setShowForm(false);
        setEditRow(null);
        setisEdited(false);
        // formikHelpers.resetForm();
        // dispatch(ProjectSubscriptionUpdated())
      }
      setEditRow(null);
      setisEdited(false);
      formikHelpers.setSubmitting(false);
    } else {
      const response = await ProjectSubscriptionApi({
        ...values,
        project_id: values?.project_id?.id,
      });

      setsnackBarData(response.snackBarData);

      if (response.data?.IsSuccess) {
        setShowForm(false);
        setEditRow(null);
        setisEdited(false);
        dispatch(ProjectSubscriptionAdded(response.data.Data));
      }
      formikHelpers.setSubmitting(false);
    }
  };
  const ProjectSubscriptionSchema = Yup.object({
    project_id: Yup.object().required("Required"),
    subscription_type: Yup.string().required("Required"),
    margin_days: Yup.string().required("Required"),
    amount: Yup.string().required("Required"),
  });
  console.log(editRow);
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        height: "calc(100vh - 175px)",
        padding: "0px 20px 20px 20px",
      }}
    >
      <Formik
        initialValues={{
          project_id: editRow
            ? { id: editRow.Project_id, name: editRow?.ProjectName }
            : null,
          subscription_type: editRow ? editRow.subscription_type : "",
          margin_days: editRow ? editRow?.margin_days : "",
          amount: editRow ? editRow?.amount : "",
          subscription_id: editRow ? editRow.subscription_id : "",
          status: editRow ? editRow.status : "",
        }}
        validationSchema={ProjectSubscriptionSchema}
        onSubmit={(values, formikHelpers) => {
          handleADD(values, formikHelpers);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  maxWidth: "760px",
                  display: "flex",
                  gap: "20px",
                  flexWrap: "wrap",
                  paddingTop: "50px",
                }}
              >
                <Box className={inputBox}>
                  <Typography className={input_typography}>
                    Project Name
                  </Typography>

                  {/* <TextField
                    sx={{ textAlign: "left" }}
                    fullWidth
                    size="small"
                    name="project_id"
                    type="text"
                    value={values.project_id}
                    select
                    // label="Project"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    error={
                      Boolean(touched.project_id) && Boolean(errors.project_id)
                    }
                    helperText={
                      Boolean(touched.project_id) && errors.project_id
                    }
                  >
                    <MenuItem value="" sx={{ textAlign: "left" }}>
                      select
                    </MenuItem>
                    {allProjects.map((project) => (
                      <MenuItem value={project.id} key={project.id}>
                        {project?.name}
                      </MenuItem>
                    ))}
                  </TextField> */}
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    disabled={isEdited}
                    name="project_id"
                    options={allProjects}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    value={values.project_id}
                    size="small"
                    onChange={(e, val) => {
                      console.log(e, val);
                      handleChange({
                        target: {
                          name: "project_id",
                          value: val,
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="project_id"
                        error={
                          Boolean(errors.project_id) &&
                          Boolean(touched.project_id)
                        }
                        helperText={
                          Boolean(touched.project_id) && errors.project_id
                        }
                      />
                    )}
                  />
                </Box>
                <Box className={inputBox}>
                  <Typography className={input_typography}>
                    Subscription Type
                  </Typography>

                  <TextField
                    sx={{ textAlign: "left" }}
                    fullWidth
                    size="small"
                    name="subscription_type"
                    type="text"
                    value={values.subscription_type}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    // label="ProjectSubscription-Type"
                    variant="outlined"
                    error={
                      Boolean(touched.subscription_type) &&
                      Boolean(errors.subscription_type)
                    }
                    helperText={
                      Boolean(touched.subscription_type) &&
                      errors.subscription_type
                    }
                  >
                    <MenuItem value="monthly">monthly</MenuItem>
                    <MenuItem value="annualy">annualy</MenuItem>
                    <MenuItem value="biannualy">biannualy</MenuItem>
                  </TextField>
                </Box>
                <Box className={inputBox}>
                  <Typography className={input_typography}>
                   Amount
                  </Typography>

                  <TextField
                    sx={{ textAlign: "left" }}
                    fullWidth
                    size="small"
                    name="amount"
                    type="number"
                    value={values?.amount}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // label="ProjectSubscription-Type"
                    variant="outlined"
                    error={
                      Boolean(touched.amount) &&
                      Boolean(errors.amount)
                    }
                    helperText={
                      Boolean(touched.amount) && errors.amount
                    }
                  />
                </Box>
                <Box className={inputBox}>
                  <Typography className={input_typography}>
                    Margin Day
                  </Typography>

                  <TextField
                    sx={{ textAlign: "left" }}
                    fullWidth
                    size="small"
                    name="margin_days"
                    type="number"
                    value={values?.margin_days}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // label="ProjectSubscription-Type"
                    variant="outlined"
                    error={
                      Boolean(touched.margin_days) &&
                      Boolean(errors.margin_days)
                    }
                    helperText={
                      Boolean(touched.margin_days) && errors.margin_days
                    }
                  />
                </Box>
              </Box>
              {isSubmitting ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    paddingTop: "10px",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "flex-end",
                    paddingTop: "20px",
                  }}
                >
                  <Button
                    sx={{ width: "80px" }}
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setEditRow(null), setisEdited(false), setShowForm(false);
                    }}
                  >
                    Cancel
                  </Button>
                  {isEdited ? (
                    <Button
                      sx={{ width: "80px" }}
                      type="submit"
                      variant="contained"
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      sx={{ width: "80px" }}
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  )}
                </Box>
              )}
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default ProjectSubscriptionForm;
ProjectSubscriptionForm.propTypes = {
  setShowForm: PropTypes.any,
  editRow: PropTypes.any,
  isEdited: PropTypes.any,
  setEditRow: PropTypes.any,
  setisEdited: PropTypes.any,
  // setisEdited:PropTypes.any,
};
