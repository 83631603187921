import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
// import logo_round from "../../Assets/logo_round.png";
import {
  AdminSideBarData,
  EmployeeSideBarData,
  SuperAdminSideBarData,
} from "../../Resources/SideBarData";
import SideBarMenu from "./SubSideBar";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useDispatch } from "react-redux";
import LogoutConfirmationModal from "../LogoutConfirmationModal/LogoutConfirmationModal";

const cookies = new Cookies();
const useStyles = makeStyles((theme) => {
  return {
    sidebar_logo: {
      marginX: "10% !important",
      height: "32px",
    },
    sideBar: {
      // maxHeight:"fit-content !important",
      // minHeight:"100vh",
      height: "100%",
      minHeight: " calc(100vh - 104px)! important",
      overflowY: "scroll",
      overflowX: "hidden",
      scrollbarWidth: "none",
      "&::-webkitScrollbar": {
        display: "none",
      },
      textAlign: "start",
      // padding: "15px !important",
      display: "flex",
      flexDirection: "column",
      gap: "15px",
    },
    logoutContainer: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      paddingLeft: "18px",
      padding: "10px 0px",
      color: theme.palette.grey[50],
      transition: "0.5s",
      cursor: "pointer",
      "&:hover": {
        background: theme.palette.background.text,
      },
    },
  };
});

const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme, open) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  [theme.breakpoints.down("sm")]: {
    position: "absolute",
    visibility: open ? "visible" : "hidden",
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  // padding: "10px !important",
  backgroundColor: theme.palette.background.secondary,
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  [theme.breakpoints.down("sm")]: {
    position: "absolute",
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme, open),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  padding: "15px !important",
  backgroundColor: theme.palette.background.secondary,

  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const SideBar = ({
  open,
  setOpen,
  setOnHover,
  selectedSecondTab,
  setselectedSecondTab,
  selectedTab,
  setselectedTab,
}) => {
  const { sideBar, logoutContainer } = useStyles({ open });
  const [Roles, setRoles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [openlogout, setopenlogout] = useState(false);
  const [user, setuser] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.type === "Employee") {
      EmployeeSideBarData.forEach((item) => {
        if (item.path === location?.pathname) {
          setselectedTab(item);
        } else {
          item?.subitems.find((subitem) => {
            if (subitem?.path === location?.pathname) {
              setselectedTab(item);
              setselectedSecondTab(subitem);
            }
          });
        }
      });
    } else if (user?.type === "Admin") {
      AdminSideBarData.forEach((item) => {
        if (item.path === location?.pathname) {
          setselectedTab(item);
        } else {
          item?.subitems.find((subitem) => {
            if (subitem?.path === location?.pathname) {
              setselectedTab(item);
              setselectedSecondTab(subitem);
            }
          });
        }
      });
    } else {
      SuperAdminSideBarData.forEach((item) => {
        if (item.path === location?.pathname) {
          setselectedTab(item);
        } else {
          item?.subitems.find((subitem) => {
            if (subitem?.path === location?.pathname) {
              setselectedTab(item);
              setselectedSecondTab(subitem);
            }
          });
        }
      });
    }

    setRoles(JSON.parse(localStorage.getItem("roles")));
    setuser(cookies.get("user"));
  }, []);

  const handleLogout = (e) => {
    e.stopPropagation();
    dispatch(logout());
    return navigate("/");
  };

  console.log(Roles);
  console.log(AdminSideBarData);
  console.log("user", user);
  return (
    <Drawer
      variant="permanent"
      open={open}
    >
      <DrawerHeader>
        {/* <img src={logo_round} className={sidebar_logo} /> */}
      </DrawerHeader>
      <List
        className={sideBar}
        sx={{
          background: (theme) => theme.palette.background.secondary,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          // backgroundImage: "linearGradient(180deg, #FFFFFF, RGBA(233,241,255,1))",
        }}
        onMouseOver={() => {
          if (open === false) {
            setOpen(true);
            setOnHover(true);
          }
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            height: "calc (100vh - 200px)",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          {user?.type === "Employee"
            ? EmployeeSideBarData.map((item, index) => {
                return (
                  <SideBarMenu
                    open={open}
                    item={item}
                    key={index}
                    setselectedTab={setselectedTab}
                    selectedTab={selectedTab}
                    selectedSecondTab={selectedSecondTab}
                    setselectedSecondTab={setselectedSecondTab}
                    setOpen={setOpen}
                    setOnHover={setOnHover}
                  />
                );
              })
            : user?.type === "Admin"
            ? AdminSideBarData.map((item, index) => {
                if (item?.subitems.length < 1) {
                  let matches = Roles?.ruleid?.some((role) => {
                    if (
                      role?.module.split("_")[0]?.toLowerCase() ===
                        item?.title?.toLowerCase() &&
                      role?.module.split("_")[1].toLowerCase() === "view"
                    ) {
                      console.log(item?.title?.toLowerCase());
                      return true;
                    }
                  });

                  if (matches) {
                    return (
                      <SideBarMenu
                        open={open}
                        item={item}
                        key={index}
                        setselectedTab={setselectedTab}
                        selectedTab={selectedTab}
                        selectedSecondTab={selectedSecondTab}
                        setselectedSecondTab={setselectedSecondTab}
                        Roles={Roles}
                        setOpen={setOpen}
                        setOnHover={setOnHover}
                      />
                    );
                  }
                } else {
                  return (
                    <SideBarMenu
                      open={open}
                      item={item}
                      key={index}
                      setselectedTab={setselectedTab}
                      selectedTab={selectedTab}
                      selectedSecondTab={selectedSecondTab}
                      setselectedSecondTab={setselectedSecondTab}
                      Roles={Roles}
                      setOpen={setOpen}
                      setOnHover={setOnHover}
                    />
                  );
                }
              })
            : SuperAdminSideBarData.map((item, index) => {
                return (
                  <SideBarMenu
                    open={open}
                    item={item}
                    key={index}
                    setselectedTab={setselectedTab}
                    selectedTab={selectedTab}
                    selectedSecondTab={selectedSecondTab}
                    setselectedSecondTab={setselectedSecondTab}
                    setOpen={setOpen}
                    setOnHover={setOnHover}
                  />
                );
              })}
        </Box>
        <Box
          className={logoutContainer}
          onClick={() => setopenlogout(true)}
        >
          <LogoutIcon
            sx={{
              fontSize: "30px",
              color: (theme) => theme.palette.white.main,
            }}
          />
          <Typography
            visibility={open ? "visible" : "hidden"}
            sx={{ fontWeight: "bold" }}
            color={"inherit"}
          >
            Logout
          </Typography>
        </Box>
      </List>
      <LogoutConfirmationModal
        showModal={openlogout}
        setshowModal={setopenlogout}
        handleLogout={handleLogout}
      />
    </Drawer>
  );
};

export default SideBar;

SideBar.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setOnHover: PropTypes.object,
  selectedSecondTab: PropTypes.func,
  setselectedSecondTab: PropTypes.func.isRequired,
  selectedTab: PropTypes.func.isRequired,
  setselectedTab: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};
