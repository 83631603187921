import {
    IconButton,
    Modal,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    Typography,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
const useStyles = makeStyles(() => {
    return {
        ModalContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            padding: "10px",
            width: "100%",
        },
        Container: {
            width: "100%",
            maxWidth: "630px",
            // height: '311px',
            padding: "20px",
            border: "none",
            position: "relative",
            background: "white",
            borderRadius: "10px",
            display: "flex",
            gap: "10px",
            flexDirection: "column",
        },
        TableContain: { height: "120px", overflowY: "scroll" },
        TableContain2: { height: "150px", overflowY: "scroll" },
        TableHeader: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        Form_input: {
            borderRadius: '3px',
            backgroundColor: '#ADD8E6 !important',
            padding: '4px'
        },
        child_box: {
            display: "flex",
            width: "100%",
            maxWidth: "280px",
          },
          box_heading: {
            fontWeight: "bold",
            width: "80px",
          },
    };
});

const JobTitleModel = ({ open, setOpen, selected }) => {
    const { TableContain, Container, ModalContainer, TableHeader,Form_input,child_box, box_heading ,TableContain2} = useStyles();

    return (
        <>
            <Modal open={open}>
                <Box className={ModalContainer}>
                    <Box className={Container}>
                        <Box className={TableHeader}>
                            <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                                Job Title Detail
                            </Typography>
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setOpen(!open);
                                }}
                            >
                                <HighlightOffIcon
                                    color="primary"
                                    sx={{
                                        fontSize: "28px",
                                    }}
                                />
                            </IconButton>
                        </Box>
                        <Box>
                        <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                overflow: "scroll",
              }}
            >
              <Box className={child_box}>
                <Typography className={box_heading}>Job Title:</Typography>
                <Typography sx={{ paddingLeft: "30px" }}>
                  {selected?.name}
                </Typography>
              </Box>
              <Box className={child_box}>
                <Typography className={box_heading}>Department:</Typography>
                <Typography sx={{ paddingLeft: "30px" }}>
                  {selected?.department}
                </Typography>
              </Box>
              </Box>
              </Box>
              <Typography className={box_heading}>Technologies:</Typography>

                        <TableContainer component={Paper} className={TableContain}>
                            <Table
                                stickyHeader
                                sx={{
                                    minWidth: { md: "auto", xs: "auto" },
                                    overflow: "scroll",
                                }}
                                aria-label="simple table"
                                size="small"
                            >
                                <TableBody sx={{ display: 'flex', gap: '15px', flexWrap: 'wrap' }}>
                                    {selected &&
                                        selected?.technologiesName
                                            .split(",")
                                            .map((tech, index) => (

                                                <TableRow key={index} >
                                                    <Typography className={Form_input}>{tech.trim()}</Typography>
                                                </TableRow>
                                            ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Typography className={box_heading}>Experties:</Typography>

<TableContainer component={Paper} className={TableContain2}>
    <Table
        stickyHeader
        sx={{
            minWidth: { md: "auto", xs: "auto" },
            overflow: "scroll",
        }}
        aria-label="simple table"
        size="small"
    >
        <TableBody sx={{ display: 'flex', gap: '15px', flexWrap: 'wrap' }}>
            {selected &&
                selected?.expertiseName
                    .split(",")
                    .map((tech, index) => (

                        <TableRow key={index} >
                            <Typography className={Form_input}>{tech.trim()}</Typography>
                        </TableRow>
                    ))}
        </TableBody>
    </Table>
</TableContainer>
                        
                    </Box>
                </Box>
            </Modal>


        </>
    );
};


export default JobTitleModel;

JobTitleModel.propTypes = {
    open: PropTypes.any,
    setOpen: PropTypes.any,
    selected: PropTypes.any,
};