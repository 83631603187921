import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => {
  return {
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
    },
    Container: {
      width: "100%",
      maxWidth: "500px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableContain: { height: "450px", overflowY: "scroll" },
  };
});
// name, handelDelete,
const DeleteModal = ({
  setshowModal,
  showModal,
  name,
  loading,
  handelDelete,
}) => {
  const { Container, ModalContainer } = useStyles();

  return (
    <Modal
      open={showModal}
      onClose={() => {

        setshowModal(false);
      }}
    >
      <Box className={ModalContainer}>
        <Box className={Container}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              overflow: "scroll",
            }}
          >
            <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
              Are you sure you want to delete {name}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                padding:'10px 20px'
              }}
            >
              {loading ? (
                <CircularProgress />
              ) : (
                <>
                  {" "}
                  <Button
                    sx={{
                      textTransform: "none",
                      border: "1px solid #3480FA",
                    }}
                    onClick={() => {
                      setshowModal(false);
                    }}
                  >
                    No
                  </Button>
                  <Button
                    sx={{
                      textTransform: "none",
                      backgroundColor: "#3480FA",
                      color: "white !important",
                    }}
                    onClick={handelDelete}
                  >
                    Yes
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
DeleteModal.propTypes = {
  name: PropTypes.string,
  handelDelete: PropTypes.func,
  setshowModal: PropTypes.func,
  showModal: PropTypes.bool,
  loading: PropTypes.bool,
};
