import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
// import { Line } from "react-chartjs-2";
import PropTypes from "prop-types";
// import moment from "moment";
import ReactApexChart from "react-apexcharts";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Change Requests",
    },
  },
};

export const WeeklyLineChart = ({ weeklyData }) => {
  const [chartdata,setchartdata] = useState([])
  useEffect(() => {
    const values = weeklyData?.map((data) => {
      let obj = {
        name: data?.label,
        data: data?.data,
      };
      return obj;
    });
    console.log(values);
    setchartdata(values)
  }, [weeklyData]);
const [state] = useState({
  // series:
  //   [
  //     {
  //       name: "Desktops",
  //       data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
  //     }
  //   ],
  options: {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    title: {
      text: "Weekly Change Requests",
      align: "left",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [
        "SAT",
        "SUN",
        "MON",
        "TUE",
        "WED",
        "THU",
        "FRI",
      
      ],
    },
  },
});

console.log(weeklyData);


  return (
    <ReactApexChart
      options={state.options}
      series={chartdata}
      type="line"
      height={350}
    />
  );

    
};

WeeklyLineChart.propTypes = {
  weeklyData: PropTypes.any,
};
