import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  engagementEvents: null,
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};

export const FetchEngagementEvents = createAsyncThunk(
  "FetchEngagementEvents",
  async (obj) => {
    const response = await axiosInstance.get(
      `usertaskcalendar?user_id=${obj.userid}&month=${obj?.month}`
    );
    return response.data;
  }
);



const EngagementEvents = createSlice({
  name: "engagementeventSlice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(FetchEngagementEvents.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchEngagementEvents.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.engagementEvents = action.payload?.Tasks;
      })
      .addCase(FetchEngagementEvents.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
     
  },
});

export const GetAllEngagementEvents = (state) => state.engagementeventSlice;

export default EngagementEvents.reducer;
