import React, { useContext, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Typography,
  FormControl,
  CircularProgress,
  // Select,
  // MenuItem,
} from "@mui/material";
// import moment from "moment";
import PropTypes from "prop-types";
import theme from "../../theme";
import { makeStyles } from "@mui/styles";
import {  useFormik } from "formik";
import * as Yup from "yup";
import { GazettedHolidayAdded } from "../../Redux/Slice/GazettedHolidays/GazettedHolidaysSlice";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { AddGazettedHolidaysApi, UpdateGazHolidayApi } from "../../Api/GazettedHolidays/GazettedHolidaysAPis";
import { useDispatch } from "react-redux";
import moment from "moment";
const useStyles = makeStyles(() => {
  return {
    Form_input: {
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,
    },
    input_typography: {
      textAlign: "left",
      fontSize: `15px !important`,
      marginTop: "10px !important",
      paddingRight: "20px !important",
    },

    form_main: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      padding: "60px 8%",
      minHeight: "calc(100vh - 173px)",
    },
    responsive: {
      maxWidth: "900px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },

    btns: {
      width: "80px",
    },
    inputBox: {
      width: "100%",
    },
    FieldInRow: {
      display: "flex",
      justifyContent: "space-between",
      gap: "25px",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },
  };
});

const GasetHoliday = Yup.object({
  holiday_name: Yup.string().required("Required"),
  holiday_type: Yup.string().required("Required"),
  holiday_date: Yup.string().required("Required"),
});

const GazettedHolidaysForm = ({ setShowForm, setIsEdit,
  setEditedData,
  EditedData,
  IsEdit,
}) => {
  const {
    form_main,
    responsive,
    Form_input,
    btns,
    input_typography,
    inputBox,
    FieldInRow,
  } = useStyles();
  const dispatch = useDispatch();
  const { setsnackBarData } = useContext(SnackBarContext);
  const handelAdd = async (values, resetForm, setSubmitting) => {
    let response
    if (IsEdit) {
      response = await UpdateGazHolidayApi(values);
      if (response.data.IsSuccess) {
        resetForm();
      setShowForm(false);
      }
        
    }
    else {
      response = await AddGazettedHolidaysApi(values);
      if (response.data.IsSuccess) {
        resetForm();
        dispatch(GazettedHolidayAdded(response.data.GazHolidays));
      setShowForm(false);
      }
    }
    setsnackBarData(response.snackBarData);

    // if (response.data.IsSuccess) {
    //   resetForm();
    //   dispatch(GazettedHolidayAdded(response.data.GazHolidays));
    //   setShowForm(false);
    // }
    setSubmitting(false);
  };

  const initialValues = {
    holiday_name: "",
    holiday_type: "",
    holiday_date: "",
  }
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: GasetHoliday,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handelAdd(values, resetForm, setSubmitting);
    },
  });


  useEffect(() => {
    console.log(EditedData)
    console.log(IsEdit)
    if (EditedData && IsEdit) {

      Object.keys(EditedData)?.map((key) => {
        formik?.setFieldValue(key, EditedData[key])


      })
    }
  }, [EditedData, IsEdit])
console.log(moment);
  return (
    <Box className={form_main}>
      <Box className={responsive}>


        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <Box className={FieldInRow}>
              <Box className={inputBox}>
                <Typography className={input_typography}>Date</Typography>
                <FormControl fullWidth sx={{ textAlign: "left" }}>
                  <TextField
                    className={Form_input}
                    fullWidth

                    size="small"
                    name="holiday_date"
                    type="date"

                    value={formik?.values.holiday_date}
                    variant="outlined"
                    // inputProps={{
                    //   min: moment().format("YYYY-MM-DD"),
                    // }}
                    error={
                      Boolean(formik.touched.holiday_date) &&
                      Boolean(formik.errors.holiday_date)
                    }
                    helperText={
                      Boolean(formik.touched.holiday_date) && formik.errors.holiday_date
                    }
                    onBlur={formik.handleBlur}
                    onChange={
                      formik.handleChange
                    }
                  />
                </FormControl>
              </Box>

              <Box className={inputBox}>
                <Typography className={input_typography}>Name</Typography>
                <FormControl fullWidth sx={{ textAlign: "left" }}>
                  <TextField
                    className={Form_input}
                    fullWidth

                    size="small"
                    name="holiday_name"
                    type="text"

                    value={formik?.values.holiday_name}
                    variant="outlined"
                    error={
                      Boolean(formik.touched.holiday_name) &&
                      Boolean(formik.errors.holiday_name)
                    }
                    helperText={
                      Boolean(formik.touched.holiday_name) && formik.errors.holiday_name
                    }
                    onBlur={formik.handleBlur}
                    onChange={
                      formik.handleChange
                    }
                  />
                </FormControl>
              </Box>
            </Box>

            <Box className={inputBox}>
              <Typography className={input_typography}>Type</Typography>
              <FormControl fullWidth sx={{ textAlign: "left" }}>
                <TextField
                  className={Form_input}
                  fullWidth

                  size="small"
                  name="holiday_type"
                  type="text"

                  value={formik?.values.holiday_type}
                  select
                  variant="outlined"
                  error={
                    Boolean(formik.touched.holiday_type) &&
                    Boolean(formik.errors.holiday_type)
                  }
                  helperText={
                    Boolean(formik.touched.holiday_type) && formik.errors.holiday_type
                  }
                  onBlur={formik.handleBlur}
                  onChange={
                    formik.handleChange
                  }
                >
                  <MenuItem value="National Holiday">
                    National Holiday
                  </MenuItem>
                  <MenuItem value="Optional Holiday">
                    Optional Holiday
                  </MenuItem>
                  {/* <MenuItem value="Islamic Holiday">Islamic Holiday</MenuItem> */}
                </TextField>
              </FormControl>
            </Box>
            <Box
              sx={{
                justifyContent: "flex-end",
                display: "flex",
                gap: "10px",
                //   marginTop: "20px",
              }}
            >
              <Button
                className={btns}
                variant="contained"
                color="error"
                onClick={() => { setShowForm(false); setIsEdit(false); setEditedData(null) }}
              >
                Cancel
              </Button>

              {formik?.isSubmitting ? (
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Box
                  sx={{
                    justifyContent: "flex-end",
                    display: "flex",
                    gap: "10px",
                    //   marginTop: "20px",
                  }}
                >
                  <>
                    <Button
                      type="submit"
                      className={btns}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        // setShowForm(false);
                      }}
                    >
                      {IsEdit ? "Update" : "Save"}
                    </Button>
                  </>
                </Box>
              )}
            </Box>
          </Box>
        </form>

      </Box>
    </Box>
  );
};

export default GazettedHolidaysForm;
GazettedHolidaysForm.propTypes = {
  setShowForm: PropTypes.any,
  setIsEdit: PropTypes.func,
  setEditedData: PropTypes.func,
  IsEdit: PropTypes.any,
  EditedData: PropTypes.any
};
