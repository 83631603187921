import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import AllRoles from "./AllRoles";
import PropTypes from "prop-types"; 
import RuleName from "./RuleName";

const useStyles = makeStyles(() => {
  return {
    form_main: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      padding: "60px 8%",
      minHeight: "calc(100vh - 173px)",
    },
    responsive: {
      maxWidth: "600px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },


  };
});
function RolesForm(
  { setShowForm,IsEdit,rolesRowData,setIsEdit,setrolesRowData }
  ) {
    const {
      form_main,
      responsive,

    } = useStyles();
    const [AllRole,setAllRole]=useState(null);
    console.log(rolesRowData);
  return (
<>

<Box className={form_main}>
      <Box className={responsive}>
<RuleName IsEdit={IsEdit} setIsEdit={setIsEdit} setrolesRowData={setrolesRowData} rolesRowData={rolesRowData} AllRole={AllRole} setShowForm={setShowForm}/>
<AllRoles rolesRowData={rolesRowData} IsEdit={IsEdit} setAllRole={setAllRole}/>
</Box>
</Box>
</>
  );
}

export default RolesForm;
RolesForm.propTypes = {
  setShowForm: PropTypes.any,
  IsEdit: PropTypes.any,
  rolesRowData: PropTypes.any,
  setIsEdit: PropTypes.any,
  setrolesRowData: PropTypes.any,
  // setEditedData: PropTypes.func,
};
