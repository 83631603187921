import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  ProjectSubHistory: [],
  Projects: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};

export const fetchProjectSubHistoryData = createAsyncThunk(
  "getsubhistorydata",
  async () => {
    const response = await axiosInstance.get(`getsubhistorydata`);
  
    return response.data;
  }
);

export const ProjectSubscriptionHistoryPopulate = createAsyncThunk(
  "projsubscriptionpopulate",
  async () => {
    const response = await axiosInstance.get(`projsubscriptionpopulate`);

    return response.data;
  }
);

const ProjectSubscriptionHistorySlice = createSlice({
  name: "ProjectSubscriptionHistory",
  initialState,
  reducers: {
    ProjectSubScriptionHistoryAdded: {
      reducer(state, action) {
        state.ProjectSubscription.push(action.payload);
      },
    },
    ProjectSubHistoryStatusUpdated: {
      // reducer(state, action) {
      //   state.ProjectSubHistory.map((item)=>{
      //     if(item.request_id===action.payload.request_id)
      //     {
      //       item=action.payload
      //     }
      //   })
      // },
    },
    ProjSubHistResetData: (state) => {
      state.ProjectSubHistory = [];
      state.Projects = [];
      state.isSuccess = false;
      state.message = null;
      state.status = "idle"; //'idle' | 'loading' | 'succeeded' | 'failed'
      state.error = null;
      state.IsLogin = true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProjectSubHistoryData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProjectSubHistoryData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.ProjectSubHistory = action.payload.SubHistory;
      })
      .addCase(fetchProjectSubHistoryData.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {

          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(ProjectSubscriptionHistoryPopulate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        ProjectSubscriptionHistoryPopulate.fulfilled,
        (state, action) => {
          state.status = "succeeded";
          state.message = action.payload.Message;
          state.isSuccess = action.payload.IsSuccess;
          state.Projects = action.payload.Projects;
        }
      )
      .addCase(ProjectSubscriptionHistoryPopulate.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {

          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
  },
});

export const getSubScriptionHistoryDetails = (state) =>
  state.ProjectSubscriptionHistory;

export const {
  ProjectSubScriptionHistoryAdded,
  ProjectSubHistoryStatusUpdated,
  ProjSubHistResetData,
} = ProjectSubscriptionHistorySlice.actions;

export default ProjectSubscriptionHistorySlice.reducer;
