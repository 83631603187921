import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  allowances: null,
  allowanceHistory: [],
  allowanceofEmployee: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};

export const fetchAllAllowances = createAsyncThunk(
  "get/allallowances",
  async () => {
    const response = await axiosInstance.get(`getallowances`);
    console.log(response.data);
    return response.data;
  }
);

export const FetchAllowanceHistory = createAsyncThunk(
  "get/getallowancehistory",
  async () => {
    const response = await axiosInstance.get(`getallowancehistory`);
    console.log(response.data);
    return response.data;
  }
);
export const FetchAllowanceEmployee = createAsyncThunk(
  "/getempallowances/",
  async (id) => {
    const response = await axiosInstance.get(`getempallowances/${id}`);
    console.log(response.data);
    return response.data;
  }
);

const AllowanceSlice = createSlice({
  name: "Allowances",
  initialState,
  reducers: {
    EmpAllowanceResetData: (state) => {
      state.allowances = null;
      state.allowanceHistory = [];
      state.isSuccess = false;
      state.message = null;
      state.status = "idle"; //'idle' | 'loading' | 'succeeded' | 'failed'
      state.error = null;
      state.IsLogin = true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllAllowances.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllAllowances.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.allowances = action.payload.Allowance;
      })
      .addCase(fetchAllAllowances.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(FetchAllowanceHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchAllowanceHistory.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.allowanceHistory = action.payload.Allowance;
      })
      .addCase(FetchAllowanceHistory.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(FetchAllowanceEmployee.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchAllowanceEmployee.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.allowanceofEmployee = action.payload.Allowance;
      })
      .addCase(FetchAllowanceEmployee.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
  },
});

export const getallallowances = (state) => state.Allowances;
export const { EmpAllowanceResetData } = AllowanceSlice.actions;

export default AllowanceSlice.reducer;
