import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import { Form, Formik } from "formik";
import { object, ref, string } from "yup";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import theme from "../../theme";
import { useNavigate } from "react-router-dom";
//   import { UpdatePassword } from "../../Api/UpdatePassword/UpdatePassword";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
//   import { useEffect } from "react";
import Cookies from "universal-cookie";
import { UpdatePassProfile } from "../../Api/UpdatePassProfile/UpdatePassProfileApi";
const useStyle = makeStyles(() => {
  return {
    container: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      // background: theme.palette.BackgroundGradient.main,
      // height: "100vh",
      // width: "100%",
      // padding: "25px",
      // [theme.breakpoints.down("md")]: {
      //   minHeight: "100vh",
      //   height: "fit-content",
      //   padding: "30px 10px",
      // },
    },
    BoxContainer: {
      alignItems: "start",
      display: "flex",
      // background: theme.palette.BackgroundGradient.main,
      borderRadius: "10px",
      padding: "20px",
      boxShadow:
        "rgba(20, 20, 20, 0.16) 0px 5px 16px 0px, rgba(20, 20, 20, 0.06) 0px 0px 0px 1px",
      width: "100%",
      maxWidth: "850px",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        width: "100%",
      },
    },
    LeftSideTypoContainer: {
      textAlign: "left",
      width: "100%",
      padding: "15px",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      // marginTop:"-70px"
    },
    Typo: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      color: theme.palette.grey[700],
    },
    RightSideFormContainer: {
      textAlign: "left",
      width: "100%",
      padding: "20px",
    },
  };
});

function ChangePassProfile() {
  const [showPassword, setshowPassword] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [loading, setloading] = useState(false);
  const cookies = new Cookies();
  const {
    container,
    BoxContainer,
    LeftSideTypoContainer,
    Typo,
    RightSideFormContainer,
  } = useStyle();
  const initialValues = {
    oldPassword: "",
    password: "",
    cpassword: "",
  };
  const user = cookies.get("user");
  const navigate = useNavigate();

  const HandleFormValues = async (values) => {
    setloading(true);
    const obj = {
      user_id: user?.user_id,
      prevpassword: values?.oldPassword,
      newpassword: values?.password,
    };

    const response = await UpdatePassProfile(obj);
    setsnackBarData(response.snackBarData);

    if (response.data.IsSuccess) {
      setloading(false);
    }
    setloading(false);
  };

  return (
    <Box className={container}>
      <Box className={BoxContainer}>
        <Box className={LeftSideTypoContainer}>
          <Typography
            variant="h6"
            sx={{ padding: "10px", color: (theme) => theme.palette.grey[600] }}
          >
            Password Must Contain
          </Typography>
          <Box className={Typo}>
            <DoneIcon />
            <Typography>At-least 6 character long</Typography>
          </Box>
          <Box className={Typo}>
            <DoneIcon />
            <Typography>At-least 1 upper-case character [A-Z]</Typography>
          </Box>
          <Box className={Typo}>
            <DoneIcon />
            <Typography>At-least 1 lower-case character [a-z]</Typography>
          </Box>
          <Box className={Typo}>
            <DoneIcon />
            <Typography>At-least 1 numaric character [0-9]</Typography>
          </Box>
        </Box>
        <Box className={RightSideFormContainer}>
          <Formik
            initialValues={initialValues}
            validationSchema={object({
              oldPassword: string().required("Please enter old password."),

              password: string()
                .required("Please new enter password")
                .min(8, "password must be 6 character long"),
              cpassword: string().when("password", {
                is: (val) => (val && val.length > 0 ? true : false),
                then: string().oneOf(
                  [ref("password")],
                  "Both Password need to be the same"
                ),
              }),
            })}
            onSubmit={(values, formikHelpers) =>
              HandleFormValues(values, formikHelpers)
            }
          >
            {({ errors, touched, handleChange, values }) => (
              <Form>
                <Box>
                  <Box>
                    <FormControl variant="standard" fullWidth>
                      <Typography className={Typo}>Old Password:</Typography>
                      <OutlinedInput
                        variant="outlined"
                        name="oldPassword"
                        placeholder="Old Password"
                        onChange={handleChange}
                        type="text"
                        size="small"
                        value={values.email}
                        sx={{ width: "100%" }}
                        error={
                          Boolean(errors.oldPassword) &&
                          Boolean(touched.oldPassword)
                        }
                        helperText={
                          Boolean(touched.oldPassword) && errors.oldPassword
                        }
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl variant="standard" fullWidth>
                      <Typography className={Typo}>Password:</Typography>
                      <OutlinedInput
                        type={showPassword ? "text" : "password"}
                        value={values.password}
                        placeholder="Password"
                        onChange={handleChange}
                        name="password"
                        size="small"
                        error={
                          Boolean(errors.password) && Boolean(touched.password)
                        }
                        helperText={
                          Boolean(touched.password) && errors.password
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setshowPassword(!showPassword);
                              }}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl variant="standard" fullWidth>
                      <Typography className={Typo}>
                        Confirm Password:
                      </Typography>
                      <OutlinedInput
                        type={showPassword ? "text" : "password"}
                        value={values.cpassword}
                        placeholder="Confirm Password"
                        onChange={handleChange}
                        name="cpassword"
                        size="small"
                        error={
                          Boolean(errors.cpassword) &&
                          Boolean(touched.cpassword)
                        }
                        helperText={
                          Boolean(touched.cpassword) && errors.cpassword
                        }
                      />
                    </FormControl>
                  </Box>

                  <Box
                    item
                    xs={12}
                    md={12}
                    sx={{
                      margin: "20px auto",
                      display: "flex",
                      gap: "10px",
                      justifyContent: "end",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Cancel
                    </Button>
                    {!loading ? (
                      <Button variant="contained" type="submit">
                        Submit
                      </Button>
                    ) : (
                      <CircularProgress />
                    )}
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
}

export default ChangePassProfile;
