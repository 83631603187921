import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import PropTypes from "prop-types";

const Card3 = ({ title, icon, data }) => {
  return (
    <>
      <Box
        sx={{
          height: "70px",
          width: "70px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: (theme) => theme.palette.warning.light,
          borderRadius: "10px",
        }}
      >
        {icon}
      </Box>
      <Box>
        <Typography sx={{ color: (theme) => theme.palette.grey['600']}}>
          {title}
        </Typography>
        <Typography variant="h6" sx={{fontWeight:'bold',textAlign:'left'}}>{data}</Typography>
      </Box>
    </>
  );
};

export default Card3;

Card3.propTypes = {
  title: PropTypes.any,
  data: PropTypes.any,
  icon: PropTypes.any,
};
