import PropTypes from "prop-types";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  GetMissingPunchesForEmployee,
  UpdateMissPunchOfEmployee,
} from "../../Api/MissingPunches/MissingPunchesApi";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
    input_typography: {
      textAlign: "left",
      fontSize: `15px !important`,
      marginTop: "10px !important",
    },
    btns: {
      width: "80px",
    },
  };
});

function MissingPunchesDetails({ setShowForm, setrowvalue, rowvalue }) {
  const { input_typography, btns } = useStyles();
  console.log(rowvalue);
  const [loading, setloading] = useState(false);
  const [MissingPunches, setMissingPunches] = useState([]);
  const [LogDetails, setLogDetails] = useState([]);
  const { setsnackBarData } = useContext(SnackBarContext);
  console.log(loading);
  console.log(MissingPunches);

  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [addPermit, setaddpermit] = useState(false);
  // console.log(crop);

  useEffect(() => {
    let string = "MonthlyReport";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Add") {
        setaddpermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      setaddpermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);

  console.log(addPermit);

  const GetMissingPunches = async () => {
    setloading(true);
    console.log(moment(rowvalue?.Date).format("YYYY-MM-DD"));
    console.log(rowvalue?.Date);
    const response = await GetMissingPunchesForEmployee({
      date: moment(rowvalue?.Date).format("YYYY-MM-DD"),
      emp_id: rowvalue?.Emp_id,
    });

    setloading(false);

    if (response.data.IsSuccess) {
      setMissingPunches(response?.data?.punches);
      setLogDetails(response?.data?.punches?.LogDetail);
    }
  };

  const UpdateMissPunch = async () => {
    let data = LogDetails.map((punch) => {
      return {
        ...punch,
        emp_id: rowvalue?.Emp_id,
        date: punch?.attendanceDetail_id
          ? punch?.date
          : moment(rowvalue.Date).format("YYYY-MM-DD"),
        year: punch?.attendanceDetail_id
          ? punch?.year
          : moment(rowvalue.Date).format("YYYY"),
      };
    });

    data.sort((a, b) => {
      if (a.time < b.time) {
        return -1;
      }
      if (a.time > b.time) {
        return 1;
      }
      return 0;
    });
    console.log(data);

    let status = "CheckIn";
    let isInSeq = true;

    data.forEach((item) => {
      if (item?.status === status) {
        status = status === "CheckIn" ? "CheckOut" : "CheckIn";
      } else {
        isInSeq = false;
      }
    });
    if (isInSeq) {
      console.log("seq");
      setloading(true);

      const response = await UpdateMissPunchOfEmployee({
        LogDetail: data,
        attendance_id: MissingPunches?.Attendance?.attendance_id,
        emp_id: data[0]?.emp_id,
      });
      setsnackBarData(response?.snackBarData);
      setloading(false);
      console.log(response);
      if (response.data.IsSuccess) {
        setShowForm(false);
        setrowvalue(null);
      }
    } else {
      setsnackBarData({
        type: "error",
        message:
          "Check in / Check out are not in a sequence according to time  ",
        openToast: true,
      });
    }
  };
  console.log(UpdateMissPunch);
  useEffect(() => {
    GetMissingPunches();
  }, [rowvalue]);

  const addPuch = () => {
    setLogDetails([
      ...LogDetails,
      {
        status: "",
        time: "",
        note: "Missed Punch",
      },
    ]);
  };

  console.log("MissingPunches", MissingPunches);
  return (
    <>
      <Box sx={{ display: "none" }}>
        {/* <MonthlyReportPrint ref={componentRef} filterRows={filterRows} /> */}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          padding: "20px 10px 10px 10px",
          // [theme.breakpoints.down("sm")]:{
          //   flexDirection:"column"
          // }
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <IconButton
            sx={{
              padding: "5px 15px",
              color: (theme) => theme.palette.grey[500],
              borderRadius: 1,
            }}
            // variant="outlined"
            onClick={() => {
              setShowForm(false);
              setrowvalue(null);
            }}
            // className="tabletopbutton1 text-right"
          >
            <ArrowBackIcon />
          </IconButton>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
          }}
        ></Box>
      </Box>

      <span className="ml-2 float-right">
        <div className="loaderclass"> </div>
      </span>

      <Box
        sx={{
          display: "flex",
          padding: "20px",

          gap: "20px",
        }}
      >
        <FormControl fullWidth sx={{ textAlign: "left" }}>
          <Typography className={input_typography}>Employee</Typography>
          <TextField
            size="small"
            name="degree_name"
            variant="outlined"
            color="primary"
            disabled
            value={rowvalue?.Emp_name}
            sx={{
              input: {
                color: "black",
              },
            }}
            fullWidth
          />
        </FormControl>

        <FormControl fullWidth sx={{ textAlign: "left" }}>
          <Typography className={input_typography}>Date</Typography>

          <TextField
            size="small"
            name="institute_name"
            variant="outlined"
            color="primary"
            value={moment(MissingPunches?.Attendance?.date).format(
              "DD MMM YYYY"
            )}
            disabled
            sx={{ input: { color: "black" } }}
            fullWidth
          />
        </FormControl>
      </Box>

      <Box sx={{ textAlign: "right", padding: "20px" }}>
        <Button variant="contained" sx={{ padding: "5px" }} onClick={addPuch}>
          <Typography
            sx={{
              textTransform: "capitalize",
            }}
          >
            Add Punch
          </Typography>
          <AddIcon />
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          padding: "20px",
        }}
      >
        {LogDetails &&
          LogDetails.map((logtime, index) => {
            return (
              <Paper key={index} sx={{ padding: "5px" }} elevation={3}>
                {logtime?.attendanceDetail_id ? null : (
                  <Box
                    sx={{
                      transform: "translate(50%,-50%)",
                      width: "fit-content",
                      float: "right",
                    }}
                  >
                    <IconButton
                      sx={{
                        background: (theme) => theme.palette.error.main,
                        padding: "0px",
                        color: (theme) => theme.palette.white.main,
                      }}
                      onClick={() => {
                        let newArr = JSON.parse(JSON.stringify(LogDetails));
                        newArr.splice(index, 1);
                        setLogDetails(newArr);
                      }}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </Box>
                )}
                <Box
                  sx={{
                    padding: "20px",
                    display: { md: "flex", xs: "block" },
                    gap: "20px",
                  }}
                >
                  <FormControl fullWidth sx={{ textAlign: "left" }}>
                    <Typography className={input_typography}>Time</Typography>
                    <TextField
                      size="small"
                      name="time"
                      type="time"
                      value={logtime.time}
                      variant="outlined"
                      color="primary"
                      disabled={logtime?.attendanceDetail_id}
                      sx={{
                        input: {
                          color: "black",
                        },
                      }}
                      onChange={(e) => {
                        console.log(e);

                        let newArr = JSON.parse(JSON.stringify(LogDetails));
                        newArr[index].time = e.target.value;
                        setLogDetails(newArr);
                      }}
                      fullWidth
                    />
                  </FormControl>

                  <FormControl fullWidth sx={{ textAlign: "left" }}>
                    <Typography className={input_typography}>Note</Typography>
                    <TextField
                      size="small"
                      name="note"
                      variant="outlined"
                      value={logtime.note}
                      color="primary"
                      disabled
                      sx={{ input: { color: "black" } }}
                      fullWidth
                      onChange={(e) => {
                        console.log(e);

                        let newArr = JSON.parse(JSON.stringify(LogDetails));
                        newArr[index].note = e.target.value;
                        setLogDetails(newArr);
                      }}
                    />
                  </FormControl>

                  <FormControl fullWidth sx={{ textAlign: "left" }}>
                    <Typography className={input_typography}>Status</Typography>
                    <TextField
                      size="small"
                      name="percentage_obtain"
                      variant="outlined"
                      type="number"
                      select
                      color="primary"
                      value={logtime.status}
                      onChange={(e) => {
                        console.log(e);

                        let newArr = JSON.parse(JSON.stringify(LogDetails));
                        newArr[index].status = e.target.value;
                        setLogDetails(newArr);
                      }}
                      sx={{ input: { color: "black" } }}
                    >
                      <MenuItem value="CheckIn">Check In</MenuItem>
                      <MenuItem value="CheckOut">Check Out</MenuItem>
                    </TextField>
                  </FormControl>
                </Box>
              </Paper>
            );
          })}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          marginTop: "20px",
          padding: "20px",
        }}
      >
        <Box
          sx={{
            float: "right",
            display: "flex",
            gap: "10px",
            marginTop: "20px",
          }}
          spacing={2}
        >
          <>
            <Button
              className={btns}
              variant="contained"
              color="error"
              onClick={() => {
                setShowForm(false);
                setrowvalue(null);
              }}
            >
              Cancel
            </Button>

            {!loading ? (
              <Button
                className={btns}
                onClick={UpdateMissPunch}
                variant="contained"
                color="primary"
              >
                Update
              </Button>
            ) : (
              <CircularProgress />
            )}
          </>
        </Box>
      </Box>
    </>
  );
}

export default MissingPunchesDetails;
MissingPunchesDetails.propTypes = {
  setShowForm: PropTypes.any,
  setrowvalue: PropTypes.any,
  rowvalue: PropTypes.any,
  selectedDate: PropTypes.any,
  setselectedDate: PropTypes.any,
};
