import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";
const initialState = {
  ManualLoans: [],


  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};

export const fetchManualLoans = createAsyncThunk("getallmanualloans", async () => {
  const response = await axiosInstance.get(`getallmanualloans`);

  return response.data;
});
const ManualLoansSlice = createSlice({
  name: "ManualLoan",
  initialState,
  reducers: {
    LoansAdded: {
      reducer(state, action) {
        state.ManualLoans?.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchManualLoans.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchManualLoans.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.ManualLoans = action.payload.Manual_loan;
      })
      .addCase(fetchManualLoans.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {

          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      
  },
});
export const getManualLoansDetails = (state) => state.ManualLoan;

export const { LoansAdded } = ManualLoansSlice.actions;

export default ManualLoansSlice.reducer;
