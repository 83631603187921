import { createContext, useState } from "react";
import PropTypes from "prop-types";

export const HeaderContext = createContext();

export const HeaderContextProvider = ({ children }) => {
  const [header, setheader] = useState(null);
  return (
    <HeaderContext.Provider value={{ header, setheader }}>
      {children}
    </HeaderContext.Provider>
  );
};
HeaderContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
