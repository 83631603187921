import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { IconButton, Typography } from "@mui/material";
import theme from "../../theme";
import { ArrowBack } from "@mui/icons-material";
import LockResetIcon from "@mui/icons-material/LockReset";
import Cookies from "universal-cookie";

const useStyles = makeStyles(() => {
  return {
    profile_sidebar_main: {
      flex: "auto",
      minWidth: "250px",
      maxWidth: "15%",
      paddingTop: "10px",
      display: "flex",
      flexDirection: "column",
      paddingLeft: "30px !important",
      textAlign: "left",

      [theme.breakpoints.down("md")]: {
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "10px",
        minWidth: "100% !important",
        maxWidth: "100% !important",
        paddingRight: "10px !important",
        paddingLeft: "10px !important",

        paddingTop: "5px",
      },
    },
    sidebar_title: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    sidebar_items: {
      width: "100%",
      height: "50px",
      borderBottom: `1px solid ${theme.palette.greenShades.g92}`,
      color: theme.palette.secondary.main,
      paddingLeft: "20px !important",
      display: "flex",
      alignItems: "center",
      gap: "10px",
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.primary.main,
      },
      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
        paddingLeft: "0px !important",
      },
    },
    selected_item: {
      borderLeft: `3px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      [theme.breakpoints.down("md")]: {
        borderLeft: "none !important",
        borderBottom: `3px solid ${theme.palette.primary.main}`,
      },
    },
  };
});
const SettingSideBar = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const location = useLocation();

  let menuItems = [
    {
      title: "Profile",
      path: "/setting/profile",
      selected: true,
      icon: <AccountBoxIcon />,
    },

    // cookies.get("user").type === "Admin" &&
    {
      title: "Company Data",
      path: "/setting/company",
      selected: false,
      icon: <AdminPanelSettingsIcon />,
    },
    {
      title: "Change Password",
      path: "/setting/changepassword",
      selected: false,
      icon: <LockResetIcon />,
    },
  ];

  const { profile_sidebar_main, sidebar_items, selected_item, sidebar_title } =
    useStyles();

  const handelClick = (item) => {
    navigate(item.path);
  };
  return (
    <Box className={profile_sidebar_main}>
      <Box sx={{ padding: "10px 0px" }}>
        <IconButton
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          <ArrowBack />
        </IconButton>
      </Box>
      {menuItems.map((item) => {
        return (
          <>
            {item?.path === "/setting/company" &&
            cookies.get("user").type === "Employee" ? null : (
              <Box
                key={item.title}
                className={`${sidebar_items}  ${
                  item.path === location.pathname && selected_item
                }`}
                onClick={() => handelClick(item)}
              >
                {item.icon}
                <Typography className={sidebar_title}>{item.title}</Typography>
              </Box>
            )}
          </>
        );
      })}
    </Box>
  );
};

export default SettingSideBar;
