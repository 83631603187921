import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  technologies: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};

export const  fetchTechnologies = createAsyncThunk(
  "gettechnologies",
  async () => {
    const response = await axiosInstance.get(`gettechnologies`);
    console.log(response);
    return response.data;
  }
);

const TechnologiesSlice = createSlice({
  name: "technologies",
  initialState,
  reducers: {
    TechnologiesAdded: {
      reducer(state, action) {
        state.technologies.push(action.payload);
   
      },
    },
    deleteDetechnology: {
      reducer(state, action) {
        const userIndex = state?.technologies.findIndex(
          (tech) => tech.tech_id === action.payload
        );
        if (userIndex !== -1) {
          state.technologies.splice(userIndex, 1);
        }
      },
    },

    TechnologiesResetData: (state) => {
      state.technologies = [];
      state.isSuccess = false;
      state.message = null;
      state.status = "idle"; //'idle' | 'loading' | 'succeeded' | 'failed'
      state.error = null;
      state.IsLogin = true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTechnologies.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTechnologies.fulfilled, (state, action) => {


        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.technologies = action.payload.AllTechnologies;
      })
      .addCase(fetchTechnologies.rejected, (state, action) => {

        if (action?.error?.message.includes(401)) {

          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
  },
});

export const getTechnologiesDetails = (state) => state.technologies;

export const { TechnologiesAdded, TechnologiesResetData, deleteDetechnology } =
  TechnologiesSlice.actions;

export default TechnologiesSlice.reducer;
