import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import LoansForm from "../../Components/Loans/LoansForm";
import LoansTable from "../../Components/Loans/LoansTable";
import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import { Box } from "@mui/material";

function Loans() {
  const [ShowForm, setShowForm] = useState(false);
  const [message, setmessage] = useState("");
  const [openToast, setopenToast] = useState(false);
  const [AlertType, setAlertType] = useState("success");
  const [canView, setcanView] = useState(null);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  useEffect(() => {
    let string = "Loans";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    let match = value?.some((val) => {
      if (val?.module?.split("_")[1] === "View") {
        return true;
      }
    });
    setcanView(match);
    console.log(match);
    if (!roles || roles.length < 1) {
      setcanView(false);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  return (
    <div>
      {canView === true ? (
        ShowForm ? (
          <LoansForm
            setShowForm={setShowForm}
            setopenToast={setopenToast}
            setmessage={setmessage}
            setAlertType={setAlertType}
            //   empData={empData}
            //   isEdited={isEdited}
            //   setisEdited={setisEdited}
          />
        ) : (
          <LoansTable
            setopenToast={setopenToast}
            setmessage={setmessage}
            setAlertType={setAlertType}
            setShowForm={setShowForm}
          />
        )
      ) : canView === false ? (
        <Navigate to={"/dashboard"} />
      ) : (
        <Box>Loading ...</Box>
      )}
      <Snackbar
        openToast={openToast}
        setopenToast={setopenToast}
        AlertType={AlertType}
        message={message}
      />
    </div>
  );
}

export default Loans;
Loans.propTypes = {
  setShowForm: PropTypes.any,
};
