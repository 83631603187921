import { IconButton, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, } from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DetailExtraHours from "./DetailExtraHours";

const useStyles = makeStyles(() => {
  return {
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
    },
    Container: {
      width: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableContain: { height: "450px", overflowY: "scroll" },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  };
});

const ExtraHours = ({ open, setopen, extraHours }) => {
  const { TableContain, Container, ModalContainer, TableHeader } = useStyles();
  const [selectedEmploy, setSelectedEmploy] = useState(null);
  const handleRowClick = (employee) => {
    setSelectedEmploy(employee);
    setopen(true)
  }
  console.log(extraHours)
  return (
    <>
      <Modal open={open}>
        <Box className={ModalContainer}>
          <Box className={Container}>
            <Box className={TableHeader}>
              <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                Extra Hours
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setopen(!open);
                }}
              >
                <HighlightOffIcon
                  color="primary"
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Box>
            <TableContainer component={Paper} className={TableContain}>
              <Table
                stickyHeader
                sx={{
                  minWidth: { md: "auto", xs: "auto" },
                  overflow: "scroll",
                }}
                aria-label="simple table"
                size="small"
              >
                <TableHead>
                  <TableRow size="small">
                    <TableCell size="small" sx={{ fontWeight: "bold" }}>
                      Employee Name
                    </TableCell>
                    <TableCell size="small" sx={{ fontWeight: "bold" }}>
                      Work Hours
                    </TableCell>
                    <TableCell size="small" sx={{ fontWeight: "bold" }}>
                      Extra Hours
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {extraHours &&
                    extraHours
                      .filter((row) => row?.emp_extra_hrs > 0)
                      .map((row, i) => (
                        <TableRow
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            "&:hover": {
                              backgroundColor: "#8080808a",
                              cursor: "pointer",
                            },
                          }}
                          onClick={() => handleRowClick(row)}
                        >
                          <TableCell size="small" component="th" scope="row">
                            {row?.emp_name}
                          </TableCell>
                          <TableCell size="small">
                            {`${row?.emp_workedhrs} H`}
                          </TableCell>
                          <TableCell size="small">
                            {`${row?.emp_extra_hrs} H`}
                          </TableCell>
                        </TableRow>
                      ))}

                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Modal>
      <DetailExtraHours
        open={selectedEmploy}
        setOpen={setSelectedEmploy}
        employeExtrahours={selectedEmploy}
      />
    </>
  );
};

export default ExtraHours;

ExtraHours.propTypes = {
  open: PropTypes.any,
  setopen: PropTypes.any,
  extraHours: PropTypes.any,
};
