import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  gridClasses,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useContext } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchInActiveProject,
  getProjectDetails,
} from "../../Redux/Slice/Project/ProjectSlice";
import { UpdateProjectStatApi } from "../../Api/Project/UpdateProjectStatusApi";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import SearchIcon from "@mui/icons-material/Search";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { makeStyles } from "@mui/styles";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { Navigate, useNavigate } from "react-router-dom";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import Loader from "../Loader/Loader";
import ProjectTableModal from "../Project/ProjectTableModal";
const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});
function InActiveProjects() {
  const { header } = useStyles();
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [inActiveProjectRow, setInactiveProjectRow] = useState([]);
  const [loading, setloading] = useState(false);
  const [allInactiveProject, setAllInActiveProj] = useState([]);
  const { Inactiveproject, status, IsLogin, message } =
    useSelector(getProjectDetails);
  const [filterRows, setfilterRows] = useState([]);
  const { setsnackBarData } = useContext(SnackBarContext);
  const { tabelStyle } = useContext(TabelStyleContext);
  const dispatch = useDispatch();
  const [SelectedRow, setSelectedRow] = useState({});
  const [showModal, setshowModal] = useState(false);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [editPermit, seteditpermit] = useState(false);
  const [canView, setcanView] = useState(null);
  useEffect(() => {
    let string = "InactiveProjects";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    let match = value?.some((val) => {
      if (val?.module?.split("_")[1] === "View") {
        return true;
      }
    });
    setcanView(match);
    console.log(match);
    if (!roles || roles.length < 1) {
      setcanView(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);

  useEffect(() => {
    let string = "InactiveProjects";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Edit") {
        seteditpermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      seteditpermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  console.log(editPermit);

  useEffect(() => {
    let arr = [];
    allInactiveProject &&
      allInactiveProject?.forEach((project, ind) => {
        return arr.push({
          id: ind + 1,
          project_id: project?.project_id,
          project_name: project?.project_name,
          module: project?.module,
        });
      });
    setRows(arr);
    setfilterRows(arr);
  }, [allInactiveProject]);

  const UpdateInactiveStat = async () => {
    setloading(true);

    const response = await UpdateProjectStatApi({
      project_id: inActiveProjectRow.project_id,
      project_status: "Active",
    });
    setsnackBarData(response.snackBarData);
    setloading(false);

    if (response.data.IsSuccess) {
      setInactiveProjectRow(null);
      dispatch(fetchInActiveProject());
      handleClose();
      setloading(false);
    }
  };

  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
    setAllInActiveProj(Inactiveproject, inActiveProjectRow);
  }, [Inactiveproject, IsLogin]);
  useEffect(() => {
    dispatch(fetchInActiveProject());
  }, [dispatch]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "InActive Projects" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
        {/* <GridCsvExportOptions fileName="jhdjwdhwjh" /> */}
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "id", headerName: "Sr #", width: 100, headerClassName: header },
    {
      field: "project_name",
      headerName: "Project Name",
      width: 200,
      headerClassName: header,
    },

    {
      field: "Active",
      headerName: "Actions",
      width: 180,
      headerClassName: header,
      renderCell: (celVal) => {
        return (
          <>
            <Box>
              {editPermit ? (
                <Tooltip title="Active Project">
                  <AutorenewIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      handleClickOpen();
                      setInactiveProjectRow(celVal.row);
                    }}
                  />
                </Tooltip>
              ) : null}
            </Box>
          </>
        );
      },
    },
  ];
  const onTextChange = () => {
    if (document.getElementById("ProjectNameFilter").value === "") {
      setfilterRows(rows);
    } else {
      let suggestions = [];

      var regex1 = new RegExp(
        document.getElementById("ProjectNameFilter").value,
        "i"
      );

      suggestions = rows?.filter(
        (val) =>
          document.getElementById("ProjectNameFilter").value === "" ||
          regex1.test(val.project_name)
      );

      setfilterRows(suggestions);
    }
  };
  useEffect(() => {
    if (status === "failed") {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
    }
  }, [status]);
  return (
    <>
      {canView === true ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              padding: "25px 10px 10px 10px",
            }}
          >
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{ color: (theme) => theme.palette.grey.G2 }}
                    />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              type="text"
              autoComplete="off"
              placeholder="Name"
              className="input_border"
              id="ProjectNameFilter"
              onChange={onTextChange}
            />
          </Box>
          <Box sx={{ height: tabelStyle.height, padding: "10px" }}>
            {Inactiveproject?.lenght<=0 && status === "loading" ? <Loader /> : null}
            <DataGrid
                          sx={{ cursor: "pointer" }}
                          onRowClick={(e) => {
                            setSelectedRow(e.row);
                            setshowModal(true);
                          }}
              rows={filterRows}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              components={{
                Toolbar: CustomToolbar,
              }}
              localeText={{
                toolbarDensity: "Size",
                toolbarDensityLabel: "Size",
                toolbarDensityCompact: "Small",
                toolbarDensityStandard: "Medium",
                toolbarDensityComfortable: "Large",
              }}
            />
          </Box>
          <ProjectTableModal
        data={SelectedRow}
        setshowModal={setshowModal}
        showModal={showModal}

      />
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title"></DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to Active{" "}
                <b> {inActiveProjectRow?.project_name}</b>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Button
                  sx={{
                    background: "red",
                    color: "white !important",
                    fontSize: "13px",
                    textTransform: "none",
                  }}
                  onClick={handleClose}
                >
                  No
                </Button>

                {loading ? (
                  <CircularProgress />
                ) : (
                  <Button
                    sx={{
                      background: "#3480FA",
                      color: "white !important",
                      fontSize: "13px",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      UpdateInactiveStat();
                    }}
                    autoFocus
                  >
                    Yes
                  </Button>
                )}
              </Box>
            </DialogActions>
          </Dialog>
        </>
      ) : canView === false ? (
        <Navigate to={"/dashboard"} />
      ) : (
        <Box>Loading ...</Box>
      )}
    </>
  );
}

export default InActiveProjects;
