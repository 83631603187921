import PropTypes from "prop-types";
import {
  Box,
  // Button,
  // CircularProgress,
  Grid,
  gridClasses,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@mui/styles";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import { fetchAllTags, getAllTags } from "../../Redux/Slice/Tags/TagSlice";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeleteModal from "../DeleteModal/DeleteModal";
import { DeleteTagApi } from "../../Api/Tags/DeleteTags";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../Button/AddNewButton";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import Loader from "../Loader/Loader";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
    ColorContainer: {
      height: "20px",
      width: "20px",
      borderRadius: "100%",
    },
  };
});

function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport csvOptions={{ fileName: "Shift Rules" }} />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  );
}

function TagsTable({ setShowForm, setisEdited, setselectedRow }) {
  const { header, ColorContainer } = useStyles();
  const { tabelStyle } = useContext(TabelStyleContext);

  const [filterRows, setfilterRows] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [rows, setrows] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { tags, status, IsLogin, message } = useSelector(getAllTags);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [editPermit, seteditpermit] = useState(false);
  const [addPermit, setaddpermit] = useState(false);
  const [deletePermit, setdeletePermit] = useState(false);
  const [deleteData, setdeleteData] = useState(null);
  const [showModal, setshowModal] = useState(false);
  const [loading, setloading] = useState(false);
  console.log(status);
  console.log(tags);
  useEffect(() => {
    let string = "Tags";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Edit") {
        seteditpermit(true);
      } else if (val?.module?.split("_")[1] === "Add") {
        setaddpermit(true);
      } else if (val?.module?.split("_")[1] === "Delete") {
        setdeletePermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      setaddpermit(true);
      seteditpermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  console.log(editPermit);
  console.log(addPermit);

  useEffect(() => {
    if (!tags) {
      dispatch(fetchAllTags());
    }
  }, [dispatch]);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);

  const handleDelete = async () => {
    if (deleteData) {
      setloading(true);
      const response = await DeleteTagApi(deleteData?.tag_id);
      setsnackBarData(response?.snackBarData);
      if (response.data.IsSuccess) {
        setloading(false);
        setshowModal(false);
        dispatch(fetchAllTags());
      }
      setloading(false);
    }
    console.log(deleteData);
  };

  const columns = [
    { field: "id", headerName: "Sr #", width: 100, headerClassName: header },
    {
      field: "tag",
      headerName: "Tag",
      width: 180,
      headerClassName: header,
    },
    {
      field: "color",
      headerName: "Color",
      width: 170,
      headerClassName: header,
      renderCell: (celval) => {
        return (
          <Box
            className={ColorContainer}
            sx={{ background: celval?.row?.color }}
          ></Box>
        );
      },
    },
    {
      field: "action",
      headerName: "Actions",
      width: 250,
      headerClassName: header,
      renderCell: (celval) => {
        return (
          <>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              {editPermit ? (
                <Tooltip title="Update Tags">
                  <AppRegistrationIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setselectedRow(celval.row);
                      setisEdited(true);
                      setShowForm(true);
                    }}
                  />
                </Tooltip>
              ) : null}
              {deletePermit ? (
                <Tooltip title="Delete Tags">
                  <DeleteOutlineIcon
                    color="error"
                    sx={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      // setloading(true)
                      setdeleteData(celval?.row);
                      setshowModal(true);
                      // handleDelete(celval?.row)
                    }}
                  />
                </Tooltip>
              ) : null}
            </Box>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    let arr = [];
    if (tags) {
      tags?.forEach((item, idx) => {
        return arr.push({
          id: idx + 1,
          tag: item?.tag,
          color: item?.color_code,
          tag_id: item?.tag_id,
        });
      });
    }

    setrows(arr);
    setfilterRows(arr);
  }, [tags]);

  const onTextChange = () => {
    if (document.getElementById("OrderNoFilter").value === "") {
      setfilterRows(rows);
    } else {
      let suggestions = [];

      var regex1 = new RegExp(
        document.getElementById("OrderNoFilter").value,
        "i"
      );

      suggestions = rows?.filter(
        (val) =>
          document.getElementById("OrderNoFilter").value === "" ||
          regex1.test(val.tag)
      );
      setfilterRows(suggestions);
    }
  };
  useEffect(() => {
    if (status === "failed") {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
    }
  }, [status]);
  return (
    <Grid container spacing={2} sx={{ padding: "10px" }}>
      <Grid item md={2} xs={12} xl={2} sx={{ display: "flex", gap: "5px" }}>
        {addPermit ? (
          <>
            {/* <Button
              sx={{ padding: "5px 10px" }}
              onClick={() => {
                setShowForm(true);
              }}
              variant="contained"
              className="tabletopbutton1 text-right"
            >
              New
            </Button> */}
            <CustomButton
              Color={"primary"}
              Icon={<AddIcon />}
              onClick={() => {
                setShowForm(true);
              }}
              Label={"Add"}
            />
          </>
        ) : null}
      </Grid>
      <Grid item md={8} xs={12} xl={8}></Grid>
      <Grid item md={2} xs={12} xl={2} sx={{ textAlign: "right" }}>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: (theme) => theme.palette.grey.G2 }} />
              </InputAdornment>
            ),
          }}
          variant="standard"
          type="text"
          autoComplete="off"
          placeholder="Tags"
          className="input_border"
          id="OrderNoFilter"
          onChange={onTextChange}
        />
      </Grid>

      <span className="ml-2 float-right">
        <div className="loaderclass"> </div>
      </span>

      {(tags?.length<=0 || tags===null) && status === "loading" ? (
        <>
          <Loader />
        </>
      ) : null}
      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        overflow
        sx={{ height: tabelStyle.height }}
      >
        <DataGrid
          rows={filterRows}
          columns={columns}
          onRowClick={() => {}}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          pagination
          components={{
            Toolbar: CustomToolbar,
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />
      </Grid>

      <DeleteModal
        name={deleteData?.tag}
        handelDelete={handleDelete}
        showModal={showModal}
        setshowModal={setshowModal}
        loading={loading}
      />
    </Grid>
  );
}

export default TagsTable;
TagsTable.propTypes = {
  setShowForm: PropTypes.any,
  setisEdited: PropTypes.any,
  setselectedRow: PropTypes.any,
};
