import {
  Box,
  IconButton,
  Link,
  //  Link,
  Modal,
  Tooltip,
  //   Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
// import theme from "../../theme";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const useStyles = makeStyles(() => {
  return {
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
    },
    Container: {
      width: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableContain: { height: "450px", overflowY: "scroll" },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    child_box: {
      display: "flex",
      width: "100%",
      maxWidth: "370px",
    },
    box_heading: {
      fontWeight: "bold",
      width: "100%",
      maxWidth: "110px",
    },
    compCircle: {
      width: "100%",
      maxWidth: "20px",
      height: "20px",
      borderRadius: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
});
function ChangeReqTableModal({ data, showModal, setshowModal }) {
  const { Container, ModalContainer, TableHeader,child_box, box_heading,compCircle  } = useStyles();
console.log(data);
  return (
    <>
      <Modal
        open={showModal}
        onClose={() => {
          setshowModal(false);
        }}
      >
        <Box className={ModalContainer}>
          <Box className={Container}>
            <Box className={TableHeader}>
              <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                Change Request Detail
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setshowModal(false);
                }}
              >
                <HighlightOffIcon
                  color="primary"
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                overflow: "scroll",
              }}
            >
                  <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                overflow: "scroll",
              }}
            >
              <Box className={child_box}>
                <Typography className={box_heading}>Project:</Typography>
                <Typography sx={{ paddingLeft: "30px" }}>
                  {data?.project_name}
                </Typography>
              </Box>
              <Box className={child_box}>
                <Typography className={box_heading}>Module:</Typography>
                <Typography
                  sx={{
                    paddingLeft: "30px",
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  {data?.module_name}
                </Typography>
              </Box>
              <Box className={child_box}>
                <Typography className={box_heading}>Sub Module:</Typography>
                <Typography sx={{ paddingLeft: "30px" }}>
                  {data?.submodule_name}
                </Typography>
              </Box>
              <Box className={child_box}>
                <Typography className={box_heading}>Priority:</Typography>
                <Typography sx={{ paddingLeft: "30px" }}>
                  {data?.priority}
                </Typography>
              </Box>
              <Box sx={{gap:"30px"}} className={child_box}>
                <Typography className={box_heading}>Complexity:</Typography>
                {/* <Box sx={{ paddingLeft: "30px" }}> */}
                <Tooltip sx={{ cursor: "pointer" }} title={data?.complexity}>
            <Box
              className={compCircle}
              sx={{
                backgroundColor:
                  data?.complexity === "easy"
                    ? "green"
                    : data?.complexity === "medium"
                    ? "orange"
                    : data?.complexity === "hard"
                    ? "blue"
                    : data?.complexity === "complex"
                    ? "red"
                    : null,
              }}
            ></Box>
          </Tooltip>
                {/* </Box> */}
              </Box>
              <Box className={child_box}>
                <Typography className={box_heading}>Comp Status:</Typography>
                <Typography sx={{ paddingLeft: "30px" }}>
                  {data?.company_status}
                </Typography>
              </Box>
              <Box className={child_box}>
                <Typography className={box_heading}>Client Status:</Typography>
                <Typography sx={{ paddingLeft: "30px" }}>
                  {data?.client_status}
                </Typography>
              </Box>
              <Box className={child_box}>
                <Typography className={box_heading}>Amend Status:</Typography>
                <Typography sx={{ paddingLeft: "30px" }}>
                  {data?.AmendStatus}
                </Typography>
              </Box>
            </Box>
              <Box>
                <Typography sx={{ fontWeight: "bold" }}>
                  Subject:
                </Typography>
                <Typography sx={{ paddingLeft: "80px" }}>
                  {data?.subject}
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontWeight: "bold" }}>
                  Description:
                </Typography>
                <Typography sx={{ paddingLeft: "80px" }}>
                  {data?.description}
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontWeight: "bold" }}>
                  Google Docs:
                </Typography>
                <Typography sx={{ paddingLeft: "80px" }}>
                  <Link href={data?.google_docs} target={"_blank"}>
                    {data?.google_docs}
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default ChangeReqTableModal;
ChangeReqTableModal.propTypes = {
  data: PropTypes.any,
  showModal: PropTypes.any,
  setshowModal: PropTypes.any,
};
