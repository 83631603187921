import { Box, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext, useEffect, useState } from "react";
import { LineChart } from "../LineChart/LineChart";
import { useDispatch, useSelector } from "react-redux";
import {
  GetMonthlyChangeRequestData,
  // getDashboardActivities,
} from "../../../Redux/Slice/Dashboard/DashboardSlice";
import {
  FetchMonthlyChange,
  getChangeRequests,
} from "../../../Redux/Slice/Dashboard/PapulateChangeRequestSlice";
import moment from "moment";
import { logout } from "../../../Redux/Slice/LogoutSlice/LogoutSlice";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { useNavigate } from "react-router-dom";

// import MonthlyAlloChangeReq from "../../Dashboard/MonthlyAlloChangeReqModal/MonthlyAlloChangeReq";
// import MonthlyCompletedChangeReqModal from "../../Dashboard/MonthlyCompletedChangeReqModal/MonthlyCompletedChangeReqModal";
// import MonthlyUnAlloChangeReq from "../../Dashboard/MonthlyUnalocatedChangeReqModal/MonthlyUnAlloChangeReq";

const useStyle = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",

      color: theme.palette.grey[700],
    },
    // cardContainer: {
    //   display: "flex",
    //   alignItems: "center",
    //   gap: "20px",
    //   justifyContent: "space-between",
    //   padding: "20px",
    //   [theme.breakpoints.down("md")]: {
    //     flexWrap: "wrap",
    //   },
    // },
    // Card: {
    //   transition: "0.5s",
    //   padding: "20px",
    //   "&:hover": {
    //     boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    //     cursor: "pointer",
    //   },
    // },
  };
});

const Graph = () => {
  const {
    container,
    //  cardContainer,
    //  Card
  } = useStyle();
  const navigate = useNavigate();
  const { setsnackBarData } = useContext(SnackBarContext);
  const [monthlyData, setmonthlyData] = useState([])
  // const { monthlyChangeRequestData,IsLogin,message } = useSelector(
  //   getDashboardActivities
  // );
  // const [MonthCompChangeReq, setMonthCompChangeReq] = useState(false);
  // const [MonthUnallocatedChangeReq, setMonthUnallocatedChangeReq] =
  //   useState(false);
  // const [MonthAllocatedChangeReq, setMonthAllocatedChangeReq] = useState(false);
  // useEffect(() => {
  //   if (!IsLogin) {
  //     setsnackBarData({
  //       type: "error",
  //       message: message,
  //       openToast: true,
  //     });
  //     dispatch(logout());
  //     return navigate("/");
  //   }
  // }, [IsLogin]);

  const { monthlyChanges, IsLogin, message ,monthlyChangesstatus} = useSelector(getChangeRequests);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(FetchMonthlyChange(moment().format("YYYY-MM-DD")));
    dispatch(GetMonthlyChangeRequestData());
  }, [dispatch]);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  useEffect(() => {
    console.log(monthlyChanges);
    setmonthlyData(monthlyChanges)
  }, [monthlyChanges]);

  return (
    <Box className={container}>

      {/*remove*/}
      {/* <Box className={cardContainer}>
        <Box>
          <Typography>All</Typography>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {monthlyChangeRequestData?.All}
          </Typography>
        </Box>
        <Box
          className={Card}
          onClick={(e) => {
            e.stopPropagation();
            setMonthCompChangeReq(true);
          }}
        >
          <Typography>Completed</Typography>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {monthlyChangeRequestData?.Completed}
          </Typography>
        </Box>
        <Box
          className={Card}
          onClick={(e) => {
            e.stopPropagation();
            setMonthAllocatedChangeReq(true);
          }}
        >
          <Typography>Allocated</Typography>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {monthlyChangeRequestData?.Allocated}
          </Typography>
        </Box>
        <Box
          className={Card}
          onClick={(e) => {
            e.stopPropagation();
            setMonthUnallocatedChangeReq(true);
          }}
        >
          <Typography>Unallocated</Typography>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {monthlyChangeRequestData?.Unallocated}
          </Typography>
        </Box>
      </Box> */}
      {monthlyChangesstatus !== "succeeded" ? (
        <Box
          sx={{
            height: "450px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        // <>
        //   {monthlyData !==null ? (
            <Box sx={{ width: '100%', minHeight: "200px" }}>
              <LineChart monthlyData={monthlyData} />
            </Box>
          // ) : (
            // <Box sx={{ width: '100%', minHeight: "200px" }}>
            //   <img src="./NoData.png" alt="No Data Found " style={{ height: '84%', width: '95%' }} />
            // </Box>
        //   )}
        // </>
      )}
      {/* <MonthlyAlloChangeReq
        MonthAllocatedChangeReq={MonthAllocatedChangeReq}
        setMonthAllocatedChangeReq={setMonthAllocatedChangeReq}
      />
      <MonthlyCompletedChangeReqModal
        MonthCompChangeReq={MonthCompChangeReq}
        setMonthCompChangeReq={setMonthCompChangeReq}
      />
      <MonthlyUnAlloChangeReq
        MonthUnallocatedChangeReq={MonthUnallocatedChangeReq}
        setMonthUnallocatedChangeReq={setMonthUnallocatedChangeReq}
      /> */}
    </Box>
  );
};

export default Graph;
