import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  modules: [],
 
  isSuccess: false,
  IsLogin: true,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const fetchRules = createAsyncThunk(
  "getallrules",
  async () => {
    const response = await axiosInstance.get(`getallrules`);

    return response.data;
  }
);


const RulesSlice = createSlice({
  name: "module",
  initialState,
  reducers: {
    Rules: {
      reducer(state, action) {
        state.modules.push(action.payload);
      },
    },

    resetData: (state) => {
      state.modules = [];
      state.isSuccess = false;
      state.IsLogin = true;
      state.message = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchRules.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRules.fulfilled, (state, action) => {

        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.modules = action.payload.Rules;
      })
      .addCase(fetchRules.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
    
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      
    
  },
});

export const getAllRules= (state) => state.module;

export const { Rules, resetData } = RulesSlice.actions;

export default RulesSlice.reducer;
