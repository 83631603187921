import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { useContext, useState } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect } from "react";
import { PatchChangeInReqClientApi } from "../../Api/ChangeinReqApi/PatchChangeInClientApi";

import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useDispatch } from "react-redux";
import {
  fetchChangeRequest,
  fetchsingleEmpChangeReq,

} from "../../Redux/Slice/ChangeRequest/ChangeRequestSlice";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import Cookies from "universal-cookie";
import theme from "../../theme";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
    inputBox: {
      width: "100%",
      minWidth: "350px",
      [theme.breakpoints.down("md")]: {
        minWidth: "200px",
      },
    },

    input_typography: {
      textAlign: "left",
      fontSize: `17px !important`,
      marginTop: "10px",
    },
  };
});
const cookies = new Cookies();

const AmendStatus = ({ AmendStatusFilter, setShowUpdateForm }) => {
  const user = cookies.get("user");

  const { header, inputBox, input_typography } = useStyles();
  const [open, setOpen] = useState(false);
  const [Row, setRow] = useState([]);
  const [filteramendRow, setFilterAmendRow] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [workedHours, setworkedHours] = useState(0);
  const [date, setdate] = useState("");
  const { setsnackBarData } = useContext(SnackBarContext);
  const [loader, setloader] = useState(false);

  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setdate("")
    setworkedHours(0)
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70, headerClassName: header },

    {
      field: "AmendStatus",
      headerName: "Amendment Status",
      width: 150,
      headerClassName: header,
    },
    {
      field: "Description",
      headerName: "Description",
      width: 150,
      headerClassName: header,
    },
    {
      field: "RejectionDate",
      headerName: "Rejection Date",
      width: 150,
      headerClassName: header,
    },
    {
      field: "Update-AmendStatus",
      headerName: "Update AmendStatus",
      width: 170,
      headerClassName: header,
      renderCell: (celval) => {
        return (
          <>
            {celval.row.AmendStatus === "pending" ? (
              <Button
      variant="contained"

                sx={{
                  // background: "primary",
                  color: "white !important",

                  textTransform: "lowercase",
                }}
                onClick={() => {
                  handleAmendStatus(celval);
                  handleClickOpen();
                }}
              >
                Completed
              </Button>
            ) : (
              ""
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    let arr = [];
    AmendStatusFilter?.Amendement.forEach((item, ind) => {
      return arr.push({
        id: ind + 1,
        AmendmentId: item?.amendement_id,
        AmendStatus: item?.amend_status,
        Description: item?.description,
        RejectionDate: item?.rejection_date
          ? moment(item?.rejection_date).format("DD-MMM-YYYY")
          : null,
      });
    });
    setRow(arr);
  }, [AmendStatusFilter]);

  const handleAmendStatus = (celval) => {
    setFilterAmendRow(celval.row);
  };
  const AmendStatusCompleted = async () => {
    if (workedHours < 1) {
      setsnackBarData({
        type: "error",
        message: "Please Enter Worked Hours",
        openToast: true,
      });
      return;
    }
    if (date =="") {
      setsnackBarData({
        type: "error",
        message: "Please Enter Completed Date",
        openToast: true,
      });
      return;
    }
    setloader(true);
    const response = await PatchChangeInReqClientApi({
      amend_status: "completed",
      amendement_id: filteramendRow.AmendmentId,
      workedhours: workedHours,
      completed_date: date,
    });

    setsnackBarData(response.snackBarData);
    if (response.data.IsSuccess) {
      if (user?.type === "Employee") {
        dispatch(fetchsingleEmpChangeReq(user?.Emp_id));
      } else {
        dispatch(fetchChangeRequest({ page: 0, pageSize: 10 }));
      }
      setloader(false);
      setShowUpdateForm("Table");
      handleClose();
    }
    setloader(false);
  };
  return (
    <Box>
      <Box sx={{ display: "flex", padding: "15px 10px 6px 10px" }}>
        <Button
          sx={{
            width: "50px",
            backgroundColor: "#53b7c5",
            color: "white !important",
          }}
          onClick={() => setShowUpdateForm("Table")}
        >
          <ArrowBackIcon fontSize="medium" />
        </Button>
      </Box>
      <Box
        sx={{ height: "calc(100vh - 234px)", width: "100%", padding: "10px" }}
      >
        <DataGrid
          rows={Row}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          pagination
        />
      </Box>
      <Box>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Amendment Status Update"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you Sure you want to change Amendment Status completed
            </DialogContentText>
            <Box className={inputBox}>
              <Typography className={input_typography}>Date</Typography>
              <TextField
                sx={{ textAlign: "left" }}
                fullWidth
                size="small"
                type="date"
                value={date}
                onChange={(e) => setdate(e.target.value)}
                variant="outlined"
              />
            </Box>
            <Box className={inputBox}>
              <Typography className={input_typography}>Worked Hours</Typography>
              <TextField
                sx={{ textAlign: "left" }}
                fullWidth
                size="small"
                type="number"
                value={workedHours}
                onChange={(e) => setworkedHours(e.target.value)}
                variant="outlined"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Button
                sx={{
                  textTransform: "none",
                  backgroundColor: "red",
                  color: "white !important",
                }}
                onClick={handleClose}
              >
                No
              </Button>
              {loader ? (
                <CircularProgress />
              ) : (
                <Button
                  sx={{ textTransform: "none" }}
                  variant="contained"
                  onClick={AmendStatusCompleted}
                  autoFocus
                >
                  Yes
                </Button>
              )}
            </Box>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default AmendStatus;
AmendStatus.propTypes = {
  AmendStatusFilter: PropTypes.any,
  setShowUpdateForm: PropTypes.any,
};
