import {
  Box,
  Button,
  Typography,
  TextField,
  MenuItem,
  Grid,
  FormControl,
  Autocomplete,
} from "@mui/material";
window.Buffer = window.Buffer || require("buffer").Buffer;
import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getemployeeDetails,
  FetchSingleEmployeeForPerformance,
} from "../../Redux/Slice/Employee/EmplyeeSlice";
import theme from "../../theme";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { PostEmployeeAllowance } from "../../Api/Employee/EmployeeAllowance";
import {
  fetchAllAllowances,
  getallallowances,
} from "../../Redux/Slice/AllAllowances/AllAllowance";
import moment from "moment";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import {
  fetchMonthlyReportPopulate,
  getEmpmonthlyreportDetail,
} from "../../Redux/Slice/EmployeMonthlyReport/EmployeMonthlyReportSlice";
const useStyles = makeStyles(() => {
  return {
    input_typography: {
      textAlign: "left",
      fontWeight: "bold",
    },

    btns: {
      width: "80px",
    },
    DetailContainer: {
      gap: "10px",

      padding: "20px",
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    },
    FieldInRow: {
      display: "flex",
      justifyContent: "space-between",
      gap: "20px",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },
    inputBox: {
      width: "100%",
    },
  };
});
const Typo = { width: { md: "150px", xs: "150px" }, fontWeight: "bold" };

const EmployeeAllowanceForm = ({ setShowForm }) => {
  const { input_typography, inputBox, DetailContainer, FieldInRow } =
    useStyles();
  const { setsnackBarData } = useContext(SnackBarContext);
  const [AllEmployees, setAllEmployees] = useState(null);
  const [singleChosenEmployee, setsingleChosenEmployee] = useState(null);
  const [empID, setempID] = useState(null);
  const [loading, setloading] = useState(false);
  const { singleEmployee, IsLogin, message } = useSelector(getemployeeDetails);
  const { Report_Populate } = useSelector(getEmpmonthlyreportDetail);
  const { allowances } = useSelector(getallallowances);
  const [showCancel, setshowCancel] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();


  useEffect(() => {
    setempID(null);
    dispatch(fetchMonthlyReportPopulate());
    dispatch(fetchAllAllowances());
  }, []);

  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
    setAllEmployees(Report_Populate);
  }, [Report_Populate, IsLogin]);

  function handleChange(e) {
    setsingleChosenEmployee({
      ...singleChosenEmployee,
      [e.target.name]: e.target.value,
    });
  }

  useEffect(() => {
    if (singleEmployee) {
      setsingleChosenEmployee({
        ...singleEmployee?.Employee,

        type: "Allowance",
        date: moment().format("YYYY-MM-DD"),
        amount: "",
        allowance: "",
        description: "",
      });
    }
  }, [singleEmployee]);

  function handleGET(e) {
    setshowCancel(false);
    e.stopPropagation();
    if (!empID) {
      return setsnackBarData({
        type: "error",
        message: "Please Select Employee",
        openToast: true,
      });
    }
    dispatch(FetchSingleEmployeeForPerformance(empID));
  }

  async function handleSave() {
    setloading(true);
    if (
      !empID ||
      singleChosenEmployee.date == "" ||
      singleChosenEmployee.amount == "" ||
      singleChosenEmployee.type == "" ||
      singleChosenEmployee.allowance == "" ||
      singleChosenEmployee.description == ""
    ) {
      setloading(false);
      return setsnackBarData({
        type: "error",
        message: "Please Provide All Necessary Data ",
        openToast: true,
      });
    }

    let obj = {
      emp_id: empID,
      date: singleChosenEmployee.date,
      amount: singleChosenEmployee.amount,
      type: singleChosenEmployee.type,
      allowance: singleChosenEmployee.allowance.toString(),
      description: singleChosenEmployee.description,
    };

    const response = await PostEmployeeAllowance(obj);
    setsnackBarData(response.snackBarData);
    if (response.data.IsSuccess) {
      setloading(false);

      setShowForm(false);
    }
    setloading(false);
  }
console.log(AllEmployees);
  return (
    <>
      <Box
        sx={{
          minHeight: "calc(100vh - 173px)",
          width: "100%",
          padding: "40px 20px",
          display: "flex",
          justifyContent: " center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "900px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              gap: "10px",
            }}
          >
            <Box sx={{ flex: 3, maxWidth: "400px" }}>
              {/* <TextField
                size="small"
                variant="outlined"
                type="text"
                placeholder="Select Employee.."
                color="primary"
                sx={{
                  input: { color: "black" },
                  textAlign: "left",
                }}
                fullWidth
                select
                onChange={(e) => {
                  e.stopPropagation();
                  setsingleChosenEmployee(null);
                  setempID(e.target.value);
                }}
              >
                {AllEmployees &&
                  AllEmployees.map((data, index) => {
                    return (
                      <MenuItem key={index} value={data?.id}>
                        {data?.name}
                      </MenuItem>
                    );
                  })}
              </TextField> */}
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                name="emp_id"
                options={AllEmployees}
                getOptionLabel={(option) => option.name}
                fullWidth
                // value={values.emp_id}
                size="small"
                onChange={(e, val) => {
                  console.log(e, val);
                  setsingleChosenEmployee(null);
                  setempID(val?.id);
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Employee.." />
                )}
              />
            </Box>

            <Box sx={{ flex: 1 }}>
              <Box sx={{ margin: "0px auto", display: "flex", gap: "10px" }}>
                <Button
                  variant="contained"
                  sx={{ width: "80px" }}
                  onClick={handleGET}
                >
                  GET
                </Button>
                {/* {empID ? (
                  ""
                ) : ( */}
                {showCancel && (
                  <Button
                    sx={{ width: "80px" }}
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setShowForm(false);
                    }}
                  >
                    Cancel
                  </Button>
                )}

                {/* )} */}
              </Box>
            </Box>
          </Box>

          {singleChosenEmployee && empID ? (
            <Grid container className={DetailContainer}>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Typography className={input_typography} sx={Typo}>
                    Father Name:
                  </Typography>
                  <Typography>{singleChosenEmployee?.father_name}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Typography className={input_typography} sx={Typo}>
                    Status:
                  </Typography>
                  <Typography>
                    {singleChosenEmployee?.status ? (
                      <Typography>Active</Typography>
                    ) : (
                      <Typography>InActive</Typography>
                    )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Typography className={input_typography} sx={Typo}>
                    Designation:{" "}
                  </Typography>
                  <Typography>
                    {singleChosenEmployee?.designation?.designation_name}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Typography className={input_typography} sx={Typo}>
                    Job Title:
                  </Typography>
                  <Typography>
                    {singleChosenEmployee?.jobtitle?.job_title}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{ display: "flex", justifyContent: "start" }}
              >
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Typography className={input_typography} sx={Typo}>
                    Department:
                  </Typography>
                  <Typography>
                    {singleChosenEmployee?.department?.department_name}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          ) : null}
          {singleChosenEmployee && empID ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: "10px",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Box className={FieldInRow}>
                <Box className={inputBox}>
                  <Typography sx={{ textAlign: "left" }}>Allowance</Typography>
                  <FormControl fullWidth sx={{ textAlign: "left" }}>
                    <TextField
                      onChange={handleChange}
                      size="small"
                      name="allowance"
                      variant="outlined"
                      type="text"
                      color="primary"
                      sx={{ input: { color: "black" } }}
                      fullWidth
                      select
                    >
                      {allowances?.map((allowance, index) => {
                        return (
                          <MenuItem key={index} value={allowance?.allowance_id}>
                            {allowance.allowance_type}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </FormControl>
                </Box>

                <Box className={inputBox}>
                  <Typography sx={{ textAlign: "left" }}>Salary</Typography>
                  <FormControl fullWidth sx={{ textAlign: "left" }}>
                    <TextField
                      size="small"
                      variant="outlined"
                      value={singleChosenEmployee?.basic_salary}
                      type="text"
                      color="primary"
                      sx={{ input: { color: "black" } }}
                      fullWidth
                      disabled
                    />
                  </FormControl>
                </Box>
              </Box>

              <Box className={FieldInRow}>
                <Box className={inputBox}>
                  <Typography sx={{ textAlign: "left" }}>Amount</Typography>
                  <FormControl fullWidth sx={{ textAlign: "left" }}>
                    <TextField
                      onChange={handleChange}
                      name="amount"
                      size="small"
                      variant="outlined"
                      value={singleChosenEmployee?.amount}
                      type="number"
                      color="primary"
                      sx={{ input: { color: "black" } }}
                      fullWidth
                    />
                  </FormControl>
                </Box>

                <Box className={inputBox}>
                  <Typography sx={{ textAlign: "left" }}>Date</Typography>
                  <FormControl fullWidth sx={{ textAlign: "left" }}>
                    <TextField
                      onChange={handleChange}
                      name="date"
                      size="small"
                      variant="outlined"
                      type="date"
                      value={singleChosenEmployee?.date}
                      color="primary"
                      sx={{ input: { color: "black" } }}
                      fullWidth
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box className={FieldInRow}>
                <Box className={inputBox}>
                  <Typography sx={{ textAlign: "left" }}>
                    Description
                  </Typography>
                  <FormControl fullWidth sx={{ textAlign: "left" }}>
                    <TextField
                      onChange={handleChange}
                      size="small"
                      name="description"
                      variant="outlined"
                      type="text"
                      color="primary"
                      multiline
                      value={singleChosenEmployee?.description}
                      minRows={5}
                      sx={{ input: { color: "black" } }}
                      fullWidth
                    />
                  </FormControl>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  gap: "20px",
                  margin: "20px 0px",
                  width: "100%",
                }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    setsingleChosenEmployee(null);
                    setShowForm(false);
                  }}
                >
                  Cancel
                </Button>
                {loading == false ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    sx={{ width: "80px" }}
                  >
                    Save
                  </Button>
                ) : (
                  <CircularProgress />
                )}
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
    </>
  );
};

export default EmployeeAllowanceForm;

EmployeeAllowanceForm.propTypes = {
  setShowForm: PropTypes.any,
  empData: PropTypes.any,
  setisEdited: PropTypes.any,
  isEdited: PropTypes.any,
};
