// import { Alert, Snackbar } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import LeaveApplicationForm from "../../Components/Leave Application/LeaveApplicationForm";
import LeaveApplicationTable from "../../Components/Leave Application/LeaveApplicationTable";
import { Navigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useEffect } from "react";
import Cookies from "universal-cookie";
import EmpLeaveApplicationTable from "../../Components/Leave Application/EmpLeaveApplicationTable";
const cookies = new Cookies();

const LeaveApplication = () => {
  const [ShowForm, setShowForm] = useState(false);
  const [message, setmessage] = useState("");
  const [openToast, setopenToast] = useState(false);
  const [AlertType, setAlertType] = useState("success");
  const [empData, setempData] = useState(null);
  const [isEdited, setisEdited] = useState(false);
  const [canView, setcanView] = useState(null);
  const user = cookies.get("user");
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  useEffect(() => {
    let string = "LeaveApplications";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    let match = value?.some((val) => {
      if (val?.module?.split("_")[1] === "View") {
        return true;
      }
    });
    setcanView(match);
    console.log(match);
    if (!roles || roles.length < 1) {
      setcanView(false);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);

  console.log(empData);
  return (
    <div>
      {canView === true ? (
        ShowForm ? (
          <LeaveApplicationForm
            setShowForm={setShowForm}
            setopenToast={setopenToast}
            setmessage={setmessage}
            setAlertType={setAlertType}
            empData={empData}
            isEdited={isEdited}
            setisEdited={setisEdited}
          />
        ) : user?.type == "Admin" ? (
          <LeaveApplicationTable
            setopenToast={setopenToast}
            setmessage={setmessage}
            setAlertType={setAlertType}
            setShowForm={setShowForm}
            setempData={setempData}
            isEdited={isEdited}
            setisEdited={setisEdited}
          />
        ) : (
          <EmpLeaveApplicationTable
            setopenToast={setopenToast}
            setmessage={setmessage}
            setAlertType={setAlertType}
            setShowForm={setShowForm}
            setempData={setempData}
            isEdited={isEdited}
            setisEdited={setisEdited}
          />
        )
      ) : canView === false ? (
        <Navigate to={"/dashboard"} />
      ) : (
        <Box>Loading ...</Box>
      )}
      <Snackbar
        openToast={openToast}
        setopenToast={setopenToast}
        AlertType={AlertType}
        message={message}
      />
    </div>
  );
};

export default LeaveApplication;
LeaveApplication.propTypes = {
  setShowForm: PropTypes.any,
  setisEdited: PropTypes.any,
  isEdited: PropTypes.any,
};
