import {
  Box,
  Button,
  FormControl,
  Typography,
  TextField,
  Paper,
  Avatar,
  CircularProgress,
} from "@mui/material";
window.Buffer = window.Buffer || require("buffer").Buffer;
import React, { useState } from "react";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { makeStyles } from "@mui/styles";
import { Field, Formik } from "formik";
import { object, string } from "yup";
import theme from "../../theme";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useRef } from "react";
import Cookies from "universal-cookie";
import { useEffect } from "react";
import { UpdateProfileApi } from "../../Api/UpdateProfile/UpdateProfileApi";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { ProfileContext } from "../../Context/ProfileContext/ProfileContext";

const useStyles = makeStyles(() => {
  return {
    container: {
      minHeight: "calc(100vh - 165px)",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      padding: "10px",
    },
    form_Container: {
      maxWidth: "600px",
      display: "flex",
      gap: "20px",
      justifyContent: "space-between",
      flexWrap: "wrap",
      [theme.breakpoints.down("md")]: {
        gap: "10px",
      },
    },
    imgcontainer: {
      width: "120px",
      height: "120px",
      borderRadius: "50%",
      padding: "10px",
    },
    inputBox: {
      width: "100%",
    },
    input_typography: {
      textAlign: "left",
      fontSize: `15px !important`,
      marginTop: "10px !important",
      color: "black",
    },
    Form_input: {
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,
      width: "100%",
    },
    btns: {
      width: "80px",
    },
    FieldInRow: {
      display: "flex",
      justifyContent: "space-between",
      gap: "20px",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },
    profileImageContainer: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        alignItems: "start",
      },
    },
  };
});
const Profile = () => {
  const {
    Form_input,
    input_typography,
    btns,
    inputBox,
    FieldInRow,
    container,
    form_Container,
    imgcontainer,
    profileImageContainer,
  } = useStyles();
  const cookies = new Cookies();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { profileData, setprofileData } = useContext(ProfileContext);

  // const {profileData} =useContext(ProfileContext)

  const [img, setimg] = useState(null);
  const inputRef = useRef();
  // const [employeedata] = useState({
  //   name: profileData?.username || "",
  //   email: profileData?.email || "",
  // });

  const [employeedata, setEmployeedata] = useState({
    name: profileData?.username || "",
    email: profileData?.email || "",
  });

  useEffect(() => {
    if (profileData) {
      setimg(
        profileData?.profile_image
          ? {
            src: profileData?.profile_image,
            file: profileData?.profile_image,
          }
          : null
      );
      setEmployeedata({
        name: profileData?.username || "",
        email: profileData?.email || "",
      });
      // employeedata.name = profileData?.username;
      // employeedata.email = profileData?.email;
    }
  }, [profileData]);
  const handelAdd = async (values, formikHelpers) => {
    const formData = new FormData();

    formData.append(
      "user_id",
      profileData?.user_id ? profileData?.user_id : null
    );
    formData.append("username", values?.name);
    formData.append("profile_image", img?.file ? img?.file : "");

    const response = await UpdateProfileApi(formData);

    setsnackBarData(response.snackBarData);

    if (response.data.IsSuccess) {
      cookies.set("user", response.data.Data, {
        path: "/",
      });
      setprofileData({
        user_id: response?.data?.Data?.user_id,
        profile_image: response?.data?.Data?.profile_image,
        email: response?.data?.Data?.email,
        username: response?.data?.Data?.username,

        type: response?.data?.Data?.type,
      });

      formikHelpers.setSubmitting(false);
    }
    formikHelpers.setSubmitting(false);
  };
  const handleImageChange = (e) => {
    const obj = {
      src: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    };
    setimg(obj);
  };
  const handleRemoveImage = (e) => {
    e.stopPropagation();
    setimg(null);
  };

  return (
    <Box className={container}>
      <Formik
        initialValues={employeedata}
        validationSchema={object({
          name: string().required("This field is required"),
        })}
        onSubmit={(values, formikHelpers) => {
          handelAdd(values, formikHelpers);
        }}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Box>
                <Box className={form_Container}>
                  <Box className={profileImageContainer}>
                    <Paper elevation={3} className={imgcontainer}>
                      {!img ? (
                        <Avatar
                          src="/broken-image.jpg"
                          sx={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                          }}
                        />
                      ) : (
                        <img
                          src={img?.src}
                          alt="img"
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                          }}
                        />
                      )}
                    </Paper>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => {
                          inputRef.current.click();
                        }}
                      >
                        <FileUploadIcon /> Upload
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid red",
                        }}
                        onClick={handleRemoveImage}
                      >
                        <DoDisturbIcon /> Remove
                      </Button>
                    </Box>
                  </Box>
                  <Box className={FieldInRow}>
                    <Box className={inputBox}>
                      <Typography className={input_typography}>
                        User Name
                      </Typography>

                      <FormControl fullWidth sx={{ textAlign: "left" }}>
                        <Field
                          size="small"
                          name="name"
                          as={TextField}
                          value={values?.name}
                          onChange={handleChange}
                          variant="outlined"
                          type="text"
                          color="primary"
                          sx={{ input: { color: "black" } }}
                          className={Form_input}
                          fullWidth
                          error={Boolean(errors.name) && Boolean(touched.name)}
                          helperText={Boolean(touched.name) && errors.name}
                        ></Field>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box className={FieldInRow}>
                    <Box className={inputBox}>
                      <Typography className={input_typography}>
                        Email
                      </Typography>
                      <FormControl fullWidth sx={{ textAlign: "left" }}>
                        <Field
                          value={employeedata?.email}
                          disabled
                          size="small"
                          name="email"
                          as={TextField}
                          variant="outlined"
                          type="text"
                          color="primary"
                          sx={{ input: { color: "black" } }}
                          className={Form_input}
                          fullWidth
                          error={
                            Boolean(errors.email) && Boolean(touched.email)
                          }
                          helperText={Boolean(touched.email) && errors.email}
                        ></Field>
                      </FormControl>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <Box
                    sx={{
                      float: "right",
                      display: "flex",
                      gap: "10px",
                      marginTop: "20px",
                    }}
                    spacing={2}
                  >
                    <>
                      {isSubmitting ? (
                        <CircularProgress />
                      ) : (
                        <Button
                          className={btns}
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Update
                        </Button>
                      )}
                    </>
                  </Box>
                </Box>
              </Box>
              <input
                type={"file"}
                onChange={handleImageChange}
                ref={inputRef}
                accept="image/*"
                style={{ display: "none" }}
              />
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default Profile;

Profile.propTypes = {
  // setShowForm: PropTypes.any,
  // empID: PropTypes.any,
  // empData: PropTypes.any,
  // setisEdited: PropTypes.any,
  // isEdited: PropTypes.any,
  // setstepper: PropTypes.any,
};
