import {
  Box,
  Button,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  CircularProgress,
  Grid,
  Typography,
  MenuItem,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import theme from "../../theme";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { makeStyles } from "@mui/styles";
import ProjectDynamicTable from "./ProjectDynamicTable";
import { useState } from "react";
import {
  ProjectAdded,
  fetchModulePapulate,
  getProjectDetails,
} from "../../Redux/Slice/Project/ProjectSlice";
import { AddProjectApi } from "../../Api/Project/ProjectAPis";
import { useDispatch, useSelector } from "react-redux";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useEffect } from "react";
import { UpdateProjectApi } from "../../Api/UpdateProject/UpdateProjectApi";
const useStyles = makeStyles(() => {
  return {
    main: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      padding: "60px 8%",
      height: "calc(100vh - 170px)",
    },
    responsive: {
      maxWidth: "600px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },
    input_typography: {
      textAlign: "left",
      // fontWeight: "bold",
      fontSize: `15px !important`,
      // opacity: "50%",
      marginTop: "10px !important",
      paddingRight: "20px !important",
    },
    table_cell: {
      textAlign: "left",
      // fontWeight: "bold",
      fontSize: `15px !important`,
      // opacity: "50%",
      marginTop: "10px !important",
    },
    Form_input: {
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,
    },
    btns: {
      width: "80px",
      cursor: "pointer",

      // backgroundColor: (theme) => theme.palette.primary.main,
      // color: (theme) => theme.palette.white.main,
      // "&:hover": {
      //   backgroundColor: (theme) => theme.palette.white.main,
      //   color: (theme) => theme.palette.primary.main,
      //   border: (theme) => `1px solid ${theme.palette.primary.main}`,
    },
    icon: {
      cursor: "pointer",
    },
  };
});

const ProjectForm = ({ setShowForm, projData, setisEdited, isEdited }) => {
  const {
    main,
    responsive,
    input_typography,
    table_cell,
    Form_input,
    btns,
    icon,
  } = useStyles();
  const [showmodule, setshowmodule] = useState(false);
  const [ProjectName, setProjectName] = useState("");
  const [description, setdescription] = useState("");
  const [identifier, setidentifier] = useState("");
  const [SelectRow, setSelectRow] = useState(null);
  const [tablerow, settablerow] = useState([]);
  const [loading, setloading] = useState(false);
  const [SelectedIndex, setSelectedIndex] = useState(null);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [DeleteModules, setDeleteModules] = useState([]);
  const [DeleteSubModules, setDeleteSubModules] = useState([]);
  const [modulesid, setmodulesid] = useState({
    submodtypeid: "",
    modtypeid: "",
  });
  const [focused, setfocused] = useState(false);
  const { modulespapulate } = useSelector(getProjectDetails);
  const [projectTypes, setprojectTypes] = useState(null);
  const dispatch = useDispatch();
console.log(projData)
  useEffect(() => {
    dispatch(fetchModulePapulate());
  }, [dispatch]);
console.log(modulespapulate);
  useEffect(() => {
    console.log(projectTypes);
    setprojectTypes(modulespapulate);
  }, [modulespapulate]);

  useEffect(() => {
    let arr = [];
    if (isEdited) {
      setmodulesid({
        submodtypeid: projData?.submodtypeid,
        modtypeid: projData?.modtypeid,
      });
      projData?.module?.map((mod) => {
        let temp = [];
        mod?.submodule?.map((m) => {
          let obj = { submodule_name: m?.submodule_name };
          temp.push(obj);
        });

        return arr.push({ module_name: mod?.module_name, submodules: temp });
      });
    }
  }, [projData]);
  useEffect(() => {
    if (isEdited) {
      setProjectName(projData?.project_name);
      settablerow(projData?.module);
    }
  }, [projData]);
  const handleAdd = () => {
    let obj = {
      module_name: "",
      submodules: [],
    };
    settablerow([...tablerow, obj]);
  };

  const handleDelete = (ind) => {
    // const originalArray = [1, 2, 3];
    const data = [...tablerow];
    let abc = data[ind];
    setDeleteModules([...DeleteModules, abc?.module_id]);
    data.splice(ind, 1);
    // data.splice(ind, 1);
    settablerow(data);
  };
  const handleOpen = (val, idx) => {
    setshowmodule(true);
    setSelectRow(val);
    setSelectedIndex(idx);
  };
  const handleSave = async () => {
    if (ProjectName === "") {
      setsnackBarData({
        type: "error",
        message: "Please Enter Project Name",
        openToast: true,
      });
      return;
    }

    if (tablerow.length === 0) {
      setsnackBarData({
        type: "error",
        message: "Please Enter At least one module",
        openToast: true,
      });
      return;
    }
    let retu = false;
    if (tablerow?.length > 0) {
      tablerow.forEach((row, idx) => {
        if (retu) return;

        if (row?.module_name === "") {
          setsnackBarData({
            type: "error",
            message: `Please enter Module Name at row ${idx + 1} `,
            openToast: true,
          });
          retu = true;
        }
      });
    }
    if (retu) return;
    if (modulesid?.submodtypeid?.length < 0 || modulesid?.modtypeid?.length < 0) {
      return setsnackBarData({
        type: "error",
        message: `Please select Module/Submodule type`,
        openToast: true,
      });
    }
   
    let data = {
      project_name: ProjectName,
      identifier: identifier,
      description: description,
      modules: tablerow,
      ...modulesid,
    };
    // let data2 = {
    //   project_name: ProjectName,
    //   modules: tablerow,
    // };

    if (!isEdited) {
      if (description === "") {
        setsnackBarData({
          type: "error",
          message: "Please Enter description",
          openToast: true,
        });
        return;
      }
      if (identifier === "") {
        setsnackBarData({
          type: "error",
          message: "Please Enter identifier",
          openToast: true,
        });
        return;
      }
      setloading(true);
      const response = await AddProjectApi(data);
      setsnackBarData(response.snackBarData);

      if (response.data.IsSuccess) {
        setShowForm(false);
        dispatch(ProjectAdded(response.data.Project));
        setProjectName("");
        settablerow([]);
        setSelectRow(null);
        setloading(false);
      }
      setloading(false);
    } else if (isEdited) {
      let data2 = {
        ...modulesid,
        project_id: projData?.id,
        modules: tablerow,
        DeleteModules: DeleteModules?.length > 0 ? DeleteModules : null,
        DeleteSubModules:
          DeleteSubModules?.length > 0 ? DeleteSubModules : null,
      };

      setloading(true);
      const response = await UpdateProjectApi(data2);
      setsnackBarData(response.snackBarData);

      if (response.data.IsSuccess) {
        setShowForm(false);
        dispatch(ProjectAdded(response.data.Project));
        setProjectName("");
        settablerow([]);
        setSelectRow(null);
        setloading(false);
        setisEdited(false);
      }
      setloading(false);
    }
  };

  return (
    <Box className={main}>
      <Box className={responsive}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {/* <Grid item xs={3} sm={3} md={3} lg={3}> */}
            <Typography className={input_typography}>Project Name</Typography>
            {/* </Grid> */}
            <FormControl fullWidth sx={{ textAlign: "left" }}>
              <TextField
                size="small"
                value={ProjectName}
                onChange={(e) => {
                  setProjectName(e.target.value);
                }}
                className={Form_input}
              />
            </FormControl>
          </Grid>
        </Grid>

        {isEdited ? null : (
          <>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {/* <Grid item xs={3} sm={3} md={3} lg={3}> */}
                <Typography className={input_typography}>
                  Description
                </Typography>
                {/* </Grid> */}
                <FormControl fullWidth sx={{ textAlign: "left" }}>
                  <TextField
                    size="small"
                    value={description}
                    onChange={(e) => {
                      setdescription(e.target.value);
                    }}
                    className={Form_input}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {/* <Grid item xs={3} sm={3} md={3} lg={3}> */}
                <Typography className={input_typography}>Identifier</Typography>
                {/* </Grid> */}
                <FormControl fullWidth sx={{ textAlign: "left" }}>
                  <TextField
                    size="small"
                    value={identifier}
                    onFocus={() => setfocused(true)}
                    onBlur={() => setfocused(false)}
                    onChange={(e) => {
                      setidentifier(
                        e.target.value.toLowerCase().replaceAll(" ", "")
                      );
                    }}
                    className={Form_input}
                  />
                  {focused ? (
                    <Typography
                      sx={{
                        color: (theme) => theme.palette.grey[500],
                        fontSize: "12px",
                      }}
                    >
                      Identifier should be unique, without spaces and no
                      upper-case letters
                    </Typography>
                  ) : null}
                </FormControl>
              </Grid>
            </Grid>
          </>
        )}
        <Grid
          container
          sx={{
            gap: "10px",
            justifyContent: "space-between",
            textAlign: "left",
          }}
        >
          <Grid items xs={12} md={5.8}>
            <FormControl fullWidth>
              <Typography>Module Type</Typography>
              <TextField
                select
                size={"small"}
                fullWidth
                value={modulesid?.modtypeid}
                disabled={isEdited}
                name={"modtypeid"}
                onChange={(e) => {
                  setmodulesid({
                    ...modulesid,
                    [e.target.name]: e.target.value,
                  });
                }}
              >
                {projectTypes?.map((data, i) => {
                  return (
                    <MenuItem key={i} value={data?.id}>
                      {data?.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </FormControl>
          </Grid>
          <Grid items xs={12} md={5.8}>
            <FormControl fullWidth>
              <Typography>Submodule Type</Typography>
              <TextField
                select
                size={"small"}
                fullWidth
                value={modulesid?.submodtypeid}
                disabled={isEdited}
                name={"submodtypeid"}
                onChange={(e) => {
                  setmodulesid({
                    ...modulesid,
                    [e.target.name]: e.target.value,
                  });
                }}
              >
                {projectTypes?.map((data, i) => {
                  return (
                    <MenuItem key={i} value={data?.id}>
                      {data?.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </FormControl>
          </Grid>
        </Grid>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={table_cell}>Sr. No</TableCell>
                <TableCell className={table_cell}>Module Name</TableCell>
                <TableCell>
                  <AddCircleIcon
                    className={icon}
                    color="primary"
                    onClick={handleAdd}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tablerow.length > 0 &&
                tablerow.map((val, idx) => {
                  return (
                    <TableRow key={idx}>
                      <TableCell>{1 + idx}</TableCell>
                      <TableCell>
                        <FormControl fullWidth>
                          <TextField
                            key={`module_name${idx}`}
                            size="small"
                            type="text"
                            id="outlined-basic"
                            onChange={(e) => {
                              let newArr = JSON.parse(JSON.stringify(tablerow));
                              newArr[idx].module_name = e.target.value;
                              settablerow(newArr);
                            }}
                            value={val.module_name}
                            variant="outlined"
                          />
                        </FormControl>
                      </TableCell>
                      <TableCell sx={{ textAlign: "right" }}>
                        <Box sx={{ display: "flex", gap: "4px" }}>
                          <AppRegistrationIcon
                            className={icon}
                            variant="contained"
                            onClick={() => {
                              handleOpen(val, idx);
                            }}
                          />
                          <DeleteForeverIcon
                            className={icon}
                            color="error"
                            variant="contained"
                            onClick={() => {
                              handleDelete(idx);
                            }}
                          />
                          {showmodule === true && (
                            <ProjectDynamicTable
                              showmodule={showmodule}
                              setshowmodule={setshowmodule}
                              SelectRow={SelectRow}
                              setSelectRow={setSelectRow}
                              SelectedIndex={SelectedIndex}
                              setSelectedIndex={setSelectedIndex}
                              tablerow={tablerow}
                              settablerow={settablerow}
                              projData={projData}
                              isEdited={isEdited}
                              DeleteSubModules={DeleteSubModules}
                              setDeleteSubModules={setDeleteSubModules}
                            />
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Box>

        <Box
          sx={{
            justifyContent: "flex-end",
            display: "flex",
            gap: "10px",
            marginTop: "20px",
          }}
          spacing={2}
        >
          <Button
            className={btns}
            variant="contained"
            color="error"
            onClick={() => {
              settablerow("");
              setShowForm(false);
              setisEdited(false);
            }}
          >
            Cancel
          </Button>
          {loading ? (
            <CircularProgress />
          ) : isEdited ? (
            <Button
              className={btns}
              variant="contained"
              color="primary"
              onClick={() => {
                handleSave();
              }}
            >
              Update
            </Button>
          ) : (
            <Button
              className={btns}
              variant="contained"
              color="primary"
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectForm;
ProjectForm.propTypes = {
  setShowForm: PropTypes.any,
  projData: PropTypes.any,
  setisEdited: PropTypes.any,
  isEdited: PropTypes.any,
};
