import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  singleEmployeeDetails: {},
  singleEmployeeAttendance: [],

  prevdaystaskdata: [],
  weektaskdata: [],
  Events: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'

  error: null,
  IsLogin: true,
};

export const FetchEvents = createAsyncThunk("eventdata", async () => {
  const response = await axiosInstance.get(`eventdata`);

  return response.data;
});

export const GetSingleEmployeeDetails = createAsyncThunk(
  "GetSingleEmployeeDetails",
  async (data) => {
    const response = await axiosInstance.get(`singlempdetail/${data}`);

    return response.data;
  }
);

export const GetSingleEmployeeAttendance = createAsyncThunk(
  "GetSingleEmployeeAttendance",
  async (data) => {
    console.log(data);
    const response = await axiosInstance.get(
      `singlempattendance?id=${data.userid}&month=${data.date}`
    );

    return response.data;
  }
);

export const GetPrevDaysTaskDetails = createAsyncThunk(
  "previousdaysesthrs",
  async (data) => {
    const response = await axiosInstance.get(`previousdaysesthrs/${data}`);
    console.log(response.data);
    return response.data;
  }
);
export const GetWeekTaskDetails = createAsyncThunk(
  "previousweektasks",
  async (data) => {
    const response = await axiosInstance.get(`previousweektasks/${data}`);

    return response.data;
  }
);

const EmployeeDashboardSlice = createSlice({
  name: "employeedashboardactivity",
  initialState,
  reducers: {
    DashboardResetData: (state) => {
      state.prevdaystaskdata = [];
      state.weektaskdata = [];

      state.singleEmployeeDetails = {};
      state.singleEmployeeAttendance = [];

      state.Events = [];
      state.isSuccess = false;
      state.message = null;
      state.status = "idle"; //'idle' | 'loading' | 'succeeded' | 'failed'

      state.error = null;
      state.IsLogin = true;
    },
  },
  extraReducers(builder) {
    builder

      .addCase(FetchEvents.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchEvents.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Events = action.payload?.Gazetted_Holidays;
      })
      .addCase(FetchEvents.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })

      .addCase(GetSingleEmployeeDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(GetSingleEmployeeDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.singleEmployeeDetails = action.payload;
      })
      .addCase(GetSingleEmployeeDetails.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(GetSingleEmployeeAttendance.pending, (state) => {
        state.attendanceStatus = "loading";
      })
      .addCase(GetSingleEmployeeAttendance.fulfilled, (state, action) => {
        state.attendanceStatus = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.singleEmployeeAttendance = action.payload?.Attendance;
      })
      .addCase(GetSingleEmployeeAttendance.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(GetPrevDaysTaskDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(GetPrevDaysTaskDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.prevdaystaskdata = action.payload?.Portal;
      })
      .addCase(GetPrevDaysTaskDetails.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(GetWeekTaskDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(GetWeekTaskDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.weektaskdata = action.payload?.Portal_tasks;
      })
      .addCase(GetWeekTaskDetails.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
  },
});

export const getEmployeeDashboardActivities = (state) =>
  state.employeedashboardactivity;
export const { DashboardResetData } = EmployeeDashboardSlice.actions;

export default EmployeeDashboardSlice.reducer;
