import PropTypes from "prop-types";
import {
  Box,
  // Button,
  CircularProgress,
  gridClasses,
  InputAdornment,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchIncrementDecrementHistory,
  getHistory,
} from "../../Redux/Slice/PerformanceManagement/IncrementDecrementSlice";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { useRef } from "react";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../Button/AddNewButton";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});

function EmployeeIncrementDecrementTable({ setShowForm }) {
  const { header } = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [AllEmployees, setAllEmployees] = useState([]);
  const { setsnackBarData } = useContext(SnackBarContext);
  const { incrementdecrementHistory, status, IsLogin, message } =
    useSelector(getHistory);

  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [addpermit, setaddpermit] = useState(false);
  // console.log(crop);

  console.log(roles);
  useEffect(() => {
    let string = "EmployeeIncrementsDecrements";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Add") {
        setaddpermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      setaddpermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  console.log(addpermit);

  useEffect(() => {
    dispatch(FetchIncrementDecrementHistory());
  }, [dispatch]);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
    setAllEmployees(incrementdecrementHistory);
  }, [incrementdecrementHistory, IsLogin]);

  const [rows, setrows] = useState([]);
  const [filterRows, setfilterRows] = useState([]);
  const [pageSize, setPageSize] = useState(10);

  const EmployeeName = useRef();
  const EmployeeType = useRef();
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport
          csvOptions={{ fileName: "Employee Increments/Decrements" }}
        />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "id", headerName: "Sr #", headerClassName: header },
    { field: "name", headerName: "Employee", width: 200, headerClassName: header },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      headerClassName: header,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 200,
      headerClassName: header,
    },
    {
      field: "incrementtype",
      headerName: "Type",
      width: 200,
      headerClassName: header,
    },

    { field: "date", headerName: "Date", width: 200, headerClassName: header },

    {
      field: "previoussalary",
      headerName: "Previous Salary",
      width: 200,
      headerClassName: header,
    },

    {
      field: "newsalary",
      headerName: "New Salary",
      width: 200,
      headerClassName: header,
    },
  ];

  useEffect(() => {
    let arr = [];
    if (AllEmployees) {
      AllEmployees?.forEach((item, idx) => {
        return arr.push({
          id: idx + 1,
          phone: item?.Phone_num,
          email: item?.Email,
          name: item?.Emp_name,
          fathername: item?.father_name,
          incrementtype: item?.type,
          date: item?.date ? moment(item?.date).format("DD-MMM-YYYY") : "",
          previoussalary: item?.prev_salary
            ? Number(item?.prev_salary).toLocaleString()
            : null,
          newsalary: item?.new_salary
            ? Number(item?.new_salary).toLocaleString()
            : null,
        });
      });
    }

    setrows(arr);
    setfilterRows(arr);
  }, [AllEmployees]);

  const onTextChange = () => {
    let employeeName = EmployeeName.current.value;
    let Types = EmployeeType.current.value;
    if (employeeName === "" && Types === "") {
      setfilterRows(rows);
    } else {
      let suggestions = [];
      const empN = new RegExp(employeeName, "i");
      const regex1 = new RegExp(Types, "i");
      suggestions = rows.filter(
        (val) =>
          (employeeName === "" || empN.test(val.name)) &&
          (Types === "" || regex1.test(val.incrementtype))
      );
      setfilterRows(suggestions);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          padding: "20px 10px 10px 10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {addpermit ? (
            <>
              {/* <Button
                sx={{ padding: "5px 10px" }}
                onClick={() => {
                  setShowForm(true);
                }}
                variant="contained"
                className="tabletopbutton1 text-right"
              >
                New
              </Button> */}
              <CustomButton
                Color={"primary"}
                Icon={<AddIcon />}
                onClick={() => {
                  setShowForm(true);
                }}
                Label={"Add"}
              />
            </>
          ) : null}
        </Box>
        <Box
          sx={{ display: " flex ", justifyContent: "flex-end", gap: "20px" }}
        >
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: (theme) => theme.palette.grey.G2 }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{ width: "100%", maxWidth: "180px" }}
            variant="standard"
            type="text"
            autoComplete="off"
            placeholder="Employee"
            className="input_border"
            id="NameFilter"
            inputRef={EmployeeName}
            onChange={onTextChange}
          />
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: (theme) => theme.palette.grey.G2 }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{ width: "100%", maxWidth: "180px" }}
            variant="standard"
            type="text"
            autoComplete="off"
            placeholder="Type"
            className="input_border"
            inputRef={EmployeeType}
            id="DecrementFilter"
            onChange={onTextChange}
          />
        </Box>
      </Box>

      {status !== "succeeded" ? (
        <>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 237px)",
            }}
          >
            <Box sx={{ width: "5%" }}>
              <CircularProgress color="primary" />
            </Box>
          </Box>
        </>
      ) : (
        <Box
          style={{
            height: "calc(100vh - 237px)",
            width: "100%",
            padding: "10px",
          }}
        >
          <DataGrid
            rows={filterRows}
            columns={columns}
            onRowClick={() => {}}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            pagination
            components={{
              Toolbar: CustomToolbar,
            }}
            localeText={{
              toolbarDensity: "Size",
              toolbarDensityLabel: "Size",
              toolbarDensityCompact: "Small",
              toolbarDensityStandard: "Medium",
              toolbarDensityComfortable: "Large",
            }}
          />
        </Box>
      )}
    </>
  );
}

export default EmployeeIncrementDecrementTable;

EmployeeIncrementDecrementTable.propTypes = {
  setShowForm: PropTypes.any,
  setempData: PropTypes.any,
  setisEdited: PropTypes.any,
};
