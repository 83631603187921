import {
  Box,
  CircularProgress,
  Divider,
  List,
  ListItemText,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAllWokaholics,
  getDashboardActivities,
} from "../../../Redux/Slice/Dashboard/DashboardSlice";
import { logout } from "../../../Redux/Slice/LogoutSlice/LogoutSlice";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { useNavigate } from "react-router-dom";
const useStyle = makeStyles((theme) => {
  return {
    container: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      gap: "20px",
      borderRadius: "20px",
      color: theme.palette.white.main,
      transition: "0.5s",
    },
    heading: {
      textAlign: "left",
      padding: "10px",
      fontWeight: "bold",
      color: theme.palette.grey[700],
    },
  };
});
function WorkaholicTable() {
  const { heading } = useStyle();
  const dispatch = useDispatch();
  const { setsnackBarData } = useContext(SnackBarContext);
  const navigate = useNavigate();

  const { allWorkaholic, IsLogin, message, allWorkaholicstatus } = useSelector(
    getDashboardActivities
  );

  useEffect(() => {
    dispatch(GetAllWokaholics());
  }, [dispatch]);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  console.log("allWorkaholic", allWorkaholic);
  return (
    <div>
      <List aria-labelledby="nested-list-subheader">
        <Typography variant="h6" className={heading} sx={{}}>
        Minimum Shortfall in Work Hours
        </Typography>
        <Divider />
        {allWorkaholicstatus !== "succeeded" ? (
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : allWorkaholic?.finalTopEmployees?.length > 0 ? (
          allWorkaholic?.finalTopEmployees?.map((emp, index) => (
            <Box key={index} sx={{ padding: "10px 20px" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <ListItemText sx={{ textAlign: "left" }} primary={emp?.Name} />
                <ListItemText
                  sx={{ textAlign: "right" }}
                  primary={Math.round(emp?.TotalShortHrs) + " hrs"}
                />
              </Box>
              <Divider />
            </Box>
          ))
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "60%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="./NoData.png"
              alt="No Data Found "
              style={{ height: "100%", width: "95%" }}
            />
          </Box>
        )}
      </List>
    </div>
  );
}

export default WorkaholicTable;
