import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  Activeproject: [],
  Inactiveproject: [],
  modulespapulate: null,
  selectedProject: null,
  isSuccess: false,
  IsLogin: true,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const fetchActiveProject = createAsyncThunk(
  "getactiveprojects",
  async () => {
    const response = await axiosInstance.get(`getactiveprojects`);

    return response.data;
  }
);
// export const fetchProjectById = createAsyncThunk(
//   "clientprojectdata",
//   async () => {
//     const response = await axiosInstance.get(`clientprojectdata`);

//     return response.data;
//   }
// );
export const fetchInActiveProject = createAsyncThunk(
  "getinactiveprojects",
  async () => {
    const response = await axiosInstance.get(`getinactiveprojects`);

    return response.data;
  }
);

export const fetchModulePapulate = createAsyncThunk(
  "modulepapulate",
  async () => {
    const response = await axiosInstance.get(`getportaltypes`);
    return response.data;
  }
);

const ProjectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    ProjectAdded: {
      reducer(state, action) {
        state.Activeproject.push(action.payload);
      },
    },

    resetData: (state) => {
      state.Activeproject = [];
      state.Inactiveproject = [];
      state.isSuccess = false;
      state.IsLogin = true;
      state.message = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchActiveProject.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchActiveProject.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Activeproject = action.payload.Projects;
      })
      .addCase(fetchActiveProject.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(fetchInActiveProject.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchInActiveProject.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Inactiveproject = action.payload.Projects;
      })
      .addCase(fetchInActiveProject.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.status = "failed";
          state.error = action.error.message;
        }
      })
      .addCase(fetchModulePapulate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchModulePapulate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.modulespapulate = action.payload?.Projects;
      })
      .addCase(fetchModulePapulate.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.status = "failed";
          state.error = action.error.message;
        }
      });
  },
});

export const getProjectDetails = (state) => state.project;

export const { ProjectAdded, resetData } = ProjectSlice.actions;

export default ProjectSlice.reducer;
