import {
    IconButton,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
   import {
    //  fetchGazettedHolidays,
    getgazettedholidayDetails
  } from "../../../Redux/Slice/GazettedHolidays/GazettedHolidaysSlice";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";

const useStyles = makeStyles(() => {
    return {
        ModalContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            padding: "10px",
            width: "100%",
        },
        Container: {
            width: "800px",
            padding: "20px",
            border: "none",
            position: "relative",
            background: "white",
            borderRadius: "10px",
            display: "flex",
            gap: "20px",
            flexDirection: "column",
        },
        TableContain: { height: "450px", overflowY: "scroll" },
        TableHeader: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
    };
});

const GazettedHolidays = ({ open, setopen, gazettedHolidays }) => {
    const { setsnackBarData } = useContext(SnackBarContext);
    const navigate=useNavigate()
    const dispatch=useDispatch();
    const { TableContain, Container, ModalContainer, TableHeader } = useStyles();
    // const hoildaysDetails = useSelector(getgazettedholidayDetails);
    const {IsLogin,message}=useSelector(getgazettedholidayDetails)
    useEffect(() => {
        if (!IsLogin) {
          setsnackBarData({
            type: "error",
            message: message,
            openToast: true,
          });
          dispatch(logout());
          return navigate("/");
        }
      }, [IsLogin]);
    return (
        <Modal open={open}>
            <Box className={ModalContainer}>
                <Box className={Container}>
                    <Box className={TableHeader}>
                        <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                            Gazetted Holidays
                        </Typography>
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                setopen(!open);
                            }}
                        >
                            <HighlightOffIcon
                                color="primary"
                                sx={{
                                    fontSize: "28px",
                                }}
                            />
                        </IconButton>
                    </Box>
                    <TableContainer component={Paper} className={TableContain}>
                        <Table
                            stickyHeader
                            sx={{
                                minWidth: { md: "auto", xs: "auto" },
                                overflow: "scroll",
                            }}
                            aria-label="simple table"
                            size="small"
                        >
                            <TableHead>
                                <TableRow size="small">
                                    <TableCell size="small" sx={{ fontWeight: "bold" }}>
                                        Holiday Type
                                    </TableCell>
                                    <TableCell size="small" sx={{ fontWeight: "bold" }}>
                                        Holiday Name
                                    </TableCell>
                                    <TableCell size="small" sx={{ fontWeight: "bold" }}>
                                        Date Of Holiday
                                    </TableCell>


                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {gazettedHolidays &&
                                    gazettedHolidays.map((row, i) => (
                                        <TableRow
                                            key={i}
                                            sx={{
                                                "&:last-child td, &:last-child th": { border: 0 },
                                            }}
                                        >
                                            <TableCell size="small" component="th" scope="row">
                                                {row?.holiday_date}
                                            </TableCell>
                                            <TableCell size="small">
                                                {row?.holiday_name}
                                            </TableCell>
                                            <TableCell size="small">
                                                {row?.holiday_type}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </Modal>
    );
};

export default GazettedHolidays;

GazettedHolidays.propTypes = {
    open: PropTypes.any,
    setopen: PropTypes.any,
    gazettedHolidays: PropTypes.any,
};
