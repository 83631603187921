import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  gridClasses,
  InputAdornment,
  //   MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
// import moment from 'moment/moment';
import {
  fetchMonthlyReportPopulate,
  getmonthlyreportDetails,
} from "../../Redux/Slice/MonthlyReport/MonthlyReportSlice";
import theme from "../../theme";
import {
  fetchAtandanceDetailsReport,
  getAtandanceDetailsReportDetails,
} from "../../Redux/Slice/AtandanceDetailsReport/AtandanceDetailsReportSlice";
import moment from "moment/moment";
import SearchIcon from "@mui/icons-material/Search";
import { useReactToPrint } from "react-to-print";
import AttendanceDetailPrint from "./AttendanceDetailReportPrint";
import { Navigate, useNavigate } from "react-router-dom";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
// import Loader from "../../Components/Loader/Loader";
import axiosInstance from "../../Utils/AxiosInstance";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
    Form_input: {
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,
    },
  };
});
function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport csvOptions={{ fileName: "AtandanceDetailsReport" }} />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  );
}
function AtandanceDetailsReport() {
  const { setsnackBarData } = useContext(SnackBarContext);
  const navigate = useNavigate();
  const { header, Form_input } = useStyles();
  const dispatch = useDispatch();
  const [rows, setrows] = useState([]);
  const [pageSize, setPageSize] = useState(40);
  const { IsLogin, message } = useSelector(getAtandanceDetailsReportDetails);
  const { Report_Populate } = useSelector(getmonthlyreportDetails);
  const [allAtandanceDetailsReport, setallAtandanceDetailsReport] = useState(
    []
  );
  const [atandanceDetailsReport, setatandanceDetailsReport] = useState([]);
  const [employees, setemployees] = useState([]);
  const [totalHour, settotalHour] = useState("");
  const [EmpName, setEmpName] = useState("");
  const [loader, setloader] = useState(false);
  const [Total_workedHrs, setTotal_workedHrs] = useState(0);
  const [Total_wastedHrs, setTotal_wastedHrs] = useState(0);
  const [Total_overtimeHrs, setTotal_overtimeHrs] = useState(0);
  const Date = useRef();
  const [canView, setcanView] = useState(null);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  console.log(totalHour);
  const currentDate = moment();
  let firstDayOfMonth = currentDate.clone().startOf("month");
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  useEffect(() => {
    let string = "AttendanceDetailsReport";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    let match = value?.some((val) => {
      if (val?.module?.split("_")[1] === "View") {
        return true;
      }
    });
    setcanView(match);
    console.log(match);
    if (!roles || roles.length < 1) {
      setcanView(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  // const [selectedEmployee, setselectedEmployee] = useState(null);

  console.log(rows, Report_Populate);
  const componentRef = useRef();
  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    dispatch(fetchMonthlyReportPopulate());

    setemployees(Report_Populate);
  }, [dispatch]);
  useEffect(() => {
    settotalHour(Number(Total_workedHrs) + Number(Total_overtimeHrs));
  }, [Total_workedHrs, Total_overtimeHrs]);
  useEffect(() => {
    if (Report_Populate) {
      setemployees(Report_Populate);
    }
  }, [Report_Populate]);
  const columns = [
    { field: "sr", headerName: "Sr #", width: 100, headerClassName: header },
    {
      field: "Date",
      headerName: "Date",
      width: 200,
      headerClassName: header,
    },
    {
      field: "CheckIn",
      headerName: "Check In",
      width: 200,
      headerClassName: header,
    },
    {
      field: "CheckOut",
      headerName: "Check Out",
      width: 200,
      headerClassName: header,
    },
    {
      field: "Worked_hrs",
      headerName: "Worked Hours",
      width: 200,
      headerClassName: header,
    },
    {
      field: "OverTime_hrs",
      headerName: "Extra Hours",
      width: 200,
      headerClassName: header,
    },
    {
      field: "waste_time",
      headerName: "Short Hours",
      width: 200,
      headerClassName: header,
    },
  ];
  useEffect(() => {
    let arr = [];

    allAtandanceDetailsReport &&
      allAtandanceDetailsReport?.forEach((item, idx) => {
        return arr.push({
          ...item,
          id: idx + 1,
          sr: idx + 1,
          Date: moment(item?.Date).format("DD-MMM-YYYY"),
          CheckIn: item?.CheckIn
            ? moment(item?.CheckIn, "HH:mm").format("LT")
            : "",
          CheckOut: item?.CheckOut
            ? moment(item?.CheckOut, "HH:mm").format("LT")
            : "",
          OverTime_hrs: item?.OverTime_hrs,
          // parseInt(item?.OverTime_hrs?.split(":")[0]) === 0 &&
          // parseInt(item?.OverTime_hrs?.split(":")[1]) === 0
          //   ? 0
          //   : `${item?.OverTime_hrs?.split(":")[0]} hrs ${
          //       item?.OverTime_hrs?.split(":")[1]
          //     } min `,
          Worked_hrs: item?.Worked_hrs,
          // parseInt(item?.Worked_hrs?.split(":")[0]) === 0 &&
          // parseInt(item?.Worked_hrs?.split(":")[1]) === 0
          //   ? 0
          //   : `${item?.Worked_hrs?.split(":")[0]} hrs ${
          //       item?.Worked_hrs?.split(":")[1]
          //     } min`,
          //   count: item?.count,
          waste_time: item?.waste_time,
        });
      });

    setrows(arr);
    setatandanceDetailsReport(arr);
  }, [allAtandanceDetailsReport]);
  const initialValues = {
    employee: "",
    from: firstDayOfMonth.format("YYYY-MM-DD"),
    to: currentDate.format("YYYY-MM-DD"),
  };
  const handleSubmit = async (values) => {
    setloader(true);
    try {
      let response = await axiosInstance.get(
        `attendancereport?emp_id=${values?.employee}&to=${values?.to}&from=${values?.from}`
      );
      if (response.data?.IsSuccess) {
        console.log(response.data?.Data);
        setloader(false);
        setallAtandanceDetailsReport(response.data?.Data);
        setTotal_workedHrs(response.data?.Total_workedHrs);
        setTotal_overtimeHrs(response.data?.Total_overtimeHrs);
        setTotal_wastedHrs(response.data?.Total_wastedHrs);
      } else {
        setloader(false);
        setsnackBarData({
          type: "error",
          message: "Something went wrong",
          openToast: true,
        });
        console.error("Failed to fetch data:", response.statusText);
      }
    } catch (error) {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
      setloader(false);
    } finally {
      setloader(false);
    }
    dispatch(
      fetchAtandanceDetailsReport({
        emp_id: values?.employee,
        to: values?.to,
        from: values?.from,
      })
    );
  };
  const ValidationSchema = Yup.object().shape({
    employee: Yup.string().required("Please Enter employee"),
    from: Yup.string().required("Please Enter Start Date"),
    to: Yup.string().required("Please Enter End Date"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSubmit(values, resetForm, setSubmitting);
    },
  });
  const onTextChange = () => {
    if (Date.current.value === "") {
      setatandanceDetailsReport(rows);
    } else {
      let suggestions = [];
      let regex1 = new RegExp(
        moment(Date.current.value).format("DD-MMM-YYYY"),
        "i"
      );

      console.log(regex1);
      suggestions = rows?.filter(
        (val) => Date.current?.value === "" || regex1.test(val?.Date)
      );
      console.log(suggestions);
      setatandanceDetailsReport(suggestions);
    }
  };
  console.log(employees);
  return (
    <>
      {canView === true ? (
        <>
          <Box sx={{ display: "none" }}>
            <AttendanceDetailPrint
              ref={componentRef}
              atandanceDetailsReport={atandanceDetailsReport}
              name={EmpName}
              Total_overtimeHrs={Total_overtimeHrs}
              Total_workedHrs={Total_workedHrs}
              totalHour={totalHour}
              month={`${moment(formik.values.from).format(
                "DD-MMM-YYYY"
              )} To ${moment(formik.values.to).format("DD-MMM-YYYY")}`}
            />
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  paddingBottom: "10px",
                  width: "100%",
                  padding: "10px 10px 0px 10px",
                  alignItems: "center",
                }}
              >
                {/* <TextField
                            size="small"
                            variant="outlined"
                            name="employee"
                            label="Employee"
                            select
                            color="primary"
                            sx={{ input: { color: "black" }, maxWidth: "250px", textAlign: "left" }}
                            className={Form_input}
                            fullWidth
                            value={formik.values.employee}
                            onChange={formik.handleChange}
                            error={Boolean(formik.errors.employee) && Boolean(formik.touched.employee)}
                            helperText={Boolean(formik.errors.employee) && formik.errors.employee}
                        >
                            <MenuItem value="">Select employee</MenuItem>
                            {employees.map((employee) => {
                                return (
                                    <MenuItem key={employee?.id} value={employee?.id}>
                                        {employee?.name}
                                    </MenuItem>
                                );
                            })}
                        </TextField> */}
                <Autocomplete
                  disablePortal
                  options={employees}
                  getOptionLabel={(option) => option.name}
                  sx={{
                    input: { color: "black" },
                    maxWidth: "250px",
                    textAlign: "left",
                  }}
                  className={Form_input}
                  fullWidth
                  size="small"
                  onChange={(e, val) => {
                    console.log(e, val);
                    setEmpName(val?.name);
                    formik.handleChange({
                      target: {
                        name: "employee",
                        value: val?.id,
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="employee"
                      placeholder="Employee"
                      error={
                        Boolean(formik.errors.employee) &&
                        Boolean(formik.touched.employee)
                      }
                      helperText={
                        Boolean(formik.touched.employee) &&
                        formik.errors.employee
                      }
                    />
                  )}
                />
                <TextField
                  type={"date"}
                  name="from"
                  // variant="outlined"
                  size="small"
                  label="From"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formik.values.from}
                  onChange={formik.handleChange}
                  error={
                    Boolean(formik.errors.from) && Boolean(formik.touched.from)
                  }
                  helperText={Boolean(formik.errors.from) && formik.errors.from}
                />
                <TextField
                  type={"date"}
                  name="to"
                  label="To"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // variant="outlined"
                  size="small"
                  value={formik.values.to}
                  onChange={formik.handleChange}
                  error={
                    Boolean(formik.errors.to) && Boolean(formik.touched.to)
                  }
                  helperText={Boolean(formik.errors.to) && formik.errors.to}
                />
                {loader ? (
                  <CircularProgress />
                ) : (
                  <Button
                    //   onClick={() => {
                    //     handleGet();
                    //   }}
                    type="submit"
                    sx={{ padding: "5px 10px" }}
                    variant="contained"
                  >
                    Get
                  </Button>
                )}
                <Button
                  onClick={() => {
                    printPaymentRec();
                  }}
                  sx={{ padding: "5px 10px" }}
                  variant="contained"
                >
                  Print
                </Button>
              </Box>
              <Box sx={{ padding: "10px 10px 0px 10px" }}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          sx={{ color: (theme) => theme.palette.grey.G2 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  type="date"
                  autoComplete="off"
                  placeholder="Search"
                  // className="input_border"
                  inputRef={Date}
                  id="date"
                  onChange={onTextChange}
                />
              </Box>
            </Box>
            <Box
              // sx={{padding:"0px 10px"}}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px 10px 0px 10px",
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Total Regular Hours:
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  {
                    Total_workedHrs
                    // ? `${parseInt(
                    //     Total_workedHrs.split(":")[0]
                    //   )} hrs ${parseInt(Total_workedHrs.split(":")[1])} min`
                    // : null
                  }
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  {" "}
                  Total Overtime Hours:
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  {Total_overtimeHrs}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  {" "}
                  Total Wasted Hours:
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  {Total_wastedHrs}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  Total Hours:
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  {totalHour ? totalHour.toFixed(2) : ""}
                </Typography>
              </Box>
            </Box>
          </form>

          {/* {loader ? <Loader /> : null} */}
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            overflow
            sx={{ height: "calc(100vh - 182px)", padding: "10px" }}
          >
            <DataGrid
              rows={atandanceDetailsReport}
              columns={columns}
              onRowClick={() => {}}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
              pagination
              components={{
                Toolbar: CustomToolbar,
              }}
              localeText={{
                toolbarDensity: "Size",
                toolbarDensityLabel: "Size",
                toolbarDensityCompact: "Small",
                toolbarDensityStandard: "Medium",
                toolbarDensityComfortable: "Large",
              }}
            />
          </Grid>
        </>
      ) : canView === false ? (
        <Navigate to={"/dashboard"} />
      ) : (
        <Box>Loading ...</Box>
      )}
    </>
  );
}

export default AtandanceDetailsReport;
