import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  AtandanceDetailsReport: [],
  dailyAttendance:[],
  Total_overtimeHrs: "",
  Total_workedHrs: "",
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,

};

export const fetchAtandanceDetailsReport = createAsyncThunk(
  "attendancereport",
  async ({emp_id,to,from}) => {
    const response = await axiosInstance.get(`attendancereport?emp_id=${emp_id}&to=${to}&from=${from}`);
   console.log(response.data)
    return response.data;
  }
);


export const FetchDailyAttendanceReport = createAsyncThunk(
  "FetchDailyAttendanceReport",
  async (obj) => {
    console.log(obj)
    const response = await axiosInstance.get(
      `getdailyattendance?date=${obj?.date}`
    );
    console.log(response.data);
    return response.data;
  }
);



const AtandanceDetailsReportSlice = createSlice({
  name: "AtandanceDetailsReport",
  initialState,
  reducers: {
    AtandanceDetailsReportAdded: {
      reducer(state, action) {
        state.AtandanceDetailsReport?.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAtandanceDetailsReport.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAtandanceDetailsReport.fulfilled, (state, action) => {
         console.log(action?.payload );
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.AtandanceDetailsReport = action.payload.Data;
        state.Total_overtimeHrs = action.payload.Total_overtimeHrs;
        state.Total_workedHrs = action.payload.Total_workedHrs;
      })
      .addCase(fetchAtandanceDetailsReport.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(FetchDailyAttendanceReport.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchDailyAttendanceReport.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.dailyAttendance = action.payload.Data;
      })
      .addCase(FetchDailyAttendanceReport.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
  },
});

export const getAtandanceDetailsReportDetails = (state) => state.AtandanceDetailsReport;

export const {AtandanceDetailsReportAdded } = AtandanceDetailsReportSlice.actions;

export default AtandanceDetailsReportSlice.reducer;