import PropTypes from "prop-types";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  gridClasses,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  //   InputAdornment,
  //   TextField,
  // Typography,
} from "@mui/material";
// import SearchIcon from "@mui/icons-material/Search";
import React, { useContext, useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import {
  fetchAllLoans,
  fetchLoans,
  getLoansDetails,
} from "../../Redux/Slice/Loans/LoansSlice";
import { PatchaLoansadminApi } from "../../Api/Loans/PatchLoansAdminApi";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import moment from "moment/moment";
import LoansApproveModal from "./LoansApproveModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LoansDetailsModal from "./LoansDetailsModal";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../Button/AddNewButton";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});

function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport csvOptions={{ fileName: "Loan Application" }} />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  );
}

function LoansTable({ setShowForm }) {
  const { tabelStyle } = useContext(TabelStyleContext);
  const { header } = useStyles();
  const [pageSize, setPageSize] = useState(10);
  const cookies = new Cookies();
  const user = cookies.get("user");
  const [rows, setrows] = useState([]);
  const [open, setOpen] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [loansRow, setloansRow] = useState([]);
  const [LoansData, setLoansData] = useState([]);
  const [loans, setloans] = useState([]);
  const [editRow, seteditRow] = useState();
  const [loading, setloading] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [showModal2, setshowModal2] = useState(false);
  const { Loans, status, IsLogin, message } = useSelector(getLoansDetails);
  const navigate = useNavigate();
  const Name = useRef();
  const Date = useRef();

  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [editPermit, seteditpermit] = useState(false);
  // console.log(crop);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  console.log(roles);
  useEffect(() => {
    let string = "Loans";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Edit") {
        seteditpermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      seteditpermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  console.log(editPermit);

  const dispatch = useDispatch();
  useEffect(() => {
    if (cookies.get("user")?.type === "Employee") {
      dispatch(fetchLoans(cookies.get("user")?.Emp_id));
    } else if (cookies.get("user")?.type === "Admin") {
      dispatch(fetchAllLoans());
    }
  }, [dispatch]);

  const handleApprove = (val) => {
    setLoansData(val);
  };
  const RejectedLoans = async () => {
    setloading(true);

    const response = await PatchaLoansadminApi({
      loan_id: loansRow.loan_id,
      status: "Rejected",
    });
    setsnackBarData(response.snackBarData);
    setloading(false);

    if (response.data.IsSuccess) {
      setloansRow(null);
      dispatch(fetchAllLoans());
      handleClose();
      setloading(false);
    }
  };
  const columns = [
    { field: "id", headerName: "Sr #", headerClassName: header },
    {
      field: "name",
      headerName: "Employee",
      width: 180,
      headerClassName: header,
    },
    {
      field: "requested_amount",
      headerName: "Requested Amt",
      width: 200,
      headerClassName: header,
    },
    {
      field: "created_at",
      headerName: "Requested On",
      width: 160,
      headerClassName: header,
    },
    {
      field: "note",
      headerName: "Note",
      width: 130,
      headerClassName: header,
    },

    {
      field: "total_month",
      headerName: "Est Payback Month",
      width: 160,
      headerClassName: header,
    },
    {
      field: "accepted_at",
      headerName: "Accepted On",
      width: 160,
      headerClassName: header,
    },

    {
      field: "amount",
      headerName: "Approved Amt",
      width: 180,
      headerClassName: header,
    },
    {
      field: "pending_amount",
      headerName: "Pending Amt ",
      width: 180,
      headerClassName: header,
    },

    {
      field: "status",
      headerName: "Status",
      width: 130,
      headerClassName: header,
      renderCell: (celVal) => {
        return (
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Typography sx={{ width: "5rem" }}>{celVal.row?.status}</Typography>
            <Box
              sx={{
                width: "20px",
                height: "20px",
                backgroundColor:
                  celVal?.row?.status === "Rejected"
                    ? "red"
                    : celVal?.row?.status === "Accepted"
                    ? "green"
                    : celVal?.row?.status === "Pending"
                    ? "yellow"
                    : null,
                borderRadius: "100%",
              }}
            ></Box>
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "Actions",
      width: 130,
      headerClassName: header,
      renderCell: (cellVal) => {
        return cellVal.row.status === "Accepted" ||
          cellVal.row.status === "Rejected" ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip title="Details">
              <VisibilityIcon
                fontSize="small"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  seteditRow(cellVal.row);
                  setshowModal2(true);
                }}
              />
            </Tooltip>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {editPermit ? (
              <Tooltip title="Approve">
                <CheckCircleIcon
                  fontSize="small"
                  sx={{ cursor: "pointer", color: "green" }}
                  onClick={() => {
                    handleApprove(cellVal.row);
                    setshowModal(true);
                  }}
                />
              </Tooltip>
            ) : null}

            {editPermit ? (
              <Tooltip title="Reject">
                <CancelPresentationIcon
                  fontSize="small"
                  sx={{ cursor: "pointer", color: "red" }}
                  onClick={() => {
                    handleClickOpen();
                    setloansRow(cellVal.row);
                  }}
                />
              </Tooltip>
            ) : null}

            {/* <Tooltip title="Approve">
                <AddTaskIcon sx={{ color: "black", cursor: "pointer" }} />
              </Tooltip> */}
          </Box>
        );
      },
    },
  ];
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let rowData = [];
    Loans?.Employee_Loan?.map((loan, idx) => {
      rowData.push({
        ...loan,
        id: idx + 1,
        amount: loan?.amount?.toLocaleString(),
        total_month: loan?.total_month,
        requested_months: loan?.requested_months,
        requested_amount: loan?.requested_amount,
        accepted_at: loan?.accepted_at
          ? moment(loan?.accepted_at).format("DD-MMM-YYYY")
          : null,
        created_at: loan?.created_at
          ? moment(loan?.created_at).format("DD-MMM-YYYY")
          : null,
        note: loan?.note,
        loan_id: loan?.loan_id,
        status: loan?.status,
        name: loan?.emp_id?.name,
        pending_amount: loan?.pending_amount?.toLocaleString(),
      });
    });
    setrows(rowData);
    setloans(rowData);
  }, [Loans]);
  const onTextChange = () => {
    if (Name.current?.value === "" && Date.current.value === "") {
      setloans(rows);
    } else {
      let suggestions = [];
      let regex1 = new RegExp(Name.current?.value, "i");
      let regex2 = new RegExp(
        moment(Date.current.value).format("DD-MMM-YYYY"),
        "i"
      );

      suggestions = rows?.filter(
        (val) =>
          (Name.current?.value === "" || regex1.test(val?.name)) &&
          (Date.current?.value === "" || regex2.test(val?.created_at))
      );
      setloans(suggestions);
    }
  };
  useEffect(() => {
    if (status === "failed") {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
    }
  }, [status]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          padding: "10px 10px 0px 10px",
        }}
      >
        {user?.type === "Employee" ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {/* <Button
              sx={{ padding: "5px 10px" }}
              onClick={() => {
                setShowForm(true);
              }}
              variant="contained"
              className="tabletopbutton1 text-right"
            >
              New
            </Button> */}
            <CustomButton
              Color={"primary"}
              Icon={<AddIcon />}
              onClick={() => {
                setShowForm(true);
              }}
              Label={"Add"}
            />
          </Box>
        ) : null}
        <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
          <Box
            sx={{ padding: "10px 10px 0px 10px", display: "flex", gap: "10px" }}
          >
            {user?.type === "Employee" ? null : (
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        sx={{ color: (theme) => theme.palette.grey.G2 }}
                      />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                type="text"
                autoComplete="off"
                placeholder="Employee"
                // className="input_border"
                id="empName"
                inputRef={Name}
                onChange={onTextChange}
              />
            )}
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{ color: (theme) => theme.palette.grey.G2 }}
                    />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              type="date"
              autoComplete="off"
              placeholder="Search"
              // className="input_border"
              inputRef={Date}
              id="date"
              onChange={onTextChange}
            />
          </Box>
        </Box>
      </Box>

      <Box
        sx={{ height: tabelStyle.height, padding: "10px" }}
        // sx={{
        //   height:
        //     user?.type === "Employee"
        //       ? "calc(100vh - 222px)"
        //       : "calc(100vh - 220px)",
        //   width: "100%",
        //   padding: "10px",
        // }}
      >
        {Loans?.length<=0 && status === "loading" ? <Loader /> : null}
        <DataGrid
          rows={loans}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          columnVisibilityModel={
            user?.type === "Employee"
              ? {
                  action: false,
                  name: false,
                }
              : { action: true }
          }
          // pagination
          components={{
            Toolbar: CustomToolbar,
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to Rejected
            <b> {loansRow?.requested_amount}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              sx={{
                background: "red",
                color: "white !important",
                fontSize: "13px",
                textTransform: "none",
              }}
              onClick={handleClose}
            >
              No
            </Button>

            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                sx={{
                  background: "#3480FA",
                  color: "white !important",
                  fontSize: "13px",
                  textTransform: "none",
                }}
                onClick={() => {
                  RejectedLoans();
                }}
                autoFocus
              >
                Yes
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
      <LoansApproveModal
        LoansData={LoansData}
        setshowModal={setshowModal}
        showModal={showModal}
        setLoansData={setLoansData}
      />
      <LoansDetailsModal
        showModal2={showModal2}
        setshowModal2={setshowModal2}
        editRow={editRow}
      />
    </>
  );
}

export default LoansTable;

LoansTable.propTypes = {
  setShowForm: PropTypes.any,
  LoansData: PropTypes.any,
  showModal2: PropTypes.any,
  setshowModal2: PropTypes.any,
  editRow: PropTypes.any,
};
