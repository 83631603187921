import * as React from "react";
import PropTypes from "prop-types";

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  Modal,
  TextField,
  Typography,
  TableRow,
  TableContainer,
  TableHead,
  TableCell,
  TableBody,
  Table,
  Collapse,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import moment from "moment";
import { makeStyles } from "@mui/styles";
// import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import * as Yup from "yup";
import { useFormik } from "formik";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { AddBreakAdjustment } from "../../Api/Attendance/AttendanceAPis";
const useStyle = makeStyles((theme) => {
  return {
    tablehead: {
      fontWeight: "bold",
      backgroundColor: "#53b7c5 !important",
      color: theme.palette.white.main,
      padding: "15px 20px",
      flex: 1,
    },
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
    },
    Container: {
      width: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    container: {
      // height: "100%",
      width: "100%",
      padding: "0px 20px 20px 20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      // padding: "20px",
      width: "100%",
      // maxWidth: "700px",
      // minHeight: "calc(100vh - 215px)",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      textAlign: "left",
    },
  };
});

function Row(props) {
  const { row, sr } = props;
  const [open, setOpen] = React.useState(false);
  console.log(props?.row);
  if (props?.row?.date === moment().format("YYYY-MM-DD")) {
    console.log("dasda");
  }
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          {sr}
        </TableCell>
        <TableCell>{row.emp_id?.name}</TableCell>
        <TableCell>{row.emp_id?.department?.department_name}</TableCell>
        <TableCell>
          {row?.in_time ? moment(row?.in_time, "HH:mm").format("hh:mm A") : ""}
        </TableCell>
        <TableCell>
          {row?.out_time
            ? moment(row?.out_time, "HH:mm").format("hh:mm A")
            : ""}
        </TableCell>
        <TableCell>
          {
            row?.over_time
            // ? `${row?.over_time?.split(":")[0]}hrs ${
            //     row?.over_time?.split(":")[1]
            //   }min` : " "
          }
        </TableCell>
        <TableCell align="center">
          {
            row?.total_hrs
            // ? `${row?.total_hrs?.split(":")[0]}hrs ${
            //     row?.total_hrs?.split(":")[1]
            //   }min`  : " "
          }
        </TableCell>
        <TableCell align="center">
          {
            row?.break_time
          }
          {/* {props?.row?.date === moment().format("YYYY-MM-DD") &&
          !props?.row?.break_time ? (
            <IconButton
              size={"small"}
              onClick={() => {
                props?.setSelectedAttendance(row);
                props?.setshowModal(true);
                // setEditedData(celVal.row);
                // setIsEdit(true);
                // setShowForm(true);
              }}
            >
              <AppRegistrationIcon
                sx={{ cursor: "pointer", color: "black !important" }}
              />
            </IconButton>
          ) : null} */}
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Log Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Time</TableCell>
                 
                    <TableCell sx={{ fontWeight: "bold" }} align="right">
                      Note
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.LogDetail?.map((log, i) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        {log?.status}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {
                          log?.time?
                          moment(log?.time, "HH:mm").format("hh:mm A"):""
                          // ? moment(log?.time, "HH:mm").format("hh:mm A")  : ""
                        }
                      </TableCell>
                
                      <TableCell align="right">{log?.note}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.any,
  sr: PropTypes.any,
  setshowModal: PropTypes.any,
  setSelectedAttendance: PropTypes.any,
};

const DailyAttTable = ({ attendanceDetails }) => {
  console.log(attendanceDetails);
  const {
    tablehead,
    Container,
    ModalContainer,
    TableHeader,
    container,
    subContainer,
    formContainer,
  } = useStyle();
  const [showModal, setshowModal] = React.useState(false);
  const [SelectedAttendance, setSelectedAttendance] = React.useState(null);
  const [loading, setloading] = React.useState(null);
  const { setsnackBarData } = React.useContext(SnackBarContext);

  const initialValues = {
    break_time: "",
    breakadjustmentreason: "",
  };
  const validationSchema = Yup.object().shape({
    break_time: Yup.string().required("Please Enter Adjustment Time"),
    breakadjustmentreason: Yup.string().required("Please Enter Reason"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSave(values, resetForm, setSubmitting);
    },
  });
  console.log(loading);
  console.log(SelectedAttendance);
  const handleSave = async () => {
    setloading(true);

    const response = await AddBreakAdjustment({
      break_time: formik?.values?.break_time,
      breakadjustmentreason: formik?.values?.breakadjustmentreason,
      attendance_id: SelectedAttendance?.attendance_id,
    });
    setsnackBarData(response?.snackBarData);
    setloading(false);
    console.log(response);
    if (response.data.IsSuccess) {
      console.log("dadas");
      setshowModal(false);
      setSelectedAttendance(null);
      formik?.resetForm();
    }

    console.log(response);
  };
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          maxWidth: "100%",
          height: "100%",
          overflowX: "scroll",
          margin: "0px auto",
        }}
      >
        <Table size="small" stickyHeader sx={{ overflowX: "scroll" }}>
          <TableHead
            sx={{
              width: "100%",
              padding: "0px 10px",
            }}
          >
            <TableRow sx={{ padding: "0px 20px", width: "100%" }}>
              <TableCell className={tablehead}>Sr# </TableCell>
              <TableCell className={tablehead}>Name</TableCell>
              <TableCell className={tablehead}>Department</TableCell>
              <TableCell className={tablehead}>In Time</TableCell>
              <TableCell className={tablehead}>Out Time</TableCell>
              <TableCell className={tablehead}>Extra Hours</TableCell>
              <TableCell className={tablehead}>Total Hours</TableCell>
              <TableCell className={tablehead}>Break Adjustment</TableCell>
              <TableCell className={tablehead} />

            </TableRow>
          </TableHead>
          <TableBody sx={{ width: "100%", overflowX: "scroll" }}>
            {attendanceDetails &&
              attendanceDetails.map((row, i) => (
                <Row
                  key={i}
                  row={row}
                  sr={i + 1}
                  setshowModal={setshowModal}
                  setSelectedAttendance={setSelectedAttendance}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        open={showModal}
        onClose={() => {
          setshowModal(false);
        }}
      >
        <Box className={ModalContainer}>
          <Box className={Container}>
            <Box className={TableHeader}>
              <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                Approve Advance
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setshowModal(false);
                }}
              >
                <HighlightOffIcon
                  color="primary"
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Box>
            <Box className={container}>
              <Box className={subContainer}>
                <form onSubmit={formik.handleSubmit}>
                  <Box className={formContainer}>
                    <FormControl fullWidth>
                      <Typography>Worked Time</Typography>
                      <TextField
                        sx={{ textAlign: "left", input: { color: "black" } }}
                        fullWidth
                        size="small"
                        name="break_time"
                        type="number"
                        onBlur={formik.handleBlur}
                        onChange={formik?.handleChange}
                        value={formik.values.break_time}
                        variant="outlined"
                        error={
                          Boolean(formik.touched.break_time) &&
                          Boolean(formik.errors.break_time)
                        }
                        helperText={
                          Boolean(formik.touched.break_time) &&
                          formik.errors.break_time
                        }
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Typography>Worked Time</Typography>
                      <TextField
                        sx={{ textAlign: "left", input: { color: "black" } }}
                        fullWidth
                        size="small"
                        name="breakadjustmentreason"
                        type="number"
                        onBlur={formik.handleBlur}
                        onChange={formik?.handleChange}
                        value={formik.values.breakadjustmentreason}
                        variant="outlined"
                        multiline
                        rows="3"
                        error={
                          Boolean(formik.touched.breakadjustmentreason) &&
                          Boolean(formik.errors.breakadjustmentreason)
                        }
                        helperText={
                          Boolean(formik.touched.breakadjustmentreason) &&
                          formik.errors.breakadjustmentreason
                        }
                      />
                    </FormControl>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "end",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <Box>
                          {formik.isSubmitting ? (
                            <CircularProgress />
                          ) : (
                            <Button type="submit" variant="contained">
                              <Typography>Submit</Typography>
                            </Button>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default DailyAttTable;
DailyAttTable.propTypes = {
  attendanceDetails: PropTypes.any,
};
