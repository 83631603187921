import * as React from "react";
import {
  Box,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import moment from "moment/moment";

function SalaryDetail({ AllSingEmpDetail }) {

  const [allSingEmpDetail, setallSingEmpDetail] = useState([]);

  useEffect(() => {
    setallSingEmpDetail(AllSingEmpDetail);
  }, [AllSingEmpDetail]);



  return (
    <>
      <Box sx={{ padding: "10px" }}>

        <Typography
          sx={{ textAlign: "left", fontWeight: "bold", fontSize: "18px" }}
        >
          Salary Details
        </Typography>
        <Divider />
        <Box
          sx={{
            display: "flex",
            gap: "40px",
            padding: "10px",
            width: "50%",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>Basic Salary:</Typography>

          <Typography>
            {" "}
            {allSingEmpDetail?.Employee?.basic_salary
              ?Number(allSingEmpDetail?.Employee?.basic_salary.replaceAll(',',"")).toLocaleString()
              : ""}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "40px",
            padding: "10px",
            width: "60%",
            justifyContent: "space-between",
            alignItems:"center"
          }}
        >
          <Typography sx={{ fontWeight: "bold", }}>Contract Scan:</Typography>

          
          <Typography sx={{overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis",width:"60%",maxWidth:"300px"}}>



          <Link href={allSingEmpDetail?.Employee?.contract_scan} target={"_blank"} >{allSingEmpDetail?.Employee?.contract_scan}</Link>
          </Typography>

        </Box>
      </Box>

      {allSingEmpDetail?.Increment_Decrement?.length > 0 ? (
        <Box sx={{ height: "400px", width: "100%", paddingX: "10px" }}>
          <Typography
            sx={{ textAlign: "left", fontWeight: "bold", fontSize: "18px" }}
          >
            Increment/Decrement Details
          </Typography>
          <TableContainer
         
            sx={{ overflowY: "scroll", paddingTop: "10px" }}
          >
            <Table
              sx={{
                minWidth: { md: "auto", xs: "auto" },
                overflow: "scroll",
              }}
              aria-label="simple table"
              size="small"
            >
              <TableHead>
                <TableRow size="small">
                  <TableCell size="small" sx={{ fontWeight: "bold" }}>
                    Id
                  </TableCell>
                  <TableCell
                    size="small"
                    align="center"
                    sx={{ fontWeight: "bold" }}
                  >
                    Type
                  </TableCell>
                  <TableCell
                    size="small"
                    align="center"
                    sx={{ fontWeight: "bold" }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    size="small"
                    align="center"
                    sx={{ fontWeight: "bold" }}
                  >
                    Father Name
                  </TableCell>

                  <TableCell
                    size="small"
                    align="center"
                    sx={{ fontWeight: "bold" }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    size="small"
                    align="center"
                    sx={{ fontWeight: "bold" }}
                  >
                    Previous Salary
                  </TableCell>
                  <TableCell
                    size="small"
                    align="center"
                    sx={{ fontWeight: "bold" }}
                  >
                    New Salary
                  </TableCell>
                  <TableCell
                    size="small"
                    align="center"
                    sx={{ fontWeight: "bold" }}
                  >
                    Description
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allSingEmpDetail?.Increment_Decrement?.map((incremnt, ind) => (
                  <TableRow
                    key={incremnt?.historyid?.emp_id?.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell size="small" component="th" scope="row">
                      {ind + 1}
                    </TableCell>
                    <TableCell size="small" align="center">
                      {incremnt?.historyid?.type}
                    </TableCell>
                    <TableCell size="small" align="center">
                      {incremnt?.historyid?.emp_id?.name}
                    </TableCell>
                    <TableCell size="small" align="center">
                      {incremnt?.historyid?.emp_id?.father_name}
                    </TableCell>

                    <TableCell size="small" align="center">
                      {moment(incremnt?.historyid?.date).format("DD-MMM-YYYY")}
                    </TableCell>
                    <TableCell size="small" align="center">
                      {Number(incremnt?.prev_salary)?.toLocaleString()}
                    </TableCell>
                    <TableCell size="small" align="center">
                      {Number(incremnt?.new_salary)?.toLocaleString()}
                    </TableCell>
                    <TableCell size="small" align="center">
                      {incremnt?.historyid?.description}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : null}
    </>
  );
}

export default SalaryDetail;
SalaryDetail.propTypes = {
  AllSingEmpDetail: PropTypes.any,
};
