import React, { useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  FormControl,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import theme from '../../../theme'
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { CreateRolesApi } from "../../../Api/Roles/CreateRolesApi";
const useStyles = makeStyles(() => {
  return {
    Form_input: {
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,
    },

    input_typography: {
      textAlign: "left",
      fontSize: `15px !important`,
      marginTop: "10px !important",
    },
    btns: {
      width: "80px",
    },
  };
});
function RuleName({ setShowForm, rolesRowData, AllRole, IsEdit, setIsEdit, setrolesRowData }) {
  const {
    Form_input,
    btns,
    input_typography,
  } = useStyles();
  const { setsnackBarData } = useContext(SnackBarContext);
  const initialValues = {
    role_name: "",
  };
  const validationSchema = Yup.object().shape({
    role_name: Yup.string().required("Please Enter Rule Name"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSave(values, resetForm, setSubmitting);
    },
  });
  console.log(rolesRowData);
  useEffect(() => {
    if (rolesRowData && IsEdit) {
      formik.setFieldValue("role_name", rolesRowData?.role_name)
    }
  }, [rolesRowData])
  const handleSave = async (values, setSubmitting,) => {

    if (IsEdit) {
      let arr2 = [];
      AllRole && AllRole?.map((item) => {
        item?.modules?.map((mod) => {
          if (mod?.status === true) {
            arr2.push(mod?.id)

          }
        })
      })
      if (arr2.length <= 0) {
        setSubmitting(false);
        return setsnackBarData({
          type: "error",
          message: "Please Add Role",
          openToast: true,
        })

      }
      const obj = { ...values, rules: arr2, role_id: rolesRowData?.role_id };
      const response = await CreateRolesApi(obj);
      setsnackBarData(response.snackBarData);
      if (response.data.IsSuccess) {
        //   resetForm();
        setIsEdit(false);
        setSubmitting(false);
        setShowForm(false);
      }
      setSubmitting(false);

    } else {
      let arr = [];
      AllRole && AllRole?.map((item) => {
        item?.modules?.map((mod) => {
          if (mod?.status === true) {
            arr.push(mod?.id)

          }
        })
      })

      if (arr.length <= 0) {
        setSubmitting(false);
        return setsnackBarData({
          type: "error",
          message: "Please Add Role",
          openToast: true,
        })

      }
      const obj = { ...values, rules: arr };
      const response = await CreateRolesApi(obj);
      setsnackBarData(response.snackBarData);
      if (response.data.IsSuccess) {
        //   resetForm();
        setSubmitting(false);
        setShowForm(false);

      }
      setSubmitting(false);
    }
  };

  return (

    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          justifyContent: "flex-end",
          display: "flex",
          gap: "10px",
          paddingTop: "20px",
        }}
      >
        <Button
          className={btns}
          variant="contained"
          color="error"
          onClick={() => {
            setrolesRowData(null);
            setIsEdit(false);
            setShowForm(false);
          }}
        >
          Cancel
        </Button>
        {formik?.isSubmitting ? (
          <CircularProgress />
        ) : (
          <Button
            type="submit"
            className={btns}
            variant="contained"
            color="primary"
          >
            {
              IsEdit ? "Update" : "Save"
            }

          </Button>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          gap: "10px",
        }}
      >
        <FormControl fullWidth>
          <Typography className={input_typography}>Rule Name</Typography>
          <TextField
            fullWidth
            className={Form_input}
            size="small"
            name="role_name"
            type={"text"}
            onBlur={formik.handleBlur}
            value={formik.values.role_name}
            onChange={formik.handleChange}
            variant="outlined"
            error={
              Boolean(formik.touched.role_name) &&
              Boolean(formik.errors.role_name)
            }
            helperText={
              Boolean(formik.touched.role_name) && formik.errors.role_name
            }
          />
        </FormControl>

      </Box>
    </form>

  )
}

export default RuleName;
RuleName.propTypes = {
  setShowForm: PropTypes.any,
  rolesRowData: PropTypes.any,
  AllRole: PropTypes.any,
  IsEdit: PropTypes.any,
  setIsEdit: PropTypes.any,
  setrolesRowData: PropTypes.any,
};
