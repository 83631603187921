import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  FormControl,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import theme from "../../theme";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import {
  AddNewTechnology,
  UpdateTechnology,
} from "../../Api/Technologies/TechnologiesAPis";
import { TechnologiesAdded } from "../../Redux/Slice/Technologies/TechnologiesSlice";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useEffect } from "react";
import remove from "../../Assets/remove.png";

const useStyles = makeStyles(() => {
  return {
    input_typography: {
      textAlign: "left",
      // fontWeight: "bold",
      fontSize: `15px !important`,
      // opacity: "50%",
      marginTop: "10px !important",
      paddingRight: "20px !important",
    },
    Form_input: {
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,
    },

    form_main: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      padding: "60px 8%",
      minHeight: "calc(100vh - 175px)",
    },
    responsive: {
      maxWidth: "600px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },

    btns: {
      width: "80px",
    },
  };
});

const TechnologiesForm = ({
  setShowForm,
  setIsEdit,
  setEditedData,
  IsEdit,
  EditedData,
}) => {
  const { Form_input, input_typography, btns, form_main, responsive } =
    useStyles();
  const { setsnackBarData } = useContext(SnackBarContext);
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();

  const [formData, setformData] = useState({
    technology_name: "",
    tech_id: "",
    expertise: [],
  });

  const [Skillset, setSkillset] = useState({
    expertise_name: "",
  });
  console.log(EditedData);
  const [allSkillsets, setallSkillsets] = useState([]);

  // const addSkillSet = () => {
  //   let arr = [...allSkillsets];
  //   if (Skillset?.expertise_name !== "") {
  //     let findNo = allSkillsets?.find(
  //       (no) => no.expertise_name === Skillset.expertise_name
  //     );
  //     if (findNo) {
  //       setsnackBarData({
  //         type: "error",
  //         message: "Already Exist",
  //         openToast: true,
  //       });
  //     } else {
  //       arr.push(Skillset);
  //       setallSkillsets(arr);
  //     }
  //     setSkillset({
  //       expertise_name: "",
  //     });
  //   } else {
  //     setsnackBarData({
  //       type: "error",
  //       message: "Please Enter Skill_Set",
  //       openToast: true,
  //     });
  //   }
  // };

  const addSkillSet = () => {
    if (Skillset?.expertise_name !== "") {
      const existingSkillSet = allSkillsets?.find(
        (skillset) => skillset.expertise_name === Skillset.expertise_name
      );
      if (existingSkillSet) {
        setsnackBarData({
          type: "error",
          message: "Already Exist",
          openToast: true,
        });
      } else {
        const updatedSkillSets = [...allSkillsets, Skillset];
        setallSkillsets(updatedSkillSets);
      }
      setSkillset({
        expertise_name: "",
      });
    } else {
      setsnackBarData({
        type: "error",
        message: "Please Enter Skill_Set",
        openToast: true,
      });
    }
  };

  const HandelChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setformData({ ...formData, [name]: value });
  };

  const AddTechnologies = async () => {
    if (formData?.technology_name === "") {
      setsnackBarData({
        type: "error",
        message: "Please Enter Technology",
        openToast: true,
      });
    } else {
      setloading(true);

      let response = null;
      if (IsEdit) {
        response = await UpdateTechnology({
          ...formData,
          expertise: allSkillsets,
        });
      } else {
        response = await AddNewTechnology({
          ...formData,
          expertise: allSkillsets,
        });
      }
      setsnackBarData(response.snackBarData);

      if (response.data.IsSuccess) {
        setformData({
          technology_name: "",
          tech_id: "",
          expertise: [],
        });
        setIsEdit(false);
        setEditedData(null);
        setShowForm(false);
        dispatch(TechnologiesAdded(response.data.new_language));
      }
      setloading(false);
    }
    console.log(formData);
  };

  useEffect(() => {
    if (EditedData && IsEdit) {
      setformData(EditedData);
      setallSkillsets(EditedData?.expertiseid);
    }
  }, [IsEdit, EditedData]);

  const deletePhoneNo = (index) => {
    let abc = [...allSkillsets];
    abc.splice(index, 1);
    setallSkillsets(abc);
  };
  return (
    <Box className={form_main}>
      <Box className={responsive}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            // gap: "10px",
          }}
        >
          <Typography className={input_typography}>Technology</Typography>

          <FormControl fullWidth sx={{ textAlign: "left" }}>
            <TextField
              size="small"
              type="text"
              name="technology_name"
              value={formData?.technology_name}
              onChange={HandelChange}
              className={Form_input}
            />
          </FormControl>

          <Box
            sx={{
              display: "flex",
              gap: "10px",
              // marginTop: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
              }}
            >
              <Typography
                sx={{ padddingTop: "10px" }}
                className={input_typography}
              >
                Skill Set
              </Typography>

              <FormControl sx={{ textAlign: "left", width: "510px" }}>
                <TextField
                  sx={{ textAlign: "left" }}
                  size="small"
                  type="text"
                  name="Skill_Set"
                  className={Form_input}
                  value={Skillset.expertise_name}
                  onChange={(e) => {
                    setSkillset({
                      ...Skillset,
                      expertise_name: e.target.value,
                    });
                  }}
                />
              </FormControl>
            </Box>

            <Box sx={{ paddingTop: "39px" }}>
              <Button
                className={btns}
                size="small"
                variant="outlined"
                onClick={() => {
                  addSkillSet();
                }}
              >
                Add
              </Button>
            </Box>
          </Box>

          {allSkillsets?.length > 0 && (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
              }}
            >
              {allSkillsets?.map((item, index) => {
                return (
                  <Box sx={{ display: "flex", padding: "5px" }} key={index}>
                    <Box 
                      sx={{
                        display: "flex",
                        backgroundColor: "lightblue",
                        borderRadius: "5px",
                        padding: "5px", 
                      }}
                    >
                      {item?.expertise_name}
                    </Box>
                    <img
                      src={remove}
                      onClick={() => deletePhoneNo(index)}
                      style={{
                        cursor: "pointer",
                        width: "15px",
                        height: "15px",
                        backgroundRepeat: "no-repeat",
                        marginTop: "-5px",
                        marginLeft: "-3px",
                      }}
                      alt=""
                    />
                    <div
                      onClick={() => deletePhoneNo(index)}
                      className="remove_circle_icon pointer"
                    ></div>
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>

        <Box
          sx={{
            justifyContent: "flex-end",
            display: "flex",
            gap: "10px",
            // marginTop: "20px",
          }}
          spacing={2}
        >
          <>
            <Button
              className={btns}
              variant="contained"
              color="error"
              onClick={() => setShowForm(false)}
            >
              Cancel
            </Button>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                className={btns}
                variant="contained"
                color="primary"
                onClick={() => {
                  AddTechnologies();
                }}
              >
                {IsEdit ? "Update" : "Save"}
              </Button>
            )}
          </>
        </Box>
      </Box>
    </Box>
  );
};

export default TechnologiesForm;
TechnologiesForm.propTypes = {
  setShowForm: PropTypes.func,
  setIsEdit: PropTypes.func,
  setEditedData: PropTypes.func,
  IsEdit: PropTypes.any,
  EditedData: PropTypes.any,
};
