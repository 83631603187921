import {
  Box,
  // Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
// import DashboardTable from "../../DashboardTable/DashboardTable";
import WorkaholicTable from "./WorkaholicTable";
import OnTimeTable from "./OnTimeTable";
import MaxAttendanceTable from "./MaxAttendanceTable";

const useStyle = makeStyles((theme) => {
  return {
    container: {
      width: "100%",
      display: "flex",

      gap: "20px",
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
        // flexDirection: "column",
      },
    },
    tableContainer: {
      // flex: 1,
      width: "100%",

      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
    detailContainer: {
      // flex: 1,
      width: "100%",
      padding: "20px",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
  };
});

const Top5Tables = () => {
  const { container, tableContainer } = useStyle();

  return (
    <Box className={container}>
      <Box className={tableContainer}>
        <OnTimeTable />
      </Box>
      <Box className={tableContainer}>
        <MaxAttendanceTable />
      </Box>
      <Box className={tableContainer}>
        <WorkaholicTable />
      </Box>
    </Box>
  );
};

export default Top5Tables;
