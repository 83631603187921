import {
  Box,
  Button,
  Typography,
  TextField,
  FormControl,
  CircularProgress,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import { makeStyles } from "@mui/styles";
import theme from "../../theme";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AddLoansApi } from "../../Api/Loans/LoansApi";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { LoansAdded } from "../../Redux/Slice/Loans/LoansSlice";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(() => {
  return {
    container: {
      height: "100%",
      width: "100%",
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      width: "100%",
      minHeight: "calc(100vh - 215px)",
      maxWidth: "700px",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      textAlign: "left",
    },
  };
});

function LoansForm({ setShowForm }) {
  const { container, subContainer, formContainer } = useStyles();
  const cookies = new Cookies();
  const { setsnackBarData } = useContext(SnackBarContext);
  const dispatch = useDispatch();

  const initialValues = {
    amount: "",
    total_month: "",
    note: "",
  };
  const handleSave = async (values, setSubmitting, resetForm) => {
    const obj = { ...values, emp_id: cookies.get("user")?.Emp_id };

    const response = await AddLoansApi(obj);

    setsnackBarData(response.snackBarData);

    if (response.data.IsSuccess) {

      setShowForm(false);
      setSubmitting(false);
      resetForm();
      dispatch(LoansAdded(response.data.Employee_Loan));
    }
    setSubmitting(false);
  };
  const validationSchema = Yup.object().shape({
    amount: Yup.string().required("Please Enter Amount"),
    total_month: Yup.string().required("Please Enter months"),
    note: Yup.string().required("Please Enter note"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSave(values, resetForm, setSubmitting);
    },
  });

  return (
    <Box className={container}>
      <Box className={subContainer}>
        <form onSubmit={formik.handleSubmit}>
          <Box className={formContainer}>
            <FormControl fullWidth>
              <Typography>Amount</Typography>
              <TextField
                sx={{ textAlign: "left", input: { color: "black" } }}
                fullWidth
                size="small"
                name="amount"
                type="number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.amount}
                variant="outlined"
                error={
                  Boolean(formik.touched.amount) &&
                  Boolean(formik.errors.amount)
                }
                helperText={
                  Boolean(formik.touched.amount) && formik.errors.amount
                }
              />
            </FormControl>
            <FormControl fullWidth>
              <Typography>Total Months</Typography>
              <TextField
                sx={{ textAlign: "left", input: { color: "black" } }}
                fullWidth
                size="small"
                name="total_month"
                type="number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.total_month}
                variant="outlined"
                error={
                  Boolean(formik.touched.total_month) &&
                  Boolean(formik.errors.total_month)
                }
                helperText={
                  Boolean(formik.touched.total_month) &&
                  formik.errors.total_month
                }
              />
            </FormControl>
            <FormControl fullWidth>
              <Typography>Note</Typography>
              <TextField
                sx={{ textAlign: "left", input: { color: "black" } }}
                fullWidth
                size="small"
                name="note"
                type="text"
                multiline
                rows={4}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.note}
                variant="outlined"
                error={
                  Boolean(formik.touched.note) && Boolean(formik.errors.note)
                }
                helperText={Boolean(formik.touched.note) && formik.errors.note}
              />
            </FormControl>
            <Box sx={{ display: "flex", width: "100%", justifyContent: "end" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <Box>
                  <Button
                    sx={{ backgroundColor: "red !important" }}
                    variant="contained"
                    onClick={() => setShowForm(false)}
                  >
                    <Typography>Cancel</Typography>
                  </Button>
                </Box>
                <Box>
                  {formik.isSubmitting ? (
                    <CircularProgress />
                  ) : (
                    <Button type="submit" variant="contained">
                      <Typography>Save</Typography>
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default LoansForm;
LoansForm.propTypes = {
  setShowForm: PropTypes.any,
};
