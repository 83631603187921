import React, { useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  CircularProgress,
  // MenuItem,
  Autocomplete,
} from "@mui/material";
import PropTypes from "prop-types";
import theme from "../../theme";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
//changes
import {
  AddTodayAttendanceApi,
  UpdateTodayAttendanceApi,
} from "../../Api/TodayAttendance/TodayAttendanceAPis";
import {
  fetchTodayAttendancePopulate,
  gettodayattendanceDetails,
} from "../../Redux/Slice/TodayAttendance/TodayAttendanceSlice";
import { useState } from "react";
import moment from "moment";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => {
  return {
    Form_input: {
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,
    },
    form_main: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      minHeight: "calc(100vh - 173px)",
      padding: "60px 2%",
    },

    responsive: {
      maxWidth: "900px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },
    input_typography: {
      textAlign: "left",

      fontSize: `15px !important`,
      // opacity: "50%",
      marginTop: "10px !important",
      paddingRight: "20px !important",
    },
    btns: {
      width: "80px",
    },
    FieldInRow: {
      display: "flex",
      justifyContent: "space-between",
      gap: "25px",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },
    inputBox: {
      width: "100%",
    },
  };
});

const TodayAttendanceForm = ({
  ShowForm,
  setShowForm,
  editedRow,
  isEdited,
  setisEdited,
  seteditedRow,
}) => {
  const {
    responsive,
    input_typography,
    Form_input,
    btns,
    form_main,
    FieldInRow,
    inputBox,
  } = useStyles();

  console.log(editedRow);
  console.log(isEdited);
  const validateIsNotEdit = {
    emp_id: Yup.object().required("This field is required"),
    in_time: Yup.string().required("This field is required"),
  };
  const isEditValidate = {
    out_time: Yup.string().required("This field is required"),
  };

  const todayattendanceSchema = Yup.object(
    isEdited ? isEditValidate : validateIsNotEdit
  );

  const todayAttendanceData = {
    in_time: null,
    out_time: null,
    emp_id: null,
    attendance_id: null,
  };
  const formik = useFormik({
    initialValues: todayAttendanceData,
    validationSchema: todayattendanceSchema,
    onSubmit: (values) => {
      handelTodayAttendance(values);
    },
  });
  const navigate = useNavigate();

  const [getallemployees, setgetallemployees] = useState();
  const [text] = useState("");
  const dispatch = useDispatch();
  const { setsnackBarData } = useContext(SnackBarContext);
  const todayattendancedetails = useSelector(gettodayattendanceDetails);
  const { IsLogin } = useSelector(gettodayattendanceDetails);

  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: todayattendancedetails?.message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
    setgetallemployees(todayattendancedetails?.Employees);
  }, [todayattendancedetails, IsLogin]);
  console.log(getallemployees);
  useEffect(() => {
    if (editedRow !== null) {
      formik.handleChange({
        target: {
          name: "in_time",
          value:
            moment(editedRow?.started_time, "hh:mm a").format("hh:mm") || null,
        },
      });
      formik.handleChange({
        target: {
          name: "attendance_id",
          value: editedRow?.id || null,
        },
      });
      formik.handleChange({
        target: {
          name: "emp_id",
          value: editedRow?.emp_id || null,
        },
      });
      formik.handleChange({
        target: {
          name: "out_time",
          value: editedRow?.ended_time
            ? moment(editedRow?.ended_time, "hh:mm a").format("hh:mm")
            : editedRow?.ended_time || null,
        },
      });
    }
  }, [ShowForm]);

  useEffect(() => {
    dispatch(fetchTodayAttendancePopulate());
  }, [dispatch]);

  const handelTodayAttendance = async (values) => {
    values = { ...values, emp_id: values?.emp_id?.id };

    if (isEdited) {
      let response = await UpdateTodayAttendanceApi(values);
      setsnackBarData(response.snackBarData);
      if (response.data.IsSuccess) {
        formik.resetForm();
        setShowForm(false);
        setisEdited(false);
        seteditedRow(null);
      }
      seteditedRow(null);
    } else if (!isEdited) {
      let response = await AddTodayAttendanceApi(values);
      setsnackBarData(response.snackBarData);
      if (response.data.IsSuccess) {
        formik.resetForm();
        setShowForm(false);
      }
    }

    formik.setSubmitting(false);
  };

  return (
    <>
      <Box className={form_main}>
        <Box className={responsive}>
          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Box className={FieldInRow}>
                {isEdited === true ? (
                  <>
                    <Box className={inputBox}>
                      <Typography className={input_typography}>
                        Attendance
                      </Typography>
                      <FormControl fullWidth sx={{ textAlign: "left" }}>
                        <TextField
                          className={Form_input}
                          fullWidth
                          size="small"
                          name="attendance_id"
                          type="text"
                          variant="outlined"
                          disabled
                          value={formik.values?.attendance_id}
                          onChange={formik.handleChange}
                          error={
                            formik.touched?.attendance_id &&
                            Boolean(formik.errors?.attendance_id)
                          }
                          helperText={
                            formik.touched?.attendance_id &&
                            formik.errors?.attendance_id
                          }
                        />
                      </FormControl>
                    </Box>
                    {/* </Box> */}
                  </>
                ) : (
                  <>
                    {/* <Box> */}

                    <Box className={inputBox}>
                      <Typography className={input_typography}>
                        Employee Name
                      </Typography>
                      {/* <FormControl fullWidth sx={{ textAlign: "left" }}>
                        <TextField
                          className={Form_input}
                          fullWidth
                          size="small"
                          name="emp_id"
                          type="text"
                          select
                          value={formik.values?.emp_id}
                          onChange={(e) => {
                            settext(
                              e.target.value.time_zone
                                ? JSON.parse(e.target.value.time_zone).label
                                : ""
                            );
                            formik.handleChange(e);
                          }}
                    
                          error={
                            formik.touched?.emp_id &&
                            Boolean(formik.errors?.emp_id)
                          }
                          helperText={
                            Boolean(formik.touched.emp_id) && formik.errors.emp_id
                            ? Boolean(formik.touched.emp_id) && formik.errors.emp_id
                            : text
                          
                          }
                          variant="outlined"
                        >
                          <MenuItem value="">Select Employee</MenuItem>
                          {getallemployees?.map((employee) => {
                            return (
                              <MenuItem value={employee} key={employee?.id}>
                                {employee?.name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </FormControl> */}
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        name="emp_id"
                        options={getallemployees}
                        getOptionLabel={(option) => option.name}
                        className={Form_input}
                        fullWidth
                        // value={values.emp_id}
                        size="small"
                        onChange={(e, val) => {
                          console.log(e, val);
                          formik.handleChange({
                            target: {
                              name: "emp_id",
                              value: val,
                            },
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Employee"
                            name="emp_id"
                            error={
                              Boolean(formik.errors.emp_id) &&
                              Boolean(formik.touched.emp_id)
                            }
                            helperText={
                              Boolean(formik.touched.emp_id) &&
                              formik.errors.emp_id
                                ? Boolean(formik.touched.emp_id) &&
                                  formik.errors.emp_id
                                : text
                            }
                          />
                        )}
                      />
                    </Box>
                  </>
                )}

                <Box className={inputBox}>
                  <Typography className={input_typography}>Check In</Typography>
                  <FormControl fullWidth sx={{ textAlign: "left" }}>
                    <TextField
                      className={Form_input}
                      fullWidth
                      size="small"
                      name="in_time"
                      type="time"
                      variant="outlined"
                      disabled={isEdited && formik.values?.in_time}
                      value={formik.values?.in_time}
                      onChange={formik.handleChange}
                      error={formik.touched.in_time && formik.errors.in_time}
                      helperText={
                        formik.touched.in_time && formik.errors.in_time
                      }
                    />
                  </FormControl>
                </Box>
              </Box>

              <Box className={inputBox}>
                <Typography className={input_typography}>Check Out</Typography>
                <FormControl fullWidth sx={{ textAlign: "left" }}>
                  <TextField
                    className={Form_input}
                    fullWidth
                    size="small"
                    name="out_time"
                    type="time"
                    value={formik.values?.out_time}
                    onChange={formik.handleChange}
                    error={
                      formik.touched?.out_time &&
                      Boolean(formik.errors?.out_time)
                    }
                    helperText={
                      formik.touched?.out_time && formik.errors?.out_time
                    }
                  />
                </FormControl>
                {formik.values.out_time < formik.values.in_time ? (
                  <Typography color={"error"} sx={{ textAlign: "left" }}>
                    Checkout time cannot be less than Checkin Time
                  </Typography>
                ) : null}
              </Box>

              {/* {formik?.isSubmitting ? (
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <CircularProgress />
                </Box>
              ) : ( */}
              <Box
                sx={{
                  justifyContent: "flex-end",
                  display: "flex",
                  gap: "10px",
                  //   marginTop: "20px",
                }}
              >
                <>
                  <Button
                    className={btns}
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setShowForm(false);
                      setisEdited(false);
                      seteditedRow(null);
                    }}
                  >
                    Cancel
                  </Button>
                  {formik?.isSubmitting ? (
                    <CircularProgress />
                  ) : (
                    <>
                      {isEdited === false ? (
                        <Button
                          type="submit"
                          className={btns}
                          variant="contained"
                          color="primary"
                          disabled={
                            formik.values.out_time < formik.values.in_time
                          }
                        >
                          Save
                        </Button>
                      ) : (
                        ""
                      )}

                      {isEdited === true ? (
                        <Button
                          type="submit"
                          className={btns}
                          variant="contained"
                        >
                          Update
                        </Button>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </>
              </Box>
              {/* )} */}
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default TodayAttendanceForm;
TodayAttendanceForm.propTypes = {
  setShowForm: PropTypes.any,
  ShowForm: PropTypes.any,
  editedRow: PropTypes.any,
  isEdited: PropTypes.any,
  seteditedRow: PropTypes.any,
  setisEdited: PropTypes.any,
};
