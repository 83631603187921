import PropTypes from "prop-types";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  // gridClasses,
  InputAdornment,
  Link,
  // Link,
  MenuItem,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  // Tooltip,
  Typography,
  // Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import {
//   DataGrid,
//   GridToolbarColumnsButton,
//   GridToolbarContainer,
//   GridToolbarDensitySelector,
//   GridToolbarExport,
//   GridToolbarFilterButton,
// } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchLeaveApplication,
  fetchsingleEmpLeave,
  getleaveapplicationDetails,
  // removeLeaveApplicationDetail,
} from "../../Redux/Slice/LeaveApplication/LeaveApplicationSlice";
import { makeStyles } from "@mui/styles";
import { useRef } from "react";
import moment from "moment/moment";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { ApproveLeaveAPI } from "../../Api/LeaveApplication/ApproveLeaveStatusApi";
import Cookies from "universal-cookie";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../Button/AddNewButton";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import Loader from "../Loader/Loader";
import theme from "../../theme";
import axiosInstance from "../../Utils/AxiosInstance";
// import axios from "axios";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
    tablehead: {
      fontWeight: "bold",
      backgroundColor: "#53b7c5 !important",
      color: theme.palette.white.main,
      padding: "15px 20px",
      flex: 1,
    },
  };
});
const cookies = new Cookies();
function LeaveApplicationTable({ setShowForm }) {
  const { tabelStyle } = useContext(TabelStyleContext);
  const { tablehead } = useStyles();
  const dispatch = useDispatch();
  const NameFilter = useRef();
  const { setsnackBarData } = useContext(SnackBarContext);
  const [open, setOpen] = useState(false);
  const [EmpLeaveDetails, setEmpLeaveDetails] = React.useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [Leaveapplication, setLeaveapplication] = useState([]);
  const { leaveApplication, status, IsLogin, message } = useSelector(
    getleaveapplicationDetails
  );
  // const {singleEmpLeave  }=useSelector(getleaveapplicationDetails);
  const [leaveRowVal, setleaveRowVal] = useState({});
  const [countVal, setcountVal] = useState(null);
  const [selctedRow, setselctedRow] = useState(null);
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [rows, setrows] = useState([]);
  const [filterRows, setfilterRows] = useState([]);
  // const [pageSize, setPageSize] = useState(10);
  // const [setSelectedRow] = useState({});
  const [setshowModal] = useState(false);
  const [disabled, setdisabled] = useState(false);
  const navigate = useNavigate();
  const [setmessage] = useState("");
  const [setopenToast] = useState(false);
  const [setAlertType] = useState("success");
  const [leaveApprove, setleaveApprove] = useState({
    leave_id: "",
    approval_status: "",
    approval_count: "",
    approval_start_date: "",
  });

  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [editPermit, seteditpermit] = useState(false);
  // const [messages, setMessages] = useState([]);
  // const [conversations, setConversations] = useState([]);
  // const [participantsImages, setParticipantsImages] = useState({});
  // const [profilePhoto, setProfilePhoto] = useState(null);
  // console.log(conversations);
  // console.log(messages);
  // console.log(profilePhoto);
  // console.log(participantsImages);

  // useEffect(() => {
  //   const fetchProfilePhoto = async () => {
  //     try {
  //       const response = await axios.get(
  //         `https://graph.facebook.com/251536024705091?fields=picture`,
  //         {
  //           params: {
  //             access_token: "EAAafiGirpJQBOw0yXR8Crzo5m2lNoJuNumyWjZAnBsR3bh93tqQRA4Wm3xciTJb9OtuvZBi12qWaxTw0UaEPP1uvUoDnZAC7hD1keBuzOTGZCfwEtVSZCCGQyGukAqHkN0CnZCWzBYaax8IFiv9yJ581stE6hrke7ZBWP5zaSkVvzsgFz8CiFmOl5H2HAk3fv49Eh4fZBZAROgOzasLCnJCOOjpRxsZD"
  //           }
  //         }
  //       );

  //       setProfilePhoto(response.data.picture.data.url);
  //     } catch (err) {
  //       // setError(err.response.data.error.message);
  //     }
  //   };

  //   fetchProfilePhoto();
  // }, []);
  // const sendMessage = async () => {
  //   try {

  //     const response = await axios.post(
  //       `https://graph.facebook.com/v12.0/me/messages`,
  //       {
  //         messaging_type: 'RESPONSE', // or 'MESSAGE_TAG', depending on your use case
  //         recipient: {
  //           id: "25084484304500798"
  //         },
  //         message: {
  //           text: "ge rana sahb"
  //         }
  //       },
  //       {
  //         params: {
  //           access_token: "EAAafiGirpJQBOw0yXR8Crzo5m2lNoJuNumyWjZAnBsR3bh93tqQRA4Wm3xciTJb9OtuvZBi12qWaxTw0UaEPP1uvUoDnZAC7hD1keBuzOTGZCfwEtVSZCCGQyGukAqHkN0CnZCWzBYaax8IFiv9yJ581stE6hrke7ZBWP5zaSkVvzsgFz8CiFmOl5H2HAk3fv49Eh4fZBZAROgOzasLCnJCOOjpRxsZD"
  //         }
  //       }
  //     );

  //     console.log('Message sent:', response.data);
  //     // Optionally, update state or perform other actions upon successful message sending
  //   } catch (err) {
  //     // setError(err.response.data.error.message);
  //   }
  // };
  // const handlePostMessage = async (conversationId) => {
  //   try {
  //     // Make a POST request to the Page's feed endpoint
  //     const response = await fetch(
  //       `https://graph.facebook.com/v12.0/${conversationId}/messages?fields=id,name`,
  //       {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           message: "Acha ge",
  //           access_token: 'EAAafiGirpJQBOw0yXR8Crzo5m2lNoJuNumyWjZAnBsR3bh93tqQRA4Wm3xciTJb9OtuvZBi12qWaxTw0UaEPP1uvUoDnZAC7hD1keBuzOTGZCfwEtVSZCCGQyGukAqHkN0CnZCWzBYaax8IFiv9yJ581stE6hrke7ZBWP5zaSkVvzsgFz8CiFmOl5H2HAk3fv49Eh4fZBZAROgOzasLCnJCOOjpRxsZD'
  //         })
  //       }
  //     );
  //     const data = await response.json();
  //     console.log('Post successful:', data);
  //   } catch (error) {
  //     console.error('Error posting message:', error);
  //   }
  // };
  // useEffect(() => {
  //   const fetchConversations = async () => {
  //     try {
  //       // Fetching the conversations using Page Access Token
  //       const response = await fetch(
  //         `https://graph.facebook.com/v12.0/251536024705091/conversations?fields=id,picture,recipient,updated_time,participants,snippet,unread_count&access_token=EAAafiGirpJQBOwqcEMiZAZBRzud2DXWXxkAH1fusx0XcZBB37z7O0CaqR3we7I52oZC5RUlZARC2ircPKhDp28DBPRZA19uQnyaGAVL7ZAcKVQqfxr3i77MZACGzAWpIMonW49ljIFJs0xZAsQvYDBQOQGOFAgwY8NSDd2aZBAQwJJZCl6lU0XrYOmhdPIdF0aOllskEZBPbh2et97sCpKRZBgcuGxJUZD`
  //       );
  //       const data = await response.json();
  //       setConversations(data.data);
  //     } catch (error) {
  //       console.error('Error fetching conversations:', error);
  //     }
  //   };

  //   // Call fetchConversations when the component mounts
  //   fetchConversations();
  // }, []);
  // const fetchMessages = async (conversationId) => {
  //   try {
  //     // Fetching the messages for a specific conversation using Page Access Token
  //     const response = await fetch(
  //       `https://graph.facebook.com/v12.0/${conversationId}/messages?fields=id,message,created_time,from,to&access_token=EAAafiGirpJQBOwqcEMiZAZBRzud2DXWXxkAH1fusx0XcZBB37z7O0CaqR3we7I52oZC5RUlZARC2ircPKhDp28DBPRZA19uQnyaGAVL7ZAcKVQqfxr3i77MZACGzAWpIMonW49ljIFJs0xZAsQvYDBQOQGOFAgwY8NSDd2aZBAQwJJZCl6lU0XrYOmhdPIdF0aOllskEZBPbh2et97sCpKRZBgcuGxJUZD`
  //     );
  //     const data = await response.json();
  //     console.log(data); // Log the messages data to the console or handle it as needed
  //   } catch (error) {
  //     console.error('Error fetching messages:', error);
  //   }
  // };
  // useEffect(() => {
  //   // Function to fetch Facebook Page messages

  //   const fetchMessages = async () => {
  //     try {
  //       const response = await fetch(
  //         `https://graph.facebook.com/v12.0/251536024705091/conversations?access_token=EAAafiGirpJQBOw0yXR8Crzo5m2lNoJuNumyWjZAnBsR3bh93tqQRA4Wm3xciTJb9OtuvZBi12qWaxTw0UaEPP1uvUoDnZAC7hD1keBuzOTGZCfwEtVSZCCGQyGukAqHkN0CnZCWzBYaax8IFiv9yJ581stE6hrke7ZBWP5zaSkVvzsgFz8CiFmOl5H2HAk3fv49Eh4fZBZAROgOzasLCnJCOOjpRxsZD`
  //       );
  //       const data = await response.json();
  //       setMessages(data?.data);
  //     } catch (error) {
  //       console.error("Error fetching messages:", error);
  //     }
  //   };
  //   const fetchMessages2 = async () => {
  //     try {
  //       // Fetching the messages for a specific conversation using Page Access Token
  //       const response = await fetch(
  //         `https://graph.facebook.com/v12.0/t_1136100901169638/messages?access_token=EAAafiGirpJQBOw0yXR8Crzo5m2lNoJuNumyWjZAnBsR3bh93tqQRA4Wm3xciTJb9OtuvZBi12qWaxTw0UaEPP1uvUoDnZAC7hD1keBuzOTGZCfwEtVSZCCGQyGukAqHkN0CnZCWzBYaax8IFiv9yJ581stE6hrke7ZBWP5zaSkVvzsgFz8CiFmOl5H2HAk3fv49Eh4fZBZAROgOzasLCnJCOOjpRxsZD`
  //       );
  //       const data = await response.json();
  //       console.log(data?.data[0]);
  //       // Handle the fetched messages data as per your requirement
  //     } catch (error) {
  //       console.error('Error fetching messages:', error);
  //     }
  //   };
  //   // Call the fetchMessages function
  //   fetchMessages();
  //   fetchMessages2();
  // }, []);
  // console.log(crop);

  console.log(leaveRowVal);
  useEffect(() => {
    let string = "LeaveApplication";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Edit") {
        seteditpermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      seteditpermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  console.log(editPermit);

  const user = cookies.get("user");
  useEffect(() => {
    if (cookies.get("user")?.type === "Employee") {
      // dispatch(removeLeaveApplicationDetail())

      dispatch(fetchsingleEmpLeave(cookies.get("user").Emp_id));
    } else if (cookies.get("user")?.type === "Admin") {
      // dispatch(removeLeaveApplicationDetail())
      dispatch(fetchLeaveApplication());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
    if (leaveApplication) {
      setLeaveapplication(leaveApplication);
    }
  }, [leaveApplication, IsLogin]);

  // function CustomToolbar() {
  //   return (
  //     <GridToolbarContainer className={gridClasses.toolbarContainer}>
  //       <GridToolbarExport csvOptions={{ fileName: "Leave Application" }} />
  //       <GridToolbarFilterButton />
  //       <GridToolbarDensitySelector />
  //       <GridToolbarColumnsButton />
  //     </GridToolbarContainer>
  //   );
  // }
  // const columns = [
  //   { field: "id", headerName: "Sr #", headerClassName: header },
  //   {
  //     field: "empId",
  //     headerName: "Employee",
  //     width: 150,
  //     headerClassName: header,
  //   },
  //   {
  //     field: "leavetype",
  //     headerName: "Leave Type",
  //     width: 200,
  //     headerClassName: header,
  //   },

  //   {
  //     field: "startdate",
  //     headerName: "Start Date",
  //     width: 150,
  //     headerClassName: header,
  //   },
  //   {
  //     field: "enddate",
  //     headerName: "End Date",
  //     width: 150,
  //     headerClassName: header,
  //   },

  //   {
  //     field: "reasons",
  //     headerName: "Reason",
  //     width: 200,
  //     headerClassName: header,
  //   },

  //   {
  //     field: "Attachment",
  //     headerName: "Attachment",
  //     width: 200,
  //     headerClassName: header,
  //     renderCell: (celVal) => {
  //       return (
  //         <Link href={celVal.row?.Attachment} target={"_blank"}>
  //           {celVal.row?.Attachment}
  //         </Link>
  //       );
  //     },
  //   },
  //   {
  //     field: "requestedCount",
  //     headerName: "Requested Leaves",
  //     width: 200,
  //     headerClassName: header,
  //   },

  //   {
  //     field: "ApprovalCount",
  //     headerName: "Approval Leaves",
  //     width: 200,
  //     headerClassName: header,
  //   },
  //   {
  //     field: "Approved",
  //     headerName: "Status",
  //     width: 170,
  //     headerClassName: header,
  //     renderCell: (celVal) => {
  //       return (
  //         <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
  //           <Typography sx={{ width: "5rem" }}>
  //             {celVal.row?.Approved}
  //           </Typography>
  //           <Box
  //             sx={{
  //               width: "20px",
  //               height: "20px",
  //               backgroundColor:
  //                 celVal?.row?.Approved === "Rejected"
  //                   ? "red"
  //                   : celVal?.row?.Approved === "Accepted"
  //                   ? "green"
  //                   : celVal?.row?.Approved === "Pending"
  //                   ? "yellow"
  //                   : null,
  //               borderRadius: "100%",
  //             }}
  //           ></Box>
  //         </Box>
  //       );
  //     },
  //   },
  //   {
  //     field: "Update",
  //     headerName: "Actions",
  //     width: 200,
  //     headerClassName: header,
  //     renderCell: (celVal) => {
  //       if (
  //         celVal.row.Approved === "pending" ||
  //         celVal.row.Approved === "Pending"
  //       ) {
  //         return user?.type === "Admin" ? (
  //           editPermit ? (
  //             <Tooltip title="Approve Leave">
  //               <Button
  //               variant="contained"
  //                 sx={{
  //                   textTransform: "none",
  //                   // backgroundColor: "#3480FA",
  //                   color: "white !important",
  //                 }}
  //                 onClick={() => {
  //                   setleaveRowVal(celVal.row);
  //                   setdisabled(false);

  //                   setleaveApprove({
  //                     leave_id: "",
  //                     approval_status: "",
  //                     approval_count: 0,
  //                     approval_start_date: "",
  //                   });
  //                   setcountVal(
  //                     moment(celVal.row.enddate).format("DD") -
  //                       moment(celVal.row.startdate).format("DD") +
  //                       1
  //                   );
  //                   handleOpen();
  //                 }}
  //               >
  //                 Approve{" "}
  //               </Button>
  //             </Tooltip>
  //           ) : null
  //         ) : null;
  //       }
  //     },
  //   },
  // ];
  console.log("count  val ", countVal);
  const handleChangeVal = (evt) => {
    let value = evt.target.value;
    setdisabled(false);

    if (evt.target.name === "approval_count") {
      if (value > countVal) {
        value = countVal;
      }
    }
    if (evt.target.name === "approval_status" && value === "Rejected") {
      setleaveApprove({
        ...leaveApprove,
        [evt.target.name]: value,
        approval_count: 0,
      });
      setdisabled(true);
    } else {
      setdisabled(false);

      setleaveApprove({
        ...leaveApprove,
        [evt.target.name]: value,
      });
    }
  };
  const fetchEmpLeaveDetais = async () => {
    setloader2(true);
    try {
      const response = await axiosInstance.get(
        `empleaveapplications?/${selctedRow}`
      );
      if (response?.data?.IsSuccess === true) {
        setloader2(false);
        setEmpLeaveDetails(response?.data?.Leave_Applications);
      } else {
        setsnackBarData({
          type: "error",
          message: "Something went wrong",
          openToast: true,
        });
        setloader2(false);
      }
    } catch (error) {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
      setloader2(false);
      //   console.error("Error fetching data:", error);
    }
  };
  const handleApproveLeave = async () => {
    if (
      leaveApprove.approval_status === "" &&
      leaveApprove.approval_count === "" &&
      leaveApprove.approval_start_date
    ) {
      setsnackBarData({
        type: "error",
        message: "please add Required Data",
        openToast: "true",
      });
      return;
    }
    setloader(true);
    if (leaveApprove?.approval_status === "Accepted") {
      const obj = {
        leave_id: leaveRowVal?.leave_id,
        approval_status: leaveApprove?.approval_status,
        approval_count: leaveApprove?.approval_count,
        approval_start_date: leaveApprove?.approval_start_date,
      };
      const response = await ApproveLeaveAPI(obj);
      setsnackBarData(response.snackBarData);
      setloader(false);
      if (response.data.IsSuccess) {
        fetchEmpLeaveDetais(leaveRowVal?.emp_id);
        dispatch(fetchLeaveApplication());
        setloader(false);
        handleClose();
        // leaveApprove.approval_status = "",
        // leaveApprove.approval_count = "" ,
        // leaveApprove.approval_start_date ="",
        // leaveApprove.leave_id=""
      }
      setloader(false);
    } else if (leaveApprove?.approval_status === "Rejected") {
      const obj = {
        leave_id: leaveRowVal?.leave_id,
        approval_status: leaveApprove?.approval_status,
        approval_count: 0,
        approval_start_date: null,
      };
      const response = await ApproveLeaveAPI(obj);
      setsnackBarData(response.snackBarData);
      setloader(false);
      if (response.data.IsSuccess) {
        fetchEmpLeaveDetais(leaveRowVal?.emp_id);
        dispatch(fetchLeaveApplication());
        setloader(false);
        handleClose();
        (leaveApprove.approval_status = ""),
          (leaveApprove.approval_count = ""),
          (leaveApprove.approval_start_date = ""),
          (leaveApprove.leave_id = "");
      }
      setloader(false);
    }
  };
  useEffect(() => {
    let arr = [];
    // if (leaveapplications) {
    Leaveapplication &&
      Leaveapplication?.map((item, idx) => {
        return arr.push({
          ...item,
          id: idx + 1,
        });
      });
    setrows(arr);
    setfilterRows(arr);
  }, [Leaveapplication]);

  useEffect(() => {
    if (
      getleaveapplicationDetails?.status === "succeeded" ||
      getleaveapplicationDetails?.status === "failed"
    ) {
      // setloading(false);
      if (getleaveapplicationDetails?.isSuccess === true) {
        setAlertType("success");
        setmessage(getleaveapplicationDetails.message);
        setopenToast(true);
      }
      if (getleaveapplicationDetails?.isSuccess === false) {
        setAlertType("error");
        setmessage(
          getleaveapplicationDetails.message || getleaveapplicationDetails.error
        );
        setopenToast(true);
      }
    }
  }, [getleaveapplicationDetails]);

  const onTextChange = () => {
    let name = NameFilter.current.value;

    if (name === "") {
      setfilterRows(rows);
    } else {
      let suggestions = [];

      const regex = new RegExp(name, "i");

      suggestions = rows?.filter(
        (val) => name === "" || regex.test(val.emp_name)
      );

      setfilterRows(suggestions);
    }
  };
  const [openRowId, setOpenRowId] = useState(null);
  useEffect(() => {
    if (status === "failed") {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
    }
  }, [status]);
  return (
    <>
      {/* <div>
      <h1>Post Message to Facebook Page</h1>
      <textarea
        value={messages}
        onChange={(e) => setMessages(e.target.value)}
        placeholder="Enter your message"
      />
      <button onClick={handlePostMessage}>Post Message</button>
    </div>
       <div>
      <h1>Facebook Page Conversations</h1>
      <ul>
        {conversations.map((conversation, index) => (
          <li key={index}>
            <h2>Conversation ID: {conversation.id}</h2>
            <p>Link: {conversation.link}</p>
            <p>Updated Time: {conversation.updated_time}</p>
            <button onClick={() =>{
              // sendMessage(conversation.id)
              fetchMessages(conversation.id)
              // handlePostMessage(conversation.id)
              }}>Fetch Messages</button>
          </li>
        ))}
      </ul>
    </div> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          padding: "20px 10px 10px 10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {user?.type !== "Admin" ? (
            // <Button
            //   sx={{ padding: "5px 10px" }}
            //   onClick={() => {
            //     setShowForm(true);
            //   }}
            //   variant="contained"
            //   className="tabletopbutton1 text-right"
            // >
            //   New
            // </Button>
            <CustomButton
              Color={"primary"}
              Icon={<AddIcon />}
              onClick={() => {
                setShowForm(true);
              }}
              Label={"Add"}
            />
          ) : null}
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
          }}
        >
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: (theme) => theme.palette.grey.G2 }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              width: "100%",
              maxWidth: "180px",
            }}
            variant="standard"
            type="text"
            autoComplete="off"
            placeholder="Employee"
            className="input_border"
            inputRef={NameFilter}
            id=" EmployeeName"
            onChange={onTextChange}
          />
        </Box>
      </Box>
      {status === "loading" || loader2 ? (
        <>
          <Loader />
        </>
      ) : null}
      <Box sx={{ height: tabelStyle.height, padding: "10px" }}>
        <TableContainer
          component={Paper}
          sx={{
            width: "100%",
            maxWidth: "100%",
            height: "100%",
            overflowX: "scroll",
            margin: "0px auto",
          }}
        >
          <Table size="small" stickyHeader sx={{ overflowX: "scroll" }}>
            <TableHead
              sx={{
                width: "100%",
                padding: "0px 10px",
              }}
            >
              <TableRow sx={{ padding: "0px 20px", width: "100%" }}>
                <TableCell className={tablehead}>Sr# </TableCell>
                <TableCell className={tablehead}>Employee</TableCell>
                <TableCell className={tablehead}>Leave Application</TableCell>
                <TableCell className={tablehead}>
                  Available Annual Leave
                </TableCell>
                <TableCell className={tablehead}>
                  Available Casual Leave
                </TableCell>
                <TableCell className={tablehead} />
              </TableRow>
            </TableHead>
            <TableBody sx={{ width: "100%", overflowX: "scroll" }}>
              {filterRows &&
                filterRows.map((row, i) => (
                  <Row
                    key={i}
                    row={row}
                    setleaveRowVal={setleaveRowVal}
                    setloader2={setloader2}
                    setdisabled={setdisabled}
                    setleaveApprove={setleaveApprove}
                    sr={i + 1}
                    setshowModal={setshowModal}
                    handleOpen={handleOpen}
                    setcountVal={setcountVal}
                    EmpLeaveDetails={EmpLeaveDetails}
                    setEmpLeaveDetails={setEmpLeaveDetails}
                    setselctedRow={setselctedRow}
                    setOpenRowId={setOpenRowId}
                    openRowId={openRowId}
                    // setSelectedAttendance={setSelectedAttendance}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <DataGrid
          rows={filterRows}
          columns={columns}
          onRowClick={(e) => {
            setSelectedRow(e.row);
            setshowModal(true);
          }}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          pagination
          components={{
            Toolbar: CustomToolbar,
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        /> */}
      </Box>

      <Modal
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
        }}
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <Paper sx={{ width: "100%", maxWidth: "500px", padding: "10px" }}>
          {leaveApprove.approval_status === "" ? null : (
            <Typography sx={{ fontWeight: "bold" }}>
              You are {leaveApprove.approval_status} Leave Application of{" "}
              {leaveRowVal.empId}
            </Typography>
          )}

          <Typography sx={{ paddingTop: "10px" }}>Approval Status</Typography>

          <TextField
            name="approval_status"
            select
            value={leaveApprove.approval_status}
            type="text"
            size="small"
            sx={{ width: "100%" }}
            onChange={handleChangeVal}
          >
            <MenuItem value="">Select</MenuItem>
            <MenuItem value="Accepted">Accepted</MenuItem>
            <MenuItem value="Rejected">Rejected</MenuItem>
          </TextField>

          <Typography sx={{ paddingTop: "10px" }}>Approval Count</Typography>
          <TextField
            name="approval_count"
            type="number"
            value={leaveApprove.approval_count}
            sx={{ width: "100%" }}
            size="small"
            disabled={disabled}
            onChange={handleChangeVal}
          />
          <Typography sx={{ paddingTop: "10px" }}>Start Date</Typography>
          <TextField
            name="approval_start_date"
            type="date"
            disabled={disabled}
            inputProps={{
              min: moment().format("YYYY-MM-DD"),
            }}
            value={leaveApprove.approval_start_date}
            sx={{ width: "100%" }}
            size="small"
            onChange={handleChangeVal}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              paddingTop: "10px",
              gap: "10px",
            }}
          >
            <Button
              sx={{
                background: "red",
                color: "white !important",
                fontSize: "13px",
                textTransform: "none",
              }}
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            {loader ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                sx={{
                  // background: "#3480FA",
                  color: "white !important",
                  fontSize: "13px",
                  // margin: "0px auto",
                  textTransform: "none",
                }}
                onClick={() => {
                  handleApproveLeave();
                }}
              >
                Save
              </Button>
            )}
          </Box>
        </Paper>
      </Modal>
    </>
  );
}

export default LeaveApplicationTable;

LeaveApplicationTable.propTypes = {
  setShowForm: PropTypes.any,
  setempData: PropTypes.any,
  setisEdited: PropTypes.any,
};
// function Row(props) {
//   const {
//     row,
//     sr,
//     setloader2,
//     setleaveRowVal,
//     setdisabled,
//     setleaveApprove,
//     setcountVal,
//     handleOpen,
//     setEmpLeaveDetails,
//     EmpLeaveDetails,
//     setselctedRow,
//   } = props;
//   const [open, setOpen] = React.useState(false);
//   const { setsnackBarData } = useContext(SnackBarContext);
//   const fetchEmpLeaveDetais = async (emp_id) => {
//     setloader2(true);
//     try {
//       console.log("Fetching id", emp_id);
//       const response = await axiosInstance.get(
//         `/empleaveapplications/${emp_id}`
//       );
//       console.log("Response:", response.data);

//       if (response?.data?.IsSuccess === true) {
//         setOpen(!open);
//         setloader2(false);
//         setEmpLeaveDetails(response?.data?.Leave_Applications);
//       } else {
//         setsnackBarData({
//           type: "error",
//           message: "Something went wrong",
//           openToast: true,
//         });
//         setloader2(false);
//       }
//     } catch (error) {
//       setsnackBarData({
//         type: "error",
//         message: "Network Error",
//         openToast: true,
//       });
//       setloader2(false);
//       //   console.error("Error fetching data:", error);
//     }
//   };
//   return (
//     <>
//       <React.Fragment>
//         <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
//           <TableCell component="th" scope="row">
//             {sr}
//           </TableCell>

//           <TableCell>{row?.emp_name}</TableCell>
//           <TableCell>{row?.leave_applications}</TableCell>
//           <TableCell>{row?.available_AL}</TableCell>
//           <TableCell>{row?.available_CL}</TableCell>
//           <TableCell>
//             <IconButton
//               aria-label="expand row"
//               size="small"
//               onClick={() => {
//                 if (open) {
//                   setOpen(!open);

//                   return;
//                 } else {
//                   setselctedRow(row?.emp_id);
//                   fetchEmpLeaveDetais(row?.emp_id);
//                 }
//               }}
//             >
//               {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
//             </IconButton>
//           </TableCell>
//         </TableRow>
//         <TableRow>
//           <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
//             <Collapse in={open} timeout="auto" unmountOnExit>
//               <Box sx={{ margin: 1 }}>
//                 <Typography variant="h6" gutterBottom component="div">
//                   Applications Details
//                 </Typography>
//                 <Table size="small" aria-label="purchases">
//                   <TableHead>
//                     <TableRow>
//                       <TableCell sx={{ fontWeight: "bold" }}>
//                         Leave Type
//                       </TableCell>
//                       <TableCell sx={{ fontWeight: "bold" }}>
//                         Start Date
//                       </TableCell>
//                       <TableCell sx={{ fontWeight: "bold" }}>
//                         End Date
//                       </TableCell>
//                       <TableCell sx={{ fontWeight: "bold" }}>
//                         Attachment
//                       </TableCell>

//                       <TableCell sx={{ fontWeight: "bold" }} align="right">
//                         Reason
//                       </TableCell>
//                       <TableCell sx={{ fontWeight: "bold" }} align="right">
//                         Approval Count
//                       </TableCell>
//                       <TableCell sx={{ fontWeight: "bold" }} align="right">
//                         Approval Status
//                       </TableCell>
//                       <TableCell sx={{ fontWeight: "bold" }} align="right">
//                         Action
//                       </TableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     {EmpLeaveDetails?.map((log, i) => (
//                       <TableRow key={i}>
//                         <TableCell component="th" scope="row">
//                           {log?.leave_type}
//                         </TableCell>
//                         <TableCell>
//                           {log?.start_date
//                             ? moment(log?.start_date).format("DD-MMM-YYYY")
//                             : ""}
//                         </TableCell>
//                         <TableCell>
//                           {log?.end_date
//                             ? moment(log?.end_date).format("DD-MMM-YYYY")
//                             : ""}
//                         </TableCell>

//                         <TableCell sx={{ width: "200px" }} align="right">
//                           {/* {log?.attachment} */}
//                           <Link href={log?.attachment} target={"_blank"}>
//                             <Typography
//                               sx={{
//                                 width: "200px",
//                                 textOverflow: "ellipsis",
//                                 overflow: "hidden",
//                                 whiteSpace: "nowrap",
//                               }}
//                             >
//                               {log?.attachment}
//                             </Typography>
//                           </Link>
//                         </TableCell>
//                         <TableCell align="right">{log?.reason}</TableCell>
//                         <TableCell align="right">
//                           {log?.approval_count ? log?.approval_count : ""}
//                         </TableCell>
//                         <TableCell align="right">
//                           {log?.approval_status ? log?.approval_status : ""}
//                         </TableCell>
//                         <TableCell align="right">
//                           {
//                             log?.approval_status == "Pending" ? (
//                               // editPermit ? (
//                               <Tooltip title="Approve Leave">
//                                 <Button
//                                   variant="contained"
//                                   sx={{
//                                     textTransform: "none",
//                                     // backgroundColor: "#3480FA",
//                                     color: "white !important",
//                                   }}
//                                   onClick={() => {
//                                     setleaveRowVal(log);
//                                     setdisabled(false);

//                                     setleaveApprove({
//                                       leave_id: "",
//                                       approval_status: "",
//                                       approval_count: 0,
//                                       approval_start_date: "",
//                                     });
//                                     console.log("log", log);
//                                     if (log.count > 0) {
//                                       setcountVal(log.count);
//                                     } else {
//                                       setcountVal(1);
//                                     }

//                                     handleOpen();
//                                   }}
//                                 >
//                                   Approve{" "}
//                                 </Button>
//                               </Tooltip>
//                             ) : null
//                             // ) : null
//                           }
//                         </TableCell>
//                       </TableRow>
//                     ))}
//                   </TableBody>
//                 </Table>
//               </Box>
//             </Collapse>
//           </TableCell>
//         </TableRow>
//       </React.Fragment>
//     </>
//   );
// }

function Row(props) {
  const {
    row,
    sr,
    setloader2,
    setleaveRowVal,
    setdisabled,
    setleaveApprove,
    setcountVal,
    handleOpen,
    setEmpLeaveDetails,
    EmpLeaveDetails,
    setselctedRow,
    setOpenRowId,
    openRowId,
  } = props;

  const { setsnackBarData } = useContext(SnackBarContext);

  const fetchEmpLeaveDetails = async (emp_id) => {
    setloader2(true);
    try {
      console.log("Fetching id", emp_id);
      const response = await axiosInstance.get(
        `/empleaveapplications/${emp_id}`
      );
      console.log("Response:", response.data);
      if (response?.data?.IsSuccess === true) {
        setEmpLeaveDetails(response?.data?.Leave_Applications);
        setOpenRowId(emp_id);
        setloader2(false);
      } else {
        setsnackBarData({
          type: "error",
          message: "Something went wrong",
          openToast: true,
        });
        setloader2(false);
      }
    } catch (error) {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
      setloader2(false);
    }
  };

  const handleCloseRow = () => {
    setOpenRowId(null);
  };

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          {sr}
        </TableCell>
        <TableCell>{row?.emp_name}</TableCell>
        <TableCell>{row?.leave_applications}</TableCell>
        <TableCell>{row?.available_AL}</TableCell>
        <TableCell>{row?.available_CL}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              if (openRowId === row?.emp_id) {
                handleCloseRow();
              } else {
                setOpenRowId(row?.emp_id);
                fetchEmpLeaveDetails(row?.emp_id);
                setselctedRow(row?.emp_id);
              }
            }}
          >
            {openRowId === row?.emp_id ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={openRowId === row?.emp_id} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Applications Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Leave Type
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Start Date
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>End Date</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Attachment
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="right">
                      Reason
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="right">
                      Approval Count
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="right">
                      Approval Status
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="right">
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {EmpLeaveDetails?.map((log, i) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        {log?.leave_type}
                      </TableCell>
                      <TableCell>
                        {log?.start_date
                          ? moment(log?.start_date).format("DD-MMM-YYYY")
                          : ""}
                      </TableCell>
                      <TableCell>
                        {log?.end_date
                          ? moment(log?.end_date).format("DD-MMM-YYYY")
                          : ""}
                      </TableCell>
                      <TableCell sx={{ width: "200px" }} align="right">
                        <Link href={log?.attachment} target={"_blank"}>
                          <Typography
                            sx={{
                              width: "200px",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {log?.attachment}
                          </Typography>
                        </Link>
                      </TableCell>
                      <TableCell align="right">{log?.reason}</TableCell>
                      <TableCell align="right">
                        {log?.approval_count ? log?.approval_count : ""}
                      </TableCell>
                      <TableCell align="right">
                        {log?.approval_status ? log?.approval_status : ""}
                      </TableCell>
                      <TableCell align="right">
                        {log?.approval_status === "Pending" && (
                          <Tooltip title="Approve Leave">
                            <Button
                              variant="outlined"
                              sx={{
                                textTransform: "none",
                                color: "black !important",
                              }}
                              size="small"
                              onClick={() => {
                                setleaveRowVal(log);
                                setdisabled(false);
                                setleaveApprove({
                                  leave_id: "",
                                  approval_status: "",
                                  approval_count: 0,
                                  approval_start_date: "",
                                });
                                setcountVal(log.count > 0 ? log.count : 1);
                                handleOpen();
                              }}
                            >
                              Approve
                            </Button>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.any,
  sr: PropTypes.any,
  setshowModal: PropTypes.any,
  setSelectedAttendance: PropTypes.any,
  setloader2: PropTypes.any,
  setleaveRowVal: PropTypes.any,
  setdisabled: PropTypes.any,
  setleaveApprove: PropTypes.any,
  handleOpen: PropTypes.any,
  setcountVal: PropTypes.any,
  EmpLeaveDetails: PropTypes.any,
  setEmpLeaveDetails: PropTypes.any,
  setselctedRow: PropTypes.any,
  setOpenRowId: PropTypes.any,
  openRowId: PropTypes.any,
};
