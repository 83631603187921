import PropTypes from "prop-types";
import {
  Box,
  Grid,
  gridClasses,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useContext } from "react";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../../Button/AddNewButton";
import Loader from "../../Loader/Loader";
import { TabelStyleContext } from "../../../Context/TabelStyleContext/TabelContext";
import { HeaderContext } from "../../../Context/SnackBarContext/HeaderContext/HeaderContext";
import { TooltipContext } from "../../../Context/TooltipContext/TooltipContent";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import axiosInstance from "../../../Utils/AxiosInstance";
import DeleteIcon from "@mui/icons-material/Delete";
import RoleDeleteModal from "../../DeleteModal/RoleDeleteModal";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});

function EmailTable({
  setShowForm,
  setempData,
  setisEdited,
  pageSize,
  setPageSize,
  page,
  setPage,
  setShowDetails,
}) {
  const { tabelStyle } = useContext(TabelStyleContext);

  const { header } = useStyles();
  const { setheader } = useContext(HeaderContext);
  const [rows, setrows] = useState([]);
  const [filterRows, setfilterRows] = useState([]);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const { settooltipData } = useContext(TooltipContext);
  const [EditedData, setEditedData] = useState(null);
  const [loading, setloading] = useState(false);
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "Emails" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }

  const columns = [
    { field: "index", headerName: "Sr #", headerClassName: header },
    {
      field: "title",
      headerName: "Title",
      width: 180,
      headerClassName: header,
    },

    // {
    //   field: "cnic",
    //   headerName: "CNIC #",
    //   width: 200,
    //   headerClassName: header,
    // },

    {
      field: "subject",
      headerName: "Subject",
      width: 200,
      headerClassName: header,
    },

    // { field: "dob", headerName: "DOB", width: 180, headerClassName: header },

    {
      field: "type",
      headerName: "Type",
      width: 200,
      headerClassName: header,
    },
    // {
    //   field: "designation",
    //   headerName: "Designation",
    //   width: 200,
    //   headerClassName: header,
    // },
    // {
    //   field: "technology",
    //   headerName: "Technology",
    //   width: 200,
    //   headerClassName: header,
    // },
    // {
    //   field: "expertie",
    //   headerName: "Experties",
    //   width: 200,
    //   headerClassName: header,
    // },
    // {
    //   field: "shiftrules",
    //   headerName: "Shift",
    //   width: 180,
    //   headerClassName: header,
    // },
    // {
    //   field: "time_zone",
    //   headerName: "Time Zone",
    //   width: 250,
    //   headerClassName: header,
    // },
    // {
    //   field: "portal_status",
    //   headerName: "Portal Status",
    //   width: 120,
    //   headerClassName: header,
    //   renderCell: (cell) => {
    //     // console.log(cell);
    //     return (
    //       <>
    //         {" "}
    //         <Box sx={{ display: "flex", gap: "25px", alignItems: "center" }}>
    //           {cell.row.portal_status ? (
    //             <CheckCircleIcon sx={{ cursor: "pointer", color: "green" }} />
    //           ) : (
    //             <CancelIcon sx={{ cursor: "pointer", color: "red" }} />
    //           )}
    //         </Box>
    //       </>
    //     );
    //   },
    // },
    {
      field: "Actions",
      headerName: "Actions",
      width: 160,
      headerClassName: header,
      renderCell: (celval) => {
        return (
          <>
            {" "}
            <Box sx={{ display: "flex", gap: "25px", alignItems: "center" }}>
              <Tooltip title="Update Email Template">
                <AppRegistrationIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setempData(celval.row);
                    setisEdited(true);
                    setShowForm(true);
                  }}
                />
              </Tooltip>
              <Tooltip title="Delete Email Template">
                <DeleteIcon
                  sx={{ cursor: "pointer" }}
                  onClick={async () => {
                    setEditedData(celval.row);
                    setshowDeleteModal(true);
                  }}
                />
              </Tooltip>{" "}
              <Box
                sx={{
                  display: "flex",
                  gap: "25px",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Tooltip title="Details">
                  <InfoIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      console.log(celval);
                      // navigate("/employeesDetails", { state: celval.row });
                      setShowDetails(true);
                      setempData(celval.row);
                    }}
                  />
                </Tooltip>
              </Box>
            </Box>
          </>
        );
      },
    },
  ];

  const dataInTable = async () => {
    setloading(true);
    let resp = await axiosInstance.get("/getAllEmailTemplates");
    if (resp.data.Data?.length) {
      console.log(resp);
      const formattedData = resp.data?.Data?.map((email, index) => ({
        index: index + 1,
        id: email.email_id,
        title: email.title,
        subject: email.subject,
        type: email.type,
        image: email.images,
        body: email.body,
      }));
      setrows(formattedData);
      setfilterRows(formattedData);
      setloading(false);
    } else {
      setloading(false);
    }
  };
  useEffect(() => {
    dataInTable();
  }, []);

  const onTextChange = () => {
    let name = document.getElementById("employeeName").value;
    let dept = document.getElementById("employeeDept").value;

    if (name == "" && dept == "") {
      setfilterRows(rows);
    } else {
      let suggestions = [];
      const nameRegix = new RegExp(name, "i");
      const deptRegix = new RegExp(dept, "i");

      suggestions = rows.filter(
        (val) =>
          (name === "" || nameRegix.test(val?.title)) &&
          (dept === "" || deptRegix.test(val?.subject))
      );

      setfilterRows(suggestions);
    }
  };

  const handelDelete = async () => {
    setloading(true);
    let resp = await axiosInstance.delete(
      "/deleteEmailTemplate/" + EditedData?.id
    );
    if (resp.data.IsSuccess) {
      let snackBarData = {
        type: resp?.data.IsSuccess ? "success" : "error",
        message: resp.data.Message,
        openToast: true,
      };

      setsnackBarData(snackBarData);
      dataInTable();
    }

    setshowDeleteModal(false);
    setloading(false);
  };
  return (
    <Grid
      container
      spacing={2}
      sx={{ padding: "15px 10px 6px 10px", backgroundColor: "white" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          flexWrap: "wrap",
          alignItems: "center",
          padding: "8px 7px 7px 17px",
        }}
      >
        <>
          <CustomButton
            Color={"primary"}
            Icon={<AddIcon />}
            onClick={() => {
              setheader("Employee And Education Information");
              settooltipData("fdsfsdfsdfsdf");
              setShowForm(true);
            }}
            Label={"Add"}
          />
        </>

        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "flex-end",
          }}
        >
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: (theme) => theme.palette.grey.G2 }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              width: "100%",
              maxWidth: "180px",
            }}
            variant="standard"
            type="text"
            autoComplete="off"
            placeholder="Title"
            id="employeeName"
            onChange={onTextChange}
          />
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: (theme) => theme.palette.grey.G2 }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              width: "100%",
              maxWidth: "200px",
            }}
            variant="standard"
            type="text"
            autoComplete="off"
            placeholder="Subject"
            id="employeeDept"
            onChange={onTextChange}
          />
        </Box>
      </Box>
      {loading ? (
        <>
          <Loader />
        </>
      ) : null}
      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        overflow
        sx={{ height: tabelStyle.height, overflowX: "visible" }}
      >
        <DataGrid
          style={{ overflow: "visible" }}
          disableColumnMenu
          scrollbarSize={50}
          // hideFooterSelectedRowCount={true}
          rows={filterRows}
          columns={columns}
          // rowCount={totalCount}
          getRowId={(row) => row.index}
          pageSize={pageSize}
          page={page}
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          onPageSizeChange={(newPageSize) => {
            setPage(1);

            setPageSize(newPageSize);
          }}
          rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          pagination
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{
            toolbar: { csvOptions: { fileName: "test" } },
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />
      </Grid>
      <RoleDeleteModal
        name={`"${EditedData?.title}"`}
        handelDelete={handelDelete}
        showModal={showDeleteModal}
        setshowModal={setshowDeleteModal}
        loading={loading}
      />
    </Grid>
  );
}

export default EmailTable;

EmailTable.propTypes = {
  setShowForm: PropTypes.any,
  setempData: PropTypes.any,
  setisEdited: PropTypes.any,
  employeeDetails: PropTypes.any,
  IsLogin: PropTypes.any,
  pageSize: PropTypes.any,
  setPageSize: PropTypes.any,
  page: PropTypes.any,
  setPage: PropTypes.any,
  setShowDetails: PropTypes.any,
};
