import PropTypes from "prop-types";
import {
  Box,
  Button,
  Grid,
  gridClasses,
  InputAdornment,
  Modal,
  Paper,
  TextField,
  Tooltip,
  Typography,
  MenuItem,
  CircularProgress,
} from "@mui/material";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";

import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";

// import { useNavigate } from "react-router-dom";

import { useContext } from "react";
// import AutorenewIcon from "@mui/icons-material/Autorenew";
import InfoIcon from "@mui/icons-material/Info";
// import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { makeStyles } from "@mui/styles";

import Loader from "../../Loader/Loader";
import { TabelStyleContext } from "../../../Context/TabelStyleContext/TabelContext";

// import { fetchEmployee } from "../../../Redux/Slice/Employee/EmplyeeSlice";
// import { TooltipContext } from "../../../Context/TooltipContext/TooltipContent";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
// import { logout } from "../../../Redux/Slice/LogoutSlice/LogoutSlice";

import { getallSessionData } from "../../../Redux/Slice/Applicants/ApplicantSlice";

import { updateApplicantSessionApi } from "../../../Api/Applicants/ApplicantAPI";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import axiosInstance from "../../../Utils/AxiosInstance";
const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
    btns: {
      width: "80px",
    },
  };
});

function ApplicantSessionTable({
  // setShowForm,
  setempData,
  // setisEdited,
  // employeeDetails,
  // IsLogin,
  pageSize,
  setPageSize,
  page,
  setPage,
  setShowDetails,
}) {
  const { tabelStyle } = useContext(TabelStyleContext);

  const { header, btns } = useStyles();
  const dispatch = useDispatch();
  // const [AllEmployees, setAllEmployees] = useState([]);

  // const [rows, setrows] = useState([]);
  const [filterRows, setfilterRows] = useState([]);
  // const [totalCount, settotalCount] = useState(0);
  // const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    edit = {};
    setEdit(edit);
  };
  const { setsnackBarData } = useContext(SnackBarContext);
  const [loader, setloader] = useState(false);

  // const { settooltipData } = useContext(TooltipContext);
  const [session, setSession] = useState([]);
  let [statee, setstatee] = useState([]);
  let [edit, setEdit] = useState({});
  const [editData, setEditData] = useState({});
  let [sessionValue, setSessionValue] = useState("");

  const { applicants ,status} = useSelector((state) => state.applicants);
  console.log(applicants);

  const getallSession = async () => {
    let resp = await axiosInstance.get("getAllHiringSessions");
    let data = resp.data.Data.map((item) => {
      return item.title;
    });

    setSession([...new Set(data)]);
  };

  useEffect(() => {
    dispatch(getallSessionData());
  }, [dispatch]);

  useEffect(() => {
    if (applicants) {
      const formattedData = statee?.map((applicant, index) => ({
        index: index + 1,
        id: applicant?.applicantsectoin_id,
        name: applicant?.applicant?.name,
        email: applicant?.applicant?.email,
        city: applicant?.applicant?.city,
        phone: applicant?.applicant?.contact_number,
        degree: applicant?.applicant?.degree,
        university: applicant?.applicant?.university,
        status: applicant?.status,
        comment: applicant?.applicant?.comment,
        cvLink: applicant?.applicant?.cv_link,
        hiringSession_title: applicant?.hiringSection?.title,
        stage: applicant?.hiringStage?.title || "NA",
        // Provide a default value if shift_rules or shift_name is undefined
        // Map other fields as needed based on your data structure
      }));
      // setrows(formattedData);
      setfilterRows(formattedData);
    }
  }, [statee]);
  useEffect(() => {
    onTextChange();
  }, [applicants]);

  useEffect(() => {
    getallSession();
  }, []);
  // console.log(crop);

  // console.log(page);

  // console.log(editPermit);
  // console.log(addPermit);

  // useEffect(() => {
  //   if (!IsLogin) {
  //     setsnackBarData({
  //       type: "error",
  //       message: employeeDetails?.message,
  //       openToast: true,
  //     });
  //     dispatch(logout());
  //     return navigate("/");
  //   }
  //   setAllEmployees(employeeDetails?.employee);
  //   setPage(employeeDetails?.PageNum);
  //   setPageSize(employeeDetails?.PageSize);
  //   settotalCount(employeeDetails?.TotalCount);
  // }, [employeeDetails, IsLogin]);
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "ApplicantSession" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }

  const columns = [
    {
      field: "index",
      headerName: "Sr #",
      headerClassName: header,
    },
    {
      field: "name",
      headerName: "Name",
      width: 180,
      headerClassName: header,
    },

    // {
    //   field: "cnic",
    //   headerName: "CNIC #",
    //   width: 200,
    //   headerClassName: header,
    // },

    {
      field: "phone",
      headerName: "Phone",
      width: 200,
      headerClassName: header,
    },

    // { field: "dob", headerName: "DOB", width: 180, headerClassName: header },

    // {
    //   field: "degree",
    //   headerName: "Degree",
    //   width: 200,
    //   headerClassName: header,
    // },
    // {
    //   field: "university",
    //   headerName: "University",
    //   width: 200,
    //   headerClassName: header,
    // },
    {
      field: "city",
      headerName: "City",
      width: 200,
      headerClassName: header,
    },
    {
      field: "hiringSession_title",
      headerName: "Hiring Session",
      width: 200,
      headerClassName: header,
    },
    {
      field: "stage",
      headerName: "Stage",
      width: 200,
      headerClassName: header,
      renderCell: (cell) => {
        // console.log(cell);
        return (
          <>
            {" "}
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
              //  onClick={()=>dispatch(deleData())}
            >
              {cell.formattedValue}
              {cell.row.status == "Shortlisted" ? (
                <AppRegistrationIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    if (cell.row.status == "Shortlisted") {
                      setOpen(true);
                      let newEdit = applicants.find((item) => {
                        return item.applicantsectoin_id == cell.row.id;
                      });
                      setEdit(newEdit);
                    }
                  }}
                />
              ) : null}

              {console.log(cell)}
            </Box>
          </>
        );
      },
    },

    // {
    //   field: "time_zone",
    //   headerName: "Time Zone",
    //   width: 250,
    //   headerClassName: header,
    // },
    {
      field: "status",
      headerName: "Status",
      width: 160,
      headerClassName: header,
      renderCell: (cell) => {
        // console.log(cell);
        return (
          <>
            {" "}
            <Box
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
              //  onClick={()=>dispatch(deleData())}
            >
              {cell.formattedValue}
              <AppRegistrationIcon
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setOpen(true);
                  setEdit(cell.row.id);
                }}
              />

              {console.log(cell.formattedValue)}
            </Box>
          </>
        );
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 120,
      headerClassName: header,
      renderCell: (celval) => {
        return (
          <>
            {" "}
            <Box
              sx={{
                display: "flex",
                gap: "25px",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Tooltip title="Details">
                <InfoIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    console.log(celval);
                    // navigate("/employeesDetails", { state: celval.row });
                    setShowDetails(true);
                    setempData(celval.row);
                  }}
                />
              </Tooltip>
            </Box>
          </>
        );
      },
    },
  ];

  const onTextChange = () => {
    let name = document.getElementById("employeeName").value;

    // let session = statee
    if (name == "" && sessionValue == "") {
      // setfilterRows(rows);
    } else {
      let suggestions = [];
      const nameRegix = new RegExp(name, "i");
      // const deptRegix = new RegExp(dept, "i");
      // const desigRegix = new RegExp(desig, "i");
      const sessionRegix = new RegExp(sessionValue, "i");
      suggestions = applicants?.filter(
        (val) =>
          (name === "" || nameRegix.test(val?.applicant?.name)) &&
          // (dept === "" || deptRegix.test(val?.degree)) &&
          // (desig === "" || desigRegix.test(val?.university))&&
          (sessionValue === "" || sessionRegix.test(val?.hiringSection?.title))
      );

      // setfilterRows(suggestions);
      setstatee(suggestions);
    }
  };
  // useEffect(() => {
  //   if (employeeDetails.status === "failed") {
  //     setsnackBarData({
  //       type: "error",
  //       message: employeeDetails.error,
  //       openToast: true,
  //     });
  //   }
  // }, [employeeDetails.status]);
  return (
    <Grid
      container
      spacing={2}
      sx={{ padding: "15px 10px 6px 10px", backgroundColor: "white" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          flexWrap: "wrap",
          alignItems: "center",
          padding: "8px 7px 7px 17px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",

            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              width: "100%",
            }}
          >
            <TextField
              id="session"
              size="small"
              type=""
              select
              variant="outlined"
              color="primary"
              fullWidth
              sx={{
                input: { color: "black" },
                maxWidth: "250px",
                textAlign: "left",
              }}
              // value={selectedEmployeeId}
              onChange={(e) => {
                sessionValue = e.target.value;
                setSessionValue(sessionValue);
              }}
              InputProps={{
                style: { borderRadius: 8 },
              }}
              className="for-dark-input"
            >
              {session?.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              ))}
            </TextField>

            <Button
              onClick={onTextChange}
              sx={{ padding: "5px 10px" }}
              variant="contained"
            >
              Get
            </Button>
          </Box>

          <Box sx={{ display: "flex", gap: 2 }}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{ color: (theme) => theme.palette.grey.G2 }}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{
                width: "100%",
                maxWidth: "180px",
              }}
              variant="standard"
              type="text"
              autoComplete="off"
              placeholder="Name"
              id="employeeName"
              onChange={onTextChange}
            />
          </Box>
        </Box>
      </Box>
      {  status== "loading" ? (
        <>
          <Loader />
        </>
      ) : null}
      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        overflow
        sx={{ height: tabelStyle.height, overflowX: "visible" }}
      >
        <DataGrid
          style={{ overflow: "visible" }}
          disableColumnMenu
          scrollbarSize={50}
          // hideFooterSelectedRowCount={true}
          rows={filterRows}
          columns={columns}
          getRowId={(row) => row.index}
          // rowCount={totalCount}
          pageSize={pageSize}
          page={page}
          onPageChange={(newPage) => {
            setPage(newPage );
            // if (
            //   Number(employeeDetails?.PageNum) < newPage + 1 &&
            //   AllEmployees?.length < pageSize * Number(newPage + 1)
            // ) {
            //   dispatch(fetchEmployee({ page: newPage, pageSize: pageSize }));
            // }
            // else if(allChangeData?.length < pageSize*(Number(newPage+1))){
            //   dispatch(fetchChangeRequest({page:newPage,pageSize:pageSize}))

            // }
          }}
          onPageSizeChange={(newPageSize) => {
            setPageSize(newPageSize);

            // if (AllEmployees?.length >= newPageSize) {
            //   setPage(1);

            //   setPageSize(newPageSize);
            // } else if (newPageSize >= AllEmployees?.length) {
            //   // dispatch(RemoveChangeRequest())
            //   setPage(1);

            //   dispatch(
            //     fetchEmployee({ page: Number(0), pageSize: newPageSize })
            //   );
            // }
          }}
          rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          pagination
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{
            toolbar: { csvOptions: { fileName: "test" } },
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />
      </Grid>

      <Modal
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
        }}
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <Paper sx={{ width: "100%", maxWidth: "500px", padding: "10px" }}>
          {edit?.hiringSection?.stage_id ? (
            <Box>
              <h3 style={{ textAlign: "center" }}>Change Stage</h3>
              <Typography sx={{ textAlign: "left" }}>Stages</Typography>

              <TextField
                fullWidth
                sx={{
                  textAlign: "left",
                  input: { color: "black" },
                }}
                size="small"
                select
                onChange={(e) =>
                  setEditData({
                    id: edit.applicantsectoin_id,
                    stage: true,
                    data: e.target.value,
                    status: false,
                  })
                }
              >
                {console.log(edit)}
                {edit?.hiringSection?.stage_id.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.stage_id}>
                      {item?.title}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Box>
          ) : (
            <Box>
              <h3 style={{ textAlign: "center" }}>Change Status</h3>
              <Typography sx={{ textAlign: "left" }}>Status</Typography>

              <TextField
                fullWidth
                sx={{
                  textAlign: "left",
                  input: { color: "black" },
                }}
                size="small"
                select
                onChange={(e) =>
                  setEditData({
                    id: edit,
                    stage: false,
                    data: e.target.value,
                    status: true,
                  })
                }
              >
                <MenuItem value={"Pending"}>Pending</MenuItem>
                <MenuItem value={"Shortlisted"}>Shortlisted</MenuItem>
                <MenuItem value={"Selected"}>Selected</MenuItem>
                <MenuItem value={"Rejected"}>Rejected</MenuItem>
              </TextField>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              paddingTop: "10px",
              gap: "10px",
            }}
          >
            <Button
              className={btns}
              variant="contained"
              color="error"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>

            {loader ? (
              <CircularProgress />
            ) : (
              <Button
              className={btns}
              type="button"
              variant="contained"
              color="primary"
              onClick={async () => {
                console.log(editData);
                setloader(true)
                const response = await updateApplicantSessionApi(editData);
                setOpen(false);
                edit = {};
                setEdit(edit);
                setsnackBarData(response.snackBarData);
                console.log(response);
                if (response.data.IsSuccess) {
                setloader(false)
                  dispatch(getallSessionData());
                }else{
                setloader(false)
                }
              }}
            >
              Update
            </Button>
            )}

    
          </Box>
        </Paper>
      </Modal>
    </Grid>
  );
}

export default ApplicantSessionTable;

ApplicantSessionTable.propTypes = {
  setShowForm: PropTypes.any,
  setempData: PropTypes.any,
  setisEdited: PropTypes.any,
  employeeDetails: PropTypes.any,
  IsLogin: PropTypes.any,
  pageSize: PropTypes.any,
  setPageSize: PropTypes.any,
  page: PropTypes.any,
  setPage: PropTypes.any,
  setShowDetails: PropTypes.any,
};
