import PropTypes from "prop-types";
import {
  Box,
  // Button,
  CircularProgress,
  gridClasses,
  InputAdornment,
  IconButton,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import DeleteModal from "../DeleteModal/DeleteModal";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import theme from "../../theme";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAllowance,
  fetchallowance,
  getallowanceDetails,
} from "../../Redux/Slice/Allowance/AllowanceSlice";
import { makeStyles } from "@mui/styles";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { DeleteAllowanceApi } from "../../Api/Allowance/AllowanceApis";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../Button/AddNewButton";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});
function AllowancesTable({
  setShowForm,
  EditedData,
  setIsEdit,
  setEditedData,
}) {
  const { header } = useStyles();
  const { tabelStyle } = useContext(TabelStyleContext);

  const dispatch = useDispatch();
  const [rows, setrows] = useState([]);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [loading, setloading] = useState(false);
  const [filterRows, setfilterRows] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { allowance, IsLogin, message, status } =
    useSelector(getallowanceDetails);
  // const allowance=useSelector(getallowanceDetails)
  console.log(allowance);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [editPermit, seteditpermit] = useState(false);
  const [addPermit, setaddpermit] = useState(false);
  const [deletePermit, setdeletepermit] = useState(false);
  // console.log(crop);

  console.log(roles);
  useEffect(() => {
    let string = "Allowances";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Edit") {
        seteditpermit(true);
      } else if (val?.module?.split("_")[1] === "Add") {
        setaddpermit(true);
      } else if (val?.module?.split("_")[1] === "Delete") {
        setdeletepermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      setaddpermit(true);
      seteditpermit(true);
      setdeletepermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  console.log(editPermit);
  console.log(addPermit);
  console.log(deletePermit);

  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  useEffect(() => {
    dispatch(fetchallowance());
  }, [dispatch]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "Allowances" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "sr", headerName: "Sr #", width: 150, headerClassName: header },
    {
      field: "allowance_type",
      headerName: "Allowance Type",
      width: 250,
      headerClassName: header,
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 180,
      headerClassName: header,
      renderCell: (celVal) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              gap: "10px",
            }}
          >
            {editPermit ? (
              <IconButton
                size={"small"}
                onClick={() => {
                  setEditedData(celVal.row);
                  setIsEdit(true);
                  setShowForm(true);
                }}
              >
                <AppRegistrationIcon
                  sx={{ cursor: "pointer", color: "black !important" }}
                />
              </IconButton>
            ) : null}
            {deletePermit ? (
              <IconButton
                onClick={() => {
                  setEditedData(celVal.row);
                  setshowDeleteModal(true);
                }}
                size={"small"}
              >
                <DeleteOutlineIcon sx={{ color: theme.palette.error.main }} />
              </IconButton>
            ) : null}
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    let arr = [];
    if (allowance) {
      allowance?.forEach((item, idx) => {
        return arr.push({
          ...item,
          sr: idx + 1,
          id: item?.allowance_id,
        });
      });
    }
    setrows(arr);
    setfilterRows(arr);
  }, [allowance]);

  const onTextChange = () => {
    if (document.getElementById("OrderNoFilter").value === "") {
      setfilterRows(rows);
    } else {
      let suggestions = [];

      var regex1 = new RegExp(
        document.getElementById("OrderNoFilter").value,
        "i"
      );

      suggestions = rows?.filter(
        (val) =>
          document.getElementById("OrderNoFilter").value === "" ||
          regex1.test(val.allowance_type)
      );

      setfilterRows(suggestions);
    }
  };
  const handelDelete = async () => {
    setloading(true);
    const response = await DeleteAllowanceApi(EditedData?.allowance_id);
    setsnackBarData(response.snackBarData);
    setloading(false);
    setshowDeleteModal(false);
    if (response.data?.IsSuccess) {
      dispatch(deleteAllowance(EditedData?.allowance_id));
    }
  };
  useEffect(() => {
    if (status === "failed") {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
    }
  }, [status]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          padding: "20px 10px 10px 10px",
        }}
      >
        <Box>
          {addPermit ? (
            <>
              {/* <Button
                sx={{ padding: "5px 10px" }}
                onClick={() => {
                  setShowForm(true);
                }}
                variant="contained"
                className="tabletopbutton1 text-right"
              >
                New
              </Button> */}
              <CustomButton
                Color={"primary"}
                Icon={<AddIcon />}
                onClick={() => {
                  setShowForm(true);
                }}
                Label={"Add"}
              />
            </>
          ) : null}
        </Box>
        <Box>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: (theme) => theme.palette.grey.G2 }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              width: "100%",
              maxWidth: "180px",
            }}
            variant="standard"
            type="text"
            autoComplete="off"
            placeholder="Allowance Type"
            className="input_border"
            id="OrderNoFilter"
            onChange={onTextChange}
          />
        </Box>
      </Box>

      <Box sx={{ height: tabelStyle.height, padding: "10px" }}>
        {allowance?.length <= 0 && status === "loading" ? (
          <Box
            sx={{
              height: "calc(100vh - 235px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <DataGrid
            rows={filterRows}
            columns={columns}
            onRowClick={() => {}}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            pagination
            components={{
              Toolbar: CustomToolbar,
            }}
            localeText={{
              toolbarDensity: "Size",
              toolbarDensityLabel: "Size",
              toolbarDensityCompact: "Small",
              toolbarDensityStandard: "Medium",
              toolbarDensityComfortable: "Large",
            }}
          />
        )}
        <DeleteModal
          name={`"${EditedData?.allowance_type}" Allowance`}
          handelDelete={handelDelete}
          showModal={showDeleteModal}
          setshowModal={setshowDeleteModal}
          loading={loading}
        />
      </Box>
    </>
  );
}

export default AllowancesTable;
AllowancesTable.propTypes = {
  setShowForm: PropTypes.any,
  setIsEdit: PropTypes.func,
  setEditedData: PropTypes.func,
  EditedData: PropTypes.any,
};
