
import axiosInstance from "../../Utils/AxiosInstance";


export const AddAllowanceApi = async (data) => {
  let snackBarData = {};
try {
  let response= await axiosInstance.post("newallowance",data);
 
    snackBarData = {
      type: response?.data.IsSuccess? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };
  
  return { ...response, snackBarData };


} catch (error){
  snackBarData = {
    type: "error",
    message: error?.response.data.Message,
    openToast: true,
  };
  return { ...error?.response, snackBarData };

}

};

export const DeleteAllowanceApi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.delete(`/deleteallowancetype/${data}`);

    snackBarData = {
      type: response.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return { ...response, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response.data.Message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const UpdateAllowanceApi = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.patch("updateallowancetype", data);

    snackBarData = {
      type: response.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return { ...response, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response.data.Message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};