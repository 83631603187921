import { Box, IconButton, Modal, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import moment from "moment";

const useStyles = makeStyles(() => {
  return {
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
    },
    Container: {
      width: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableContain: { height: "450px", overflowY: "scroll" },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    child_box: {
      display: "flex",
      width: "100%",
      maxWidth: "350px",
    },
    box_heading: {
      fontWeight: "bold",
      width: "80px",
    },
  };
});
function EngagementTableModal({ data, showModal, setshowModal }) {
  const { Container, ModalContainer, TableHeader, child_box, box_heading } =
    useStyles();
  console.log(data);
  return (
    <>
      <Modal
        open={showModal}
        onClose={() => {
          setshowModal(false);
        }}
      >
        <Box className={ModalContainer}>
          <Box className={Container}>
            <Box className={TableHeader}>
              <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                Engagement Detail
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setshowModal(false);
                }}
              >
                <HighlightOffIcon
                  color="primary"
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                overflow: "scroll",
              }}
            >
              <Box className={child_box}>
                <Typography className={box_heading}>Employee:</Typography>
                <Typography sx={{ paddingLeft: "30px" }}>
                  {data?.user_name}
                </Typography>
              </Box>
              <Box className={child_box}>
                <Typography className={box_heading}>Tag:</Typography>
                <Typography
                  sx={{
                    paddingLeft: "30px",
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  {data?.tag}
                  <Box
                    sx={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "50%",
                      backgroundColor: data?.color_code || "red",
                    }}
                  ></Box>
                </Typography>
              </Box>
              <Box className={child_box}>
                <Typography className={box_heading}>Title:</Typography>
                <Typography sx={{ paddingLeft: "30px" }}>
                  {data?.title}
                </Typography>
              </Box>
              <Box className={child_box}>
                <Typography className={box_heading}>Hours:</Typography>
                <Typography sx={{ paddingLeft: "30px" }}>
                  {data?.hours}
                </Typography>
              </Box>
              <Box className={child_box}>
                <Typography className={box_heading}>Starting at:</Typography>
                <Typography sx={{ paddingLeft: "30px" }}>
                  {data?.start_date
                    ? moment(data?.start_date).format("DD-MMM-YYYY")
                    : null}
                </Typography>
              </Box>
              <Box className={child_box}>
                <Typography className={box_heading}>Ending at:</Typography>
                <Typography sx={{ paddingLeft: "30px" }}>
                  {data?.end_date
                    ? moment(data?.end_date).format("DD-MMM-YYYY")
                    : null}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography sx={{ fontWeight: "bold" }}>Description:</Typography>
              <Typography sx={{ paddingLeft: "80px" }}>
                {data?.description}
              </Typography>
            </Box>
            {/* <Box>
              <Typography sx={{ fontWeight: "bold" }}>Google Docs:</Typography>
              <Typography sx={{ paddingLeft: "80px" }}>
                <Link href={data?.google_docs} target={"_blank"}>
                  {data?.google_docs}
                </Link>
              </Typography>
            </Box> */}
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default EngagementTableModal;
EngagementTableModal.propTypes = {
  data: PropTypes.any,
  showModal: PropTypes.any,
  setshowModal: PropTypes.any,
};
