import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
// import MonthlyReportForm from "../../Components/MonthlyReport/MonthlyReportForm";
import MonthlyReportTable from "../../Components/MonthlyReport/MonthlyReportTable";
import { Navigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useEffect } from "react";
import MonthlyReportDetailTable from "../../Components/MonthlyReport/MonthlyReportDetailTable";

const GazettedHolidaysMain = () => {
  const [ShowForm, setShowForm] = useState(false);
  const [canView, setcanView] = useState(null);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [rowvalue, setrowvalue] = useState(null);

  useEffect(() => {
    let string = "MonthlyReport";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    let match = value?.some((val) => {
      if (val?.module?.split("_")[1] === "View") {
        return true;
      }
    });
    setcanView(match);
    console.log(match);
    if (!roles || roles.length < 1) {
      setcanView(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  return (
    <div>
      {canView === true ? (
        ShowForm ? (
          <MonthlyReportTable
            setShowForm={setShowForm}
            rowvalue={rowvalue}
            setrowvalue={setrowvalue}
          />
        ) : (
          <MonthlyReportDetailTable
            setShowForm={setShowForm}
            setrowvalue={setrowvalue}
          />
        )
      ) : canView === false ? (
        <Navigate to={"/dashboard"} />
      ) : (
        <Box>Loading ...</Box>
      )}
    </div>
  );
};

export default GazettedHolidaysMain;
GazettedHolidaysMain.propTypes = {
  setShowForm: PropTypes.any,
};
