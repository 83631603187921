import axiosInstance from "../../Utils/AxiosInstance";

export const AddNewTechnology = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post("addNewTechnology", data);

    snackBarData = {
      type: response.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return { ...response, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response.data.Message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const UpdateTechnology = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.patch("updatetechnology", data);

    snackBarData = {
      type: response.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };
  

    return { ...response, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response.data.Message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const DeleteTechnology = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.delete(`deletetechnology/${data}`);

    snackBarData = {
      type: response.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return { ...response, snackBarData };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response.data.Message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
