import {
  Box,
  Button,
  Typography,
  TextField,
  FormControl,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
//import Cookies from "universal-cookie";
import { makeStyles } from "@mui/styles";
import theme from "../../theme";
import * as Yup from "yup";
import { useFormik } from "formik";
//import { AddLoansApi } from "../../Api/Loans/LoansApi";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { LoansAdded } from "../../Redux/Slice/Loans/LoansSlice";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { fetchAttendancePopulate, getattendanceDetails } from "../../Redux/Slice/Attendance/AttendanceSlice";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AddManualLoanApi } from "../../Api/ManualLoan/ManualLoanApis";

const useStyles = makeStyles(() => {
  return {
    container: {
      height: "100%",
      width: "100%",
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
      width: "100%",
      minHeight: "calc(100vh - 215px)",
      maxWidth: "700px",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      textAlign: "left",
    },
  };
});

function ManualLoansForm({ setShowForm }) {
  const { container, subContainer, formContainer } = useStyles();
  //const cookies = new Cookies();
  const { setsnackBarData } = useContext(SnackBarContext);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [Allemployees, setAllemployees] = useState();
  const { Employees,IsLogin,message } = useSelector(getattendanceDetails);
  const initialValues = {
    received_amount: "",
    received_date: "",
    emp_id: "",
  };
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
    setAllemployees(Employees);
  }, [Employees,IsLogin]);
  useEffect(() => {
    dispatch(fetchAttendancePopulate());
  }, [dispatch]);
  const handleSave = async (values, setSubmitting, resetForm) => {
   // const obj = {values};

    const response = await AddManualLoanApi(values);

    setsnackBarData(response.snackBarData);

    if (response.data.IsSuccess) {

      setShowForm(false);
      setSubmitting(false);
      resetForm();
      dispatch(LoansAdded(response.data.Employee_Loan));
    }
    setSubmitting(false);
  };
  const validationSchema = Yup.object().shape({
    received_amount: Yup.number().required("Please Enter Amount"),
    received_date: Yup.date().required("Please Enter months"),
    emp_id: Yup.string().required("Please Enter months"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSave(values, resetForm, setSubmitting);
    },
  });

 
  console.log( formik.values);

  return (
    <Box className={container}>
      <Box className={subContainer}>
        <form onSubmit={formik.handleSubmit}>
          <Box className={formContainer}>

            <Typography >
              Employee
            </Typography>
            <FormControl fullWidth sx={{ textAlign: "left" }}>
              
              <Autocomplete
              disablePortal
              options={Allemployees || []}
              getOptionLabel={(option) => option.name}
              // sx={{
              //   input: { color: "black" },
              //   maxWidth: "250px",
              //   textAlign: "left",
              // }}
              // className={Form_input}
              fullWidth
              // value={formik?.values?.emp_id || ""}
              size="small"
              onChange={(e, val) => {
                console.log(e, val);
                formik.handleChange({
                  target: { 
                    name: "emp_id",
                    value: val?.id,
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="emp_id"
                  placeholder="Employee"
                  error={
                    Boolean(formik.errors.emp_id) &&
                    Boolean(formik.touched.emp_id)
                  }
                  helperText={
                    Boolean(formik.touched.emp_id) && formik.errors.emp_id
                  }
                />
              )}
            />
             
            </FormControl>

            <FormControl fullWidth>
              <Typography>Received Amount</Typography>
              <TextField
                sx={{ textAlign: "left", input: { color: "black" } }}
                fullWidth
                size="small"
                name="received_amount"
                type="number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.received_amount}
                variant="outlined"
                error={
                  Boolean(formik.touched.received_amount) &&
                  Boolean(formik.errors.received_amount)
                }
                helperText={
                  Boolean(formik.touched.received_amount) && formik.errors.received_amount
                }
              />
            </FormControl>
            <Typography >Received Date</Typography>
            <FormControl fullWidth sx={{ textAlign: "left" }}>
              <TextField
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                name="received_date"
                size="small"
                variant="outlined"
                value={formik?.values?.received_date}
                type="date"
                color="primary"
                error={
                  Boolean(formik.touched.received_date) &&
                  Boolean(formik.errors.received_date)
                }
                helperText={
                  Boolean(formik.touched.received_date) && formik.errors.received_date
                }
                fullWidth
              />
            </FormControl>



            <Box sx={{ display: "flex", width: "100%", justifyContent: "end" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <Box>
                  <Button
                    sx={{ backgroundColor: "red !important" }}
                    variant="contained"
                    onClick={() => setShowForm(false)}
                  >
                    <Typography>Cancel</Typography>
                  </Button>
                </Box>
                <Box>
                  {formik.isSubmitting ? (
                    <CircularProgress />
                  ) : (
                    <Button type="submit" variant="contained">
                      <Typography>Save</Typography>
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default ManualLoansForm;
ManualLoansForm.propTypes = {
  setShowForm: PropTypes.any,
};
