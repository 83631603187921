import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import PropTypes from "prop-types";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { PostEngagementApi } from "../../Api/Engagements/PostEngagements";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchAllEngUser,
  FetchAllEngagements,
  GetAllEngagements,
} from "../../Redux/Slice/Engagements/EngagementSlice";
import Cookies from "universal-cookie";
import { getAllTags } from "../../Redux/Slice/Tags/TagSlice";
import { UpdateEngagements } from "../../Api/Engagements/UpdateEngagement";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../Loader/Loader";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      gap: "50px",
      justifyContent: "center",
      height: "calc(100vh - 175px)",
    },
    subContainer: {
      display: "flex",
      width: "100%",
      maxWidth: "1000px",
      flexDirection: "column",
      gap: "20px",
      textAlign: "left",
      padding: "40px 20px",
    },
    FieldInRow: {
      width: "100%",
      display: "flex",
      gap: "20px",
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
      },
    },
    imageBox: {
      height: "110px",
      width: "110px",
      borderRadius: "5px",
      border: "1px solid rgb(214, 214, 214)",
      display: "flex",
      alignItems: "center",
      cursor: "pointer !important",
      justifyContent: "center",
      position: "relative",
      padding: "10px",
    },
    hiddenimput: {
      height: "100%",
      width: "100%",
      position: "absolute",
      top: "0px",
      opacity: 0,
    },
    imgtypoBox: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      justifyContent: "start",
      width: "100%",
    },
  };
});
const cookies = new Cookies();
const validationSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  user_id: Yup.object().required("user_id"),
  hours: Yup.string().required("hours"),
  description: Yup.string().required("description"),
  tagid: Yup.string().required("Required"),
  start_date: Yup.string().required("Required"),
  end_date: Yup.string().required("Required"),
});

const initialValues = {
  title: "",
  description: "",
  hours: "",
  user_id: null,
  tagid: "",
  start_date: "",
  end_date: "",
};

const EngagementForm = ({
  setShowForm,
  EditedData,
  setIsEdit,
  IsEdit,
  setEditedData,
}) => {
  const { container, subContainer, FieldInRow, imageBox, hiddenimput } =
    useStyles();
  const { tags, IsLogin, message } = useSelector(getAllTags);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [logo, setlogo] = useState(null);
  const [allUsers, setallUsers] = useState([]);
  const user = cookies.get("user");
  const { AllUserEngagements, status } = useSelector(GetAllEngagements);
  const handleLogo = (e) => {
    setlogo({
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
  };
  console.log(user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log(AllUserEngagements, status);
  useEffect(() => {
    if (AllUserEngagements) {
      setallUsers(AllUserEngagements);
    }
  }, [AllUserEngagements]);
  useEffect(() => {
    dispatch(FetchAllEngUser());
  }, [dispatch]);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  const handleSubmit = async (values, setSubmitting, resetForm) => {
    console.log(values);
    let response = {};
    const formData = new FormData();
    formData.append("image", logo?.file);
    formData.append("user_id", values?.user_id?.userid);
    formData.append("hours", Number(values?.hours));
    formData.append("title", values?.title);
    formData.append("description", values?.description);
    formData.append("tagid", values?.tagid);
    formData.append("start_date", moment(values?.start_date).format());
    formData.append("end_date", moment(values?.end_date).format());

    if (IsEdit) {
      formData.append("task_id", EditedData?.taskid);
      response = await UpdateEngagements(formData);
    } else {
      response = await PostEngagementApi(formData);
    }
    setsnackBarData(response?.snackBarData);
    console.log(response);
    if (response.data.IsSuccess) {
      setSubmitting(false);
      resetForm();
      setIsEdit(false);
      setEditedData(null);
      setShowForm(false);
      dispatch(FetchAllEngagements({user_id:user?.user_id,type:"Completed"}));
    }
    setSubmitting(false);
  };

  const handleCancel = () => {
    setShowForm(false);
    setEditedData(null);
    setIsEdit(false);
  };

  console.log(EditedData);

  useEffect(() => {
    if (IsEdit) {
      setlogo({
        file: EditedData?.image,
        url: EditedData?.image,
      });
      formik.setFieldValue("title", EditedData?.title);
      formik.setFieldValue("hours", EditedData?.hours);
      formik.setFieldValue("description", EditedData?.description);
      formik.setFieldValue("user_id", {
        name: EditedData?.user_name,
        userid: EditedData?.user_id,
      });
      formik.setFieldValue("tagid", EditedData?.tagid);
      formik.setFieldValue(
        "start_date",
        moment(EditedData?.start_date).format("YYYY-MM-DDTHH:mm")
      );
      formik.setFieldValue(
        "end_date",
        moment(EditedData?.end_date).format("YYYY-MM-DDTHH:mm")
      );
    }
  }, [EditedData]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      handleSubmit(values, setSubmitting, resetForm);
    },
  });
  console.log(formik.values.user_id);
  return (
    <>
      {status == "loading" && <Loader />}
      <Box className={container}>
        <form
          onSubmit={formik.handleSubmit}
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Box className={subContainer}>
            <Box className={FieldInRow}>
              <FormControl fullWidth>
                <Typography>Employee</Typography>
                <Autocomplete
                  disablePortal
                  options={allUsers}
                  getOptionLabel={(option) => option.name}
                  sx={{
                    input: { color: "black" },
                    maxWidth: "100%",
                    textAlign: "left",
                  }}
                  // className={Form_input}
                  fullWidth
                  size="small"
                  // onChange={(e, val) => {
                  //   console.log(e, val);
                  //   setEmpName(val);
                  // }}
                  name="user_id"
                  value={formik.values.user_id}
                  onChange={(e, val) => {
                    console.log(e, val);
                    formik.setFieldValue("user_id", val);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Engagment Users"
                      error={
                        formik.touched.user_id && Boolean(formik.errors.user_id)
                      }
                      helperText={
                        formik.touched.user_id && formik.errors.user_id
                      }
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth>
                <Typography>Title</Typography>
                <TextField
                  name="title"
                  fullWidth
                  size="small"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />
              </FormControl>
            </Box>
            <Box className={FieldInRow}>
              <FormControl fullWidth>
                <Typography>Start Date</Typography>
                <TextField
                  name="start_date"
                  type="datetime-local"
                  fullWidth
                  size="small"
                  value={formik.values.start_date}
                  onChange={(e) => {
                    console.log(e.target.value);
                    console.log(
                      moment("2023-05-23").format("YYYY-MM-DDTHH:MM")
                    );
                    formik.handleChange(e);
                  }}
                  error={
                    formik.touched.start_date &&
                    Boolean(formik.errors.start_date)
                  }
                  helperText={
                    formik.touched.start_date && formik.errors.start_date
                  }
                />
              </FormControl>
              <FormControl fullWidth>
                <Typography>End Date</Typography>
                <TextField
                  name="end_date"
                  fullWidth
                  size="small"
                  value={formik.values.end_date}
                  type="datetime-local"
                  onChange={formik.handleChange}
                  error={
                    formik.touched.end_date && Boolean(formik.errors.end_date)
                  }
                  helperText={formik.touched.end_date && formik.errors.end_date}
                />
              </FormControl>
            </Box>
            <Box className={FieldInRow}>
              <FormControl fullWidth>
                <Typography>Tags</Typography>
                <TextField
                  name="tagid"
                  fullWidth
                  size="small"
                  select
                  value={formik.values.tagid}
                  onChange={formik.handleChange}
                  error={formik.touched.tagid && Boolean(formik.errors.tagid)}
                  helperText={formik.touched.tagid && formik.errors.tagid}
                >
                  {tags &&
                    tags.map((tag, i) => {
                      return (
                        <MenuItem key={i} value={tag?.tag_id}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Typography>{tag?.tag}</Typography>
                            <Box
                              alignSelf="right"
                              sx={{
                                borderRadius: "15px",
                                backgroundColor: `${tag?.color_code}`,
                                height: "15px",
                                width: "15px",
                              }}
                              size="medium"
                            ></Box>
                          </Box>
                        </MenuItem>
                      );
                    })}
                </TextField>
              </FormControl>
              <FormControl fullWidth>
                <Typography>Hours</Typography>
                <TextField
                  name="hours"
                  type="number"
                  fullWidth
                  size="small"
                  value={formik.values.hours}
                  onChange={(e) => {
                    console.log(e.target.value);
                    console.log(
                      moment("2023-05-23").format("YYYY-MM-DDTHH:MM")
                    );
                    formik.handleChange(e);
                  }}
                  error={formik.touched.hours && Boolean(formik.errors.hours)}
                  helperText={formik.touched.hours && formik.errors.hours}
                />
              </FormControl>
            </Box>
            <Box className={FieldInRow}>
              <FormControl fullWidth>
                <Typography>Description</Typography>
                <TextField
                  name="description"
                  type="text"
                  fullWidth
                  multiline
                  rows={4}
                  size="small"
                  value={formik.values.description}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </FormControl>
              <FormControl fullWidth>
                <Typography>Attachment</Typography>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  {logo ? (
                    <Box className={imageBox}>
                      <img
                        src={logo?.url}
                        alt={""}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </Box>
                  ) : null}
                  <Box className={imageBox}>
                    <AddIcon sx={{ color: "#415f73 !important" }} />
                    <input
                      type="file"
                      accept="image/*"
                      name="product_images"
                      className={hiddenimput}
                      onChange={handleLogo}
                    />
                  </Box>
                </Box>
              </FormControl>
            </Box>
            <Box
              className={FieldInRow}
              sx={{ justifyContent: "end", alignItems: "start" }}
            >
              <Button variant="contained" color="error" onClick={handleCancel}>
                Cancel
              </Button>
              {formik.isSubmitting ? (
                <Box>
                  <CircularProgress />
                </Box>
              ) : (
                <Button variant="contained" type="submit">
                  {IsEdit ? "Update" : "Save"}
                </Button>
              )}
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default EngagementForm;
EngagementForm.propTypes = {
  setShowForm: PropTypes.any,
  EditedData: PropTypes.any,
  setIsEdit: PropTypes.any,
  IsEdit: PropTypes.any,
  setEditedData: PropTypes.any,
};
