import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useEffect } from "react";
import MissingPunchesOfAllEmployees from "../../Components/EmployeMissingPunches/MissingPunchesOfAllEmployees";
const EmployeMissingPunches = () => {
    const [canView, setcanView] = useState(null);
    const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
    const [rowvalue, setrowvalue] = useState(null);

    useEffect(() => {
        let string = "MissingPunches";
        let value = roles?.ruleid?.filter((role) => {
            if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
                return role;
            }
        });
        console.log(value);
        let match = value?.some((val) => {
            if (val?.module?.split("_")[1] === "View") {
                return true;
            }
        });
        setcanView(match);
        console.log(match);
        if (!roles || roles.length < 1) {
            setcanView(true);
        }
        setroles(JSON.parse(localStorage.getItem("roles")));
    }, []);
    
    return (
        <>
            {canView === true ? (
                <MissingPunchesOfAllEmployees
                    rowvalue={rowvalue}
                    setrowvalue={setrowvalue}
                />
            )
                : canView === false ? (
                    <Navigate to={"/dashboard"} />
                ) : (
                    <Box>Loading ...</Box>
                )}
        </>
    );
};

export default EmployeMissingPunches;
EmployeMissingPunches.propTypes = {
    rowvalue: PropTypes.any,
    setrowvalue: PropTypes.any,

};
