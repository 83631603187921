import PropTypes from "prop-types";
import {
  Box,
  gridClasses,
  InputAdornment,
  IconButton,
  TextField,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import DeleteModal from "../DeleteModal/DeleteModal";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import theme from "../../theme";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@mui/styles";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import {
  FetchAllEngagements,
  GetAllEngagements,
} from "../../Redux/Slice/Engagements/EngagementSlice";
import moment from "moment";
import { DeleteEngagementAPI } from "../../Api/Engagements/DeleteEngagements";
import Cookies from "universal-cookie";
import CustomButton from "../Button/AddNewButton";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import Loader from "../Loader/Loader";
import EngagementTableModal from "./EngagementTableModal";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});

const cookies = new Cookies();

function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport csvOptions={{ fileName: "Allowances" }} />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  );
}

function EngagementTable({
  setShowForm,
  EditedData,
  setIsEdit,
  setEditedData,
}) {
  const { header } = useStyles();
  const user = cookies.get("user");
  console.log(user);
  const dispatch = useDispatch();
  const [rows, setrows] = useState([]);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [loading, setloading] = useState(false);
  const [filterRows, setfilterRows] = useState([]);
  const navigate = useNavigate();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { tabelStyle } = useContext(TabelStyleContext);
  const [showModal, setshowModal] = useState(false);
  const [SelectedRow, setSelectedRow] = useState({});
  const [value, setValue] = useState(0);
  const { engagements, IsLogin, message, status } =
    useSelector(GetAllEngagements);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [editPermit, seteditpermit] = useState(false);
  const [addPermit, setaddpermit] = useState(false);
  const [deletePermit, setdeletepermit] = useState(false);
  console.log(tabelStyle);

  console.log(roles);
  console.log(engagements);
  useEffect(() => {
    let string = user?.type === "Admin" ? "Engagements" : "EmployeeEngagement";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Edit") {
        seteditpermit(true);
      } else if (val?.module?.split("_")[1] === "Add") {
        setaddpermit(true);
      } else if (val?.module?.split("_")[1] === "Delete") {
        setdeletepermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      setaddpermit(true);
      seteditpermit(true);
      setdeletepermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  console.log(editPermit);
  console.log(addPermit);
  console.log(deletePermit);

  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);

  useEffect(() => {
    // if (!engagements) {
      dispatch(FetchAllEngagements({user_id:user?.user_id,type:value==0?"Completed":value==1?"Incoming":"Ongoing"}));
    // }
  }, [dispatch,value]);

  const columns = [
    { field: "sr", headerName: "Sr #", width: 75, headerClassName: header },
    {
      field: "user_name",
      headerName: "Employee",
      width: 250,
      headerClassName: header,
    },
    {
      field: "title",
      headerName: "Title",
      width: 250,
      headerClassName: header,
    },
    {
      field: "tag",
      headerName: "Tag",
      width: 170,
      headerClassName: header,
      renderCell: (celVal) => {
        console.log(celVal.row?.color_code);
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Typography>{celVal.row?.tag}</Typography>
            <Box
              alignSelf="right"
              sx={{
                borderRadius: "15px",
                backgroundColor: `${celVal.row?.color_code}`,
                height: "15px",
                width: "15px",
              }}
              size="medium"
            ></Box>
          </Box>
        );
      },
    },
    {
      field: "start",
      headerName: "Starting at",
      width: 170,
      headerClassName: header,
    },
    {
      field: "end",
      headerName: "Ending at",
      width: 170,
      headerClassName: header,
    },
    {
      field: "hours",
      headerName: "Hours",
      width: 100,
      headerClassName: header,
    },

    {
      field: "Actions",
      headerName: "Actions",
      width: 180,
      headerClassName: header,
      renderCell: (celVal) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              gap: "10px",
            }}
          >
            {editPermit ? (
              <IconButton
                size={"small"}
                onClick={() => {
                  setEditedData(celVal.row);
                  setIsEdit(true);
                  setShowForm(true);
                }}
              >
                <AppRegistrationIcon
                  sx={{ cursor: "pointer", color: "black !important" }}
                />
              </IconButton>
            ) : null}
            {deletePermit ? (
              <IconButton
                onClick={() => {
                  console.log(celVal?.row);
                  setEditedData(celVal.row);
                  setshowDeleteModal(true);
                }}
                size={"small"}
              >
                <DeleteOutlineIcon sx={{ color: theme.palette.error.main }} />
              </IconButton>
            ) : null}
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    let arr = [];
    if (engagements) {
      engagements?.forEach((item, idx) => {
        return arr.push({
          sr: idx + 1,
          id: idx + 1,
          title: item?.title,
          start: item?.start_date?moment(item?.start_date).format("DD-MMM-YYYY"):"",
          end: item?.end_date?moment(item?.end_date).format("DD-MMM-YYYY"):"",
          start_date: item?.start_date?item?.start_date:"",
          end_date: item?.end_date?item?.end_date:"",
          tag: item?.tag_name,
          tagid: item?.tagid,
          taskid: item?.task_id,
          color_code: item?.color_code,
          hours: item?.hours,
          description: item?.description,
          user_id: item?.user_id,
          user_name: item?.user_name,
          image: item?.image,
        });
      });
    }
    setrows(arr);
    setfilterRows(arr);
  }, [engagements]);

  const onTextChange = () => {
    if (document.getElementById("OrderNoFilter").value === "") {
      setfilterRows(rows);
    } else {
      let suggestions = [];

      var regex1 = new RegExp(
        document.getElementById("OrderNoFilter").value,
        "i"
      );

      suggestions = rows?.filter(
        (val) =>
          document.getElementById("OrderNoFilter").value === "" ||
          regex1.test(val.title)
      );

      setfilterRows(suggestions);
    }
  };
  const handelDelete = async () => {
    if (EditedData) {
      setloading(true);
      console.log(EditedData);
      const response = await DeleteEngagementAPI(EditedData?.taskid);
      setsnackBarData(response?.snackBarData);
      if (response.data.IsSuccess) {
        setloading(false);
        setshowDeleteModal(false);
        dispatch(FetchAllEngagements({user_id:user?.user_id,type:value==0?"Completed":value==1?"Incoming":"Ongoing"}));
      }
      setloading(false);
    }

    console.log("delete");
  };
  useEffect(() => {
    if (status === "failed") {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
    }
  }, [status]);
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          padding: "20px 10px 10px 10px",
        }}
      >
        <Box>
          {addPermit ? (
            <>
              {/* <Button
                sx={{
                  display: "flex",
                  borderRadius: 0,
                  padding: "0px",
                }}
                onClick={() => {
                  setShowForm(true);
                }}
                variant="contained"
                className="tabletopbutton1 text-right"
              >
                <Typography
                  sx={{
                    padding: "0px 20px",
                    textAlign: "center",
                  }}
                >
                  Add
                </Typography>
                <Box
                  sx={{
                    padding: "6px 10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: (theme) => theme.palette.background.text,
                  }}
                >
                  <AddIcon />
                </Box>
              </Button> */}
              <CustomButton
                Color={"primary"}
                Icon={<AddIcon />}
                onClick={() => {
                  setShowForm(true);
                }}
                Label={"Add"}
              />
            </>
          ) : null}
        </Box>
        <Box>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: (theme) => theme.palette.grey.G2 }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              width: "100%",
              maxWidth: "180px",
            }}
            variant="standard"
            type="text"
            autoComplete="off"
            placeholder="Engagements"
            className="input_border"
            id="OrderNoFilter"
            onChange={onTextChange}
          />
        </Box>
      </Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              variant="fullWidth"
              fullWidth
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Completed" {...a11yProps(0)} />
              <Tab label="Incoming" {...a11yProps(1)} />
              <Tab label="Ongoing" {...a11yProps(2)} />
            </Tabs>
          </Box>
      <Box
        style={{
          height: "calc(100vh - 235px)",
          width: "100%",
          padding: "10px",
        }}
      >
        { status === "loading" ? <Loader /> : null}
        <DataGrid
          sx={{ height: "calc(100vh - 200px)",cursor:"pointer" }}
          rows={filterRows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          pagination
          components={{
            Toolbar: CustomToolbar,
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
          onRowClick={(e) => {
            setSelectedRow(e.row);
            setshowModal(true);
          }}
        />

        <DeleteModal
          name={`${EditedData?.title}`}
          handelDelete={handelDelete}
          showModal={showDeleteModal}
          setshowModal={setshowDeleteModal}
          loading={loading}
        />
      </Box>
      <EngagementTableModal
        data={SelectedRow}
        setshowModal={setshowModal}
        showModal={showModal}
      />
    </>
  );
}

export default EngagementTable;
EngagementTable.propTypes = {
  setShowForm: PropTypes.any,
  setIsEdit: PropTypes.func,
  setEditedData: PropTypes.func,
  EditedData: PropTypes.any,
};
