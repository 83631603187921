import {
  Box,
  Button,
  FormControl,
  Typography,
  TextField,
  Paper,
  Avatar,
  CircularProgress,
} from "@mui/material";
window.Buffer = window.Buffer || require("buffer").Buffer;
import React, { useState, useEffect } from "react";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { makeStyles } from "@mui/styles";
import { object, string } from "yup";
import { useDispatch, useSelector } from "react-redux";
import theme from "../../theme";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useRef } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { useFormik } from "formik";
import { AddCompanyData } from "../../Api/CompanyProfile/CompanyProfileApi";
import {
  CompanyProfileAdded,
  fetchAllCompanyProfile,
  getCompanyProfile,
} from "../../Redux/Slice/CompanyProfile/CompanyProfileSlice";
import { useNavigate } from "react-router-dom";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
const useStyles = makeStyles(() => {
  return {
    container: {
      minHeight: "calc(100vh - 165px)",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      padding: "10px",
    },
    form_Container: {
      maxWidth: "600px",
      display: "flex",
      gap: "20px",
      justifyContent: "space-between",
      flexWrap: "wrap",
      [theme.breakpoints.down("md")]: {
        gap: "10px",
      },
    },
    imgcontainer: {
      width: "120px",
      height: "120px",
      borderRadius: "50%",
      padding: "10px",
    },
    inputBox: {
      width: "100%",
    },
    input_typography: {
      textAlign: "left",
      fontSize: `15px !important`,
      marginTop: "10px !important",
      color: "black",
    },
    Form_input: {
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,
      width: "100%",
    },
    btns: {
      width: "80px",
    },
    FieldInRow: {
      display: "flex",
      justifyContent: "space-between",
      gap: "20px",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },
    profileImageContainer: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        alignItems: "start",
      },
    },
  };
});

const validationSchema = object().shape({
  address: string().required("Please Enter Address"),
  company_name: string().required("Please Enter Company Name"),
  email: string()
    .email("Please Enter Valid Eamil address")
    .required("Please Enter Amount"),
  phonenum: string().required("Please Enter Contant Number"),
});
const CompanyData = () => {
  const {
    Form_input,
    input_typography,
    btns,
    inputBox,
    FieldInRow,
    container,
    form_Container,
    imgcontainer,
    profileImageContainer,
  } = useStyles();
  const { setsnackBarData } = useContext(SnackBarContext);
  const navigate = useNavigate();
  const { CompanyProfile, IsLogin, message } = useSelector(getCompanyProfile);

  const [img, setimg] = useState(null);
  const inputRef = useRef();
  const companyData = {
    companyprofile_id: "",
    address: "",
    email: "",
    company_name: "",
    phonenum: "",
    website: "",
    facebook_link: "",
    insta_link: "",
    supporting_email: "",
    linkedin_link: "",
    twitter_link: "",
  };
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: companyData,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSave(values, resetForm, setSubmitting);
    },
  });
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  useEffect(() => {
    dispatch(fetchAllCompanyProfile());
  }, [dispatch]);

  useEffect(() => {
    if (CompanyProfile) {
      // setimg(
      //   profileData?.profile_image
      //     ? {
      //         src: profileData?.profile_image,
      //         file: profileData?.profile_image,
      //       }
      //     : null
      // );
      setimg(
        CompanyProfile?.logo
          ? {
            src: CompanyProfile?.logo,
            file: CompanyProfile?.logo,
          }
          : null
      );
      Object.keys(CompanyProfile).map((key) => {
        formik.setFieldValue(key, CompanyProfile[key]);
      });
    }
  }, [CompanyProfile]);
  const handleSave = async (values) => {
    const formData = new FormData();

    // formData.append(
    //   "user_id",
    //   profileData?.user_id ? profileData?.user_id : null
    // );
    formData.append("logo", img?.file);
    Object.keys(values)?.map((key) => {
      formData.append(key, values[key]);
    });
    // formData.append("companyprofile_id", values?.companyprofile_id);
    // formData.append("address", values?.address);
    // formData.append("company_name", values?.company_name);
    // formData.append("phonenum", values?.phonenum);
    // formData.append("website", values?.website);

    const response = await AddCompanyData(formData);

    setsnackBarData(response.snackBarData);

    if (response.data.IsSuccess) {
      //   setprofileData({
      //     user_id: response?.data?.Data?.user_id,
      //     profile_image: response?.data?.Data?.profile_image,
      //     email: response?.data?.Data?.email,
      //     username: response?.data?.Data?.username,
      //     type: response?.data?.Data?.type,
      //   });
      dispatch(CompanyProfileAdded(response.data.CompanyData));
      formik.setSubmitting(false);
    }
  };
  const handleImageChange = (e) => {
    const obj = {
      file: e.target.files[0],
      src: URL.createObjectURL(e.target.files[0]),
    };
    setimg(obj);
  };
  const handleRemoveImage = (e) => {
    e.stopPropagation();
    setimg(null);
  };

  return (
    <Box className={container}>
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <Box className={form_Container}>
            <Box className={profileImageContainer}>
              <Paper elevation={3} className={imgcontainer}>
                {!img ? (
                  <Avatar
                    src="/broken-image.jpg"
                    sx={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <img
                    src={img?.src}
                    alt="img"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                    }}
                  />
                )}
              </Paper>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    inputRef.current.click();
                  }}
                >
                  <FileUploadIcon /> Upload
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid red",
                  }}
                  onClick={handleRemoveImage}
                >
                  <DoDisturbIcon /> Remove
                </Button>
              </Box>
            </Box>
            <Box className={FieldInRow}>
              <Box className={inputBox}>
                <Typography className={input_typography}>
                  Company Name*
                </Typography>

                <FormControl fullWidth sx={{ textAlign: "left" }}>
                  <TextField
                    size="small"
                    name="company_name"
                    value={formik?.values?.company_name}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    type="text"
                    color="primary"
                    sx={{ input: { color: "black" } }}
                    className={Form_input}
                    fullWidth
                    error={
                      Boolean(formik?.errors.company_name) &&
                      Boolean(formik?.touched.company_name)
                    }
                    helperText={
                      Boolean(formik?.touched.company_name) &&
                      formik?.errors.company_name
                    }
                  />
                </FormControl>
              </Box>
              <Box className={inputBox}>
                <Typography className={input_typography}>
                  Phone Number*
                </Typography>
                <FormControl fullWidth sx={{ textAlign: "left" }}>
                  <TextField
                    value={formik?.values?.phonenum}
                    size="small"
                    name="phonenum"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="number"
                    color="primary"
                    sx={{ input: { color: "black" } }}
                    className={Form_input}
                    fullWidth
                    error={
                      Boolean(formik?.errors.phonenum) &&
                      Boolean(formik?.touched.phonenum)
                    }
                    helperText={
                      Boolean(formik?.touched.phonenum) &&
                      formik?.errors.phonenum
                    }
                  />
                </FormControl>
              </Box>
            </Box>
            <Box className={FieldInRow}>
              <Box className={inputBox}>
                <Typography className={input_typography}>Email*</Typography>
                <FormControl fullWidth sx={{ textAlign: "left" }}>
                  <TextField
                    value={formik?.values?.email}
                    size="small"
                    name="email"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="email"
                    color="primary"
                    sx={{ input: { color: "black" } }}
                    className={Form_input}
                    fullWidth
                    error={
                      Boolean(formik?.errors.email) &&
                      Boolean(formik?.touched.email)
                    }
                    helperText={
                      Boolean(formik?.touched.email) && formik?.errors.email
                    }
                  />
                </FormControl>
              </Box>
              <Box className={inputBox}>
                <Typography className={input_typography}>
                  Supporting Email
                </Typography>
                <FormControl fullWidth sx={{ textAlign: "left" }}>
                  <TextField
                    value={formik?.values?.supporting_email}
                    size="small"
                    name="supporting_email"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="email"
                    color="primary"
                    sx={{ input: { color: "black" } }}
                    className={Form_input}
                    fullWidth
                    error={
                      Boolean(formik?.errors.supporting_email) &&
                      Boolean(formik?.touched.supporting_email)
                    }
                    helperText={
                      Boolean(formik?.touched.supporting_email) &&
                      formik?.errors.supporting_email
                    }
                  />
                </FormControl>
              </Box>
            </Box>
            <Box className={FieldInRow}></Box>
            <Box className={FieldInRow}>
              <Box className={inputBox}>
                <Typography className={input_typography}>Address*</Typography>
                <FormControl fullWidth sx={{ textAlign: "left" }}>
                  <TextField
                    value={formik?.values?.address}
                    size="small"
                    name="address"
                    variant="outlined"
                    multiline
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    rows={3}
                    type="text"
                    color="primary"
                    sx={{ input: { color: "black" } }}
                    className={Form_input}
                    fullWidth
                    error={
                      Boolean(formik?.errors.address) &&
                      Boolean(formik?.touched.address)
                    }
                    helperText={
                      Boolean(formik?.touched.address) && formik?.errors.address
                    }
                  />
                </FormControl>
              </Box>
            </Box>
            <Box className={FieldInRow}>
              <Box className={inputBox}>
                <Typography className={input_typography}>Webiste</Typography>
                <FormControl fullWidth sx={{ textAlign: "left" }}>
                  <TextField
                    value={formik?.values?.website}
                    size="small"
                    name="website"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    color="primary"
                    sx={{ input: { color: "black" } }}
                    className={Form_input}
                    fullWidth
                    error={
                      Boolean(formik?.errors.website) &&
                      Boolean(formik?.touched.website)
                    }
                    helperText={
                      Boolean(formik?.touched.website) && formik?.errors.website
                    }
                  />
                </FormControl>
              </Box>
            </Box>
            <Box className={FieldInRow}>
              <Box className={inputBox}>
                <Typography className={input_typography}>Facebook Link</Typography>
                <FormControl fullWidth sx={{ textAlign: "left" }}>
                  <TextField
                    value={formik?.values?.facebook_link}
                    size="small"
                    name="facebook_link"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    color="primary"
                    sx={{ input: { color: "black" } }}
                    className={Form_input}
                    fullWidth
                    error={
                      Boolean(formik?.errors.facebook_link) &&
                      Boolean(formik?.touched.facebook_link)
                    }
                    helperText={
                      Boolean(formik?.touched.facebook_link) && formik?.errors.facebook_link
                    }
                  />
                </FormControl>
              </Box>
            </Box>
            <Box className={FieldInRow}>
              <Box className={inputBox}>
                <Typography className={input_typography}>LinkedIn link</Typography>
                <FormControl fullWidth sx={{ textAlign: "left" }}>
                  <TextField
                    value={formik?.values?.linkedin_link}
                    size="small"
                    name="linkedin_link"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    color="primary"
                    sx={{ input: { color: "black" } }}
                    className={Form_input}
                    fullWidth
                    error={
                      Boolean(formik?.errors.linkedin_link) &&
                      Boolean(formik?.touched.linkedin_link)
                    }
                    helperText={
                      Boolean(formik?.touched.linkedin_link) && formik?.errors.linkedin_link
                    }
                  />
                </FormControl>
              </Box>
            </Box>
            <Box className={FieldInRow}>
              <Box className={inputBox}>
                <Typography className={input_typography}>Twitter Link</Typography>

                <FormControl fullWidth sx={{ textAlign: "left" }}>
                  <TextField
                    value={formik?.values?.twitter_link}
                    size="small"
                    name="twitter_link"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    color="primary"
                    sx={{ input: { color: "black" } }}
                    className={Form_input}
                    fullWidth
                    error={
                      Boolean(formik?.errors.twitter_link) &&
                      Boolean(formik?.touched.twitter_link)
                    }
                    helperText={
                      Boolean(formik?.touched.twitter_link) && formik?.errors.twitter_link
                    }
                  />
                </FormControl>
              </Box>
            </Box>
            <Box className={FieldInRow}>
              <Box className={inputBox}>
                <Typography className={input_typography}>Instagram Link</Typography>
                <FormControl fullWidth sx={{ textAlign: "left" }}>
                  <TextField
                    value={formik?.values?.insta_link}
                    size="small"
                    name="insta_link"
                    variant="outlined"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    color="primary"
                    sx={{ input: { color: "black" } }}
                    className={Form_input}
                    fullWidth
                    error={
                      Boolean(formik?.errors.insta_link) &&
                      Boolean(formik?.touched.insta_link)
                    }
                    helperText={
                      Boolean(formik?.touched.insta_link) && formik?.errors.insta_link
                    }
                  />
                </FormControl>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Box
              sx={{
                float: "right",
                display: "flex",
                gap: "10px",
                marginTop: "20px",
              }}
              spacing={2}
            >
              <>
                {formik?.isSubmitting ? (
                  <CircularProgress />
                ) : (
                  <Button
                    className={btns}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Update
                  </Button>
                )}
              </>
            </Box>
          </Box>
        </Box>
        <input
          type={"file"}
          onChange={handleImageChange}
          ref={inputRef}
          accept="image/*"
          style={{ display: "none" }}
        />
      </form>
    </Box>
  );
};

export default CompanyData;

CompanyData.propTypes = {
  // setShowForm: PropTypes.any,
  // empID: PropTypes.any,
  // empData: PropTypes.any,
  // setisEdited: PropTypes.any,
  // isEdited: PropTypes.any,
  // setstepper: PropTypes.any,
};
