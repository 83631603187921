import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from "@mui/material";
import React, { Component } from "react";
import PrintHeader from "../../Components/Print/PrintHeader/PrintHeader";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import PrintReportSetion from "../../Components/Print/PrintReportSection/PrintReportSetion";
class PrintCPL extends Component {
    render() {
        const cookies = new Cookies();
        console.log(cookies?.get("user")?.username);
        return (
            <Box sx={{ width: "100%", padding: "10px" }}>
                <PrintHeader />
                <PrintReportSetion
                    Reportname={"CPL Report"}
                    userName={
                        this.props?.name ? this.props?.name : cookies?.get("user")?.username
                    }
                    Months={this.props.month}
                />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "0px 10px 10px 10px",
                        width: "100%",
                    }}
                >
                </Box>
                <Box>
                    <TableContainer component={Paper} sx={{ overflowY: "scroll" }}>
                        <Table
                            sx={{
                                minWidth: { md: "auto", xs: "auto" },
                                overflow: "scroll",
                            }}
                            aria-label="simple table"
                            size="small"
                        >
                            <TableHead>
                                <TableRow size="small">
                                    <TableCell
                                        size="small"
                                        align="left"
                                        sx={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Sr #
                                    </TableCell>
                                    <TableCell
                                        size="small"
                                        align="left"
                                        sx={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Employee
                                    </TableCell>
                                    <TableCell
                                        size="small"
                                        align="left"
                                        sx={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Casual Leaves
                                    </TableCell>
                                    <TableCell
                                        size="small"
                                        align="left"
                                        sx={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Annual Leaves
                                    </TableCell>
                                    <TableCell
                                        size="small"
                                        align="left"
                                        sx={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Year
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.Cpl?.map((item, i) => {
                                    return (
                                        <TableRow
                                            key={i}
                                            sx={{
                                                "&:last-child td, &:last-child th": { border: 0 },
                                            }}
                                        >
                                            <TableCell size="small" align="left">
                                                {item?.sr}
                                            </TableCell>
                                            <TableCell size="small" align="left">
                                                {item?.emp_id?.name}
                                            </TableCell>
                                            <TableCell size="small" align="left">
                                                {item?.available_CL}
                                            </TableCell>
                                            <TableCell size="small" align="left">
                                                {item?.available_CL}
                                            </TableCell>
                                            <TableCell size="small" align="left">
                                                {item?.year}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

            </Box>
        );
    }
}

export default PrintCPL;
PrintCPL.propTypes = {
    Cpl: PropTypes.any,
    name: PropTypes.any,
    month: PropTypes.any,
};
