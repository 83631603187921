import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  leaveApplication: [],
  EmpleaveApplication: [],
  // singleEmpLeave:[],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
  EmpAvlCl: null,
  EmpAvlAl: null,
};

// export const fetchLeaveApplication = createAsyncThunk(
//   "getleaveapplications",
//   async () => {
//     const response = await axiosInstance.get(`getleaveapplications`);

//     return response.data;
//   }
// );
export const fetchLeaveApplication = createAsyncThunk(
  "empleavestatus",
  async () => {
    const response = await axiosInstance.get(`empleavestatus`);

    return response.data;
  }
);
export const fetchsingleEmpLeave = createAsyncThunk(
  "singlempleaves/",
  async (id) => {
    const response = await axiosInstance.get(`empleaveapplications/${id}`);
    return response.data;
  }
);

const LeaveApplicationSlice = createSlice({
  name: "leaveapplication",
  initialState,
  reducers: {
    LeaveApplicationAdded: {
      reducer(state, action) {
        state.leaveApplication.push(action.payload);
      },
    },
    removeLeaveApplicationDetail: {
      reducer(state) {
        state.leaveApplication = [];
      },
    },
    LeaveAppResetData: (state) => {
      state.leaveApplication = [];
      state.isSuccess = false;
      state.message = null;
      state.status = "idle"; //'idle' | 'loading' | 'succeeded' | 'failed'
      state.error = null;
      state.IsLogin = true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLeaveApplication.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLeaveApplication.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.leaveApplication = action.payload.Leave_Applications;
      })
      .addCase(fetchLeaveApplication.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(fetchsingleEmpLeave.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchsingleEmpLeave.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.EmpleaveApplication = action.payload.Leave_Applications;
        state.EmpAvlCl = action.payload.available_CL;
        state.EmpAvlAl = action.payload.available_AL;
      })
      .addCase(fetchsingleEmpLeave.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
    //   .addCase(jobTitlePopulate.pending, (state) => {
    //     state.status = "loading";
    //   })
    //   .addCase(jobTitlePopulate.fulfilled, (state, action) => {
    //     state.status = "succeeded";
    //     state.message = action.payload.Message;
    //     state.isSuccess = action.payload.IsSuccess;
    //     state.department = action.payload.Department;
    //     state.technologies = action.payload.Technolgies;
    //     state.experties = action.payload.Expertise;
    //   })
    //   .addCase(jobTitlePopulate.rejected, (state, action) => {
    //     state.status = "failed";
    //     state.error = action.error.message;
    //   })
  },
});

export const getleaveapplicationDetails = (state) => state.leaveapplication;

export const {
  LeaveApplicationAdded,
  removeLeaveApplicationDetail,
  LeaveAppResetData,
} = LeaveApplicationSlice.actions;

export default LeaveApplicationSlice.reducer;
