import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  tags: null,
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};

export const fetchAllTags = createAsyncThunk("gettag", async () => {
  const response = await axiosInstance.get(`gettag`);
  return response.data;
});

const TagsSlice = createSlice({
  name: "Tagslice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(fetchAllTags.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllTags.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.tags = action.payload.Tags;
      })
      .addCase(fetchAllTags.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
  },
});

export const getAllTags = (state) => state.Tagslice;

export default TagsSlice.reducer;
