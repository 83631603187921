import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  FormControl,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import theme from "../../theme";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import {
  AddDepartmentApi,
  UpdatedepartmentApi,
} from "../../Api/Department/DepartmentAPis";
import { DepartmentAdded } from "../../Redux/Slice/Department/DepartmentSlice";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
const useStyles = makeStyles(() => {
  return {
    input_typography: {
      textAlign: "left",

      fontSize: `15px !important`,
      marginTop: "10px !important",
      paddingRight: "20px !important",
    },
    Form_input: {
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,
    },

    form_main: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      padding: "60px 8%",
      minHeight: "calc(100vh - 175px)",
    },
    responsive: {
      maxWidth: "600px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },

    btns: {
      width: "80px",
    },
    main_box: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
    },
  };
});

const DepartmentForm = ({
  setShowForm,
  setIsEdit,
  setEditedData,
  IsEdit,
  EditedData,
}) => {
  const { setsnackBarData } = useContext(SnackBarContext);
  const [loading, setloading] = useState(false);
  const {
    Form_input,
    input_typography,
    btns,
    form_main,
    responsive,
    main_box,
  } = useStyles();
  const [formData, setformData] = useState({
    department_name: "",
    dept_id: "",
  });
  const dispatch = useDispatch();

  const handelChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setformData({ ...formData, [name]: value });
  };
  const HandleAddDepartment = async () => {
    if (formData?.department_name === "") {
      setsnackBarData({
        type: "error",
        message: "Please Enter Department",
        openToast: true,
      });
    } else {
      setloading(true);
      let response;
      if (IsEdit) {
        response = await UpdatedepartmentApi(formData);
      } else {
        response = await AddDepartmentApi(formData);
      }
      setsnackBarData(response.snackBarData);

      if (response.data.IsSuccess) {
        setformData({
          department_name: "",
          dept_id: "",
        });
        setShowForm(false);
        setIsEdit(false);
        setEditedData(null);
        dispatch(DepartmentAdded(response.data.new_dept));
      }
      setloading(false);
    }
  };
  useEffect(() => {
    if (EditedData && IsEdit) {
      setformData(EditedData);
    }
  }, [EditedData, IsEdit]);
  return (
    <Box className={form_main}>
      <Box className={responsive}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
          <Box className={main_box}>
            <Typography className={input_typography}>Department</Typography>

            <FormControl fullWidth sx={{ textAlign: "left" }}>
              <TextField
                size="small"
                value={formData?.department_name}
                onChange={handelChange}
                className={Form_input}
                name="department_name"
              />
            </FormControl>
          </Box>
        </Box>

        <Box
          sx={{
            justifyContent: "flex-end",
            display: "flex",
            gap: "10px",
          }}
          spacing={2}
        >
          <>
            <Button
              className={btns}
              variant="contained"
              color="error"
              onClick={() => {
                setEditedData(null);
                setShowForm(false);
              }}
            >
              Cancel
            </Button>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                className={btns}
                variant="contained"
                color="primary"
                onClick={() => {
                  HandleAddDepartment();
                }}
              >
                {IsEdit ? "Update" : "Save"}
              </Button>
            )}
          </>
        </Box>
      </Box>
    </Box>
  );
};

export default DepartmentForm;
DepartmentForm.propTypes = {
  setShowForm: PropTypes.any,
  setIsEdit: PropTypes.func,
  setEditedData: PropTypes.func,
  IsEdit: PropTypes.any,
  EditedData: PropTypes.any,
};
