import Cookies from "universal-cookie";
import axiosInstance from "../../Utils/AxiosInstance";
const cookies = new Cookies();

export const EmailVarificationAPI = async (data) => {
  let snackBarData = {};
  try {
    let response = await axiosInstance.post("verifypin", data);
    snackBarData = {
      type: response?.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    if (
      response.data.IsSuccess &&
      response.data.Data.ispasswordchange === false
    ) {
      snackBarData = {
        type: "success",
        message: "please change your password.!",
        openToast: true,
      };
      return { ...response, snackBarData, navigate: "/changepassword" };
    }
    
    
    
    
    
    else if (
      response.data.IsSuccess &&
      response.data.Data.ispasswordchange === true
    ) {
      cookies.set("access_token", response.data.acces_token, {
        path: "/",
      });
      snackBarData = {
        type: "success",
        message: response.data.Message,
        openToast: true,
      };
      return { ...response, snackBarData, navigate: "/dashboard" };
    }
    else {
      snackBarData = {
        type: "error",
        message: response?.data?.Message,
        openToast: true,
      };
      return { ...response, snackBarData };
    }



  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.response.data.Message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
