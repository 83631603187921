import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { useState } from "react";
import PropTypes from "prop-types";
import SideBar from "../../Components/SideBar/SideBar";
import TopBar from "../../Components/TopBar/UserDashBoard/TopBar";
import { Divider } from "@mui/material";
// import TitleBar from "../../Components/TopBar/UserDashBoard/TitleBar";
import Cookies from "universal-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ProfileContext } from "../../Context/ProfileContext/ProfileContext";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { fetchAllCompanyProfile } from "../../Redux/Slice/CompanyProfile/CompanyProfileSlice";
// import { TooltipContext } from "../../Context/TooltipContext/TooltipContent";

const UserDashboard = ({ activePage, headerTitle }) => {
  const [open, setOpen] = useState(false);
  const [OnHover, setOnHover] = useState(false);
  const [selectedTab, setselectedTab] = useState("");
  const [selectedSecondTab, setselectedSecondTab] = useState("");
  const { profileData, setprofileData } = useContext(ProfileContext);
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [openDialog, setopenDialog] = useState(false);
  const dispatch = useDispatch();
  console.log(profileData);
  // const { tooltipData } = useContext(TooltipContext);
  useEffect(() => {
    let user = cookies.get("user");
    console.log(user);

    setprofileData({
      user_id: cookies.get("user")?.user_id,
      profile_image: cookies.get("user")?.profile_image,
      email: cookies.get("user")?.email,
      username: cookies.get("user")?.username,
      // Emp_id: response?.data?.Data?.Emp_id,
      type: cookies.get("user")?.type,
    });
  }, []);

  useEffect(() => {
    if (profileData?.type == "Admin") {
      dispatch(fetchAllCompanyProfile());
    } 
  }, [dispatch, profileData?.type]);

  useEffect(() => {
    let access_token = cookies.get("access_token");
    let user = cookies.get("user");
    if (!access_token || !user) {
      navigate("/");
    }
  }, [cookies.get("user")]);
  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: (theme) => theme.palette.background.main,
      }}
    >
      <CssBaseline />
      {/* Side Bar Component  */}
      <SideBar
        open={open}
        setOpen={setOpen}
        setOnHover={setOnHover}
        OnHover={OnHover}
        selectedTab={selectedTab}
        setselectedTab={setselectedTab}
        selectedSecondTab={selectedSecondTab}
        setselectedSecondTab={setselectedSecondTab}
        isAdmin={false}
      />
      {/* Top Bar Component  */}

      <Box sx={{ flex: 1 }}>
        <TopBar
          open={open}
          setOpen={setOpen}
          setOnHover={setOnHover}
          openDialog={openDialog}
          setopenDialog={setopenDialog}
          isSettings={false}
          headerTitle={headerTitle}
        />
        <Divider />
        <Box
          position="sticky"
          sx={{
            backgroundColor: "transparent",
            // backgroundColor: (theme) => theme.palette.background.main,
            // padding: "12px",
          }}
        >
          {/* <TitleBar headerTitle={headerTitle} toolTipText={tooltipData} /> */}
        </Box>

        {/* UserDashboard Component  */}
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.background.main,
            // padding: "10px",
            // borderRadius:"15px"
          }}
        >
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              minHeight: "calc(100vh - 250px)",
              maxHeight: "fit-content ",
              backgroundColor: "white !important",
              borderRadius: "5px",
            }}
            onMouseOver={() => {
              if (OnHover === true) {
                setOpen(false);
                setOnHover(false);
              }
            }}
          >
            <Box>{activePage}</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default UserDashboard;

UserDashboard.propTypes = {
  activePage: PropTypes.any.isRequired,
  headerTitle: PropTypes.string.isRequired,
  toolTipText: PropTypes.string,
};
