import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  gazettedholidays: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,

};

export const fetchGazettedHolidays = createAsyncThunk(
  "getgazetttedholidays",
  async () => {
    const response = await axiosInstance.get(`getgazetttedholidays`);
    return response.data;
  }
);



const GazettedHolidaySlice = createSlice({
  name: "gazettedholidays",
  initialState,
  reducers: {
    GazettedHolidayAdded: {
      reducer(state, action) {
        state.gazettedholidays.push(action.payload);
      },
    },
    deleteGazHoliday: {
      reducer(state, action) {
        const userIndex = state?.gazettedholidays.findIndex(
          (gazettedholidays) => gazettedholidays.holiday_id === action.payload
        );
        if (userIndex !== -1) {
          state.gazettedholidays.splice(userIndex, 1);
        }
      },
    },
    GazzetResetData: (state) => {
      state.gazettedholidays= [];
      state.isSuccess= false;
      state.message= null;
      state.status= "idle"; //'idle' | 'loading' | 'succeeded' | 'failed'
      state.error= null;
      state.IsLogin= true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchGazettedHolidays.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchGazettedHolidays.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.gazettedholidays = action.payload.Gazetted_Holidays;
      })
      .addCase(fetchGazettedHolidays.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })

  },
});

export const getgazettedholidayDetails = (state) => state.gazettedholidays;

export const {  GazettedHolidayAdded,GazzetResetData,deleteGazHoliday } = GazettedHolidaySlice.actions;

export default GazettedHolidaySlice.reducer;