import {
  Box,
  Button,
  Typography,
  TextField,
  MenuItem,
  Grid,
  FormControl,
  Autocomplete,
} from "@mui/material";
window.Buffer = window.Buffer || require("buffer").Buffer;
import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchSingleEmployee,
  getemployeeDetails,
} from "../../Redux/Slice/Employee/EmplyeeSlice";

import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";

import { EmployeePromotionDemotionAPI } from "../../Api/Employee/EmployeePromotionDemotionAPI";
import theme from "../../theme";
import moment from "moment";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import {
  fetchMonthlyReportPopulate,
  getEmpmonthlyreportDetail,
} from "../../Redux/Slice/EmployeMonthlyReport/EmployeMonthlyReportSlice";
import {
  fetchDesignation,
  getDesignationDetails,
} from "../../Redux/Slice/Designation/DesignationSlice";

const useStyles = makeStyles(() => {
  return {
    input_typography: {
      textAlign: "left",
      fontWeight: "bold",
      fontSize: `14px !important`,
      marginTop: "10px !important",
    },

    btns: {
      width: "80px",
    },
    DetailContainer: {
      gap: "10px",

      padding: "20px",
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    },
    FieldInRow: {
      display: "flex",
      justifyContent: "space-between",
      gap: "20px",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },
    inputBox: {
      width: "100%",
    },
  };
});
const Typo = { width: { md: "150px", xs: "auto" }, fontWeight: "bold" };

const EmployeePromotionDemotionForm = ({ setShowForm }) => {
  const { input_typography, inputBox, DetailContainer, FieldInRow } =
    useStyles();
  const { setsnackBarData } = useContext(SnackBarContext);
  const [AllEmployees, setAllEmployees] = useState(null);
  const [singleChosenEmployee, setsingleChosenEmployee] = useState(null);
  const [empID, setempID] = useState(null);
  const [loading, setloading] = useState(false);
  const [showCancel, setshowCancel] = useState(true);
  const navigate = useNavigate();
  const { singleEmployee, employee, IsLogin, message } =
    useSelector(getemployeeDetails);
  const { Report_Populate } = useSelector(getEmpmonthlyreportDetail);
  const { designation } = useSelector(getDesignationDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    setempID(null);
    dispatch(fetchMonthlyReportPopulate());
    dispatch(fetchDesignation());
  }, []);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
    setAllEmployees(Report_Populate);
  }, [Report_Populate, employee]);

  useEffect(() => {
    if (singleEmployee) {
      setsingleChosenEmployee({
        ...singleEmployee?.Employee,
        type: "",
        date: moment().format("YYYY-MM-DD"),
        description: "",
        new_designation: "",
      });
    }
  }, [singleEmployee]);

  function handleChange(e) {
    e.stopPropagation();

    setsingleChosenEmployee({
      ...singleChosenEmployee,
      [e.target.name]: e.target.value,
    });
  }
  function handleGET(e) {
    e.stopPropagation();
    setshowCancel(false);
    if (!empID) {
      return setsnackBarData({
        type: "error",
        message: "Please Select Employee",
        openToast: true,
      });
    }
    dispatch(FetchSingleEmployee(empID));
  }

  async function handleSave() {
    setloading(true);
    if (
      !empID ||
      singleChosenEmployee.date == "" ||
      singleChosenEmployee.type == "" ||
      singleChosenEmployee.new_designation == "" ||
      singleChosenEmployee.description == ""
    ) {
      setloading(false);
      return setsnackBarData({
        type: "error",
        message: "Please Provide All Necessary Data ",
        openToast: true,
      });
    }
    let obj = {
      emp_id: empID,
      date: singleChosenEmployee.date,
      type: singleChosenEmployee.type,
      new_designation: singleChosenEmployee.new_designation,
      description: singleChosenEmployee.description,
    };
    const response = await EmployeePromotionDemotionAPI(obj);
    setsnackBarData(response.snackBarData);
    setloading(false);
    if (response.data.IsSuccess) {
      setShowForm(false);
    }
  }

  return (
    <>
      <Box
        sx={{
          minHeight: "calc(100vh - 173px)",
          width: "100%",
          padding: "40px 20px",
          display: "flex",
          justifyContent: " center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "900px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              gap: "10px",
            }}
          >
            <Box sx={{ flex: 3, maxWidth: "400px" }}>
              {/* <TextField
                size="small"
                variant="outlined"
                type="text"
                color="primary"
                sx={{
                  input: { color: "black" },
                  textAlign: "left",
                }}
                fullWidth
                select
                onChange={(e) => {
                  e.stopPropagation();
                  setsingleChosenEmployee(null);
                  setempID(e.target.value);
                }}
              >
                {AllEmployees &&
                  AllEmployees.map((data, index) => {
                    return (
                      <MenuItem key={index} value={data?.id}>
                        {data.name}
                      </MenuItem>
                    );
                  })}
              </TextField> */}
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                name="emp_id"
                options={AllEmployees}
                getOptionLabel={(option) => option.name}
                fullWidth
                // value={values.emp_id}
                size="small"
                onChange={(e, val) => {
                  console.log(e, val);
                  setsingleChosenEmployee(null);
                  setempID(val?.id);
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Employee" />
                )}
              />
            </Box>

            <Box sx={{ flex: 1 }}>
              <Box sx={{ margin: "0px auto", display: "flex", gap: "10px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleGET}
                  sx={{ width: "80px" }}
                >
                  GET
                </Button>

                {showCancel && (
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ width: "80px" }}
                    onClick={() => {
                      setShowForm(false);
                    }}
                  >
                    Cancel
                  </Button>
                )}
              </Box>
            </Box>
          </Box>

          {singleChosenEmployee && empID ? (
            <Grid container className={DetailContainer}>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Typography className={input_typography} sx={Typo}>
                    Father Name:
                  </Typography>
                  <Typography>{singleChosenEmployee?.father_name}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Typography className={input_typography} sx={Typo}>
                    Status:
                  </Typography>
                  <Typography>{singleChosenEmployee?.status}</Typography>

                  {singleChosenEmployee?.status ? (
                    <Typography>Active</Typography>
                  ) : (
                    <Typography>InActive</Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Typography className={input_typography} sx={Typo}>
                    Designation:{" "}
                  </Typography>
                  <Typography>
                    {singleChosenEmployee?.designation?.designation_name}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Typography className={input_typography} sx={Typo}>
                    Job Title:
                  </Typography>
                  <Typography>
                    {singleChosenEmployee?.jobtitle?.job_title}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{ display: "flex", justifyContent: "start" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Typography className={input_typography} sx={Typo}>
                    Department:
                  </Typography>
                  <Typography>
                    {singleChosenEmployee?.department?.department_name}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          ) : null}
          {singleChosenEmployee && empID ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: "10px",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Box className={FieldInRow}>
                <Box className={inputBox}>
                  <Typography sx={{ textAlign: "left" }}>Type</Typography>
                  <FormControl fullWidth sx={{ textAlign: "left" }}>
                    <TextField
                      onChange={handleChange}
                      size="small"
                      name="type"
                      variant="outlined"
                      value={singleChosenEmployee?.type}
                      type="text"
                      color="primary"
                      sx={{ input: { color: "black" } }}
                      fullWidth
                      select
                    >
                      <MenuItem value="Promotion">Promotion</MenuItem>
                      <MenuItem value="Demotion">Demotion</MenuItem>
                    </TextField>
                  </FormControl>
                </Box>

                <Box className={inputBox}>
                  <Typography sx={{ textAlign: "left" }}>Salary</Typography>
                  <FormControl fullWidth sx={{ textAlign: "left" }}>
                    <TextField
                      size="small"
                      variant="outlined"
                      value={singleChosenEmployee?.basic_salary}
                      type="text"
                      color="primary"
                      sx={{ input: { color: "black" } }}
                      fullWidth
                      disabled
                    />
                  </FormControl>
                </Box>
              </Box>

              <Box className={FieldInRow}>
                <Box className={inputBox}>
                  <Typography sx={{ textAlign: "left" }}>
                    Designation
                  </Typography>
                  <FormControl fullWidth sx={{ textAlign: "left" }}>
                    <TextField
                      onChange={handleChange}
                      name="new_designation"
                      size="small"
                      variant="outlined"
                      value={singleChosenEmployee?.amount}
                      type="text"
                      color="primary"
                      sx={{ input: { color: "black" } }}
                      fullWidth
                      select
                    >
                      {designation &&
                        designation.map((desig, index) => {
                          return (
                            <MenuItem key={index} value={desig.designation_id}>
                              {desig.designation_name}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </FormControl>
                </Box>
                <Box className={inputBox}>
                  <Typography sx={{ textAlign: "left" }}>Date</Typography>
                  <FormControl fullWidth sx={{ textAlign: "left" }}>
                    <TextField
                      onChange={handleChange}
                      value={singleChosenEmployee?.date}
                      name="date"
                      size="small"
                      variant="outlined"
                      type="date"
                      color="primary"
                      sx={{ input: { color: "black" } }}
                      fullWidth
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box className={FieldInRow}>
                <Box className={inputBox}>
                  <Typography sx={{ textAlign: "left" }}>
                    Description
                  </Typography>
                  <FormControl fullWidth sx={{ textAlign: "left" }}>
                    <TextField
                      onChange={handleChange}
                      size="small"
                      name="description"
                      variant="outlined"
                      type="text"
                      color="primary"
                      multiline
                      value={singleChosenEmployee?.description}
                      minRows={5}
                      sx={{ input: { color: "black" } }}
                      fullWidth
                    />
                  </FormControl>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  gap: "20px",
                  margin: "20px 0px",
                  width: "100%",
                }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation();
                    setsingleChosenEmployee(null);
                    setShowForm(false);
                  }}
                >
                  Cancel
                </Button>
                {loading == false ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    sx={{ width: "80px" }}
                  >
                    Save
                  </Button>
                ) : (
                  <CircularProgress />
                )}
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
    </>
  );
};

export default EmployeePromotionDemotionForm;

EmployeePromotionDemotionForm.propTypes = {
  setShowForm: PropTypes.any,
  empData: PropTypes.any,
  setisEdited: PropTypes.any,
  isEdited: PropTypes.any,
};
