import { Box } from '@mui/system';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
// import SalaryDetails from '../../Components/SalaryDetails/SalaryDetails';
import SalaryGeneMain from '../../Components/SalaryDetails/SalaryGeneMain';

function SalaryDetailsMain() {
  const [canView, setcanView] = useState(null);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  useEffect(() => {
    let string = "PayrollDetails";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    let match = value?.some((val) => {
      if (val?.module?.split("_")[1] === "View") {
        return true;
      }
    });
    setcanView(match);
    console.log(match);
    if (!roles || roles.length < 1) {
      setcanView(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  return (
    <div>
            {canView === true ? (

    // <SalaryDetails/>
    <SalaryGeneMain/>
    ) : canView === false ? (
      <Navigate to={"/dashboard"} />
    ) : (
      <Box>Loading ...</Box>
    )}
    </div>
  )
}

export default SalaryDetailsMain;