import {
  Box,
  // CircularProgress,
  gridClasses,
  IconButton,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useContext } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  fetchLoanDeduction,
  getLoanDeductionDetails,
} from "../../Redux/Slice/LoanDeductionRequest/LoanDeductionRequestSlice";
import moment from "moment";
import RuleIcon from "@mui/icons-material/Rule";
import DeductionApproveModal from "./DeductionApproveModal";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});
function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport csvOptions={{ fileName: "Loan Deduction" }} />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  );
}
function LoandDeductionRequest() {
  const navigate = useNavigate();
  const { tabelStyle } = useContext(TabelStyleContext);
  const { setsnackBarData } = useContext(SnackBarContext);
  const { header } = useStyles();
  const dispatch = useDispatch();
  const [rows, setrows] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const { LoanDeduction, status, IsLogin, message } = useSelector(
    getLoanDeductionDetails
  );
  const [loanDeduction, setloanDeduction] = useState([]);
  const [approveRejRow, setapproveRejRow] = useState([]);
  const [showModal, setshowModal] = useState(false);

  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [editpermit, seteditpermit] = useState(false);
  // console.log(crop);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  console.log(roles);
  useEffect(() => {
    let string = "LoanDeductionRequest";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Edit") {
        seteditpermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      seteditpermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  console.log(editpermit);

  console.log(rows);
  useEffect(() => {
    dispatch(fetchLoanDeduction());
  }, [dispatch]);
  const columns = [
    { field: "sr", headerName: "Sr #", width: 100, headerClassName: header },
    {
      field: "name",
      headerName: "Employee",
      width: 200,
      headerClassName: header,
    },
    {
      field: "created_at",
      headerName: "Requested On",
      width: 200,
      headerClassName: header,
    },
    {
      field: "amounts",
      headerName: "Amount",
      width: 200,
      headerClassName: header,
    },
    {
      field: "note",
      headerName: "Note",
      width: 200,
      headerClassName: header,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      headerClassName: header,
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      headerClassName: header,
      renderCell: (celVal) => {
        return (
          <>
            {editpermit ? (
              <Tooltip title="Accepted/Rejected">
                <IconButton
                  onClick={() => {
                    setapproveRejRow(celVal.row);
                    setshowModal(true);
                    // setIsEdit(true);
                  }}
                >
                  <RuleIcon />
                </IconButton>
              </Tooltip>
            ) : null}
          </>
        );
      },
    },
  ];
  useEffect(() => {
    let arr = [];
    console.log(LoanDeduction);
    LoanDeduction &&
      LoanDeduction?.forEach((item, idx) => {
        return arr.push({
          ...item,
          id: idx + 1,
          sr: idx + 1,
          amounts: item?.req_amount?.toLocaleString(),
          created_at: moment(item?.created_at).format("DD-MMM-YYYY"),
        });
      });

    setrows(arr);
    setloanDeduction(arr);
  }, [LoanDeduction]);
  useEffect(() => {
    if (status === "failed") {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
    }
  }, [status]);
  return (
    <>
      {LoanDeduction?.length<=0 && status === "loading" ? <Loader /> : null}
      <Box
        style={{
          height: tabelStyle.height,
          width: "100%",
          padding: "10px",
        }}
      >
        <DataGrid
          rows={loanDeduction}
          columns={columns}
          onRowClick={() => {}}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          pagination
          components={{
            Toolbar: CustomToolbar,
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />
      </Box>

      <DeductionApproveModal
        setapproveRejRow={setapproveRejRow}
        approveRejRow={approveRejRow}
        showModal={showModal}
        setshowModal={setshowModal}
      />
    </>
  );
}

export default LoandDeductionRequest;
