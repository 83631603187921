import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import * as Yup from "yup";
import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import theme from "../../theme";
import PropTypes from "prop-types";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { LoanDeductionReq } from "../../Api/EmployeeSalaryDetails/LoanDeductionReq";
import { fetchsingleSlaryDetails } from "../../Redux/Slice/SalaryDetails/SalaryDetailsSlice";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import { useEffect } from "react";

const useStyles = makeStyles(() => {
  return {
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
    },
    Container: {
      width: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    container: {
      // height: "100%",
      width: "100%",
      padding: "0px 20px 20px 20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      // padding: "20px",
      width: "100%",
      // maxWidth: "700px",
      // minHeight: "calc(100vh - 215px)",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      textAlign: "left",
    },
  };
});
function EditModal({ showModal, setshowModal, EditedDataRow,setEditedDataRow }) {
  const {
    Container,
    ModalContainer,
    TableHeader,
    container,
    subContainer,
    formContainer,
  } = useStyles();
  const { setsnackBarData } = useContext(SnackBarContext);
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const initialValues = {
    note: "",
    amount: "",
  };
  console.log(EditedDataRow);
  useEffect(()=>{
if(EditedDataRow){
    formik.setFieldValue("amount",EditedDataRow?.loan_deducted)
}
  },[EditedDataRow])
  console.log(EditedDataRow);
  const validationSchema = Yup.object().shape({
    note: Yup.string().required("Please Enter Note"),
    amount: Yup.string().required("Please Enter Amount"),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleSave(values, resetForm, setSubmitting);
    },
  });
  const handleSave = async (values, setSubmitting, resetForm) => {
    const obj = { ...values,report_id: EditedDataRow?.report_id};
    console.log(obj);
    const response = await LoanDeductionReq(obj);
    setsnackBarData(response.snackBarData);
    if (response.data.IsSuccess) {
      dispatch(fetchsingleSlaryDetails(cookies.get("user").Emp_id));
      resetForm();
      setSubmitting(false);

      setshowModal(false);
    }
    setSubmitting(false);
  };
  return (
    <>
      <Modal
        open={showModal}
        onClose={() => {
          setshowModal(false);
        }}
      >
        <Box className={ModalContainer}>
          <Box className={Container}>
            <Box className={TableHeader}>
              <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                Loan Deduction Request
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setEditedDataRow(null);
                  setshowModal(false);
                }}
              >
                <HighlightOffIcon
                  color="primary"
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Box>
            <Box className={container}>
              <Box className={subContainer}>
                <form onSubmit={formik.handleSubmit}>
                  <Box className={formContainer}>
                    <FormControl fullWidth>
                      <Typography>Loan Deducted</Typography>
                      <TextField
                        sx={{ textAlign: "left", input: { color: "black" } }}
                        fullWidth
                        size="small"
                        name="amount"
                        type={"number"}
                        onBlur={formik.handleBlur}
                        onChange={
                            (e) => {
                                if (e.target.value > EditedDataRow?.loan_deducted){
                                    return
                                } 
                                else{
                                    formik.setFieldValue("amount",e.target.value)
                                }
                            }
                            // formik.handleChange
                        }
                        value={formik.values.amount}
                        variant="outlined"
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <Typography>Note</Typography>
                      <TextField
                        sx={{ textAlign: "left", input: { color: "black" } }}
                        fullWidth
                        size="small"
                        name="note"
                        type={"text"}
                        multiline
                        rows={4}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.note}
                        variant="outlined"
                        error={
                          Boolean(formik.touched.note) &&
                          Boolean(formik.errors.note)
                        }
                        helperText={
                          Boolean(formik.touched.note) && formik.errors.note
                        }
                      />
                    </FormControl>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "end",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <Box>
                          {formik.isSubmitting ? (
                            <CircularProgress />
                          ) : (
                            <Button type="submit" variant="contained">
                              <Typography>Submit</Typography>
                            </Button>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default EditModal;
EditModal.propTypes = {
  showModal: PropTypes.any,
  setshowModal: PropTypes.any,
  EditedDataRow: PropTypes.any,
  setEditedDataRow: PropTypes.any,
};
