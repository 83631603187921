import { Box } from "@mui/system";
import React from "react";
// import TitleBar from "../../Components/TopBar/UserDashBoard/TitleBar";
import PropTypes from "prop-types";
import TopBar from "../../Components/TopBar/UserDashBoard/TopBar";
import { Divider } from "@mui/material";
import { useState } from "react";
import SettingSideBar from "../../Components/ProfileDashboard/SideBar";
import { makeStyles } from "@mui/styles";
import theme from "../../theme";
import { useEffect } from "react";
import { ProfileContext } from "../../Context/ProfileContext/ProfileContext";
import { useContext } from "react";
import Cookies from "universal-cookie";
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const useStyles = makeStyles(() => {
  return {
    dashbaord_main: {
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      minHeight: "calc(100vh - 115px)",
      maxHeight: "fit-content",
      borderRadius: "15px !important",

      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        height: "fit-content",
      },
    },
  };
});

const UserProfile = ({ headerTitle, activePage }) => {
  const cookies = new Cookies();
  const { dashbaord_main } = useStyles();
  const [OnHover, setOnHover] = useState(false);
  const [openDialog, setopenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const { setprofileData } = useContext(ProfileContext);
  useEffect(() => {
    let user = cookies.get("user");
  console.log("jjdhjw")
    setprofileData({
      user_id: user?.user_id,
      profile_image: user?.profile_image,
      email: user?.email,
      username: user?.username,
      // Emp_id: response?.data?.Data?.Emp_id,
      type: user?.type,
    });
  }, [])
  console.log(open, OnHover);
  return (
    <div>
      <TopBar
        open={true}
        setOpen={setOpen}
        setOnHover={setOnHover}
        openDialog={openDialog}
        setopenDialog={setopenDialog}
        isSettings={false}
        headerTitle={headerTitle}
      />
      <Divider />
      <Box
        position="sticky"
        sx={{
          backgroundColor: (theme) => theme.palette.background.main,
          padding: "12px",
        }}
      >
        {/* <TitleBar headerTitle={headerTitle} /> */}
      </Box>
      <Box sx={{ padding: "10px" }}>
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.white.main,
            borderRadius: "5px !important",
          }}
        >
          <Box className={dashbaord_main}>
            <SettingSideBar />
            <Box
              component="main"
              sx={{
                flexGrow: 1,

                // padding:"20px"
                // minHeight: "calc(100vh - 250px)",
                // maxHeight: "fit-content ",
                // backgroundColor: "white !important",
                // borderRadius:"5px"
              }}
              onMouseOver={() => {
                if (OnHover === true) {
                  setOpen(false);
                  setOnHover(false);
                }
              }}
            >
              <Box
                sx={{
                  p: 3,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {activePage}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default UserProfile;
UserProfile.propTypes = {
  activePage: PropTypes.any.isRequired,
  headerTitle: PropTypes.string.isRequired,
};
