import {
    Box,
    Button,
    // CircularProgress,
    Grid,
    gridClasses,
    InputAdornment,
    TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useContext, useEffect, useRef, } from "react";
import {
    DataGrid,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { fetchSalaryDetails, getSalaryDetailsDetails } from "../../Redux/Slice/SalaryDetails/SalaryDetailsSlice";
import { useReactToPrint } from "react-to-print";
import SalaryDetailsPrint from "./SalaryDetailsPrint";
import { useNavigate } from "react-router-dom";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import Loader from "../Loader/Loader";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});
function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarExport csvOptions={{ fileName: "Salary Detail" }} />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  );
}
function SalaryDetails() {
  const navigate = useNavigate();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { header } = useStyles();
  const dispatch = useDispatch();
  const [rows, setrows] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const { SalaryDetails, status, IsLogin, message } = useSelector(
    getSalaryDetailsDetails
  );
  const [salaryDetails, setsalaryDetails] = useState([]);
  const Name = useRef();
  const componentRef = useRef();
  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);

  useEffect(() => {
    dispatch(fetchSalaryDetails());
  }, [dispatch]);
  const columns = [
    { field: "sr", headerName: "Sr #", width: 100, headerClassName: header },
    {
      field: "Department_Name",
      headerName: "Department",
      width: 200,
      headerClassName: header,
    },
    {
      field: "month",
      headerName: "Month",
      width: 180,
      headerClassName: header,
    },
    {
      field: "emp_name",
      headerName: "Employee",
      width: 200,
      headerClassName: header,
    },
    {
      field: "expected_hours",
      headerName: "Expected Hours",
      width: 150,
      headerClassName: header,
    },
    {
      field: "worked_hours",
      headerName: "Worked Hours",
      width: 150,
      headerClassName: header,
    },
    {
      field: "ot_hrs",
      headerName: "Extra Hours",
      width: 150,
      headerClassName: header,
    },
    {
      field: "wasted_hrs",
      headerName: "Short Hours",
      width: 150,
      headerClassName: header,
    },
    {
      field: "basic_salary",
      headerName: "Basic Salary",
      width: 200,
      headerClassName: header,
    },
    {
      field: "actual_salary",
      headerName: "Actual Salary",
      width: 150,
      headerClassName: header,
    },
    {
      field: "loan_deducted",
      headerName: "Loan",
      width: 180,
      headerClassName: header,
    },
    {
      field: "advance_deducted",
      headerName: "Advance",
      width: 180,
      headerClassName: header,
    },

    {
      field: "net_salary",
      headerName: "Net Salary",
      width: 150,
      headerClassName: header,
    },
  ];
  useEffect(() => {
    let arr = [];
    let serial = 0;
    SalaryDetails &&
      SalaryDetails?.forEach((item) => {
        item?.Employees.forEach((emp) => {
          console.log(emp);
          serial++;
          return arr.push({
            ...emp,
            id: serial,
            sr: serial,
            Department_Name: item?.Department_Name,
            wasted_hrs: emp?.wasted_hrs,
            ot_hrs: emp?.ot_hrs,
            worked_hours: emp?.worked_hours,
            // ? `${emp?.worked_hours.split(":")[0]} hrs ${emp?.worked_hours.split(":")[1]
            // } min `
            // : 0,
            basic_salary: emp?.basic_salary?.toLocaleString(),
            actual_salary: emp?.actual_salary?.toLocaleString(),
            net_salary: emp?.net_salary?.toLocaleString(),
          });
        });
      });

    setrows(arr);
    setsalaryDetails(arr);
  }, [SalaryDetails]);
  const onTextChange = () => {
    if (Name.current.value === "") {
      setsalaryDetails(rows);
    } else {
      let suggestions = [];
      let regex1 = new RegExp(Name.current.value, "i");

      suggestions = rows?.filter(
        (val) => Name.current?.value === "" || regex1.test(val?.emp_name)
      );

      setsalaryDetails(suggestions);
    }
  };
  return (
    <>
      <Box sx={{ display: "none" }}>
        <SalaryDetailsPrint ref={componentRef} salaryDetails={salaryDetails} />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            padding: "10px 10px 0px 10px",
          }}
        >
          <Button
            onClick={() => {
              printPaymentRec();
            }}
            sx={{ padding: "5px 10px" }}
            variant="contained"
          >
            Print
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            alignItems: "center",
            padding: "10px 10px 0px 10px",
          }}
        >
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: (theme) => theme.palette.grey.G2 }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              width: "100%",
              maxWidth: "180px",
            }}
            variant="standard"
            type="text"
            autoComplete="off"
            placeholder="Employee"
            className="input_border"
            inputRef={Name}
            onChange={onTextChange}
          />
        </Box>
      </Box>

      <span className="ml-2 float-right">
        <div className="loaderclass"> </div>
      </span>

      {status !== "succeeded" ? <Loader /> : null}
      <Grid item md={12} sm={12} xs={12} overflow>
        <div
          style={{
            height: "calc(100vh - 217px)",
            width: "100%",
            padding: "10px",
          }}
        >
          <DataGrid
            rows={salaryDetails}
            columns={columns}
            onRowClick={() => {}}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            pagination
            components={{
              Toolbar: CustomToolbar,
            }}
            localeText={{
              toolbarDensity: "Size",
              toolbarDensityLabel: "Size",
              toolbarDensityCompact: "Small",
              toolbarDensityStandard: "Medium",
              toolbarDensityComfortable: "Large",
            }}
          />
        </div>
      </Grid>
    </>
  );
}

export default SalaryDetails;
