import {
  Box,
  CircularProgress,
  LinearProgress,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { useNavigate } from "react-router-dom";
import {
  GetOnTime,
  getDashboardActivities,
} from "../../../Redux/Slice/Dashboard/DashboardSlice";
import { logout } from "../../../Redux/Slice/LogoutSlice/LogoutSlice";
const useStyle = makeStyles((theme) => {
  return {
    container: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      gap: "20px",
      borderRadius: "20px",
      color: theme.palette.white.main,
      transition: "0.5s",
    },
    heading: {
      textAlign: "left",
      padding: "10px",
      fontWeight: "bold",
      color: theme.palette.grey[700],
    },
    progress: {
      "& .MuiLinearProgress-colorPrimary": {
        backgroundColor: "red !important",
      },
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "green !important",
      },
    },
  };
});
function OnTimeTable() {
  const { heading } = useStyle();
  const dispatch = useDispatch();
  const { setsnackBarData } = useContext(SnackBarContext);
  const navigate = useNavigate();

  const { onTime, IsLogin, message, onTimestatus } = useSelector(
    getDashboardActivities
  );
  console.log("onTime", onTime);

  useEffect(() => {
    dispatch(GetOnTime());
  }, [dispatch]);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  return (
    <div style={{ padding: "10px 20px" }}>
      <Typography variant="h6" className={heading} sx={{}}>
        On-time Members
      </Typography>
      {onTimestatus !== "succeeded" ? (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : onTime?.finalTopEmployees?.length > 0 ? (
        onTime?.finalTopEmployees?.map((emp, index) => (
          <Box
            sx={{
              width: "100%",
              padding: "10px",
              border: "1px solid #cfcfcf",
              borderRadius: "10px",
              mb: 2,
            }}
            key={index}
          >
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography>{emp.Name}</Typography>
              <div style={{ display: "flex" }}>
                <Typography>
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {emp?.early_count} in 90
                  </span>{" "}
                  days
                </Typography>
              </div>
            </Box>
            <LinearProgress
              //   className={progress}
              variant="determinate"
              color="success"
              value={(emp?.early_count / 90) * 100}
            />
          </Box>
        ))
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "60%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src="./NoData.png"
            alt="No Data Found "
            style={{ height: "100%", width: "95%" }}
          />
        </Box>
      )}
    </div>
  );
}

export default OnTimeTable;
