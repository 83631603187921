import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  ProjectSubscription: [],
  Projects: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,

};


export const fetchProjectSucbscriptions = createAsyncThunk(
  "getprojsubdata",
  async () => {
    const response = await axiosInstance.get(`getprojsubdata`);

    return response.data;
  }
);

export const ProjectSubscriptionPopulate = createAsyncThunk(
  "projsubscriptionpopulate",
  async () => {
    const response = await axiosInstance.get(`projsubscriptionpopulate`);
    return response.data;
  }
);

const ProjectSubscriptionSlice = createSlice({
  name: "ProjectSubscription",
  initialState,
  reducers: {
    ProjectSubscriptionAdded: {
      reducer(state, action) {
        state.ProjectSubscription.push(action.payload);
       
      },
    },
    ProjectSubscriptionUpdated: {
      reducer(state, action) {
        state.ProjectSubscription.push(action.payload);
      },
    },
    ProjSubResetData: (state) => {
      state.ProjectSubscription= [];
      state.Projects= [];
      state.isSuccess= false;
      state.message= null;
      state.status= "idle"; //'idle' | 'loading' | 'succeeded' | 'failed'
      state.error= null;
      state.IsLogin= true;
    },

  },
  extraReducers(builder) {
    builder
      .addCase(fetchProjectSucbscriptions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProjectSucbscriptions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.ProjectSubscription = action.payload.ProjectSubData;
      })
      .addCase(fetchProjectSucbscriptions.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(ProjectSubscriptionPopulate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(ProjectSubscriptionPopulate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Projects = action.payload.Projects;
      })
      .addCase(ProjectSubscriptionPopulate.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
  },
});

export const getSubScriptionDetails = (state) => state.ProjectSubscription;

export const { ProjectSubscriptionAdded,ProjectSubscriptionUpdated,ProjSubResetData } = ProjectSubscriptionSlice.actions;

export default ProjectSubscriptionSlice.reducer;
