import PropTypes from "prop-types";
import {
  Box,
  Button,
  // CircularProgress,
  Grid,
  gridClasses,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchOneMonthReport,
  getmonthlyreportDetails,
} from "../../Redux/Slice/MonthlyReport/MonthlyReportSlice";
import { makeStyles } from "@mui/styles";
import { useRef } from "react";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import MonthlyReportPrint from "./MonthlyReportPrint";
import { useReactToPrint } from "react-to-print";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import Loader from "../Loader/Loader";
// import theme from "../../theme";
const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});

function MonthlyReportTable({ setShowForm, setrowvalue, rowvalue }) {
  const { header } = useStyles();
  const { tabelStyle } = useContext(TabelStyleContext);
  const [rows, setrows] = useState([]);
  const [filterRows, setfilterRows] = useState([]);
  const [pageSize, setPageSize] = useState(40);
  const navigate = useNavigate();
  const EmployeeName = useRef();

  const dispatch = useDispatch();
  const { setsnackBarData } = useContext(SnackBarContext);
  console.log(rowvalue);
  console.log(setrowvalue);

  const componentRef = useRef();
  const printPaymentRec = useReactToPrint({
    content: () => componentRef.current,
  });

  const { oneMonthReports, status, IsLogin, message, oneMonthGazzetHolidays } =
    useSelector(getmonthlyreportDetails);
  console.log(oneMonthReports);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [addPermit, setaddpermit] = useState(false);
  // console.log(crop);

  console.log(roles);
  useEffect(() => {
    let string = "MonthlyReport";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Add") {
        setaddpermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      setaddpermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);

  console.log(addPermit);

  useEffect(() => {
    if (rowvalue) {
      console.log(moment(rowvalue?.month).format("YYYY-MM"));
      dispatch(
        FetchOneMonthReport({
          month: moment(rowvalue?.month).format("YYYY-MM"),
        })
      );
    }
  }, [dispatch]);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  useEffect(() => {
    if (rowvalue) {
      let arr = [];
      if (oneMonthReports) {
        console.log(oneMonthReports);
        oneMonthReports?.forEach((item, idx) => {
          return arr.push({
            id: idx + 1,
            Employee: item?.emp_id.name,
            Expected_hours: item?.expected_hrs.toLocaleString(),
            Estimated_work: convertToHoursAndMinutes(
              item?.estimated_work_hrs.toLocaleString()
            ),
            Logged_work: convertToHoursAndMinutes(
              item?.project_working_hrs.toLocaleString()
            ),
            date: item.month ? moment(item.month).format("MMMM-YYYY") : "",
            // item.month ? moment(item.month).format("DD MMM, YYYY") : "",
            OT_Hours: item?.ot_hrs,
            workinghrs: convertDecimalHoursToHoursAndMinutes(item?.worked_hrs),
            wastedhrs: convertDecimalHoursToHoursAndMinutes(item?.wasted_hrs),
            // ? `${item?.worked_hrs.split(":")[0]} hrs ${
            //     item?.worked_hrs.split(":")[1]
            //   } min `
            // : 0,

            absent: item?.total_absents,
            present: item?.total_present,
            clleave: item?.avail_CasualLeaves,
            alleave: item?.avail_AnnualLeaves,
          });
        });
      }
      setrows(arr);
      setfilterRows(arr);
    }
  }, [oneMonthReports]);

  function convertToHoursAndMinutes(timeString) {
    if (!timeString == "") {
      const [hours, minutes] = timeString.split(":").map(Number);
      return `${hours} hours ${minutes} minutes`;
    } else {
      return "N/A";
    }
  }
  function convertDecimalHoursToHoursAndMinutes(decimalHours) {
    const hours = Math.floor(decimalHours);
    const minutes = Math.floor((decimalHours - hours) * 60);
    return `${hours} hours ${minutes} minutes`;
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "Monthly Report" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "id", headerName: "Sr #", width: 100, headerClassName: header },
    // {
    //   field: "date",
    //   headerName: "Month",
    //   width: 160,
    //   // type: "date",
    //   headerClassName: header,
    // },
    {
      field: "Employee",
      headerName: "Employee",
      width: 160,
      headerClassName: header,
    },
    {
      field: "Expected_hours",
      headerName: "Expected Hours",
      width: 160,
      headerClassName: header,
    },
    {
      field: "Estimated_work",
      headerName: "Estimated Work",
      width: 190,
      headerClassName: header,
    },
    {
      field: "Logged_work",
      headerName: "Logged Work",
      width: 190,
      headerClassName: header,
    },
    {
      field: "OT_Hours",
      headerName: "Extra Hours",
      width: 150,
      headerClassName: header,
    },
    {
      field: "workinghrs",
      headerName: "Working Hours",
      width: 185,
      headerClassName: header,
    },
    {
      field: "wastedhrs",
      headerName: "Short Hours",
      width: 185,
      headerClassName: header,
    },

    {
      field: "present",
      headerName: "Presents",
      width: 120,
      headerClassName: header,
    },
    {
      field: "absent",
      headerName: "Absents",
      width: 110,
      headerClassName: header,
    },
    {
      field: "clleave",
      headerName: "Casual  leaves",
      width: 140,
      headerClassName: header,
    },
    {
      field: "alleave",
      headerName: "Annual leave ",
      width: 140,
      headerClassName: header,
    },
  ];

  const onTextChange = () => {
    if (EmployeeName.current.value === "") {
      setfilterRows(rows);
    } else {
      let suggestions = [];

      let regex1 = new RegExp(EmployeeName.current.value, "i");

      suggestions = rows?.filter(
        (val) => EmployeeName.current.value === "" || regex1.test(val.Employee)
      );

      setfilterRows(suggestions);
    }
  };

  console.log("oneMonthReports", oneMonthReports);
  return (
    <>
      <Box sx={{ display: "none" }}>
        <MonthlyReportPrint
          ref={componentRef}
          filterRows={filterRows}
          name={""}
          month={moment(rowvalue?.month).format("MMM-YYYY")}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          padding: "20px 10px 10px 10px",
          // [theme.breakpoints.down("sm")]:{
          //   flexDirection:"column"
          // }
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <IconButton
            sx={{
              padding: "5px 15px",
              color: (theme) => theme.palette.grey[500],
              borderRadius: 1,
            }}
            // variant="outlined"
            onClick={() => {
              setShowForm(false);
              setrowvalue(null);
            }}
            // className="tabletopbutton1 text-right"
          >
            <ArrowBackIcon />
          </IconButton>
          <Button
            onClick={() => {
              printPaymentRec();
            }}
            sx={{ padding: "5px 10px" }}
            variant="contained"
          >
            Print
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "30px",
          }}
        >
          <Typography variant="h6">Month: {rowvalue?.month}</Typography>
          <Typography variant="h6">
            Gazetted Holidays: {oneMonthGazzetHolidays}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
          }}
        >
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: (theme) => theme.palette.grey.G2 }}
                  />
                </InputAdornment>
              ),
            }}
            variant="standard"
            type="text"
            autoComplete="off"
            placeholder="Employee"
            // className="input_border"
            id="empName"
            inputRef={EmployeeName}
            onChange={onTextChange}
          />
        </Box>
      </Box>

      {status !== "succeeded" ? (
        <>
          <Loader />
        </>
      ) : null}
      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        overflow
        sx={{ height: tabelStyle.height, padding: "10px" }}
      >
        <DataGrid
          rows={filterRows}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[40, 50, 60, 70, 80, 90, 100]}
          components={{
            Toolbar: CustomToolbar,
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
          hideFooter={true}
        />
      </Grid>
    </>
  );
}

export default MonthlyReportTable;
MonthlyReportTable.propTypes = {
  setShowForm: PropTypes.any,
  setrowvalue: PropTypes.any,
  rowvalue: PropTypes.any,
};
