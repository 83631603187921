// import { Alert, Snackbar } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import EmployeeForm from "../../Components/Employee/EmployeeForm";
import EmployeeTable from "../../Components/Employee/EmployeeTable";
import Snackbar from "@mui/material/Snackbar";
import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchEmployee,
  getemployeeDetails,
} from "../../Redux/Slice/Employee/EmplyeeSlice";
import FullWidthTabs from "../EmployeeDetails/EmployeeDetails";

const EmployeeMain = () => {
  const [ShowForm, setShowForm] = useState(false);
  const [ShowDetails, setShowDetails] = useState(false);

  const [message, setmessage] = useState("");
  const [openToast, setopenToast] = useState(false);
  const [AlertType, setAlertType] = useState("success");
  const [empData, setempData] = useState(null);
  const [isEdited, setisEdited] = useState(false);
  const [canView, setcanView] = useState(null);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const employeeDetails = useSelector(getemployeeDetails);
  const { IsLogin } = useSelector(getemployeeDetails);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    let string = "ActiveEmployees";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    let match = value?.some((val) => {
      if (val?.module?.split("_")[1] === "View") {
        return true;
      }
    });
    setcanView(match);
    console.log(match);
    if (!roles || roles.length < 1) {
      setcanView(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  useEffect(() => {
    console.log("call");
    dispatch(fetchEmployee({ page: page, pageSize: pageSize }));
  }, [dispatch]);
  // console.log(empData);
  return (
    <div>
      {canView === true ? (
        ShowDetails ? (
          <FullWidthTabs
            setShowDetails={setShowDetails}
            data={empData}
          />
        ) : ShowForm ? (
          <EmployeeForm
            setShowForm={setShowForm}
            setopenToast={setopenToast}
            setmessage={setmessage}
            setAlertType={setAlertType}
            empData={empData}
            isEdited={isEdited}
            setisEdited={setisEdited}
          />
        ) : (
          <EmployeeTable
            setopenToast={setopenToast}
            setmessage={setmessage}
            setAlertType={setAlertType}
            setShowForm={setShowForm}
            setShowDetails={setShowDetails}
            setempData={setempData}
            isEdited={isEdited}
            setisEdited={setisEdited}
            employeeDetails={employeeDetails}
            IsLogin={IsLogin}
            setPage={setPage}
            page={page}
            setPageSize={setPageSize}
            pageSize={pageSize}
          />
        )
      ) : canView === false ? (
        <Navigate to={"/dashboard"} />
      ) : (
        <Box>Loading ...</Box>
      )}
      <Snackbar
        openToast={openToast}
        setopenToast={setopenToast}
        AlertType={AlertType}
        message={message}
      />
    </div>
  );
};

export default EmployeeMain;
EmployeeMain.propTypes = {
  setShowForm: PropTypes.any,
  setisEdited: PropTypes.any,
  isEdited: PropTypes.any,
};
