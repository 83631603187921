import {
  Box,
  Button,
  FormControl,
  Typography,
  TextField,
  CircularProgress,
  MenuItem,
  IconButton,
  Tooltip,
} from "@mui/material";
window.Buffer = window.Buffer || require("buffer").Buffer;
import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import { mixed, object, string } from "yup";
import "react-image-crop/dist/ReactCrop.css";
import { useContext } from "react";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { HeaderContext } from "../../../Context/SnackBarContext/HeaderContext/HeaderContext";
import { logout } from "../../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import RemoveIcon from "@mui/icons-material/Remove";
import UploadIcon from "@mui/icons-material/Upload";
import CollectionsIcon from "@mui/icons-material/Collections";
import "react-phone-number-input/style.css";

import {
 
  getemployeeDetails,
} from "../../../Redux/Slice/Employee/EmplyeeSlice";
// import Loader from "../../Loader/Loader";
import theme from "../../../theme";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
// import axiosInstance from "../../../Utils/AxiosInstance";

import Modal from "../../StageModal.jsx/Modal";

import { EditorState,ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import axiosInstance from "../../../Utils/AxiosInstance";
const useStyles = makeStyles(() => {
  return {
    inputBox: {
      width: "100%",
      minWidth: "440px",
      [theme.breakpoints.down("custom")]: {
        minWidth: "0px"
      },
    },
    input_typography: {
      textAlign: "left",
      fontSize: `15px !important`,
      marginTop: "10px !important",
    },
    Form_input: {
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,
    },
    btns: {
      width: "80px",
    },
    uploadBoxContainer: {
      minWidth: "100%",
      position: "relative",
    },
    IconContainer: {
      paddingTop: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    reviewBox: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
      flexWrap: "wrap",
    },
    FieldInRow: {
      display: "flex",
      justifyContent: "space-between",
      gap: "20px",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },
    imgcontainer: {
      width: "120px",
      height: "120px",
      borderRadius: "50%",
      padding: "10px",
    },

  };
});

let validationSchema = object(
  {
  
  title: string().required("This field is required"),
  subject: string().required("This field is required"),
  type: string().required("This field is required"),
 
  
});


const EmailForm = ({ setShowForm, empData, setisEdited, isEdited }) => {
  const { Form_input, input_typography, btns, inputBox, FieldInRow, uploadBoxContainer, IconContainer, reviewBox } =
    useStyles();
  const [loading, setloading] = useState(false);


  const [open, setOpen] = useState(false)
  const navigate = useNavigate();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { IsLogin, message } =
    useSelector(getemployeeDetails);
  const { setheader } = useContext(HeaderContext);

  const [image, setImage] = useState(null);
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );
  const [convertedContent, setConvertedContent] = useState(null);
  const inputRef2 = useRef();
  const [forceUpdate, setForceUpdate] = React.useState(0)
  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    
    // console.log(formik.values.body)
    setConvertedContent(html);
    // console.log(convertedContent)
  }, [editorState]);

  
  const dispatch = useDispatch();



  const initialValues = {
    type: "",
    title: "",



    subject: "",
    images: "",
    body:""
  };


  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handelAdd(values, resetForm, setSubmitting);
     
    },
  });
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);


  console.log(formik?.errors)

  useEffect(() => {
    setForceUpdate((prev) => prev + 1);
  }, [formik?.values.type]);
  const handelAdd = async (values,resetForm) => {
    
      setloading(true);
      formik.values.body=convertedContent
      const formData = new FormData();
      Object.keys(values).map((key) => {
        formData.append(key, values[key]);
      });

      if (isEdited) {
        formData.append("id",empData.id)
        try {
          let resp=await axiosInstance.put("updateEmailTemplates",formData)
          console.log(resp)
          let snackBarData = {
            type: resp?.data.IsSuccess ? "success" : "error",
            message: resp.data.Message,
            openToast: true,
          };
  
          setsnackBarData(snackBarData)
          resetForm(false)
          
          setShowForm(false);
        } catch (error) {
          console.log(error)
        }
      }else{
        try {
          let resp=await axiosInstance.post("createEmailTemplate",formData)
          console.log(resp)
          let snackBarData = {
            type: resp?.data.IsSuccess ? "success" : "error",
            message: resp.data.Message,
            openToast: true,
          };
  
          setsnackBarData(snackBarData)
          resetForm(false)
          setShowForm(false);
        } catch (error) {
          console.log(error)
        }
      }
     

      setloading(false);
      
  };

  
  useEffect(() => {
    
    if (isEdited) {
      formik.setFieldValue("title", empData?.title);
      formik.setFieldValue("subject", empData?.subject);
      formik.setFieldValue("type", empData?.type);
      formik.setFieldValue("images", empData?.images);

      setConvertedContent(empData?.body);
      let body=htmlToDraft(empData?.body)
      if (body) {
        const contentState = ContentState.createFromBlockArray(body.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);

        setEditorState(editorState)
      }
     
     setloading(false)
    }
  }, []);



  useEffect(()=>{
    if (formik?.values.type != "Text"&&formik?.values.type != "") {
      validationSchema = object(
        {
        
        title: string().required("This field is required"),
        subject: string().required("This field is required"),
        type: string().required("This field is required"),
       
        images:mixed().required("This field is required"),
      });
    }else{
      validationSchema = object(
        {
        
        title: string().required("This field is required"),
        subject: string().required("This field is required"),
        type: string().required("This field is required"),
       
        
      });
    }
  },[formik?.values.type])

  useEffect(()=>{
    formik.values.images=image?.file
  },[image])

  return (
    <>

      {open && <Modal onClose={() => setOpen(false)} />}

      
      <Box
        sx={{
          minHeight: "calc(100vh - 165px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: "20px",
        }}
      >
        <form onSubmit={formik?.handleSubmit}>
          <Box>
            <Box
              sx={{
                maxWidth: "900px",
                width: "100%",
                display: "flex",
                gap: "20px",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Box className={FieldInRow}>




                <Box className={inputBox}>
                  <Typography className={input_typography}>Title</Typography>
                  <FormControl fullWidth sx={{ textAlign: "left" }}>
                    <TextField
                      onChange={formik?.handleChange}
                      onBlur={formik.handleBlur}
                      size="small"
                      name="title"
                      value={formik?.values.title}
                      variant="outlined"
                      type="text"
                      color="primary"
                      sx={{ input: { color: "black" } }}
                      className={Form_input}
                      autoComplete="off"
                      inputProps={{ autoComplete: "off" }}
                      
                      fullWidth
                      error={
                        Boolean(formik?.errors.title) &&
                        Boolean(formik?.touched.title)
                      }
                      helperText={
                        Boolean(formik?.touched.title) && formik?.errors.title
                      }
                    />
                  </FormControl>
                </Box>

                <Box className={inputBox}>
                  <Typography className={input_typography}>
                    Subject
                  </Typography>

                  <FormControl fullWidth sx={{ textAlign: "left" }}>
                    <TextField
                      onBlur={formik.handleBlur}
                      onChange={formik?.handleChange}
                      size="small"
                      name="subject"
                      value={formik?.values.subject}
                      variant="outlined"
                      type="text"
                      color="primary"
                      sx={{ input: { color: "black" } }}
                      className={Form_input}
                      fullWidth
                      error={
                        Boolean(formik?.errors.subject) &&
                        Boolean(formik?.touched.subject)
                      }
                      helperText={
                        Boolean(formik?.touched.subject) &&
                        formik?.errors.subject
                      }
                    />
                  </FormControl>
                </Box>
              </Box>


              <Box className={FieldInRow}>


                <Box className={inputBox}>
                  <Typography className={input_typography}>
                    Type
                  </Typography>
                  <FormControl fullWidth sx={{ textAlign: "left" }}>
                    <TextField


                      size="small"
                      select
                      onChange={(e) => {
                        formik?.handleChange(e);
                      }}
                      name="type"
                      value={formik?.values.type}
                      className={Form_input}
                      error={
                        Boolean(formik?.errors.type) &&
                        Boolean(formik?.touched.type)
                      }
                      helperText={
                        Boolean(formik?.touched.type) && formik?.errors.type
                      }
                    >

                      <MenuItem value="Text">Text</MenuItem>
                      <MenuItem value="Image">Image</MenuItem>
                      <MenuItem value="Document">Document</MenuItem>
                    </TextField>
                  </FormControl>
                </Box>

                {formik?.values.type != "Text" && formik?.values.type != "" ? <Box className={inputBox}>
                  <Typography className={input_typography}>
                    {formik?.values.type}
                  </Typography>
                  {formik?.values.type == "Image" ? <input
                    // name="cnicF"
                    ref={inputRef2}
                    id="images"
                    key={forceUpdate}
                    type="file"
                    name="images"
                    onChange={(e) => {
                      // handleChange(e);
                      e.stopPropagation();
                      const obj = {
                        file: e.target.files[0],
                        url: URL.createObjectURL(e.target.files[0]),
                      };
                      setImage(obj);
                      // formik.values.images=obj
                    }}
                    accept={"image/*"}
                    
                    style={{
                      width: "100%",
                      display: "none",
                      position: "absolute",
                    }}
                  /> : <input
                    // name="cnicF"
                    ref={inputRef2}
                    id="images"
                    key={forceUpdate}
                    type="file"
                    onChange={(e) => {
                      // handleChange(e);
                      e.stopPropagation();
                      const obj = {
                        file: e.target.files[0],
                        url: URL.createObjectURL(e.target.files[0]),
                      };
                      setImage(obj);
                      console.log(obj)
                    }}
                    accept={".pdf, .doc, .docx"}
                  
                    style={{
                      width: "100%",
                      display: "none",
                      position: "absolute",
                    }}
                  />}
                  <Box className={uploadBoxContainer}>
                    <Box className={IconContainer}>
                      <Box className={reviewBox}>
                        {image !== null ? (
                          <Box
                            sx={{
                              height: "40px",
                              width: "200px",
                              position: "relative",
                            }}
                          >
                            <IconButton
                              sx={{
                                transform: "translate(-465%,-50%)",
                                position: "absolute",
                                background: (theme) =>
                                  theme.palette.error.main,
                                padding: "0px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setImage(null)
                              }}
                            >
                              <RemoveIcon
                                sx={{
                                  color: (theme) => theme.palette.white.main,
                                  fontWeight: "bold",
                                }}
                              />
                            </IconButton>

                            <Typography
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                paddingTop: "5px",
                                textAlign: "left",
                              }}
                            >
                              {image.file.name}
                            </Typography>
                          </Box>
                        ) : (
                          <CollectionsIcon
                            sx={{
                              color: (theme) => theme.palette.primary.main,
                            }}
                          />
                        )}
                      </Box>
                      <Box sx={{ minWidth: "100px", textAlign: "right" }}>
                        <IconButton onClick={() => inputRef2.current.click()}>
                          <Tooltip title="Upload Cnic Front">
                            <UploadIcon
                              sx={{
                                color: (theme) => theme.palette.primary.main,
                              }}
                            />
                          </Tooltip>
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                  {formik.errors.images&&<div style={{color:"#d32f2f",fontSize:" 0.80rem"}}>This field is required</div>}
                </Box> :
                  <Box className={inputBox}>
                  
                  </Box>}
                    


              </Box>



              <Box className={FieldInRow}>
                <Box className={inputBox} >
                  <Typography className={input_typography}>
                    Body
                  </Typography>
                  <Box sx={{ border: "1px solid #bdbdbd", padding: "8px" }}>


                    <Editor
                    placeholder="Start Typing your body from here"
                      editorState={editorState}
                      onEditorStateChange={setEditorState}
                    />

                  </Box>
                  {formik.errors.body&&<div style={{color:"#d32f2f",fontSize:" 0.80rem"}}>This field is required</div>}
                </Box>



              </Box>


              {/* three divs */}

            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <Box
                sx={{
                  float: "right",
                  display: "flex",
                  gap: "10px",
                  marginTop: "20px",
                }}
                spacing={2}
              >

                <Button
                  className={btns}
                  variant="contained"
                  color="error"
                  onClick={() => {
                    setShowForm(false);
                    setisEdited(false);
                    setheader(null);
                  }}
                >
                  Cancel
                </Button>

                {loading === false ? (
                  !isEdited ? (
                    <Button
                      className={btns}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Save
                    </Button>
                  ) : (
                    <Button
                      className={btns}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Update
                    </Button>
                  )
                ) : (
                  <CircularProgress />
                )}


              </Box>
            </Box>
          </Box>
        </form>




      </Box>



    </>


  );
};

export default EmailForm;

EmailForm.propTypes = {
  setShowForm: PropTypes.any,
  empData: PropTypes.any,
  setisEdited: PropTypes.any,
  isEdited: PropTypes.any,
};
