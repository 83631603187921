import {
  Box,
  CircularProgress,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import theme from "../../theme";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import {
  fetchAdminLogWork,
  getattendanceDetails,
} from "../../Redux/Slice/Attendance/AttendanceSlice";
const useStyles = makeStyles(() => {
  return {
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
    },
    Container: {
      width: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    container: {
      // height: "100%",
      width: "100%",
      padding: "0px 20px 20px 20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "5px",
      },
    },
  };
});
function ViewLogWorkModal({
  setlogWorkRow,
  showModal,
  setshowModal,
  logWorkRow,
}) {
  const { Container, ModalContainer, TableHeader, container } = useStyles();
  const { AdminLogWork, status2 } = useSelector(getattendanceDetails);
  console.log(logWorkRow);
  console.log(AdminLogWork);
  console.log(status2);
  const dispatch = useDispatch();
  useEffect(() => {
    if (logWorkRow) {
      dispatch(
        fetchAdminLogWork({
          id: logWorkRow?.Emp_id,
          date: logWorkRow?.date,
        })
      );
    }
  }, [logWorkRow]);
  return (
    <>
      <Modal
        open={showModal}
        onClose={() => {
          setshowModal(false);
        }}
      >
        <Box className={ModalContainer}>
          <Box className={Container}>
            <Box className={TableHeader}>
              <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                Log Work
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setlogWorkRow(null);
                  setshowModal(false);
                }}
              >
                <HighlightOffIcon
                  color="primary"
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Box>
            {status2 === "loading" ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box className={container}>
                <TableContainer
                  //   component={Paper}
                  sx={{ height: "400px", overflowY: "scroll" }}
                >
                  <Table
                    sx={{
                      minWidth: { md: "auto", xs: "auto" },
                      overflow: "scroll",
                    }}
                    aria-label="simple table"
                    size="small"
                  >
                    <TableHead>
                      <TableRow size="small">
                        <TableCell
                          size="small"
                          align="left"
                          sx={{
                            fontWeight: "bold",
                            color: (theme) => theme.palette.grey["500"],
                          }}
                        >
                          Sr #
                        </TableCell>
                        <TableCell
                          size="small"
                          align="left"
                          sx={{
                            fontWeight: "bold",
                            color: (theme) => theme.palette.grey["500"],
                          }}
                        >
                          Status
                        </TableCell>
                        <TableCell
                          size="small"
                          align="left"
                          sx={{
                            fontWeight: "bold",
                            color: (theme) => theme.palette.grey["500"],
                          }}
                        >
                          Time
                        </TableCell>
                        <TableCell
                          size="small"
                          align="left"
                          sx={{
                            fontWeight: "bold",
                            color: (theme) => theme.palette.grey["500"],
                          }}
                        >
                          Note
                        </TableCell>
                        <TableCell
                          size="small"
                          align="left"
                          sx={{
                            fontWeight: "bold",
                            color: (theme) => theme.palette.grey["500"],
                          }}
                        >
                          Time Zone
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {AdminLogWork?.map((row, i) => {
                        let timezone = row?.emp_id?.shift_rules?.time_zone
                          ? JSON.parse(row?.emp_id?.shift_rules?.time_zone)
                          : {};
                        return (
                          <TableRow
                            key={i}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell size="small" component="th" scope="row">
                              {i + 1}
                            </TableCell>
                            <TableCell size="small" component="th" scope="row">
                              {row?.status}
                            </TableCell>
                            <TableCell size="small" align="left">
                              {row?.time
                                ? moment(row?.time, "HH:mm:ss").format(
                                    "hh:mm A"
                                  )
                                : ""}
                            </TableCell>
                            <TableCell size="small" align="left">
                              {row?.note}
                            </TableCell>
                            <TableCell size="small" align="left">
                              {timezone?.label}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default ViewLogWorkModal;
ViewLogWorkModal.propTypes = {
  showModal: PropTypes.any,
  setshowModal: PropTypes.any,
  setlogWorkRow: PropTypes.any,
  logWorkRow: PropTypes.any,
};
