import {
    IconButton,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
const useStyles = makeStyles(() => {
    return {
        ModalContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            padding: "10px",
            width: "100%",
        },
        Container: {
            width: "630px",
            height: '311px',
            padding: "20px",
            border: "none",
            position: "relative",
            background: "white",
            borderRadius: "10px",
            display: "flex",
            gap: "20px",
            flexDirection: "column",
        },
        TableContain: { height: "450px", overflowY: "scroll" },
        TableHeader: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
    };
});

const DetailExtraHours = ({ open, setOpen, employeExtrahours }) => {
    const { TableContain, Container, ModalContainer, TableHeader } = useStyles();

    return (
        <>
            <Modal open={open}>
                <Box className={ModalContainer}>
                    <Box className={Container}>
                        <Box className={TableHeader}>
                            <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                                Detail Extra Hours
                            </Typography>
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setOpen(!open);
                                }}
                            >
                                <HighlightOffIcon
                                    color="primary"
                                    sx={{
                                        fontSize: "28px",
                                    }}
                                />
                            </IconButton>
                        </Box>
                        <TableContainer component={Paper} className={TableContain}>
                            <Table
                                stickyHeader
                                sx={{
                                    minWidth: { md: "auto", xs: "auto" },
                                    overflow: "scroll",
                                }}
                                aria-label="simple table"
                                size="small"
                            >
                                <TableHead>
                                    <TableRow size="small">
                                        <TableCell size="small" sx={{ fontWeight: "bold" }}>
                                            Sr#
                                        </TableCell>
                                        <TableCell size="small" sx={{ fontWeight: "bold" }}>
                                            Name
                                        </TableCell>
                                        <TableCell size="small" sx={{ fontWeight: "bold" }}>
                                            Date
                                        </TableCell>
                                        <TableCell size="small" sx={{ fontWeight: "bold" }}>
                                            Extra Hours
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {employeExtrahours &&
                                        employeExtrahours?.extrahrs?.map((row, i) => (
                                            <TableRow
                                                key={i}
                                                sx={{
                                                    "&:last-child td, &:last-child th": { border: 0 },
                                                }}
                                            >
                                                <TableCell size="small" component="th" scope="row">
                                                    {1 + i}
                                                </TableCell>
                                                <TableCell size="small">
                                                    {`${row?.emp_name}`}
                                                </TableCell>
                                                <TableCell size="small">
                                                    {`${row?.date}`}
                                                </TableCell>
                                                <TableCell size="small">
                                                    {`${row?.emp_extra_hrs} H`}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Modal>


        </>
    );
};


export default DetailExtraHours;

DetailExtraHours.propTypes = {
    open: PropTypes.any,
    setOpen: PropTypes.any,
    employeExtrahours: PropTypes.any,
};