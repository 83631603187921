import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Modal,
  TableRow,
  Table,
  TableCell,
  DialogTitle,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { makeStyles } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import theme from "../../theme";
const useStyles = makeStyles(() => {
  return {
    table_modal: {
      fontFamily: "Poppins !important",
      backgroundColor: theme.palette.common.white,
      padding: "5px",
        position: "absolute",
        top: "50%",
        left: "50%",
      transform: "translate(-50%, -50%)",
      width: "40%",
      height: "fit-content",
      maxHeight: "fit-content",
      border: "1px solid #888",
      borderRadius: " 10px",
      //   overFlow: "hidden",
    },
  };
});
const ProjectTableModal = ({ data, showModal, setshowModal ,}) => {
  const { table_modal } = useStyles();

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  //   const [expanded, setExpanded] = React.useState(false);

  //   const handleChange = (panel) => (isExpanded) => {
  //     setExpanded(isExpanded ? panel : false);
  //   };
  // CutProdSizingColor
  return (
    <>
      <Modal
        //   disableScrollLock
        sx={{  height:"100vh" , overflow:"scroll"}}
        open={showModal}
        onClose={() => {
          setshowModal(false);
        }}
      >
        <Box
          sx={{
            height: "100vh",
            overFlow: "scroll",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box className={table_modal}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <DialogTitle>Project Details</DialogTitle>
              <HighlightOffIcon
                color="primary"
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setshowModal(false);
                }}
              />
            </Box>
            <Box sx={{display:"flex",gap:"20px",paddingX:"20px"}}>
              <Typography sx={{ fontWeight: "bold" }}>Project Name:</Typography>
              <Typography >
                {data?.project_name}
              </Typography>
            </Box>
            <Box sx={{ overFlow: "scroll" }}>
              <Table sx={{ marginTop: "20px", marginLeft: 2, width: "90%" }}>
                <StyledTableRow>
                  <TableCell sx={{ fontWeight: "bold", width: 300 }}>
                    Sr. No
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", width: 300 }}>
                    Modules
                  </TableCell>
                </StyledTableRow>
              </Table>
              {data?.module?.map((x, idx) => {
                return (
                  <Accordion
                    key={idx}
                    // expanded={expanded}
                    // onChange={handleChange(idx)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <StyledTableRow>
                        <TableCell sx={{ width: 300 }}>{idx + 1}</TableCell>
                        <TableCell sx={{ width: 300 }}>
                          {x?.module_name}
                        </TableCell>
                      </StyledTableRow>
                    </AccordionSummary>
                    {x?.submodules?.length > 0 && (
                      <AccordionDetails>
                        <Table>
                          <TableRow>
                            <TableCell sx={{ fontWeight: "bold" }}>
                              Sr. No
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                              Sub Modules
                            </TableCell>
                          </TableRow>
                          {x?.submodules?.map((subMod, idx) => {
                            return (
                              <>
                                <TableRow>
                                  <TableCell>{idx + 1}</TableCell>

                                  <TableCell>
                                    {subMod?.submodule_name}
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                        </Table>
                      </AccordionDetails>
                    )}
                  </Accordion>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ProjectTableModal;

ProjectTableModal.propTypes = {
  data: PropTypes.any,
  showModal: PropTypes.any,
  setshowModal: PropTypes.any,
};
