import React, { useContext } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
  // Button,
  // CircularProgress,
  gridClasses,
  InputAdornment,
  // Link,
  TextField,
  Tooltip,
  // Typography,
} from "@mui/material";
import {
  fetchChangeRequest,
  fetchsingleEmpChangeReq,
  getChangerequestDetails,
  // RemoveChangeRequest,
} from "../../Redux/Slice/ChangeRequest/ChangeRequestSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import CompanyStatusForm from "./CompanyStatusForm";
import ClientStatusForm from "./ClientStatusForm";
import AmendStatus from "./AmendStatus";
import SearchIcon from "@mui/icons-material/Search";
import { useRef } from "react";
import { makeStyles } from "@mui/styles";
import Cookies from "universal-cookie";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import ChangeReqTableModal from "./ChangeReqTableModal";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../Button/AddNewButton";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import Loader from "../Loader/Loader";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
    compCircle: {
      width: "100%",
      maxWidth: "20px",
      height: "20px",
      borderRadius: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
});
const cookies = new Cookies();
const ChangeinReqTable = ({ setShowForm }) => {
  const { tabelStyle } = useContext(TabelStyleContext);

  const { header, compCircle } = useStyles();
  const [filtertoStatus, setFiltertoStatus] = useState([]);
  const [filterClientStatus, setFilterClientStatus] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, settotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [filterRows, setfilterRows] = useState([]);
  const [AmendStatusFilter, setAmendStatusFilter] = useState([]);
  const [showupdateForm, setShowUpdateForm] = useState(
    "Table",
    "CompanyForm",
    "ClientForm",
    "AmendStatus"
  );
  const [allChangeData, setAllChangeData] = useState([]);
  const dispatch = useDispatch();
  const [SelectedRow, setSelectedRow] = useState({});
  const [showModal, setshowModal] = useState(false);
  const changeRequestProject = useRef();
  const changeRequestModule = useRef();
  const changeRequestsubModule = useRef();
  const {
    ChangeInRequests,
    status,
    TotalCount,
    PageNum,
    PageSize,
    IsLogin,
    message,
  } = useSelector(getChangerequestDetails);
  const [Row, setRow] = useState(null);
  const user = cookies.get("user");
  const navigate = useNavigate();
  const { setsnackBarData } = useContext(SnackBarContext);

  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [editPermit, seteditpermit] = useState(false);
  const [addPermit, setaddpermit] = useState(false);
  // console.log(crop);

  console.log(roles);
  useEffect(() => {
    let string = "ChangeRequest";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Edit") {
        seteditpermit(true);
      } else if (val?.module?.split("_")[1] === "Add") {
        setaddpermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      setaddpermit(true);
      seteditpermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  console.log(editPermit);
  console.log(addPermit);

  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
    if (ChangeInRequests) {
      setAllChangeData(ChangeInRequests);
      setPage(PageNum);
      setPageSize(PageSize);
      settotalCount(TotalCount);
    }
  }, [ChangeInRequests, IsLogin]);

  useEffect(() => {
    if (cookies.get("user")?.type === "Employee") {
      dispatch(fetchsingleEmpChangeReq(cookies.get("user").Emp_id));
    } else if (cookies.get("user")?.type === "Admin") {
      if (ChangeInRequests.length < pageSize * (page + 1)) {
        dispatch(fetchChangeRequest({ page: page, pageSize: pageSize }));
      }
    }
  }, [dispatch]);
  // useEffect(() => {
  //   const user = cookies.get("user");
  //   if (user?.type === "Employee") {
  //     dispatch(fetchsingleEmpChangeReq(user?.Emp_id));
  //   } else if (user?.type === "Admin") {
  //     dispatch(fetchChangeRequest({ page: 1, pageSize: 10 }));
  //   }
  // }, []);
  const columns = [
    { field: "id", headerName: "Sr #", width: 70, headerClassName: header },
    {
      field: "project_name",
      headerName: "Project",
      width: 180,
      headerClassName: header,
    },
    {
      field: "module_name",
      headerName: "Module",
      width: 180,
      headerClassName: header,
    },
    {
      field: "submodule_name",
      headerName: "Sub Module",
      width: 180,
      headerClassName: header,
    },
    {
      field: "subject",
      headerName: "Subject",
      width: 250,
      headerClassName: header,
    },
    // {
    //   field: "google_docs",
    //   headerName: "Google Docs",
    //   width: 180,
    //   headerClassName: header,
    //   renderCell: (celVal) => {
    //     return (
    //       <Link href={celVal.row?.google_docs} target={"_blank"}>
    //         {celVal.row?.google_docs}
    //       </Link>
    //     );
    //   },
    // },
    {
      field: "priority",
      headerName: "Priority",
      width: 110,
      headerClassName: header,
    },
    {
      field: "complexity",
      headerName: "Complexity",
      width: 110,
      headerClassName: header,
      renderCell: (celVal) => {
        return celVal?.row?.complexity ? (
          <Tooltip sx={{ cursor: "pointer" }} title={celVal?.row?.complexity}>
            <Box
              className={compCircle}
              sx={{
                backgroundColor:
                  celVal?.row?.complexity === "easy"
                    ? "green"
                    : celVal?.row?.complexity === "medium"
                    ? "orange"
                    : celVal?.row?.complexity === "hard"
                    ? "blue"
                    : celVal?.row?.complexity === "complex"
                    ? "red"
                    : null,
              }}
            ></Box>
          </Tooltip>
        ) : null;
      },
    },
    {
      field: "company_status",
      headerName: "Comp Status",
      width: 180,
      headerClassName: header,
      renderCell: (celval) => {
        return (
          <Box
            sx={{
              display: "flex",

              alignItems: "center",
              justifyContent: "space-between",
              paddingX: "15px",
              width: "100%",
            }}
          >
            <Box>{celval?.row?.company_status}</Box>
            <Box>
              {celval.row.company_status === "completed" ||
              editPermit === false ? null : (
                <Tooltip title="Update company status">
                  <AppRegistrationIcon
                    sx={{ color: "black", cursor: "pointer" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowUpdateForm("CompanyForm"),
                        handleCompanyStatus(celval);
                    }}
                  />
                </Tooltip>
              )}
            </Box>
          </Box>
        );
      },
    },

    {
      field: "client_status",
      headerName: "Client Status",
      width: 180,
      headerClassName: header,
      renderCell: (celval, idx) => {
        return (
          <Box
            key={idx}
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              justifyContent: "space-between",
              paddingX: "15px",
              width: "100%",
            }}
          >
            <Box>{celval?.row?.client_status}</Box>
            <Box>
              {celval.row?.client_status === "approved" ? null : (celval?.row
                  ?.company_status === "completed" &&
                  celval?.row?.AmendStatus !== "pending") ||
                celval?.row?.AmendStatus.length == 0 ? (
                editPermit ? (
                  <Tooltip title="Update client status">
                    <AppRegistrationIcon
                      key={idx}
                      sx={{ color: "black", cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowUpdateForm("ClientForm"),
                          handleClientStatus(celval);
                      }}
                    />
                  </Tooltip>
                ) : null
              ) : null}
            </Box>
          </Box>
        );
      },
    },

    {
      field: "AmendStatus",
      headerName: "Amend Status",
      width: 180,
      headerClassName: header,
      renderCell: (celval) => {
        return (
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              justifyContent: "space-between",
              paddingX: "15px",
              width: "100%",
            }}
          >
            <Box>{celval?.row?.AmendStatus}</Box>
            <Box>
              {celval.row.AmendStatus === "pending" ? (
                editPermit ? (
                  <Tooltip title="Update Amend status">
                    <AppRegistrationIcon
                      sx={{ color: "black", cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();

                        setShowUpdateForm("AmendStatus"),
                          handleAmendStatus(celval);
                      }}
                    />
                  </Tooltip>
                ) : null
              ) : (
                ""
              )}
            </Box>
          </Box>
        );
      },
    },

    // {
    //   field: "Update_CompanyStatus",
    //   headerName: "Company Status",
    //   width: 160,
    //   headerClassName: header,
    //   renderCell: (celval) => {

    //     return (
    //       <>
    //         {celval.row.company_status === "completed" ? null : (
    //           <Button
    //             sx={{
    //               background: "#3480FA",
    //               color: "white !important",
    //               textTransform: "none",
    //             }}
    //             onClick={() => {
    //               setShowUpdateForm("CompanyForm"), handleCompanyStatus(celval);
    //             }}
    //           >
    //             Update
    //           </Button>
    //         )}
    //       </>
    //     );
    //   },
    // },
    // {
    //   field: "Update_ClientStatus",
    //   headerName: "Approval",
    //   width: 150,
    //   headerClassName: header,
    //   renderCell: (celval) => {

    //     return (
    //       <>
    //         {celval.row?.client_status === "approved" ? null : celval?.row
    //             ?.company_status === "completed" &&
    //           celval?.row?.AmendStatus !== "pending" ? (
    //           <Button
    //             sx={{
    //               background: "#3480FA",
    //               color: "white !important",
    //               textTransform: "none",
    //             }}
    //             onClick={() => {
    //               setShowUpdateForm("ClientForm"), handleClientStatus(celval);
    //             }}
    //           >
    //             Update
    //           </Button>
    //         ) : null}
    //       </>
    //     );
    //   },
    // },
    // {
    //   field: "AmendmentStatus",
    //   headerName: "Completion",
    //   width: 130,
    //   headerClassName: header,
    //   renderCell: (celval) => {
    //     return (
    //       <>
    //         {celval.row.AmendStatus === "pending" ? (
    //           <Button
    //             sx={{
    //               textTransform: "none",

    //               background: "red",
    //               color: "white !important",

    //             }}
    //             onClick={() => {
    //               setShowUpdateForm("AmendStatus"), handleAmendStatus(celval);
    //             }}
    //           >
    //             Update
    //           </Button>
    //         ) : (
    //           ""
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];
  useEffect(() => {
    let arr = [];
    allChangeData &&
      allChangeData?.forEach((changeData, ind) => {
        console.log(changeData);
        let amendmentStatus = changeData?.amendement?.find((amend) => {
          return amend?.amend_status === "pending";
        });

        arr.push({
          ...changeData,
          id: ind + 1,
          description: changeData?.description,
          priority: changeData?.priority,
          company_status: changeData?.company_status,
          client_status: changeData?.client_status,
          google_docs: changeData?.google_docs,
          project_name: changeData?.project?.project_name,
          module_name: changeData?.module?.module_name,
          submodule_name: changeData?.submodule?.submodule_name,
          request_id: changeData?.request_id,
          req_timeline: changeData?.reqtimeline,
          complexity: changeData?.reqtimeline?.complexity,
          Amendement: changeData?.amendement,
          AmendStatus:
            changeData?.amendement?.length > 0
              ? amendmentStatus?.amend_status
                ? "pending"
                : "completed"
              : "",
          subject: changeData?.subject,
        });
      });

    setRow(arr);
    setfilterRows(arr);
  }, [allChangeData]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "ChangeRequest" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
        {/* <GridCsvExportOptions fileName="jhdjwdhwjh" /> */}
      </GridToolbarContainer>
    );
  }

  const handleAmendStatus = (celval) => {
    setAmendStatusFilter(celval.row);
  };
  const handleCompanyStatus = (celval) => {
    setFiltertoStatus(celval.row);
  };
  const handleClientStatus = (celval) => {
    setFilterClientStatus(celval.row);
  };

  const onTextChange = () => {
    let name = changeRequestProject.current.value;
    let modules = changeRequestModule.current.value;
    let Priority = changeRequestsubModule.current.value;
    if (name === "" && module === "" && Priority === "") {
      setfilterRows(Row);
    } else {
      let suggestions = [];
      const regex = new RegExp(name, "i");
      const regex1 = new RegExp(modules, "i");
      const regex2 = new RegExp(Priority, "i");
      suggestions = Row?.filter(
        (val) =>
          (name === "" || regex.test(val.project_name)) &&
          (modules === "" || regex1.test(val.module_name)) &&
          (Priority === "" || regex2.test(val.priority))
      );

      setfilterRows(suggestions);
    }
  };
  useEffect(() => {
    if (status === "failed") {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
    }
  }, [status]);

  return (
    <Box>
      {showupdateForm === "Table" ? (
        <Box
          sx={{
            padding: "15px 10px 6px 10px",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          {user?.type === "Null" || user?.type === "Null" ? null : addPermit ? (
            <>
              {/* <Button
                sx={{ padding: "5px 10px" }}
                variant="contained"
                className="tabletopbutton1 text-right"
                onClick={() => setShowForm(true)}
              >
                New
              </Button> */}
              <CustomButton
                Color={"primary"}
                Icon={<AddIcon />}
                onClick={() => setShowForm(true)}
                Label={"Add"}
              />
            </>
          ) : null}

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              gap: "10px",
            }}
          >
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{ color: (theme) => theme.palette.grey.G2 }}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{ width: "100%", maxWidth: "180px" }}
              variant="standard"
              type="text"
              autoComplete="off"
              inputRef={changeRequestProject}
              placeholder="Project"
              id="ProjectNameFilter"
              onChange={onTextChange}
            />
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{ color: (theme) => theme.palette.grey.G2 }}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{ width: "100%", maxWidth: "180px" }}
              variant="standard"
              type="text"
              autoComplete="off"
              placeholder="Module"
              id="ProjectNameFilter"
              inputRef={changeRequestModule}
              onChange={onTextChange}
            />
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{ color: (theme) => theme.palette.grey.G2 }}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{ width: "100%", maxWidth: "180px" }}
              variant="standard"
              type="text"
              inputRef={changeRequestsubModule}
              autoComplete="off"
              placeholder="Priority"
              id="ProjectNameFilter"
              onChange={onTextChange}
            />
          </Box>
        </Box>
      ) : null}
      {showupdateForm === "CompanyForm" ? (
        <CompanyStatusForm
          setShowUpdateForm={setShowUpdateForm}
          filtertoStatus={filtertoStatus}
        />
      ) : (
        ""
      )}
      {ChangeInRequests?.length<=0 && status === "loading" ? <Loader /> : null}
      {showupdateForm === "Table" ? (
        <Box sx={{ height: tabelStyle.height, padding: "10px" }}>
          <DataGrid
            rows={filterRows}
            columns={columns}
            rowCount={
              cookies.get("user")?.type === "Admin"
                ? totalCount
                : allChangeData?.length
            }
            pageSize={pageSize}
            page={
              cookies.get("user")?.type === "Admin" ? Number(page) - 1 : page
            }
            onPageChange={(newPage) => {
              if (cookies.get("user")?.type === "Admin") {
                setPage(newPage + 1);

                if (
                  Number(PageNum) < newPage + 1 &&
                  allChangeData?.length < pageSize * Number(newPage + 1)
                ) {
                  dispatch(
                    fetchChangeRequest({ page: newPage, pageSize: pageSize })
                  );
                }
              } else if (cookies.get("user")?.type === "Employee") {
                setPage(newPage);
              }
            }}
            onRowClick={(e) => {
              setSelectedRow(e.row);
              setshowModal(true);
            }}
            columnVisibilityModel={
              user?.type === "Employee"
                ? {
                    client_status: false,
                    status: false,
                    Update_ClientStatus: false,
                  }
                : { client_status: true, status: true }
            }
            onPageSizeChange={(newPageSize) => {
              if (cookies.get("user")?.type === "Admin") {
                if (allChangeData.length >= newPageSize) {
                  setPage(1);
                  setPageSize(newPageSize);
                } else if (newPageSize >= allChangeData?.length) {
                  // dispatch(RemoveChangeRequest())
                  setPage(1);

                  dispatch(
                    fetchChangeRequest({
                      page: Number(0),
                      pageSize: newPageSize,
                    })
                  );
                }
              }
              if (cookies.get("user")?.type === "Employee") {
                setPageSize(newPageSize);
              }
            }}
            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            pagination
            // paginationMode="server"
            sx={{ background: "white", cursor: "pointer" }}
            components={{
              Toolbar: CustomToolbar,
            }}
            localeText={{
              toolbarDensity: "Size",
              toolbarDensityLabel: "Size",
              toolbarDensityCompact: "Small",
              toolbarDensityStandard: "Medium",
              toolbarDensityComfortable: "Large",
            }}
          />
        </Box>
      ) : (
        ""
      )}
      {showupdateForm === "ClientForm" ? (
        <ClientStatusForm
          setShowUpdateForm={setShowUpdateForm}
          filterClientStatus={filterClientStatus}
        />
      ) : (
        ""
      )}
      {showupdateForm === "AmendStatus" ? (
        <AmendStatus
          setShowUpdateForm={setShowUpdateForm}
          AmendStatusFilter={AmendStatusFilter}
        />
      ) : (
        ""
      )}
      <ChangeReqTableModal
        data={SelectedRow}
        setshowModal={setshowModal}
        showModal={showModal}
      />
    </Box>
  );
};
export default ChangeinReqTable;
ChangeinReqTable.propTypes = {
  setShowForm: PropTypes.any,
  filtertoStatus: PropTypes.any,
  setShowUpdateForm: PropTypes.any,
  filterClientStatus: PropTypes.any,
  AmendStatusFilter: PropTypes.any,
};

// let arr=allChangeData;
// Array.prototype.splice.apply(
//   arr,
//   [
//     pageSize * page - pageSize,
//     ChangeInRequests,
//   ].concat(ChangeInRequests),
// );
