import { Box, IconButton, Modal, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const useStyles = makeStyles(() => {
  return {
    ModalContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      padding: "10px",
      width: "100%",
    },
    Container: {
      width: "800px",
      padding: "20px",
      border: "none",
      position: "relative",
      background: "white",
      borderRadius: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    TableContain: { height: "450px", overflowY: "scroll" },
    TableHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    child_box: {
      display: "flex",
      width: "100%",
      maxWidth: "350px",
    },
    box_heading: {
      fontWeight: "bold",
      width: "100%",
      maxWidth: "120px",
    },
  };
});
function AllowanceDetailModal({ data, showModal, setshowModal }) {
  const { Container, ModalContainer, TableHeader, child_box, box_heading } =
    useStyles();
  console.log(data);
  return (
    <>
      <Modal
        open={showModal}
        onClose={() => {
          setshowModal(false);
        }}
      >
        <Box className={ModalContainer}>
          <Box className={Container}>
            <Box className={TableHeader}>
              <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                Employee Allowance Detail
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setshowModal(false);
                }}
              >
                <HighlightOffIcon
                  color="primary"
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                overflow: "scroll",
              }}
            >
              <Box className={child_box}>
                <Typography className={box_heading}>Employee:</Typography>
                <Typography sx={{ paddingLeft: "30px" }}>
                  {data?.name}
                </Typography>
              </Box>
              <Box className={child_box}>
                <Typography className={box_heading}>Allowance Type:</Typography>
                <Typography
                  sx={{
                    paddingLeft: "30px",
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  {data?.allowancetype}
                </Typography>
              </Box>
              <Box className={child_box}>
                <Typography className={box_heading}>Amount:</Typography>
                <Typography sx={{ paddingLeft: "30px" }}>
                  {data?.amount}
                </Typography>
              </Box>
   
         
            </Box>
            <Box>
              <Typography sx={{ fontWeight: "bold" }}>Description:</Typography>
              <Typography sx={{ paddingLeft: "80px" }}>
                {data?.description}
              </Typography>
            </Box>

          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default AllowanceDetailModal;
AllowanceDetailModal.propTypes = {
  data: PropTypes.any,
  showModal: PropTypes.any,
  setshowModal: PropTypes.any,
};
