import { Paper, Typography, Button, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useContext, useState } from "react";
import shield from "../../Assets/shield.jpg";
import theme from "../../theme";
import AuthCode from "react-auth-code-input";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useLocation, useNavigate } from "react-router-dom";
import { EmailVarificationAPI } from "../../Api/EmailVarification/EmailVarification";

// import { useEffect } from "react";

const useStyles = makeStyles(() => {
  return {
    container: {
      width: "100%",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "20px",
    },
    PaperContainer: {
      width: "100%",
      maxWidth: "800px",
      background: theme.palette.white.main,
      padding: "20px",
    },
    message: {
      padding: "20px",
      [theme.breakpoints.down("sm")]: {
        padding: "10px 0px",
      },
    },
    ButtonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      gap: "20px",
      alignItems: "end",
    },
    containerAuthCode: {
      padding: "10px",
    },

    inputAuthCode: {
      width: "50px",
      height: "50px",
      padding: "0px",
      fontSize: "24px",
      margin: "5px",
      textAlign: "center",
      marginRight: "7px",
      textTransform: "uppercase",
      color: "#494949",
      border: "1px solid #d6d6d6",
      borderRadius: "4px",
      background: "#fff",
      backgroundClip: "padding-box",
    },
  };
});

const EmailVarification = () => {
  const {
    container,
    PaperContainer,
    message,
    ButtonContainer,
    containerAuthCode,
    inputAuthCode,
  } = useStyles();
  const [result, setResult] = useState("");
  const [loading, setLoading] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  const { state } = useLocation();
  console.log(state);
  const navigate = useNavigate();
  //   useEffect(() => {
  //     if (!state) {
  //       navigate(-1);
  //     }
  //   }, [navigate]);
  const handleOnChange = async (res) => {
    setResult(res);
  };
  const handleSubmit = async () => {
    setLoading(true);
    if (result.length < 4) {
      setLoading(false);
      return setsnackBarData({
        type: "error",
        message: "Please Enter A Valid Code.!",
        openToast: true,
      });
    }

    const obj = {
      email: state?.email,
      otp: result,
    };

    console.log(obj);

    const response = await EmailVarificationAPI(obj);
    setsnackBarData(response?.snackBarData);
    console.log(response);
    if (response.data?.IsSuccess) {
      setLoading(false);
      navigate(response.navigate, {
        state: {
          empId: response?.data?.Data?.Emp_id,
          user_id: response?.data?.Data?.user_id,
          email: response?.data?.Data?.email,
        },
      });
    }
    setLoading(false);
    console.log(result);
  };
  return (
    <Box className={container}>
      <Paper elevation={3} className={PaperContainer}>
        <Box>
          <Box>
            <img src={shield} alt="twofactorlogin" style={{ width: "150px" }} />
          </Box>
          <Box>
            <Typography variant="h5">Authenticate Your Account</Typography>
          </Box>
          <Box className={message}>
            <Typography>
              Protecting your account is our first priority. Please enter the
              code sent to your email address.
            </Typography>
          </Box>
          <Box
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
          >
            <AuthCode
              allowedCharacters="numeric"
              length={4}
              height="50px"
              containerClassName={containerAuthCode}
              inputClassName={inputAuthCode}
              onChange={handleOnChange}
            />
          </Box>
          <Box className={ButtonContainer}>
            <Box>
              {loading ? (
                <CircularProgress />
              ) : (
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <Button
                    color="primary"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default EmailVarification;
