import {
  Box,
  // CircularProgress,
  Grid,
  gridClasses,
  InputAdornment,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useRef, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { Navigate, useNavigate } from "react-router-dom";
import {
  FetchUnallocatedChangeRequest,
  getChangerequestDetails,
} from "../../Redux/Slice/ChangeRequest/ChangeRequestSlice";
import moment from "moment/moment";
import Loader from "../Loader/Loader";
const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});

function UnallocatedChangeReq() {
  const { tabelStyle } = useContext(TabelStyleContext);
  const { header } = useStyles();
  const dispatch = useDispatch();
  const [rows, setrows] = useState([]);
  const [unAllocatedChangeReq, setunAllocatedChangeReq] = useState([]);
  const [pageSize, setPageSize] = useState(50);
  const { setsnackBarData } = useContext(SnackBarContext);
  const navigate = useNavigate();
  const { UnallocatedChangeRequest, status, IsLogin, message } = useSelector(
    getChangerequestDetails
  );
  const projectRef = useRef();
  const [canView, setcanView] = useState(null);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  useEffect(() => {
    let string = "UnallocatedChangeRequest";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    let match = value?.some((val) => {
      if (val?.module?.split("_")[1] === "View") {
        return true;
      }
    });
    setcanView(match);
    console.log(match);
    if (!roles || roles.length < 1) {
      setcanView(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  useEffect(() => {
    dispatch(FetchUnallocatedChangeRequest());
  }, [dispatch]);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport
          csvOptions={{ fileName: "UnallocatedChangeReqReport" }}
        />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "sr", headerName: "Sr #", width: 100, headerClassName: header },

    {
      field: "project_name",
      headerName: "Project",
      width: 200,
      headerClassName: header,
    },
    {
      field: "module",
      headerName: "Module",
      width: 170,
      headerClassName: header,
    },
    {
      field: "submodule",
      headerName: "Sub Module",
      width: 170,
      headerClassName: header,
    },
    {
      field: "subject",
      headerName: "Subject",
      width: 200,
      headerClassName: header,
    },
    {
      field: "description",
      headerName: "Description",
      width: 250,
      headerClassName: header,
    },
    {
      field: "priority",
      headerName: "Priority",
      width: 150,
      headerClassName: header,
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 170,
      headerClassName: header,
    },
  ];

  useEffect(() => {
    let arr = [];

    UnallocatedChangeRequest &&
      UnallocatedChangeRequest.forEach((item, idx) => {
        return arr.push({
          ...item,
          sr: idx + 1,
          id: item?.request_id,
          created_at: item?.created_at
            ? moment(item?.created_at).format("DD-MMM-YYYY")
            : null,
          project_name: item?.project?.project_name,
          module: item?.module?.module_name,
          submodule: item?.submodule?.submodule_name,
        });
      });

    setrows(arr);
    setunAllocatedChangeReq(arr);
  }, [UnallocatedChangeRequest]);

  const onTextChange = () => {
    if (projectRef?.current?.value === "") {
      setunAllocatedChangeReq(rows);
    } else {
      let suggestions = [];

      var regex1 = new RegExp(projectRef?.current?.value, "i");

      suggestions = rows?.filter(
        (val) =>
          projectRef?.current?.value === "" || regex1.test(val.project_name)
      );

      setunAllocatedChangeReq(suggestions);
    }
  };
  useEffect(() => {
    if (status === "failed") {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
    }
  }, [status]);
  return (
    <>
      {canView === true ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              alignItems: "center",
              padding: "20px 10px 10px 10px",
            }}
          >
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{ color: (theme) => theme.palette.grey.G2 }}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{
                width: "100%",
                maxWidth: "180px",
              }}
              variant="standard"
              type="text"
              autoComplete="off"
              placeholder="Project"
              className="input_border"
              id="ProjectNameFilter"
              inputRef={projectRef}
              onChange={onTextChange}
            />
          </Box>

          <span className="ml-2 float-right">
            <div className="loaderclass"> </div>
          </span>

          {status === "loading" ? <Loader /> : null}
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            overflow
            sx={{ height: tabelStyle.height, padding: "10px" }}
          >
            <DataGrid
              rows={unAllocatedChangeReq}
              columns={columns}
              onRowClick={() => {}}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
              pagination
              components={{
                Toolbar: CustomToolbar,
              }}
              localeText={{
                toolbarDensity: "Size",
                toolbarDensityLabel: "Size",
                toolbarDensityCompact: "Small",
                toolbarDensityStandard: "Medium",
                toolbarDensityComfortable: "Large",
              }}
            />
          </Grid>
        </>
      ) : canView === false ? (
        <Navigate to={"/dashboard"} />
      ) : (
        <Box>Loading ...</Box>
      )}
    </>
  );
}

export default UnallocatedChangeReq;
