import { Box } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import RolesForm from "../../Components/Roles/RolesForm/RolesForm";
import RolesTable from "../../Components/Roles/RolesTable/RolesTable";
function RolesMain() {
    const [ShowForm, setShowForm] =useState(false);
    const [IsEdit, setIsEdit] =useState(false);
    const [rolesRowData, setrolesRowData] =useState(null);
    const [canView, setcanView] = useState(null);
    const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
    useEffect(() => {
      let string = "Roles";
      let value = roles?.ruleid?.filter((role) => {
        if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
          return role;
        }
      });
      console.log(value);
      let match = value?.some((val) => {
        if (val?.module?.split("_")[1] === "View") {
          return true;
        }
      });
      setcanView(match);
      console.log(match);
      if (!roles || roles.length < 1) {
        setcanView(true);
      }
      setroles(JSON.parse(localStorage.getItem("roles")));
    }, []);
  return (
    <div>
            {canView === true ? (

      ShowForm ? (
        <RolesForm
          setShowForm={setShowForm}
          IsEdit={IsEdit}
          setIsEdit={setIsEdit}
          rolesRowData={rolesRowData}
          setrolesRowData={setrolesRowData}
        />
      ) : (
        <RolesTable
        setShowForm={setShowForm}
        setrolesRowData={setrolesRowData}
        setIsEdit={setIsEdit}
        />
      )
      ) : canView === false ? (
        <Navigate to={"/dashboard"} />
      ) : (
        <Box>Loading ...</Box>
      )}
    
    </div>
  )
}

export default RolesMain