import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  singleEmployee:null,
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin:true
};

export const employePopulate= createAsyncThunk(
  "employeepopulate",
  async()=>{
    try{
      const response = await axiosInstance.get(`employeepopulate`);
      return response.data;
    }catch (error) {
      console.log(error)
    }
  }
)
// export const FetchSingleEmployeDetails = createAsyncThunk(
//   "Fetch/SingleEmployee",
//   async (id) => {
//     try {
//       const response = await axiosInstance.get(`getoneemployee/${id}`);
//       return response.data;
      
//     } catch (error) {
//       console.log(error)
//     }
//   }
// );

const EmployeDetailSlice = createSlice({
  name: "employeDetail",
  initialState,
  reducers: {
    // EmployeeAdded: {
    //   reducer(state, action) {
    //     state.employee.push(action.payload);
    //   },
    // },
  },
  extraReducers(builder) {
    builder
      
    
    //   .addCase(FetchSingleEmployeDetails.fulfilled, (state, action) => {
    //     console.log(action.payload.Data)
    //     state.status = "succeeded";
    //     state.message = action.payload.Message;
    //     state.isSuccess = action.payload.IsSuccess;
    //     state.singleEmployee = action.payload.Data[0];
    //   })
    //   .addCase(FetchSingleEmployeDetails.pending, (state) => {
    //     state.status = "loading";
    //   })
    //   .addCase(FetchSingleEmployeDetails.rejected, (state, action) => {
    //     state.status = "failed";
    //     state.error = action.error.message;
    //   })
    // .addCase(FetchSingleEmployeDetails.rejected, (state, action) => {
    //   if (action?.error?.message.includes(401)) {
    //     state.message = action.error.message;
    //     state.IsLogin = false;
    //   } else {
    //     state.error = action.error.message;
    //     state.status = "failed";
    //   }
    // })

  },
});

export const getemployeeDetails = (state) => state.employeDetail;

// export const { EmployeeAdded } = EmployeDetailSlice.actions;

export default EmployeDetailSlice.reducer;