import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  FormControl,
  Typography,
  TextField,
  CircularProgress,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import { useEffect } from "react";
import theme from "../../theme";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import {
  AddDesignationApi,
  UpdateDesignation,
} from "../../Api/Designation/DesignationAPis";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { DesignationAdded } from "../../Redux/Slice/Designation/DesignationSlice";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
const useStyles = makeStyles(() => {
  return {
    input_typography: {
      textAlign: "left",

      fontSize: `15px !important`,
      marginTop: "10px !important",
      paddingRight: "20px !important",
    },
    Form_input: {
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,
    },

    form_main: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      padding: "60px 8%",
      minHeight: "calc(100vh - 175px)",
    },
    responsive: {
      maxWidth: "600px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },

    btns: {
      width: "80px",
    },
    main_box: {
      width: "100%",
    },
    showbox: {
      width: "100%",
      display: "flex",
      gap: "20px",
      justifyContent: "space-between",
      flexDirection: "column",

      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },
    FieldInRow: {
      display: "flex",
      justifyContent: "space-between",
      gap: "25px",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },
  };
});

const DesignationForm = ({
  setShowForm,
  setIsEdit,
  setEditedData,
  IsEdit,
  EditedData,
}) => {
  const { setsnackBarData } = useContext(SnackBarContext);
  const [loading, setloading] = useState(false);
  const [edited, setedited] = useState(false);
  const [resIndex, setresIndex] = useState(null);
  const {
    Form_input,
    input_typography,
    btns,
    form_main,
    main_box,
    FieldInRow,
    showbox,
  } = useStyles();
  const [allresponsibilities, setallresponsibilities] = useState([]);
  const [responsibilties, setresponsibilties] = useState("");
  const [formData, setformData] = useState({
    designation_name: "",
    annual_leaves: "",
    casual_leaves: "",
  });
  const dispatch = useDispatch();
  console.log(responsibilties);
  const handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setformData({ ...formData, [name]: value });
  };
  const AddTechnologies = async () => {
    if (formData.designation_name === "") {
      setsnackBarData({
        type: "error",
        message: "Please Enter all detail",
        openToast: true,
      });
    } else if (allresponsibilities?.length <= 0) {
      setsnackBarData({
        type: "error",
        message: "Please Enter Responsibilties",
        openToast: true,
      });
    } else if (formData.annual_leaves === "") {
      setsnackBarData({
        type: "error",
        message: "Please Enter Annual Leave",
        openToast: true,
      });
    } else if (formData.casual_leaves === "") {
      setsnackBarData({
        type: "error",
        message: "Please Enter Casual Leave",
        openToast: true,
      });
    } else {
      setloading(true);
      let response;
      if (IsEdit) {
        response = await UpdateDesignation({
          ...formData,
          responsibilties: allresponsibilities,
        });
      } else {
        response = await AddDesignationApi({
          ...formData,
          responsibilties: allresponsibilities,
        });
      }

      setsnackBarData(response.snackBarData);

      if (response.data.IsSuccess) {
        setformData({
          designation_name: "",
          responsibilties: "",
          annual_leaves: "",
          casual_leaves: "",
        });
        setShowForm(false);
        setIsEdit(false);
        setEditedData(null);
        dispatch(DesignationAdded(response.data.newDesignation));
      }
      setloading(false);
    }
  };

  useEffect(() => {
    if (EditedData && IsEdit) {
      console.log(EditedData);
      setformData({
        designation_name: EditedData?.designation_name,
        annual_leaves: EditedData?.annual_leaves,
        casual_leaves: EditedData?.casual_leaves,
        designation_id: EditedData?.designation_id,
        id: EditedData?.id,
      });
      setallresponsibilities(EditedData?.resp_id);
    }
  }, [EditedData, IsEdit]);
  const addResponsibility = () => {
    const obj = {
      resp_id: "",
      responsibilty: responsibilties,
    };
    setallresponsibilities([...allresponsibilities, obj]);
    setresponsibilties("");
  };
  const editResponsibility = () => {
    let arr = [];
    allresponsibilities?.forEach((item, ind) => {
      return arr.push({
        resp_id: item?.resp_id,
        responsibilty: ind === resIndex ? responsibilties : item?.responsibilty,
      });
    });
    setallresponsibilities(arr);
    setresponsibilties("");
    setedited(false);
  };
  return (
    <Box className={form_main}>
      <Box sx={{ maxWidth: "900px", width: "100%" }}>
        <Box className={showbox}>
          <Box className={FieldInRow}>
            <Box className={main_box}>
              <Typography className={input_typography}>Designation</Typography>
              <FormControl fullWidth sx={{ textAlign: "left" }}>
                <TextField
                  fullWidth
                  size="small"
                  name="designation_name"
                  value={formData.designation_name}
                  onChange={handleChange}
                  className={Form_input}
                />
              </FormControl>
            </Box>
            <Box className={FieldInRow}>
              <Box className={main_box}>
                <Typography className={input_typography}>
                  Casual Leaves
                </Typography>

                <FormControl fullWidth sx={{ textAlign: "left" }}>
                  <TextField
                    fullWidth
                    size="small"
                    name="casual_leaves"
                    value={formData.casual_leaves}
                    onChange={handleChange}
                    className={Form_input}
                    type="number"
                  />
                </FormControl>
              </Box>
            </Box>

            <Box className={main_box}>
              <Typography className={input_typography}>
                Annual Leaves
              </Typography>

              <FormControl fullWidth sx={{ textAlign: "left" }}>
                <TextField
                  fullWidth
                  size="small"
                  name="annual_leaves"
                  value={formData.annual_leaves}
                  onChange={handleChange}
                  className={Form_input}
                  type="number"
                />
              </FormControl>
            </Box>
          </Box>
          <Box className={main_box}>
            <Typography className={input_typography}>
              Responsibilities
            </Typography>

            <FormControl fullWidth sx={{ textAlign: "left" }}>
              <Box sx={{ display: "flex", gap: "20px" }}>
                <TextField
                  multiline
                  fullWidth
                  size="small"
                  // name="responsibilties"
                  value={responsibilties}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setresponsibilties(e.target.value);
                  }}
                  className={Form_input}
                />
                <Button
                  onClick={() => {
                    edited ? editResponsibility() : addResponsibility();
                  }}
                  variant="contained"
                >
                  {edited ? "Update" : <AddIcon />}
                </Button>
              </Box>
            </FormControl>
          </Box>
          <TableContainer
            sx={{
              height: "auto",
              overflow: "scroll",
              "::-webkit-scrollbar": { width: "5px" },
              "::-webkit-scrollbar-thumb": {
                backgroundColor: "darkgrey",
              },
            }}
            component={Paper}
          >
            <Table
              sx={{
                width: "100%",
                flex: 1,
                "& .MuiTableCell-root": {
                  border: (theme) => `1px solid ${theme.palette.grey[300]}`,
                },
              }}
              size="small"
              aria-label="a dense table"
              stripe
            >
              <TableHead>
                <TableRow sx={{ fontWeight: "bold !important" }}>
                  <TableCell sx={{ fontWeight: "bold" }} align="left">
                    Sr #
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align="left">
                    Responsibilty
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} align="left">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allresponsibilities?.map((item, index) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        // "&:last-child td, &:last-child th": { border: 0 },
                        background: index % 2 === 0 ? "#f5f4f4 !important" : "",
                      }}
                    >
                      <TableCell align="left">{index + 1}</TableCell>
                      <TableCell align="left">{item?.responsibilty}</TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "start",
                            gap: "10px",
                          }}
                        >
                          <IconButton
                            size={"small"}
                            onClick={() => {
                              setedited(true);
                              setresIndex(index);
                              setresponsibilties(item?.responsibilty);
                            }}
                          >
                            {" "}
                            <AppRegistrationIcon
                              sx={{
                                cursor: "pointer",
                                color: "black !important",
                                fontSize: "18px",
                              }}
                            />
                          </IconButton>

                          <IconButton
                            onClick={() => {
                              let newArr = JSON.parse(
                                JSON.stringify(allresponsibilities)
                              );
                              newArr.splice(index, 1);
                              setallresponsibilities(newArr);
                            }}
                            size={"small"}
                          >
                            <DeleteOutlineIcon
                              sx={{
                                color: theme.palette.error.main,
                                fontSize: "18px",
                              }}
                            />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              justifyContent: "flex-end",
              display: "flex",
              gap: "10px",
              marginTop: "20px",
            }}
            spacing={2}
          >
            <>
              <Button
                className={btns}
                variant="contained"
                color="error"
                onClick={() => {
                  setShowForm(false);
                  setIsEdit(false);
                  setEditedData(null);
                }}
              >
                Cancel
              </Button>
              {loading ? (
                <CircularProgress />
              ) : (
                <Button
                  className={btns}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    AddTechnologies();
                  }}
                >
                  {IsEdit ? "Update" : "Save"}
                </Button>
              )}
            </>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DesignationForm;
DesignationForm.propTypes = {
  setShowForm: PropTypes.any,
  setIsEdit: PropTypes.func,
  setEditedData: PropTypes.func,
  IsEdit: PropTypes.any,
  EditedData: PropTypes.any,
};
