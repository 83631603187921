import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  dashboarddata: [],
  changereqchartdata: [],
  presentEmployee: [],
  yesterdayAttendace: [],
  singleEmployeeDetails: {},
  singleEmployeeAttendance: [],
  monthlyChangeRequest: [],
  monthlyChangeRequestData: [],
  weeklyChangeRequestData: [],
  AbsentEmployee: [],
  MonthlyCompletedChangeReq: [],
  MonthlyAllocatedChangeReq: [],
  MonthlyUnallocatedChangeReq: [],
  WeeklyCompletedChangeReq: [],
  WeeklyAllocatedChangeReq: [],
  WeeklyUnallocatedChangeReq: [],
  wastedhours: {},
  allleaves: {},
  allWorkaholic: {},
  onTime: {},
  maxAttendance: {},

  Events: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  dashboarddatastatus: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  allleavesstatus: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  allWorkaholicstatus: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  onTimestatus: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  maxAttendancestatus: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'

  wastedhoursstatus: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  presentEmployeeStatus: "idle",
  attendanceStatus: "idle",
  error: null,
  IsLogin: true,
};

export const FetchDashboardData = createAsyncThunk(
  "dashboardactivity",
  async () => {
    const response = await axiosInstance.get(`dashboardactivity`);

    return response.data;
  }
);
export const FetchChangeReqChartData = createAsyncThunk(
  "changereqsgraph",
  async () => {
    const response = await axiosInstance.get(`changereqsgraph`);

    return response.data;
  }
);
export const FetchPresentEmployeeData = createAsyncThunk(
  "presentempactivity",
  async () => {
    const response = await axiosInstance.get(`presentempactivity`);
    console.log(response.data);
    return response.data;
  }
);
export const FetchEvents = createAsyncThunk("eventdata", async () => {
  const response = await axiosInstance.get(`eventdata`);

  return response.data;
});

export const GetWastedHours = createAsyncThunk("GetWastedHours", async () => {
  const response = await axiosInstance.get(
    `wastedhrs?date=${moment().format("YYYY-MM-DD")}`
  );

  return response.data;
});

export const GetAllLeaveApplications = createAsyncThunk(
  "GetAllLeaveApplications",
  async () => {
    const response = await axiosInstance.get(`allleaveapplications`);

    return response.data;
  }
);
export const GetAllWokaholics = createAsyncThunk(
  "GetAllWokaholics",
  async () => {
    const response = await axiosInstance.get(`topempwrkhrs`);

    return response.data;
  }
);
export const GetOnTime = createAsyncThunk("GetOnTime", async () => {
  const response = await axiosInstance.get(`allempearlyarival`);

  return response.data;
});
export const GetMaxAttendance = createAsyncThunk(
  "GetMaxAttendance",
  async () => {
    const response = await axiosInstance.get(`emptopattendance `);

    return response.data;
  }
);
export const GetSingleEmployeeDetails = createAsyncThunk(
  "GetSingleEmployeeDetails",
  async (data) => {
    const response = await axiosInstance.get(`singlempdetail/${data}`);

    return response.data;
  }
);

export const GetSingleEmployeeAttendance = createAsyncThunk(
  "GetSingleEmployeeAttendance",
  async (data) => {
    console.log(data);
    const response = await axiosInstance.get(
      `singlempattendance?id=${data.userid}&month=${data.date}`
    );

    return response.data;
  }
);
export const GetMonthlyChangeRequest = createAsyncThunk(
  "onemonthchangereqs",
  async (data) => {
    console.log(data);

    const response = await axiosInstance.get("onemonthchangereqs");
    console.log(response.data);

    return response.data;
  }
);
export const GetMonthlyChangeRequestData = createAsyncThunk(
  "changereqslastdays?days=30",
  async () => {
    const response = await axiosInstance.get("changereqslastdays?days=30");
    console.log(response.data);

    return response.data;
  }
);
export const GetWeeklyChangeRequestData = createAsyncThunk(
  "changereqslastdays?days=7",
  async () => {
    const response = await axiosInstance.get("changereqslastdays?days=7");
    console.log(response.data);
    return response.data;
  }
);
export const fetchAbsentEmployeeAct = createAsyncThunk(
  "absentempactivity",
  async () => {
    const response = await axiosInstance.get("absentempactivity");
    console.log(response.data);
    return response.data;
  }
);
export const fetchMonthlyCompChangeReq = createAsyncThunk(
  "changereqscompleted?days=30",
  async () => {
    const response = await axiosInstance.get("changereqscompleted?days=30");
    console.log(response.data);
    return response.data;
  }
);
export const fetchMonthlyAlloChangeReq = createAsyncThunk(
  "changereqsallocated?days=30",
  async () => {
    const response = await axiosInstance.get("changereqsallocated?days=30");
    console.log(response.data);
    return response.data;
  }
);
export const fetchMonthlyUnalloChangeReq = createAsyncThunk(
  "changereqsunallocated?days=30",
  async () => {
    const response = await axiosInstance.get("changereqsunallocated?days=30");
    console.log(response.data);
    return response.data;
  }
);
export const fetchWeeklyCompChangeReq = createAsyncThunk(
  "changereqscompleted?days=7",
  async () => {
    const response = await axiosInstance.get("changereqscompleted?days=7");
    console.log(response.data);
    return response.data;
  }
);
export const fetchWeeklyAlloChangeReq = createAsyncThunk(
  "changereqsallocated?days=7",
  async () => {
    const response = await axiosInstance.get("changereqsallocated?days=7");
    console.log(response.data);
    return response.data;
  }
);
export const fetchWeeklyUnalloChangeReq = createAsyncThunk(
  "changereqsunallocated?days=7",
  async () => {
    const response = await axiosInstance.get("changereqsunallocated?days=7");
    console.log(response.data);
    return response.data;
  }
);
// export const fetchPresentEmployeeActivityyyy = createAsyncThunk(
//   "presentempactivity",
//   async () => {
//     const response = await axiosInstance.get("presentempactivity");
//     console.log(response.data);
//     return response.data;
//   }
// );

const DashboardSlice = createSlice({
  name: "dashboardactivity",
  initialState,
  reducers: {
    DashboardResetData: (state) => {
      state.dashboarddata = [];
      state.changereqchartdata = [];
      state.presentEmployee = [];
      state.yesterdayAttendace = [];
      state.singleEmployeeDetails = {};
      state.singleEmployeeAttendance = [];
      state.monthlyChangeRequest = [];
      state.wastedhours = {};
      state.allleaves = {};
      state.allWorkaholic = {};
      state.onTime = {};
      state.maxAttendance = {};

      state.Events = [];
      state.isSuccess = false;
      state.message = null;
      state.status = "idle"; //'idle' | 'loading' | 'succeeded' | 'failed'
      state.presentEmployeeStatus = "idle";
      state.attendanceStatus = "idle";

      state.error = null;
      state.IsLogin = true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(FetchDashboardData.pending, (state) => {
        state.dashboarddatastatus = "loading";
      })
      .addCase(FetchDashboardData.fulfilled, (state, action) => {
        state.dashboarddatastatus = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.dashboarddata = action.payload;
      })
      .addCase(FetchDashboardData.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.dashboarddatastatus = "failed";
        }
      })
      .addCase(FetchChangeReqChartData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchChangeReqChartData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.changereqchartdata = action.payload;
      })
      .addCase(FetchChangeReqChartData.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(FetchPresentEmployeeData.pending, (state) => {
        state.presentEmployeeStatus = "loading";
      })
      .addCase(FetchPresentEmployeeData.fulfilled, (state, action) => {
        state.presentEmployeeStatus = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.presentEmployee = action.payload?.Data;
      })
      .addCase(FetchPresentEmployeeData.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(FetchEvents.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchEvents.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Events = action.payload?.Gazetted_Holidays;
      })
      .addCase(FetchEvents.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(GetWastedHours.pending, (state) => {
        state.wastedhoursstatus = "loading";
      })
      .addCase(GetWastedHours.fulfilled, (state, action) => {
        state.wastedhoursstatus = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.wastedhours = action.payload;
      })
      .addCase(GetWastedHours.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.wastedhoursstatus = "failed";
        }
      })
      .addCase(GetAllLeaveApplications.pending, (state) => {
        state.allleavesstatus = "loading";
      })
      .addCase(GetAllLeaveApplications.fulfilled, (state, action) => {
        state.allleavesstatus = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.allleaves = action.payload;
      })
      .addCase(GetAllWokaholics.pending, (state) => {
        state.allWorkaholicstatus = "loading";
      })
      .addCase(GetAllWokaholics.fulfilled, (state, action) => {
        state.allWorkaholicstatus = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.allWorkaholic = action.payload;
      })
      .addCase(GetAllWokaholics.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.allWorkaholicstatus = "failed";
        }
      })
      .addCase(GetOnTime.pending, (state) => {
        state.onTimestatus = "loading";
      })
      .addCase(GetOnTime.fulfilled, (state, action) => {
        state.onTimestatus = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.onTime = action.payload;
      })
      .addCase(GetOnTime.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.onTimestatus = "failed";
        }
      })
      .addCase(GetMaxAttendance.pending, (state) => {
        state.maxAttendancestatus = "loading";
      })
      .addCase(GetMaxAttendance.fulfilled, (state, action) => {
        state.maxAttendancestatus = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.maxAttendance = action.payload;
      })
      .addCase(GetMaxAttendance.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.maxAttendancestatus = "failed";
        }
      })
      .addCase(GetSingleEmployeeDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(GetSingleEmployeeDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.singleEmployeeDetails = action.payload;
      })
      .addCase(GetSingleEmployeeDetails.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(GetSingleEmployeeAttendance.pending, (state) => {
        state.attendanceStatus = "loading";
      })
      .addCase(GetSingleEmployeeAttendance.fulfilled, (state, action) => {
        state.attendanceStatus = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.singleEmployeeAttendance = action.payload?.Attendance;
      })
      .addCase(GetSingleEmployeeAttendance.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(GetMonthlyChangeRequest.pending, (state) => {
        state.attendanceStatus = "loading";
      })
      .addCase(GetMonthlyChangeRequest.fulfilled, (state, action) => {
        state.attendanceStatus = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.monthlyChangeRequest = action.payload.Data;
      })
      .addCase(GetMonthlyChangeRequest.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(GetMonthlyChangeRequestData.pending, (state) => {
        state.attendanceStatus = "loading";
      })
      .addCase(GetMonthlyChangeRequestData.fulfilled, (state, action) => {
        state.attendanceStatus = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.monthlyChangeRequestData = action.payload;
      })
      .addCase(GetMonthlyChangeRequestData.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(GetWeeklyChangeRequestData.pending, (state) => {
        state.attendanceStatus = "loading";
      })
      .addCase(GetWeeklyChangeRequestData.fulfilled, (state, action) => {
        state.attendanceStatus = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.weeklyChangeRequestData = action.payload;
      })
      .addCase(GetWeeklyChangeRequestData.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(fetchAbsentEmployeeAct.pending, (state) => {
        state.attendanceStatus = "loading";
      })
      .addCase(fetchAbsentEmployeeAct.fulfilled, (state, action) => {
        state.attendanceStatus = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.AbsentEmployee = action.payload.Absent_emp;
      })
      .addCase(fetchAbsentEmployeeAct.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.attendanceStatus = "failed";
        }
      })
      .addCase(fetchMonthlyCompChangeReq.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMonthlyCompChangeReq.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.MonthlyCompletedChangeReq = action.payload.Completed;
      })
      .addCase(fetchMonthlyCompChangeReq.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(fetchMonthlyAlloChangeReq.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMonthlyAlloChangeReq.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.MonthlyAllocatedChangeReq = action.payload.Allocated;
      })
      .addCase(fetchMonthlyAlloChangeReq.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(fetchMonthlyUnalloChangeReq.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMonthlyUnalloChangeReq.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.MonthlyUnallocatedChangeReq = action.payload.Unallocated;
      })
      .addCase(fetchMonthlyUnalloChangeReq.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(fetchWeeklyCompChangeReq.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchWeeklyCompChangeReq.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.WeeklyCompletedChangeReq = action.payload.Completed;
      })
      .addCase(fetchWeeklyCompChangeReq.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(fetchWeeklyAlloChangeReq.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchWeeklyAlloChangeReq.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.WeeklyAllocatedChangeReq = action.payload.Allocated;
      })
      .addCase(fetchWeeklyAlloChangeReq.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(fetchWeeklyUnalloChangeReq.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchWeeklyUnalloChangeReq.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.WeeklyUnallocatedChangeReq = action.payload.Unallocated;
      })
      .addCase(fetchWeeklyUnalloChangeReq.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
  },
});

export const getDashboardActivities = (state) => state.dashboardactivity;
export const { DashboardResetData } = DashboardSlice.actions;

export default DashboardSlice.reducer;
