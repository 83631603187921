import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  attendance: [],
  Employees: [],
  AdminLogWork: [],
  PageNum: 0,
  PageSize: 10,
  TotalCount: 0,
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  status2: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,

};

export const fetchAttendance = createAsyncThunk("getattendance?", async ({ page, pageSize }) => {
  const response = await axiosInstance.get(`getattendance?PageNum=${Number(page) + 1}&PageSize=${pageSize}`);
  console.log(response.data)
  return response.data;
});

export const fetchAttendancePopulate = createAsyncThunk(
  "attendancepopulate",
  async (initialItem) => {
    const response = await axiosInstance.get(`attendancepopulate`, initialItem);
    return response.data;
  }
);
export const fetchAdminLogWork = createAsyncThunk(
  "adminempattendancedetail?",
  async ({id,date}) => {
    const response = await axiosInstance.get(`adminempattendancedetail?id=${id}&date=${date}`);
    console.log(response.data);
    return response.data;
  }
);

const AttendanceSlice = createSlice({
  name: "attendance",
  initialState,
  reducers: {
    AttenndanceAdded: {
      reducer(state, action) {
        console.log(action.payload);
        state.attendance.push(action.payload);
      },
    },
    AttendanceResetData: (state) => {
      state.attendance= [];
      state.Employees= [];
      state.PageNum= 0;
      state.PageSize= 10;
      state.TotalCount= 0;
      state.isSuccess= false;
      state.message= null;
      state.status= "idle"; //'idle' | 'loading' | 'succeeded' | 'failed'
      state.error= null;
      state.IsLogin= true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAttendance.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAttendance.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        let arr=[...state.attendance];
        Array.prototype.splice.apply(
          arr,
          [
            action.payload?.PageSize * action.payload?.PageNum - action.payload?.PageSize,
            action?.payload?.Data.length,
          ].concat(action?.payload?.Data),
        );

        state.attendance = [...arr]
        state.PageNum = Number(action.payload?.PageNum);
        state.PageSize = Number(action.payload?.PageSize);
        state.TotalCount = Number(action.payload?.TotalCount);
        console.log(state.PageNum)
        // state.attendance = action.payload.Data;
      })
      .addCase(fetchAttendance.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })



      .addCase(fetchAttendancePopulate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Employees = action.payload.Employees;
      })
      .addCase(fetchAttendancePopulate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAttendancePopulate.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(fetchAdminLogWork.fulfilled, (state, action) => {
        state.status2 = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.AdminLogWork = action.payload.Attendance_detail;
      })
      .addCase(fetchAdminLogWork.pending, (state) => {
        state.status2 = "loading";
      })
      .addCase(fetchAdminLogWork.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status2 = "failed";
        }
      });
  },
});

export const getattendanceDetails = (state) => state.attendance;

export const { AttenndanceAdded,AttendanceResetData } = AttendanceSlice.actions;

export default AttendanceSlice.reducer;
