import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  isSuccess: false,
  message: null,
  employeeStatus: "idle",
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
  allOrganizationsProfile: [],
};

export const getAllOrganizationsProfile = createAsyncThunk(
  "getallOrganizationsProfile",
  async () => {
    const response = await axiosInstance.get(`admingetcompanyprofiles`);
    console.log("getAllOrganizationsProfile", response);
    return response.data;
  }
);

const OrganizationsSlice = createSlice({
  name: "OrganizationsSlice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getAllOrganizationsProfile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllOrganizationsProfile.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload?.Message;
        state.isSuccess = action.payload?.IsSuccess;
        state.allOrganizationsProfile = action.payload?.companydata;
      })
      .addCase(getAllOrganizationsProfile.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
  },
});

export const getOrganizationsDetails = (state) => state.organizations;
export default OrganizationsSlice.reducer;
