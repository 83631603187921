import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import * as Yup from "yup";
import { useFormik } from "formik";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import theme from "../../theme";
import PropTypes from "prop-types";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { LoanDeductionStatusApi } from "../../Api/LoanDeductionRequest/LoanDeductionStatusApi";
import { fetchLoanDeduction } from "../../Redux/Slice/LoanDeductionRequest/LoanDeductionRequestSlice";
const useStyles = makeStyles(() => {
    return {
      ModalContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        padding: "10px",
        width: "100%",
      },
      Container: {
        width: "800px",
        padding: "20px",
        border: "none",
        position: "relative",
        background: "white",
        borderRadius: "10px",
        display: "flex",
        gap: "20px",
        flexDirection: "column",
      },
      TableHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      container: {
        // height: "100%",
        width: "100%",
        padding: "0px 20px 20px 20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
          padding: "5px",
        },
      },
      subContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        // padding: "20px",
        width: "100%",
        // maxWidth: "700px",
        // minHeight: "calc(100vh - 215px)",
        [theme.breakpoints.down("md")]: {
          padding: "5px",
        },
      },
      formContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        textAlign: "left",
      },
    };
  });
function DeductionApproveModal({ showModal, setshowModal, approveRejRow,setapproveRejRow }) {
    const {
        Container,
        ModalContainer,
        TableHeader,
        container,
        subContainer,
        formContainer,
      } = useStyles();
      const { setsnackBarData } = useContext(SnackBarContext);
      const dispatch = useDispatch();
      const initialValues = {
        status: "",
        amount: "",
      };
      console.log(approveRejRow);
      useEffect(()=>{
    if(approveRejRow){
        formik.setFieldValue("amount",approveRejRow?.req_amount)
    }
      },[approveRejRow])
      console.log(approveRejRow);
      const validationSchema = Yup.object().shape({
        status: Yup.string().required("Please Enter Status"),
        amount: Yup.string().required("Please Enter Amount"),
      });
      const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm, setSubmitting }) => {
          handleSave(values, resetForm, setSubmitting);
        },
      });
      const handleSave = async (values, setSubmitting, resetForm) => {
        const obj = { ...values,loan_id:approveRejRow?.loan?.loan_id,request_id:approveRejRow?.request_id,report_id:approveRejRow?.report_id?.report_id};
        console.log(obj);
        const response = await LoanDeductionStatusApi(obj);
        setsnackBarData(response.snackBarData);
        if (response.data.IsSuccess) {
            dispatch(fetchLoanDeduction());
          resetForm();
          setSubmitting(false);
    
          setshowModal(false);
        }
        setSubmitting(false);
      };
  return (
    <>
      <Modal
        open={showModal}
        onClose={() => {
          setshowModal(false);
        }}
      >
        <Box className={ModalContainer}>
          <Box className={Container}>
            <Box className={TableHeader}>
              <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                Loan Deduction Status
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setapproveRejRow(null);
                  setshowModal(false);
                }}
              >
                <HighlightOffIcon
                  color="primary"
                  sx={{
                    fontSize: "28px",
                  }}
                />
              </IconButton>
            </Box>
            <Box className={container}>
              <Box className={subContainer}>
                <form onSubmit={formik.handleSubmit}>
                  <Box className={formContainer}>
                    <FormControl fullWidth>
                      <Typography>Amount</Typography>
                      <TextField
                        sx={{ textAlign: "left", input: { color: "black" } }}
                        fullWidth
                        size="small"
                        name="amount"
                        type={"number"}
                        onBlur={formik.handleBlur}
                        onChange={
                            (e) => {
                                if (e.target.value > approveRejRow?.req_amount){
                                    return
                                }else{
                                    formik.setFieldValue("amount",e.target.value)
                                }
                            }
                            // formik.handleChange
                        }
                        value={formik.values.amount}
                        variant="outlined"
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <Typography>Status</Typography>
                      <TextField
                        sx={{ textAlign: "left", input: { color: "black" } }}
                        fullWidth
                        size="small"
                        name="status"
                        select
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.status}
                        variant="outlined"
                        error={
                          Boolean(formik.touched.status) &&
                          Boolean(formik.errors.status)
                        }
                        helperText={
                          Boolean(formik.touched.status) && formik.errors.status
                        }
                      >
                        <MenuItem value="Accepted">Accepted</MenuItem>
                        <MenuItem value="Rejected">Rejected</MenuItem>
                        </TextField>

                    </FormControl>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "end",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <Box>
                          {formik.isSubmitting ? (
                            <CircularProgress />
                          ) : (
                            <Button type="submit" variant="contained">
                              <Typography>Submit</Typography>
                            </Button>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default DeductionApproveModal;
DeductionApproveModal.propTypes = {
    showModal: PropTypes.any,
    setshowModal: PropTypes.any,
    approveRejRow: PropTypes.any,
    setapproveRejRow: PropTypes.any,
  };
  