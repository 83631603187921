import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  attendancedetail: [],
  singleEmployeeAttendance: [],
  Latest_Entry: null,
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};

export const fetchAttendanceDetail = createAsyncThunk(
  "getattendance",
  async () => {
    const response = await axiosInstance.get(`getattendance`);
    return response.data;
  }
);
export const fetchSingleEmployeeAttendanceDetail = createAsyncThunk(
  "empattendancedetail",
  async (obj) => {
    console.log(obj)
    const response = await axiosInstance.get(`empattendancedetail?id=${obj.id}&date=${obj.date}`);
    return response.data;
  }
);

const AttendanceDetailSlice = createSlice({
  name: "attendancedetail",
  initialState,
  reducers: {
    AttendanceDetailAdded: {
      reducer(state, action) {
        state.attendancedetail.push(action.payload);
      },
    },
    updateAttendanceDetail: {
      reducer(state, action) {
        state.singleEmployeeAttendance.push(action.payload);
        state.Latest_Entry = action.payload;
      },
    },
    removeAttendanceDetail: {
      reducer(state) {
        state.singleEmployeeAttendance = [];
        state.Latest_Entry = null;
      },
    },
    AttendanceDetailResetData: (state) => {
      state.attendancedetail= [];
      state.singleEmployeeAttendance= [];
      state.Latest_Entry= null;
      state.isSuccess= false;
      state.message= null;
      state.status= "idle"; //'idle' | 'loading' | 'succeeded' | 'failed'
      state.error= null;
      state.IsLogin= true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAttendanceDetail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAttendanceDetail.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.attendancedetail = action.payload.Data;
      })
      .addCase(fetchAttendanceDetail.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(fetchSingleEmployeeAttendanceDetail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchSingleEmployeeAttendanceDetail.fulfilled,
        (state, action) => {
          state.status = "succeeded";
          state.message = action.payload.Message;
          state.isSuccess = action.payload.IsSuccess;
          state.singleEmployeeAttendance = action.payload.Attendance_detail;
          state.Latest_Entry = action.payload.Latest_Entry;
        }
      )
      .addCase(
        fetchSingleEmployeeAttendanceDetail.rejected,
        (state, action) => {
          if (action?.error?.message.includes(401)) {
            console.log(action.error.message);
            state.message = action.error.message;
            state.IsLogin = false;
          } else {
            state.error = action.error.message;
            state.status = "failed";
          }
        }
      );
  },
});

export const getAttendancedetailDetails = (state) => state.attendancedetail;

export const {
  AttendanceDetailAdded,
  updateAttendanceDetail,
  removeAttendanceDetail,
  AttendanceDetailResetData
} = AttendanceDetailSlice.actions;

export default AttendanceDetailSlice.reducer;
