import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  Roles: [],
  RolesByName: [],
  isSuccess: false,
  IsLogin: true,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const fetchRoles = createAsyncThunk(
  "getallroles",
  async () => {
    const response = await axiosInstance.get(`getallroles`);
console.log(response.data);
    return response.data;
  }
);
export const fetchRulesByName = createAsyncThunk(
  "getrulesbyname",
  async () => {
    const response = await axiosInstance.get(`getrulesbyname`);
console.log(response.data);
    return response.data;
  }
);


const RolesSlice = createSlice({
  name: "Roles",
  initialState,
  reducers: {
    RolesAdded: {
      reducer(state, action) {
        state.Roles.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchRoles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {

        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Roles = action.payload.Roles;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
    
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(fetchRulesByName.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRulesByName.fulfilled, (state, action) => {

        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.RolesByName = action.payload.Rules;
      })
      .addCase(fetchRulesByName.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
    
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
      
    
  },
});

export const getAllRoles= (state) => state.Roles;

export const { RolesAdded,  } = RolesSlice.actions;

export default RolesSlice.reducer;
