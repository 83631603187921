import React from "react";
import JobApplicationTable from "../../Components/JobApplication/JobApplicationTable";

function JobApplication() {
  return (
    <>
      <JobApplicationTable />
    </>
  );
}

export default JobApplication;
