import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  incrementdecrementHistory:[],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,

};



export const FetchIncrementDecrementHistory = createAsyncThunk(
  "get/incrementdecrementHistory",
  async () => {
    const response = await axiosInstance.get(`getsalarychnge`);
    return response.data;
  }
);





const InrementDecrementSlice = createSlice({
  name: "IncrementDecrement",
  initialState,
  reducers: {
    EmpIncreDecreResetData: (state) => {
      state.jobTitle= [];
      state.department= [];
      state.technologies =[];
      state.experties=[];
      state.isSuccess= false;
      state.message= null;
      state.status= "idle"; //'idle' | 'loading' | 'succeeded' | 'failed'
      state.error= null;
      state.IsLogin= true;
    },
  },

  extraReducers(builder) {
    builder
     .addCase(FetchIncrementDecrementHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchIncrementDecrementHistory.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.incrementdecrementHistory = action.payload.Salary;
      })
      .addCase(FetchIncrementDecrementHistory.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
  },
});

export const getHistory = (state) => state.IncrementDecrement;
export const { EmpIncreDecreResetData } = InrementDecrementSlice.actions;


export default InrementDecrementSlice.reducer;
