import React, { useContext, useEffect } from "react";

import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardActivities } from "../../../Redux/Slice/Dashboard/DashboardSlice";
import { useState } from "react";
// import EventModal from "./EventModal";
import Cookies from "universal-cookie";
import { makeStyles } from "@mui/styles";
import {
  FetchEmployeesForEngagements,
  GetAllEngagements,
} from "../../../Redux/Slice/Engagements/EngagementSlice";
// import { useContext } from "react";
// import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import {
  FetchEngagementEvents,
  GetAllEngagementEvents,
} from "../../../Redux/Slice/Engagements/EngagementEvents";
import { logout } from "../../../Redux/Slice/LogoutSlice/LogoutSlice";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { useNavigate } from "react-router-dom";

const localizer = momentLocalizer(moment);

const cookies = new Cookies();

const useStyle = makeStyles(() => {
  return {
    container: {
      width: "100%",
      height: "700px",
      padding: "40px 0px",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      justifyContent: "start",
      textAlign: "left",
    },
    loaderContainer: {
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    FieldContainer: {
      display: "flex",
      alignItems: "center",
      gap: '23%',
    },
    Field_input: {
      display: "flex",
      gap: '20px',
      width: '30%',
    }
  };
});

const CalendarChart = () => {
  const navigate=useNavigate();
  const { Events,IsLogin,message } = useSelector(getDashboardActivities);
  const { setsnackBarData } = useContext(SnackBarContext);
  const { container, loaderContainer, FieldContainer, Field_input } = useStyle();
  const [selectedEmployee, setselectedEmployee] = useState(null);
  // const { setsnackBarData } = useContext(SnackBarContext);
  // const [open, setopen] = useState(false);
  const [event, setevent] = useState([]);
  const { employees } = useSelector(GetAllEngagements);
  const { engagementEvents, status } = useSelector(GetAllEngagementEvents);
  console.log(engagementEvents);
  const user = cookies.get("user");
  const dispatch = useDispatch();
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  useEffect(() => {
    dispatch(
      FetchEngagementEvents({
        userid: user?.user_id,
        month: moment().format("YYYY-MM-DD"),
      })
    );
    dispatch(FetchEmployeesForEngagements());
  }, [dispatch]);

  console.log(employees);
  console.log(user);
  // const ev = [
  //   {
  //     title: "Birthday hi",
  //     start: new Date("2023-04-04T17:28:00+05:00"),
  //     end: new Date("2023-04-04T19:28:00+05:00"),
  //     // desc: "Pre-meeting meeting, to prepare for the meeting",
  //   },
  //   {
  //     id: 6,
  //     title: "Meeting",
  //     start: new Date("2023-04-12T00:00:00+05:00"),
  //     end: new Date(2023, 3, 12, 1, 30, 0, 0),
  //     desc: "Pre-meeting meeting, to prepare for the meeting",
  //   },
  //   {
  //     id: 6,
  //     title: "Meeting",
  //     start: new Date("2023-04-12T01:00:00+05:00"),
  //     end: new Date(2023, 3, 12, 2, 30, 0, 0),
  //     desc: "Pre-meeting meeting, to prepare for the meeting",
  //   },
  //   {
  //     id: 6,
  //     title: "Meeting",
  //     start: new Date("2023-04-12T00:00:00+05:00"),
  //     end: new Date(2023, 3, 12, 1, 30, 0, 0),
  //     resource: "Pre-meeting meeting, to prepare for the meeting",
  //   },
  //   {
  //     id: 6,
  //     title: "Meeting",
  //     start: new Date("2023-04-12T00:00:00+05:00"),
  //     end: new Date(2023, 3, 12, 1, 30, 0, 0),
  //     resource: "Pre-meeting meeting, to prepare for the meeting",
  //   },
  //   {
  //     id: 6,
  //     title: "Meeting",
  //     start: new Date("2023-04-12T00:00:00+05:00"),
  //     end: new Date(2023, 3, 12, 1, 30, 0, 0),
  //     resource: "Pre-meeting meeting, to prepare for the meeting",
  //   },
  // ];

  useEffect(() => {
    console.log(Events);
  }, [Events]);

  useEffect(() => {
    let values = [];
    if (engagementEvents) {
      engagementEvents?.map((data) => {
        let obj = {
          title: data?.title,
          start: new Date(data?.start_date),
          end: new Date(data?.end_date),
          color: data.color_code ? data.color_code : null,
        };
        values.push(obj);
      });

      setevent(values);
    }
  }, [engagementEvents]);

  const handleGet = () => {
    if (!selectedEmployee) {
      return dispatch(
        FetchEngagementEvents({
          userid: user?.user_id,
          month: moment().format("YYYY-MM-DD"),
        })
      );
    }
    console.log(selectedEmployee);
    dispatch(
      FetchEngagementEvents({
        userid: selectedEmployee?.userid,
        month: moment().format("YYYY-MM-DD"),
      })
    );

    console.log(selectedEmployee);
  };

  const eventPropGetter = (event) => {
    const backgroundColor = event.color; // get the event color
    const style = {
      backgroundColor,
      borderRadius: "5px",
      opacity: 1,
      border: "none",
      color: event?.color ? "white" : "black",
    };
    return {
      style,
    };
  };

  return (
    <Box className={container}>
      {user?.type === "Admin" ? (
        <Box className={FieldContainer} >
          <Box className={Field_input}>
            <Autocomplete
              disablePortal
              options={employees}
              getOptionLabel={(option) => option?.name}
              sx={{
                input: { color: "black" },
                maxWidth: "350px",
                textAlign: "left",
              }}
              fullWidth
              // value={values.emp_id}
              size="small"
              onChange={(e, val) => {
                console.log(e, val);
                setselectedEmployee(val);
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Employee" />
              )}
            />
            <Button
              variant="contained"
              sx={{ padding: "5px 10px" }}
              onClick={handleGet}
            >
              Get
            </Button>
          </Box>
          <Box>
            <Typography color='red'>COMMING SOON...</Typography>
          </Box>

        </Box>
      ) : null}
      {status !== "succeeded" ? (
        <Box className={loaderContainer}>
          <CircularProgress />
        </Box>
      ) : (
        <Calendar
          localizer={localizer}
          startAccessor="start"
          onSelectEvent={() => {
            // setevent(event);
            // setopen(true);
          }}
          defaultView="month"
          events={event}
          endAccessor="end"
          eventPropGetter={eventPropGetter}
          defaultDate={new Date()}
          views={["month", "week", "agenda", "day"]}
          style={{ height: "100%", width: "100%" }}
        />
      )}
      {/* <EventModal open={open} setopen={setopen} event={event} /> */}
    </Box>
  );
};

export default CalendarChart;
