import React, { useState, useRef, useContext, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  Typography,
  TextField,
  MenuItem,
  CircularProgress,
  // Select,
  InputLabel,
  OutlinedInput,
  Chip,
  IconButton,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableBody,
  // SelectChangeEvent
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import { object, string } from "yup";
import PropTypes from "prop-types";
import theme from "../../theme";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
// import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { AddJobTitleApi } from "../../Api/JobTitle/JobTitleAPis";
import { JobTitleAdded } from "../../Redux/Slice/JobTitle/JobTitleSlice";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useTheme } from "@mui/material/styles";
import Select from "@mui/material/Select";
import AddIcon from "@mui/icons-material/Add";
import {
  jobTitlePopulate,
  getJobTitleDetails,
} from "../../Redux/Slice/JobTitle/JobTitleSlice";
import { Formik, Form, Field } from "formik";
import { UpdateJobTitleApi } from "../../Api/JobTitle/UpdateJobTitleApi";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => {
  return {
    input_typography: {
      textAlign: "left",
      fontSize: `15px !important`,
      marginTop: "10px !important",
      paddingRight: "20px !important",
    },
    Form_input: {
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,
    },

    form_main: {
      minHeight: "calc(100vh - 175px)",
      width: "100%",
      padding: "60px",
      display: "flex",
      justifyContent: "center",
    },

    box: {
      maxWidth: "900px",
      width: "100%",
      display: "flex",
      gap: "20px",
      justifyContent: "space-between",
      flexDirection: "column",
    },
    btns: {
      width: "80px",
    },
    main_box: {
      width: "100%",
    },
    FieldInRow: {
      display: "flex",
      justifyContent: "space-between",
      gap: "20px",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },
  };
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(tech, filterTechnology) {
  return {
    backgroundColor: filterTechnology?.indexOf(tech) === -1 ? "" : "#BCDAF4",
  };
}
const JobTitleForm = ({ setShowForm, updateRow, isEdited, setIsEdited }) => {
  const theme = useTheme();


  const { setsnackBarData } = useContext(SnackBarContext);
  const {
    Form_input,
    //  inputBox,
    input_typography,
    btns,
    form_main,

    main_box,
    box,
    FieldInRow,
  } = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filterTechnology, setfilterTechnology] = useState([]);
  const [filterExpertise, setfilterExpertise] = useState([]);
  const [AllTechnology, setAllTechnology] = useState([]);
  const [AllExperties, setAllExperties] = useState([]);
  console.warn(AllExperties);
  console.warn(filterExpertise);
  console.log("Tech Name",filterTechnology);
  const [AllDepartment, setAllDepartment] = useState([]);
  const [selectedExperties, setselectedExperties] = useState([]);
  // const { Technologies } = useSelector(getJobTitleDetails);
  const ref = useRef();
  const [addedItems, setAddedItems] = useState([]);
  console.log("addedItems", addedItems);
  const [selectedTechnologies, setselectedTechnologies] = useState({});
  const [formData, setformData] = useState({
    job_title: "",
    department: "",
    technologies: [],
    expertise: [],
  });

  useEffect(() => {
    if (isEdited && updateRow) {
      console.log(updateRow);
      let value = updateRow.technologies?.expertise?.map(
        (expertise) => expertise?.expertise_name
      );
      let techName = updateRow.technologies?.map(
        (tech) => tech?.technology_name
      );
      console.log("Tech Name",techName);
      console.log(value);
      // let techName = updateRow.technology?.map((tech) => tech?.technology_name);
      let ExpertieName = updateRow.expertise?.map((exp) => exp?.expertise_name);

      let arr = [];
      updateRow?.technologies?.map((item) => {
        let arr2 = [];
        item?.expertise?.map((item2) => {
          arr2.push({
            expertise_id: item2?.expertise_id,
            expertise_name: item2?.expertise_name
          })

        })
        arr.push({
          tech_id: item?.tech_id,
          technology_name: item?.technology_name,
          expertiseid: arr2
        })
      })
      // let arr3=[];
      // updateRow?.technologies?.map((item) => {
      //   arr3.push({
      //     technology_name: item?.technology_name,
      //   })
      //   localStorage.setItem("technology",JSON.stringify(arr3))
      // });
      // console.log(arr);
      // console.log("My Array",arr3)
      setfilterTechnology(techName || []);
      setfilterExpertise(ExpertieName || []);
      setAddedItems(arr);
      // setAllTechnology(arr3)
      setformData((prevFormData) => ({
        ...prevFormData,
        technologies: techName,
      }));
      formData.job_title = updateRow.name;
      formData.department = updateRow.dept_id?.dept_id;
    }
  }, [updateRow]);

  const { technologies, experties, department, IsLogin, message } =
    useSelector(getJobTitleDetails);
  // console.log(updateRow);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
    setAllTechnology(technologies);
    setAllExperties(experties);
    setAllDepartment(department);
  }, [technologies, experties, department, IsLogin]);
  useEffect(() => {
    dispatch(jobTitlePopulate());
  }, [dispatch]);

  const AddJobTitle = async (values, formikHelpers) => {
    let techExpArr = [];
    addedItems.forEach((item) => {
      techExpArr.push({
        tech_id: item.tech_id,
        expertise: item.expertiseid.map((exp) => exp.expertise_id),
      });
    });

    // let techID = [];
    // let ExpID = [];
    // filterTechnology?.map((tech) => {
    //   AllTechnology?.map((tech2) => {
    //     if (tech2.technology_name === tech) {
    //       techID.push(tech2.tech_id);
    //     }
    //   });
    // });


    // filterExpertise?.map((expert) => {

    //   AllExperties?.map((expertie) => {


    //     if (expertie.expertise_name === expert)
    //       ExpID.push(expertie.expertise_id);
    //   });
    // });

    let valuesData = {
      ...values,
      technologies: techExpArr,
      //expertise: ExpID,
    };
    if (isEdited) {
      const response2 = await UpdateJobTitleApi({
        jobTitle_id: updateRow?.id,
        job_title: values.job_title,
        department: values.department,
        technologies: techExpArr,
        //  expertise: ExpID,
      });

      setsnackBarData(response2.snackBarData);
      if (response2?.data?.IsSuccess) {
        formikHelpers.resetForm();
        // dispatch(ProjectSubscriptionUpdated())
        setShowForm(false);
        setIsEdited(false);
      }
      formikHelpers.setSubmitting(false);
    } else {
      const response = await AddJobTitleApi(valuesData);
      setsnackBarData(response.snackBarData);
      if (response.data.IsSuccess) {
        setShowForm(false);

        setformData({
          job_title: "",
          department: "",
          technologies: [],
          // expertise: [],
        });
        setfilterExpertise([]);
        setfilterTechnology([]);
        dispatch(JobTitleAdded(response.data.Data));
      }
      formikHelpers.setSubmitting(false);
    }
  };

  const handleChangeValue = (event) => {


    const {
      target: { value },
    } = event;


    setfilterTechnology(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  // const handleChangeexprtieVal = (event) => {

  //   const {
  //     target: { value },
  //   } = event;
  //   setfilterExpertise(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };
  const handleChangeexp = (event) => {
    const selectedValue = event.target.value;

    setselectedExperties(selectedValue);
  };
  const handleCancelRow = (index) => {
    const updatedItems = [...addedItems];
    updatedItems.splice(index, 1);
    setAddedItems(updatedItems);
  };


  const handelAddTechnologies = () => {
    if (!selectedTechnologies || selectedExperties.length < 1) {
      setsnackBarData({
        type: "error",
        message: "Please select technology with their expertise",
        openToast: true,
      });
      return;
    }
    const techExperties = selectedTechnologies.expertiseid.filter((exp) => {
      let matched = selectedExperties.some((ex) => {
        if (ex === exp.expertise_name) {
          return exp;
        }
      });
      return matched;
    });
    console.log("selectedTechnologies", techExperties);
    var tech = JSON.parse(JSON.stringify(selectedTechnologies));
    tech.expertiseid = techExperties;
    const newItems = [tech];

    setAddedItems([...addedItems, ...newItems]);
    setselectedTechnologies([]);
    setselectedExperties([]);
  };

  return (
    <Box className={form_main}>
      <Box sx={{ maxWidth: "900px", width: "100%" }}>
        <Formik
          initialValues={formData}
          validationSchema={object({
            job_title: string().required("This field is required"),
            department: string().required("This field is required"),
            // technologies: string().required("This field is required"),
          })}
          onSubmit={(values, formikHelpers) => {
            AddJobTitle(values, formikHelpers);
          }}
        >
          {({
            errors,
            touched,
            isSubmitting,
            values,
            handleSubmit,
            handleChange,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Box className={box}>
                  <Box className={FieldInRow}>
                    <Box className={main_box}>
                      <Typography className={input_typography}>
                        Job Title
                      </Typography>

                      <FormControl fullWidth sx={{ textAlign: "left" }}>
                        <Field
                          size="small"
                          name="job_title"
                          as={TextField}
                          onChange={handleChange}
                          value={values.job_title}
                          variant="outlined"
                          type="text"
                          color="primary"
                          sx={{ input: { color: "black" } }}
                          className={Form_input}
                          error={
                            Boolean(errors.job_title) &&
                            Boolean(touched.job_title)
                          }
                          helperText={
                            Boolean(touched.job_title) && errors.job_title
                          }
                        ></Field>
                      </FormControl>
                    </Box>

                    <Box className={main_box}>
                      <Typography className={input_typography}>
                        Department
                      </Typography>

                      <FormControl fullWidth sx={{ textAlign: "left" }}>
                        <Field
                          onChange={handleChange}
                          value={values.department}
                          size="small"
                          name="department"
                          as={TextField}
                          // placeholder="Enter Employee Id"
                          variant="outlined"
                          type="text"
                          color="primary"
                          sx={{ input: { color: "black" } }}
                          className={Form_input}
                          fullWidth
                          select
                          error={
                            Boolean(errors.department) &&
                            Boolean(touched.department)
                          }
                          helperText={
                            Boolean(touched.department) && errors.department
                          }
                        >
                          {AllDepartment?.map((dpt) => (
                            <MenuItem value={dpt?.dept_id} key={dpt.dept_id}>
                              {dpt?.department_name}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                    </Box>
                  </Box>

                  {/* <Box className={FieldInRow}>
                    <Box className={main_box}>
                      <Typography className={input_typography}>
                        Technologies
                      </Typography>

                      <FormControl fullWidth sx={{ textAlign: "left" }}>
                        <Select
                          multiple
                          size="small"
                          variant="outlined"
                          type="text"
                          color="primary"
                          sx={{ input: { color: "black" } }}
                          className={Form_input}
                          name="technologies"
                          MenuProps={MenuProps}
                          onChange={handleChangeValue}
                          value={filterTechnology}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Chip"
                            />
                          }
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected?.map((value, i) => (
                                <Chip key={i} label={value} />
                              ))}
                            </Box>
                          )}
                        >
                          {AllTechnology?.map((tech) => {
                            return (
                              <MenuItem
                                style={getStyles(
                                  tech?.technology_name,
                                  filterTechnology,
                                  theme
                                )}
                                // style={getStyles(tech, filterTechnology, theme)}
                                value={tech?.technology_name}
                                key={tech?.tech_id}
                              >
                                {tech?.technology_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>

                    <Box className={main_box}>
                      <Typography className={input_typography}>
                        Experties
                      </Typography>

                      <FormControl fullWidth sx={{ textAlign: "left" }}>
                        <Select
                          multiple
                          size="small"
                          variant="outlined"
                          type="text"
                          color="primary"
                          sx={{ input: { color: "black" } }}
                          className={Form_input}
                          name="expertise"
                          MenuProps={MenuProps}
                          onChange={handleChangeexprtieVal}
                          value={filterExpertise}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Chip"
                            />
                          }
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected?.map((value, i) => (
                                <Chip key={i} label={value} />
                              ))}
                            </Box>
                          )}
                        >
                          <MenuItem value="">Select</MenuItem>
                          {AllExperties?.map((exp) => {
             
                            return (
                              <MenuItem
                                style={getStyles(
                                  exp.expertise_name,
                                  filterExpertise,
                                  theme
                                )}
                                value={exp.expertise_name}
                                key={exp?.expertise_id}
                              >
                                {exp?.expertise_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box> */}
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Box className={FieldInRow}>
                      <Box className={main_box}>
                        <InputLabel
                          id="technology"
                          className={input_typography}
                        >
                          Technology
                        </InputLabel>
                        <FormControl fullWidth sx={{ textAlign: "left" }}>
                          <Field
                            size="small"
                            name="technologies"
                            id="technology"
                            variant="outlined"
                            onChange={(event) => {
                              handleChangeValue;
                              setselectedTechnologies(event.target.value);
                              setselectedExperties([]);
                            }}
                            as={TextField}
                            type="text"
                            color="primary"
                            ref={ref}
                            value={selectedTechnologies}
                            sx={{ input: { color: "black" } }}
                            className={Form_input}
                            fullWidth
                            select
                          >
                            <MenuItem value={""}>Select</MenuItem>
                            {AllTechnology
                              ? AllTechnology.map((tech, index) => {
                                return (
                                  <MenuItem
                                    // style={getStyles(
                                    //   tech.technology_name,
                                    //   selectedTechnologies,
                                    //   theme
                                    // )}
                                    key={index}
                                    value={tech}
                                  >
                                    {tech.technology_name}
                                  </MenuItem>
                                );
                              })
                              : null}
                          </Field>
                        </FormControl>
                      </Box>

                      <Box className={main_box}>
                        <InputLabel
                          id="expertise"
                          className={input_typography}
                        >
                          Skill Set
                        </InputLabel>

                        <FormControl fullWidth sx={{ textAlign: "left" }}>
                          <Select
                            size="small"
                            name="expertise"
                            id="expertise"
                            variant="outlined"
                            type="text"
                            color="primary"
                            onChange={handleChangeexp}
                            sx={{ input: { color: "black" } }}
                            className={Form_input}
                            fullWidth
                            value={selectedExperties || []}
                            multiple
                            input={
                              <OutlinedInput
                                id="select-multiple-chip"
                                label="Chip"
                              />
                            }
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected?.map((value, i) => (
                                  <Chip key={i} label={value} />
                                ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >

                            {selectedTechnologies?.expertiseid?.map(
                              (expertie) => (
                                <MenuItem
                                  style={getStyles(
                                    expertie.expertise_name,
                                    selectedExperties,
                                    theme
                                  )}
                                  value={expertie.expertise_name}
                                  key={expertie.expertise_id}
                                >
                                  {expertie.expertise_name}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ textAlign: "right" }}>
                    <Button
                      variant="contained"
                      sx={{ padding: "2px" }}
                      onClick={handelAddTechnologies}
                    >
                      <AddIcon />
                    </Button>
                  </Box>

                  <Paper sx={{ padding: "5px" }} elevation={3}>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ fontWeight: "bolder" }}>
                              Technology
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bolder" }}>
                              Skill Set
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bolder" }}>
                              Actions
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {addedItems?.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell>{item.technology_name}</TableCell>
                              <TableCell>
                                {item.expertiseid
                                  .map((exp) => exp.expertise_name)
                                  .join(", ")}
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  sx={{
                                    background: (theme) =>
                                      theme.palette.error.main,
                                    padding: "0px",
                                    color: (theme) =>
                                      theme.palette.white.main,
                                  }}
                                  onClick={() => handleCancelRow(index)}
                                >
                                  <RemoveIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>

                </Box>

                {isSubmitting ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      paddingTop: "10px",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      justifyContent: "flex-end",
                      display: "flex",
                      gap: "10px",
                      marginTop: "20px",
                    }}
                    spacing={2}
                  >
                    <>
                      <Button
                        className={btns}
                        variant="contained"
                        color="error"
                        onClick={() => {
                          setIsEdited(false), setShowForm(false);
                        }}
                      >
                        Cancel
                      </Button>
                      {isEdited === true ? (
                        <Button
                          className={btns}
                          type="submit"
                          variant="contained"
                          color="primary"
                        // onClick={() => {
                        //   AddJobTitle();
                        // }}
                        >
                          Update
                        </Button>
                      ) : (
                        <Button
                          className={btns}
                          type="submit"
                          variant="contained"
                          color="primary"
                        // onClick={() => {
                        //   AddJobTitle();
                        // }}
                        >
                          Save
                        </Button>
                      )}
                    </>
                  </Box>
                )}
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};

export default JobTitleForm;
JobTitleForm.propTypes = {
  setShowForm: PropTypes.any,
  updateRow: PropTypes.any,
  isEdited: PropTypes.any,
  setIsEdited: PropTypes.any,
};
