import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    IconButton,
    Modal,
    TextField,
    Typography,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import theme from "../../theme";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { useEffect } from "react";
import { PatchaAdvanceadminApi } from "../../Api/Advanced/PatchAdvanceAdminApi";
import * as Yup from "yup";
import { useFormik } from "formik";
import { fetchAllAdvanced, getAdvancedDetails } from "../../Redux/Slice/Advanced/AdvancedSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";

const useStyles = makeStyles(() => {
    return {
        ModalContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            padding: "10px",
            width: "100%",
        },
        Container: {
            width: "800px",
            padding: "20px",
            border: "none",
            position: "relative",
            background: "white",
            borderRadius: "10px",
            display: "flex",
            gap: "20px",
            flexDirection: "column",
        },
        TableHeader: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        container: {
            // height: "100%",
            width: "100%",
            padding: "0px 20px 20px 20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            [theme.breakpoints.down("md")]: {
                padding: "5px",
            },
        },
        subContainer: {
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            // padding: "20px",
            width: "100%",
            // maxWidth: "700px",
            // minHeight: "calc(100vh - 215px)",
            [theme.breakpoints.down("md")]: {
                padding: "5px",
            },
        },
        formContainer: {
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            textAlign: "left",
        },
    };
});
function AdvanceApprovedModal({ advancedData, showModal, setshowModal }) {
    const { Container, ModalContainer, TableHeader, container, subContainer, formContainer } = useStyles();
    const { setsnackBarData } = useContext(SnackBarContext);
    const { IsLogin, message } = useSelector(getAdvancedDetails);
    const navigate = useNavigate();
    console.log(advancedData)
    const initialValues = {
        amount: "",
    };
    const dispatch = useDispatch()
    useEffect(() => {
        if (advancedData) {
            formik.setFieldValue("amount", advancedData?.requested_amount ? advancedData?.requested_amount : 0)
        }
    }, [advancedData]);
    useEffect(() => {
        if (!IsLogin) {
            setsnackBarData({
                type: "error",
                message: message,
                openToast: true,
            });
            dispatch(logout());
            return navigate("/");
        }
    }, [IsLogin]);
    const handleSave = async (values, setSubmitting, resetForm) => {
        const obj = { ...values, advance_id: advancedData?.advance_id, status: 'Accepted' };

        const response = await PatchaAdvanceadminApi(obj);
        setsnackBarData(response.snackBarData);
        if (response.data.IsSuccess) {
            dispatch(fetchAllAdvanced())
            resetForm();
            setshowModal(false)
        }
        setSubmitting(false);
    };
    const validationSchema = Yup.object().shape({
        amount: Yup.string().required("Please Enter Amount"),
    });
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm, setSubmitting }) => {
            handleSave(values, resetForm, setSubmitting);
        },
    });

    return (
        <>
            <Modal
                open={showModal}
                onClose={() => {
                    setshowModal(false);
                }}
            >
                <Box className={ModalContainer}>
                    <Box className={Container}>
                        <Box className={TableHeader}>
                            <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                                Approve Advance
                            </Typography>
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setshowModal(false);
                                }}
                            >
                                <HighlightOffIcon
                                    color="primary"
                                    sx={{
                                        fontSize: "28px",
                                    }}
                                />
                            </IconButton>
                        </Box>
                        <Box className={container}>
                            <Box className={subContainer}>
                                <form onSubmit={formik.handleSubmit}>
                                    <Box className={formContainer}>
                                        <FormControl fullWidth>
                                            <Typography>Amount</Typography>
                                            <TextField
                                                sx={{ textAlign: "left", input: { color: "black" } }}
                                                fullWidth
                                                size="small"
                                                name="amount"
                                                type="number"
                                                onBlur={formik.handleBlur}
                                                onChange={
                                                    (e) => {
                                                        if (e.target.value > advancedData?.requested_amount) {
                                                            return
                                                        } else {
                                                            formik.setFieldValue("amount", e.target.value)
                                                        }
                                                    }
                                                    // formik.handleChange
                                                }
                                                value={formik.values.amount}
                                                variant="outlined"
                                                error={
                                                    Boolean(formik.touched.amount) &&
                                                    Boolean(formik.errors.amount)
                                                }
                                                helperText={
                                                    Boolean(formik.touched.amount) && formik.errors.amount
                                                }
                                            />
                                        </FormControl>
                                        <Box sx={{ display: "flex", width: "100%", justifyContent: "end" }}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    gap: "10px",
                                                }}
                                            >
                                                <Box>
                                                    {formik.isSubmitting ? (
                                                        <CircularProgress />
                                                    ) : (
                                                        <Button type="submit" variant="contained">
                                                            <Typography>Save</Typography>
                                                        </Button>
                                                    )}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </form>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default AdvanceApprovedModal;
AdvanceApprovedModal.propTypes = {
    showModal: PropTypes.any,
    setshowModal: PropTypes.any,
    advancedData: PropTypes.any,
};
