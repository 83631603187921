import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  cpl: [],
  // singleEmpCpl:[],
  CPL_Populate: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};

export const fetchCpl = createAsyncThunk(
  "getcpldata",
  async ({ year, emp_id }) => {
    let url = `getcpldata?year=${year}`;

    if (emp_id) {
      url += `&emp_id=${emp_id}`;
    }
    const response = await axiosInstance.get(url);

    return response.data;
  }
);
export const CplPopulate = createAsyncThunk("cplpopulate", async () => {
  const response = await axiosInstance.get(`cplpopulate`);
  return response.data;
});
export const fetchsingleEmpCpl = createAsyncThunk(
  "singleempcpl/",
  async (id) => {
    const response = await axiosInstance.get(`singleempcpl/${id}`);

    return response.data;
  }
);

const CplSlice = createSlice({
  name: "cpl",
  initialState,
  reducers: {
    CplAdded: {
      reducer(state, action) {
        state.cpl?.push(action.payload);
      },
    },
    CplResetData: (state) => {
      state.cpl = [];
      // singleEmpCpl=[];
      state.CPL_Populate = [];
      state.isSuccess = false;
      state.message = null;
      state.status = "idle"; //'idle' | 'loading' | 'succeeded' | 'failed'
      state.error = null;
      state.IsLogin = true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCpl.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCpl.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.cpl = action.payload.CPLData;
      })
      .addCase(fetchCpl.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })

      .addCase(CplPopulate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.CPL_Populate = action.payload.CPL_Employees;
      })
      .addCase(CplPopulate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(CplPopulate.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(fetchsingleEmpCpl.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.cpl = action.payload.cpldata;
      })
      .addCase(fetchsingleEmpCpl.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchsingleEmpCpl.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          console.log(action.error.message);
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
  },
});

export const getcplDetails = (state) => state.cpl;

export const { CplAdded, CplResetData } = CplSlice.actions;

export default CplSlice.reducer;
