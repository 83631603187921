import { Box, Divider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

//import companylogo from "../../../Assets/icons/companylogo.png";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyProfile } from "../../../Redux/Slice/CompanyProfile/CompanyProfileSlice";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../Redux/Slice/LogoutSlice/LogoutSlice";
import {SnackBarContext} from "../../../Context/SnackBarContext/SnackBarContext";

const useStyles = makeStyles(() => {
  return {
    topSection: {
      display: "flex",
      // justifyContent: "space-between",
      gap: "20px",
    },
    addressDetailBox: {
      display: "flex",
      flexDirection: "column",
      textAlign: "right",
      justifyContent: "end",
    },
    mobBox: {
      display: "flex",
      gap: "6px",
      justifyContent: "start",
    },
  };
});
function PrintHeader() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { topSection, mobBox, addressDetailBox } = useStyles();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { CompanyProfile, IsLogin, message } = useSelector(getCompanyProfile);

  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  return (
    <>
      <Box className={topSection}>
        {/* <Box> */}
        <Box sx={{ padding: "10px" }}>
          <img
            src={`${CompanyProfile.logo}`}
            alt="img"
            style={{ width: "120px" }}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>

          <Typography variant="h3" size="large">{CompanyProfile?.company_name}</Typography>

          <Box
            className={addressDetailBox}
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              justifyContent: "start",
              // maxWidth: "300px",
            }}
          >

            <Box sx={{
              display: "flex",
              gap: "6px",
            }}>
              <Typography sx={{ fontWeight: "bold" }}>
                Address :
              </Typography>
              <Typography>{CompanyProfile?.address}</Typography>
            </Box>

            <Box
              className={mobBox}
              sx={{
                display: "flex",
                gap: "6px",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>Mobile :</Typography>
              <Typography>{CompanyProfile?.phonenum}</Typography>
            </Box>
            <Box
              className={mobBox}
              sx={{
                display: "flex",
                gap: "6px",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>Email :</Typography>
              <Typography>{CompanyProfile?.email}</Typography>
            </Box>

          </Box>
        </Box>
        {/* </Box> */}
      </Box>
      <Divider sx={{ width: "100%", paddingTop: "20px" }} />
    </>
  );
}

export default PrintHeader;
