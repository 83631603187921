import PropTypes from "prop-types";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  gridClasses,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchSalaryReportsByMonths,
  getSalaryDetailsDetails,
} from "../../Redux/Slice/SalaryDetails/SalaryDetailsSlice";
import { makeStyles } from "@mui/styles";
import { useRef } from "react";
import { useContext } from "react";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { GeneratePayroll } from "../../Api/PayrollDetails/PayrollDetail";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import Loader from "../Loader/Loader";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});

function SalaryGenerateTable({ setShowForm, setrowvalue }) {
  const {tabelStyle}=useContext(TabelStyleContext)

  // const dispatch = useDispatch();
  const { header } = useStyles();
  const [rows, setrows] = useState([]);
  const [filterRows, setfilterRows] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setloading] = useState(false);
  const [selectedDate, setselectedDate] = useState(null);
  const navigate = useNavigate();
  const ByDate = useRef();
  const dispatch = useDispatch();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { status, IsLogin, message, ReportByMonths } = useSelector(
    getSalaryDetailsDetails
  );
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [addPermit, setaddpermit] = useState(false);

  // console.log(crop);
  console.log(selectedDate);
  console.log(roles);
  console.log(ReportByMonths);
  useEffect(() => {
    let string = "MonthlyReport";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Add") {
        setaddpermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      setaddpermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  console.log(ReportByMonths);

  useEffect(() => {
    dispatch(FetchSalaryReportsByMonths());
  }, [dispatch]);

  console.log(addPermit);
  useEffect(() => {
    let arr = [];
    if (ReportByMonths) {
      ReportByMonths?.forEach((item, idx) => {
        return arr.push({
          id: idx + 1,
          month: item.month ? moment(item.month).format("MMMM-YYYY") : "",
          date: item.date ? moment(item?.date).format("DD-MMM-YYYY") : "",
        });
      });
    }
    setrows(arr);
    setfilterRows(arr);
  }, [ReportByMonths]);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport
          csvOptions={{ fileName: "MonthlySalaryGenerateReport" }}
        />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "id", headerName: "Sr #", width: 100, headerClassName: header },
    {
      field: "month",
      headerName: "Month",
      width: 160,
      // type: "date",
      headerClassName: header,
    },
    {
      field: "date",
      headerName: "Created On",
      width: 160,
      headerClassName: header,
    },
    {
      field: "action",
      headerName: "Action",
      width: 160,
      headerClassName: header,
      renderCell: (cellVal) => {
        return (
          <>
            <Box>
              <IconButton
                onClick={() => {
                  console.log(cellVal?.row);
                  setrowvalue(cellVal?.row);
                  setShowForm(true);
                }}
              >
                <VisibilityIcon />
              </IconButton>
            </Box>
          </>
        );
      },
    },
  ];

  const onTextChange = () => {
    if (ByDate.current.value === "") {
      setfilterRows(rows);
    } else {
      let suggestions = [];
      let regex2 = new RegExp(
        moment(ByDate.current.value).format("MMMM-YYYY"),
        "i"
      );
      suggestions = rows?.filter(
        (val) => ByDate.current.value === "" || regex2.test(val?.month)
      );
      setfilterRows(suggestions);
    }
  };

  const handleSubmit = async () => {
    setloading(true);
    if (!selectedDate) {
      setloading(false);
      return setsnackBarData({
        openToast: true,
        message: "please select date",
        type: "error",
      });
    }
    const response = await GeneratePayroll({ month: selectedDate });
    setsnackBarData(response?.snackBarData);
    if (response.data.IsSuccess) {
      setloading(false);
      dispatch(FetchSalaryReportsByMonths());
    }
    setloading(false);
    console.log(response);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          padding: "20px 10px 10px 10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
            width: "100%",
          }}
        >
          {addPermit ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                width: "100%",
              }}
            >
              <TextField
                size="small"
                type="month"
                variant="outlined"
                color="primary"
                fullWidth
                sx={{
                  input: { color: "black" },
                  maxWidth: "250px",
                  textAlign: "left",
                }}
                // value={currenttime}
                inputProps={{
                  max: moment().format("YYYY-MM"),
                }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setselectedDate(e.target.value);
                }}
              />
              <Button
                sx={{ padding: "5px 10px" }}
                onClick={() => {
                  handleSubmit();
                }}
                variant="contained"
                className="tabletopbutton1 text-right"
              >
                Generate
              </Button>
            </Box>
          ) : null}
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "end",
            width: "100%",
          }}
        >
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: (theme) => theme.palette.grey.G2 }}
                  />
                </InputAdornment>
              ),
            }}
            variant="standard"
            type="month"
            autoComplete="off"
            placeholder="Search"
            // className="input_border"
            inputRef={ByDate}
            id="date"
            onChange={onTextChange}
          />
        </Box>
      </Box>

      <span className="ml-2 float-right">
        <div className="loaderclass"> </div>
      </span>

      {status !== "succeeded" ? (
        <>
<Loader/>
        </>
      ) : null}
        <Grid item md={12} sm={12} xs={12} overflow sx={{height:tabelStyle.height,padding:'10px'}}
        >

          
            <DataGrid
              rows={filterRows}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
              pagination
              components={{
                Toolbar: CustomToolbar,
              }}
              localeText={{
                toolbarDensity: "Size",
                toolbarDensityLabel: "Size",
                toolbarDensityCompact: "Small",
                toolbarDensityStandard: "Medium",
                toolbarDensityComfortable: "Large",
              }}
            />
        </Grid>
     
      {loading ? (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            height: "100%",
            width: "100%",
            zIndex: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(250,250,250,0.5)",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null}
    </>
  );
}

export default SalaryGenerateTable;
SalaryGenerateTable.propTypes = {
  setShowForm: PropTypes.any,
  setrowvalue: PropTypes.any,
};
