import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import PropTypes from "prop-types";

const Card2 = ({ title, icon, data }) => {
  return (
    <>
      <Box
        sx={{
          height: "70px",
          width: "70px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "10px",
        }}
      >
        {icon}
      </Box>
      <Box>
        {title}
        <Typography variant="h6" sx={{fontWeight:'bold',textAlign:'left'}}>{data}</Typography>
      </Box>
    </>
  );
};

export default Card2;

Card2.propTypes = {
  title: PropTypes.any,
  data: PropTypes.any,
  icon: PropTypes.any,
  color: PropTypes.any,
};
