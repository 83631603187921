import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  experties: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,

};

export const fetchExperties = createAsyncThunk(
  "getexpertise",
  async () => {
    const response = await axiosInstance.get(`getsocialskills`);
    return response.data;
  }
);

const ExpertiesSlice = createSlice({
  name: "experties",
  initialState,
  reducers: {
    ExpertiesAdded: {
      reducer(state, action) {
        state.experties.push(action.payload);

      },
    },
    deleteExpertise: {
      reducer(state, action) {
        const userIndex = state?.experties.findIndex(
          (experties) => experties.expertise_id === action.payload
        );
        if (userIndex !== -1) {
          state.experties.splice(userIndex, 1);
        }
      },
    },
    ExpertiesResetData: (state) => {
      state.experties= [];
      state.isSuccess= false;
      state.message= null;
      state.status= "idle"; //'idle' | 'loading' | 'succeeded' | 'failed'
      state.error= null;
      state.IsLogin= true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchExperties.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchExperties.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.experties = action.payload.Expertise;
      })
      .addCase(fetchExperties.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })

  },
});

export const getExpertiesDetails = (state) => state.experties;

export const { ExpertiesAdded,ExpertiesResetData,deleteExpertise } = ExpertiesSlice.actions;

export default ExpertiesSlice.reducer;