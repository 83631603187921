import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  Empmonthlyreport: [],
  EmpMonthlyAttendance: null,
  Report_Populate: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};

// export const fetchMonthlyReport = createAsyncThunk(
//   "getmonthlyreport",
//   async () => {
//     const response = await axiosInstance.get(`getmonthlyreport`);
//     return response.data;
//   }
// );

export const fetchMonthlyReportPopulate = createAsyncThunk(
  "monthlyrepopopulate",
  async () => {
    const response = await axiosInstance.get(`monthlyrepopopulate`);
    return response.data;
  }
);
export const FetchMonthlyAttendanceReport = createAsyncThunk(
  "FetchMonthlyAttendanceReport",
  async (obj) => {
    const response = await axiosInstance.get(
      `/monthattendancesummary?emp_id=${obj?.empid}&month=${obj?.month}`
    );
    return response.data;
  }
);

export const fetchsingleEmpReport = createAsyncThunk(
  "emponemonthattendance?id",
  async ({ id, month }) => {
    const response = await axiosInstance.get(
      `emponemonthattendance?id=${id}&month=${month}`
    );

    return response.data;
  }
);

const EmpMonthlyReportSlice = createSlice({
  name: "Empmonthlyreport",
  initialState,
  reducers: {
    MonthlyReportAdded: {
      reducer(state, action) {
        state.Empmonthlyreport.push(action.payload);
      },
    },
    RemoveEmpMonthlyReport: {
      reducer(state) {
        state.Empmonthlyreport = [];
      },
    },
    EmpMonReportResetData: (state) => {
      state.Empmonthlyreport = [];
      state.Report_Populate = [];
      state.EmpMonthlyAttendance = null;
      state.isSuccess = false;
      state.message = null;
      state.status = "idle"; //'idle' | 'loading' | 'succeeded' | 'failed'
      state.error = null;
      state.IsLogin = true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMonthlyReportPopulate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Report_Populate = action.payload.Employees;
      })
      .addCase(fetchMonthlyReportPopulate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMonthlyReportPopulate.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(fetchsingleEmpReport.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Empmonthlyreport = action.payload.Data;
      })
      .addCase(fetchsingleEmpReport.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchsingleEmpReport.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(FetchMonthlyAttendanceReport.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.EmpMonthlyAttendance = action.payload;
      })
      .addCase(FetchMonthlyAttendanceReport.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchMonthlyAttendanceReport.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
  },
});

export const getEmpmonthlyreportDetail = (state) => state.Empmonthlyreport;

export const {
  MonthlyReportAdded,
  RemoveEmpMonthlyReport,
  EmpMonReportResetData,
} = EmpMonthlyReportSlice.actions;

export default EmpMonthlyReportSlice.reducer;
