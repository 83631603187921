import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  monthlyChanges: null,
  weeklyChanges: null,
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  monthlyChangesstatus: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};

export const FetchMonthlyChange = createAsyncThunk("FetchMonthlyChange", async (month) => {
  const response = await axiosInstance.get(`monthchangereqs?month=${month}`);
  return response.data;
});

export const FetchWeeklyChanges = createAsyncThunk(
  "FetchWeeklyChanges",
  async () => {
    const response = await axiosInstance.get(`weekchangereqs`);
    return response.data;
  }
);

const PapulateChangeRequestSlice= createSlice({
  name: "changeRequests",
  initialState,
 
  extraReducers(builder) {
    builder
      .addCase(FetchMonthlyChange.pending, (state) => {
        state.monthlyChangesstatus = "loading";
      })
      .addCase(FetchMonthlyChange.fulfilled, (state, action) => {
        state.monthlyChangesstatus = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.monthlyChanges = action.payload.Data;
      })
      .addCase(FetchMonthlyChange.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.monthlyChangesstatus = "failed";
        }
      })
      .addCase(FetchWeeklyChanges.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchWeeklyChanges.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.weeklyChanges = action.payload.Data;
      })
      .addCase(FetchWeeklyChanges.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
  },
});

export const getChangeRequests = (state) => state.changeRequests;


export default PapulateChangeRequestSlice.reducer;
