import {
  Box,
  Button,
  FormControl,
  Modal,
  Table,
  TableBody,
  TableCell,
  // TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { makeStyles } from "@mui/styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useEffect } from "react";
import theme from "../../theme";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const useStyles = makeStyles(() => {
  return {
    main_table: {
      fontFamily: "Poppins !important",
      backgroundColor: theme.palette.common.white,
      padding: "15px",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
      maxWidth:'600px',
      height: "500px",
      border: "1px solid #888",
      borderRadius: " 10px",
      overFlow: "scroll",
    },
    table_cell: {
      textAlign: "left",
      // fontWeight: "bold",
      fontSize: `15px !important`,
      // opacity: "50%",
      marginTop: "10px !important",
    },
  };
});

const ProjectDynamicTable = ({
  setshowmodule,
  showmodule,
  SelectedIndex,
  tablerow,
  settablerow,
  setSelectRow,
  SelectRow,
  setSelectedIndex,
 
  isEdited,
  setDeleteSubModules,
  DeleteSubModules,

}) => {
  // const [SubModuleName] = useState("")
  const { main_table, table_cell } = useStyles();
  // const [tablerow, settablerow] = useState([])
  // const [SubModuleName, ] = useState("")
  const [submoduledata, setsubmoduledata] = useState([]);

useEffect(()=>{
  if(isEdited && showmodule && SelectRow?.submodules?.length > 0){
   
    setsubmoduledata(SelectRow?.submodules);
  
  }
    },[showmodule])
  const handleAdd = () => {
    let obj = {
      submodule_name: "",
    };
    setsubmoduledata([...submoduledata,obj]);
  };


  const handleDelete = (ind) => {

    const data = [...submoduledata];
    let abc = data[ind]

    setDeleteSubModules([...DeleteSubModules,abc?.submodule_id])
    data.splice(ind, 1);
    setsubmoduledata(data);
  };
  const handleClose = () => {
    setshowmodule(false);
    setSelectRow(null);
    setSelectedIndex(null);
  };
  const handleSaves = () => {
    let obj = {
      ...SelectRow,
      submodules: submoduledata,
    };
 
    let arr = [];
    tablerow.map((x, id) => {
      if (id === SelectedIndex) {
        x = obj;
      }
      arr.push(x);
    });
    settablerow(arr);

    handleClose();
  };

  useEffect(() => {
    if (showmodule && SelectRow?.submodules?.length > 0) {
      setsubmoduledata(SelectRow?.submodules);
    }
  }, [showmodule]);

  return (
    <Modal
    // disableScrollLock={false}
      open={showmodule}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Box className={main_table}>
          <HighlightOffIcon
            sx={{ float: "right", cursor: "pointer" }}
            onClick={() => {
              handleClose(close);
            }}
          />
          
          <Box sx={{ padding: 5 ,height:"400px" ,overflow:"scroll"}}>
            <Table
              sx={{
                height:"100%",
                overflow:"scroll"
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell className={table_cell}>Sub Module Name</TableCell>
                  <TableCell>
                    <AddCircleIcon sx={{cursor: "pointer"}} color="primary" onClick={handleAdd} />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
                {submoduledata?.length > 0 &&
                  submoduledata?.map((val, idx) => {
                    return (
                      <TableRow sx={{display:"flex",}} key={idx}>
                        <TableCell sx={{}} >
                          <FormControl fullWidth sx={{minWidth:'250px'}}>
                            <TextField
                              key={`submodule_name${idx}`}
                              sx={{}}
                              size="small"
                              fullWidth
                              type="text"
                              id="outlined-basic"
                              onChange={(e) => {
                                let newArr = JSON.parse(
                                  JSON.stringify(submoduledata)
                                );
                                newArr[idx].submodule_name = e.target.value;
                                setsubmoduledata(newArr);
                              }}
                              value={val.submodule_name}
                              variant="outlined"
                            />
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <Box sx={{ display: "flex" }}>
                            <DeleteForeverIcon
                              onClick={() => {
                                handleDelete(idx);
                              }}
                            />
                            {/* <AppRegistrationIcon /> */}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
           

          </Box>
          <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <Button
               sx={{cursor: "pointer"}} 
                variant="contained"
                color="primary"
                onClick={() => {
                  handleSaves();
                }}
              >
                Save
              </Button>
            </Box>
           
        </Box>
      </>
    </Modal>
  );
};

export default ProjectDynamicTable;
ProjectDynamicTable.propTypes = {
  setshowmodule: PropTypes.any,
  showmodule: PropTypes.any,
  SelectedIndex: PropTypes.any,
  tablerow: PropTypes.any,
  settablerow: PropTypes.any,
  setSelectRow: PropTypes.any,
  SelectRow: PropTypes.any,
  setSelectedIndex: PropTypes.any,
  projData: PropTypes.any,
  isEdited: PropTypes.any,
  DeleteSubModules: PropTypes.any,
  setDeleteSubModules: PropTypes.any,
};
