import { Box } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import AttendanceForm from "../../Components/Attendance/AttendanceForm";
import AttendanceTable from "../../Components/Attendance/AttendanceTable";
const AttendanceMain = () => {
  const [ShowForm, setShowForm] = useState(false);
  const [SelectedRow, setSelectedRow] = useState(null);
  const [canView, setcanView] = useState(null);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));

  console.log(roles);
  useEffect(() => {
    let string = "Attendance";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    let match = value?.some((val) => {
      if (val?.module?.split("_")[1] === "View") {
        return true;
      }
    });
    setcanView(match);
    console.log(match);
    if (!roles || roles.length < 1) {
      setcanView(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);

  return (
    <Box>
            {canView === true ? (
      ShowForm ? (
        <AttendanceForm
          ShowForm={ShowForm}
          setShowForm={setShowForm}
          setSelectedRow={setSelectedRow}
          SelectedRow={SelectedRow}
        
        />
      ) : (
        <AttendanceTable
          SelectedRow={SelectedRow}
          setSelectedRow={setSelectedRow}
          setShowForm={setShowForm}
        />
      )
      ) : canView === false ? (
        <Navigate to={"/dashboard"} />
      ) : (
        <Box>Loading ...</Box>
      )}
    </Box>
  );
};

export default AttendanceMain;
