// import { Box } from "@mui/material";
import React from "react";
// import NewDashboard from "../../Components/Dashboard/Dashboard";
import DashboardRedesign from "../DashboardReDesign/DashboardRedesign";
// import DashboardTopSection from "../../Components/Dashboard/DashboardTopSection/DashboardTopSection";

const Dashboard = () => {
  return (
    <>
      {/* <NewDashboard /> */}
      <DashboardRedesign />
    </>
  
  );
};

export default Dashboard;
