import { Box, LinearProgress, Modal, Typography } from '@mui/material';
import React from 'react'
import PropTypes from "prop-types";
import report from '../../Assets/report2.jpg'

function Loader2({showloadingModal,setshowloadingModal,loadingMessage,setloadingMessage}) {
  return (
    <Modal
    open={showloadingModal}
    onClose={() => {
        setloadingMessage("Calculating Hours...")
      setshowloadingModal(false);
    }}
  >
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        padding: "10px",
        width: "100%",
        backgroundColor: "rgba(0,0,0,0.1) !important",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "390px !important",
          border: "none",
          position: "relative",
          background: "white !important",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          maxHeight: "100%",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap:"10px"
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              background: "#53b7c5",
            }}
          >
            <img src={report}          style={{
                  width: "100%",
                  maxWidth: "140px",
                  height: "140px",
                  borderRadius: "100%",
                }} alt="img" />
          </Box>
          {/* <RingLoader color={"#36D7B7"} loading={true} size={150} /> */}
          <Box sx={{padding:"20px",
          width: "100%",
        textAlign:"center"
        }}>
          <Typography
            variant="h2"
            sx={{
              fontSize: "18px !important",
              fontWeight: "500 !important",
            }}
          >
           {/* Calculating Hours... */}
            {loadingMessage}
          </Typography>
          {/* <Typography
            variant="h2"
            sx={{
              fontSize: "18px !important",
              fontWeight: "500 !important",
            }}
          >
        Fetching Absents...

          </Typography> */}
          <LinearProgress sx={{ width: "100%", mt: 2 }} />
          </Box>
        </Box>
      </Box>
    </Box>
  </Modal>
  )
}

export default Loader2;
Loader2.propTypes = {
    showloadingModal: PropTypes.any,
    setshowloadingModal: PropTypes.any,
    loadingMessage: PropTypes.any,
    setloadingMessage: PropTypes.any,
  };