import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    IconButton,
    MenuItem,
    Modal,
    TextField,
    Typography,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import theme from "../../theme";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { useEffect } from "react";
import { PatchaAdvanceadminApi } from "../../Api/Advanced/PatchAdvanceAdminApi";
import * as Yup from "yup";
import { useFormik } from "formik";
import { fetchAllAdvanced } from "../../Redux/Slice/Advanced/AdvancedSlice";
import { useDispatch,
    //  useSelector
     } from "react-redux";
// import { useNavigate } from 'react-router-dom'
// import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
const useStyles = makeStyles(() => {
    return {
        ModalContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            padding: "10px",
            width: "100%",
        },
        Container: {
            width: "800px",
            padding: "20px",
            border: "none",
            position: "relative",
            background: "white",
            borderRadius: "10px",
            display: "flex",
            gap: "20px",
            flexDirection: "column",
        },
        TableHeader: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        container: {
            // height: "100%",
            width: "100%",
            padding: "0px 20px 20px 20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            [theme.breakpoints.down("md")]: {
                padding: "5px",
            },
        },
        subContainer: {
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            // padding: "20px",
            width: "100%",
            // maxWidth: "700px",
            // minHeight: "calc(100vh - 215px)",
            [theme.breakpoints.down("md")]: {
                padding: "5px",
            },
        },
        formContainer: {
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            textAlign: "left",
        },
    };
});
function AdvancedEditModal({ editRow, showModal2, setshowModal2 }) {
    const { Container, ModalContainer, TableHeader, container, subContainer, formContainer } = useStyles();
    const { setsnackBarData } = useContext(SnackBarContext);
    // const navigate = useNavigate();
    // const { IsLogin, message } = useSelector(PatchaAdvanceadminApi);

    const initialValues = {
        status: "",
        note: "",
        amount: "",
    };
    const dispatch = useDispatch()
    // useEffect(() => {
    //     if (!IsLogin) {
    //         setsnackBarData({
    //             type: "error",
    //             message: message,
    //             openToast: true,
    //         });
    //         dispatch(logout());
    //         return navigate("/");
    //     }
    // }, [IsLogin]);
    useEffect(() => {
        if (editRow) {
            formik.setFieldValue("status", editRow?.status ? editRow?.status : null)
            formik.setFieldValue("amount", editRow?.amount ? editRow?.amount : null)
        }
    }, [editRow]);

    const handleSave = async (values, setSubmitting, resetForm) => {
        const obj = { ...values, advance_id: editRow?.advance_id, };

        if (formik.values.status === "Accepted") {
            const response = await PatchaAdvanceadminApi(obj);
            setsnackBarData(response.snackBarData);
            if (response.data.IsSuccess) {
                dispatch(fetchAllAdvanced())
                resetForm();
                setshowModal2(false)
            }
        } else if (formik.values.status === "Rejected") {
            const response = await PatchaAdvanceadminApi({
                advance_id: editRow.advance_id,
                status: "Rejected",
                note: values?.note,
                amount: 0
            });
            setsnackBarData(response.snackBarData);

            if (response.data.IsSuccess) {
                dispatch(fetchAllAdvanced());
                setshowModal2(false)
            }
        }
        setSubmitting(false);
    };
    const validationSchema = Yup.object().shape({
        status: Yup.string().required("Please Enter status"),
        note: Yup.string().required("Please Enter Note"),
    });
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm, setSubmitting }) => {
            handleSave(values, resetForm, setSubmitting);
        },
    });
    return (
        <>
            <Modal
                open={showModal2}
                onClose={() => {
                    setshowModal2(false);
                }}
            >
                <Box className={ModalContainer}>
                    <Box className={Container}>
                        <Box className={TableHeader}>
                            <Typography variant="h1" sx={{ fontSize: "18px !important" }}>
                                Edit Advance
                            </Typography>
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setshowModal2(false);
                                }}
                            >
                                <HighlightOffIcon
                                    color="primary"
                                    sx={{
                                        fontSize: "28px",
                                    }}
                                />
                            </IconButton>
                        </Box>
                        <Box className={container}>
                            <Box className={subContainer}>
                                <form onSubmit={formik.handleSubmit}>
                                    <Box className={formContainer}>
                                        <FormControl fullWidth>
                                            <Typography>Status</Typography>
                                            <TextField
                                                sx={{ textAlign: "left", input: { color: "black" } }}
                                                fullWidth
                                                size="small"
                                                name="status"
                                                select
                                                onBlur={formik.handleBlur}
                                                onChange={
                                                    formik.handleChange
                                                }
                                                value={formik.values.status}
                                                variant="outlined"
                                                error={
                                                    Boolean(formik.touched.status) &&
                                                    Boolean(formik.errors.status)
                                                }
                                                helperText={
                                                    Boolean(formik.touched.status) && formik.errors.status
                                                }
                                            >
                                                <MenuItem value="Accepted">Accepted</MenuItem>
                                                <MenuItem value="Rejected">Rejected</MenuItem>
                                            </TextField>
                                        </FormControl>
                                        {
                                            formik?.values.status === "Rejected" ? null :

                                                <FormControl fullWidth>
                                                    <Typography>Amount</Typography>
                                                    <TextField
                                                        sx={{ textAlign: "left", input: { color: "black" } }}
                                                        fullWidth
                                                        size="small"
                                                        name="amount"
                                                        type={"number"}
                                                        onBlur={formik.handleBlur}
                                                        onChange={
                                                            (e) => {
                                                                if (e.target.value > editRow?.requestedamount) {
                                                                    return
                                                                } else {
                                                                    formik.setFieldValue("amount", e.target.value)
                                                                }
                                                            }
                                                            // formik.handleChange
                                                        }
                                                        value={formik.values.amount}
                                                        variant="outlined"
                                                    />

                                                </FormControl>
                                        }
                                        <FormControl fullWidth>
                                            <Typography>Note</Typography>
                                            <TextField
                                                sx={{ textAlign: "left", input: { color: "black" } }}
                                                fullWidth
                                                size="small"
                                                name="note"
                                                type={"text"}
                                                onBlur={formik.handleBlur}
                                                onChange={
                                                    formik.handleChange
                                                }
                                                value={formik.values.note}
                                                variant="outlined"
                                                error={
                                                    Boolean(formik.touched.note) &&
                                                    Boolean(formik.errors.note)
                                                }
                                                helperText={
                                                    Boolean(formik.touched.note) && formik.errors.note
                                                }
                                            />

                                        </FormControl>
                                        <Box sx={{ display: "flex", width: "100%", justifyContent: "end" }}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    gap: "10px",
                                                }}
                                            >
                                                <Box>
                                                    {formik.isSubmitting ? (
                                                        <CircularProgress />
                                                    ) : (
                                                        <Button type="submit" variant="contained">
                                                            <Typography>Save</Typography>
                                                        </Button>
                                                    )}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </form>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default AdvancedEditModal;
AdvancedEditModal.propTypes = {
    showModal2: PropTypes.any,
    setshowModal2: PropTypes.any,
    editRow: PropTypes.any,
};