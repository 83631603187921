import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  monthlyreport: [],
  Report_Populate: [],
  ReportByMonths: [],
  ReportShiftRuleByMonths: [],
  oneMonthReports: [],
  oneMonthGazzetHolidays: "",
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  message2: null,
  status2: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};
// export const fetchEmpAltOffDays = createAsyncThunk(
//   "singleempalternatedays",
//   async () => {
//     const response = await axiosInstance.get(`singleempalternatedays`);
//     return response.data;
//   }
// );
export const fetchMonthlyReport = createAsyncThunk(
  "getmonthlyreport",
  async () => {
    const response = await axiosInstance.get(`getmonthlyreport`);
    return response.data;
  }
);

export const fetchMonthlyReportPopulate = createAsyncThunk(
  "monthlyrepopopulate",
  async () => {
    const response = await axiosInstance.get(`monthlyrepopopulate`);
    return response.data;
  }
);
export const FetchReportsShiftRuleByMonths = createAsyncThunk(
  "getemployeesshiftrules2",
  async (month) => {
    const response = await axiosInstance.get(`getemployeesshiftrules?month=${month}`);
    return response.data;
  }
);
export const FetchReportsByMonths = createAsyncThunk(
  "gethistorymonth",
  async () => {
    const response = await axiosInstance.get(`gethistorymonth`);
    return response.data;
  }
);

export const FetchOneMonthReport = createAsyncThunk(
  "FetchOneMonthReport",
  async (obj) => {
    console.log(obj);
    const response = await axiosInstance.get(
      `getonemonthreports?month=${obj?.month}`
    );
    return response.data;
  }
);

const MonthlyReportSlice = createSlice({
  name: "monthlyreport",
  initialState,
  reducers: {
    MonthlyReportAdded: {
      reducer(state, action) {
        state.monthlyreport.push(action.payload);
      },
    },
    MonthlyReportResetData: (state) => {
      state.monthlyreport = [];
      state.Report_Populate = [];
      state.ReportByMonths = [];
      state.isSuccess = false;
      state.message = null;
      state.status = "idle"; //'idle' | 'loading' | 'succeeded' | 'failed'
      state.error = null;
      state.IsLogin = true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMonthlyReport.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMonthlyReport.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.monthlyreport = action.payload.Data;
      })
      .addCase(fetchMonthlyReport.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })

      .addCase(fetchMonthlyReportPopulate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Report_Populate = action.payload.Employees;
      })
      .addCase(fetchMonthlyReportPopulate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMonthlyReportPopulate.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(FetchReportsByMonths.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.ReportByMonths = action.payload.Data;
      })
      .addCase(FetchReportsByMonths.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchReportsByMonths.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(FetchOneMonthReport.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.oneMonthReports = action.payload.Data;
        state.oneMonthGazzetHolidays = action.payload.gazzettedHolidays;
      })
      .addCase(FetchOneMonthReport.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchOneMonthReport.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(FetchReportsShiftRuleByMonths.fulfilled, (state, action) => {
        state.status2 = "succeeded";
        state.message2 = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.ReportShiftRuleByMonths = action.payload.Data;
      })
      .addCase(FetchReportsShiftRuleByMonths.pending, (state) => {
        state.status2 = "loading";
      })
      .addCase(FetchReportsShiftRuleByMonths.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message2 = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status2 = "failed";
        }
      });
  },
});

export const getmonthlyreportDetails = (state) => state.monthlyreport;

export const { MonthlyReportAdded, MonthlyReportResetData } =
  MonthlyReportSlice.actions;

export default MonthlyReportSlice.reducer;
