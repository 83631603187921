import PropTypes from "prop-types";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  gridClasses,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useContext, useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

import {
  fetchActiveProject,
  getProjectDetails,
} from "../../Redux/Slice/Project/ProjectSlice";

import { useDispatch, useSelector } from "react-redux";
import ProjectTableModal from "./ProjectTableModal";
import { UpdateProjectStatApi } from "../../Api/Project/UpdateProjectStatusApi";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { makeStyles } from "@mui/styles";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { useNavigate } from "react-router-dom";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../Button/AddNewButton";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import Loader from "../Loader/Loader";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});
function ProjectTable({ setShowForm, setisEdited, setprojData }) {
  const { tabelStyle } = useContext(TabelStyleContext);
  const navigate = useNavigate();
  const { header } = useStyles();
  const dispatch = useDispatch();
  const [rows, setrows] = useState([]);
  const [filterRows, setfilterRows] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [SelectedRow, setSelectedRow] = useState({});
  const [showModal, setshowModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [AllActiveProjects, setAllActiveProjects] = useState([]);
  const [activeProjRow, setActiveProjRow] = useState([]);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [loading, setloading] = useState(false);

  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [editPermit, seteditpermit] = useState(false);
  const [addPermit, setaddpermit] = useState(false);
  // console.log(crop);

  console.log(roles);
  useEffect(() => {
    let string = "Projects";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Edit") {
        seteditpermit(true);
      } else if (val?.module?.split("_")[1] === "Add") {
        setaddpermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      setaddpermit(true);
      seteditpermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { Activeproject, status, IsLogin, message } =
    useSelector(getProjectDetails);

  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }

    setAllActiveProjects(Activeproject);
  }, [Activeproject, IsLogin]);
  useEffect(() => {
    dispatch(fetchActiveProject());
  }, [dispatch]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "Projects" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }

  const columns = [
    { field: "sr", headerName: "Sr #", width: 100, headerClassName: header },
    {
      field: "project_name",
      headerName: "Project Name",
      width: 300,
      headerClassName: header,
    },
    {
      field: "Update",
      headerName: "Actions",
      width: 180,
      headerClassName: header,
      renderCell: (celVal) => {
        return (
          <>
            <Box sx={{ display: "flex", gap: "10px" }}>
              {editPermit ? (
                <Tooltip title="DeActive Project">
                  <AutorenewIcon
                    sx={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClickOpen();
                      setActiveProjRow(celVal.row);
                      // setshowModal(false);
                    }}
                  />
                </Tooltip>
              ) : null}
              {editPermit ? (
                <Tooltip title="Update Project">
                  <AppRegistrationIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setprojData(celVal.row);
                      setisEdited(true);
                      setShowForm(true);
                    }}
                  />
                </Tooltip>
              ) : null}
            </Box>
          </>
        );
      },
    },
  ];
  const UpdateProjStatus = async (project) => {
    setloading(true);
    const response = await UpdateProjectStatApi({
      project_id: project.id,
      project_status: "Inactive",
    });
    setsnackBarData(response.snackBarData);
    setloading(false);

    if (response.data.IsSuccess) {
      setActiveProjRow(null);

      dispatch(fetchActiveProject());

      handleClose();
    }
  };
  useEffect(() => {
    let arr = [];
    if (AllActiveProjects.length > 0) {
      AllActiveProjects?.forEach((item, idx) => {
        return arr.push({
          ...item,
          sr: idx + 1,
          id: item?.project_id,
          // date: item.Date ? moment(item.Date).format("DD MMM, YYYY") : "",
        });
      });
    }

    setrows(arr);
    setfilterRows(arr);
  }, [AllActiveProjects]);

  const onTextChange = () => {
    if (document.getElementById("ProjectNameFilter").value === "") {
      setfilterRows(rows);
    } else {
      let suggestions = [];

      var regex1 = new RegExp(
        document.getElementById("ProjectNameFilter").value,
        "i"
      );

      suggestions = rows?.filter(
        (val) =>
          document.getElementById("ProjectNameFilter").value === "" ||
          regex1.test(val.project_name)
      );

      setfilterRows(suggestions);
    }
  };

  return (
    <Box spacing={2} sx={{ padding: "10px 0px" }}>
      <Box
        sx={{
          padding: "10px",
          display: "flex",
          justifyContent: "space-between",
          gap: "20px",
          width: "100%",
        }}
      >
        {addPermit ? (
          <>
            {/* <Button
              sx={{ padding: "5px 10px" }}
              onClick={() => {
                setShowForm(true);
              }}
              variant="contained"
              className="tabletopbutton1 text-right"
            >
              New
            </Button> */}

            <CustomButton
              Color={"primary"}
              Icon={<AddIcon />}
              onClick={() => {
                setShowForm(true);
              }}
              Label={"Add"}
            />
          </>
        ) : null}
        <Box>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: (theme) => theme.palette.grey.G2 }}
                  />
                </InputAdornment>
              ),
            }}
            variant="standard"
            type="text"
            autoComplete="off"
            placeholder="Name"
            className="input_border"
            id="ProjectNameFilter"
            onChange={onTextChange}
          />
        </Box>
      </Box>

      <Box overflow>
        <Box sx={{ height: tabelStyle.height, padding: "10px" }}>
          {Activeproject?.length<=0 && status !== "succeeded" ? (
<Loader/>
          ) : null}
            <DataGrid
              sx={{ cursor: "pointer" }}
              rows={filterRows}
              columns={columns}
              onRowClick={(e) => {
                setSelectedRow(e.row);
                setshowModal(true);
              }}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
              pagination
              components={{
                Toolbar: CustomToolbar,
              }}
              localeText={{
                toolbarDensity: "Size",
                toolbarDensityLabel: "Size",
                toolbarDensityCompact: "Small",
                toolbarDensityStandard: "Medium",
                toolbarDensityComfortable: "Large",
              }}
            />
        
        </Box>
      </Box>

      <ProjectTableModal
        data={SelectedRow}
        setshowModal={setshowModal}
        showModal={showModal}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">
          {/* InActive {activeProjRow?.project_name} */}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to DeActive{" "}
            <b> {activeProjRow?.project_name}</b>
            {/* Are you sure to InActive {activeProjRow?.project_name} */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Button
                onClick={() => {
                  setActiveProjRow(null);
                  handleClose();
                }}
                sx={{
                  background: "red",
                  color: "white !important",
                  fontSize: "13px",
                  textTransform: "none",
                }}
              >
                No
              </Button>
              {loading ? (
                <CircularProgress />
              ) : (
                <Button
                  sx={{
                    background: "#3480FA",
                    color: "white !important",
                    fontSize: "13px",
                    // margin: "0px auto",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    UpdateProjStatus(activeProjRow);
                  }}
                >
                  Yes
                </Button>
              )}
            </Box>
          </>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ProjectTable;
ProjectTable.propTypes = {
  setShowForm: PropTypes.any,
  setisEdited: PropTypes.any,
  setprojData: PropTypes.any,
};
