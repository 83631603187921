import { Box, CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import PropTypes from "prop-types";
const useStyle = makeStyles((theme) => {
  return {
    container: {
      width: "100%",
      padding: "20px",
      display: "flex",
      alignItems: "center",
      gap: "20px",
      borderRadius: "20px",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
      color: theme.palette.white.main,
      // cursor:'pointer',
      transition: "0.5s",
      "&:hover": {
        transform: "scale(1.05)",
      },
    },
    iconContainer: {
      height: "80px",
      width: "80px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      background: theme.palette.white.main,
    },
    contentContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    PriceContainer: {
      width: "100%",
      display: "flex",
      alignItems: "end",
      gap: "10px",
    },
  };
});

const Cards = ({ title, icon, count, background, cursor,status }) => {
  const { container, iconContainer, contentContainer, PriceContainer } =
    useStyle();
    console.log(status);
  return (
    <Box
      className={container}
      sx={{ background: background, cursor: cursor ? "pointer" : "" }}
    >
      <Box className={iconContainer}>{icon}</Box>
      <Box className={contentContainer}>
        <Box>
          <Typography variant="h6">{title}</Typography>
        </Box>
        <Box className={PriceContainer}>
          {
            status!=="succeeded"?<CircularProgress sx={{color:"white !important"}}/>:
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {count}
          </Typography>
          }
     
          
        </Box>
      </Box>
    </Box>
  );
};

export default Cards;
Cards.propTypes = {
  title: PropTypes.any,
  background: PropTypes.any,
  icon: PropTypes.any,
  count: PropTypes.any,
  cursor: PropTypes.any,
  status: PropTypes.any,
};
