import React, { useContext, useState } from "react";
import {
  Box,
  // Button,
  // TextField,
  // Typography,
  // CircularProgress,
  FormControl,
} from "@mui/material";
// import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
// import * as Yup from "yup";
// import { useFormik } from "formik";
// import theme from "../../../theme";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchRulesByName,
  getAllRoles,
} from "../../../Redux/Slice/Roles/RolesSlice";
import { logout } from "../../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import Loader from "../../Loader/Loader";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  // border: `1px solid ${theme.palette.divider}`,
  border: "none !important",
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", padding: "0px" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    border: "none",
    padding: "0px",
    height: "10px",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    padding: "0px",
    height: "10px",
    border: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  marginLeft: theme.spacing(7),
  padding: "0px",
  border: "none",
}));
const useStyles = makeStyles(() => {
  return {
    accordBox: {
      display: "flex",
      flexDirection: "column",
    },
  };
});
function AllRoles({ setAllRole, rolesRowData, IsEdit }) {
  const navigate=useNavigate()
  const { setsnackBarData } = useContext(SnackBarContext);
  const { accordBox } = useStyles();
  const [allRoles, setallRoles] = useState([]);
  const { RolesByName,IsLogin,message ,status} = useSelector(getAllRoles);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchRulesByName());
  }, [dispatch]);
  useEffect(() => {
    if (RolesByName) {
      setallRoles(RolesByName);
    }
  }, [RolesByName]);

  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  useEffect(() => {
    if (rolesRowData && IsEdit) {
      let newData = JSON.parse(JSON.stringify(RolesByName));
    //   let newDataaaa = []
      let allRolesssss = newData?.map((all) => {
        // all.status=true;
        let mat = false;
        let subModules = all?.modules?.map((mod) => {
          let matched = rolesRowData?.ruleid?.some((mod2) => {
            // console.log(mod?.id === mod2?.rule_id   )
            if (mod?.id === mod2?.rule_id) {
              return true;
            }
          });
          if (matched) {
            mod.status = true;
            mat = true;
          }
          return mod;
        });
        all.modules = subModules;
        all.status = mat;
        // newDataaaa.push(all)
        return all;
      });
      console.log(allRolesssss);
      setallRoles(allRolesssss);
      setAllRole(allRolesssss);

    }
  }, [rolesRowData, RolesByName]);
  const recursiveFun = (data, i) => {
    if (!data.modules) {
      return <></>;
    } else {
      return data?.modules.map((role, idx) => {
        return (
          <>
            <FormControlLabel
              key={i}
              onChange={(e) => onCheckedChange(role, e, i, idx)}
              checked={role.status}
              control={<Checkbox />}
              label={role.name}
            />
            {recursiveFun(role, idx)}
          </>
        );
      });
    }
  };

  const onCheckedChange = (role, e, index, idx) => {
    console.log(role);
    let newData = JSON.parse(JSON.stringify(allRoles));
    console.log(idx);
    if (idx !== undefined) {
      newData[index].modules[idx].status = e.target.checked;
    } else {
      newData[index].status = e.target.checked;
      newData[index].modules.forEach((module) => {
        module.status = e.target.checked;
      });
    }

    setallRoles(newData);
    setAllRole(newData);
  };
  return (
    <>
    {
  status !== "succeeded"? <Loader/>:null
}
      <Box className={accordBox}>
        <>
          {allRoles?.map((role, i) => {
            return (
              <>
                <FormControl key={i}>
                  <Accordion>
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                    >
                      <FormControlLabel
                        checked={role.status}
                        onChange={(e) => onCheckedChange(role, e, i)}
                        control={<Checkbox />}
                        label={role.name}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormGroup>{recursiveFun(role, i)}</FormGroup>
                    </AccordionDetails>
                  </Accordion>
                </FormControl>
              </>
            );
          })}
        </>
      </Box>
    </>
  );
}

export default AllRoles;
AllRoles.propTypes = {
  setAllRole: PropTypes.any,
  rolesRowData: PropTypes.any,
  IsEdit: PropTypes.any,
};
