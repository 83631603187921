import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";
const initialState = {
    CompanyProfile: {},
    isSuccess: false,
    message: null,
    status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    IsLogin: true,
  
  };
  
  export const fetchAllCompanyProfile = createAsyncThunk(
    "getcompanyprofiles",
    async () => {
      const response = await axiosInstance.get(`getcompanyprofiles`);
     console.log(response)
      return response.data;
    }
  );
  const CompanyProfileSlice = createSlice({
    name: "CompanyProfile",
    initialState,
    reducers: {
        CompanyProfileAdded: {
        reducer(state, action) {
          state.CompanyProfile = action.payload;
        },
      },
    },
    extraReducers(builder) {
      builder
     
     
        .addCase(fetchAllCompanyProfile.pending, (state) => {
          state.status = "loading";
        })
        .addCase(fetchAllCompanyProfile.fulfilled, (state, action) => {
          state.status = "succeeded";
          state.message = action.payload.Message;
          state.isSuccess = action.payload.IsSuccess;
          state.CompanyProfile = action.payload?.companydata;
        })
        .addCase(fetchAllCompanyProfile.rejected, (state, action) => {
          if (action?.error?.message.includes(401)) {
            console.log(action.error.message);
            state.message = action.error.message;
            state.IsLogin = false;
          } else {
            state.error = action.error.message;
            state.status = "failed";
          }
        })
    },
  });
  export const getCompanyProfile = (state) => state.CompanyProfile;

export const { CompanyProfileAdded } = CompanyProfileSlice.actions;

export default CompanyProfileSlice.reducer;
  

  