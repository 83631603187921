import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
// import { Doughnut } from "react-chartjs-2";
import PropTypes from "prop-types";
// import AbsentEmployeeModal from "../../Dashboard/AbsentEmployeeModal/AbsentEmployeeModal";
import ReactApexChart from "react-apexcharts";
// import PresentEmployeeModal from "../../Dashboard/PresentEmployeeModal/PresentEmployeeModal";
// import { getDashboardActivities } from "../../../Redux/Slice/Dashboard/DashboardSlice";
// import { useSelector } from "react-redux";
ChartJS.register(ArcElement, Tooltip, Legend);

export const DonatGraph = ({ dashboarddata }) => {
  console.log(dashboarddata);
  // const [presentEmp, setpresentEmp] = useState(false);
  // const [open, setopen] = useState(false);
  // const { presentEmployee,IsLogin,message } = useSelector(getDashboardActivities);
  // const data = {
  //   labels: ["Present", "Absent"],
  //   datasets: [
  //     {
  //       label: "Employees",
  //       data: [dashboarddata?.Present_today, dashboarddata?.Absent_today],
  //       backgroundColor: ["rgba(75, 192, 192, 0.7)", "rgba(255, 99, 132, 0.7)"],
  //       borderColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)"],
  //       borderWidth: 1,
  //     },
  //   ],
  // };
  // useEffect(() => {
  //   if (!IsLogin) {
  //     setsnackBarData({
  //       type: "error",
  //       message: message,
  //       openToast: true,
  //     });
  //     dispatch(logout());
  //     return navigate("/");
  //   }
  // }, [IsLogin]);
  // console.log(DonatGraph);
  const [series, setseries] = useState([0, 0]);

  useEffect(() => {
    if (dashboarddata) {
      setseries([dashboarddata?.Present_today, dashboarddata?.Absent_today]);
    }
  }, [dashboarddata]);
  const [state] = useState({
    options: {
      chart: {
        height: 390,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 280,
          hollow: {
            margin: 5,
            size: "30%",
            background: "#89c7d9",
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
      colors: ["#1ab7ea", "#0084ff"],
      labels: ["Present", "Absents"],
      legend: {
        show: true,
        floating: true,
        fontSize: "16px",
        position: "left",
        offsetX: 0,
        offsetY: 25,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          size: 0,
        },
        formatter: function (seriesName, opts) {
          return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
        },
        itemMargin: {
          vertical: 3,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false,
            },
          },
        },
      ],
    },
  });
  // const handleDataClick = (event, segments) => {
  //   if (segments.length) {
  //     const segmentIndex = segments[0].index;

  //     // if (data.labels[segmentIndex] === "Present") {
  //     //   setpresentEmp(true);
  //     // } else
  //     if (data.labels[segmentIndex] === "Absent") {
  //       setopen(true);
  //     }
  //   }
  // };
  return (
    <>
      {/* <Doughnut
        data={data}
        redraw
        options={{
          responsive: true,
          onClick: handleDataClick,
        }}
      />
      <AbsentEmployeeModal open={open} setopen={setopen} />
      {/* <PresentEmployeeModal
        presentEmp={presentEmp}
        setpresentEmp={setpresentEmp}
        presentEmployee={presentEmployee}
      /> */}
      <div id="chart">
        <ReactApexChart
          options={state.options}
          series={series}
          type="radialBar"
          height={390}
        />
      </div>
    </>
  );
};

DonatGraph.propTypes = {
  dashboarddata: PropTypes.any,
};
