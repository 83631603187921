import { createContext, useState } from "react";
import PropTypes from "prop-types";

export const TabelStyleContext = createContext();

const TabelStyleContextProvider = (props) => {
    const [tabelStyle, setTabelStyle] = useState({
        height: "calc(100vh - 158px)",
    });


    return (
        <TabelStyleContext.Provider value={{ tabelStyle,setTabelStyle }}>
           {props.children}
        </TabelStyleContext.Provider>
    );
};

export default TabelStyleContextProvider;

TabelStyleContextProvider.propTypes = {
    children: PropTypes.any,
};

