import PropTypes from "prop-types";
import {
  Box,
  // Button,
  // CircularProgress,
  gridClasses,
  InputAdornment,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";

import {
  FetchAllowanceEmployee,
  getallallowances,
} from "../../Redux/Slice/AllAllowances/AllAllowance";
import { makeStyles } from "@mui/styles";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../Button/AddNewButton";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import Loader from "../Loader/Loader";
import Cookies from "universal-cookie";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});

function AllowanceforEmploye({ setShowForm }) {
  const cookies = new Cookies();
  const { tabelStyle } = useContext(TabelStyleContext);
  const { header } = useStyles();
  const dispatch = useDispatch();
  const [EmployeesAllowance, setEmployeesAllowance] = useState([]);
  const [rows, setrows] = useState([]);
  const [filterRows, setfilterRows] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const { setsnackBarData } = useContext(SnackBarContext);
  const navigate = useNavigate();
  const emp_id = cookies.get("Emp_id");

  const { allowanceofEmployee, status, IsLogin, message } =
    useSelector(getallallowances);

  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [addPermit, setaddpermit] = useState(false);

  // console.log(crop);

  console.log(roles);
  useEffect(() => {
    let string = "EmployeeAllowances";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Add") {
        setaddpermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      setaddpermit(false);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);

  console.log(addPermit);

  useEffect(() => {
    dispatch(FetchAllowanceEmployee(emp_id));
  }, [dispatch]);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
    setEmployeesAllowance(allowanceofEmployee);
  }, [allowanceofEmployee, IsLogin]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "Employee Allowances" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "id", headerName: "Sr #", headerClassName: header },
    {
      field: "allowancetype",
      headerName: "Allowance Type",
      width: 200,
      headerClassName: header,
    },

    {
      field: "amount",
      headerName: "Allowance Amount",
      width: 200,
      headerClassName: header,
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
      headerClassName: header,
    },

    // {
    //   field: "salary",
    //   headerName: "Basic Salary",
    //   width: 200,
    //   headerClassName: header,
    // },
    // {
    //   field: "netsalary",
    //   headerName: "Net Salary",
    //   width: 200,
    //   headerClassName: header,
    // },
  ];

  useEffect(() => {
    let arr = [];
    if (EmployeesAllowance) {
      EmployeesAllowance?.forEach((item, idx) => {
        return arr.push({
          id: idx + 1,
          allowancetype: item?.type,
          description: item?.description,
          amount: item?.amount?.toLocaleString(),
          salary: item?.basic_salary
            ? Number(item?.basic_salary).toLocaleString()
            : null,
          netsalary:
            +item?.basic_salary + +item?.amount
              ? Number(+item?.basic_salary + +item?.amount).toLocaleString()
              : null,
        });
      });
    }

    setrows(arr);
    setfilterRows(arr);
  }, [EmployeesAllowance]);

  const onTextChange = () => {
    if (document.getElementById("allowanceTypeFilter").value === "") {
      setfilterRows(rows);
    } else {
      let suggestions = [];

      var regex1 = new RegExp(
        document.getElementById("allowanceTypeFilter").value,
        "i"
      );

      suggestions = rows?.filter(
        (val) =>
          document.getElementById("allowanceTypeFilter").value === "" ||
          regex1.test(val.allowancetype)
      );

      setfilterRows(suggestions);
    }
  };
  useEffect(() => {
    if (status === "failed") {
      setsnackBarData({
        type: "error",
        message: "Network Error",
        openToast: true,
      });
    }
  }, [status]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          padding: "20px 10px 10px 10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {addPermit ? (
            <>
              {/* <Button
                sx={{ padding: "5px 10px" }}
                onClick={() => {
                  setShowForm(true);
                }}
                variant="contained"
                className="tabletopbutton1 text-right"
              >
                New
              </Button> */}
              <CustomButton
                Color={"primary"}
                Icon={<AddIcon />}
                onClick={() => {
                  setShowForm(true);
                }}
                Label={"Add"}
              />
            </>
          ) : null}
        </Box>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: (theme) => theme.palette.grey.G2 }} />
              </InputAdornment>
            ),
          }}
          sx={{ width: "100%", maxWidth: "180px" }}
          variant="standard"
          type="text"
          autoComplete="off"
          placeholder="Allowance Type"
          className="input_border"
          id="allowanceTypeFilter"
          onChange={onTextChange}
        />
      </Box>

      {allowanceofEmployee?.length <= 0 && status === "loading" ? (
        <>
          <Loader />
        </>
      ) : null}
      <Box sx={{ height: tabelStyle.height, padding: "10px" }}>
        <DataGrid
          rows={filterRows}
          columns={columns}
          onRowClick={() => {}}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          pagination
          components={{
            Toolbar: CustomToolbar,
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />
      </Box>
    </>
  );
}

export default AllowanceforEmploye;
AllowanceforEmploye.propTypes = {
  setShowForm: PropTypes.any,
  setempData: PropTypes.any,
  setisEdited: PropTypes.any,
};
