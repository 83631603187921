import { createContext, useState } from "react";
import PropTypes from "prop-types";

export const TooltipContext = createContext();

export const TooltipContextProvider = ({ children }) => {
  const [tooltipData, settooltipData] = useState("");
  return (
    <TooltipContext.Provider value={{ tooltipData, settooltipData }}>
      {children}
    </TooltipContext.Provider>
  );
};
TooltipContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
