import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";

import { Navigate } from "react-router-dom";
import { Box } from "@mui/material";

import BreakAdjustmentForm from "../../Components/BreakAdjustment/BreakAdjustmentForm";
import BreakAdjustmentTable from "../../Components/BreakAdjustment/EmpBreakAdjustmentTable";

const BreakAdjustment = () => {
  const [ShowForm, setShowForm] = useState(false);
  const [canView] = useState(true);
  const [adjData, setAdjData] = useState();
  const [isEdited, setisEdited] = useState(false);

  return (
    <div>
      {canView === true ? (
        ShowForm ? (
          <BreakAdjustmentForm
            setShowForm={setShowForm}
            setAdjData={setAdjData}
            isEdited={isEdited}
            setisEdited={setisEdited}
            adjData={adjData}
            ShowForm={ShowForm}
          />
        ) : (
          <BreakAdjustmentTable
            setShowForm={setShowForm}
            setAdjData={setAdjData}
            isEdited={isEdited}
            setisEdited={setisEdited}
            adjData={adjData}
            ShowForm={ShowForm}
          />
        )
      ) : canView === false ? (
        <Navigate to={"/dashboard"} />
      ) : (
        <Box>Loading ...</Box>
      )}
    </div>
  );
};

export default BreakAdjustment;
BreakAdjustment.propTypes = {
  setShowForm: PropTypes.any,
};
