import { Modal, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import PropTypes from "prop-types";
import emailsend from "../../Assets/send_email_icon.png";
import theme from "../../theme";
import { BeatLoader } from "react-spinners";

const useStyles = makeStyles(() => {
  return {
    container: {
      background: theme.palette.white.main,
      width: "100%",
      padding: "20px",
      maxWidth: "800px",
      border: "none",
      borderRadius: "20px",
    },
    contentContainer: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      gap: "30px",
      border: "none",
      alignItems: "center",
      textAlign: "center",
      padding: "40px 20px",
    },
    modal: {
      padding: "20px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    imageContainer: {
      width: "100%",
      maxWidth: "200px",
      [theme.breakpoints.down("md")]: {
        maxWidth: "100px",
      },
    },
    TypoContainer: {
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      gap: "5px",
      [theme.breakpoints.down("md")]: {
        textAlign: "left",
      },
    },
  };
});

const EmailSentModal = ({ open, setOpen, Email }) => {
  const { container, contentContainer, modal, imageContainer, TypoContainer } =
    useStyles();
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Modal open={open} onClose={handleClose} className={modal}>
      <Box className={container}>
        <Box className={contentContainer}>
          <Box className={imageContainer}>
            <img src={emailsend} alt="email icon" style={{ width: "100%" }} />
          </Box>
          <Box>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              Please Check Your Email
            </Typography>
          </Box>
          <Box className={TypoContainer}>
            <Typography>
              You&apos;ve entered{" "}
              <Typography component={"span"} variant="h6">
                {Email?.email}
              </Typography>{" "}
              as a email address for your account.
            </Typography>
            <Typography>
              Kindly check your email account a reset password link has been
              sent to your email address
            </Typography>
          </Box>
          <Box>
            <BeatLoader color="#36d7b7" />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default EmailSentModal;
EmailSentModal.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
  Email: PropTypes.any,
};
