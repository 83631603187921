import React from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useState } from 'react';
import OvertimeMonthyDetailed from '../../Components/OvertimeReport/MonthyDetailed/OvertimeMonthyDetailed';
import OvertimeMonthlySummay from '../../Components/OvertimeReport/MonthlySummary/OvertimeMonthlySummay';
import OvertimeYearlySummary from '../../Components/OvertimeReport/YearlySummary/OvertimeYearlySummary';
import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';

function OvertimeReportMain() {
  const [value, setValue] = useState(0);
  const [canView, setcanView] = useState(null);
  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  useEffect(() => {
    let string = "OvertimeReport";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    console.log(value);
    let match = value?.some((val) => {
      if (val?.module?.split("_")[1] === "View") {
        return true;
      }
    });
    setcanView(match);
    console.log(match);
    if (!roles || roles.length < 1) {
      setcanView(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      {canView === true ? (

        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
            <Tabs variant="fullWidth" fullWidth value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Monthly Detailed" {...a11yProps(0)} />
              <Tab label="Monthly Summary" {...a11yProps(1)} />
              <Tab label="Yearly Summary" {...a11yProps(2)} />
            </Tabs>

          </Box>
          {
            value === 0 ? <OvertimeMonthyDetailed /> : value === 1 ? <OvertimeMonthlySummay /> : value === 2 ? <OvertimeYearlySummary /> : null
          }
        </Box>
      ) : canView === false ? (
        <Navigate to={"/dashboard"} />
      ) : (
        <Box>Loading ...</Box>
      )}
    </>
  )
}

export default OvertimeReportMain