import {
  Divider,
  Link,
  Modal,
  // Table,
  // TableBody,
  // TableCell,
  // TableContainer,
  // TableHead,
  // TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { useState } from "react";
import PropTypes from "prop-types";
import { useEffect } from "react";
// import moment from "moment/moment";

function PersonalInfo({ AllSingEmpDetail, data }) {
  const [allSingEmpDetail, setallSingEmpDetail] = useState([]);
  const [imgAd, setImgAd] = useState("front");
  const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  console.log(data)
  useEffect(() => {
    setallSingEmpDetail(AllSingEmpDetail);
  }, [AllSingEmpDetail]);
  return (
    <>
      {imgAd && open ? (
        <Modal
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "500px",
              padding: "10px",
              backgroundColor: "white !important",
            }}
          >
            {
              <Typography sx={{ fontWeight: "bold" }}>
                {imgAd === "front" ? "CNIC Front Image" : "CNIC Back Image"}
              </Typography>
            }
            <Typography
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "60%",
                maxWidth: "300px",
              }}
            >
              <Link
                href={
                  imgAd === "front"
                    ? allSingEmpDetail?.Employee?.cnic_photofront
                    : allSingEmpDetail?.Employee?.cnic_photoback
                }
                target={"_blank"}
              >
                {imgAd === "front"
                  ? allSingEmpDetail?.Employee?.cnic_photofront
                  : allSingEmpDetail?.Employee?.cnic_photoback}
              </Link>
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                paddingTop: "20px",
              }}
            >
              <Typography>
                {imgAd === "front" ? "CNIC Back" : "CNIC Front"}
              </Typography>
              <Tooltip
                title={`${imgAd === "front" ? "CNIC Back" : "CNIC Front"}`}
              >
                <AutorenewIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setImgAd(imgAd === "front" ? "back" : "front");
                  }}
                  sx={{ width: "100px", cursor: "pointer" }}
                />
              </Tooltip>
            </Box>
          </Box>
        </Modal>
      ) : null}
      <Box sx={{ padding: "10px" }}>
        <Typography
          sx={{ textAlign: "left", fontWeight: "bold", fontSize: "18px" }}
        >
          Personal Details
        </Typography>

        <Divider />
        <Box
          sx={{
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            padding: "20px",
            paddingTop: "10px",
            paddingBottom: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",

              gap: "80px ",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "30px",
                justifyContent: "",
                width: "100%",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>Name:</Typography>
              <Typography>{data?.name}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "30px",
                justifyContent: "",
                width: "100%",
              }}
            >
              <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
                Email:
              </Typography>
              <Typography sx={{ textAlign: "left" }}>
                {data?.email}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              gap: "80px ",
              textAlign: "right",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "40px",
                justifyContent: "",
                width: "100%",
              }}
            >
              <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
                Phone No:
              </Typography>
              <Typography>{data.phone}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "40px",
                justifyContent: "",
                width: "100%",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>Degree:</Typography>
              <Typography>
                {data?.degree}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              gap: "80px ",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "40px",
                justifyContent: "",
                width: "100%",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>University:</Typography>
              <Typography>
                {data.university}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "40px",
                justifyContent: "",
                width: "100%",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>City:</Typography>
              <Typography>
                {data.city}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              gap: "80px ",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "40px",
                justifyContent: "",
                width: "100%",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>Hiring Session:</Typography>
              <Typography>
                {data.hiringSession_title}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "40px",
                justifyContent: "",
                width: "100%",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>Curriculum vitae:</Typography>
              <Typography>
                <a href={data.cvLink}>{data.cvLink}</a>
              </Typography>
            </Box>


          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              gap: "80px ",
            }}
          >
           <Box
              sx={{
                display: "flex",
                gap: "40px",
                justifyContent: "",
                width: "100%",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>Comment:</Typography>
              <Typography>
               {data.comment}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "40px",
                justifyContent: "",
                width: "100%",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>Status:</Typography>
              <Typography>
               {data.status}
              </Typography>
            </Box>


          </Box>
          
        </Box>
      </Box>

      {/* <Box sx={{ paddingX: "20px", width: "100%" }}>
        <Typography
          sx={{
            textAlign: "left",
            fontWeight: "bold",
            fontSize: "17px",

            paddingTop: "10px",
          }}
        >
          Education Details
        </Typography>
        <TableContainer sx={{ overflowY: "scroll", paddingTop: "10px" }}>
          <Table
            sx={{
              minWidth: { md: "auto", xs: "auto" },
              overflow: "scroll",
            }}
            aria-label="simple table"
            size="small"
          >
            <TableHead>
              <TableRow size="small">
                <TableCell size="small" sx={{ fontWeight: "bold" }}>
                  Id
                </TableCell>
                <TableCell
                  size="small"
                  align="center"
                  sx={{ fontWeight: "bold" }}
                >
                  Degree Name
                </TableCell>
                <TableCell
                  size="small"
                  align="center"
                  sx={{ fontWeight: "bold" }}
                >
                  Institute Name
                </TableCell>
                <TableCell
                  size="small"
                  align="center"
                  sx={{ fontWeight: "bold" }}
                >
                  Starting Year
                </TableCell>
                <TableCell
                  size="small"
                  align="center"
                  sx={{ fontWeight: "bold" }}
                >
                  Ending Year
                </TableCell>
                <TableCell
                  size="small"
                  align="center"
                  sx={{ fontWeight: "bold" }}
                >
                  Percentage Obtain
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allSingEmpDetail?.Education?.map((emp, ind) => (
                <TableRow
                  key={emp?.degree_name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell size="small" component="th" scope="row">
                    {ind + 1}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {emp?.degree_name}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {emp?.institute_name}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {emp?.starting_year
                      ? moment(emp?.starting_year).format("DD-MMM-YYYY")
                      : ""}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {emp?.ending_year
                      ? moment(emp?.ending_year).format("DD-MMM-YYYY")
                      : ""}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {emp?.percentage_obtain ? emp?.percentage_obtain : ""}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box> */}

      {/* <Box sx={{ paddingX: "20px", width: "100%", paddingTop: "20px" }}>
        <Typography
          sx={{
            textAlign: "left",
            fontWeight: "bold",
            fontSize: "17px",

            paddingTop: "30px",
          }}
        >
          Past Experience
        </Typography>
        <TableContainer sx={{ overflowY: "scroll", paddingTop: "10px" }}>
          <Table
            sx={{
              minWidth: { md: "auto", xs: "auto" },
              overflow: "scroll",
            }}
            aria-label="simple table"
            size="small"
          >
            <TableHead>
              <TableRow size="small">
                <TableCell size="small" sx={{ fontWeight: "bold" }}>
                  Id
                </TableCell>
                <TableCell
                  size="small"
                  align="center"
                  sx={{ fontWeight: "bold" }}
                >
                  Job Title
                </TableCell>
                <TableCell
                  size="small"
                  align="center"
                  sx={{ fontWeight: "bold" }}
                >
                  Company Name
                </TableCell>
                <TableCell
                  size="small"
                  align="center"
                  sx={{ fontWeight: "bold" }}
                >
                  Starting Date
                </TableCell>
                <TableCell
                  size="small"
                  align="center"
                  sx={{ fontWeight: "bold" }}
                >
                  Leaving Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allSingEmpDetail?.Past_experience?.map((past, ind) => (
                <TableRow
                  key={past?.degree_name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell size="small" component="th" scope="row">
                    {ind + 1}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {past?.job_title}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {past?.company_name}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {past?.starting_date
                      ? moment(past?.starting_date).format("DD-MMM-YYYY")
                      : ""}
                  </TableCell>
                  <TableCell size="small" align="center">
                    {past?.leaving_date
                      ? moment(past?.leaving_date).format("DD-MMM-YYYY")
                      : ""}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box> */}
    </>
  );
}

export default PersonalInfo;
PersonalInfo.propTypes = {
  AllSingEmpDetail: PropTypes.any,
  data: PropTypes.object
};
