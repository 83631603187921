import { Box, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext, useEffect, useState } from "react";

import { WeeklyLineChart } from "../LineChart/WeeklyLineChart";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchWeeklyChanges,
  getChangeRequests,
} from "../../../Redux/Slice/Dashboard/PapulateChangeRequestSlice";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { logout } from "../../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";

const useStyle = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      width: "100%",
      color: theme.palette.grey[700],
    },
  };
});

const WeeklyChangeRequestGraph = () => {
  const navigate = useNavigate();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { container } = useStyle();
  const dispatch = useDispatch();
  const [weeklyData, setweeklyData] = useState([]);
  const { weeklyChanges, IsLogin, message, status } = useSelector(getChangeRequests);
  useEffect(() => {
    dispatch(FetchWeeklyChanges());
  }, []);
  useEffect(() => {
    setweeklyData(weeklyChanges)
  }, [weeklyChanges])
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  return (
    <Box className={container}>
      {status === "loading" ? (
        <Box
          sx={{
            height: "450px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {weeklyData ? (
            <Box sx={{ width: '100%', minHeight: "200px" }}>
              <WeeklyLineChart weeklyData={weeklyData} />
            </Box>
          ) : (
            <Box sx={{ width: '100%', minHeight: "200px" }}>
              <img src="./NoData.png" alt="No Data Found " style={{ height: '84%', width: '95%' }} />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default WeeklyChangeRequestGraph;
