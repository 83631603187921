import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  PageNum: 0,
  PageSize: 10,
  TotalCount: 0,
  applicants: [],
  stages:[],
  allsession:[],
  singlesession:[],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,
};

export const fetchApplicants = createAsyncThunk(
  "getjobapplicantforms",
  async () => {
    const response = await axiosInstance.get(`getjobapplicantforms`);
    console.log(response);
    return response.data;
  }
);
export const showData = createAsyncThunk(
  "showData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/getAllHiringSessions");
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getallData = createAsyncThunk(
  "getallData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/getAllApplicants");
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getallSessionData = createAsyncThunk(
  "getallSessionData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/getAllApplicantSessions");
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getSingleSessionData = createAsyncThunk(
  "getallSessionData",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/getApplicantSessionById/${id}`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const deleData = createAsyncThunk(
  "deleData",
  async (id, { rejectWithValue }) => {
    try {
      const apiData = await fetch(`/deleteHiringSection/${id}`, {
        method: "Delete",
      });
      const res = await apiData.json();
      return res;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const updateApplicant = createAsyncThunk(
  "updateApplicant",
  async (updatedData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/updateHiringSection`,
        updatedData
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Hirng stages API GET STAGES
export const getstagesData = createAsyncThunk(
  "getstagesData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/getAllHiringStages");
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//get data in table of hiring session
export const getallsession = createAsyncThunk(
  "getallsession",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/getAllHiringSessions");
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//Get session by Id 
export const getsingledata = createAsyncThunk(
  "getsingledata",
  async (id, { rejectWithValue }) => {
    console.log('id console',id)
    try {
      const response = await axiosInstance.get(`/getHiringSessionById/${id}`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);



// Get data in form from id which will be updated
// export const getDataform = createAsyncThunk(
//   "getDataform",
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await axiosInstance.get(`/getApplicantById/${id}`);
//       console.log(response.data);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
// );
const ApplicantSlice = createSlice({
  name: "applicants",
  initialState,
  reducers: {
    ApplicantsAdded: {
      reducer(state, action) {
        state.applicants.push(action.payload);
      },
    },
    deleteDeapplicant: {
      reducer(state, action) {
        const userIndex = state?.applicants.findIndex(
          (tech) => tech.tech_id === action.payload
        );
        if (userIndex !== -1) {
          state.applicants.splice(userIndex, 1);
        }
      },
    },

    ApplicantsResetData: (state) => {
      state.applicants = [];
      state.stages=[];
      state.allsession=[];
      state.singlesession=[];
      state.isSuccess = false;
      state.message = null;
      state.status = "idle"; //'idle' | 'loading' | 'succeeded' | 'failed'
      state.error = null;
      state.IsLogin = true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchApplicants.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchApplicants.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.applicants = action.payload.Data;
      })
      .addCase(fetchApplicants.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
    builder
      .addCase(showData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(showData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.applicants = action.payload.Data;
      })
      .addCase(showData.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
    builder
      .addCase(getallData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getallData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.applicants = action.payload.Data;
      })
      .addCase(getallData.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(getallSessionData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getallSessionData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.applicants = action.payload.Data;
      })
      .addCase(getallSessionData.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(deleData.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleData.fulfilled, (state, action) => {
        state.loading = false;
        const { id } = action.payload;
        if (id) {
          state.applicants = state.applicants.filter((ele) => ele.id !== id);
        }
      })
      .addCase(deleData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
       builder
      .addCase(updateApplicant.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateApplicant.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.applicants = action.payload.Data;
      })
      .addCase(updateApplicant.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = "failed";
      });
      builder
      .addCase(getstagesData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getstagesData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.stages = action.payload.Data;
      })
      .addCase(getstagesData.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
      // builder
      // .addCase(getDataform.pending, (state) => {
      //   state.status = "loading";
      // })
      // .addCase(getDataform.fulfilled, (state, action) => {
      //   state.status = "succeeded";
      //   state.message = action.payload.Message;
      //   state.isSuccess = action.payload.IsSuccess;
      //   state.applicants = action.payload.Data;
      // })
      // .addCase(getDataform.rejected, (state, action) => {
      //   if (action?.error?.message.includes(401)) {
      //     state.message = action.error.message;
      //     state.IsLogin = false;
      //   } else {
      //     state.error = action.error.message;
      //     state.status = "failed";
      //   }
      // });
      builder
      .addCase(getallsession.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getallsession.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.allsession = action.payload.Data;
        state.PageNum = Number(action.payload?.PageNum);
        state.PageSize = Number(action.payload?.PageSize);
        state.TotalCount = Number(action.payload?.TotalCount);
      })
      .addCase(getallsession.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
      builder
      .addCase(getsingledata.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getsingledata.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.singlesession = action.payload.Data;
      })
      .addCase(getsingledata.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
  },
});

export const getApplicantsDetails = (state) => state.applicants;

export const { ApplicantsAdded, ApplicantsResetData, deleteDeapplicant } =
  ApplicantSlice.actions;

export default ApplicantSlice.reducer;
