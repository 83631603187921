import {
  Box,
  Button,
  FormControl,
  Typography,
  TextField,
  CircularProgress,
  IconButton,
  Paper,
  Select,
  Chip,
  OutlinedInput,
  InputLabel,
  MenuItem,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableBody,
  TableHead,
} from "@mui/material";
import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import theme from "../../../theme";
import { makeStyles } from "@mui/styles";
import RemoveIcon from "@mui/icons-material/Remove";
import { Field, Formik } from "formik";
import { object } from "yup";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useContext } from "react";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";

import {
  // employePopulate,
  // FetchSingleEmployee,
  getemployeeDetails,
} from "../../../Redux/Slice/Employee/EmplyeeSlice";
import { PatchTechExpApi } from "../../../Api/Employee/UpdateTechExpApi";
import { PostExperience } from "../../../Api/Employee/PostExperience";
import { HeaderContext } from "../../../Context/SnackBarContext/HeaderContext/HeaderContext";
import { logout } from "../../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => {
  return {
    inputBox: {
      width: "100%",
    },
    input_typography: {
      textAlign: "left",

      fontSize: `15px !important`,

      marginTop: "10px !important",
      color: "black",
    },
    Form_input: {
      width: "100%",
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,
    },

    btns: {
      width: "80px",
    },
    FieldInRow: {
      display: "flex",
      justifyContent: "space-between",
      gap: "20px",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },
  };
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(tech, filterTechnology) {
  return {
    backgroundColor: filterTechnology?.indexOf(tech) === -1 ? "" : "#BCDAF4",
  };
}
const PastExperienceForm = ({
  setstepper,

  isEdited,
  empID,
  setisEdited,
  setShowForm,
}) => {
  const { Form_input, input_typography, btns, inputBox, FieldInRow } =
    useStyles();
  const [experience, setExperience] = useState([]);
  const [selectedTechnologies, setselectedTechnologies] = useState({});
  const [selectedExperties, setselectedExperties] = useState([]);
  const { Technologies } = useSelector(getemployeeDetails);
  const [disableNext, setdisableNext] = useState(true);
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { singleEmployee, IsLogin, message } = useSelector(getemployeeDetails);
  const ref = useRef();
  const { setheader } = useContext(HeaderContext);
  const [addedItems, setAddedItems] = useState([]);
  const [Experties, setExperties] = useState([]);
  console.log("addedItems", addedItems);
  console.log(Experties, setExperties);
  console.log(selectedExperties);
  const [employeedata] = useState({
    emptech: "",
    emp_expertise: "",
    joining_date: "",
  });
console.log(selectedTechnologies);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  // const handelAdd = async () => {
  //   setloading(true);
  //   if (selectedExperties.length < 1 || selectedTechnologies.length < 1) {
  //     setloading(false);
  //     return setsnackBarData({
  //       type: "error",
  //       message: "please add complete details",
  //       openToast: true,
  //     });
  //   }

  //   if (selectedTechnologies.length < 1 || selectedExperties.length < 1) {
  //     setloading(false);
  //     return setsnackBarData({
  //       type: "error",
  //       message: "Please add complete details",
  //       openToast: true,
  //     });
  //   }
  //   const EmptyExperience =
  //     experience &&
  //     experience.find((exp) => {
  //       if (
  //         exp?.company_name == "" ||
  //         exp?.leaving_date == "" ||
  //         exp?.starting_date == "" ||
  //         exp?.job_title == ""
  //       ) {
  //         return exp;
  //       }
  //     });
  //   if (EmptyExperience) {
  //     setloading(false);
  //     return setsnackBarData({
  //       type: "error",
  //       message: "Please Add Complete Experience Details",
  //       openToast: true,
  //     });
  //   } else {
  //     let techIDs = [];
  //     let expertiseIDs = [];
  //     selectedTechnologies?.map((tech) => {
  //       Technologies.map((technology) => {
  //         if (technology.technology_name === tech) {
  //           techIDs.push(technology.tech_id);
  //         }
  //       });
  //     });
  //     selectedExperties?.map((exp) => {
  //       Experties.map((expertise) => {
  //         if (expertise.expertise_name === exp) {
  //           expertiseIDs.push(expertise.expertise_id);
  //         }
  //       });
  //     });

  //     const obj = {
  //       emptech: techIDs,
  //       emp_expertise: expertiseIDs,
  //       past_experience: experience,
  //     };

  //     if (isEdited) {
  //       let response = await PatchTechExpApi({
  //         ...obj,
  //         emp_id: singleEmployee?.Employee?.Emp_id,
  //       });
  //       setsnackBarData(response.snackBarData);
  //       if (response.data.IsSuccess) {
  //         setloading(false);
  //         setstepper((stepper) => stepper + 1);
  //       } else {
  //         setloading(false);
  //       }
  //     } else if (!isEdited) {
  //       setloading(true);

  //       let response = await PostExperience({ ...obj, emp_id: empID });
  //       setsnackBarData(response.snackBarData);
  //       if (response.data.IsSuccess) {
  //         setloading(false);
  //         setstepper((stepper) => stepper + 1);
  //         setdisableNext(false);
  //       } else {
  //         setloading(false);
  //       }
  //     }
  //     const newItem = {
  //       technology: selectedTechnologies,
  //       expertise: selectedExperties,
  //     };

  //     setAddedItems([...addedItems, newItem]);
  //     setselectedTechnologies([]);
  //     setselectedExperties([]);
  //   }
  // };

  const handelAdd = async () => {
    setloading(true);

    if (addedItems.length < 1) {
      setloading(false);
      return setsnackBarData({
        type: "error",
        message: "Please add Technology details",
        openToast: true,
      });
    }

    const EmptyExperience =
      experience &&
      experience.find((exp) => {
        if (
          exp?.company_name == "" ||
          exp?.starting_date == "" ||
          exp?.job_title == ""
        ) {
          return exp;
        }
      });
    if (EmptyExperience) {
      setloading(false);
      return setsnackBarData({
        type: "error",
        message: "Please add complete experience details",
        openToast: true,
      });
    } else {
      let techExpArr = [];
      addedItems.forEach((item) => {
        techExpArr.push({
          tech_id: item.tech_id,
          emp_expertise: item.expertiseid.map((exp) => exp.expertise_id),
        });
      });
      console.log(techExpArr);
      const obj = {
        emptech: techExpArr,
        past_experience: experience,
      };
      if (isEdited) {
        let response = await PatchTechExpApi({
          ...obj,
          emp_id: singleEmployee?.Employee?.Emp_id,
        });
        setsnackBarData(response.snackBarData);
        if (response.data.IsSuccess) {
          setloading(false);
          setstepper((stepper) => stepper + 1);
        } else {
          setloading(false);
        }
      } else if (!isEdited) {
        setloading(true);

        let response = await PostExperience({ ...obj, emp_id: empID });
        setsnackBarData(response.snackBarData);
        if (response.data.IsSuccess) {
          setloading(false);
          setstepper((stepper) => stepper + 1);
          setdisableNext(false);
        } else {
          setloading(false);
        }
      }
    }
  };
console.log(addedItems);
  const handelAddTechnologies = () => {
    if (!selectedTechnologies || selectedExperties.length < 1) {
      setsnackBarData({
        type: "error",
        message: "Please select technology with their expertise",
        openToast: true,
      });
      return;
    }
    const techExperties = selectedTechnologies.expertiseid.filter((exp) => {
      let matched = selectedExperties.some((ex) => {
        if (ex === exp.expertise_name) {
          return exp;
        }
      });
      return matched;
    });
    console.log("selectedTechnologies", selectedTechnologies);
    var tech = JSON.parse(JSON.stringify(selectedTechnologies));
    tech.expertiseid = techExperties;
    const newItems = [tech];

    setAddedItems([...addedItems, ...newItems]);
    setselectedTechnologies([]);
    setselectedExperties([]);
  };

  const addExperience = () => {
    const obj = {
      company_name: "",
      leaving_date: "",
      starting_date: "",
      job_title: "",
    };
    setExperience([...experience, obj]);
  };

  useEffect(() => {
    if (isEdited) {
      console.log(singleEmployee);
      let value = singleEmployee?.Employee?.emp_expertise?.map(
        (expertise) => expertise?.expertise_name
      );
      // let empTech = singleEmployee?.Employee?.emptech?.map(
      //   (tech) => {return setselectedTechnologies({tech_id:tech?.tech_id,technology_name:tech?.technology_name,expertiseid:tech?.empexpertise})}
      // );
      console.log(value);
      // console.log(empTech);
      // setselectedTechnologies(empTech);
      // setselectedExperties("");
      setExperience(singleEmployee?.Past_experience);
      setdisableNext(false);
      let arr=[]
      singleEmployee?.Employee?.emptech?.map((item)=>{
        let arr2=[]
        item?.empexpertise?.map((exp)=>{
arr2.push({
  expertise_id:exp?.expertise_id,
  expertise_name:exp?.expertise_name
})
        })
arr.push({
  tech_id:item?.tech_id,
  technology_name:item?.technology_name,
  expertiseid:arr2
})
      })

      setAddedItems(arr)
    }
  }, [singleEmployee]);

  useEffect(() => {
    // dispatch(employePopulate());
    // if (isEdited) {
    //   dispatch(FetchSingleEmployee(empData?.empId));
    // }
  }, [dispatch]);

  // const handleChange = (event) => {
  //   const selectedTech = event.target.value;
  //   if (event.target.name === "emp_tech") {
  //     setselectedTechnologies(
  //       typeof selectedTech === "string"
  //         ? selectedTech.split(",")
  //         : selectedTech
  //     );
  //   } else if (event.target.name === "emp_expertise") {
  //     setselectedExperties(
  //       typeof selectedTech === "string"
  //         ? selectedTech.split(",")
  //         : selectedTech
  //     );
  //   }

  //   const techExperties = Experties.filter(
  //     (expertie) => expertie.technology === selectedTech
  //   );
  //   const selectedExperties = techExperties.map(
  //     (expertie) => expertie.expertise_name
  //   );
  //   setselectedExperties(selectedExperties);
  // };

  const handleChange = (event) => {
    const selectedValue = event.target.value;

    setselectedExperties(selectedValue);
  };

  const handleCancelRow = (index) => {
    const updatedItems = [...addedItems];
    updatedItems.splice(index, 1);
    setAddedItems(updatedItems);
  };
  // console.log(addItems);
  return (
    <>
    {
      isEdited?
    
    <Box sx={{display:"flex",justifyContent:"center",paddingTop:"20px"}}>
    <Box sx={{          width: "100%",
          maxWidth: "900px",}}>
                  <Paper elevation={3} sx={{ padding: "10px",display:"flex",justifyContent:"space-between",alignItems:"center" }}>
                    <Box sx={{display:"flex",gap:"10px"}}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Employee Name
                        </Typography>
                        <Typography sx={{ fontWeight: "" }}>
                          {singleEmployee?.Employee?.name}
                        </Typography>
                        </Box>
                    <Box sx={{display:"flex",gap:"10px"}}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Designation
                        </Typography>
                        <Typography sx={{ fontWeight: "" }}>
                          {singleEmployee?.Employee?.designation?.designation_name}
                        </Typography>
                        </Box>
                      </Paper>
                      </Box>
                      </Box>:null
                      }
    <Box
      sx={{
        minHeight: "calc(100vh - 165px)",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "20px",
      }}
    >
          
      <Box
        sx={{
          width: "100%",
          maxWidth: "900px",
          display: "flex",
          gap: "20px",
          justifyContent: "space-between",
          flexWrap: "wrap",
          [theme.breakpoints.down("md")]: {
            gap: "20px",
          },
        }}
      >
        <Formik
          initialValues={employeedata}
          validationSchema={object({})}
          onSubmit={(values, formikHelpers) => {
            handelAdd(values, formikHelpers);
          }}
        >
          {({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        // marginTop: "20px",
                      }}
                    >
                      <Box className={FieldInRow}>
                        <Box className={inputBox}>
                          <InputLabel
                            id="technology"
                            className={input_typography}
                          >
                            Technology
                          </InputLabel>
                          <FormControl fullWidth sx={{ textAlign: "left" }}>
                            <Field
                              size="small"
                              name="emp_tech"
                              id="technology"
                              variant="outlined"
                              onChange={(event) => {
                                console.log(event.target.value);
                                setselectedTechnologies(event.target.value);
                                setselectedExperties([]);
                              }}
                              as={TextField}
                              type="text"
                              color="primary"
                              ref={ref}
                              value={selectedTechnologies}
                              sx={{ input: { color: "black" } }}
                              className={Form_input}
                              fullWidth
                              select
                            >
                              <MenuItem value={""}>Select</MenuItem>
                              {Technologies
                                ? Technologies?.map((tech, index) => {
                                    return (
                                      <MenuItem
                                        // style={getStyles(
                                        //   tech.technology_name,
                                        //   selectedTechnologies,
                                        //   theme
                                        // )}
                                        key={index}
                                        value={tech}
                                      >
                                        {tech.technology_name}
                                      </MenuItem>
                                    );
                                  })
                                : null}
                            </Field>
                          </FormControl>
                        </Box>

                        <Box className={inputBox}>
                          <InputLabel
                            id="expertise"
                            className={input_typography}
                          >
                            Skill Set
                          </InputLabel>

                          <FormControl fullWidth sx={{ textAlign: "left" }}>
                            <Select
                              size="small"
                              name="emp_expertise"
                              id="expertise"
                              variant="outlined"
                              type="text"
                              color="primary"
                              onChange={handleChange}
                              sx={{ input: { color: "black" } }}
                              className={Form_input}
                              fullWidth
                              value={selectedExperties || []}
                              multiple
                              input={
                                <OutlinedInput
                                  id="select-multiple-chip"
                                  label="Chip"
                                />
                              }
                              renderValue={(selected) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 0.5,
                                  }}
                                >
                                  {selected?.map((value, i) => (
                                    <Chip key={i} label={value} />
                                  ))}
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {selectedTechnologies?.expertiseid?.map(
                                (expertie) => (
                                  <MenuItem
                                    style={getStyles(
                                      expertie.expertise_name,
                                      selectedExperties,
                                      theme
                                    )}
                                    value={expertie.expertise_name}
                                    key={expertie.expertise_id}
                                  >
                                    {expertie.expertise_name}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ textAlign: "right" }}>
                      <Button
                        variant="contained"
                        sx={{ padding: "2px" }}
                        onClick={handelAddTechnologies}
                      >
                        <AddIcon />
                      </Button>
                    </Box>

                    <Paper sx={{ padding: "5px" }} elevation={3}>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontWeight: "bolder" }}>
                                Technology
                              </TableCell>
                              <TableCell sx={{ fontWeight: "bolder" }}>
                                Skill Set
                              </TableCell>
                              <TableCell sx={{ fontWeight: "bolder" }}>
                                Actions
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {addedItems?.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>{item.technology_name}</TableCell>
                                <TableCell>
                                  {item.expertiseid
                                    .map((exp) => exp.expertise_name)
                                    .join(", ")}
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    sx={{
                                      background: (theme) =>
                                        theme.palette.error.main,
                                      padding: "0px",
                                      color: (theme) =>
                                        theme.palette.white.main,
                                    }}
                                    onClick={() => handleCancelRow(index)}
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <Paper elevation={3} sx={{ padding: "10px" }}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Experience
                        </Typography>
                      </Paper>
                      <Box sx={{ textAlign: "right" }}>
                        <Button
                          variant="contained"
                          sx={{ padding: "2px" }}
                          onClick={addExperience}
                        >
                          <AddIcon />
                        </Button>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                        }}
                      >
                        {experience &&
                          experience.map((education, index) => {
                            return (
                              <Paper
                                key={index}
                                sx={{ padding: "5px" }}
                                elevation={3}
                              >
                                <Box
                                  sx={{
                                    transform: "translate(50%,-50%)",
                                    width: "fit-content",
                                    float: "right",
                                  }}
                                >
                                  <IconButton
                                    sx={{
                                      background: (theme) =>
                                        theme.palette.error.main,
                                      padding: "0px",
                                      color: (theme) =>
                                        theme.palette.white.main,
                                    }}
                                    onClick={() => {
                                      let newArr = JSON.parse(
                                        JSON.stringify(experience)
                                      );
                                      newArr.splice(index, 1);
                                      setExperience(newArr);
                                    }}
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                </Box>
                                <Box
                                  sx={{
                                    padding: "20px",
                                    display: { md: "flex", xs: "block" },
                                    gap: "20px",
                                  }}
                                >
                                  <Box>
                                    <Typography className={input_typography}>
                                      Company Name
                                    </Typography>
                                    <FormControl
                                      fullWidth
                                      sx={{ textAlign: "left" }}
                                    >
                                      <Field
                                        size="small"
                                        name="company_name"
                                        as={TextField}
                                        variant="outlined"
                                        value={education.company_name}
                                        color="primary"
                                        onChange={(e) => {
                                          let newArr = JSON.parse(
                                            JSON.stringify(experience)
                                          );
                                          newArr[index].company_name =
                                            e.target.value;
                                          setExperience(newArr);
                                        }}
                                        sx={{ input: { color: "black" } }}
                                        fullWidth
                                      />
                                    </FormControl>
                                  </Box>
                                  <Box>
                                    <Typography className={input_typography}>
                                      Job Title
                                    </Typography>
                                    <FormControl
                                      fullWidth
                                      sx={{ textAlign: "left" }}
                                    >
                                      <Field
                                        size="small"
                                        name="job_title"
                                        as={TextField}
                                        variant="outlined"
                                        color="primary"
                                        value={education.job_title}
                                        onChange={(e) => {
                                          let newArr = JSON.parse(
                                            JSON.stringify(experience)
                                          );
                                          newArr[index].job_title =
                                            e.target.value;
                                          setExperience(newArr);
                                        }}
                                        sx={{ input: { color: "black" } }}
                                        fullWidth
                                      />
                                    </FormControl>
                                  </Box>
                                  <Box>
                                    <Typography className={input_typography}>
                                      Starting Date
                                    </Typography>
                                    <FormControl
                                      fullWidth
                                      sx={{ textAlign: "left" }}
                                    >
                                      <Field
                                        size="small"
                                        name="starting_date"
                                        type="date"
                                        as={TextField}
                                        variant="outlined"
                                        value={education.starting_date}
                                        onChange={(e) => {
                                          let newArr = JSON.parse(
                                            JSON.stringify(experience)
                                          );
                                          newArr[index].starting_date =
                                            e.target.value;
                                          setExperience(newArr);
                                        }}
                                        color="primary"
                                        sx={{ input: { color: "black" } }}
                                        fullWidth
                                      />
                                    </FormControl>
                                  </Box>
                                  <Box>
                                    <Typography className={input_typography}>
                                      Leaving Date
                                    </Typography>
                                    <FormControl
                                      fullWidth
                                      sx={{ textAlign: "left" }}
                                    >
                                      <Field
                                        size="small"
                                        name="leaving_date"
                                        as={TextField}
                                        variant="outlined"
                                        value={education.leaving_date}
                                        type="date"
                                        color="primary"
                                        onChange={(e) => {
                                          let newArr = JSON.parse(
                                            JSON.stringify(experience)
                                          );
                                          newArr[index].leaving_date =
                                            e.target.value;
                                          setExperience(newArr);
                                        }}
                                        sx={{ input: { color: "black" } }}
                                        fullWidth
                                      />
                                    </FormControl>
                                  </Box>
                                </Box>
                              </Paper>
                            );
                          })}
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        float: "right",
                        display: "flex",
                        gap: "10px",
                        marginTop: "20px",
                      }}
                      spacing={2}
                    >
                      <>
                        <Button
                          className={btns}
                          variant="contained"
                          color="error"
                          onClick={() => {
                            setShowForm(false);
                            setisEdited(false);
                            setheader(null);
                          }}
                        >
                          Cancel
                        </Button>
                        {isEdited ? (
                          <Button
                            className={btns}
                            variant="contained"
                            color="warning"
                            onClick={() => {
                              setstepper((stepper) => stepper - 1);
                            }}
                          >
                            Back
                          </Button>
                        ) : null}
                        {loading === false ? (
                          !isEdited ? (
                            <Button
                              className={btns}
                              type="submit"
                              variant="contained"
                              color="primary"
                            >
                              Save
                            </Button>
                          ) : (
                            <Button
                              className={btns}
                              type="submit"
                              variant="contained"
                              color="primary"
                            >
                              Update
                            </Button>
                          )
                        ) : (
                          <CircularProgress />
                        )}
                        {isEdited ? (
                          <Button
                            className={btns}
                            variant="contained"
                            color="success"
                            disabled={disableNext === true ? true : false}
                            onClick={() => {
                              setstepper((stepper) => stepper + 1);
                            }}
                          >
                            Next
                          </Button>
                        ) : null}
                      </>
                    </Box>
                  </Box>
                </Box>
              </form>
            );
          }}
        </Formik>
      </Box>
    </Box>
    </>
  );
};

export default PastExperienceForm;

PastExperienceForm.propTypes = {
  setShowForm: PropTypes.any,
  setstepper: PropTypes.any,
  empID: PropTypes.any,
  empData: PropTypes.any,
  isEdited: PropTypes.any,
  setisEdited: PropTypes.any,
  singleEmployee: PropTypes.any,
};
