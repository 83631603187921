import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import CodeIcon from "@mui/icons-material/Code";
import AttendanceDrawer from "../AttendanceDrawer/AttendanceDrawer";
import PropTypes from "prop-types";
const useStyle = makeStyles((theme) => {
  return {
    containerCard: {
      width: "100%",
      maxWidth: "300px",

      padding: "20px",
      color: theme.palette.grey[500],
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      borderRadius: "10px",
      cursor: "pointer",
    },
    contentContainerCard: {
      display: "flex",
      justifyContent: "space-between",
    },
    attendanceDetails: {
      display: "flex",
      gap: "20px",
      justifyContent: "space-between",
      alignItems: "center",
    },
    BottomTag: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
  };
});

const AttendanceCard = ({ data }) => {
  const { containerCard, contentContainerCard, attendanceDetails, BottomTag } =
    useStyle();
  const [selectedData, setselectedData] = useState(null);
  const [open, setOpen] = useState(false);
  // console.log(data);
  const handleClick = (e) => {
    e.stopPropagation();
    setselectedData(data);
    setOpen(true);
  };
  return (
    <Box className={containerCard} onClick={handleClick}>
      <Box className={contentContainerCard}>
        <Box>
          <Typography variant="h6">Total</Typography>
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            {data?.departmetn_emp_count}
          </Typography>
        </Box>
        <Box>
          <Box className={attendanceDetails}>
            <Typography>On-time</Typography>
            <Typography sx={{ fontWeight: "bold" }}>{data?.Ontime}</Typography>
          </Box>
          <Box className={attendanceDetails}>
            <Typography>Late</Typography>
            <Typography sx={{ fontWeight: "bold" }}>{data?.late}</Typography>
          </Box>
          <Box className={attendanceDetails}>
            <Typography>Absent/Leave</Typography>
            <Typography sx={{ fontWeight: "bold" }}>{data?.absent}</Typography>
          </Box>
        </Box>
      </Box>
      <Box className={BottomTag}>
        <CodeIcon sx={{ fontSize: "30px" }} />
        <Typography
          sx={{
            fontWeight: "bold",
            textTransform: "capitalize",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {data?.Department_Name}
        </Typography>
      </Box>
      <AttendanceDrawer open={open} setOpen={setOpen} data={selectedData} />
    </Box>
  );
};

export default AttendanceCard;
AttendanceCard.propTypes = {
  data: PropTypes.any,
};
