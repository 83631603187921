import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardActivities,
  GetSingleEmployeeAttendance,
} from "../../Redux/Slice/Dashboard/DashboardSlice";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment/moment";
import Cookies from "universal-cookie";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useNavigate } from "react-router-dom";

function EmployeeAttendanceDetails() {
  const navigate=useNavigate()
  const { setsnackBarData } = useContext(SnackBarContext);
  const { singleEmployeeAttendance, attendanceStatus,IsLogin,message } = useSelector(
    getDashboardActivities
  );
  const [state, setstate] = useState(null);
  const [date, setdate] = useState("");
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const user = cookies.get("user");
  console.log(state);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);

  useEffect(() => {
    dispatch(
      GetSingleEmployeeAttendance({
        userid: user?.Emp_id,
        date: moment().format("YYYY-MM-DD"),
      })
    );
  }, [dispatch]);

  useEffect(() => {
    setstate(singleEmployeeAttendance);
  }, [singleEmployeeAttendance]);

  const handleChange = (e) => {
    setdate(e.target.value);
    let date = moment(e.target.value).format("YYYY-MM-DD");
    dispatch(GetSingleEmployeeAttendance({ userid: user?.Emp_id, date: date }));
  };

  return (
    <>
      <Grid container sx={{ overflow: "scroll", width: "100%" }}>
        <div
          style={{
            height: "430px",
            width: "100%",
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            overflow: "scroll",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: (theme) => theme.palette.grey["500"],
                textAlign: "left",
              }}
            >
              Attendance Summary
            </Typography>
            <TextField
              variant="standard"
              type="month"
              autoComplete="off"
              value={date}
              placeholder="Employee"
              className="input_border"
              onChange={handleChange}
              id="ProjectNameFilter"
            />
          </Box>

          <TableContainer
            component={Paper}
            sx={{ height: "400px", overflowY: "scroll" }}
          >
            {attendanceStatus !== "succeeded" ? (
              <Box
                sx={{
                  width: "100%",
                  height: "400px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Table
                sx={{
                  minWidth: { md: "auto", xs: "auto" },
                  overflow: "scroll",
                }}
                aria-label="simple table"
                size="small"
              >
                <TableHead>
                  <TableRow size="small">
                    <TableCell
                      size="small"
                      align="left"
                      sx={{
                        fontWeight: "bold",
                        color: (theme) => theme.palette.grey["500"],
                      }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      size="small"
                      align="left"
                      sx={{
                        fontWeight: "bold",
                        color: (theme) => theme.palette.grey["500"],
                      }}
                    >
                      In Time
                    </TableCell>
                    <TableCell
                      size="small"
                      align="left"
                      sx={{
                        fontWeight: "bold",
                        color: (theme) => theme.palette.grey["500"],
                      }}
                    >
                      Out Time
                    </TableCell>
                    <TableCell
                      size="small"
                      align="right"
                      sx={{
                        fontWeight: "bold",
                        color: (theme) => theme.palette.grey["500"],
                      }}
                    >
                      Worked Hours
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state &&
                    state?.map((row, i) => (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell size="small" align="left">
                          {row?.Date
                            ? moment(row?.Date).format("DD MMM, YY")
                            : ""}
                        </TableCell>
                        <TableCell size="small" align="left">
                          {row?.InTime
                            ? moment(row?.InTime, ["hh:mm"]).format("hh:mm A")
                            : ""}
                        </TableCell>
                        <TableCell size="small" align="left">
                          {row?.OutTime && row?.OutTime
                            ? moment(row?.OutTime, ["hh:mm"]).format("hh:mm A")
                            : ""}
                        </TableCell>
                        <TableCell size="small" align="right">
                        {/* {row?.WorkedHours && row?.WorkedHours
                            ? moment(row?.WorkedHours, ["hh:mm"]).format("hh:mm A")
                            : ""} */}
                          {row?.WorkedHours && row?.WorkedHours
                            ? `${row?.WorkedHours==null?"":String(row?.WorkedHours)?.split(".")[0]} hrs ${
                              !String(row?.WorkedHours)?.split(".")[1]?0:String(row?.WorkedHours)?.split(".")[1]
                              } min `
                            : null}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </div>
      </Grid>
    </>
  );
}

export default EmployeeAttendanceDetails;
