import {
  Box,
  Button,
  FormControl,
  Typography,
  TextField,
  IconButton,
  Paper,
  CircularProgress,
  InputAdornment,
  Avatar,
  Tooltip,
} from "@mui/material";
window.Buffer = window.Buffer || require("buffer").Buffer;
import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Form, Formik } from "formik";
import { object, string } from "yup";
import "react-image-crop/dist/ReactCrop.css";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { useContext } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { getemployeeDetails } from "../../../Redux/Slice/Employee/EmplyeeSlice";
import { HeaderContext } from "../../../Context/SnackBarContext/HeaderContext/HeaderContext";
import { logout } from "../../../Redux/Slice/LogoutSlice/LogoutSlice";
import theme from "../../../theme";
import { AddOrganizationApi } from "../../../Api/Organizations/AddOrganizationApi";

const validationSchema = object({
  username: string().required("This field is required"),
  password: string()
    .required("This field is required")
    .min(10, "Please Enter At Least 10 Character!"),
  companyName: string().required("This field is required"),
  email: string()
    .email("Please enter a valid email address")
    .required("This field is required"),
  supportedEmail: string().email("Please enter a valid email address"),
  website: string().url("Invalid URL format"),
  linkedinLink: string().url("Invalid URL format"),
  facebookLink: string().url("Invalid URL format"),
  instaLink: string().url("Invalid URL format"),
});

const OrganizationsForm = ({ setShowForm, setisEdited, isEdited }) => {
  const {
    Form_input,
    input_typography,
    btns,
    inputBox,
    FieldInRow,
    imgcontainer,
  } = useStyles();
  const logoInputRef = useRef();
  const personInputRef = useRef();
  const [loading, setloading] = useState(false);

  const navigate = useNavigate();
  const { setheader } = useContext(HeaderContext);
  const { setsnackBarData } = useContext(SnackBarContext);
  const [phoneNum, setPhoneNum] = useState(null);

  const dispatch = useDispatch();
  const { IsLogin, message } = useSelector(getemployeeDetails);

  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);

  const handelAdd = async (values, { resetForm }) => {
    if (!phoneNum || !isValidPhoneNumber(phoneNum)) {
      return setsnackBarData({
        openToast: true,
        message: "Please Enter Valid Phone Number",
        type: "error",
      });
    }
    // setloading(true);

    const formData = new FormData();
    formData.append("username", values.username);
    formData.append("password", values.password);
    formData.append("company_name", values.companyName);
    formData.append("email", values.email);
    formData.append("phonenum", phoneNum);

    if (values.logo) {
      formData.append("logo", values.logo);
    }

    if (values.personalimg) {
      formData.append("personalimg", values.personalimg);
    }

    if (values.supportedEmail) {
      formData.append("supported_email", values.supportedEmail);
    }

    if (values.address) {
      formData.append("address", values.address);
    }

    if (values.website) {
      formData.append("website", values.website);
    }

    if (values.instaLink) {
      formData.append("insta_link", values.instaLink);
    }

    if (values.linkedinLink) {
      formData.append("linkedin_link", values.linkedinLink);
    }

    if (values.facebookLink) {
      formData.append("facebook_link", values.facebookLink);
    }

    const response = await AddOrganizationApi(formData);
    setloading(false);
    setsnackBarData(response.snackBarData);

    if (response?.data?.IsSuccess) {
      setPhoneNum(null);
      resetForm();
    }
  };

  return (
    <Box
      sx={{
        minHeight: "calc(100vh - 165px)",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "20px",
      }}
    >
      <Formik
        validateOnChange={true}
        enableReinitialize={true}
        initialValues={{
          personalimg: null,
          logo: null,
          username: "",
          password: "",
          companyName: "",
          email: "",
          address: "",
          supportedEmail: "",
          website: "",
          linkedinLink: "",
          facebookLink: "",
          instaLink: "",
          showPassword: false,
          readOnly: true,
        }}
        validationSchema={validationSchema}
        onSubmit={handelAdd}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => {
          return (
            <Form autoComplete="off">
              <Box>
                <Box
                  sx={{
                    maxWidth: "900px",
                    width: "100%",
                    display: "flex",
                    gap: "20px",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <Box className={FieldInRow}>
                    <Box
                      className={inputBox}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <Paper
                        elevation={3}
                        className={imgcontainer}
                        sx={{ borderRadius: "50%" }}
                      >
                        {!values.logo ? (
                          <Avatar
                            src="/broken-image.jpg"
                            sx={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "50%",
                            }}
                          />
                        ) : (
                          <img
                            src={`${values.logo?.url}`}
                            alt={
                              values.logo
                                ? values.logo?.url
                                    .split("?")[0]
                                    ?.split(".com/")[1]
                                    ?.split("-")[1]
                                : "profile"
                            }
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "50%",
                              objectFit: "fill",
                            }}
                          />
                        )}
                      </Paper>

                      <Box>
                        <Typography
                          className={input_typography}
                          sx={{ fontWeight: "bold" }}
                        >
                          Company Logo
                        </Typography>
                        <Box
                          sx={{
                            marginTop: "10px",
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <Box>
                            <input
                              type={"file"}
                              ref={logoInputRef}
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={(e) => {
                                let obj = {
                                  url: URL.createObjectURL(e.target.files[0]),
                                  file: e.target.files[0],
                                };
                                setFieldValue("logo", obj);
                              }}
                            />

                            <Tooltip
                              title="Upload"
                              placement="top"
                            >
                              <IconButton
                                color="primary"
                                onClick={() => {
                                  logoInputRef.current.click();
                                }}
                              >
                                <FileUploadIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>

                          <Tooltip
                            title="Remove"
                            placement="top"
                          >
                            <IconButton
                              color="error"
                              onClick={(e) => {
                                e.stopPropagation();
                                setFieldValue("logo", null);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      className={inputBox}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <Paper
                        elevation={3}
                        className={imgcontainer}
                        sx={{ borderRadius: "50%" }}
                      >
                        {!values.personalimg ? (
                          <Avatar
                            src="/broken-image.jpg"
                            sx={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "50%",
                            }}
                          />
                        ) : (
                          <img
                            src={`${values.personalimg?.url}`}
                            alt={
                              values.personalimg
                                ? values.personalimg?.url
                                    .split("?")[0]
                                    ?.split(".com/")[1]
                                    ?.split("-")[1]
                                : "profile"
                            }
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "50%",
                              objectFit: "fill",
                            }}
                          />
                        )}
                      </Paper>

                      <Box>
                        <Typography
                          className={input_typography}
                          sx={{ fontWeight: "bold" }}
                        >
                          Person Image
                        </Typography>
                        <Box
                          sx={{
                            marginTop: "10px",
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <Box>
                            <input
                              type={"file"}
                              ref={personInputRef}
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={(e) => {
                                let obj = {
                                  url: URL.createObjectURL(e.target.files[0]),
                                  file: e.target.files[0],
                                };
                                setFieldValue("personalimg", obj);
                              }}
                            />

                            <Tooltip
                              title="Upload"
                              placement="top"
                            >
                              <IconButton
                                color="primary"
                                onClick={() => {
                                  personInputRef.current.click();
                                }}
                              >
                                <FileUploadIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>

                          <Tooltip
                            title="Remove"
                            placement="top"
                          >
                            <IconButton
                              color="error"
                              onClick={(e) => {
                                e.stopPropagation();
                                setFieldValue("personalimg", null);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box className={FieldInRow}>
                    <Box className={inputBox}>
                      <Typography className={input_typography}>
                        User Name
                      </Typography>

                      <FormControl
                        fullWidth
                        sx={{ textAlign: "left" }}
                      >
                        <TextField
                          variant="outlined"
                          size="small"
                          type="text"
                          color="primary"
                          sx={{ input: { color: "black" } }}
                          className={Form_input}
                          fullWidth
                          name="username"
                          value={values.username}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            Boolean(errors.username) &&
                            Boolean(touched.username)
                          }
                          helperText={
                            Boolean(touched.username) && errors.username
                          }
                        />
                      </FormControl>
                    </Box>

                    <Box className={inputBox}>
                      <Typography className={input_typography}>
                        Password
                      </Typography>
                      <FormControl
                        fullWidth
                        sx={{ textAlign: "left" }}
                      >
                        <TextField
                          size="small"
                          variant="outlined"
                          color="primary"
                          inputProps={{ autoComplete: "off" }}
                          sx={{ input: { color: "black" } }}
                          className={Form_input}
                          fullWidth
                          disabled={values.readOnly || isEdited}
                          onMouseOver={() => setFieldValue("readOnly", false)}
                          type={values.showPassword ? "text" : "password"}
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            Boolean(errors.password) &&
                            Boolean(touched.password)
                          }
                          helperText={
                            Boolean(touched.password) && errors.password
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  disabled={isEdited ? true : false}
                                  onClick={() => {
                                    setFieldValue(
                                      "showPassword",
                                      !values.showPassword
                                    );
                                  }}
                                  edge="end"
                                >
                                  {values.showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Box>
                  </Box>

                  <Box className={FieldInRow}>
                    <Box className={inputBox}>
                      <Typography className={input_typography}>
                        Company Name
                      </Typography>

                      <FormControl
                        fullWidth
                        sx={{ textAlign: "left" }}
                      >
                        <TextField
                          variant="outlined"
                          size="small"
                          type="text"
                          color="primary"
                          sx={{ input: { color: "black" } }}
                          className={Form_input}
                          fullWidth
                          name="companyName"
                          value={values.companyName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            Boolean(errors.companyName) &&
                            Boolean(touched.companyName)
                          }
                          helperText={
                            Boolean(touched.companyName) && errors.companyName
                          }
                        />
                      </FormControl>
                    </Box>

                    <Box className={inputBox}>
                      <Typography className={input_typography}>
                        Email
                      </Typography>

                      <FormControl
                        fullWidth
                        sx={{ textAlign: "left" }}
                      >
                        <TextField
                          variant="outlined"
                          size="small"
                          type="email"
                          color="primary"
                          sx={{ input: { color: "black" } }}
                          className={Form_input}
                          fullWidth
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            Boolean(errors.email) && Boolean(touched.email)
                          }
                          helperText={Boolean(touched.email) && errors.email}
                        />
                      </FormControl>
                    </Box>
                  </Box>

                  <Box className={FieldInRow}>
                    <Box className={inputBox}>
                      <Typography className={input_typography}>
                        Phone
                      </Typography>
                      <PhoneInput
                        placeholder="Enter phone number"
                        value={phoneNum}
                        onChange={setPhoneNum}
                        limitMaxLength
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="PK"
                      />
                    </Box>

                    <Box className={inputBox}>
                      <Typography className={input_typography}>
                        Supported Email
                      </Typography>

                      <FormControl
                        fullWidth
                        sx={{ textAlign: "left" }}
                      >
                        <TextField
                          variant="outlined"
                          size="small"
                          type="email"
                          color="primary"
                          sx={{ input: { color: "black" } }}
                          className={Form_input}
                          fullWidth
                          name="supportedEmail"
                          value={values.supportedEmail}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            Boolean(errors.supportedEmail) &&
                            Boolean(touched.supportedEmail)
                          }
                          helperText={
                            Boolean(touched.supportedEmail) &&
                            errors.supportedEmail
                          }
                        />
                      </FormControl>
                    </Box>
                  </Box>

                  <Box className={FieldInRow}>
                    <Box className={inputBox}>
                      <Typography className={input_typography}>
                        Address
                      </Typography>

                      <FormControl
                        fullWidth
                        sx={{ textAlign: "left" }}
                      >
                        <TextField
                          variant="outlined"
                          size="small"
                          type="text"
                          color="primary"
                          sx={{ input: { color: "black" } }}
                          className={Form_input}
                          fullWidth
                          name="address"
                          value={values.address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            Boolean(errors.address) && Boolean(touched.address)
                          }
                          helperText={
                            Boolean(touched.address) && errors.address
                          }
                        />
                      </FormControl>
                    </Box>
                  </Box>

                  <Box className={FieldInRow}>
                    <Box className={inputBox}>
                      <Typography className={input_typography}>
                        Website
                      </Typography>

                      <FormControl
                        fullWidth
                        sx={{ textAlign: "left" }}
                      >
                        <TextField
                          variant="outlined"
                          size="small"
                          type="text"
                          color="primary"
                          sx={{ input: { color: "black" } }}
                          className={Form_input}
                          fullWidth
                          name="website"
                          value={values.website}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            Boolean(errors.website) && Boolean(touched.website)
                          }
                          helperText={
                            Boolean(touched.website) && errors.website
                          }
                        />
                      </FormControl>
                    </Box>

                    <Box className={inputBox}>
                      <Typography className={input_typography}>
                        Instagram Link
                      </Typography>

                      <FormControl
                        fullWidth
                        sx={{ textAlign: "left" }}
                      >
                        <TextField
                          variant="outlined"
                          size="small"
                          type="text"
                          color="primary"
                          sx={{ input: { color: "black" } }}
                          className={Form_input}
                          fullWidth
                          name="instaLink"
                          value={values.instaLink}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            Boolean(errors.instaLink) &&
                            Boolean(touched.instaLink)
                          }
                          helperText={
                            Boolean(touched.instaLink) && errors.instaLink
                          }
                        />
                      </FormControl>
                    </Box>
                  </Box>

                  <Box className={FieldInRow}>
                    <Box className={inputBox}>
                      <Typography className={input_typography}>
                        LinkedIn Link
                      </Typography>

                      <FormControl
                        fullWidth
                        sx={{ textAlign: "left" }}
                      >
                        <TextField
                          variant="outlined"
                          size="small"
                          type="text"
                          color="primary"
                          sx={{ input: { color: "black" } }}
                          className={Form_input}
                          fullWidth
                          name="linkedinLink"
                          value={values.linkedinLink}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            Boolean(errors.linkedinLink) &&
                            Boolean(touched.linkedinLink)
                          }
                          helperText={
                            Boolean(touched.linkedinLink) && errors.linkedinLink
                          }
                        />
                      </FormControl>
                    </Box>

                    <Box className={inputBox}>
                      <Typography className={input_typography}>
                        Facebook Link
                      </Typography>

                      <FormControl
                        fullWidth
                        sx={{ textAlign: "left" }}
                      >
                        <TextField
                          variant="outlined"
                          size="small"
                          type="text"
                          color="primary"
                          sx={{ input: { color: "black" } }}
                          className={Form_input}
                          fullWidth
                          name="facebookLink"
                          value={values.facebookLink}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            Boolean(errors.facebookLink) &&
                            Boolean(touched.facebookLink)
                          }
                          helperText={
                            Boolean(touched.facebookLink) && errors.facebookLink
                          }
                        />
                      </FormControl>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <Box
                    sx={{
                      float: "right",
                      display: "flex",
                      gap: "10px",
                      marginTop: "20px",
                    }}
                    spacing={2}
                  >
                    <>
                      <Button
                        className={btns}
                        variant="contained"
                        color="error"
                        onClick={() => {
                          setShowForm(false);
                          setisEdited(false);
                          setheader(null);
                        }}
                      >
                        Cancel
                      </Button>

                      {loading === false ? (
                        <Button
                          className={btns}
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          Save
                        </Button>
                      ) : (
                        <CircularProgress />
                      )}
                    </>
                  </Box>
                </Box>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default OrganizationsForm;
OrganizationsForm.propTypes = {
  setShowForm: PropTypes.any,
  empData: PropTypes.any,
  setisEdited: PropTypes.any,
  isEdited: PropTypes.any,
};

const useStyles = makeStyles(() => {
  return {
    inputBox: {
      width: "100%",
    },
    input_typography: {
      textAlign: "left",
      fontSize: `15px !important`,
      marginTop: "10px !important",
    },
    Form_input: {
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,
    },
    btns: {
      width: "80px",
    },
    uploadBoxContainer: {
      minWidth: "100%",
      position: "relative",
    },
    IconContainer: {
      paddingTop: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    reviewBox: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
      flexWrap: "wrap",
    },
    FieldInRow: {
      display: "flex",
      justifyContent: "space-between",
      gap: "20px",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },
    imgcontainer: {
      width: "120px",
      height: "120px",
      borderRadius: "50%",
      padding: "10px",
    },
  };
});
