import React, { useContext } from "react";
import {
  Box,
  Button,
  FormControl,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";
import { object, string } from "yup";
import PropTypes from "prop-types";
import theme from "../../theme";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { useFormik } from "formik";
import moment from "moment/moment";
import { AddClockInOut } from "../../Api/Attendance/AttendanceAPis";
import {
  getAttendancedetailDetails,
  updateAttendanceDetail,
} from "../../Redux/Slice/AttendanceDetail/AttendanceDetailSlice";
import Cookies from "universal-cookie";
import { useEffect } from "react";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => {
  return {
    signUp_form: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    input_typography: {
      textAlign: "left",
      fontWeight: "bold",
      fontSize: `15px !important`,
      marginTop: "10px !important",
      paddingRight: "20px !important",
    },
    Form_input: {
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,
    },
    fieldsInRow: {
      display: "flex",
      justifyContent: "space-between",
      gap: "20px",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },

    form_main: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      padding: "25px",
      height: "calc(100vh - 175px)",
    },
    responsive: {
      maxWidth: "900px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },
    inputBox: {
      width: "100%",
    },

    btns: {
      width: "fit-content",
      textTransform: "capitalize",
    },
  };
});

const validationSchema = object({
  date: string("").required("Date is required"),
  time: string("").required("Time is required"),
  Note: string(""),
});

const LogWorkTimeForm = ({ setShowForm, latest_Entry }) => {
  const {
    Form_input,
    inputBox,
    input_typography,
    btns,
    form_main,
    responsive,
    fieldsInRow,
    signUp_form,
  } = useStyles();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { Latest_Entry, IsLogin, message } = useSelector(
    getAttendancedetailDetails
  );
  console.log(latest_Entry);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const user = cookies.get("user");
  console.log(user);
  let time;
  let value;
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  if (user?.timezone) {
    let Label = user?.timezone ? JSON.parse(user?.timezone).label : "";
    console.log(user, Label);
    value = Label.split("GMT")[1].split(")")[0];
    if (value.split(":")[0].length <= 2) {
      let x = value.split("");
      // let y = x.splice(1,0,'0');
      x.splice(1, 0, "0");
      x = x.join("");
      value = x;
    }

    time = moment().utcOffset(value).format("YYYY/MM/DD hh:mm:ss A");
  }

  const logTimeFormData = {
    date: moment(time).format("YYYY-MM-DD"),
    time: moment(time).format("HH:mm"),
    emp_id: user?.Emp_id,
    note: "",
    status: Latest_Entry?.status === "CheckIn" ? "CheckOut" : "CheckIn",
  };

  const formik = useFormik({
    initialValues: logTimeFormData,

    validationSchema: validationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handelSubmitForm(values, resetForm, setSubmitting);
    },
  });

  const handelSubmitForm = async (values, resetForm, setSubmitting) => {
    console.log(values);
    console.log(value);
    if(!value){
    setSubmitting(false);
      return setsnackBarData({
        type: "error",
        message: "timezone issue",
        openToast: true,
      });
     
    }
    const time = moment().utcOffset(value)?.format("YYYY/MM/DD hh:mm:ss A");
    const response = await AddClockInOut({
      ...values,
      emp_id: cookies.get("user")?.Emp_id,
      date: moment(time).format("YYYY-MM-DD"),
      time: moment(time).format("HH:mm"),
      attendance_id: latest_Entry ? latest_Entry?.attendance_id : null,
    });
    setsnackBarData(response.snackBarData);
    setSubmitting(false);
    if (response.data?.IsSuccess) {
      dispatch(updateAttendanceDetail(response.data?.AttendanceDetail));
      setShowForm(false);
      resetForm();
    setSubmitting(false);

    }else{
    setSubmitting(false);

    }

  };

  return (
    <Box className={form_main}>
      <Box className={responsive}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <form onSubmit={formik.handleSubmit} className={signUp_form}>
            <Box className={fieldsInRow}>
              <Box className={inputBox}>
                <Typography className={input_typography}>Date*</Typography>
                <FormControl fullWidth sx={{ textAlign: "left" }}>
                  <TextField
                    size="small"
                    name="date"
                    variant="outlined"
                    disabled
                    type="date"
                    color="primary"
                    sx={{ input: { color: "black" } }}
                    className={Form_input}
                    fullWidth
                    value={formik.values.date}
                    onChange={formik.handleChange}
                    error={formik.touched.date && Boolean(formik.errors.date)}
                    helperText={formik.touched.date && formik.errors.date}
                  />
                </FormControl>
              </Box>
              <Box className={inputBox}>
                <Typography className={input_typography}>Time*</Typography>
                <FormControl fullWidth sx={{ textAlign: "left" }}>
                  <TextField
                    size="small"
                    name="time"
                    variant="outlined"
                    disabled
                    type="time"
                    color="primary"
                    sx={{ input: { color: "black" } }}
                    className={Form_input}
                    fullWidth
                    value={formik.values.time}
                    onChange={formik.handleChange}
                    error={formik.touched.time && Boolean(formik.errors.time)}
                    helperText={formik.touched.time && formik.errors.time}
                  />
                </FormControl>
              </Box>
            </Box>
            <Box className={fieldsInRow}>
              <Box className={inputBox}>
                <Typography className={input_typography}>Note</Typography>
                <FormControl fullWidth sx={{ textAlign: "left" }}>
                  <TextField
                    size="small"
                    name="note"
                    variant="outlined"
                    type="text"
                    color="primary"
                    sx={{ input: { color: "black" } }}
                    className={Form_input}
                    fullWidth
                    value={formik.values.note}
                    onChange={formik.handleChange}
                    error={formik.touched.note && Boolean(formik.errors.note)}
                    helperText={formik.touched.note && formik.errors.note}
                  />
                </FormControl>
              </Box>
            </Box>
            {formik.isSubmitting ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  paddingTop: "10px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box
                sx={{
                  justifyContent: "flex-end",
                  display: "flex",
                  gap: "10px",
                  marginTop: "20px",
                }}
                spacing={2}
              >
                <>
                  <Button
                    className={btns}
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setShowForm(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={btns}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {Latest_Entry?.status === "CheckIn"
                      ? "Check Out"
                      : "Check In"}
                  </Button>
                </>
              </Box>
            )}
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default LogWorkTimeForm;
LogWorkTimeForm.propTypes = {
  setShowForm: PropTypes.any,
  latest_Entry: PropTypes.any,
};
