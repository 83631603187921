import {
  Box,
  //  CircularProgress
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext, useEffect, useState } from "react";
import Cards from "../Cards/Cards";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CelebrationIcon from "@mui/icons-material/Celebration";
import SouthWestIcon from "@mui/icons-material/SouthWest";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchDashboardData,
  FetchEvents,
  FetchPresentEmployeeData,
  GetWastedHours,
  getDashboardActivities,
} from "../../../Redux/Slice/Dashboard/DashboardSlice";
import BirthDayModal from "../../Dashboard/BirthdayModal/BirthdayModal";
import WastedHourModals from "../../Dashboard/WastedHoursModal/WastedHoursModal";
import ExtraHours from "../../Dashboard/ExtraHoursModel/ExtraHours";
import GazettedHolidays from "../../Dashboard/GazettedHolidays/GazettedHolidays";
import { SnackBarContext } from "../../../Context/SnackBarContext/SnackBarContext";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../Redux/Slice/LogoutSlice/LogoutSlice";
import CakeIcon from "@mui/icons-material/Cake";
import AnniversaryModal from "../../Dashboard/AnniversaryModal/AnniversaryModal";
const useStyle = makeStyles((theme) => {
  return {
    cardContainer: {
      display: "flex",
      alignItems: "center",
      gap: "40px",
      flexWrap: "wrap",
      padding: "10px 0px",
      justifyContent: "start",
      [theme.breakpoints.down("md")]: {
        gap: "20px",
      },
    },
  };
});

const DetailsCard = () => {
  const navigate = useNavigate();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { cardContainer } = useStyle();
  const {
    dashboarddata,
    wastedhours,
    IsLogin,
    message,
    dashboarddatastatus,
    wastedhoursstatus,
  } = useSelector(getDashboardActivities);
  const [openwasted, setopenwasted] = useState(false);
  const [openBirthdays, setopenBirthdays] = useState(false);
  const [openAnniversary, setopenAnniversary] = useState(false);

  const [extraHours, setExtraHours] = useState(false);
  const [gazetted, setGazeted] = useState(false);
  // const [loader, setloader] = useState(false);
  // const { isSuccess } = useSelector((state) => state.)
  const dispatch = useDispatch();
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
  }, [IsLogin]);
  useEffect(() => {
    dispatch(FetchDashboardData());
    dispatch(FetchPresentEmployeeData());
    dispatch(FetchEvents());
    dispatch(GetWastedHours());
  }, [dispatch]);
  console.log("DashData", wastedhours, wastedhours?.Wasted_hours);
  console.log("dashboarddata", dashboarddata);
  return (
    <Box className={cardContainer}>
      <Box
        sx={{ width: "100%", maxWidth: "320px" }}
        onClick={(e) => {
          e.stopPropagation();
          setExtraHours(true);
        }}
      >
        <Cards
          title={"Extra Hours"}
          status={wastedhoursstatus}
          cursor={true}
          icon={
            <CallMadeIcon
              sx={{
                fontSize: "45px",
                color: "rgb(0 171 92 / 86%) !important",
              }}
            />
          }
          count={
            wastedhours?.Extra_hours || 0

            // wastedhours
            //   ? `${wastedhours.Extra_hours ?? "Loading..."} `
            //   : 0
          }
          background={
            "linear-gradient(155deg, rgb(0 156 231 / 52%) 0%, rgb(0 171 92 / 86%) 100%)"
          }
        />
      </Box>
      <Box
        sx={{ width: "100%", maxWidth: "320px" }}
        onClick={(e) => {
          e.stopPropagation();
          setopenwasted(true);
        }}
      >
        <Cards
          title={"Short Hours"}
          status={wastedhoursstatus}
          cursor={true}
          icon={
            <AvTimerIcon
              sx={{
                fontSize: "45px",
                color: "rgb(0 102 162 / 85%) !important",
              }}
            />
          }
          count={wastedhours?.Wasted_hours || 0}
          background={
            "linear-gradient(155deg, rgb(96 225 219 / 85%) 0%, rgb(0 102 162 / 85%) 100%)"
          }
        />
      </Box>
      <Box
        sx={{ width: "100%", maxWidth: "320px" }}
        onClick={(e) => {
          e.stopPropagation();
          setGazeted(true);
        }}
      >
        <Cards
          title={"Gazetted Holidays"}
          status={wastedhoursstatus}
          icon={
            <SouthWestIcon
              sx={{
                fontSize: "45px",
                color: "rgb(21 202 221 / 94%) !important",
              }}
            />
          }
          count={
            wastedhours?.GazettedHolidays?.length || 0

            // wastedhours
            //   ? `${wastedhours?.GazettedHolidays?.length ?? "Loading..."}`
            //   : 0
          }
          background={
            "linear-gradient(155deg,rgb(8 117 195) 0%, rgb(21 202 221 / 94%) 100%)"
          }
          cursor={true}
        />
      </Box>
      <Box
        sx={{ width: "100%", maxWidth: "320px" }}
        onClick={(e) => {
          e.stopPropagation();
          setopenBirthdays(true);
        }}
      >
        <Cards
          title={"Birthdays"}
          status={dashboarddatastatus}
          icon={
            <CakeIcon
              sx={{
                fontSize: "45px",
                color: "rgba(0,117,182,0.8547794117647058) !important",
              }}
            />
          }
          count={
            dashboarddata?.Birthdays_today?.length || 0

            // dashboarddata
            //   ? dashboarddata?.Birthdays_today?.length ?? "Loading..."
            //   : 0
          }
          background={
            "linear-gradient(155deg, rgb(173 0 244 / 72%) 0%, rgba(0,117,182,0.8547794117647058) 100%)"
          }
          cursor={true}
        />
      </Box>
      <Box
        sx={{ width: "100%", maxWidth: "320px" }}
        onClick={(e) => {
          e.stopPropagation();
          setopenAnniversary(true);
        }}
      >
        <Cards
          title={"Work Anniversary"}
          status={dashboarddatastatus}
          icon={
            <CelebrationIcon
              sx={{
                fontSize: "45px",
                color: "rgb(21 202 221 / 94%) !important",
              }}
            />
          }
          count={
            dashboarddata?.Joining_date?.length || 0

            // dashboarddata
            //   ? dashboarddata?.Birthdays_today?.length ?? "Loading..."
            //   : 0
          }
          background={
            "linear-gradient(155deg, rgb(21 202 221 / 94%) 0%, rgb(173 0 244 / 72%) 100%)"
          }
          cursor={true}
        />
      </Box>
      <ExtraHours
        open={extraHours}
        setopen={setExtraHours}
        extraHours={wastedhours?.Employee_Data}
      />
      <GazettedHolidays
        open={gazetted}
        setopen={setGazeted}
        gazettedHolidays={wastedhours?.GazettedHolidays}
      />
      <BirthDayModal
        open={openBirthdays}
        setopen={setopenBirthdays}
        employeeBirthdays={dashboarddata?.Birthdays_today}
      />
      <AnniversaryModal
        open={openAnniversary}
        setopen={setopenAnniversary}
        employeeAnniversary={dashboarddata?.Joining_date}
      />
      <WastedHourModals
        open={openwasted}
        setopen={setopenwasted}
        employeewatedhours={wastedhours?.Employee_Data}
      />
    </Box>
  );
};

export default DetailsCard;
