import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance"

const initialState = {
  todayattendance: [],
  Employees: [],
  isSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  IsLogin: true,

};

export const fetchTodayAttendance = createAsyncThunk("getonedayattendance", async () => {
  const response = await axiosInstance.get(`getonedayattendance`);
  return response.data;
});
export const fetchTodayAttendancePopulate = createAsyncThunk(
  "attendancepopulate",
  async (initialItem) => {
    const response = await axiosInstance.get(`attendancepopulate`, initialItem);
    return response.data;
  }
);

// export const fetchTodayAttendancePopulate = createAsyncThunk(
//   "attendancepopulate",
//   async (initialItem) => {
//     const response = await axiosInstance.get(`attendancepopulate`, initialItem);
//     return response.data;
//   }
// );

const TodayAttendanceSlice = createSlice({
  name: "todayattendance",
  initialState,
  reducers: {
    TodayAttenndanceAdded: {
      reducer(state, action) {
        state.todayattendance.push(action.payload);
      },
    },
    TodayAttendanceResetData: (state) => {
      state.todayattendance= [];
      state.Employees= [];
      state.isSuccess= false;
      state.message= null;
      state.status= "idle"; //'idle' | 'loading' | 'succeeded' | 'failed'
      state.error= null;
      state.IsLogin= true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTodayAttendance.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTodayAttendance.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.todayattendance = action.payload.Data;

      })
      .addCase(fetchTodayAttendance.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      })
      .addCase(fetchTodayAttendancePopulate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Employees = action.payload.Employees;
      })
      .addCase(fetchTodayAttendancePopulate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTodayAttendancePopulate.rejected, (state, action) => {
        if (action?.error?.message.includes(401)) {
          state.message = action.error.message;
          state.IsLogin = false;
        } else {
          state.error = action.error.message;
          state.status = "failed";
        }
      });
  },
});

export const gettodayattendanceDetails = (state) => state.todayattendance;

export const { TodayAttenndanceAdded,TodayAttendanceResetData } = TodayAttendanceSlice.actions;

export default TodayAttendanceSlice.reducer;