import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useEffect } from "react";

import theme from "../../theme";

import { Field, Form, Formik } from "formik";
import { object, string } from "yup";
import { makeStyles } from "@mui/styles";
import { LoginAPI } from "../../Api/LoginAPI/LoginAPI";
import Cookies from "universal-cookie/cjs/Cookies";
import { useNavigate } from "react-router-dom";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import loginImage from "../../Assets/loginImage.png";
import { ProfileContext } from "../../Context/ProfileContext/ProfileContext";
// import { useContext } from "react";

const cookies = new Cookies();
const useStyles = makeStyles(() => {
  return {
    login_main: {
      backgroundColor: "#E8E8E8",
      display: "flex",
      width: "100%",
      height: "100vh",
      justifyContent: "center",
      alignItems: "center",
      gap: "50px",
      padding: "20px",
    },

    login_image: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },

    form_box: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: `${theme.palette.grey[100]} !important`,
      padding: "50px",
      flexDirection: "column",
      alignItems: "center",
      maxWidth: "450px",
      width: "100%",
      borderRadius: "15px",

      [theme.breakpoints.down("md")]: {
        padding: "25px",
      },
    },
    input_typography: {
      fontWeight: "bold",
      fontSize: `15px !important`,
      opacity: "50%",
      marginBottom: "2px",

      textAlign: "left",
    },
    login_input: {
      borderRadius: theme.borderRadius.All,
      backgroundColor: `${theme.palette.common.white} !important`,
    },
  };
});

const LoginContent = () => {
  console.log(loginImage);
  const { login_main, login_input, login_image, form_box, input_typography } =
    useStyles();
  const { setsnackBarData } = useContext(SnackBarContext);
  const { setprofileData } = useContext(ProfileContext);
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    const token = cookies.get("access_token");
    const user = cookies.get("user");
    console.log(user);
    if (token && user) {
      navigate("/dashboard");
    }
  }, []);

  const datadetails = {
    email: "",
    password: "",
  };
  const HandleFormValues = async (values) => {
    setloading(true);
    console.log(values);
    const response = await LoginAPI(values);
    console.log(response);
    setsnackBarData(response.snackBarData);
    setloading(false);
    console.log(response);
    if (response.data.IsSuccess) {
      cookies.set("Emp_id", response?.data?.Data?.Emp_id);
      setprofileData({
        user_id: response?.data?.Data?.user_id
          ? response?.data?.Data?.user_id
          : "",
        profile_image: response?.data?.Data?.profile_image
          ? response?.data?.Data?.profile_image
          : "",
        email: response?.data?.Data?.email ? response?.data?.Data?.email : "",
        username: response?.data?.Data?.username
          ? response?.data?.Data?.username
          : "",
        Emp_id: response?.data?.Data?.Emp_id
          ? response?.data?.Data?.Emp_id
          : "",
        type: response?.data?.Data?.type ? response?.data?.Data?.type : "",
      });
      navigate(response.navigate, {
        state: {
          empId: response?.data?.Data?.Emp_id,
          user_id: response?.data?.Data?.user_id,
          email: values?.email,
        },
      });
    } else if (!response.data.IsSuccess) {
      setloading(false);
    }
  };
  return (
    <>
      <Box
        className={login_main}
        sx={{}}
      >
        <Box className={login_image}>
          <img
            style={{ width: "100%", maxWidth: "800px" }}
            src={loginImage}
            alt="loginImage"
          />
        </Box>
        <Box>
          <Box sx={{ flex: 1 }}>
            <Paper
              className={form_box}
              elevation={3}
            >
              <Box sx={{ textAlign: "left" }}>
                <Typography
                  sx={{
                    fontSize: (theme) => theme.typography.h6,
                    fontWeight: "bold",
                    marginTop: 2,
                    paddingBottom: "10px",
                  }}
                >
                  Welcome back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "100%",
                }}
              >
                <Formik
                  initialValues={datadetails}
                  validationSchema={object({
                    email: string().required("This field is required"),

                    password: string().required("This field is required"),
                  })}
                  onSubmit={(values, formikHelpers) =>
                    HandleFormValues(values, formikHelpers)
                  }
                >
                  {({
                    errors,
                    isValid,
                    touched,
                    dirty,
                    isSubmitting,
                    values,
                    handleChange,
                  }) => (
                    <Form
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        width: "100%",
                      }}
                    >
                      <FormControl
                        fullWidth
                        sx={{ textAlign: "Left" }}
                      >
                        <Typography className={input_typography}>
                          Email
                        </Typography>
                        <Field
                          value={values.email}
                          onChange={handleChange}
                          placeholder="Enter Email"
                          type="email"
                          size="small"
                          as={TextField}
                          variant="outlined"
                          color="primary"
                          autoComplete="off"
                          readonly="readonly"
                          onMouseDown={(e) => {
                            e.target.removeAttribute("readonly");
                          }}
                          fullWidth
                          error={
                            Boolean(errors.email) && Boolean(touched.email)
                          }
                          helperText={Boolean(touched.email) && errors.email}
                          name="email"
                          className={login_input}
                        />
                      </FormControl>

                      <FormControl
                        fullWidth
                        sx={{ textAlign: "left" }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography className={input_typography}>
                            Password
                          </Typography>
                          <Box sx={{ width: "100%", textAlign: "right" }}>
                            <Typography
                              sx={{
                                cursor: "pointer",
                                fontSize: "14px",
                                opacity: "50%",
                              }}
                              onClick={() => navigate("/forgotpassword/")}
                            >
                              Forgot Password?.
                            </Typography>
                          </Box>{" "}
                        </Box>

                        <OutlinedInput
                          onChange={handleChange}
                          name="password"
                          value={values.password}
                          placeholder="Enter Password"
                          size="small"
                          fullWidth
                          autoComplete={false}
                          readonly="readonly"
                          onMouseDown={(e) => {
                            e.target.removeAttribute("readonly");
                          }}
                          error={
                            Boolean(errors.password) &&
                            Boolean(touched.password)
                          }
                          helperText={
                            Boolean(touched.password) && errors.password
                          }
                          className={login_input}
                          id="outlined-adornment-password"
                          type={showPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>

                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        {loading === false ? (
                          <Button
                            type="submit"
                            disabled={!isValid || !dirty || isSubmitting}
                            sx={{
                              backgroundColor: (theme) =>
                                !isValid || !dirty
                                  ? theme.palette.white.main
                                  : theme.palette.primary.main,
                              color: (theme) =>
                                !isValid || !dirty
                                  ? theme.palette.primary.main
                                  : theme.palette.white.main,
                              width: "100% !important",
                              margin: "10px",

                              border: (theme) =>
                                `1px solid ${theme.palette.primary.main}`,

                              "&:hover": {
                                backgroundColor: (theme) =>
                                  theme.palette.white.main,
                                color: (theme) => theme.palette.primary.main,
                                border: (theme) =>
                                  `1px solid ${theme.palette.primary.main}`,
                              },
                            }}
                          >
                            Login
                          </Button>
                        ) : (
                          <Box sx={{ margin: "5px auto" }}>
                            <CircularProgress />
                          </Box>
                        )}
                      </Box>
                    </Form>
                  )}
                </Formik>

                <Typography
                  sx={{
                    fontSize: (theme) => theme.typography.fontSize,
                  }}
                >
                  Don&apos;t have an account?{" "}
                  <Typography
                    component="span"
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      cursor: "pointer",
                    }}
                  >
                    Contact admin
                  </Typography>
                </Typography>
              </Box>
            </Paper>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LoginContent;
