import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
// import TitleBar from "../../Components/DashboardReDesign/TitleBar/TitleBar";
import DetailsCard from "../../Components/DashboardReDesign/Detailscards/DetailsCard";
import DetailsCharts from "../../Components/DashboardReDesign/DetailsCharts/DetailsCharts";
import PresentEmployeeTableContainer from "../../Components/DashboardReDesign/PresentEmployeeTableContainer/PresentEmployeeTableContainer";
import CalendarChart from "../../Components/Charts/CalendarChart/CalendarChart";
import AttendanceSwiper from "../../Components/DashboardReDesign/AttendanceDrawer/AttendanceSwiper";
import Top5Tables from "../../Components/DashboardReDesign/Top5Tables/Top5Tables";

const useStyle = makeStyles(() => {
  return {
    container: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0px 20px",
      overflow: "hidden",
      background:
        "linear-gradient(155deg, rgba(240,240,240,1) 0%, rgba(255,255,255,0.9920343137254902) 100%)",
    },
    subContainer: {
      display: "flex",
      alignItems: "center",
      gap: "40px",
      width: "100%",
      flexDirection: "column",
      justifyContent: "center",
    },
  };
});

const DashboardRedesign = () => {
  const { container, subContainer } = useStyle();

  return (
    <Box className={container}>
      <Box className={subContainer}>
        <Box sx={{ width: "100%" }}>{/* <TitleBar /> */}</Box>
        <Box sx={{ width: "100%" }}>
          <DetailsCard />
        </Box>
        <Box sx={{ width: "100%" }}>
          <DetailsCharts />
        </Box>
        <Box sx={{ width: "100%" }}>
          <PresentEmployeeTableContainer />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Top5Tables />
        </Box>
        <Box sx={{ width: "100%" }}>
          <AttendanceSwiper />
        </Box>
        <Box sx={{ width: "100%" }}>
          <CalendarChart />
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardRedesign;
