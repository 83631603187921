import PropTypes from "prop-types";
import {
  Box,
  // Button,
  // CircularProgress,
  Grid,
  gridClasses,
  InputAdornment,
  TextField,
  IconButton,
  // Switch,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import moment from "moment";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGazettedHolidays,
  getgazettedholidayDetails,
  deleteGazHoliday,
} from "../../Redux/Slice/GazettedHolidays/GazettedHolidaysSlice";
import { makeStyles } from "@mui/styles";
import { useContext } from "react";
import DeleteModal from "../DeleteModal/DeleteModal";
import theme from "../../theme";
import { SnackBarContext } from "../../Context/SnackBarContext/SnackBarContext";
import { logout } from "../../Redux/Slice/LogoutSlice/LogoutSlice";
import { useNavigate } from "react-router-dom";
import { DeleteGazHolidayApi } from "../../Api/GazettedHolidays/GazettedHolidaysAPis";
import CustomButton from "../Button/AddNewButton";
import AddIcon from "@mui/icons-material/Add";
import { TabelStyleContext } from "../../Context/TabelStyleContext/TabelContext";
import Loader from "../Loader/Loader";

const useStyles = makeStyles(() => {
  return {
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
    },
  };
});

function GazettedHolidaysTable({
  setShowForm,
  setIsEdit,
  setEditedData,
  EditedData,
}) {
  const { tabelStyle } = useContext(TabelStyleContext);

  const { header } = useStyles();
  const [rows, setrows] = useState([]);
  const [filterRows, setfilterRows] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setloading] = useState(false);
  const [setmessage] = useState("");
  const [setopenToast] = useState(false);
  const [setAlertType] = useState("success");
  const navigate = useNavigate();
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [editPermit, seteditpermit] = useState(false);

  const [deletePermit, setdeletepermit] = useState(false);
  const dispatch = useDispatch();
  const [AllHolidays, setAllHolidays] = useState([]);

  const hoildaysDetails = useSelector(getgazettedholidayDetails);
  const { IsLogin } = useSelector(getgazettedholidayDetails);
  const { setsnackBarData } = useContext(SnackBarContext);

  const [roles, setroles] = useState(JSON.parse(localStorage.getItem("roles")));
  const [addPermit, setaddpermit] = useState(false);

  console.log(editPermit);

  console.log(roles);
  useEffect(() => {
    let string = "GazettedHolidays";
    let value = roles?.ruleid?.filter((role) => {
      if (role?.module?.split("_")[0].toLowerCase() === string.toLowerCase()) {
        return role;
      }
    });
    value?.find((val) => {
      if (val?.module?.split("_")[1] === "Add") {
        setaddpermit(true);
      } else if (val?.module?.split("_")[1] === "Edit") {
        seteditpermit(true);
      } else if (val?.module?.split("_")[1] === "Delete") {
        setdeletepermit(true);
      }
    });
    if (!roles || roles.length < 1) {
      setaddpermit(true);
      seteditpermit(true);
      setdeletepermit(true);
    }
    setroles(JSON.parse(localStorage.getItem("roles")));
  }, []);

  console.log(addPermit);

  useEffect(() => {
    dispatch(fetchGazettedHolidays());
  }, [dispatch]);
  useEffect(() => {
    if (!IsLogin) {
      setsnackBarData({
        type: "error",
        message: hoildaysDetails?.message,
        openToast: true,
      });
      dispatch(logout());
      return navigate("/");
    }
    setAllHolidays(hoildaysDetails?.gazettedholidays);
  }, [hoildaysDetails, IsLogin]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport csvOptions={{ fileName: "Gazetted Holidays" }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }
  const columns = [
    { field: "sr", headerName: "Sr #", headerClassName: header },

    {
      field: "holiday_name",
      headerName: "Holiday Name",
      width: 250,
      headerClassName: header,
    },
    {
      field: "holiday_type",
      headerName: "Type",
      width: 250,
      headerClassName: header,
    },

    {
      field: "date",
      headerName: "Date",
      type: "date",
      width: 250,
      headerClassName: header,
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 200,
      headerClassName: header,
      renderCell: (celVal) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              gap: "10px",
            }}
          >
            {editPermit ? (
              <IconButton
                size={"small"}
                onClick={() => {
                  setEditedData(celVal.row);
                  setIsEdit(true);
                  setShowForm(true);
                }}
              >
                <AppRegistrationIcon
                  sx={{ cursor: "pointer", color: "black !important" }}
                />
              </IconButton>
            ) : null}
            {deletePermit ? (
              <IconButton
                onClick={() => {
                  setEditedData(celVal.row);
                  setshowDeleteModal(true);
                }}
                size={"small"}
              >
                <DeleteOutlineIcon sx={{ color: theme.palette.error.main }} />
              </IconButton>
            ) : null}
          </Box>
        );
      },
    },
    // {
    //   field: "click",
    //   headerName: "Status",
    //   type: <button />,
    //   width: 250,
    //   renderCell: () => {
    //     // console.log(cellValues.row);
    //     return (
    //       <Switch

    //         sx={{ color: "black" }}
    //       ></Switch>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    // console.log(DyeingQuotationDetails);

    let arr = [];
    if (AllHolidays) {
      console.log(AllHolidays);
      AllHolidays?.forEach((item, idx) => {
        console.log(item);
        return arr.push({
          ...item,
          sr: idx + 1,
          id: item?.holiday_id,

          date: item.holiday_date
            ? moment(item.holiday_date).format("DD-MMM-YYYY")
            : "",
        });
      });
    }

    setrows(arr);
    setfilterRows(arr);
  }, [AllHolidays]);

  const onTextChange = () => {
    if (document.getElementById("OrderNoFilter").value === "") {
      setfilterRows(rows);
    } else {
      let suggestions = [];

      var regex1 = new RegExp(
        document.getElementById("OrderNoFilter").value,
        "i"
      );

      suggestions = rows?.filter(
        (val) =>
          document.getElementById("OrderNoFilter").value === "" ||
          regex1.test(val.holiday_name)
      );

      setfilterRows(suggestions);
    }
  };

  useEffect(() => {
    //   console.log(loading);

    if (
      getgazettedholidayDetails?.status === "succeeded" ||
      getgazettedholidayDetails?.status === "failed"
    ) {
      // setloading(false);
      if (getgazettedholidayDetails?.isSuccess === true) {
        setAlertType("success");
        setmessage(getgazettedholidayDetails.message);
        setopenToast(true);
      }
      if (getgazettedholidayDetails?.isSuccess === false) {
        setAlertType("error");
        setmessage(
          getgazettedholidayDetails.message || getgazettedholidayDetails.error
        );
        setopenToast(true);
      }
    }
  }, [getgazettedholidayDetails]);
  const handelDelete = async () => {
    setloading(true);
    const response = await DeleteGazHolidayApi(EditedData?.holiday_id);
    setsnackBarData(response.snackBarData);
    setloading(false);
    if (response.data?.IsSuccess) {
      setshowDeleteModal(false);
      dispatch(deleteGazHoliday(EditedData?.holiday_id));
    }
  };

  useEffect(() => {
    if (hoildaysDetails?.status === "failed") {
      setsnackBarData({
        type: "error",
        message: hoildaysDetails?.error,
        openToast: true,
      });
    }
  }, [hoildaysDetails.status]);
  return (
    <Grid container spacing={2} sx={{ padding: "10px" }}>
      <Grid item md={2} xs={12} xl={2} sx={{ display: "flex", gap: "5px" }}>
        {addPermit ? (
          <>
            {/* <Button
              sx={{ padding: "5px 10px" }}
              onClick={() => {
                setShowForm(true);
              }}
              variant="contained"
              className="tabletopbutton1 text-right"
            >
              New
            </Button> */}
            <CustomButton
              Color={"primary"}
              Icon={<AddIcon />}
              onClick={() => {
                setShowForm(true);
              }}
              Label={"Add"}
            />
          </>
        ) : null}
      </Grid>
      <Grid item md={8} xs={12} xl={8}></Grid>
      <Grid item md={2} xs={12} xl={2}>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: (theme) => theme.palette.grey.G2 }} />
              </InputAdornment>
            ),
          }}
          variant="standard"
          type="text"
          autoComplete="off"
          placeholder="Holidays"
          className="input_border"
          id="OrderNoFilter"
          onChange={onTextChange}
        />
      </Grid>

      <span className="ml-2 float-right">
        <div className="loaderclass"> </div>
      </span>

      {hoildaysDetails?.gazettedholidays?.length <= 0 &&
      hoildaysDetails.status === "loading" ? (
        <>
          <Loader />
        </>
      ) : null}
      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        overflow
        sx={{ height: tabelStyle.height }}
      >
        <DataGrid
          rows={filterRows}
          columns={columns}
          onRowClick={() => {}}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          pagination
          components={{
            Toolbar: CustomToolbar,
          }}
          localeText={{
            toolbarDensity: "Size",
            toolbarDensityLabel: "Size",
            toolbarDensityCompact: "Small",
            toolbarDensityStandard: "Medium",
            toolbarDensityComfortable: "Large",
          }}
        />
      </Grid>

      <DeleteModal
        name={`"${EditedData?.holiday_name}" Gazetted Holiday`}
        handelDelete={handelDelete}
        showModal={showDeleteModal}
        setshowModal={setshowDeleteModal}
        loading={loading}
      />
    </Grid>
  );
}

export default GazettedHolidaysTable;
GazettedHolidaysTable.propTypes = {
  setShowForm: PropTypes.any,
  setIsEdit: PropTypes.func,
  setEditedData: PropTypes.func,
  EditedData: PropTypes.any,
  // loadings: PropTypes.bool,
};
